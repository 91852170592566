import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { FieldArray, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';

import { intToOrdinal, getQueryVariables } from 'util/index';

import Card from 'components/Styled/Card';
import { Row as BaseRow, Column as BaseColumn, PullRight, PullLeft } from 'components/Styled/Layout';

import { Input, Select, NumberInput, LabelledInput, TextArea } from 'components/Form';
import Label from 'components/Styled/Label';
import InputGroup from 'components/Common/InputGroup'; 
import BackButton from 'components/Common/BackButton';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';

import imgAdd from 'img/button/small-add-blue.png';
import imgTrash from 'img/button/trash-red.png';


const Row = styled(BaseRow)`
  padding: 30px 0 0 40px;
`;

const Column = styled(BaseColumn)`
  padding: 0 40px 0 0;
`;

const ImgAdd = styled.img.attrs({
  src: imgAdd,
  width: 10,
})`
  position: relative;
  margin-right: 10px;
`;

const ImgTrash = styled.img.attrs({
  src: imgTrash,
  width: 16,
})`
  position: relative;
  top: 2px;
  right: 4px;
`;


const Heading = styled.h2`
  font-size: 20px;
`;

class SecuritiesForm extends Component {
  
  render() {
    const { id } = getQueryVariables(this.props.location.search);
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FieldArray name='securities' component={Securities} id={id}/>
        <BaseRow style={{width:'800px', margin: '-20px 0 0 0'}}>
          <PullLeft>
            <ButtonPlain type='button' width='100px' onClick={() => this.props.history.goBack()}>Cancel</ButtonPlain>
          </PullLeft>

          <PullRight>
            <ButtonPrimary type='submit' width='150px' >Save</ButtonPrimary>
          </PullRight>
        </BaseRow>
      </form>
    );
  }
}

const SecuritiesBase = ({fields, id, history}) => {

  return (
    <Fragment>
      <BaseRow style={{width:'800px'}}>
        <PullLeft>
          <BackButton margin='15px 0 0 0'/>
        </PullLeft>

        <PullRight>

          <ButtonPlain type='button' onClick={fields.push} style={{margin: '0 0 20px 20px'}}>
            <ImgAdd/>
            Add Additional Security
          </ButtonPlain>
        </PullRight>

      </BaseRow>
      {fields.map((applicant, index) => 
        <SecurityCard key={index} index={index} applicant={applicant} fields={fields}/>)
      }
    </Fragment>
  );
};

const Securities = withRouter(SecuritiesBase);

const SecurityCard = ({ index, security, fields }) => (
  <Card padding='10px 0 40px 0' margin='0 0 40px 0' width='800px'>
    <Row>
      <Column>
        <PullLeft>
          <Heading>{intToOrdinal(index + 1)} Security </Heading>
        </PullLeft>
        {index > 0 &&
            <PullRight>
              <ButtonPlain 
                onClick={() => fields.remove(index)} 
                width='30px' 
                height='30px' 
                style={{ margin: '-1px 0 0 0' }}>
                <ImgTrash /></ButtonPlain>
            </PullRight>
        }
      </Column>

    </Row>
    <Row>

      <Column width='100%'>
        <Label>Address</Label>
        <Input name='address' />
      </Column>

      <Column width='50%' style={{ padding: '29px 40px 0 0' }}>
        <Label>Value</Label>
        <InputGroup>
          <LabelledInput name='value' as={NumberInput} label='$' width='65%' />
          <Select name='valueType' width='35%' options={[{name: 'Estimate', value: 'estimate'}, {name: 'Appraised', value: 'appraised'}]}/>
        </InputGroup>
      </Column>
      <Column width='50%' style={{ padding: '29px 40px 0 0' }}>
        <Label>Purpose</Label>
        <Select options={[{name: 'Investment', value: ''}]}/>
      </Column>

      <Column width='50%' style={{ padding: '29px 40px 0 0' }}>
        <Label>Notes</Label>
        <TextArea name='notes' height='135px' />
      </Column>

      <BaseColumn width='50%' style={{ padding: '29px 40px 0 0' }}>
        <BaseRow>
          <Column width='100%'>
            <Label>Type</Label>
            <Select name='type' options={[{name: 'Security', value: 'security'}]}/>
          </Column>
          <Column width='100%' style={{ padding: '29px 40px 0 0' }}>
            <Label>Cross-Collateralised Group</Label>
            <Select name='ccgroup' options={[{name: '-', value: ''}]}/>
          </Column>
        </BaseRow>
      </BaseColumn>
    </Row>
  </Card>
);



SecuritiesForm = reduxForm({
  form: 'securities',
  enableReinitialize: true,
})(SecuritiesForm);


export default withRouter(SecuritiesForm);
