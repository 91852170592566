import React from 'react';
import styled from 'styled-components';

const InputGroup = styled.div`
  display: inline-block;
  width: ${p => p.width || '100%'};
  height: ${p => p.height || '40px'};
  > * {
    display: inline-block !important;
    margin: 0 0 0 0 !important; 

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      /* border-width: 1px 1px 1px 0px; */
    }

    &:not(:first-child) {
      border-left: none;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0px !important;
      /* border-width: 1px 1px 1px 0px; */
    }
  }

  
`;

export default InputGroup;