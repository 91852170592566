import React, { Component } from 'react';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';

import Card from 'components/Styled/Card';
import { Row, Column, PullRight } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';

import { ButtonPrimary } from 'components/Styled/Button';
import { TextInputRow, ToggleInputRow } from './SharedComponents';
import ButtonSpinner from 'components/Styled/ButtonSpinner';


const RightColumn = styled(Column)`
  min-height: 85px;
  width: calc(100% - 230px);
  padding: 0 40px 20px 40px;
  border-right: 1px solid #D8DCE7;
`;

const FillerRow = styled(Column)`
  height: 20px;
  width: calc(100% - 230px);
  padding: 0 40px 20px 40px;
  border-right: 1px solid #D8DCE7;
`;

const LeftColumn = styled(Column)`
  min-height: 85px;
  width: 230px;
  padding: 0 40px 20px 40px;
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #D8DCE7;
  margin-bottom: ${p => p.noMargin ? '0' : '20px'};
`;

const Heading = props => <Txt size='20px' {...props} />;


class AdditionalDetails extends Component {
  render() {
    const { handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Card maxWidth='1200px' minWidth='1080px' padding='0 0 20px 0' margin='0 0 40px 0'>
          <Row>
            <RightColumn>
              <Heading marginTop='40px' marginBottom='20px'>Additional Details</Heading>
            </RightColumn>
            <LeftColumn>
              <Heading marginTop='40px' >Review Updates</Heading>
            </LeftColumn>
          </Row>
          <TextInputRow formName='product-additional-details' name='minimum_loan_amount' title='Minimum Loan Size' type='money'/>
          <TextInputRow formName='product-additional-details' name='maximum_loan_amount' title='Maximum Loan Size' type='money'/>
          <TextInputRow formName='product-additional-details' name='maximum_lodoc_loan_amount' title='Maximum Lo Doc Loan Size' type='money'/>
          <TextInputRow formName='product-additional-details' name='maximum_term' title='Maximum Term (years)' type='number'/>
          <TextInputRow formName='product-additional-details' name='maximum_lvr_refinance' title='Maximum LVR Refinance' type='percent'/>
          <TextInputRow formName='product-additional-details' name='maximum_lvr_investment' title='Maximum LVR Investment' type='percent'/>
          <TextInputRow formName='product-additional-details' name='maximum_lvr_own_occ' title='Maximum LVR Owner Occupied' type='percent'/>
          <TextInputRow formName='product-additional-details' name='maximum_lvr_owner_builder' title='Maximum LVR Owner Builder' type='percent'/>
          <TextInputRow formName='product-additional-details' name='maximum_lvr_spec_builder' title='Maximum LVR Special Builder' type='percent'/>
          

          <ToggleInputRow formName='product-additional-details' name='capitalise_lmi' title='Capitalise LMI' valueChecked='Y' valueUnchecked='N'/>
          {/* <ToggleInputRow formName='product-additional-details' name='maxLvrLmi' title='Max LVR includes LMI' /> */}
          <TextInputRow formName='product-additional-details' name='minimum_genuine_savings' title='Minimum Genuine Savings' type='percent'/>
          <Row>
            <FillerRow />
          </Row>
          <BottomBorder />

          <Row>
            <PullRight padding='0 40px 0 0'>
              <ButtonPrimary type='submit' disabled={this.props.pristine || this.props.submitting} width='150px' background='#26BD27'>
                <ButtonSpinner marginRight='15px' show={this.props.submitting}/> 
                Save
              </ButtonPrimary>
            </PullRight>
          </Row>

        </Card>
      </form>
    );
  }
}

export default reduxForm({
  form: 'product-additional-details',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(AdditionalDetails);