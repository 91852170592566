import styled from 'styled-components';
import imgDown from 'img/button/down-blue.png';

const Select = styled.select`

      position: relative;
      bottom: 1px;
      width: ${p => p.width || '100%'};
      height: ${p => p.height || '40px'};
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid #D8DCE7;
      padding-left: 15px;
      font-size: ${p => p.fontSize || '14'}px;
      background-image: url(${imgDown});
      background-size: 10px 6px;
      background-position: Calc(100% - 15px) 50%;
      background-repeat: no-repeat;
      border-radius: 4px;
      /* box-shadow: 0px 1px 0px 0px #e9ebf1; */

      &:focus {
        border: 1px solid #2291FF !important;
        box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
        padding-left: 14px;
      }

      &:disabled, &.disabled {
        background-color: #ebebe4;
        background-image: none;
      }

      &.no-arrow {
        background-image: none;
      }

      ${p => p.noClick ? 'pointer-events: none;' : ''}
`;

export default Select;