import calculateMonthlyRepayments from "./calculateMonthlyRepayments";
import calculateLoanValues from "./calculateLoanValues";

export const calculateProductValues = (product, currentLoan, user) => {
    product = calculateLoanValues(product, user);
    const irWithRebate = product.iir - (product.trailRebate || 0);
  
    let loanSchedule = currentLoan.repaymentType === 'Interest' ?
        calculateLoanScheduleInterestOnly({ 
          period: (currentLoan.loanPeriod || 30) * 12, 
          ir: currentLoan.interestRate / 1200, 
          principle: currentLoan.loanAmount, 
          chartPeriod: (currentLoan.loanPeriod || 30) * 12 
        })
        :
        calculateLoanSchedulePandI({ 
          period: (currentLoan.loanPeriod || 30) * 12, 
          ir: currentLoan.interestRate / 1200, 
          principle: currentLoan.loanAmount, 
          chartPeriod: (currentLoan.loanPeriod || 30) * 12 
        });
  
    const newLoanSchedule = product.repaymentType === 'Interest Only' ?
      calculateLoanScheduleInterestOnly({ 
        period: (currentLoan.newLoanPeriod || 30) * 12, 
        ir: irWithRebate / 1200, 
        principle: currentLoan.loanAmount, 
        chartPeriod: (currentLoan.newLoanPeriod || 30) * 12 
      })
      :
      calculateLoanSchedulePandI({ 
        period: (currentLoan.newLoanPeriod || 30) * 12, 
        ir: irWithRebate / 1200, 
        principle: currentLoan.loanAmount, 
        chartPeriod: (currentLoan.newLoanPeriod || 30) * 12
      });
  
    const newLoanScheduleWithoutRebate = product.repaymentType === 'Interest Only' ?
      calculateLoanScheduleInterestOnly({ 
        period: (currentLoan.newLoanPeriod || 30) * 12, 
        ir: product.iir / 1200, 
        principle: currentLoan.loanAmount, 
        chartPeriod: 12 })
      :
      calculateLoanSchedulePandI({ 
        period: (currentLoan.newLoanPeriod || 30) * 12, 
        ir: product.iir / 1200, 
        principle: currentLoan.loanAmount, 
        chartPeriod: 12 });

    if (currentLoan.newLoanPurpose !== 'refinance') {
      loanSchedule = newLoanSchedule;
      currentLoan.loanPeriod = currentLoan.newLoanPeriod;
    }

    product.yearOneInterest = newLoanSchedule[12].totalInterestRepayment;
    product.oldYearOneInterest = loanSchedule[12].totalInterestRepayment;

    product.yearOneInterestSaving = 
      loanSchedule[12].totalInterestRepayment 
      - newLoanSchedule[12].totalInterestRepayment;
    
    product.yearOneRebateSaving = 
      newLoanScheduleWithoutRebate[12].totalInterestRepayment 
      - newLoanSchedule[12].totalInterestRepayment;

    product.totalInterestSavings = 
      loanSchedule[(currentLoan.loanPeriod || 30) * 12].totalInterestRepayment 
      - newLoanSchedule[(currentLoan.newLoanPeriod || 30) * 12].totalInterestRepayment;

    product.newLoanSchedule = newLoanSchedule;
    product.oldLoanSchedule = loanSchedule;

    product.yearXInterestSaving = year => 
      product.oldLoanSchedule[Math.min(currentLoan.loanPeriod || 30, year) * 12]?.totalInterestRepayment 
      - product.newLoanSchedule[Math.min(currentLoan.newLoanPeriod || 30, year) * 12]?.totalInterestRepayment;

    const minPeriod = Math.min(currentLoan.loanPeriod || 30, currentLoan.newLoanPeriod || 30);
    product.releventInterestTerm = 
      product.initial_ir_type === "Fixed" ? Math.min(product.fixedTermYears, minPeriod) : minPeriod;


    
    // product.newLoanPeriod = currentLoan.newLoanPeriod;
    // product.oldLoanPeriod = currentLoan.oldLoanPeriod;

    if (product.repaymentType === 'Interest Only') {
      product.monthlyRepayments = (parseFloat(product.iir) / 100 / 12 * currentLoan.loanAmount);
      product.fortnightlyRepayments = (parseFloat(product.iir) / 100 / 26.0714 * currentLoan.loanAmount); 
      product.weeklyRepayments = (parseFloat(product.iir) / 100 / 52.1428 * currentLoan.loanAmount);
    } else {
      product.monthlyRepayments = calculateMonthlyRepayments(
        parseFloat(product.iir) / 100 / 12, 
        (currentLoan.newLoanPeriod || 30) * 12, 
        currentLoan.loanAmount
      );
      product.fortnightlyRepayments = calculateMonthlyRepayments(
        parseFloat(product.iir) / 100 / 26.0714, 
        (currentLoan.newLoanPeriod || 30) * 26.0714, 
        currentLoan.loanAmount
      );
      product.weeklyRepayments = calculateMonthlyRepayments(
        parseFloat(product.iir) / 100 / 52.1428, 
        (currentLoan.newLoanPeriod || 30) * 52.1428, 
        currentLoan.loanAmount
      );
    }
  
    
  
    return product;
  }

  const calculateLoanSchedulePandI = ({ period, ir, principle, chartPeriod }) => {
    const repayment = calculateMonthlyRepayments(ir, period, principle);
    let totalInterestRepayment, totalPrincipleRepayment;
    totalInterestRepayment = totalPrincipleRepayment = 0;
    let schedule = [{
      time: 0,
      remainingPrinciple: principle,
      totalInterestRepayment,
      totalPrincipleRepayment,
      interestRepayment: 0,
      principleRepayment: 0
    }];
    [...Array(chartPeriod)].forEach((_, i) => {
      const remainingPrinciple = principle - totalPrincipleRepayment;
      const interestRepayment = remainingPrinciple * ir;
      const principleRepayment = repayment - interestRepayment;
      if (i < period) {
        totalInterestRepayment += interestRepayment;
        totalPrincipleRepayment += principleRepayment;
      }
     
      schedule.push({
        time: i + 1,
        interestRepayment,
        totalInterestRepayment,
        principleRepayment,
        remainingPrinciple
      });
    });
    return schedule;
  }
  
  const calculateLoanScheduleInterestOnly = ({ period, ir, principle, chartPeriod }) => {
    //console.log('IO schedule stuff:', period, ir, principle)
    let schedule = [{
      time: 0,
      remainingPrinciple: principle,
      totalInterestRepayment: 0,
      totalPrincipleRepayment: 0,
      interestRepayment: 0,
      principleRepayment: 0
    }];
    const interestRepayment = ir * principle;
    let totalInterestRepayment = 0;
    [...Array(chartPeriod)].forEach((_, i) => {
      if (i < period) {
        totalInterestRepayment += interestRepayment;
      }
  
      schedule.push({
        time: i + 1,
        interestRepayment,
        totalInterestRepayment,
        principleRepayment: 0,
        remainingPrinciple: principle
      });
      
    });
    return schedule;
  }