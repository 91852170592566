import React, { Component } from 'react';
import { Debouncer } from 'util/index';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import imgSearch from 'img/icons/icon-search-blue.png';
import TextInput from 'components/Styled/TextInput';

const SearchInput = styled(TextInput)`
  padding-left: 35px;
  background-image: url(${imgSearch});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 15px auto;
  padding-top: 3px;
`;

class SearchBar extends Component {
  constructor() {
    super();
    this.debouncer = new Debouncer();
  }

  render() {
    const {placeholder, width, onChange, msDebounce, style, ...props} = this.props;
    return (
      <SearchInput 
        type='text' 
        placeholder={placeholder || ''} 
        style={{ ...style, width }}
        onChange= {async e => {
          const search = e.target.value;
          this.debouncer.run(() => onChange(search), msDebounce || 0);
        }}
        {...props}  
      />
    );
  }
} 

SearchBar.propTypes = {
  width: PropTypes.string,
  placeholder: PropTypes.string,
  msDebounce: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export default SearchBar;