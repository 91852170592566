// src/views/NewsFeed/index.js
import React, { Component } from 'react';
import { BtnComment, BtnComplete, UpArrow, DownArrow } from './styled';
import BackButton from 'components/Common/BackButton';
import { withRouter } from 'react-router';

const listItems1 = [
  {
    title: 'Resimac Horizon product rate change from 3.5% to 3.6%. Product rate override currently in place, please check.',
    type: 'green',
    time: '9:32 am, 20 Aug.'
  },
  {
    title: 'Client John Smith’s settlement date is only 5 days away',
    type: 'blue',
    time: '8:40 am, 20 Aug.'
  },
  {
    title: 'You have an update on your eligibility check',
    type: 'grey',
    time: '4:06 pm, 19 Aug.'
  },
  {
    title: 'Resimac Horizon product rate change from 2.5% to 2.9%.',
    type: 'grey',
    time: '2:56 pm, 19 Aug.'
  },
  {
    title: 'Client Joel Collins’ settlement date is only 5 days away',
    type: 'grey',
    time: '11:00 am, 18 Aug.'
  },
  {
    title: 'Resimac Horizon product rate change from 2.5% to 2.9%.',
    type: 'grey',
    time: '12:00 am, 20 Aug.'
  },
  {
    title: 'Client Joel Collins’ settlement date is only 5 days away',
    type: 'grey',
    time: '12:00 am, 20 Aug.'
  },
];

const listItems2 = [
  {
    title: 'John Smith has a fixed term expiry in 90 days for his $440,000 ANZ investment loan.',
    type: 'green',
    time: '9:32 am, 20 Aug.'
  },
  {
    title: 'Client John Smith’s settlement date is only 5 days away',
    type: 'blue',
    time: '8:40 am, 20 Aug.'
  },
  {
    title: 'James Credence has requested an application update.',
    type: 'blue',
    time: '4:06 pm, 19 Aug.'
  },
  {
    title: 'John Smith has a fixed term expiry in 10 days for his $1,290,000 ANZ investment loan.',
    type: 'grey',
    time: '2:56 pm, 19 Aug.'
  },
];

const listItems3 = [
  {
    title: 'John Smith has lodged an eligibility check for $700,000 with ANZ, Resimac, and Bankwest.',
    type: 'green',
    time: '9:32 am, 20 Aug.'
  },
  {
    title: 'Jane Davids has lodged an eligiblity check for $1,500,000 with Resimac.',
    type: 'green',
    time: '8:40 am, 20 Aug.'
  },
  {
    title: 'Stephen Smith has lodged an eligibliity check for $950,000 with ANZ.',
    type: 'green',
    time: '4:06 pm, 19 Aug.'
  },
  {
    title: 'Mateusz Dembek has lodged an eligiblity check for $5,000,000 with ANZ.',
    type: 'blue',
    time: '2:56 pm, 19 Aug.'
  },
  {
    title: 'Joel Collins has lodged an eligiblity check for $500,000 with ANZ.',
    type: 'grey',
    time: '11:00 am, 18 Aug.'
  },
  {
    title: 'Joel Collins has lodged an eligiblity check for $500,000 with ANZ.',
    type: 'grey',
    time: '12:00 am, 20 Aug.'
  },
  {
    title: 'Stephen Smith has lodged an eligibliity check for $950,000 with ANZ.',
    type: 'grey',
    time: '12:00 am, 20 Aug.'
  },
  {
    title: 'Joel Collins has lodged an eligiblity check for $500,000 with ANZ.',
    type: 'grey',
    time: '12:00 am, 20 Aug.'
  },
  {
    title: 'Stephen Smith has lodged an eligibliity check for $950,000 with ANZ.',
    type: 'grey',
    time: '12:00 am, 20 Aug.'
  },
];

const listItems4 = [
  {
    title: 'John Smith has lodged an eligibility check for $700,000 with ANZ, Resimac, and Bankwest.',
    type: 'green',
    time: '9:32 am, 20 Aug.'
  },
  {
    title: 'Jane Davids has lodged an eligiblity check for $1,500,000 with Resimac.',
    type: 'green',
    time: '8:40 am, 20 Aug.'
  },
  {
    title: 'Stephen Smith has lodged an eligibliity check for $950,000 with ANZ.',
    type: 'blue',
    time: '4:06 pm, 19 Aug.'
  },
  {
    title: 'Mateusz Dembek has lodged an eligiblity check for $5,000,000 with ANZ.',
    type: 'grey',
    time: '2:56 pm, 19 Aug.'
  },
  {
    title: 'Joel Collins has lodged an eligiblity check for $500,000 with ANZ.',
    type: 'grey',
    time: '11:00 am, 18 Aug.'
  },
  {
    title: 'Mateusz Dembek has lodged an eligiblity check for $5,000,000 with ANZ.',
    type: 'grey',
    time: '12:00 am, 20 Aug.'
  },
];


const NewsFeed= () => (
  <main className="main dashboard">
    <div className="">
      <p className='items-to-review'>
        You have <span className='pill danger'>10</span> new items in total to review
      </p>

      <NewsFeedList list={listItems1} title='Product Updates' url='newsfeed/products/' maxItems={5}/>
      <NewsFeedList list={listItems2} title='Applications' url='newsfeed/applications/' maxItems={5}/>
      <NewsFeedList list={listItems3} title='Eligibility Checks' url='newsfeed/eligibility/' maxItems={5}/>
      <NewsFeedList list={listItems4} title='Reviews' url='newsfeed/reviews/' maxItems={5}/>
    </div>
  </main>
);

const ShowAll = ({title, list}) => (
  <main className="main dashboard">
    <div className="">
      <BackButton margin='-20px 0 20px 0'/>

      <NewsFeedList list={list} title={title} maxItems={100000} noShowAll/>

    </div>
  </main>
);

export const ShowAllProducts = () => <ShowAll title='Product Updates' list={listItems1}/>;
export const ShowAllApplications = () => <ShowAll title='Applications' list={listItems2}/>;
export const ShowAllEligibility = () => <ShowAll title='Eligibility Checks' list={listItems3}/>;
export const ShowAllReviews = () => <ShowAll title='Reviews' list={listItems4}/>;


export default NewsFeed;

const ListItem = ({item: {title, type, time} }) => {
  return (
    <div className={`list-item ${type}`}>
      <div className='dot'></div>
      {title}
      <div className='pull-right visible-on-hover' style={{bottom: '9px'}}>
        <span className='date' style={{verticalAlign: 'top', marginTop: '9px', display: 'inline-block'}}>{time}</span>
        <BtnComment/>
        { type !== 'grey' &&
          <BtnComplete>Mark Complete</BtnComplete>
        }
        
      </div>
    </div>
  );
};

class NewsFeedListBase extends Component {
  constructor() {
    super();
    this.state = {
      isExpanded: true,
    };
  }

  render() {
    const { title, list, noShowAll, url, maxItems } = this.props;
    const { isExpanded } = this.state;
    
    const n = list.length;
    const updates = list.filter(i => i.type !== 'grey').length;
    const moreItems = (n > maxItems) ? (n - maxItems) : 0;
    const visibleItems = (n > maxItems) ? maxItems : n;
    const collapsableHeight = visibleItems * 53 + (moreItems ? 40 : 0);
 
    return (
      <div className={`newsfeed-list-container ${!isExpanded ? 'closed' : ''}`}>
        <div className='header' onClick={() => !noShowAll && this.setState({ isExpanded: !isExpanded })}>
          <div className='pull-left'>
            {!noShowAll && (isExpanded ? <DownArrow/> : <UpArrow/>)} 
            {title} 
            <span className='scale-down'><span className='pill small danger'>{updates}</span></span>
          </div>
          {!noShowAll &&
            <div 
              className='pull-right' 
              onClick={() => this.props.history.push(url)}
              style={{bottom: '18px', cursor:'pointer'}}
            >
              Show All
            </div>
          }
        </div>
        <div className='collapsable-content' style={{
          height: `${isExpanded ? collapsableHeight : 0}px`,
          transition: `all ${collapsableHeight * 2}ms`,
        }}>
          {list.map((item, index) => (index < maxItems) ? <ListItem key={index} item={item} /> : null)}
          
          {(moreItems) &&
          <div className='footer'>
            +{moreItems} More New Items <span className='show-more' onClick={() => this.props.history.push(url)}>Show More →</span>
          </div>
          }
          
        </div>
        
      </div>
    );
  }
}

const NewsFeedList = withRouter(NewsFeedListBase);

