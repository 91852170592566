import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Heading, Label, ToggleContainer, BigBlueButton, Row, Column, PlainButton, Templates, Library, TemplateItems, 
  RequestColumn, ListItem, TemplateItem, LibraryRequest, BigTrashButton, ActionButtons, ButtonsLeft, Saved, Unsaved } 
  from './DocShared';
import { reduxForm, Form } from 'redux-form';
import { Input, RichTextArea, ToggleButton } from 'components/Form';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

const required = value => value ? null : 'This field is required';

let DocForm = ({ handleSubmit, onSubmit, saving, onChangeState, dirty, disabled, invalid, pristine, ...props }) => {

  useEffect(() => {
    onChangeState && onChangeState(dirty);
  }, [dirty]);

  return <Form onSubmit={handleSubmit(onSubmit)}>
    <Input name='name' validate={required}/>
    <Label style={{ marginTop: '25px' }}>Description</Label>
    <RichTextArea noButtons name='description' style={{ height: '165px'}} validate={required}/>
    {/* <Label style={{ marginTop: '15px' }}>Options</Label> */}
    <OptionsContainer>
      <ToggleContainer>
        <ToggleButton name='isPropertyDoc' valueChecked={1} valueUnchecked={0}/>
        Is a property document
      </ToggleContainer>
      <ToggleContainer>
        <ToggleButton name='isUrgent' valueChecked={1} valueUnchecked={0}/>
        Flag as urgent
      </ToggleContainer>
      <ToggleContainer>
        <ToggleButton name='isBankStatement' valueChecked={1} valueUnchecked={0}/>
        Bank statement
      </ToggleContainer>
    </OptionsContainer>
    
    <BigBlueButton disabled={disabled || invalid || pristine} type='submit' style={{ marginTop: '29px' }}><ButtonSpinner show={saving}/>Save Item</BigBlueButton>
  </Form>;
};

let TemplateForm = ({ handleSubmit, onSubmit, saving, onChangeState, dirty, invalid }) => {

  useEffect(() => {
    onChangeState && onChangeState(dirty);
  }, [dirty]);

  return <Form onSubmit={handleSubmit(onSubmit)}>
    <Label>Description</Label>
    <Input name='name'  validate={required}/>
    <BigBlueButton disabled={invalid} type='submit' style={{ marginTop: '29px' }}><ButtonSpinner show={saving}/>Save Item</BigBlueButton>
  </Form>;
};

DocForm = reduxForm({ enableReinitialize: true, })(DocForm);
TemplateForm = reduxForm({ enableReinitialize: true, })(TemplateForm);

export { TemplateForm, DocForm };

const OptionsContainer = styled.div`
  margin-top: 15px;
`;


