import React from 'react';
import styled from 'styled-components';

export const Row = styled.div`
  &:after {
    content: "";
    display: table;
    clear: both;
    margin: ${p => p.margin || '0'};
    padding: ${p => p.padding || '0'};
    width: ${p => p.width || '100%'};
  }
`;

export const Column = styled.div`
  position: relative;
  float: left;
  width: ${p => p.width || '100%'};
  min-width: ${p => p.minWidth || '0'};
  margin: ${p => p.margin || '0'};
  padding: ${p => p.padding || '0'};
`;

export const PullRight = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  float: right;
  padding: ${p => p.padding || '0'};
`;

export const PullLeft = styled.div`
  display: inline-flex;
  float: left;
`;