import React from 'react';

import { connect } from 'react-redux';
import Form from './Forms/MiniProposalForm';
import EmailForm from './Forms/MiniProposalEmailForm';

import Card from 'components/Styled/Card';
import { PageLoader } from 'components/Loader';

const MiniProposalView = ({ client }) => {

  if (!client.id) return (
    <PageLoader />
  );
  let miniproposal = (client.meta && client.meta.miniproposal) || {};
  miniproposal = {
    showSummary: miniproposal.showSummary,
    draftMode: !!miniproposal.draftMode,
    brokerId: miniproposal.brokerId,
    agreedToProceed: !!miniproposal.agreedToProceed,
  };
  //debugger;

  const EmailInitialValues = {
    expiry: 14,
    content: 'As discussed, we have prepared an online proposal for your lending situation. Please find a link to view this proposal below.\n\nNote that this proposal currently cannot be viewed on a mobile. Tablets, laptops and desktops should work fine.\n\nLet me know if you have any issues viewing this proposal or if you have any other questions.'
  };



  return (
    <Card margin="40px 0 0 0" >
      <div style={{ width: '720px', marginLeft: '40px'}}>
        <Form initialValues={{ client: { meta: { miniproposal }}}} agreeDate={miniproposal.agreedToProceed}/>
        <EmailForm initialValues={EmailInitialValues}/>
      </div>
    </Card>
  );
};

const stateToProps = state => ({
  client: state.clients.current,
});

export default connect(stateToProps)(MiniProposalView);


