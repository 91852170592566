import { takeEvery, call, put } from 'redux-saga/effects';

import {
  findDocuments,
  patchDocument,
  createDocument,
  findTemplates,
  patchTemplate,
  createTemplate,
  removeDocument,
  removeTemplate,
  createTemplateDoc,
  removeTemplateDoc,
  removeUserDocument,
  createUserDocument,
  patchUserDocument,
} from './api';

import {
  receiveFindDocuments,
  receivePatchDocument,
  receiveCreateDocument,
  receiveFindDocumentTemplates,
  receivePatchDocumentTemplate,
  receiveCreateDocumentTemplate,
  receiveDeleteDocumentTemplate,
  receiveDeleteDocument,

  REQUEST_CREATE_DOCUMENT,
  REQUEST_PATCH_DOCUMENT,
  REQUEST_FIND_DOCUMENTS,
  REQUEST_CREATE_DOCUMENT_TEMPLATE,
  REQUEST_PATCH_DOCUMENT_TEMPLATE,
  REQUEST_FIND_DOCUMENT_TEMPLATES,
  REQUEST_DELETE_DOCUMENT,
  REQUEST_DELETE_DOCUMENT_TEMPLATE,
  ADD_DOC_TO_TEMPLATE,
  REMOVE_DOC_FROM_TEMPLATE,
  REQUEST_REMOVE_USER_DOCUMENT,
  REQUEST_ADD_USER_DOCUMENT,
  REQUEST_PATCH_USER_DOCUMENT,
  receivePatchUserDocument,
  receiveAddUserDocument,
  receiveRemoveUserDocument,
} from './actions';


// ************** Documents **************

function* callGetDocuments({ payload }) {
  const result = yield call(findDocuments);
  yield put(receiveFindDocuments(result));
  if (payload)
    payload();
}

export function* getDocumentsSaga() {
  yield takeEvery(REQUEST_FIND_DOCUMENTS, callGetDocuments);
}

function* callPatchDocument({ payload: { data, callback } }) {
  let result = yield call(patchDocument, { data } );
  if (!result.error) {
    yield put(receivePatchDocument(result));
  }
  callback && callback(result);
}

export function* patchDocumentSaga() {
  yield takeEvery(REQUEST_PATCH_DOCUMENT, callPatchDocument);
}

function* callCreateDocument({ payload: { data, callback } }) {
  let result = yield call(createDocument, data);
  if (!result.error) {
    yield put(receiveCreateDocument(result));
  }
  callback && callback(result);
}

export function* createDocumentSaga() {
  yield takeEvery(REQUEST_CREATE_DOCUMENT, callCreateDocument);
}

function* callDeleteDocument({ payload: { id, callback } }) {
  let result = yield call(removeDocument, id);
  if (!result.error) {
    yield put(receiveDeleteDocument(id));
  }
  callback && callback(result);
}

export function* deleteDocumentSaga() {
  yield takeEvery(REQUEST_DELETE_DOCUMENT, callDeleteDocument);
}

// ************** Document Templates **************

function* callGetTemplates({ payload }) {
  const result = yield call(findTemplates);
  yield put(receiveFindDocumentTemplates(result));
  if (payload)
    payload();
}

export function* getTemplatesSaga() {
  yield takeEvery(REQUEST_FIND_DOCUMENT_TEMPLATES, callGetTemplates);
}

function* callPatchTemplate({ payload: { data, callback } }) {
  let result = yield call(patchTemplate, { data } );
  if (!result.error) {
    yield put(receivePatchDocumentTemplate(result));
  }
  callback && callback(result);
}

export function* patchTemplateSaga() {
  yield takeEvery(REQUEST_PATCH_DOCUMENT_TEMPLATE, callPatchTemplate);
}

function* callCreateTemplate({ payload: { data, callback } }) {
  let result = yield call(createTemplate, data);
  if (!result.error) {
    yield put(receiveCreateDocumentTemplate(result));
  }
  callback && callback(result);
}

export function* createTemplateSaga() {
  yield takeEvery(REQUEST_CREATE_DOCUMENT_TEMPLATE, callCreateTemplate);
}

function* callDeleteTemplate({ payload: { id, callback } }) {
  let result = yield call(removeTemplate, id);
  if (!result.error) {
    yield put(receiveDeleteDocumentTemplate(id));
  }
  callback && callback(result);
}

export function* deleteTemplateSaga() {
  yield takeEvery(REQUEST_DELETE_DOCUMENT_TEMPLATE, callDeleteTemplate);
}

function* callRemoveTemplateDoc({ payload: { documentId, templateId, callback } }) {
  let result = yield call(removeTemplateDoc, { documentId, documentTemplateId: templateId });
  callback && callback(result);
}

export function* removeTemplateDocSaga() {
  yield takeEvery(REMOVE_DOC_FROM_TEMPLATE, callRemoveTemplateDoc);
}


function* callAddTemplateDoc({ payload: { documentId, templateId, callback } }) {
  let result = yield call(createTemplateDoc, { documentId, documentTemplateId: templateId });
  callback && callback(result);
}

export function* addTemplateDocSaga() {
  yield takeEvery(ADD_DOC_TO_TEMPLATE, callAddTemplateDoc);
}

/************** User Document Requests ***************/


function* callPatchUserDocument({ payload: { data, callback } }) {
  yield put(receivePatchUserDocument({ data }));
  data.sessionId = sessionStorage.tabID;
  let result = yield call(patchUserDocument, { data } );
  callback && callback(result);
}

export function* patchUserDocumentSaga() {
  yield takeEvery(REQUEST_PATCH_USER_DOCUMENT, callPatchUserDocument);
}

function* callUserCreateDocument({ payload: { data, callback } }) {
  yield put(receiveAddUserDocument({ data }));
  data.sessionId = sessionStorage.tabID;
  let result = yield call(createUserDocument, data);
  callback && callback(result);
}

export function* createUserDocumentSaga() {
  yield takeEvery(REQUEST_ADD_USER_DOCUMENT, callUserCreateDocument);
}

function* callDeleteUserDocument({ payload: { id, userId, callback } }) {
  yield put(receiveRemoveUserDocument( { id, userId } ));
  let result = yield call(removeUserDocument, id);
  callback && callback(result);
}

export function* deleteUserDocumentSaga() {
  yield takeEvery(REQUEST_REMOVE_USER_DOCUMENT, callDeleteUserDocument);
}

