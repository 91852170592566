import React, { useState, useContext } from 'react';

import { modalContext, ModalBox } from 'components/Modal/index.jsx';

import { ButtonPrimary as Button } from 'components/Styled/Button'
import { PullRight, Row, Column, PullLeft } from 'components/Styled/Layout';

import { Input } from 'components/Form';

import { reduxForm, Form } from 'redux-form';
import Label from '../Styled/Label';

import { required } from 'util/validation'; 

const randomWidth = () => Math.random() * 600 + 400 + 'px';
const randomHeight = () => Math.random() * 300 + 40 + 'px';

export default () => { 

  const { openModal, closeModal } = useContext(modalContext);
  const [ name, setName ] = useState({ first: '', last: '' });

  const onSubmit = ({ name: { first, last } }) => {
    setName({ first, last });
    closeModal();
  }
  
  const userName = name.first ? 
    (name.first + ' ' + name.last) 
    : 
    'yet to be revealed';

  const Modal1Options = { 
    component: Modal1, 
    props: { 
      width: '600px', 
      height: '200px', 
      number: 1 
    }
  };

  const Modal2Options = { 
    component: Modal2, 
    props: { 
      onSubmit,
      initialValues: { name } 
    } 
  };
  
  return (
    <main>
      <Row margin='0 0 30px 0'>
        <Button onClick={() => openModal(Modal1Options)}>Simple Multiple Modal Example</Button>
      </Row>
      <Row margin='0 0 30px 0'>
        <Button onClick={() => openModal(Modal2Options)}>Simple Modal Form Example</Button>
      </Row>
      <span>Your name is <strong>{userName}</strong>!</span>
    </main>
  )
}


const Modal1 = ({ modal, width, height, number }) => {

  const nextModalOptions = {
    component: Modal1, 
    props: { 
      width: randomWidth(), 
      height: randomHeight(), 
      number: number + 1 
    } 
  };

  return (
    <ModalBox width={width}>
      <Row margin={`0 0 ${height} 0`}>
        <h2>Modal No. {number}</h2>
      </Row>
      <Row>
        <PullLeft>
          <Button autofocus="true" onClick={() => modal.open(nextModalOptions)}>Open Another Modal</Button>
        </PullLeft>
        <PullRight>
          <Button onClick={modal.close}>Close</Button>
        </PullRight>
        <PullRight padding='0 20px 0 0'>
          <Button onClick={modal.closeAll}>Close All</Button>
        </PullRight>
      </Row>
    </ModalBox>
  )
};

let Modal2 = ({ modal, handleSubmit, invalid, pristine }) => {

  return (
    <ModalBox width='500px'>
      <Form onSubmit={handleSubmit}>
        <Row margin='0 0 30px 0'>
          <h1>Form Modal Example</h1>
        </Row>
        <Row margin='0 0 30px 0'>
          <Column width='50%' padding='0 10px 0 0'>
            <Label>First Name</Label>
            <Input name='name.first' autofocus="true" validate={required}/>
          </Column>
          <Column width='50%' padding='0 0 0 10px'>
            <Label>Last Name</Label>
            <Input name='name.last' validate={required}/>
          </Column>
        </Row>
        <Row>
          <PullLeft>
            <Button type='submit' disabled={invalid || pristine}>Submit</Button>
          </PullLeft>
          <PullRight>
            <Button type='button' onClick={modal.close}>Close</Button>
          </PullRight>
        </Row>
      </Form>
    </ModalBox>
  )
}

Modal2 = reduxForm({form: 'modalForm'})(Modal2);

