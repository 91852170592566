import { takeEvery, call, put } from 'redux-saga/effects';

import {
  getLenders,
  patchLender,
  createLender,
  deleteLender
 } from './api';

import {
  receiveGetLenders,
  receiveUpdateLender,
  receiveCreateLender,
  receiveDeleteLender,

  REQUEST_GET_LENDERS,
  REQUEST_CREATE_LENDER,
  REQUEST_UPDATE_LENDER,
  REQUEST_DELETE_LENDER
} from './actions';

function* callGetLenders({ payload }) {
  const result = yield call(getLenders);
  yield put(receiveGetLenders(result));
  payload();
}

export function* getLendersSaga() {
  yield takeEvery(REQUEST_GET_LENDERS, callGetLenders)
}

function* callCreateLender({ payload: {data, callback} }) {
  const result = yield call(createLender, data);
  yield put(receiveCreateLender(result));
  if (callback)
    callback();
}

export function* createLenderSaga() {
  yield takeEvery(REQUEST_CREATE_LENDER, callCreateLender);
}

function* callPatchLender({ payload: {data, callback} }) {
  const result = yield call(patchLender, data);
  yield put(receiveUpdateLender(result));
  if (callback)
    callback();
}

export function* patchLenderSaga() {
  yield takeEvery(REQUEST_UPDATE_LENDER, callPatchLender);
}

function* callDeleteLender({ payload: {id, callback} }) {
  const result = yield call(deleteLender, id);
  yield put(receiveDeleteLender(result))
  if (callback)
    callback();
}

export function* deleteLenderSaga() {
  yield takeEvery(REQUEST_DELETE_LENDER, callDeleteLender);
}