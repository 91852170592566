import React from "react";
import styled, { css } from 'styled-components';
import formatNumber from '../utils/formatNumber';

const Property = ({ tabId, loan, isView, user }) => {
  
  let property = user.meta.miniproposal.properties[tabId].propertyDetail ?? {};
  property = {...property, loanAmount: formatNumber(loan.loanAmount)};

  const loanIsCurrent = !!property.loanAmountEnabled && property.loanAmountEnabled === 1;

  if (!isView) return null;

  return (
    <ProposedOptionsContainer>
      <CenterContent>
        <Header style={{ marginTop: '0'}} marginBottom='5px'>
          <Span>{property.title}</Span>
        </Header>
      </CenterContent>
      {property.addressEnabled ?
        <CenterContent>
          <Subtitle>{property.address}</Subtitle>
        </CenterContent>
        : ""}
      <PropertyDetails className="property-details">
        <div className="left" style={{ marginBottom: '25px'}}>
          {property.image ?
            <img src={require('../img/proposal/house.png')} alt="house" />
            :
            <img src={require('../img/proposal/apartment.png')} alt="apartment" />
          }
        </div>
        <div className="right">
        <div className="container-right">
          <RateContainer icon={require('../img/elements/repayment.png')} >
          <Value><span>$</span>{property.value}</Value>
            <div class="value-description">Approximate Value</div>
          </RateContainer>
          <RateContainer icon={require('../img/elements/comparison-rate.png')} >
            <Value>
              <span>$</span>{property.loanAmount}
            </Value>
            <div class="value-description">
              {loanIsCurrent ? 'Current' : 'New'} Loan Amount
            </div>
          </RateContainer>
        </div>
        </div>
      </PropertyDetails>
    </ProposedOptionsContainer>
  );
};

export default Property;

export const Header = styled.h2`
  width: 100%;
  outline: none;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-bottom: ${p => p.marginBottom ?? '10px'} !important;
  ${p => !p.isPDF && css`
    margin-top: 25px;
  `}
  border-color: #FFF;
  transition: border-color 0.4s ease-in-out;

    &.empty {
      border: solid 1px;
      border-radius: 6px;
      border-color: #EB5846;
    }
  }

`;

export const Subtitle = styled.h3`

  outline: none;
  min-width: 80px;
  padding: 3px;
  display: inline-block;
  text-align: center;
  color: #727C8F;
  font-size: 10px !important;
  border: solid 1px;
  border-color: #FFF;
  transition: border-color 0.4s ease-in-out;

    &.empty {
      border: solid 1px;
      border-radius: 6px;
      border-color: #EB5846;
    }
  }

`;

const Span = styled.span`
  display: inline-block;
  outline: none;
  min-width: 50px;
  font-weight: 400;
`;

const Value = styled.div`
  outline: none;
  font-size: 20px;
  font-weight: 700;
`;

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PropertyDetails = styled.div`
`;

const ProposedOptionsContainer = styled.div`
  position: relative;
  max-width: 500px;
  
  /* margin: 0 auto 0 auto;
  
  ${p => !p.isPDF && css`
    margin: 25px auto 0 auto;
  `} */

  
  transition: max-height 0.2s;
  max-height: 1000px;

  .property-details {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    .left, .right {
      display: block;
      margin-bottom: 35px;
      display: block;
      /* width: 100%; */
      min-width: 200px;
    }

    .left {
      margin-right: 20px;

      img {
        display: block;
        object-fit: contain;
        /* width: 100%; */
        min-width: 140px;
        max-width: 140px;
        margin: 0 auto;
      }
    }

    .right {

      .container-right {
        padding-left: 12px;
        width: 200px;
        margin: 0 auto;
      }
    }
  }
`;

const RateContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  padding-left: 43px;
  background-image: url(${props => props.icon});

  &:last-of-type {
    margin-bottom: 0;
  }

  .value {
    font-size: 24px;
    font-weight: 700;
  }

  .value-description {
    font-size: 10px;
    font-weight: 400;
    color: #727C8F;
    margin-top: 7px;
  }
`;


