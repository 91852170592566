// src/views/Accounts/SignIn.js

import React from "react";

import LoginForm from '../../components/Accounts/LoginForm';
import Navigation from "../../components/Navigation/Navigation";
import OrDivider from '../../components/OrDivider';


const SignIn = () => (
    <div>
      <Navigation state="unactive" />
      <main className="user-authentication">
        <div className="modal single">
          <h2>Sign In</h2>
          <div className="">
            <LoginForm />
          </div>
          
        </div>
      </main>
  </div>
);

export default SignIn;