import React from 'react';
import styled from 'styled-components';


const Label = styled.div` 
  font-family: 'Lato';
  font-weight: 700;
  font-size: 14px;
  color: #101922;
  letter-spacing: 0;
  margin-bottom: ${p => p.marginBottom || '12px'};
`;

export default Label;