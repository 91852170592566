class Debouncer {
  constructor() {
    this.currentAction = null;
  }
  run(fn, ms) {
    clearTimeout(this.currentAction);
    this.currentAction = setTimeout(fn, ms);
  }
}

export default Debouncer;