import React, { Component } from 'react';

import { reduxForm, Form, SubmissionError  } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';
import { ButtonPlain } from 'components/Styled/Button';

import { Input } from 'components/Form';
import { Column as BaseColumn } from 'components/Styled/Layout';
import { ToggleField as CheckBox } from 'components/Styled/CheckBox';
import { ImgWand, Row, Column } from '../ViewClient';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { verificationService } from 'modules';
import { requestPatchUser } from 'modules/clients/actions';
import { emailService } from 'modules';

class ChangePassword extends Component {

  state = {
    resetPasswordLoading: false,
    magicLinkLoading: false,
    resetPasswordSent: false,
    magicLinkSent: false,
    passwordSubmitting: false,
    passwordChanged: false,
  }

  sendResetPasswordEmail = async () => {
    if (!this.props.client.email) return;
    this.setState({resetPasswordLoading: true});
    await verificationService.create({
      type: 'forgot',
      email: this.props.client.email,
    });
    this.setState({resetPasswordLoading: false, resetPasswordSent: true});
  }

  sendMagicLink = async () => {
    if (!this.props.client.email) return;
    this.setState({magicLinkLoading: true});
    await verificationService.create({
      type: 'create-login-link',
      email: this.props.client.email,
    });
    this.setState({ magicLinkLoading: false, magicLinkSent: true });
  }

  setPassword = async ({ password, sendEmail }) => {
    const { client, requestPatchUser } = this.props;
    return new Promise((resolve, reject) => {
      if (!password) return reject(new SubmissionError({
        password: 'No password entered',
      }));
      requestPatchUser({
        data: { id: client.id, password },
        callback: () => {
          if (sendEmail) {
            emailService.create({
              type: 'admin-reset-password',
              user: client,
              password: password,
            });
          }
          return resolve();
        }
      });
    });
    
  }

  render() {
    const { resetPasswordLoading, magicLinkLoading, magicLinkSent, resetPasswordSent } = this.state;
    const { error, submitting, submitSucceeded } = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit(this.setPassword)} autoComplete="off" >
        <input hidden autoComplete="off" />
        <Column width='50%'>
          <Label>Password</Label>
          <Input type='text' name='password' data-Ipignore='true'/>
        </Column>

        <Column width='50%' style={{ marginTop: '26px' }}>
          <ButtonPlain type='button' width='100%' onClick={this.sendMagicLink}>
            <ButtonSpinner show={magicLinkLoading} color='#919191'/>
            <ImgWand />
            {magicLinkSent ? 'Secure Link Sent' : 'Email Client Secure Link'}
          </ButtonPlain>
        </Column>

        <Column width='50%'>
          <BaseColumn width='60%'>
            <ButtonPlain type='submit' width='100%'>
              <ButtonSpinner show={submitting} color='#919191'/>
              {submitSucceeded ? 'Password Changed' : 'Set Password Manually'}
            </ButtonPlain>
          </BaseColumn>
          <BaseColumn width='40%' style={{ padding: '10px 0 0 20px' }}>
            <CheckBox title='Email Client' name='sendEmail' checked />
          </BaseColumn>
        </Column>

        <Column width='50%'>
          <ButtonPlain type='button' onClick={this.sendResetPasswordEmail} width='100%'>
            <ButtonSpinner color='#919191' show={resetPasswordLoading}/>
            {resetPasswordSent ? 'Reset Password Email Sent' : 'Reset Password Automatically'}
          </ButtonPlain>
        </Column>
        <Row>
          <Column width='100%'>
            <Divider width='100%' />
          </Column>
        </Row>
      </Form>
    );
  }
}

ChangePassword = reduxForm({
  form: 'password',
  initialValues: {
    sendEmail: true,
  }
})(ChangePassword);

const stateToProps = state => ({
  client: state.clients.current,
});

const dispatchToProps = dispatch => 
  bindActionCreators({
    requestPatchUser,
  }, dispatch);

export default connect(stateToProps, dispatchToProps)(ChangePassword);