import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route, Switch, Link, withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ActionQueue, getQueryVariables } from 'util/index';
import { requestGetLenders } from 'modules/lenders/actions';

import imgEditPen from 'img/button/edit-pen.png';

import BackButton from 'components/Common/BackButton';


import { applicationService } from 'modules';

import { showUnfinished } from 'modules';

import {
  requestGetApplication,
  requestMoveApplication,
  requestUpdateApplications,
  setCurrentDeal,
} from 'modules/applications/actions';

import { requestUser } from 'modules/clients/actions';

import { TopBar, SideBar } from 'components/ApplicationsEligibility';
import { ApplicationsDashboard } from '../Shared/Dashboard/Dashboard';
import ApplicationsApplicants from '../Shared/Applicants/ApplicantsView';
import ApplicationsSecurities from '../Shared/Securities/SecuritiesView';
import ClientSearch from '../Shared/Applicants/ClientSearch';
import ApplicationsFunding from '../Shared/Funding/FundingView';
import { ApplicationsFiles } from '../Shared/Files/FilesView';
import SubmissionView from './SubmissionView';
import DealNotes from './DealNotes';
import SupportingDocs from '../Clients/SupportingDocs';

import { PageLoader } from 'components/Loader';
import DetailsView from 'views/Clients/DetailsView';
import { Heading } from 'shared/PDF/Shared';
import Loading from 'components/Styled/ImageLoading';

const Dashboard = props => <ApplicationsDashboard isEligibilty={true} {...props}/>;
const Files = props => <ApplicationsFiles url='/eligibility' {...props}/>;

const ContentContainer = styled.div`
      flex: 1;
      background: #f6f6fa;
			padding: 40px;
			overflow: auto;
      width: calc(100% - 300px);
      min-height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  min-height: 100%;
`;


const ClientHeading = styled.h2`
  font-weight: bold;
  font-size: 17px;
`;

const ClientView = ({ location, history }) => {

  //const clientLoading = useSelector(state => state.clients?.userLoading);
  const { id } = getQueryVariables(location.search);
  const applications = useSelector(state => state.applications);
  if (!(applications && applications.list)) return null;
  const application = applications.list[parseInt(id)];
  if (!application || !application.client) return <PageLoader />;
  //if (clientLoading) return <PageLoader />;
  
  return <>
    <BackButton margin='0 0 20px 0' onClick={() => history.push('./notes?id=' + id)} />
    <ClientHeading>Client Details</ClientHeading>
    <div style={{ width: '880px'}}>
      <DetailsView excludeApplications={true} client={application.client}/>
    </div>
  </>;
};

const FilesView = ({ location, history }) => {

  const applications = useSelector(state => state.applications);
  if (!(applications && applications.list)) return null;
  const { id } = getQueryVariables(location.search);
  const application = applications.list[parseInt(id)];

  return <>
    <BackButton margin='0 0 0 0' onClick={() => history.push('./notes?id=' + id)} />
    <SupportingDocs client={application.client}/>
  </>;
};

const DealNotesPage = props => {
  let { id } = getQueryVariables(props.location.search);
  const deal = useSelector( state => state.applications.list[parseInt(id)]);
  if (!deal.client?.id) return <Loading />;
  return <DealNotes {...props} initialValues={{ notepad: deal && deal.notepad }}/>;
};

const Routes = ({ viewportRef }) => (
  <Switch>

    <Route exact path="/eligibility/view" component={DealNotesPage}/>
    <Route exact path="/eligibility/view/applicants" component={ApplicationsApplicants}/>
    <Route exact path="/eligibility/view/applicants/search" component={ClientSearch}/>
    <Route exact path="/eligibility/view/securities" component={ApplicationsSecurities}/>
    <Route exact path="/eligibility/view/funding" component={ApplicationsFunding}/>
    <Route path="/eligibility/view/files" component={FilesView}/>
    <Route exact path="/eligibility/view/notes" component={DealNotesPage}/>
    <Route exact path="/eligibility/view/submission" component={props => <SubmissionView viewportRef={viewportRef} {...props}/>}/>
    <Route exact path="/eligibility/view/client" component={ClientView}/>

    <Route exact path="/deals/view" component={DealNotesPage}/>
    <Route exact path="/deals/view/applicants" component={ApplicationsApplicants}/>
    <Route exact path="/deals/view/applicants/search" component={ClientSearch}/>
    <Route exact path="/deals/view/securities" component={ApplicationsSecurities}/>
    <Route exact path="/deals/view/funding" component={ApplicationsFunding}/>
    <Route path="/deals/view/files" component={FilesView}/>
    <Route exact path="/deals/view/notes" component={DealNotesPage}/>
    <Route exact path="/deals/view/submission" component={props => <SubmissionView viewportRef={viewportRef} {...props}/>}/>
    <Route exact path="/deals/view/client" component={ClientView}/>
    
    <Route exact path="/referrals/view" component={DealNotesPage}/>
    <Route exact path="/referrals/view/applicants" component={ApplicationsApplicants}/>
    <Route exact path="/referrals/view/applicants/search" component={ClientSearch}/>
    <Route exact path="/referrals/view/securities" component={ApplicationsSecurities}/>
    <Route exact path="/referrals/view/funding" component={ApplicationsFunding}/>
    <Route path="/referrals/view/files" component={FilesView}/>
    <Route exact path="/referrals/view/notes" component={DealNotesPage}/>
    <Route exact path="/referrals/view/submission" component={props => <SubmissionView viewportRef={viewportRef} {...props}/>}/>
    <Route exact path="/referrals/view/client" component={ClientView}/>

    <Route exact path="/qualifiers/view" component={DealNotesPage}/>
    <Route exact path="/qualifiers/view/applicants" component={ApplicationsApplicants}/>
    <Route exact path="/qualifiers/view/applicants/search" component={ClientSearch}/>
    <Route exact path="/qualifiers/view/securities" component={ApplicationsSecurities}/>
    <Route exact path="/qualifiers/view/funding" component={ApplicationsFunding}/>
    <Route path="/qualifiers/view/files" component={FilesView}/>
    <Route exact path="/qualifiers/view/notes" component={DealNotesPage}/>
    <Route exact path="/qualifiers/view/submission" component={props => <SubmissionView viewportRef={viewportRef} {...props}/>}/>
    <Route exact path="/qualifiers/view/client" component={ClientView}/>

    <Route exact path="/in-progress/view" component={DealNotesPage}/>
    <Route exact path="/in-progress/view/applicants" component={ApplicationsApplicants}/>
    <Route exact path="/in-progress/view/applicants/search" component={ClientSearch}/>
    <Route exact path="/in-progress/view/securities" component={ApplicationsSecurities}/>
    <Route exact path="/in-progress/view/funding" component={ApplicationsFunding}/>
    <Route path="/in-progress/view/files" component={FilesView}/>
    <Route exact path="/in-progress/view/notes" component={DealNotesPage}/>
    <Route exact path="/in-progress/view/submission" component={props => <SubmissionView viewportRef={viewportRef} {...props}/>}/>
    <Route exact path="/in-progress/view/client" component={ClientView}/>

    <Route exact path="/new-leads/view" component={DealNotesPage}/>
    <Route exact path="/new-leads/view/applicants" component={ApplicationsApplicants}/>
    <Route exact path="/new-leads/view/applicants/search" component={ClientSearch}/>
    <Route exact path="/new-leads/view/securities" component={ApplicationsSecurities}/>
    <Route exact path="/new-leads/view/funding" component={ApplicationsFunding}/>
    <Route path="/new-leads/view/files" component={FilesView}/>
    <Route exact path="/new-leads/view/notes" component={DealNotesPage}/>
    <Route exact path="/new-leads/view/submission" component={props => <SubmissionView viewportRef={viewportRef} {...props}/>}/>
    <Route exact path="/new-leads/view/client" component={ClientView}/>
  </Switch>
);



class ApplicationView extends Component {

  constructor() {
    super();

    this.state = {
      actionQueue: new ActionQueue(),
      application: null,
      client: null,
      clientLoading: false,
    };

    this.viewportRef = React.createRef();
  }

  componentDidMount() {
    const { application, client } = this.props;
    if (!showUnfinished) {
      const { id } = getQueryVariables(this.props.location.search);
      this.props.setCurrentDeal(parseInt(id));  
      // if (!this.props.location.pathname.includes('submission')) {
      //   this.props.history.push('view/submission?id=' +  id);
      // }
    }

    this.getApplications();
    this.getLenders();
      
  }

  componentDidUpdate(prevProps, prevState) {
    const { application, requestUser, client } = this.props;
    //debugger;
    if (!this.state.clientLoading && 
      application &&
      (!client || client.id  !== application.userId))
    {
      this.setState({ clientLoading: true });
      requestUser({ query: application.userId, callback: () => {
        this.setState({ clientLoading: false });
      }});
    }
  }

  getApplications = () => {
    const { requestGetApplication, applications, requestUser, application, client } = this.props;
    const { id } = getQueryVariables(this.props.location.search);

    requestGetApplication(id);

    if (!this.state.clientLoading && 
      application &&
      (!client || client.id  !== application.userId))
    {
      this.setState({ clientLoading: true });
      requestUser({ query: application.userId, callback: () => {
        this.setState({ clientLoading: false });
      }});
    }
  }

  changeApplication = mods => {
    const { application } = this.props;
    const newApp = {...application, ...mods};
    this.props.requestUpdateApplications(newApp);
  }

  getLenders = () => {
    if (!( this.props.lenders && this.props.lenders[0])) {
      this.props.requestGetLenders(() => 
        this.setState({ lendersLoaded: true })
      );
    } else {}
  }

  changeApplication = mods => {
    const { application } = this.props;
    const newApp = {...application, ...mods};
    this.setState({ application: newApp });
    this.props.requestUpdateApplications(newApp);
  }

  render() {
    const { applications, application, location, page } = this.props;
    

    let lenders = [];
    if (application && application.products) {
      const lenderIds = [...new Set(application.products.map(p => p.bank))];
      lenders = lenderIds
        .map(id => (  this.props.lenders ?? []).find(l => l.id === id))
        .filter(lender => !!lender);
    }

    let lenderId = application?.lenderId;
    if (!lenderId) {
      const product = application?.products?.[0];
      if (product) lenderId = product.bank;
    }

    let lender = lenderId === 'other' ? { id: 'other', name: application.lenderName } : (this.props.lenders ?? []).find(l => l.id === lenderId);
    if (lenderId) {
      if (!lender) {
        lender = { name: application.products?.[0]?.bank_name };
      }
      lenders = [ lender ];
    }

    //if (!( lenders && lenders[0])) return null;
    if (!(application && applications && applications.list)) return (
      <main className="main application-edit" ref={this.viewportRef}>
        <PageLoader />
      </main>
    );
    let client = (this.props.client.id === application.userId) ? this.props.client : application.client;
    console.log({ application });
    return (

      <main className="main application-edit" ref={this.viewportRef}>
        <TopBar 
          application={application} 
          applications={applications} 
          lenders={lenders}
        />

        <FlexContainer>
          <SideBar
            client={client} 
            application={application}
            page={page}
            rootPath={`/${this.props.page}/view`}
            currentPath={location.pathname} 
            changeApp={this.changeApplication}
            lender={lender}/>
          
          <ContentContainer>
            <Routes viewportRef={this.viewportRef}/>
          </ContentContainer>
        </FlexContainer>
      </main>
    );
  }
}



const mapStateToProps = (state, props) => {
  const { location } = props;
  const { applications } = state; 
  if (!(applications && applications.list) || !(state.lenders && state.lenders.list)) return {};
  const { id } = getQueryVariables(location.search);
  const application = applications.list[parseInt(id)];

  return {
    application,
    lenders: state.lenders.list,
    applications,
    client: state.clients.current,
  };
};

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestUpdateApplications,
    requestGetApplication,
    requestMoveApplication,
    requestUser,
    requestGetLenders,
    setCurrentDeal,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (ApplicationView);

export const EditButton = ({ to, ...props }) => (
  <Link to={to || ''} {...props}>
    <img
      width={12}
      height={12}
      src={imgEditPen} 
      className='edit-pen'
    />
  </Link>
  
);
