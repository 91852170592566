import React from 'react';
import styled from 'styled-components';
import TextInput from 'components/Styled/TextInput';


export const LabelledInputDiv = styled(TextInput)`
  padding-right: 0 !important;
  width: ${p => p.width || '100%'};
  height: ${p => p.height || '40px'} !important;

  &:focus-within {
    border: 1px solid #2291FF;
    box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20);
  }

  &:before {
    position: relative;
    font-size: ${p => p.fontSize || '14px'};
    color: ${p => p.color || '#727C8F'};
    display: inline;
    content: ${({ label }) => `"${label}"`};
  }
  &.disabled {
    background: #ebebe4 !important;
  }
`;

const InvisibleInput = styled.input`
  font-family: Lato;
  background: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 0 0 10px;
  box-shadow: none !important;
  height: ${p => p.height || '38px'} !important;
  width: calc(100% - 15px);
`;

const LabelledInput = ({ label, width, disabled, height, fontSize, heigh, input, className, ...props}) => {
  return (
    <LabelledInputDiv as='div' label={label} width={width} className={(disabled && 'disabled') + className} fontSize={fontSize} height={heigh}>
      <InvisibleInput type='text' {...props} {...input} disabled={disabled} height={heigh}/>
    </LabelledInputDiv>
  );
};

export default LabelledInput;