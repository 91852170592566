import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { DragDropContext } from 'react-beautiful-dnd';
import { modalContext as ModalContext, ModalBox } from 'components/Modal/index.jsx';

import { ActionQueue } from 'util/index';
import CollapsableCard from 'components/ApplicationsEligibility/CollapsableCard';
import DragToScroll from 'components/Common/DragToScroll';

import { emailService } from 'modules';
import moment from 'moment';

import {
  requestMoveApplication,
  requestUpdateApplications,
} from 'modules/applications/actions';
import html from 'util/html';
import SendSmsModal from './Modals/SendSmsModal';
import SmsSentModal from './Modals/SmsSentModal';
import SendEmailModal from './Modals/SendEmailModal';


const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestMoveApplication,
    requestUpdateApplications,
  }, dispatch);

const mapStateToProps = ({ applications, user }) => ({ applications, user });

class StageListsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragCancelled: false,
    };
    
    this.dragLocation = null; 
    this.isDragging = false;
    this.dragFrom = null;
  }
  
  componentDidMount() {
    // checks to see if a drag has been cancelled so it can play the cancel animation
    window.addEventListener('mouseup', this.checkIfDragCancelled);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.checkIfDragCancelled);
  }

  render() {
    
    const { applications, searchFilter, url, page, sortBy } = this.props;

    let sortList = sortByDate('currentTaskDueDate');
    if (sortBy === 'due-date') sortList = sortByDate('dueDate');
    if (sortBy === 'loan-amount') sortList = sortByValue('loanAmount');
    if (sortBy === 'offset-amount') sortList = sortByValue('offsetAmount');
    if (sortBy === 'referrals') sortList = sortByValue('referrerId');
    
    const { isCollapsed } = this.state;
    //console.log(applications.stages);
    //debugger;
    return (
      <ModalContext.Consumer>
        {(modalContext) => {
          return <>
            <DragDropContext 
              onDragEnd={this.onDragEnd(modalContext)} 
              onDragUpdate={this.onDragUpdate}
              onDragStart={() => this.isDragging = true}
            >
            
              <DragToScroll
                page={page}
                horizontal
                exclude={['.task *', '.task']}
                style={DragToScrollStyle}>
                {applications.stages && Object.values(applications.stages)
                  .filter(s => s.page === page)
                  .sort((a, b) => a.stageIndex > b.stageIndex ? 1 : -1)
                  .map((list, index) => {
                    const sortedList = (list.applications ?? []).sort(sortList);
                    return (
                      <CollapsableCard
                        key={index}
                        {...this.cardProps(list)}
                        data={searchFilter(sortedList)}
                        urlPath={`${url}/view`}
                        isCollapsed={this.props.isCollapsed[index]}
                        toggleCollapsed={() => this.props.toggleCollapsed(index)}
                        page={page}
                      />
                    )
                  })
                }
              </DragToScroll>
              
            </DragDropContext>
          </>;
        }}
      </ModalContext.Consumer>
    );
  }

  cardProps = list => ({
    title: list.name,
    name: list.id,
    dragCancelled: this.state.dragCancelled,
    isCancelTarget: this.dragFrom === list.name,
    stage: { ...list, applications: undefined },
  });

  checkIfDragCancelled = () => {
    if (this.isDragging && !this.dragLocation) {
      this.setState({ dragCancelled: true});
    }
  }

  onDragUpdate = async update => {
    this.dragLocation = update.destination;
    this.dragFrom = update.source.droppableId;
  }

  onDragEnd = modalContext =>
    async result => {
      const userProfile = this.props.user.profile;
      const brokerName = userProfile && `${userProfile.firstName} ${userProfile.lastName}`;

      this.isDragging = false;
      this.setState({ dragCancelled: false});
      const { actionQueue } = this;
      const { source, destination } = result;
      const { applications, requestMoveApplication, searchFilter, isCollapsed } = this.props;

      if (!destination) return;

      const srcId = parseInt(source.droppableId);
      const dstId = parseInt(destination.droppableId);
      const srcIndex = source.index;
      let dstIndex = destination.index;

      if (srcId === dstId && srcIndex === dstIndex) return;

      const stages = Object.values(applications.stages)
        .filter(x => x.page === this.props.page)
        .sort((a, b) => a.stageIndex > b.stageIndex ? 1 : -1)
        .map((stage, index) => ({ ...stage, isCollapsed: isCollapsed[index], index }));
      
      const srcList = stages.find(x => x.id === srcId);
      const dstList = stages.find(x => x.id === dstId);

      let srcApps = Object.values(srcList.applications);
      let dstApps = srcId === dstId ? srcApps : Object.values(dstList.applications);

      const srcApp = searchFilter(srcApps)[srcIndex];
      const filteredList = searchFilter(dstApps);

      if (srcId === dstId && srcApp.dueDate) {
        const now = moment().startOf('day');
        const dueDate = moment(srcApp.dueDate).startOf('day');
        const daysDue = dueDate.diff(now, 'days');
        if (daysDue <= 30) return;
      }

      if (srcId === dstId && srcApp.currentTaskDueDate) {
        const now = moment().startOf('day');
        const dueDate = moment(srcApp.currentTaskDueDate).startOf('day');
        const daysDue = dueDate.diff(now, 'days');
        if (daysDue <= 0) return;
      }

      if (dstList.isCollapsed) this.props.toggleCollapsed(dstList.index);

      // set due date if stage has one and the deal's is unset
      if (dstList.daysDue && !srcApp.dueDate) {
        const dueDate = moment().add(dstList.daysDue, 'days');
        this.props.requestUpdateApplications({
          id: srcApp.id, 
          meta: { 
            dueDate,
          }
        });
      }

      // add deal move to notes
      if (srcId !== dstId) {
        const body = html`The deal was moved from <b>${srcList.name}</b> to <b>${dstList.name}</b> ${(brokerName ? 'by ' + brokerName : '')}`;
        this.props.requestUpdateApplications({
          id: srcApp.id, 
          note: { 
            body, 
            brokerId: 'system'
          }
        });
      }
  
      let listOrder = null;
      let index = (srcId === dstId && dstIndex > srcIndex) ? dstIndex : dstIndex - 1;
      let before = filteredList[index];

      //if (before?.id === srcApp.id) before = filteredList[dstIndex];
      if (!before 
        || before.dueDate && moment(before.dueDate).diff(moment(), 'days') <= 30
        || before.currentTaskDueDate && moment(before.currentTaskDueDate).diff(moment(), 'days') <= 0) { // if no before it must be first in the list
        const first = dstList.applications.sort((a, b) => a.listOrder - b.listOrder)[0];
        if (!first) { // list is empty start at 0
          listOrder = 0;
        } else { // set below current lowest list item
          listOrder = (Math.floor(first.listOrder ?? 0)) - 1;
        }
      } else {
        const after = dstList.applications[before.index + 1];
        if (!after) { // at end of list set higher than 'before'
          listOrder = (Math.ceil(before.listOrder ?? 0)) + 1;
        } else { // it has been placed between 'before' and 'after' set it to their average
          listOrder = (before.listOrder + after.listOrder) / 2;
        }
      }

      this.props.requestUpdateApplications({
        id: srcApp.id, 
        list: dstId,
        listOrder,
      });

      if (dstList.emailEnabled) {
        await modalContext.openModal({
          component: SendEmailModal,
          props: {
            page: dstList.page,
            initialValues: { ...dstList, includeClient: true },
            deal: srcApp,
          }
        });
      }
      
      if (dstList.smsEnabled) {
        const openModal = props => {
          modalContext.closeAllModals();
          modalContext.openModal({
            component: SendSmsModal,
            props: {
              ...props,
              sendSms: props => {
                modalContext.closeAllModals();
                modalContext.openModal({
                  component: SmsSentModal,
                  props,
                });
              },
              goBack: openModal
            }
          });
        };
        openModal({
          page: dstList.page,
          initialValues: { ...dstList, includeClient: true },
          deal: srcApp,
        });
      }
    }
}

const DragToScrollStyle = {
  paddingLeft: '40px',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  top: '150px',
};

export const StageLists = connect(mapStateToProps, mapDispatchToProps) (StageListsBase);

const sortByDate = (key) =>
  (a, b) => {
    if (!a[key] && !b[key]) return 0;
    if (!a[key]) return 1;
    if (!b[key]) return -1;
    const dateA = moment(a[key]);
    const dateB = moment(b[key]);
    const dateAValid = dateA.isValid();
    const dateBValid = dateB.isValid();
    if (dateAValid && dateBValid) return moment(a[key]).valueOf() - moment(b[key]).valueOf();
    if (!dateAValid && !dateBValid) return 0;
    if (!dateAValid) return 1;
    if (!dateBValid) return -1;
    return 0;  
  };

  const sortByValue = (key) =>
    (a, b) => {
      if (!a[key] && !b[key]) return 0;
      if (!a[key]) return 1;
      if (!b[key]) return -1;
      return b[key] - a[key];
    };