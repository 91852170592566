import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import Context from './ContextOld';
import { ModalHide } from './old';
import Card from '../Styled/Card';
import { Row, PullRight } from '../Styled/Layout';
import { Scrollbars } from 'react-custom-scrollbars';
import imgClose from 'img/button/close-modal.png';



export class Modal extends Component {
  render() {

    const { children, modal, ...props } = this.props;

    return (
      <Context.Consumer>
        { modall => (
          <ModalContainer>
            <Scrollbars >
              <VerticalCenter>
                <ModalAnimation modal={modal} {...props}>
                  {children}
                </ModalAnimation>
              </VerticalCenter>
            </Scrollbars>
          </ModalContainer>
        )}
      </Context.Consumer>
    );
  }
}

class ModalAnimation extends Component {
  state = {
    padding: 0,
  };

  modalRef = React.createRef();

  componentDidMount() {
    const ref = this.modalRef.current;
    const windowHeight = window.innerHeight;
    const modalHeight = ref.clientHeight;
    
    if ((windowHeight - modalHeight) < 100) {
      this.setState({ padding: 100 });
    }
  }

  render() {
    const { modal, children, ...props } = this.props;

    let classes;
    switch (modal.transition) {
    case 'entering':
      classes = ['modal-slide-in', 'modal-fade-in'];
      break;
    case 'exiting':
    case 'exited':
      classes = ['modal-slide-out', 'modal-fade-out'];
      break;
    default: classes = ['modal-slide-in', 'modal-fade-in'];
    }

    const [boxClass, backClass] = classes;
    return (
      <ModalPadding amount={this.state.padding}>
        <ModalHide as={Background} className={backClass} />
        <ModalBox 
          className={boxClass} 
          {...props} 
          ref={this.modalRef}
          onKeyDown={({ keyCode }) => keyCode === 27 && modal.hide()}>
          <Row>
            <PullRight>
              <ModalHide as={BtnClose} />
            </PullRight>
          </Row>
          {children}
        </ModalBox>
      </ModalPadding>
    );
  }
}

const Background = styled.div`
  background: #213D58;
  opacity: 0.9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  @keyframes fade-in {

    0% {
      opacity: 0;
    }
    100% {
      transform: 0.9;
    }
  } 

  @keyframes fade-out {
    0% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;   
    }
  } 
`;

const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100% !important;
`;

const ModalPadding = styled.div`
  padding: ${p => p.amount + 'px' || '0'};
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;


const ModalBox = styled(Card)`
  position: relative;
  margin: auto;
  z-index: 200;

  @keyframes slide-in {

    0% {
      transform: translate(0px, 1500px);   
    }
    100% {
      transform: translate(0px, 0px);
    }
  } 

  @keyframes slide-out {
    0% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: translate(0px, 1500px);   
    }
  } 
`;


const BtnClose = styled.img.attrs({
  src: imgClose,
  width: 15,
  height: 15,
})`
  margin: -20px -20px 0 0;
  cursor: pointer;
`;


