import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SearchBar, ToggleButton } from 'components/Form';
import { requestFindProducts } from 'modules/products/actions';
import { requestPatchSelf } from 'modules/user/actions';
import { withRouter } from 'react-router';
import { MultiSelect } from 'components/Common/MultiSelect';
import { ButtonPlain } from 'components/Styled/Button';

import imgQuestionmark from 'img/icons/question-mark-white.png';
import Debouncer from 'util/debouncer';



import { 
  Table, 
  TableRow, 
  TableCell, 
  TableHeader, 
  SortableHeaderCellBlock as HeaderCell,
  ScrollableBody,
  tableStyle } 
from 'components/Table/TableBlock';
import Label from 'components/Styled/Label';
import ControlPanel from 'components/Form/ControlPanel';

const debouncer = new Debouncer();

const initialPageSize = 50;
const pageSize = 20;
const bottomLoadHeight = 1200;


class ProductSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      search: props.user.meta.productSearch,
      sortField: 'product_name',
      sortDirection: 1,
      overridenOnly: props.user.meta.overrideFilter,
    }
  }
  

  componentDidMount() {
    // console.log(this.state);
    // this.props.requestFindProducts({
    //   query: {
    //     limit: initialPageSize,
    //     skip: 0,
    //     search: this.state.search,
    //     sortField: 'product_name',
    //     sortDirection: 1,
    //     overridenOnly: this.state.overridenOnly,
    //   },
    //   callback: () => this.setState({ loaded: true })
    // });
    // console.log(this.props.user);
    this.setState({
      search: this.props.user.meta.productSearch || '',
      overridenOnly: this.props.user.meta.overrideFilter || '',
    }, () => {
      this.onSearch(this.props.user.meta.productSearch || '');

    });
  }

  onScroll = async e => {
    const products = this.props.products;
    const { loaded } = this.state;
    if (products.isComplete) return;

    const currentFilledLength = products.list.length * 70; 
    const scrollPosition = e.target.offsetHeight + e.target.scrollTop;

    if (scrollPosition < currentFilledLength - bottomLoadHeight) return;
    if (loaded) { //i.e. no currently pending calls to the DB
      this.setState({ loaded: false });
      const { search , sortField, sortDirection, overridenOnly } = this.state;

      //if user scrolls past currently loaded point increase page size to load to that point in only one call
      let limit = pageSize;
      if (((scrollPosition / 70) - products.list.length) > pageSize) 
        limit = ((scrollPosition / 70) - products.list.length) + 10; // + 10 to account for extra scrolling during load

      this.props.requestFindProducts({
        query: {
          limit,
          skip: products.list.length,
          search,
          sortField: sortField,
          sortDirection: sortDirection,
          overridenOnly,
        },
        callback: () => {
          this.setState({ loaded: true });
        }});  
    }
  };

  onSort = ({ field, direction }) => {
    const { search = '', overridenOnly } = this.state;
    this.setState({ loaded: false, sortField: field, sortDirection: direction });
    this.props.requestFindProducts({
      query: {
        limit: initialPageSize,
        skip: 0,
        search: search,
        sortField: field,
        sortDirection: direction,
        overridenOnly,
      },
      callback: () => this.setState({ loaded: true })
    });
  }

  onSearch = (search = '') => {
    const { sortField, sortDirection, overridenOnly } = this.state;
    this.setState({ loaded: false, search });
    this.props.requestFindProducts({
      query: {
        limit: initialPageSize,
        skip: 0,
        search: search,
        sortField: sortField,
        sortDirection: sortDirection,
        overridenOnly,
      },
      callback: () => this.setState({ loaded: true })
    });
  }

  // onToggleOverriden(e) {
  
  //   this.setState({ overridenOnly: e.target.checked }, () => {
  //     this.onSearch(this.state.search);
  //   });
  //   this.props.requestPatchSelf({
  //     data: {
  //       id: this.props.user.id,
  //       meta: {
  //         productOverrideFilter: e.target.checked
  //       }
  //     }
  //   });
  // }

  onChange = ({productSearch, overrideFilter}) => {
    if ((productSearch || null) != (this.state.search || null)) {
      this.setState({ search: productSearch }, () => debouncer.run(() => this.onSearch(productSearch), 300)); 
    } else {
      this.setState({ overridenOnly: overrideFilter }, () => this.onSearch(productSearch));
    }
  }

  render() {
    const products = this.props.products;   
    const LengthToAdd = products.list ? (products.total - products.list.length) * 70 : 0;

    return (
      <main className="main client-search" onScroll={this.onScroll} >
        <div className="client-search-container">
          <div style={{ margin: '0 20px 40px 20px' }}>
            <div style={{ margin: '0 0 px 40px', minWidth: '800px' }}>
            <ControlPanel callback={this.onChange.bind(this)}>
              <div className='inline-block'>
                <SearchBar
                  width='187px'
                  placeholder='Search'
                  name='productSearch'
                />
              </div>
              <div className='inline-block' style={{ margin: '6px 0 0 20px'}}>
                <Label>
                  <ToggleButton name='overrideFilter'/>
                  Overriden Products Only
                </Label>
              </div>
            </ControlPanel>
              
            </div>
          </div>

          <Table styling={tableStyle.checked} sortField='product_name' onSort={this.onSort} width='100%' headerWidth='100%'>
            <TableHeader>
              <TableRow >
                <HeaderCell width='16.66%' fieldName='bank_name'>Bank</HeaderCell>
                <HeaderCell width='33.33%' fieldName='product_name'>Name</HeaderCell>
                <HeaderCell width='11.11%' fieldName='maximum_lvr_own_occ'>Purpose</HeaderCell>
                <HeaderCell width='11.11%' fieldName='initial_ir_type'>Payment Type</HeaderCell>
                <HeaderCell width='11.11%' fieldName='iir'>Interest Rate</HeaderCell>
                <HeaderCell width='16.66%' fieldName='maximum_loan_amount'>Max Loan Size</HeaderCell>
              </TableRow>
            </TableHeader>
            <ScrollableBody topPos='170px' style={{width: '100%'}}>
              {products.list && !!products.list.length &&
                products.list.map((p) => (
                  <ProductRow product={p} key={p.id}/>
                ))
              }
              <div style={{height: `${LengthToAdd}px`}}></div>
            </ScrollableBody>
          </Table>
        </div>
      </main>
    );
  }
}

class ProductRow extends React.PureComponent {
  render() {
    const p = this.props.product;
    let type = 'Either';
    if (p.initial_ir_type === 'F') type = 'Fixed';
    if (p.initial_ir_type === 'V') type = 'Variable';
    if (p.initial_ir_type === 'IV') type = 'Intro Variable';
    return (
      <TableRow style={{width: '100%'}} onClick={() => this.props.history.push('/products/view?id=' + p.id)}>
        <TableCell width='16.66%'>{p.bank_name}</TableCell>
        <TableCell width='33.33%'>{p.product_name}</TableCell>
        <TableCell width='11.11%'>{p.maximum_lvr_own_occ > 0 ? 'Home Loan' : 'Investment'}</TableCell>
        <TableCell width='11.11%'>{type}</TableCell>
        <TableCell width='11.11%'>{parseFloat(p.iir).toFixed(2)}%</TableCell>
        <TableCell width='16.66%'>${formatNumber(p.maximum_loan_amount)}</TableCell>
      </TableRow>
    );
  }
}

ProductRow = withRouter(ProductRow);


const formatNumber = n => {
  if (n == null) return '?';
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const stateToProps = state => ({
  products: state.products,
  user: state.user,
});

const dispatchToProps = dispatch => 
  bindActionCreators(
    { 
      requestFindProducts, 
      requestPatchSelf
    },
    dispatch
  );


export default connect(stateToProps, dispatchToProps)(ProductSearch);