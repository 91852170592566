import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import { Row, Column, PullLeft, PullRight } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';
import MultiSelectList from 'components/Common/MultiSelectList';
import SelectList from 'components/Common/SelectList';
import { Modal, ModalSubmit, ModalContext, ModalShow } from 'components/Modal/old';

import TextInput from 'components/Styled/TextInput';
import Label from 'components/Styled/Label';
import TextArea from 'components/Styled/TextArea';
import Select from 'components/Common/Select';

import imgLeftArrow from 'img/button/small-left-grey.png';
import imgRightArrow from 'img/button/small-right-grey.png';



export class RequestView extends Component {
  state = {
    requested: selectedlist.map(it => it.name),
    notRequested: unselectedlist.map(it => it.name),
    selectedReq: [],
    selectedNotReq: [],
  };
  selectReqested = (values) => {
    this.setState(state => ({ ...state, selectedReq: values }));
  };
  selectNotRequested = (values) => {
    this.setState(state => ({ ...state, selectedNotReq: values }));
  };
  AddToRequested = () => {
    const { requested, notRequested, selectedNotReq } = this.state;
    const selected = notRequested.filter((_, index) => selectedNotReq.some(i => i === index));
    const notSelected = notRequested.filter((_, index) => selectedNotReq.every(i => i !== index));
    this.setState(state => ({
      ...state,
      requested: [...requested, ...selected],
      notRequested: notSelected,
      selectedReq: [],
      selectedNotReq: [],
    }));
    this.clearNotRequested();
    this.clearRequested();
  };
  RemoveFromRequested = () => {
    const { requested, notRequested, selectedReq } = this.state;
    const selected = requested.filter((_, index) => selectedReq.some(i => i === index));
    const notSelected = requested.filter((_, index) => selectedReq.every(i => i !== index));
    this.setState(state => ({
      ...state,
      notRequested: [...notRequested, ...selected],
      requested: notSelected,
      selectedReq: [],
      selectedNotReq: [],
    }));
    this.clearNotRequested();
    this.clearRequested();
  };
  render() {
    const { requested, notRequested } = this.state;
    return (<Fragment>
      <div style={{ borderBottom: 'solid 1px #D8DCE7' }}>
        <div style={{ margin: '40px' }}>
          <Row>
            <PullLeft><Txt size='20px' marginTop='8px' marginBottom='15px' weight='500'>Requests</Txt></PullLeft>
            <PullRight>
              <ModalContext 
                animationTimeout={500} 
                modal={CustomRequestModal}
                onSubmit={(data, hide) => hide()}>
                <ModalShow as={ButtonPlain} height='30px' fontSize='11px'>Add Custom Request</ModalShow>
              </ModalContext> 
            </PullRight>
          </Row>
          <Row>
            <Column width='329px'>
              <Txt size='13px'>Not Requested</Txt>
              <MultiSelectList width='329px' height='330px' onChange={this.selectNotRequested} clear={fn => this.clearNotRequested = fn} list={notRequested.map((item, index) => ({ name: item, value: index }))} />
            </Column>
            <Column width='60px'>
              <ButtonAdd onClick={this.AddToRequested} />
              <ButtonRemove onClick={this.RemoveFromRequested} />
            </Column>
            <Column width='325px'>
              <Txt size='13px'>Requested</Txt>
              <MultiSelectList width='329px' height='330px' onChange={this.selectReqested} clear={fn => this.clearRequested = fn} list={requested.map((item, index) => ({ name: item, value: index }))} />
            </Column>
          </Row>
        </div>
      </div>
      <div style={{ margin: '40px' }}>
        <Row>
          <PullLeft><Txt size='20px' marginTop='8px' marginBottom='15px' weight='500'>Template</Txt></PullLeft>
        </Row>
        <Row>
          <Column width='329px'>
            <SelectList width='329px' height='215px' list={templates.map((item, index) => ({ name: item, value: index }))} />
          </Column>
          <Column width='60px' style={{ height: '200px' }}></Column>
          <Column width='329px'>
            <ButtonPlain height='32px' fontSize='11px'>Apply Template</ButtonPlain>
          </Column>
        </Row>
        <Row>
          <PullRight>
            <ButtonPrimary width='125px' backgroundColor='#26BD27'>Save Request</ButtonPrimary>
          </PullRight>
        </Row>

      </div>
    </Fragment>);
  }
}

const CustomRequestModal = ({ modal }) => (
  <Modal width='420px' modal={modal}>
    <Row>
      <ModalColumn>
        <Txt center size='24px'>Add Custom Request</Txt>
      </ModalColumn>
      <ModalColumn>
        <Label>Document Name</Label>
        <TextInput ref={el => modal.focusOnStart(el)}/>
      </ModalColumn>
      <ModalColumn>
        <Label>Description</Label>
        <TextArea/>
      </ModalColumn>
      <ModalColumn>
        <Label>Which Applicant does this apply to?</Label>
        <Select options={[{name: 'Joel Collins', value: ''}, {name: 'Anna Trusova', value: ''}]}/>
      </ModalColumn>
      <ModalSubmit as={ButtonPrimary} width='100%'>Add Custom Request</ModalSubmit>
    </Row>
  </Modal>
);

const ModalColumn = styled(Column)`
  margin-bottom: 20px;
`;

let unselectedlist = [
  {name: 'thing1', value: 1},
  {name: 'thing2', value: 2},
  {name: 'thing3', value: 3},
  {name: 'thing4', value: 4},
  {name: 'thing5', value: 5},
  {name: 'thing6', value: 6},
];

let selectedlist = [
  {name: 'otherThing1', value: 1},
  {name: 'otherThing2', value: 2},
  {name: 'otherThing3', value: 3},
  {name: 'otherThing4', value: 4},
  {name: 'otherThing5', value: 5},
  {name: 'otherThing6', value: 6},
];

let templates = [
  'template 1',
  'template 2',
  'template 3',
  'template 4',
  'template 5',
  'template 6',
  'template 7',
];

const ButtonAddRemove = styled(ButtonPlain).attrs({
  width: '36px',
  height: '36px',
})`
  background-size: 8px 12px;
  background-position: Calc(100% - 13px) 50%;
  background-repeat: no-repeat;
`;


const ButtonAdd = styled(ButtonAddRemove)`
  margin: 23px 0 0 12px;
  background-image: url(${imgRightArrow});
`;

const ButtonRemove = styled(ButtonAddRemove)`
  margin: 15px 0 0 12px;
  background-image: url(${imgLeftArrow});
`;