import React from 'react';
import styled, { css } from 'styled-components';
import { Container as ContainerBase, Heading, Column } from './Shared';
import imgTick from '../img/elements/white-check.png';


const Container = styled(ContainerBase)`
  padding: 40px 0;
`;

const TimeLine = ({ isFinal }) => {
  return <>
    <Container>
      <Heading>Application Process Overview</Heading>
      <StagesContainer>
        <Row>
          <StageLeft>
          </StageLeft>
          <StageCenter>
            {isFinal ? <StageCircleTick/> : <StageCircleBlue/>}
            {isFinal ? <StageConnectorBlue/> : <StageConnector/>}
          </StageCenter>
          <StageRight>
            <StageHeading style={{ color: '#2689EB'}}>Application Preparation</StageHeading>
            <StageText style={{ color: '#2689EB'}}>
              You send through supporting documentation and our team will pre-fill our fact find with as many details as possible. Your broker will then fill in any gaps with you.
            </StageText>
          </StageRight>
        </Row>
        <Row>
          <StageLeft>
            <StageHeading style={ isFinal ? { color: '#2689EB'} : {}}>Application Finalisation</StageHeading>
            <StageText style={ isFinal ? { color: '#2689EB'} : {}}>
              Our loan processor, Lauren, will prepare your application. She will also confirm any final supporting documents that are required. You complete the application paperwork and email it back to us.
            </StageText>
          </StageLeft>
          <StageCenter>
            {isFinal ? <StageCircleBlue/> : <StageCircleGrey/>}
            <StageConnector/>
          </StageCenter>
          <StageRight></StageRight>
        </Row>
        <Row>
          <StageLeft>
          </StageLeft>
          <StageCenter>
            <StageCircleGrey/>
            <StageConnector/>
          </StageCenter>
          <StageRight>
            <StageHeading >Application Submission</StageHeading>
            <StageText>
              We will then submit your application to the lender. The lender may come back to us requesting further information or documentation. We will work with you to address any of these further requirements.
            </StageText>
          </StageRight>
        </Row>
        <Row>
          <StageLeft>
            <StageHeading>Approval</StageHeading>
            <StageText>
              Once the lender is satisfied with your application, they should issue a conditional approval. Where applicable, Lauren will send the discharge authority to your current lender. We will work with you to addres any outstanding conditions. Your new lender should then issue a formal approval.
            </StageText>
          </StageLeft>
          <StageCenter>
            <StageCircleGrey/>
            <StageConnector/>
          </StageCenter>
          <StageRight></StageRight>
        </Row>
        <Row>
          <StageLeft>
          </StageLeft>
          <StageCenter>
            <StageCircleGrey/>
            <StageConnector/>
          </StageCenter>
          <StageRight>
            <StageHeading>Loan Documents</StageHeading>
            <StageText>
              The solicitors acting on behalf of your new lender will prepare and issue loan documents. You will need to complete these documents and return them to the lender’s solicitor. Remember, we are here to help with any questions you may have.
            </StageText>
          </StageRight>
        </Row>
        <Row>
          <StageLeft>
            <StageHeading>Settlement</StageHeading>
            <StageText>
            Once your loan documents have been certified as ready, the loan will be booked for settlement. If this is a refinance, this will booked with your current lender. If this is a purchase, your solicitor will need to liaise with the lender’s solicitor to book in settlement.
            </StageText>
          </StageLeft>
          <StageCenter>
            <StageCircleGrey/>
            <StageConnector/>
          </StageCenter>
          <StageRight></StageRight>
        </Row>
        <Row>
          <StageLeft>
          </StageLeft>
          <StageCenter>
            <StageCircleGrey/>
          </StageCenter>
          <StageRight>
            <StageHeading>Post-settlement</StageHeading>
            <StageText>
            After settlement, we will confirm and e-mail through your new loan details, along with any internet banking instructions. We will also check in to ensure everything is going okay with your new loan, and book in a future date to review your lending.
            </StageText>
          </StageRight>
        </Row>
      </StagesContainer>
    </Container>
  </>;
};

export default TimeLine;

const Row = styled.div`
  display: flex;
`;

const StagesContainer = styled.div`
  margin-top: 55px;
`;

const StageHeading = styled.div``;
const StageText = styled.div``;

const StageRight = styled.div`
  padding-top: 3px; 
  width: 390px;
  ${StageHeading} {
    font-size: 20.3px;
    font-weight: 700;
    padding-bottom: 7px;
  }

  ${StageText} {
    font-size: 16.65px;
    line-height: 20.3px;
  }
`;

const StageLeft = styled(StageRight)`
  ${StageHeading}, ${StageText} {
    text-align: right;
  }
`;

const StageCenter = styled.div`
  position: relative;
  /* width: 28px; */
  flex: 0 0 28px;
  margin: 0 20px;
`;

const StageCircleGrey = styled.div`
  background-color: #C7C9C8;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  position: absolute;
  top: -1px;
`;

const StageCircleBlue = styled(StageCircleGrey)`
  background-color: #2689EB;
`;

const StageCircleTick = styled(StageCircleGrey)`
  background-color: #2689EB;
  background-image: url(${imgTick});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
`;

const StageConnector = styled.div`
  background-color: #EDEDED;
  width: 9px;
  height: 166px;
  margin: auto;
`;

const StageConnectorBlue = styled(StageConnector)`
  background-color: #2689EB;
`;