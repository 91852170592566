import React, { useCallback, useEffect } from 'react';
import { reduxForm, Form } from 'redux-form';
import { requestPatchSelf } from 'modules/user/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Debouncer from 'util/debouncer';

const debouncer = new Debouncer();

const ControlPanel = ({ handleSubmit, children, callback, requestPatchSelf, user, initialize, ...props }) => {
  
  useEffect(() => {
    initialize(user.meta);
  }, []);

  const onSubmit = useCallback(values => {
    callback && callback(values);
    debouncer.run(() => 
      requestPatchSelf({
        data: {
          id: user.id,
          meta: values,
        }
      }), 500);
  }, [callback])

  const onFormChange = useCallback(() => 
    setTimeout(
      handleSubmit(values => onSubmit(values))
    ), [onSubmit])
  
  return (
    <Form {...props} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}>
      {children}
    </Form>
  )
}

const stateToProps = state => ({
  user: state.user,
});

const dispatchToProps = dispatch => 
  bindActionCreators(
    { requestPatchSelf },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(reduxForm({ 
  form: 'ControlPanel',
  onChange: (values, _, props) => {
    if (!props) return;
    props.callback && props.callback(values);
    debouncer.run(() => 
      props.requestPatchSelf({
        data: {
          id: props.user.id,
          meta: values,
        }
      }), 500);
    }
  })(ControlPanel))