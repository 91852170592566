/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { reduxForm, Form, formValueSelector } from 'redux-form';
import styled, { css } from 'styled-components';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { ButtonPrimary } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import Divider from 'components/Styled/Divider';

import { connect } from 'react-redux';
import { requestUser, requestPatchUser } from 'modules/clients/actions';

import { TextArea, NumberInput } from 'components/Form';
import { Row, Column } from '../ViewClient';
import { PullRight } from 'components/Styled/Layout';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

import { verificationService } from 'modules';

import { required } from 'util/validation';

import ProposalPDF from 'shared/ProposalPDF';
import { filter } from 'lodash';
import moment from 'moment';
import uriToBlob from 'util/uriToBlob';
import download from 'downloadjs';
import uploadPDF from 'util/upload-pdf';
import { uploadUrl } from 'modules';
import { useHistory } from 'react-router-dom';

import { addFile } from 'modules/clients/actions';



let MiniProposalForm = ({
  client,
  handleSubmit, 
  submitting, 
  brokerId,
  submitSucceeded,
  proposal,
  requestUser,
  requestPatchUser,
  settings,
}) => {


  const [ isSavingProposal, setIsSavingProposal ] = useState(false);
  const [ isUploadingProposal, setIsUploadingProposal ] = useState(false);
  const [ showAll_Proposal, setShowAll_Proposal ] = useState(false);
  const [ showAll_ATP, setShowAll_ATP ] = useState(false);
  const [ isSendingMagicLink, setIsSendingMagicLink ] = useState(false);
  const { openModal } = useContext(modalContext);
  const history = useHistory();
  

  const dispatch = useDispatch();

  let proposalPDFs = client.uploads ?
    client.uploads.filter(file => file.type === 'proposal')
    .sort((a,b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()) : [];

  let showViewMore_Proposal = false;
  if (!showAll_Proposal && proposalPDFs.length > 2) {
    proposalPDFs = [proposalPDFs[0], proposalPDFs[1]];
    showViewMore_Proposal = true;
  }

  let atpPDFs = client.uploads ? 
    client.uploads.filter(file => file.type === 'atp-pdf' || file.type === 'atp-pdf-final')
    .sort((a,b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()) : [];

  let showViewMore_ATP = false;
  if (!showAll_ATP && atpPDFs.length > 2) {
    atpPDFs = [atpPDFs[0], atpPDFs[1]];
    showViewMore_ATP = true;
  }
  

  const sendMagicLink = async () => {
    setIsSendingMagicLink(true);
    try {
      await sendEmail();
    } catch (err) {}
    setIsSendingMagicLink(false);
  };

  const sendEmail = () => {
    return new Promise( async (resolve, reject) => {
      if (!proposal || !proposal.enabled) {
        openModal({ component: ErrorModal });
        return reject();
      }
      try {
        await verificationService.create({
          type: 'mini-proposal-link',
          email: client.email,
          brokerId,
        });
        resolve();

        requestPatchUser({
          data: {
            id: client.id,
            meta: {
              magicLinkSentDate: moment().format(),
            }
          },
          params: {
            mergeMeta: true,
          },
        });
      } catch(err) {
        reject();
      }
    });
  };

  const downloadPDF = async (pdf, date) => {
    const blob = await uriToBlob(`${uploadUrl}/${pdf.storageId}`);
    download(blob, `Mortgage Proposal - ${client.lastName || client.firstName} - ${date.format('DDMMYY')}.pdf`, 'application/pdf');
  };

  const startSendPDF = async () => {
    setIsSavingProposal(true);
    setIsUploadingProposal(true);
  };

  const date = moment();
  const name = `Mortgage Proposal - ${client.lastName || client.firstName} - ${date.format('DDMMYY')}.pdf`;

  const finishSendPDF = async blob => {
    setIsSavingProposal(false);
    console.log('Uploading PDF...');
    const result = await uploadPDF({
      blob,
      type: 'send-proposal',
      name, 
      userId: client.id,
      email: client.email,
      userName: client.lastName || client.firstName,
      brokerId,
    });

    console.log('Uploading finished');
    setIsUploadingProposal(false);

    dispatch(addFile({
      storageId: result.storageId,
      userId: client.id,
      name,
      type: 'proposal',
      status: 1,
      createdAt: date.format(),
    }));
  };

  let magicLinkSentDate;
  if (client.meta && client.meta.magicLinkSentDate) {
    magicLinkSentDate = moment(client.meta.magicLinkSentDate);
  }

  return (
    <>
      <Row>
        <Column width='50%'>
          <Label>Magic Link Proposal Email</Label>
          <ButtonPrimary width='150px' margin='0' onClick={() => sendMagicLink()}><ButtonSpinner show={isSendingMagicLink}/>Send Magic Link</ButtonPrimary>
          {magicLinkSentDate &&
            <BlueInfoText key={magicLinkSentDate.format()}>
              Magic Link e-mailed at {magicLinkSentDate.format('HH:mm')} on {magicLinkSentDate.format('DD/MM/YYYY')}
            </BlueInfoText>
          }
          
        </Column>

        <Column width='50%'>
          <Label>PDF Proposal Email</Label>
          <ButtonPrimary width='150px' margin='0' onClick={() => startSendPDF()}><ButtonSpinner show={isUploadingProposal}/>Send PDF</ButtonPrimary>
          {proposalPDFs.map((pdf) => {
            const date = moment(pdf.createdAt);
            return (
              <BlueInfoText as='a' href={`${uploadUrl}/${pdf.storageId}`} target='_blank' key={pdf.createdAt}>
                PDF e-mailed at {date.format('HH:mm')} on {date.format('DD/MM/YYYY')}
              </BlueInfoText>
            );
          })}
          {showViewMore_Proposal && !showAll_Proposal &&
            <BlueInfoText onClick={() => setShowAll_Proposal(true)}>View more…</BlueInfoText>
          }
        </Column> 
        {isSavingProposal &&
          <PDF>
            <ProposalPDF user={client} settings={settings} finished={blob => finishSendPDF(blob)}/>
          </PDF>
        }
      </Row>
      
      <Row>
        <Column width='100%'>
          <Divider width='100%' />
        </Column>
      </Row>

      <Row>
        <Column width='50%'>
          <Label>Agree to Proceed Generator</Label>
          <ButtonPrimary type='button' width='150px' margin='0' as='a' onClick={() => history.push(`/clients/view/atp?id=${client.id}`)}>Generate ATP</ButtonPrimary>
        </Column>
        <Column width='50%'>
          <Label>ATP History</Label>
          {atpPDFs.map((pdf) => {
            const date = moment(pdf.createdAt);
            return (
              <BlueInfoText as='a' href={`${uploadUrl}/${pdf.storageId}`} target='_blank' key={pdf.createdAt}>
                {pdf.type === 'atp-pdf' ? 'ATP' : 'Final'} Generated at {date.format('HH:mm')} on {date.format('DD/MM/YYYY')}
              </BlueInfoText>
            );
          })} 
          {showViewMore_ATP && !showAll_ATP &&
            <BlueInfoText onClick={() => setShowAll_ATP(true)}>View more…</BlueInfoText>
          }
        </Column>
      </Row>
    </>
  );
};

const BlueInfoText = styled.div`
  display: block;
  color: #4887f0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 10px;
  animation: fadein 1s ease-in-out;
  animation-fill-mode: forwards;

  ${props => (props.onClick || props.href) && css`
    cursor: pointer;
  `}

  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`; 

MiniProposalForm = reduxForm({
  form: 'miniProposalEmail',
})(MiniProposalForm);

const proposalFormSelector = formValueSelector('miniProposal');
const stateToProps = state => ({
  client: state.clients.current,
  brokerId: proposalFormSelector(state, 'brokerId') ?? state.clients.current?.proposalBroker?.id,
  proposal: state.clients.current.meta && state.clients.current.meta.miniproposal,
  settings: state.user.globalSettings,
});

const dispatchToProps = dispatch =>
  bindActionCreators({ requestUser, requestPatchUser }, dispatch);


export default connect(stateToProps, dispatchToProps)(MiniProposalForm);

const PDF = styled.div`
  position: absolute;
  left: 10000px;
  top: -100000px;

  * {
    font-family: LatoPDF, sans-serif !important;
  }

  li {
    font-family: inherit;
  }
`;

const ErrorModal = ({ modal }) => {

  return (
    <ModalBoxStyle width='540px'>
      <Row style={{ margin: '0' }}>
        <ModalHeading><OrangeExcl>!</OrangeExcl>Cannot Send Email</ModalHeading>
      </Row>
      <Row style={{margin: '0 0 20px 0'}}>
        <Paragraph>The proposal is not currently live. Please set to live before sending the Magic Link.</Paragraph>
      </Row>
      <Row style={{margin: '0', textAlign: 'center' }}>
        <ButtonPrimaryExtended width='100px' type='button' onClick={modal.close}>Okay</ButtonPrimaryExtended>
      </Row>
    </ModalBoxStyle>
  );
};

const ModalBoxStyle = styled(ModalBox)`
  .button-close {
    display: none;
  }
`;

const ModalHeading = styled.h2`
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  margin-bottom: 25px;
  line-height: 1.4;
  text-align: center;
`; 

const Paragraph = styled.p`
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  line-height: 1.4;
  margin-bottom: 10px;
  text-align: center;
`;

const ButtonPrimaryExtended = styled(ButtonPrimary)`
font-weight: 600;
`;

const OrangeExcl = styled.span`
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #F5A623;
  margin-left: 10px;
  font-weight: 900;
  z-index: 99;
  margin-right: 10px;
  pointer-events: none;
  height: 22px;
  width: 22px;
  line-height: 22px;
  font-size: 12px;
  vertical-align: middle;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
`;