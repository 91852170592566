import { createAction } from 'redux-actions';

export const REQUEST_UPDATE_APPLICATIONS = 'REQUEST_UPDATE_APPLICATIONS';
export const RECEIVE_UPDATE_APPLICATIONS = 'RECEIVE_UPDATE_APPLICATIONS'; 
export const REQUEST_GET_APPLICATIONS = 'REQUEST_GET_APPLICATIONS';
export const RECEIVE_GET_APPLICATIONS = 'RECEIVE_GET_APPLICATIONS';
export const REQUEST_MOVE_APPLICATION = 'REQUEST_MOVE_APPLICATION';
export const REQUEST_CREATE_APPLICATION = 'REQUEST_CREATE_APPLICATION';
export const RECEIVE_CREATE_APPLICATION = 'RECEIVE_CREATE_APPLICATION';
export const REQUEST_GET_APPLICATION = 'REQUEST_GET_APPLICATION';
export const RECEIVE_GET_APPLICATION = 'RECEIVE_GET_APPLICATION';

export const CREATE_STAGE = 'CREATE_STAGE';
export const UPDATE_STAGE = 'UPDATE_STAGE';
export const REMOVE_STAGE = 'REMOVE_STAGE';

export const SET_CURRENT_DEAL = 'SET_CURRENT_DEAL';

export const requestUpdateApplications = createAction(REQUEST_UPDATE_APPLICATIONS);
export const receiveUpdateApplications = createAction(RECEIVE_UPDATE_APPLICATIONS);
export const requestMoveApplication = createAction(REQUEST_MOVE_APPLICATION);
export const requestGetApplications = createAction(REQUEST_GET_APPLICATIONS);
export const receiveGetApplications = createAction(RECEIVE_GET_APPLICATIONS);
export const requestCreateApplication = createAction(REQUEST_CREATE_APPLICATION);
export const receiveCreateApplication = createAction(RECEIVE_CREATE_APPLICATION);
export const requestGetApplication = createAction(REQUEST_GET_APPLICATION);
export const receiveGetApplication = createAction(RECEIVE_GET_APPLICATION);

export const createStage = createAction(CREATE_STAGE);
export const updateStage = createAction(UPDATE_STAGE);
export const removeStage = createAction(REMOVE_STAGE);

export const setCurrentDeal = createAction(SET_CURRENT_DEAL);