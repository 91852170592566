import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

const $ = window.$;

class SectionButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      subMenuOpen: false,
      subMenuOpen2: false
    };
  }

  checkIfLeaveMenu = e => {
    if(!$(e.target).parents('.proposal-button').length) {
      this.leaveMenu();
    }
  }

  eventHandler = this.checkIfLeaveMenu.bind(this);

  componentDidMount() {
    $(window).on('click', this.eventHandler);  
  }

  componentWillUnmount() {
    $(window).off('click', this.eventHandler);
  }

  toggleMenu = () => {
    this.setState(state => ({ ...state, menuOpen: !this.state.menuOpen }))
  }

  toggleSubMenu = () => {
    this.setState(state => ({ ...state, subMenuOpen: !this.state.subMenuOpen }))
  }

  toggleSubMenu2 = () => {
    this.setState(state => ({ ...state, subMenuOpen2: !this.state.subMenuOpen2 }))
  }

  leaveMenu = () => {
    this.setState(state => ({ ...state, subMenuOpen: false }));
    this.setState(state => ({ ...state, subMenuOpen2: false }));
    this.setState(state => ({ ...state, menuOpen: false }));
  }

  andClose = fn => 
    (...args) => {
      fn(...args);
      this.leaveMenu();
    }

  render() {
    return (
      <SectionButtonContainer {...this.props} menuOpen={this.state.menuOpen} submenuOpen={this.state.subMenuOpen}>
        <span className='proposal-button'>
          {this.props.editMode
            ?
            <CancelSaveContainer cancelSaveContainer={this.props.cancelSaveContainer}>
              <Button cancel onClick={this.props.cancelEdit}>Cancel</Button>
              <Button save onClick={this.props.saveEdit}>Save</Button>
            </CancelSaveContainer>
            :
            <ButtonDots color={this.props.color} onClick={() => this.toggleMenu()} active={this.state.menuOpen}></ButtonDots>
          }

          <SubMenu color={this.props.color} status={this.state.menuOpen} location={this.props.right} width={this.props.width}>
            {this.props.children({ 
              closeMenu: this.leaveMenu, 
              toggleSubmenu: this.toggleSubMenu,
              andClose: this.andClose,
            })}
          </SubMenu>
        </span>
      </SectionButtonContainer>
    )
  }
}

const CancelSaveContainer = styled.div`
  float: right;
  margin-top: ${props => props.cancelSaveContainer};  
`;

const Button = styled.button`
  vertical-align: top;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #fff;
  background-color: #2291FF;
  font-weight: 600;
  font-size: 11px;
  transition: background color 0.3s;
  box-sizing: border-box;
  padding: 4px 2px;
  width: 50px;
  margin-left: 5px;

  ${props => props.cancel && css`
    background-color: #FF4E4C;
  `};

  ${props => props.save && css`
    background-color: #26BD27;
  `};
`;

export const SubMenuOption = styled.div`
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216,220,231,0.65);
  border-radius: 4px 0 4px 4px;
  right: -11px;
  top: 33px;
  padding: 10px;
  display: none;
  text-align: left;
  z-index: 60;

  ${props => props.color === 'red' && css`
    color: #FF4E4C;

    &:hover {
      color: #EC2422;
    }
  `};
`;

const SectionButtonContainer = styled.span`
  z-index: ${p => p.menuOpen ? 60 : 50};

  ${SubMenuOption} {
    display: ${p => p.submenuOpen ? 'block' : 'none'};
  }
`;



const ButtonDots = styled.div`
  float: right;
  background-image: ${p => p.color === 'white' ?
    css`url(${require('img/button/tripledot_white.png')})`
    :
    css`url(${require('img/button/tripledot.png')})`};
  background-size: 16px 5px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: rgba(0,0,0,0);
  height: 25px;
  width: 40px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  ${props => props.active && css`
    border: 1px solid #D8DCE7;

    ${p => p.color === 'white' && css`
      background-image: url(${require('img/button/tripledot.png')});
      background-color: white;
    `}
  `};

  &:hover {
    border: 1px solid #D8DCE7;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
    border-radius: 4px 4px 4px 4px;
  }
`;

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps)(SectionButton);

const SubMenu = styled.ul`
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
  border-radius: 4px 0 4px 4px;
  right: 0px;
  top: 23px;
  padding: 2px 10px;
  width: ${p => p.width || 150}px;
  display: none;
  text-align: left;
  z-index: 51;

  ${props => props.location && css`
    right: none;
    left: 0px;
    border-radius: 0 4px 4px 4px;

    &:after {
      right: none;
      left: -1px;
    }
  `}
  
  ${props => props.status && css`
    display: block;
  `};

  &:after {
    content: '';
    position: absolute;
    height: 3px;
    background-color: #fff;
    width: 40px;
    right: -1px;
    top: -3px;
    border-right: 1px solid #D8DCE7;
    border-left: 1px solid #D8DCE7;
  }
`;

export const MenuOption = styled.li`
  font-size: 12px;
  border-bottom: 1px solid #D8DCE7;
  padding: 10px 0;
  cursor: pointer;
  text-align: left;

  ${props => props.confirm && css`
    position: relative;
  `};

  ${props => props.color === 'default' && css`
    color: #818CA2;

    &:hover {
      color: #101922;
    }
  `};

  ${props => props.color === 'disabled' && css`
    color: #dedede;
    cursor: not-allowed;
  `};

  ${props => props.color === 'red' && css`
    color: #FF4E4C;

    &:hover {
      color: #EC2422;
    }
  `};

  &:last-of-type {
    border-bottom: 0;
  }
`;