import React, {useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import EditableContent from './EditableContent'; 

export const EmailTextArea = props => {
  const inputRef = useRef(null);

  const onKeyDown = useCallback(e => {
    if (document.activeElement !== ReactDOM.findDOMNode(inputRef.current)) return;
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
        e.preventDefault();
      }, 5);
    }
    return false;
  }, [inputRef]);

  const insertUnorderedList = () => {
    document.execCommand('insertUnorderedList');
  };

  const insertOrderedList = () => {
    document.execCommand('insertOrderedList');
  };

  return (
    <Container>
      <TextArea allowLineBreaks allowAnyPaste onKeyDown={onKeyDown} {...props} innerRef={inputRef}/>
      {!props.noButtons && <>
        <OrderedButton onClick={() => insertOrderedList()}/>
        <UnorderedButton onClick={() => insertUnorderedList()}/>
      </>}
    </Container>);
};

const ButtonBase = styled.button.attrs({
  type: 'button',
})`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: solid 1px #d8dce7;
  background-color: white;
  transition: opacity 0.5s ease-in-out;
`;

const OrderedButton = styled(ButtonBase)`
  background-image: url(${require('img/button/ordered_list.png')});
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  bottom: -35px;
  left: 0px;
`;

const UnorderedButton = styled(ButtonBase)`
  background-image: url(${require('img/button/unordered_list.png')});
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  bottom: -35px;
  left: 36px;
`;

const Container = styled.div`
  &:focus-within {
    ${ButtonBase} {
      opacity: 1;
      cursor: pointer;
      pointer-events: all;
    }
  }
  position: relative;
`;

const TextArea = styled(EditableContent)`
  color: #101922;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  letter-spacing: 0; 
  resize: none;
  min-width: ${p => p.width || '100%'};
  border: 1px solid #d8dce7;
  border-radius: 4px;
  background: #ffffff;
  color: #101922;
  padding: 10px 12px 10px 12px;
  /* box-shadow: 0px 1px 0px 0px #e9ebf1; */
  transition: all 300ms;
  overflow: auto;
  margin-bottom: 40px;
  max-height: 350px;

  ${p => p.error && css`
    border: 1px solid rgba(255,0,0,0.5) !important;
    box-shadow: inset 0 0 0 2px rgba(255,0,0,0.2) !important;
  `}

  &:focus {
    border: 1px solid #2291FF !important;
    box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
  }

  line-height: 20px;
  min-height: 100px;
  /* color: #575E6C; */
  
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
    }
  }

  ol {
    li {
      list-style: decimal inside !important;
      margin-left: 10px;
    }
  } 
`;

