import React from 'react';
import { withRouter } from 'react-router';

const BackButton = ({ margin, history, onClick, style }) => (
  <div className='btn-back' onClick={onClick || history.goBack} style={{ margin, ...style }}>
    <img src={require('../../img/button/small-left-blue.png')} width='10px'/>
    <div>Go back</div>
  </div>
);

export default withRouter(BackButton);