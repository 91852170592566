import React from 'react';
import { reduxForm, Form, formValueSelector } from 'redux-form';

import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';

import { connect } from 'react-redux';

import { Input, Select, ToggleButton, TextArea } from 'components/Form';
import { PullRight } from 'components/Styled/Layout';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column } from '../ViewClient';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

import { verificationService } from 'modules';

import { required } from 'util/validation';

let VideoProposalForm = ({
  client,
  handleSubmit, 
  pristine, 
  submitting, 
  invalid,
  brokerId,
  submitSucceeded,
}) => {

  const sendEmail = ({ content }) => {
    return new Promise( async (resolve, reject) => {
      try {
        await verificationService.create({
          type: 'video-proposal-link',
          email: client.email,
          brokerId,
          content,
        });
        resolve();
      } catch(err) {
        reject();
      }      
    });
  } 

  return (
    <Form onSubmit={handleSubmit(sendEmail)}>
      <Row>
        <Column width='100%'>
          <Label>Proposal Magic Link Email Content</Label>
          <TextArea name='content' validate={required}/>
        </Column>
        <Column width='50%'>
        {submitSucceeded &&
           <span style={{position: 'absolute', top: '13px', right: '20px'}}>The proposal has been sent! 🙂</span>
        }
        </Column>
        <Column width='50%'>
          <ButtonPrimary width='100%' disabled={pristine}><ButtonSpinner show={submitting}/>Email Proposal Magic Link</ButtonPrimary>
        </Column>
      </Row>
      <Row>
        <Column width='100%'>
          <Divider width='100%' />
        </Column>
      </Row>
    </Form>
  );
}

VideoProposalForm = reduxForm({
  form: 'videoProposalEmail',
})(VideoProposalForm);

const proposalFormSelector = formValueSelector('videoProposal');
const stateToProps = state => ({
  client: state.clients.current,
  brokerId: proposalFormSelector(state, 'brokerId'),
});


export default connect(stateToProps)(VideoProposalForm);