import React, { Component } from 'react';

import { formValueSelector, reduxForm, FieldArray } from 'redux-form';
import styled from 'styled-components';
import { ButtonPlain } from 'components/Styled/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonImageSquare } from 'components/Styled/Button';
import Label from 'components/Styled/Label';

import { Input, Select, TextArea, DateInput, MoneyInputInt, PercentInput, NumberInput } from 'components/Form';
import { Column as BaseColumn } from 'components/Styled/Layout';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column, SecondaryColumn, SubColumns } from 'views/Clients/ViewClient';
import pen from 'img/button/edit-pen-2.png';
import deleteBin from 'img/button/trash-red.png';

import statusError from 'img/elements/excl-circle.png';
import statusComplete from 'img/elements/tick-circle.png';
import { requestDeleteLoan, requestPatchUser } from 'modules/clients/actions';
import { requestGetLenders } from 'modules/lenders/actions';

import { geocodeByAddress } from 'react-places-autocomplete';
import ErrorDot from '../Tabs/components/ErrorDot';

import Debouncer from 'util/debouncer';

import BankLogo from 'components/Common/BankLogo';
import HiddenPenEditField from 'views/Clients/component/HiddenPenEditField';


function mapStateToProps(state, props) {
  const selector = formValueSelector(props.form);
  const id = selector(state, 'id');
  return {
    id,
    displayOpen: (state.clients.current.loans.find(l => l.id === id) || {}).displayOpen,
    userId: selector(state, 'userId'),
    houseId: selector(state, 'houseId'),
    propertyPurpose: selector(state, 'propertyPurpose'),
    productPurpose: selector(state, 'productPurpose'),
    currentLender: selector(state, 'currentLender'),
    interestRate: selector(state, 'interestRate'),
    balance: selector(state, 'balance'),
    paymentType: selector(state, 'paymentType'),
    lenderId: selector(state, 'lenderId'),
    loanType: selector(state, 'productType'),
    lenderName: selector(state, 'lenderName'),
    remainingTerm: selector(state, 'remainingTerm'),
    remainingTermUnit: selector(state, 'remainingTermUnit'),
    repayments: selector(state, 'repayments'),
    repaymentFrequency: selector(state, 'repaymentFrequency'),
    security: selector(state, 'security'),
    name: selector(state, 'name'),
    isDeleted: selector(state, 'isDeleted'),
    lenders: (state.lenders || {}).list,
    client: state.clients.current,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestDeleteLoan,
    requestPatchUser,
    requestGetLenders,
  }, dispatch);
}

class AddSplit extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      displayOpen: true, 
      displayDelete: true,
      groupsLoaded: false,
      lendersLoaded: true,
      groupOptions: [{ name: 'Please Select', value: '' }],
      lastSubmit: {
        ...props.initialValues, 
        fixedRateExp: parseDate(props.initialValues.fixedRateExp),
        remainingTermUnit: undefined,
        repaymentFrequency: undefined,
      },
    };
  }

  debouncer = new Debouncer();

  async componentDidMount() {
    
    
    
    const { houses, lenders } = this.props;
    let groups = [];
    (houses || []).filter(i => !i.isDeleted).forEach(house => {
      if (house.group) {
        let g = groups.find(group=>group.propertyGroup === 'Group ' + house.group);
        if (!g) {
          groups.push({
            id: house.group.toString(),
            propertyGroup: 'Group ' + house.group,
            properties: [house]
          });
        } else {
          g.properties.push(house)
        }
      } else {
        groups.push({
          id: house.id.toString(),
          propertyGroup: house.name,
          properties: [house]
        });
      }
    });


    let groupOptions = (groups || []).map(group => ({
      name: this.getGroupName(group),
      value: group.id,
    }));

    groupOptions = [{ name: 'Please Select', value: '' }, ...groupOptions];
    this.setState({ groupOptions, groupsLoaded: true });

    //this.onFormChange();
  }

  toggleDisplayOpen(e) {
    this.props.requestPatchUser({
      data: { loan: { id: this.props.id, displayOpen: !this.props.displayOpen }, id: this.props.userId },
    });
  }

  toggleDelete(e) {
    e.preventDefault();
    this.setState(state => ({ displayDelete: !state.displayDelete }));
    return false;
  }

  deleteRestoreSplit(willDelete = true) {
    this.props.requestPatchUser({
      data: { 
        loan: {
          id: this.props.split.id,
          deleting: true,
        }, 
        id: this.props.client.id 
      },
    });
    setTimeout(() => {
      this.props.requestPatchUser({
        data: { 
          loan: {
            id: this.props.split.id,
            deleting: false,
            isDeleted: willDelete,
            displayOpen: false,
          }, 
          id: this.props.client.id 
        },
      });
    }, 1000);
  }

  onFormChange() {
  
    setTimeout(
      this.props.handleSubmit(values => {
        if ((values.productPurpose || []).includes('Other') && values.houseId) {
          this.props.change('houseId', '');
          values.houseId = '';
        }
        this.onSubmit(values);
      })
    );


  }

  filterUnchangedValues = values => {
    const oldValues = this.state.lastSubmit;
    let result = Object.keys(values).reduce((obj, key) => {
      if (values[key] !== oldValues[key]) return {...obj, [key]: values[key]}
      return obj;
    }, {});
    result.id = values.id;
    return result;
  }

  onSubmit = async _data => {
    let fixedRateExp = parseDate(_data.fixedRateExp);
    delete _data.displayOpen;
    _data = {
      ..._data,
      fixedRateExp,
    };

    const loan = this.filterUnchangedValues(_data);

    let data = {
      loan,
    }

    if (this.state.security === undefined) {
      this.setState({ security: _data.security });
    } else if (_data.security !== this.state.security) {
      if (this.props.selectedGroups.length) {

        this.props.requestPatchUser({
          data: { 
            loan: {
              id: _data.id,
              changingSecurity: true,
            }, 
            id: this.props.client.id },
        });

      data.loan.changingSecurity = false;

        setTimeout(() => {
           
            this.props.requestPatchUser({
              data: { ...data, id: this.props.client.id },
            });

        }, 1000);

        return;
      }
    }

    this.debouncer.run(() => {
        this.setState({ lastSubmit: _data });
        this.props.requestPatchUser({
          data: { ...data, id: this.props.client.id },
        });
    }, 2000)
  }

  getPropertyName(house) {
    let name = `${house.name} - ${house.address || ''}`;
    if (name.length > 53) name = name.slice(0, 53) + '...';
    return name;
  }

  getGroupName = ({ propertyGroup, properties, id }) => {
    if (parseInt(id)) {
      return this.getPropertyName(properties[0]);
    } else {
      const suburbs = properties.map(p => p.locale);
      let name = `${propertyGroup} - ${suburbs.join(', ')}`;
      if (name.length > 53) name = name.slice(0, 53) + '...';
      return name;
    }
  }

  componentWillReceiveProps(nextProps) {

    const newBalance = nextProps.balance;
    const newInterestRate = nextProps.interestRate;
    const newPaymentType = nextProps.paymentType;
    const newRemainingTerm = nextProps.remainingTerm;
    const newRemainingTermUnit = nextProps.remainingTermUnit;
    const newRepaymentFrequency = nextProps.repaymentFrequency;
    
    const {
      balance, 
      interestRate,
      paymentType,
      remainingTerm,
      remainingTermUnit,
      repayments,
      repaymentFrequency,
    } = this.props;

    if (!newBalance || !newInterestRate || !newPaymentType || !newRemainingTerm || !newRemainingTermUnit || !newRepaymentFrequency) return;


    const newTimeRatio = newRemainingTermUnit === 'Years' ? 1 : 1/12;
    const timeRatio = remainingTermUnit === 'Years' ? 1 : 1/12;

    const loanPeriod = remainingTerm * timeRatio;
    const newLoanPeriod = newRemainingTerm * newTimeRatio;
    
    if (
      balance !== newBalance ||
      interestRate !== newInterestRate ||
      paymentType !== newPaymentType ||
      loanPeriod !== newLoanPeriod ||
      repaymentFrequency !== newRepaymentFrequency
    ) {
      const monthlyRepayments = calculateRepayments({
        loanAmount: newBalance || 0,
        interestRate: newInterestRate / 100 || 0,
        repaymentType: newPaymentType,
        loanPeriod: newLoanPeriod || 30,
        repaymentFrequency: newRepaymentFrequency
      });
      if (!repayments || repayments === this.state.monthlyRepayments) {;
        this.props.change('repayments', monthlyRepayments);
      }

      this.setState({ monthlyRepayments });
    }

  }

  autocalcRepayments = () => {
    
    const {
      balance, 
      interestRate,
      paymentType,
      remainingTerm,
      remainingTermUnit,
      repaymentFrequency,
    } = this.props;

    if (!balance || !interestRate || !paymentType || !remainingTerm || !remainingTermUnit || !repaymentFrequency) return;

    const timeRatio = remainingTermUnit === 'Years' ? 1 : 1/12;
    const loanPeriod = remainingTerm * timeRatio;

    const monthlyRepayments = calculateRepayments({
      loanAmount: balance || 0,
      interestRate: interestRate / 100 || 0,
      repaymentType: paymentType,
      loanPeriod: loanPeriod || 30,
      repaymentFrequency: repaymentFrequency
    });

      this.props.change('repayments', monthlyRepayments);
      this.setState({ monthlyRepayments });
  }
    
  

  render() {
    if (!this.state.groupsLoaded || !this.props.lenders) return null;
    const { isDeleted, warning, houses, lenders, groups, lenderId, interestRate, paymentType, loanType, lenderName } = this.props;
    const group = (groups || []).find(g => (g.loans || []).some(l => l.id === this.props.id));

    const lender = (lenders || []).find(l => l.id === lenderId);

    let lenderOptions = (lenders || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name }) => ({ name, value: id }));

      lenderOptions = [ 
        {name: 'Please Select', value: ''}, 
        {name: 'Other', value: 'OTHER'}, 
        ...lenderOptions 
      ];

    let houseOptions = (houses || []).map(house => ({
      name: this.getPropertyName(house),
      value: house.id 
    }));
    
    houseOptions = [ {name: 'Please Select', value: ''}, ...houseOptions ];

    return (
      <form onChange={() => this.onFormChange()} style={{ height: this.props.displayOpen ? "auto" : "40px" }} >
        <Row>
          <ButtonImageSquare 
          type='button'
          style={{
            float: 'left',
            margin: '0 20px 24px 0',
            backgroundImage: this.props.displayOpen ? `url(${require('../../../img/button/up.png')})` : `url(${require('../../../img/button/down.png')})`
          }} onClick={(e) => this.toggleDisplayOpen(e)}></ButtonImageSquare>
          <Column width={this.props.displayOpen ? "400px" : "220px"}>
            <RenameContainer>
              <HiddenPenEditField name={this.props.name} keyID={this.props.id} />
            </RenameContainer>
          </Column>
          {/* Collapsed Details */}
          <Column width='550px' style={{ paddingRight: "10px", display: this.props.displayOpen ? "none" : "block" }}>
            
            <LoanSplit className={"property-type " + propertyPurposeIcon(this.props.productPurpose)}>
            {!!group && [
              <div key='1' className="value">${formatNumber(this.props.balance)}<span>Lending</span></div>,
              <div key='2' className="value">${formatNumber(group.totalValue)}<span>Value</span></div>,
              <div key='3' className="value">{Math.round((group.totalBalance / group.totalValue) * 100)}%<span>LVR</span></div>,
            ]}
            </LoanSplit>
            
            
            <PropertyType>
              <span>
                {/* {this.props.currentLender || "" + " · " + this.props.interestRate || "" + " " + this.props.loanType || "" + " " + this.props.repaymentType || ""} */}
                {!!lender && lender.name || lenderName} {!!interestRate && (interestRate + '%')} {loanType} {paymentType && (paymentType === 'Interest Only' ? 'IO' : 'P&I')}
              </span>
            </PropertyType>
          </Column>
          <StatusIndicator className={warning ? "error" : "complete"} style={{ paddingRight: "10px", display: this.props.displayOpen ? "none" : "inline-block" }} />

          <LenderContainer width='420px' style={{ display: this.props.displayOpen ? "block " : "none" }}>
            {!!lender ?
              <BankLogo product={{ bank: lender.id, bank_name: lender.name}}/>
            :
              <LenderName>{lenderName}</LenderName>
            }
            
          </LenderContainer>

          {!isDeleted ?
            <DeleteButton type='button' onClick={(e) => this.toggleDelete(e)}>
              <div className="confirm-delete" onClick={() => this.deleteRestoreSplit()} style={{ display: this.state.displayDelete ? "none" : "block" }}>
                Click here to delete
              </div>
            </DeleteButton>
          :
            <UndeleteButton type='button' onClick={(e) => this.toggleDelete(e)}>
              ♻
              <div className="confirm-restore" onClick={() => this.deleteRestoreSplit(false)} style={{ display: this.state.displayDelete ? "none" : "block" }}>
                Click here to restore
              </div>
            </UndeleteButton>
          }
        </Row>

        <div style={{
          display: this.props.displayOpen ? "block" : "none"
        }}>
          <Row>
            <SubColumns width='50%'>
              <SecondaryColumn width='50%' >
                <Label>Current Lender</Label>
                <Select name='lenderId' options={lenderOptions} onChange={() => this.onFormChange()}/>
              </SecondaryColumn>
              <SecondaryColumn width='50%'>
                <Label>Loan Balance<ErrorDot name='balance'/></Label>
                <MoneyInputInt name='balance'  />
              </SecondaryColumn>
            </SubColumns>
            <SubColumns width='50%'>
              <SecondaryColumn width='50%'>
                <Label>Loan Limit</Label>
                <MoneyInputInt name='loanLimit'  />
              </SecondaryColumn>
              <SecondaryColumn width='50%'>
                <Label>Offset Balance</Label>
                <MoneyInputInt name='offsetBalance' />
              </SecondaryColumn>
            </SubColumns>
          </Row>

          <Row>
            <SubColumns width='50%'>
              <SecondaryColumn width='50%'>
                <Label>Interest Rate</Label>
                <PercentInput name='interestRate' />
              </SecondaryColumn>
              <SecondaryColumn width='50%'>
                <Label>Repayment Type</Label>
                <Select width='100%' options={repaymentTypeOptions} name='paymentType' onChange={() => this.onFormChange()}/>
              </SecondaryColumn>
            </SubColumns>
            <SubColumns width='50%'>
              <SecondaryColumn width='50%'>
                <Label>Loan Type</Label>
                <Select width='100%' options={LoanTypeOptions} name='productType' onChange={() => this.onFormChange()}/>
              </SecondaryColumn>
              <SecondaryColumn width='50%'>
                <Label>Remaining Loan Term</Label>
                <InputGroup>
                  <NumberInput width='40%' name='remainingTerm' />
                  <Select width='60%' options={remainingLoanTermOptions} name='remainingTermUnit' onChange={() => this.onFormChange()}/>
                </InputGroup>
              </SecondaryColumn>
            </SubColumns>
          </Row>

          <Row>
            <Column width='50%'>
              <Label>Security<ErrorDot name='security'/></Label>
              <Select width='100%' options={this.state.groupOptions} name='security' onChange={() => this.onFormChange()} />
            </Column>
            <SubColumns width='50%'>
              <SecondaryColumn width='65%'>
                <Label>
                  Repayments 
                  {this.props.repayments === this.state.monthlyRepayments && 
                    <span style={{color: '#26BD27', marginLeft: '85px'}}>Auto-calculated</span>
                  }
                </Label>
                <InputGroup>
                  <MoneyInputInt width='50%' name='repayments' />
                  <Select width='50%' options={repaymentOptions} name='repaymentFrequency' onChange={() => this.onFormChange()}/>
                </InputGroup>
              </SecondaryColumn>
              <SecondaryColumn width='35%'>
                <ButtonPlain type='button' style={{ marginTop: "25px" }} width="100%" onClick={() => this.autocalcRepayments()}>Recalculate</ButtonPlain>
              </SecondaryColumn>
            </SubColumns>
          </Row>

          <Row>
            <Column width='50%'>
              <Label>Which property was the loan used for?</Label>
              <Select width='100%' options={houseOptions} name='houseId' onChange={() => this.onFormChange()} disabled={(this.props.productPurpose || '').includes('Other')}/>
            </Column>
            <SubColumns width='50%'>
              <SecondaryColumn width='50%'>
                <Label>Loan Purpose<ErrorDot name='productPurpose'/></Label>
                <Select width='100%' options={propertyPurposeOptions} name='productPurpose' onChange={() => this.onFormChange()}/>
              </SecondaryColumn>
              <SecondaryColumn width='50%'>
                <Label>Loan Introducer<ErrorDot name='loanIntroducer'/></Label>
                <Select width='100%' options={LoanIntroducerOptions} name='loanIntroducer' onChange={() => this.onFormChange()}/>
              </SecondaryColumn>
            </SubColumns>
          </Row>

          <Row>
            <Column width='50%'>
              <Label>Notes</Label>
              <TextArea height='150px' name='notes' />
            </Column>
            <SubColumns width='50%'>
              <SecondaryColumn width='33%'>
                <Label>Annual Fee</Label>
                <MoneyInputInt name='annualFee' />
              </SecondaryColumn>
              <SecondaryColumn width='33%'>
                <Label>Discharge</Label>
                <MoneyInputInt name='dischargeFee' />
              </SecondaryColumn>
              <SecondaryColumn width='33%'>
                <Label>Fixed Expiry</Label>
                <DateInput name='fixedRateExp' />
              </SecondaryColumn>
              
            </SubColumns>
            <Column width='25%'>
                <Label>Interest Only Expiry</Label>
                <DateInput name='ioTermExp' disabled={this.props.paymentType !== 'Interest Only'} />
            </Column>
            {this.props.lenderId === 'OTHER' &&
            <Column width='25%'>
              <Label>Lender Name</Label>
              <Input name='lenderName' />
            </Column>
            }
          </Row>
          
          <Row>
            {validationMessage(!warning)}
          </Row>
        </div>
      </form>
    );
  }
}

function formatNumber(n = 0, d = 0) {
  if (!parseInt(n)) return '-'
  return parseInt(n)
    .toFixed(d)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const calculateRepayments = ({
  loanAmount,
  interestRate,
  repaymentType,
  loanPeriod,
  repaymentFrequency,
}) => {
  let result = 0
  let ratio = timeUnitToRatio(repaymentFrequency);
  if (repaymentType === 'Principal & Interest') {
    result = calculateMonthlyRepayments(interestRate * ratio, loanPeriod / ratio, loanAmount) || 0;
  } else if (repaymentType === 'Interest Only') {
    result = interestRate * loanAmount * ratio; 
  }
  if (result) {
    return Number.parseInt(result);
  }
}

function calculateMonthlyRepayments(rate, period, loanAmount) {
  var q = Math.pow(1 + rate, period);
  return loanAmount * q * rate / (q - 1);
}

const formValidation = values => {
  let errors = { _warning: false, balance: false, security: false, productPurpose: false, loanIntroducer: false };

    let { balance, security, productPurpose, loanIntroducer } = values;

    if (!balance) {
      errors = {...errors, balance: true, _warning: true };
    }

    if (!security) {
      errors = {...errors, security: true, _warning: true };
    }

    if (!productPurpose) {
      errors = {...errors, productPurpose: true, _warning: true };
    }

    if (!loanIntroducer) {
      errors = {...errors, loanIntroducer: true, _warning: true };
    }

    return errors;
} 

const parseDate = raw => {
  if (!raw || raw.length !== 8) return;

  const day = parseInt(raw.slice(0,2));
  const month = parseInt(raw.slice(2,4));
  const year = parseInt(raw.slice(4,8));
    
  if (!(day && month && year)) return;
   
  const date = new Date(year, month - 1, day);
  
  if (isNaN(date)) return;
   
  if (day !== date.getDate() ||
    (month - 1) !== date.getMonth() ||
    year !== date.getFullYear()) 
      return;
  
  return date;
}

const propertyPurposeOptions = [
  { name: 'Please Select', value: ''},
  { name: 'Owner Occupied', value: 'Owner Occupied' },
  { name: 'Investment', value: 'Investment' },
  { name: 'Vacant', value: 'Vacant' },
  { name: 'Holiday', value: 'Holiday' },
  { name: 'Other', value: 'Other'},
  { name: 'Other - Investment', value: 'Other - Investment'},
];

const repaymentTypeOptions = [
  { name: 'Please Select', value: ''},
  { name: 'Principal & Interest', value: 'Principal & Interest' },
  { name: 'Interest Only', value: 'Interest Only' },
];

const remainingLoanTermOptions = [
  { name: 'Years', value: 'Years' },
  { name: 'Month', value: 'Months' },
];

const repaymentOptions = [
  { name: 'Monthly', value: 'Monthly' },
  { name: 'Fortnightly', value: 'Fortnightly' },
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Annually', value: 'Annually' },
];

const LoanTypeOptions = [
  { name: 'Please Select', value: ''},
  { name: 'Variable', value: 'Variable' },
  { name: 'Fixed', value: 'Fixed' }
]

const LoanIntroducerOptions = [
  { name: 'Please Select', value: ''},
  { name: 'Other', value: 'Other' },
  { name: 'Loan Base', value: 'Loan Base' }
]


const LenderContainer = styled(BaseColumn)`
  padding: 0 40px 24px 0;
  text-align: right;
  padding-right: 20px;

  img {
    object-fit: contain;
    height: 40px;
    max-width: 100px;
  }
`;

const LenderName = styled.div`
  display: inline;
  line-height: 40px;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
`;

const LoanSplit = styled.div`
  display: inline-block;
  position: relative;
  vertical-align:top;
  padding-left: 40px;
  padding-top: 10px;
  height: 40px;
  line-height: 20px;
  width: 240px;
  background-size: auto 25px;
  background-position: 0% 50%;
  background-repeat: no-repeat;

  .value {
    display: inline-block;
    vertical-align:top;
    font-size: 14px;
    text-align: center;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }

    span {
      display: block;
      font-size: 12px;
      color: #727C8F;
    }
  }
`;

const HiddenInput = styled(Input)`
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0 50px 0 0;
  text-overflow: ellipsis;
  background-image: url(${pen});
  background-position: Calc(100% - 15px) 50%;
  background-size: 17px 17px;
  background-repeat: no-repeat;

  &:focus {
    background-image: none;
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px 0 12px;
  }
`;

const AutoRename = styled(ButtonPlain)`
  display: none;
  font-size: 11px;
  height: 18px;
  line-height: 16px;
`;

const RenameContainer = styled.div`
  position: relative;

  &:hover {
    button {
      display: block;
    }
  }
`;

const UndeleteButton = styled(ButtonImageSquare)`
  float: left;
  margin: 0 20px 24px 0;
  background-size: 14px 14px;
  position: relative;
  color: #2291FF;
  font-size: 20px;

  &:focus {
    border: 1px solid #D8DCE7;
  }

  .confirm-restore {
    display: block;
    font-size: 12px;
    color: #2291FF;
    position: absolute;
    top: 37px;
    border: 1px solid #d8dce7;
    box-shadow: 0 1px 6px 0 rgba(216,220,231,.65);
    border-radius: 4px 0 4px 4px;
    right: -1px;
    width: 130px;
    padding: 10px 5px;
    background-color: #fff;
    z-index: 100;

    &:after {
      position: absolute;
      display: block;
      top: -4px;
      right: 0;
      content: "";
      background-color: #fff;
      width: 38px;
      height: 5px;
    }
  }
`;

const PropertyType = styled.div`
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: top;
  background-size: auto 25px;
  background-position: 0% 50%;
  margin-left: 10px;
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
  width: 265px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-weight: 600;
  }
`;

const DeleteButton = styled(ButtonImageSquare)`
  float: left;
  margin: 0 20px 24px 0;
  background-size: 14px 14px;
  background-image: url(${deleteBin});
  position: relative;

  &:focus {
    border: 1px solid #D8DCE7;
  }

  .confirm-delete {
    display: block;
    font-size: 12px;
    color: #ff4e4c;
    position: absolute;
    top: 37px;
    border: 1px solid #d8dce7;
    box-shadow: 0 1px 6px 0 rgba(216,220,231,.65);
    border-radius: 4px 0 4px 4px;
    right: -1px;
    width: 130px;
    padding: 10px 5px;
    background-color: #fff;
    z-index: 100;

    &:after {
      position: absolute;
      display: block;
      top: -4px;
      right: 0;
      content: "";
      background-color: #fff;
      width: 38px;
      height: 5px;
    }
  }
`;

const StatusIndicator = styled.div`
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: top;
  float:left;
  margin-right: 10px;
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &.error {
    color: #BF7700;
    background-image: url(${statusError});
  }

  &.complete {
    color: #26BD27;
    background-image: url(${statusComplete});
  }
`;

function validationMessage(valid) {
  if (valid) {
    return <div className="validation-message complete">All mandatory items are complete.</div>;
  } else {
    return <div className="validation-message error">Mandatory items incomplete</div>;
  }
}

const timeUnitToRatio = unit => {
  switch(unit) {
    case "Annually": return 1;
    case "Monthly": return 1/12;
    case "Fortnightly": return 1/26;
    case "Weekly": return 1/52;
    default: return 1/12;
  }
}

function propertyPurposeIcon(type) {
  switch (type) {
    case "Owner Occupied": return "owner-occ";
    case "Investment": return "investment";
    case "Holiday": return "holiday";
    case "Vacant": return "vacant";
    default: return "";
  }
}


AddSplit = reduxForm({
  warn: formValidation,
  //enableReinitialize: true,
})(AddSplit);

export default connect(mapStateToProps, mapDispatchToProps)(AddSplit);