import styled from 'styled-components';

import { ButtonPlain } from 'components/Styled/Button';

// images
import imgCommentAdd from 'img/button/comment-add.png';
import imgUpArrow from 'img/button/up-white.png';
import imgDownArrow from 'img/button/down-white.png';
import imgCheckSmall from 'img/button/check-small.png';

export const BtnComment = styled(ButtonPlain).attrs({
  width: '32px',
  height: '32px'
})`
  background-image: url(${imgCommentAdd});
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 7px 7px;
  margin-right: 10px;
  vertical-align: bottom;
`;

export const BtnComplete = styled(ButtonPlain).attrs({
  height: '32px'
})`
  background-image: url(${imgCheckSmall});
  background-repeat: no-repeat;
  background-position: 7px 10px;
  background-size: 12px;
  padding-left: 23px;
`;


export const UpArrow = styled.img.attrs({
  src: imgUpArrow,
})`
  position: relative;
  width: 10px;
  margin-right: 5px;
  bottom: 2px;
`;

export const DownArrow = styled.img.attrs({
  src: imgDownArrow,
})`
  position: relative;
  width: 10px;
  margin-right: 5px;
  bottom: 2px;
`;