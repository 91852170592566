import React, { Component, useContext, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ApplicationCard } from './';
import styled from 'styled-components';

import iconTotal from 'img/icons/stage-total.svg';
import iconOffset from 'img/icons/stage-offset.svg';
import iconDifference from 'img/icons/stage-difference.svg';
import moment from 'moment';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { requestUpdateApplications } from 'modules/applications/actions';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';
import { Column, Row } from 'components/Styled/Layout';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Select from 'components/Common/Select';
import html from 'util/html';
import SmsTemplateModal from './Modals/SmsTemplateModal';
import { createStage, updateStage } from 'modules/applications/actions';
import EmailTemplateModal from './Modals/EmailTemplateModal';

const $ = window.$;
class CollapsableCard extends Component {
  constructor() {
    super();
    this.state = {
      menuIsOpen: false,
      menuX: 0,
      menuY: 0,
      pagingActivated: false
    };
  }

  setMenuIsOpen(value) {
    this.setState({ menuIsOpen: value });
  }

  toggleCollapsed = () => {
    this.props.toggleCollapsed();
  }
  // this.setState({ isCollapsed: !this.state.isCollapsed });

  componentDidMount() {
    const { name, user, data } = this.props;
    if (user.userType !== 'admin') return;
    $(document).on('click.list' + name, e => {
      this.setState({ menuIsOpen: false });
    });

    $(document).on('contextmenu.list' + name, e => {
      if (e.target.closest('#list-context-menu-trigger-' + name)) return;
      this.setState({ menuIsOpen: false });
    });

    $('#list-context-menu-trigger-' + name).on('contextmenu.list' + name, e => {
      this.setState({ menuIsOpen: true, menuX: e.pageX, menuY: e.pageY });
      e.preventDefault();
    });

    if (data.length > 20) {
      this.setState({ pagingActivated: true });
    }
  }

  componentWillUnmount() {
    const { name, user } = this.props;
    if (user.userType !== 'admin') return;
    $(document).off('contextmenu.list' + name);
    $(document).off('click.list' + name);
    $('#list-context-menu-trigger-' + name).off();
  }

  render() {
    const { 
      title,
      data,
      name, 
      dragCancelled, 
      isCancelTarget,
      urlPath, 
      isCollapsed,
      page,
      stage,
      userDocuments,
    } = this.props;
    
    let totalMoney = (data ?? []).reduce((tot, app) => tot + (parseInt(app.loanAmount || 0) ?? 0), 0);
    let totalOffset = (data ?? []).reduce((tot, app) => tot + (parseInt(app.offsetAmount || 0) ?? 0), 0);
    let totalDifference = totalMoney - totalOffset;
    
    const pagedData = this.state.pagingActivated ? data.slice(0, 20) : data;

    return <>

      <Droppable 
        droppableId={name + ''} 
        isDropDisabled={false}
      >
        {(provided, snapshot) =>
          <div 
            className={`collapsable-card ${isCollapsed ? 'closed' : 'open'} ${snapshot.isDraggingOver || (dragCancelled && isCancelTarget) ? 'drag-active' : ''}`}
          >
            <button
              className='btn-collapse'
              onClick={this.toggleCollapsed} />
            <div className='title' id={'list-context-menu-trigger-' + name}>
              <div className='main-title'>{title}</div>
              <div className='sub-title'>
                {!!totalMoney && <>
                  <span style={{ color: '#2291FF' }}>
                    <Icon src={iconTotal}/>
                    {toMoneyString(totalMoney)}
                  </span>
                  {!!totalOffset && <>
                    <span style={{ color: '#26BD27', marginLeft: '5px' }}>
                      <Icon src={iconDifference}/>
                      {toMoneyString(totalDifference)}
                    </span>
                    <span style={{ color: '#FF4E4C', marginLeft: '5px' }}>
                      <Icon src={iconOffset}/>
                      {toMoneyString(totalOffset)}
                    </span>
                    
                  </>}
                  <div className='card-count'><b>#</b> {data?.length ?? 0}</div>
                </>}
                
              
              </div>
            </div>
            <div
              className={`list-container ${snapshot.isDraggingOver || dragCancelled ? 'active' : ''}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {pagedData
                // .sort(sortByUrgency)
                .map((value, index) => {
                  value.client = { ...value.client, documents: userDocuments[value.userId] };
                  return (
                    <ApplicationCard 
                      key={value.id} 
                      data={value} 
                      index={index}
                      isDisabled={isCollapsed} 
                      urlPath={urlPath}
                      page={page}
                    />
                  );
                })}

              {provided.placeholder}
            </div>
            {!!this.state.pagingActivated &&
              <ButtonPrimary width='100%' onClick={() => this.setState({ pagingActivated: false })}>Show All</ButtonPrimary>
            }
          </div>
          
        }
      </Droppable>
      
      {this.state.menuIsOpen &&
        <Menu setMenuIsOpen={this.setMenuIsOpen.bind(this)} data={{ applications: data, id: name, page, title, stage }} style={{ top: this.state.menuY, left: this.state.menuX }} />
      }

    </>;
  }
}


const Icon = styled.img`
  position: relative;
  top: 1px;
  margin-right: 3px;
`;

const ContextMenu = styled.ul`
  position: fixed;
  /* background-color: #838383; */
  background-clip: padding-box;
  /* border: 1px solid rgba(0,0,0,.15);
  border-radius: 8px;
  color: #f5f5f5; */
  font-size: 13px;
  margin: 10px 0 0;
  min-width: 160px;
  outline: none;
  padding: 8px 0; 
  text-align: left;
  z-index: 9999;
  border: 1px solid #E9EBF1;
  border-radius: 5px;
  background-color: white;
  color: black;
`;

const MenuItem = styled.li`
  background: 0 0;
  border: 0;
  //color: #ffffff;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;

  &:hover {
    /* background-color: #9e9e9e;
    border-color:  #9e9e9e; */
    color: #2291FF;
  }
`;

const Menu = ({ data, style, setMenuIsOpen }) => {
  const { openModal } = useContext(modalContext);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const stages = useSelector(state => state.applications.stages);

  const openSendModal = () =>
    () => {
      setMenuIsOpen(false);
      openModal({
        component: SendModal,
        props: {
          data,
          onSubmit: stageId => {
            const newStage = stages[stageId];
            const userProfile = user.profile;
            const brokerName = userProfile && `${userProfile.firstName} ${userProfile.lastName}`;
            let minOrder = newStage.applications.reduce((min, app) => app.listOrder < min ? app.listOrder : min, 0);
            minOrder = Math.floor(minOrder);
            let n = data.applications.length;
            for (const app of data.applications.sort((a,b)=>a.listOrder-b.listOrder)) {
              dispatch(requestUpdateApplications({
                id: app.id,
                listOrder: minOrder - n,
                list: newStage.id,
                note: { 
                  body: html`The deal was moved from <b>${data.title}</b> to <b>${newStage.name}</b> ${(brokerName ? 'by ' + brokerName : '')}`,
                  brokerId: 'system'
                }
              }));
              n--;
            }
          }
        }
      });
    };

  const openArchiveModal = () =>
    () => {
      setMenuIsOpen(false);
      openModal({
        component: ArchiveModal,
        props: {
          data,
          onSubmit: () => {
            const userProfile = user.profile;
            const brokerName = userProfile && `${userProfile.firstName} ${userProfile.lastName}`;
            for (const app of data.applications) {
              dispatch(requestUpdateApplications({
                id: app.id,
                isArchived: true,
                note: { 
                  body: 'The deal was archived ' + (brokerName ? 'by ' + brokerName : ''),
                  brokerId: 'system'
                }
              }));
            }
          }
        }
      });
    };

  const openSmsModal = () =>
    () => {
      setMenuIsOpen(false);
      openModal({
        component: SmsTemplateModal,
        props: {
          initialValues: data.stage,
          onSubmit: values => {
            if (!values.smsSender) values.smsSender = 'broker';
            if (values.isNew) {
              delete values.isNew;
              values = { ...values, stageIndex: 0, hidden: true, canBeDeleted: true };
              dispatch(createStage(values));
            } else {
              dispatch(updateStage(values));
            }
          }
        }
      });
    };

  const openEmailModal = () =>
    () => {
      setMenuIsOpen(false);
      openModal({
        component: EmailTemplateModal,
        props: {
          initialValues: data.stage,
          onSubmit: values => {
            if (!values.smsSender) values.smsSender = 'broker';
            if (values.isNew) {
              delete values.isNew;
              values = { ...values, stageIndex: 0, hidden: true, canBeDeleted: true };
              dispatch(createStage(values));
            } else {
              dispatch(updateStage(values));
            }
          }
        }
      });
    };

  return (
    <ContextMenu style={style}>
      <MenuItem onClick={openArchiveModal()}>Archive all items</MenuItem>
      <MenuItem onClick={openSendModal()}>Move all items</MenuItem>
      <MenuItem onClick={openSmsModal()} >SMS Automation</MenuItem>
      <MenuItem onClick={openEmailModal()} >E-Mail Automation</MenuItem>
    </ContextMenu>
  );
}; 

const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

const ConfirmButton = styled(ButtonPrimary)`
  transition: all 1s;
`;

const SendModalBase = ({ modal, onSubmit, handleSubmit, data }) => {

  const [ needsConfirm, setNeedsConfirm ] = useState(false);
  const [ confirmationActive, setConfirmationActive ] = useState(false);
  const [ stage, setStage ] = useState(data.id);

  const stages = useSelector(state => state.applications.stages);
  const stageOptions = Object.values(stages)
    .filter(x => x.page === data.page)
    .map(({ name, id }) => ({ name, value: id }));

  const submit = () => {
    if (needsConfirm) {
      if (!confirmationActive) return; 
      modal.close();
      onSubmit(stage);
    } else {
      setNeedsConfirm(true);
      // make sure a double click doesn't accidentally trigger confirmation
      setTimeout(() => setConfirmationActive(true), 500);
    }
  };
  
  return (
    <ModalBox style={{ width: '400px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Move All Items</SmallHeading>
        </Row>
        <Label>Select Stage</Label>
        <Row margin='0 0 20px 0'>
          <Column width='100%' margin='0 10% 0 0'>
            <Select onChange={setStage} value={stage} options={stageOptions} />
          </Column>
  
        </Row>  
        <Row> 
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={() => modal.close()} width='100%'>Cancel</ButtonPlain>
          </Column>
          <Column width='45%'>
            <ConfirmButton type='submit' width='100%' background={needsConfirm ? '#FF4E4C' : '#2291FF'}>{needsConfirm ? 'Confirm?' : 'OK'}</ConfirmButton>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};

const SendModal = reduxForm({ form: 'send-all'})(SendModalBase);

const ArchiveModalBase = ({ modal, onSubmit, handleSubmit, data }) => {

  const [ needsConfirm, setNeedsConfirm ] = useState(false);
  const [ confirmationActive, setConfirmationActive ] = useState(false);

  const submit = () => {
    if (needsConfirm) {
      if (!confirmationActive) return; 
      modal.close();
      onSubmit();
    } else {
      setNeedsConfirm(true);
      // make sure a double click doesn't accidentally trigger confirmation
      setTimeout(() => setConfirmationActive(true), 500);
    }
  };
  
  return (
    <ModalBox style={{ width: '400px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row margin='0 0 20px 0'>
          <SmallHeading>Archive All Items</SmallHeading>
        </Row>
        <Row margin='0 0 40px 0'>
          This will archive all items in <b style={{fontWeight: 'bold'}}>{data.title}</b>
        </Row>  
        <Row> 
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={() => modal.close()} width='100%'>Cancel</ButtonPlain>
          </Column>
          <Column width='45%'>
            <ConfirmButton type='submit' width='100%' background={needsConfirm ? '#FF4E4C' : '#2291FF'}>{needsConfirm ? 'Confirm?' : 'Archive'}</ConfirmButton>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};

const ArchiveModal = reduxForm({ form: 'archive-all'})(ArchiveModalBase);

const toMoneyString = amount => {
  if (!amount) {
    return '$-';
  }

  if (amount < 1000) {
    const rounded = Math.round(amount*10)/10;
    return `$${rounded}`;
  }
    
  if (amount < 1000000) {
    const rounded = Math.round(amount/100)/10;
    return `$${rounded}K`;
  }

  const rounded = Math.round(amount/10000)/100;
  return `$${rounded}M`;
};

const stateToProps = ({user, documents }) => ({user, userDocuments: documents.users });

export default connect(stateToProps)(CollapsableCard);
