import formatNumber from './utils/formatNumber';
import moment from 'moment';

const populateAdditionalDetails = (_content, user) => {
  let content = _content;
  const loanbaseCashback = user.meta.miniproposal?.overview?.loanbaseCashback ?? {};
  const lenderCashback = user.meta.miniproposal?.overview?.lenderCashback ?? {};
  const banks = user.lenders;

  const loanbaseCashbackValue = '$' + formatNumber(loanbaseCashback.rebateAmount);
  let loanbaseCashbackExpiry = '';
  if (loanbaseCashback.hasEndDate && loanbaseCashback.endDate) {
    const expiryDate = moment(loanbaseCashback.endDate);
    loanbaseCashbackExpiry = expiryDate.format('Do MMMM YYYY');
  } else {
    loanbaseCashbackExpiry = 'the expiry date';
  }

  const lenderCashbackValue = '$' + formatNumber(lenderCashback.rebateAmount);

  let lenderCashbackExpiry = '';
  if (lenderCashback.hasEndDate && lenderCashback.endDate) {
    const expiryDate = moment(lenderCashback.endDate);
    lenderCashbackExpiry = expiryDate.format('Do MMMM YYYY');
  } else {
    lenderCashbackExpiry = 'the expiry date';
  }

  let lenderName = '';
  if (lenderCashback.lender === 'other') {
    lenderName = lenderCashback.lenderName;
  } else {
    const bank = banks.find(x => x.id === lenderCashback.lender);
    if (bank) {
      lenderName = bank.name;
    }
  }

  content = content.replace(/\{loanbasecashbackvalue\}/g, loanbaseCashbackValue);
  content = content.replace(/\{loanbasecashbackexpiry\}/g, loanbaseCashbackExpiry);
  content = content.replace(/\{lendercashbackvalue\}/g, lenderCashbackValue);
  content = content.replace(/\{lendercashbackexpiry\}/g, lenderCashbackExpiry);
  content = content.replace(/\{lendername\}/g, lenderName);

  return content;
};

export default populateAdditionalDetails;