import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";

import Navigation from "components/Navigation/Navigation";
import { NavLink } from "react-router-dom";
import { requestPasswordReset } from "modules/user/actions";

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPasswordReset
  }, dispatch);
}

function getQueryVariables(query) {
    let values = {};
    if (query) {
      var vars = query.split('?')[1].split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        values[pair[0]] = pair[1];
      }
    }
    return values;
}

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = { password: '', confirmPassword: '', submitted: false  };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.password) {
      if (this.state.password === this.state.confirmPassword) {

        let values = getQueryVariables(this.props.location.search);


        this.props.requestPasswordReset({
          data: {
            password: this.state.password,
            email: values.email,
            token: values.token
          },
          callback:(result)=>{
            if (result.err) {
              alert(result.err.message);
            } else {
              this.setState(()=>{
                return {submitted: true}
              });
            }
        }});

      } else {
        alert("Passwords don't match!");
      }
    }
    return false;
  }

  render() {
    return (
    <div>
      <Navigation state="unactive" />
      <main className="user-authentication">
        {
          (this.state.submitted)?(
            <div className="modal single">
              <h2>Your password has been successfully changed. Click below to log in:</h2>
                <div className="navigation back">
                  <NavLink exact to="/login">
                    Go to Sign In
                  </NavLink>
                </div>
            </div>
          ):(
            <div className="modal single">
              <h2>Setup your new password</h2>
              <h4>Please enter your new password:</h4>
              <form onSubmit={this.handleSubmit}>
                <label>Password</label>
                <input name="password" placeholder="Enter Password" type="password" onChange={e => this.setState({ password: e.target.value })} />
                <span className="password-requirements">Min, 8 characters. At least 1 letter & 1 number.</span>
                <label>Confirm Password</label>
                <input name="confirm-password" placeholder="Enter Password" type="password" onChange={e => this.setState({ confirmPassword: e.target.value })} />
                <button type="submit">Setup my New Password & Continue</button>
              </form>
            </div>
        )};
      </main>
  </div>
  );
}
}


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
