import store from 'store';
import { receiveUpdateApplications, receiveCreateApplication } from 'modules/applications/actions';
import _ from 'lodash';
import { externalPatchUser } from 'modules/clients/actions';
import { change, reset, initialize } from 'redux-form';
import { applicationService, userService, hostUrl } from 'modules';
import { receiveAddUserDocument, receivePatchUserDocument, receiveRemoveUserDocument } from 'modules/documents/actions';
let is_live_updating = false;

let  pingInterval;

const affectedForms = [
  'deal-client-details',
  'client-details',
  'editBankDetails',
  'miniProposal',
  'submission-comment',
  'submission-applicants',
  'submission-security',
  'submission-other_properties',
  'submission-income',
  'submission-liabilities',
  'submission-expenses',
  'deal-details',
  'deal-notes'
];

const escapedChars = [
  ['%20', ' '], ['%21', '!'], ['%23', '#'],  ['%22', '"'], ['%24', '$'],
  ['%25', '%'], ['%26', '&'], ['%27', '\''], ['%28', '('], ['%29', ')'],
  ['%2A', '*'], ['%2B', '+'], ['%2C', ','],  ['%2D', '-'], ['%2E', '.'],
  ['%2F', '/'], ['%3A', ':'], ['%3B', ';'],  ['%3C', '<'], ['%3D', '='],
  ['%3E', '>'], ['%3F', '?'], ['%40', '@'],  ['%5B', '['], ['%5C', '\\'],
  ['%5D', ']'], ['%5E', '^'], ['%5F', '_'],  ['%60', '`'], ['%7B', '{'],
  ['%7C', '|'], ['%7D', '}'], ['%7E', '~'],
];

const decodeEscChars = str => {
  let result = str;
  for (const [esc, char] of escapedChars) {
    result = result.replaceAll(esc, char);
  }
  return result;
};

const getChanges = (a, b) => {
  //debugger;
  let changes = {};
  for (const key in b) {
    if (!a) changes = { ...changes, [key]: b[key] };
    else if (!a[key] && !b[key]) continue;
    else if (_.isEqual(a[key], b[key])) {
      continue;
    } else if (Object.is(b[key])) {
      changes = { ...changes, [key]: getChanges(a[key], b[key]) };
    } else {
      changes = { ...changes, [key]: b[key] };
    }
  }
  return changes;
};

const getFormFields = (obj, isFromFields) => {
  const fields = {};
  for (const [key, val] of Object.entries(obj)) {
    if (val && typeof val === 'object') {
      const values = getFormFields(val, isFromFields);
      if (isFromFields && ('visited' in values  || 'touched' in values)) { // don't pull apart form.field objects
        fields[key] = val;
      } else {
        for (const [key2, val2] of Object.entries(values)) {
          fields[key + '.' + key2] = val2;
        }
      }
    } else {
      fields[key] = val;
    }
  }
  if (fields['client.address.address']) {
    fields['client.address'] = {
      address: fields['client.address.address'],
      placeId: fields['client.address.placeId'],
      locale: fields['client.address.locale'],
    };
  }

  return fields;
};

const toConvertToBools = [
  'client.isMobileVerified',
  'client.mobileVerificationRequired',
  'client.meta.miniproposal.agreedToProceed',
  'client.isProfessionalReferrer',
  'client.isClientReferrer',
  'client.isMobileVerified',
  'application.showLVR'
];

const updateForms = values => {
  const newFormValues = getFormFields(values);

  for (const key of toConvertToBools) {
    if (newFormValues[key] !== undefined) newFormValues[key] = !!newFormValues[key];
    else delete newFormValues[key];
  }

  const state = store.getState();
  const currentForms = state.form;
 
  for (const [formName, formValues] of Object.entries(currentForms)) {
    if (affectedForms.includes(formName)) {
      let changedFields = [];
      const oldFormValues = getFormFields(formValues.values);
      for (const [fieldName, fieldValue] of Object.entries(newFormValues)) {
        if (formValues.registeredFields[fieldName] && !_.isEqual(oldFormValues[fieldName], fieldValue)) {
          store.dispatch(change(formName, fieldName, fieldValue));
          changedFields.push(fieldName);
        }
      }

      let resetForm = true;
      if (formValues.fields) {
        const fields = getFormFields(formValues.fields, true);
        for (const [ fieldName, fieldValues ] of Object.entries(fields)) {
          if (changedFields.includes(fieldName)) continue;
          if (fieldValues.touched) resetForm = false;
        }
      }

      if (resetForm) {
        const forms = store.getState().form;
        const form = forms[formName];
        store.dispatch(initialize(formName, form.values));
        store.dispatch(reset(formName));
      }
    }
  }
};




function startLiveUpdating() {
  if (is_live_updating) {
    return;
  }

  is_live_updating = true;

  let socket;
  let user_id = Math.floor(Math.random() * 1000);
  
  socket = new WebSocket('wss://s0yn0hvg1f.execute-api.ap-southeast-2.amazonaws.com/production');
  
  socket.onmessage = async function(event) {
    var result;
    try {
      result = JSON.parse(decodeEscChars(event.data));
    } catch (e) {
      return;
    }

    console.log('live update:', result);

    const state = store.getState();
    if (result) {
      if (hostUrl === 'http://localhost:3030' || hostUrl === 'http://api-staging.loanbase.com.au') {
        if (result.host !== 'http://localhost:3030' && result.host !== 'http://api-staging.loanbase.com.au') {
          return;
        }
      } else if (hostUrl !== result.host) {
        return;
      }
      console.log('update accepted');
      const { sessionId } = result;
      if (sessionId && sessionId === sessionStorage.tabID) {
        //console.log('same session detected');
        return;
      }
      
      if (result.field === 'applications') {
        const { applications } = state;
        if (applications.loading) return;
        if (result.method === 'patch') {
          const { brokers } = state;
          const appList = applications.list;
          let newApp = result.result;
          newApp.showLVR = !!newApp.showLVR;
          const oldApp = appList[newApp.id];
          if (!oldApp) { // deal isn't in list so create it
            
            let app = await applicationService.get(newApp.id);
            app = { ...app, ...newApp };
            if (!app.client && app.userId) {
              let client = await userService.get(app.userId);
              app.client = client;
            }
            if (!app.broker && app.brokerId) {
              const broker = brokers.find(x => x.id === app.brokerId);
              app.broker = broker;
            }
            if (!app.processor && app.processorId) {
              const processor = brokers.find(x => x.id === app.processorId);
              app.processor = processor;
            }
            if (state.user.userType === 'processor') {
              if (!app.processorId || !state.user.linkedBrokerId) return;
              if (parseInt(app.processorId) !== parseInt(state.user.linkedBrokerId)) return;
            }
            store.dispatch(receiveCreateApplication(app));
            return;
          }
          const changes = getChanges(oldApp, newApp);
          if (changes.brokerId) {
            const broker = brokers.find(x => x.id === changes.brokerId);
            changes.broker = broker;
          }
          if (changes.processorId) {
            const processor = brokers.find(x => x.id === changes.processorId);
            changes.processor = processor;
          }
          store.dispatch(receiveUpdateApplications({ ...changes, id: newApp.id }));
          //console.log('changes', { ...changes, id: newApp.id });
          if (applications.currentDeal === newApp.id) {
            //console.log('updating forms');
            updateForms({ application: changes });
          }
          
        
        } else if (result.method === 'create') {
          // newly created applications never appear in "current deals" so can be ignored for processors
          if (state.user.userType === 'processor') return; 

          const app = result.result;
          if (!app.broker) {
            const { brokers } = state;
            const broker = brokers.find(x => x.id === app.brokerId);
            app.broker = broker;
          }

          

          store.dispatch(receiveCreateApplication(app));
        }
        
      }

      if (result.field === 'users') {
        if (result.method === 'patch') {
          console.log('patch in process');
          let { current } = _.cloneDeep(state.clients);
          let newUser = result.result;
          newUser.isMobileVerified = !!newUser.isMobileVerified;
          if (current?.id !== newUser.id) current = {};
          const changes = getChanges(current, newUser);
          console.log('changes', { ...changes, id: newUser.id });
          store.dispatch(externalPatchUser({ data: { ...changes, id: newUser.id } }));

          if (current?.id === newUser.id) {
            //console.log('updating forms');
            updateForms({ client: changes });
          }

        } else if (result.method === 'create') {
          // do nothing, users are usually loaded on demand, so no need to do anything
        }
      }

      if (result.field === 'documents') {
        if (result.method === 'patch') {
          //console.log('Patching document:', result.result);
          store.dispatch(receivePatchUserDocument({ data: result.result }));
        } else if (result.method === 'create') {
          //console.log('Creating document:', result.result);
          store.dispatch(receiveAddUserDocument({ data: result.result }));
        } else if (result.method === 'remove') {
          //console.log('Removing document:', result.result);
          store.dispatch(receiveRemoveUserDocument({ id: result.result.id, userId: result.result.userId }));
        }
      }


    }
  };



  socket.onclose = function(event) {
    startLiveUpdating();
    clearTimeout(pingInterval);
  };
  
  socket.onopen = function(event) {
    const state = store.getState();
    let auth = state.user && state.user.websocket_auth;
    
    var message = {
      'action':'sendMessage',
      'type': 'connect_user',
      'host': hostUrl,
      'auth': auth,
      'user_id' : state.user && state.user.id, 
      'auth_timestamp' : ''
    };
  
    socket.send(JSON.stringify(message));

    //ping to keep connection open
    clearTimeout(pingInterval);
    pingInterval = setInterval(function(){
      if (socket.readyState === socket.CLOSED) {
        clearTimeout(pingInterval);
        startLiveUpdating();
        return;
      }
      var message = {
        'action':'sendMessage',
        'type': 'ping'
      };
      socket.send(JSON.stringify(message));
    }, 60000);
  };
}

export default async function liveUpdate() {
  startLiveUpdating();
}