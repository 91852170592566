import React, { Component } from 'react';
import styled from 'styled-components';
import { Table, tableStyle, TableRow, TableCell, TableHeader, TableBody, SortableHeaderCell as HeaderCell } from 'components/Table';
import DragToScroll from 'components/Common/DragToScroll';
import { Modal, ModalContext, ModalSubmit, ModalShow } from 'components/Modal/old';
import { ButtonPrimary } from 'components/Styled/Button';
import Text from 'components/Styled/Text';
import Input from 'components/Styled/TextInput';
import Label from 'components/Styled/Label';
import Select from 'components/Common/Select';
import LabelledInput from 'components/Common/LabelledInput';
import { ToolTip, ToolTipContainer } from 'components/Styled/Tooltip';

import { Row as BasicRow, Column as BasicColumn } from 'components/Styled/Layout';

import imgLink from 'img/button/link-blue.png';
import imgBank from 'img/temp/banks/ADE.png';
import imgEdit from 'img/button/edit-pen.png';
import { NumberInput } from 'components/Common/NumberInput';
import { PercentInput } from 'components/Common/PercentInput';

import Card from 'components/Styled/Card';

export class LoansView extends Component {

  render() {

    return (
      <Card margin="40px 0 0 0" >
        <ModalContext animationTimeout={500} modal={LenderModal} onSubmit={(thing, hide) => {
          hide();
        }}>
          <DragToScroll horizontal style={{ overflow: 'auto', padding: '0px 40px', height: '500px' }}>
            <LoanTable />
          </DragToScroll>
        </ModalContext>
      </Card>
    );
  }
}

class LoanTable extends Component {
  render() {
    return (<Table styling={tableStyle.plain} sortField='1'>
      <TableHeader>
        <TableRow>
          <HeaderCell fieldName='1'>Lender</HeaderCell>
          <HeaderCell fieldName='2'>LB Loan</HeaderCell>
          <HeaderCell fieldName='3'>Security Property</HeaderCell>
          <HeaderCell fieldName='4'>Loan Limit</HeaderCell>
          <HeaderCell fieldName='5'>Balance</HeaderCell>
          <HeaderCell fieldName='6'>Rate</HeaderCell>
          <HeaderCell fieldName='7'>Product Type</HeaderCell>
          <HeaderCell fieldName='8'>Loan Purpose</HeaderCell>
          <HeaderCell fieldName='9'>LVR</HeaderCell>
          <HeaderCell fieldName='10'>Payment Type</HeaderCell>
          <HeaderCell fieldName='11'>Settlement</HeaderCell>
          <HeaderCell fieldName='12'>Fixed Rate Exp.</HeaderCell>
          <HeaderCell fieldName='13'>IO Term Exp.</HeaderCell>
          <HeaderCell fieldName='14'>Broker</HeaderCell>
          <HeaderCell fieldName='15'>Loan BSB</HeaderCell>
          <HeaderCell fieldName='16'>Loan Account</HeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <LoanTableRow thing='hi' />
        <LoanTableRow thing='bye' />
        <LoanTableRow thing='Wooo!' />

      </TableBody>
    </Table>);
  }
}




export const LenderModal = ({ modal }) => (
  <Modal width='800px' minHeight='500px' modal={modal}>
    <ImgLogoModal />
    <Text center size='15px' marginBottom='80px'>Offset Home Loan Package</Text>
    <Row>
      <Column width='50%'>
        <Label>LoanBase Loan</Label>
        <Select options={[{ name: 'Yes', value: '' }, { name: 'No', value: '' }]} reference={el => modal.focusOnStart(el)} />
      </Column>
      <Column width='50%'>
        <Label>Security Property</Label>
        <Input />
      </Column>
      <Column width='25%'>
        <Label>Loan Limit</Label>
        <LabelledInput as={NumberInput} label='$' />
      </Column>
      <Column width='25%'>
        <Label>Balance</Label>
        <LabelledInput as={NumberInput} label='$' />
      </Column>
      <Column width='50%'>
        <Label>LVR</Label>
        <LabelledInput as={PercentInput} label='%' />
      </Column>
      <Column width='50%'>
        <Label>Interest Rate</Label>
        <LabelledInput as={PercentInput} label='%' />
      </Column>
      <Column width='50%'>
        <Label>Revert Rate</Label>
        <LabelledInput as={PercentInput} label='%' />
      </Column>
      <Column width='50%'>
        <Label>Loan Purpose</Label>
        <Select options={[{ name: 'Investment', value: '' }]} />
      </Column>
      <Column width='50%'>
        <Label>Settlement Date</Label>
        <Input />
      </Column>
      <Column width='50%'>
        <Label>Payment Type</Label>
        <Select options={[{ name: 'Interest Only', value: '' }]} />
      </Column>
      <Column width='50%'>
        <Label>Product Type</Label>
        <Select options={[{ name: 'Fixed', value: '' }]} />
      </Column>
      <Column width='50%'>
        <Label>IO Term Expiry</Label>
        <Input />
      </Column>
      <Column width='50%'>
        <Label>Fixed Term Expiry</Label>
        <Input />
      </Column>
      <Column width='50%'>
        <Label>Broker</Label>
        <Select options={[{ name: 'Anna Trusova', value: '' }]} />
      </Column>
      <Column width='20%'>
        <Label>BSB</Label>
        <Input />
      </Column>
      <Column width='30%'>
        <Label>Account Number</Label>
        <Input />
      </Column>
      <Column></Column>
    </Row>
    <ModalSubmit as={ButtonPrimary} width='100%'>Update Loan</ModalSubmit>
  </Modal>
);


const LoanTableRow = ({ thing }) => (
  <TableRow>
    <TableCell>
      <ToolTipContainer>
        <ImgLogo />
        <ToolTip>Tooltip</ToolTip>
      </ToolTipContainer>
      <ModalShow as={EditButton} modalProps={{ thing }} />
    </TableCell>
    <TableCell>Yes <LinkButton /></TableCell>
    <TableCell>Lithgow 2790</TableCell>
    <TableCell>$10,000</TableCell>
    <TableCell>$400,000</TableCell>
    <TableCell>4%</TableCell>
    <TableCell>Varible</TableCell>
    <TableCell>Investment</TableCell>
    <TableCell>72%</TableCell>
    <TableCell>Interest Only</TableCell>
    <TableCell>Settlement</TableCell>
    <TableCell>10/10/2018</TableCell>
    <TableCell>10/10/2018</TableCell>
    <TableCell>Anna Trusova</TableCell>
    <TableCell>Something</TableCell>
    <TableCell>13562112</TableCell>
  </TableRow>
)


// Styled Components
export const Column = styled(BasicColumn)`
  padding: 0 40px 24px 0;
`;

export const Row = styled(BasicRow)`
  margin-right: -40px;
`;

const LinkButton = styled.img.attrs({
  src: imgLink,
  width: 18,
})`
  display: inline-block;
  position: relative;
  top: 3px;
  margin-left: 5px;
  cursor: pointer;
`;

const ImgLogo = styled.img.attrs({
  src: imgBank,
  height: 25,
})`
  display: inline-block;    
  position: relative;
  top: 8px;
`;

const ImgLogoModal = styled.img.attrs({
  src: imgBank,
  height: 40,
})`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const EditButton = styled.img.attrs({
  src: imgEdit,
  height: 12,
})`
  position: relative;
  display: inline-block;
  top: 1px;
  margin-left: 8px;
  cursor: pointer;
`;