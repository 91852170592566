export const makeRequired = 
  message => 
    value => value ? null : message;

export const required = value => value ? null : 'This field is required';

//for dates in the format ddmmyy
export const createValidateDate = (errorString = 'The date entered is invalid') => 
  value => {
    if (!value) return undefined;
    
    const day = parseInt(value.slice(0,2));
    const month = parseInt(value.slice(2,4));
    const year = parseInt(value.slice(4,8));
      
    if (!(day && month && year)) return errorString;

    const date = new Date(year, month - 1, day);
    if (isNaN(date)) return errorString;
    
    // Checks to see if any values overflowed. 99/99/99 would be valid otherwise
    if (day !== date.getDate() ||
      (month - 1) !== date.getMonth() ||
      year !== date.getFullYear()) 
      return errorString;
};


