import styled from 'styled-components';
import imgSearch from 'img/icons/icon-search-blue.png';
import TextInput from 'components/Styled/TextInput';

export default styled(TextInput)`
  padding-left: 35px;
  background-image: url(${imgSearch});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 15px auto;
`;