import { 
  RECEIVE_FIND_DOCUMENTS,
  REQUEST_PATCH_DOCUMENT,
  REQUEST_DELETE_DOCUMENT,
  RECEIVE_FIND_DOCUMENT_TEMPLATES,
  REQUEST_PATCH_DOCUMENT_TEMPLATE,
  REQUEST_CREATE_DOCUMENT_TEMPLATE,
  REQUEST_DELETE_DOCUMENT_TEMPLATE,
  REQUEST_CREATE_DOCUMENT,
  ADD_DOC_TO_TEMPLATE,
  REMOVE_DOC_FROM_TEMPLATE,
  RECEIVE_ADD_USER_DOCUMENT,
  RECEIVE_REMOVE_USER_DOCUMENT,
  RECEIVE_PATCH_USER_DOCUMENT,
} from './actions';

import _ from 'lodash';

function split(arr = [], fn) {
  const a = [], b = [];
  for (const x of arr)
    fn(x) ? a.push(x) : b.push(x);
  return [a, b];
}

const documents = (state = { library: [], templates: [], users: {} }, { type, payload }) => {
  
  switch (type) {
  case RECEIVE_FIND_DOCUMENTS: {
    //debugger;
    const [ userList, library ] = split(payload, x => x.type === 'user');
    const userMap = {};
    for (const doc of userList) {
      if (userMap[doc.userId]) {
        userMap[doc.userId].push(doc);
      } else {
        userMap[doc.userId] = [ doc ];
      }
    }
    return { ...state, library, users: userMap };
  }

  case REQUEST_PATCH_DOCUMENT: {
    //  debugger;
    const document = payload.data;
    // if (document.type === 'library') {
    const index = state.library.findIndex(u => u.id === document.id);
    if (index > -1) state.library[index] = document; 
    return state;
    // } 
    // if (document.type === 'template') {
    //   let template = state.templates.find(x => x.id === document.documentTemplateId);
    //   template.documents = [ ...template.documents.filter(x => x.id !== document.id), document ];
    //   const templates = state.templates.filter(x => x.id !== document.documentTemplateId);
    //   return {...state, templates: [...templates, template] };
    // }
    
    // throw new Error('Document type invalid');
  }
  case REQUEST_CREATE_DOCUMENT: {
    const document = payload.data;
    // document.id = 'undefined';
    document.isUrgent = document.isUrgent ? 1 : 0;
    document.isPropertyDoc = document.isPropertyDoc ? 1 : 0;
    document.isBankStatement = document.isBankStatement ? 1 : 0;
    // const templateId = document.documentTemplateId;
    // if (document.type === 'template' && templateId) {
    //   const template = state.templates.find(({id}) => id === templateId);
    //   const newTemplate = { 
    //     ...template, 
    //     documents: [
    //       ...template.documents ?? [],
    //       document,
    //     ]
    //   };
    //   const newTemplates = [
    //     ...state.templates.filter(({id}) => id !== templateId), 
    //     newTemplate
    //   ];
    //   return {...state, templates: newTemplates };
    // } else {
    return {...state, library: [...state.library, document] };
    // }
  }
  // case RECEIVE_CREATE_DOCUMENT: {
  //   debugger;
  //   const document = payload;
  //   const templateId = document.documentTemplateId;
  //   if (document.type === 'template' && templateId) {
  //     const template = state.templates.find(({id}) => id === templateId);
  //     const newTemplate = { 
  //       ...template,
  //       documents: [
  //         ...template.documents.filter(x => !(x.id === 'undefined' && x.name === document.name)),
  //         document,
  //       ]
  //     };
  //     const newTemplates = [
  //       ...state.templates.filter(({id}) => id !== templateId), 
  //       newTemplate
  //     ];
  //     return {...state, templates: newTemplates };
  //   } else {
  //     return {
  //       ...state, 
  //       library: [
  //         ...state.library.filter(x => !(x.id === 'undefined' && x.name === document.name)), 
  //         document
  //       ] 
  //     };
  //   }
  // }
  case REQUEST_DELETE_DOCUMENT: {
    const { id, type, templateId } = payload;
    if (!id) throw new Error('No id specified');
    // if (type === 'library') {
    return {...state, library: state.library.filter(doc => doc.id !== id) };
    // } 
    // if (type === 'template') {
    //   if (!templateId) throw new Error('No template id specified');
    //   let template = state.templates.find(x => x.id === templateId);
    //   if (!template) throw new Error('Template does not exist');
    //   template.documents = template.documents.filter(x => x.id !== id);
    //   const templates = state.templates.filter(x => x.id !== templateId);
    //   return {...state, templates: [...templates, template] };
    // }

    throw new Error('No valid type specified');

  }
  case RECEIVE_FIND_DOCUMENT_TEMPLATES: {
    return { ...state, templates: payload };
  }
  case REQUEST_PATCH_DOCUMENT_TEMPLATE: {
    const template = payload.data;
    const index = state.templates.findIndex(u => u.id === template.id);
    if (index > -1) state.templates[index] = { ...state.templates[index], ...template }; 
    return state;
  }
  case REQUEST_CREATE_DOCUMENT_TEMPLATE: {
    const template = payload.data;
    return {...state, templates: [...state.templates, template] };
  }
  // case RECEIVE_CREATE_DOCUMENT_TEMPLATE: {
  //   const template = payload;
  //   return {...state, templates: [...state.templates, template] };
  // }
  case REQUEST_DELETE_DOCUMENT_TEMPLATE: {
    return {...state, templates: state.templates.filter(template => template.id !== payload.id) };
  }
  case ADD_DOC_TO_TEMPLATE: {
    const { templateId, documentId } = payload;
    const template = state.templates.find(({id}) => id === templateId);
    const newTemplate = { 
      ...template,
      documents: [
        ...(template.documents ?? []),
        { id: documentId },
      ]
    };
    const newTemplates = [
      ...state.templates.filter(({id}) => id !== templateId), 
      newTemplate
    ];
    return {...state, templates: newTemplates };
  }

  case REMOVE_DOC_FROM_TEMPLATE: {
    const { templateId, documentId } = payload;
    const template = state.templates.find(({id}) => id === templateId);
    const newTemplate = { 
      ...template,
      documents: [
        ...(template.documents ?? []).filter(x => x.id !== documentId),
      ]
    };
    const newTemplates = [
      ...state.templates.filter(({id}) => id !== templateId), 
      newTemplate
    ];
    return {...state, templates: newTemplates };
  }
  case RECEIVE_ADD_USER_DOCUMENT: {
    const doc = _.cloneDeep(payload.data);
    if (!doc.userId) return state;
    const userDocs = state.users[doc.userId] ?? [];
    return { 
      ...state, 
      users: { 
        ...state.users, 
        [doc.userId]: [ 
          ...userDocs, 
          doc
        ] 
      } 
    };
  }
  case RECEIVE_REMOVE_USER_DOCUMENT: {
    const { id, userId } = payload;
    const users = _.cloneDeep(state.users);
    users[userId] = users[userId].filter(x => x.id !== id);
    return { ...state, users };
  }
  case RECEIVE_PATCH_USER_DOCUMENT: { 
    const doc = _.cloneDeep(payload.data);
    for (const key in doc) {
      if (!doc[key]) delete doc[key];
    }
    if (!doc.userId) return state;
    const users = _.cloneDeep(state.users);
    const oldDoc = users[doc.userId].find(x => x.id === doc.id);
    users[doc.userId] = users[doc.userId].filter(x => x.id !== doc.id);
    users[doc.userId].push({ ...oldDoc, ...doc });
    return { ...state, users };
  }
  default:
    return state;
  }
};



export default documents;

