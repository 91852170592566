import React, { Component } from 'react';

import { TabBar, Tab } from 'components/Styled/TabBar';
import styled from "styled-components";
import { Row, Column } from "components/Styled/Layout";
import Card from 'components/Styled/Card';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import SearchBar from 'components/Common/SearchBar';
import { Modal, ModalContext, ModalShow } from 'components/Modal/old';

import Label from 'components/Styled/Label';
import TextArea from 'components/Styled/TextArea';


import imgEditNote from 'img/icons/edit-square-dark.png';
import imgEmail from 'img/icons/email-dark.png';
import imgToDo from 'img/icons/todo-dark.png';

class DealHistory extends Component {
  constructor() {
    super();
    this.state = {
      currentTab: 'all',
    };
  }
  render() {
    const { currentTab } = this.state;
    return (
      <Card minWidth='650px' maxWidth='800px' padding='0'>
        <TabBar height={60} tabWidth='auto' tabMargin={25} marginBottom={1}>
          <Tab className={currentTab === 'all' ? 'current' : ''} onClick={() => this.setState({ currentTab: 'all' })}>
            All
          </Tab>
          <Tab className={currentTab === 'notes' ? 'current' : ''} onClick={() => this.setState({ currentTab: 'notes' })}>
            Notes(5)
          </Tab>
          <Tab className={currentTab === 'tasks' ? 'current' : ''} onClick={() => this.setState({ currentTab: 'tasks' })}>
            Tasks(3)
          </Tab>
          <Tab className={currentTab === 'emails' ? 'current' : ''} onClick={() => this.setState({ currentTab: 'emails' })}>
            Emails(0)
          </Tab>
          <div style={{float:'right', margin:'10px 10px 0 0'}}>
            <SearchBar placeholder='Search' onChange={() => {}}/>
          </div>
        </TabBar>
        <ul>
          <ListItem type='email'/>
          <ListItem type='note'/>
          <ListItem type='task'/>
          <ListItem type='note'/>
          <ListItem type='email'/>
        </ul>
        <ButtonPlain width='calc(100% - 80px)' style={{margin: '40px'}}>Show More</ButtonPlain>
      </Card>
    );
  }
}

const Circle = styled.div`
  width: ${({ diameter }) => diameter};
  height: ${({ diameter }) => diameter};
  background: ${({ color }) => color};
  border-radius: ${({ diameter }) => diameter};
  border: 1px solid ${({ borderColor, color }) => borderColor || color};
`;

const IconCircle = styled(Circle)`
  &:before {
    background-image: url(${({img}) => img});
    background-size: 16px;
    background-position: ${({offsetX}) => offsetX}px ${({offsetY}) => offsetY}px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100px; 
    height: 100px;
    content:"";
  }
`;

const TaskTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #101922;
  margin-bottom: 9px;
`;

const TaskDetails = styled.p`
  font-size: 13px;
  color: #575E6C;
`;

const ClickableDiv = (props) => <div {...props} style={{cursor: 'pointer'}}/>; 

const ListItem = ({ type }) => (
  <li>
    <ModalContext modal={DetailsModal} animationTimeout={500} onSubmit={(data, hide) => hide()}>
      <ModalShow as={ClickableDiv}>
        <Row style={{paddingTop: '40px'}}>
          <Column width='100px' style={{paddingLeft: '40px'}}>
            {type === 'email' && <IconCircle img={imgEmail} offsetX={11} offsetY={13} diameter='40px' color='#F6F7FB' borderColor='#D8DCE7'/>}
            {type === 'note' && <IconCircle img={imgEditNote} offsetX={11} offsetY={11} diameter='40px' color='#F6F7FB' borderColor='#D8DCE7'/>}
            {type === 'task' && <IconCircle img={imgToDo} offsetX={11} offsetY={11} diameter='40px' color='#F6F7FB' borderColor='#D8DCE7'/>}
          </Column>
          <Column width='calc(100% - 100px)'>
            <TaskTitle>Hello this is a title</TaskTitle>
            <TaskDetails>06/04/1978 5:20am</TaskDetails>
          </Column>
        </Row>
      </ModalShow>
    </ModalContext>
    
  </li>
);

const DetailsModal = ({ modal }) => (
  <Modal width='600px' modal={modal}>
    <Row>
      <Label>Placeholder Modal</Label>
      <TextArea/>
    </Row>
  </Modal>
);

export default DealHistory;
