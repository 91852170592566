import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PathNavLink } from 'components/Common/PathNavLink.jsx';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { ButtonImageLeft, ButtonPrimary } from 'components/Styled/Button';

import { requestPatchUser, requestCreateHouse } from 'modules/clients/actions';
import { getQueryVariables } from 'util/index';

import { requestUser } from 'modules/clients/actions';
import Card from 'components/Styled/Card';
import Details from './components/Details';
import AddProperty from '../Forms/AddProperty';
import intToOrdinal from 'util/int-to-ordinal';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

const $ = window.$;

class PropertyDetails extends Component {

  state = {
    newHouseLoading: false,
  }
  
  addProperty() {
    const { requestCreateHouse, client, houses} = this.props;
    this.setState({ newHouseLoading: true });
    const houseIds = (houses || []).map(h => h.id.split('-'));
    const nextId = houseIds.reduce((max, id) => Math.max(max, id[1]), 0) + 1;
    const newId = `${client.id}-${nextId}`;
    requestCreateHouse({ 
      userId: client.id, 
      house: {
        id: newId,
        name: `${intToOrdinal((houses || []).length + 1)} Security`,
        address: '',
        value: '',
        valueType: 'X',
        purpose: 'X',
        type: 'X',
        flags: '[]',
        displayOpen: true,
      },
      callback: () => {
        this.setState({ newHouseLoading: false });
      }
    })

    setTimeout(() => {
      $('main').animate({
        scrollTop: $('.form-card').last().offset().top - $('main').offset().top + $('main').scrollTop() - 10
      }, 400);
    }, 10);
  }

  changeOpenStateTo = newState => {
    for (let house of this.props.houses) {
      if (house.displayOpen === newState) continue;
      this.props.requestPatchUser({
        data: { house: { id: house.id, displayOpen: newState }, id: this.props.client.id },
      });
    }
  }

  render() {
    const filteredHouses = (this.props.houses || []).filter(house => !house.isDeleted);
    return (
      <div>
        <Card borderTopLeftRadius="0px" >
          <Details houses={filteredHouses} toggleGroup={() => () => {}} selectedGroups={[]} />
        </Card>
        {!!filteredHouses.length &&
          <div className="expand-close-buttons">
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(true)}><span style={{ backgroundImage: `url(${require('../../../img/button/down.png')})` }}>Expand All</span></ButtonImageLeft>
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(false)}><span style={{ backgroundImage: `url(${require('../../../img/button/up.png')})` }}>Collapse All</span></ButtonImageLeft>
          </div>
        }
        
        {filteredHouses.map((house)=> {
          return <PropertyBox property={house} key={house.id}/>
        }
        )}
        <div style={{width: '800px', position: 'relative', marginBottom: '40px'}}>
          <ButtonAdd isBig={!filteredHouses.length} onClick={() => this.addProperty()}>
            <img src={require('img/button/plus-white.png')}/>
            Add Property
          </ButtonAdd>
        </div>

      </div>
    );
  }
}

const ButtonAdd = styled(ButtonPrimary)`
  position: absolute;
  margin-top: 20px;

  ${p => p.isBig ? `
    left: 0;
    margin-left: 40px;
    font-size: 24px;
    font-weight: 600;
    padding: 0 30px;
    height: 60px;
    margin-top: 40px;
  ` : `
    left: 620px;
    width: 180px;
  `}

  img {
    ${p => p.isBig ? `
      position: relative;
      height: 17px;
      width: 17px;
      margin-right: 15px;
    ` : `
      position: relative;
      height: 14px;
      width: 14px;
      top: 1px;
      margin-right: 15px;
    `}
  }
`;


class PropertyBox extends Component {
  render() {
    let house = this.props.property
    const flags = JSON.parse(house.flags)

    house = {
      ...house,
      ['future-purchase']: flags.includes('Future Purchase'),
      ['cross-collateralise']: flags.includes('Cross-collateralise'),
      ['being-sold']: flags.includes('Being Sold'),
      address: { address: house.address, placeId: house.placeId, locale: house.locale },

    }
    return (
      <Card maxWidth="800px" margin="20px 0 0 0" className={'form-card ' + (house.deleting ? 'card-fade-out add-property ' : 'add-property-card ') + (house.displayOpen ? '' : 'collapsed')}>
        <AddProperty property={house} form={'addProperty' + this.props.property.id} initialValues={house} />
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestUser,
    requestCreateHouse,
  }, dispatch);

const mapStateToProps = ({ clients }) => ({ houses: clients.current.houses, client: clients.current });

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);

