import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';


export default ({ name }) => {
  return <Field name={name} component={ErrorDot}/>
}

const ErrorDot = ({ meta: { warning } }) => {
  if (warning) return <Dot color='#D0021B'/>;
  else return <Dot color='#26BD27'/>;
}

const Dot = styled.span`
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 7px;
  background-color: ${p => p.color};
  margin: 0 0 1px 5px;
  vertical-align: middle;
`