import React, { Component } from 'react';
import styled from 'styled-components';

import { intToOrdinal, getQueryVariables } from 'util/index';

import Card from 'components/Styled/Card';
import { Row as BaseRow, Column as BaseColumn, PullRight, PullLeft } from 'components/Styled/Layout';
import Input from 'components/Styled/TextInput';
import TextArea from 'components/Styled/TextArea';
import Label from 'components/Styled/Label';
import Select from 'components/Common/Select';
import InputGroup from 'components/Common/InputGroup';
import BackButton from 'components/Common/BackButton';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';

import Form from './SecuritiesForm';



class ApplicationsSecurities extends Component {

  render() {
    const { id } = getQueryVariables(this.props.location.search);
    return (
      <Form onSubmit={this.onSubmit} initialValues={{securities: [{}]}}/>
    );
  }

}
const Heading = styled.h2`
  font-size: 20px;
`;

export default ApplicationsSecurities;