import React, { Component } from 'react';

export class PercentInput extends Component {
  state = {
    currentValue: 0,
    currentString: '',
  }

  ref = React.createRef();

  handleEvent = e => {
    const input = e.target;
    
    var newValue = input.value.replace(/%/g, '');
    if (newValue !== '') newValue += '%';
    input.value = newValue;
    if (input.selectionEnd === input.value.length) {
      input.selectionEnd = input.value.length - 1;
    }
  }

  componentDidMount() {
    const input = this.ref.current;
    ['mouseup', 'mousedown'].forEach(
      (type) => input.addEventListener(type, this.handleEvent)
    );
  }

  componentWillUnmount() {
    const input = this.ref.current;
    ['click'].forEach(
      (type) => input.removeEventListener(type, this.handleEvent)
    );
  }

  onChange = e => {
    // const input = e.target;
    // if (input.value === '') return;
    // const { currentValue, currentString } = this.state;
    // const value = input.value.replace(/%/g, '');
    // const number = parseFloat(value);

    // if (!isNaN(number) && number !== currentValue) {
    //   const percentString = number.toString() + '%';
    //   input.value = percentString;
    //   if (input.selectionStart === input.value.length) {
    //     input.selectionStart -= 1;
    //     input.selectionEnd = input.selectionStart;
    //   }
    //   this.setState({ currentValue: number, currentString: percentString });
    // } else {
    //   input.value = currentString;
    // }
    // if (this.props.onChange) this.props.onChange(e);
  }

  render() {
    const {onChange, ...props } = this.props;
    return (
      <input onChange={this.handleEvent(onChange)} {...props} ref={this.ref} />
    );
  }
}
