import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';



import Card from 'components/Styled/Card';
import { Row, Column, PullRight } from 'components/Styled/Layout';
import Label from 'components/Styled/Label';

import Txt from 'components/Styled/Text';

import { ToggleButton, Input, PercentInput, MoneyInput, Select, TextArea } from 'components/Form';


import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';

import imgTrash from 'img/button/trash-red.png';


const FullColumn = styled(Column)`
  width: 100%;
  padding: 0 40px 20px 40px;
`;

const HalfColumn = styled(Column)`
  width: 50%;
  padding: 0 40px 20px 40px;
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #D8DCE7;
  margin-bottom: ${p => p.noMargin ? '0' : '20px'};
`;

const ImgTrash = styled.img.attrs({
  src: imgTrash,
  width: 16,
})`
  position: relative;
  top: 2px;
  right: 4px;
`;

const Heading = props => <Txt size='20px' {...props} />;

const SpacedColumn = styled(Column)`
  padding-left: 20px;
`;

class RebateForm extends Component {
  render() {
    const { handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Card maxWidth='1200px' minWidth='1080px' padding='0 0 20px 0' margin='0 0 40px 0'>
          <Row>
            <Column width='900px'>
            <Row>
                <FullColumn>
                  <Heading marginTop='40px' marginBottom='20px'>Rebate</Heading>
                </FullColumn>
              </Row>
              <Row>
                <HalfColumn>
                  <Label>Rebate Type</Label>
                  <Select
                    style={{marginBottom: '20px'}}
                    name='rebate_type.raw'
                    width="100%"
                    options={[
                      { name: 'No Rebate', value: '' },
                      { name: 'Text', value: 'text' },
                      { name: 'Fixed Sum', value: 'amount' },
                      { name: 'Percentage', value: 'percent' }
                    ]} />
                </HalfColumn>
                <HalfColumn style={{ overflow: 'hidden', transition: 'all 300ms', opacity: this.props.rebate_type ? 1 : 0 }}>
                  <Label>Rebate Value</Label>
                  {this.props.rebate_type === 'text' &&
                    <Input type='text' name='rebate_value.raw' />
                  } {this.props.rebate_type === 'amount' &&
                    <MoneyInput name='rebate_value.raw'/>
                  } {this.props.rebate_type === 'percent' &&
                    <PercentInput name='rebate_value.raw'/>
                  }
                </HalfColumn>

              </Row>
              <Row>
                <HalfColumn style={{ overflow: 'hidden', transition: 'all 300ms', display: this.props.rebate_type ? 'block' : 'none' }}>
                  <Label>Hover Text</Label>
                  <TextArea name='rebate_tooltip.raw'/>
                </HalfColumn>
              </Row>

            </Column>
          </Row>
          
          
          <BottomBorder />

          <Row>
            <PullRight padding='0 40px 0 0'>
              <ButtonPrimary type='submit' disabled={pristine} width='150px' background='#26BD27'>Save</ButtonPrimary>
            </PullRight>
          </Row>

        </Card>
      
      </form>
    );
  }


}

// class Rebates extends Component {
//   render() {
//     const { fields } = this.props;
//     return (
//       <Fragment>
//         <Row>
//           <FullColumn>
//             <Column width='300px'>
//               <Heading marginTop='40px' marginBottom='30px'>Rebate</Heading>
//             </Column>
           
//             <PullRight>
//               <ButtonPrimary margin='20px 0 0 0' type='button' onClick={() => fields.push()}>+ Add Rebate</ButtonPrimary>
//             </PullRight>
//           </FullColumn>
//         </Row>
//         {(fields || []).map( (rebate, index) => 
//           <Rebate key={index} index={index} rebate={rebate} fields={fields}/>
//         )}
//       </Fragment>
     
//     );
//   }
// }

// class Rebate extends Component {
//   render() {
//     const { index, rebate, fields } = this.props;
//     return(
//       <Fragment>
//         <Row>
//           <FullColumn>
//             <BottomBorder />
//           </FullColumn>
//         </Row>
//         <Row>
//           <FullColumn>
//             <Column>
//               <Heading as='span' marginTop='40px' marginBottom='20px'>Rebate {index + 1}</Heading>
//               <PullRight>
//                 <ButtonPlain
//                   onClick={() => fields.remove(index)} 
//                   width='30px' 
//                   height='30px' 
//                 ><ImgTrash/></ButtonPlain>
//               </PullRight>
//             </Column>
//             <ToggleButton name={`${rebate}.isActive`}/>
//           </FullColumn>
//         </Row>
//         <Row margin='0 0 20px 0'>
//           <FullColumn>
//             <Column width='23.5%'>
//               <Label>Rebate Type</Label>
//               <Select name={`${rebate}.type`} options={[{ name: 'Percentage', value: '' }]}/>
//             </Column>
//             <SpacedColumn width='25.5%'>
//               <Label>Rebate Value</Label>
//               <PercentInput name={`${rebate}.value`} />
//             </SpacedColumn>
//             <SpacedColumn width='25.5%'>
//               <Label>Minimum Loan Size</Label>
//               <MoneyInput name={`${rebate}.minLoan`} />
//             </SpacedColumn>
//             <SpacedColumn width='25.5%'>
//               <Label>Maximum Loan Size</Label>
//               <MoneyInput name={`${rebate}.maxLoan`} label='$' />
//             </SpacedColumn>
//           </FullColumn>
//         </Row>
//       </Fragment>
//     );
//   }
// } 

RebateForm = reduxForm({
  form: 'product-rebates',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(RebateForm);

const selector = formValueSelector('product-rebates');
const stateToProps = state => ({
  rebate_type: selector(state, 'rebate_type.raw'),
});

export default connect(stateToProps)(RebateForm);