import store from 'store';
import {
  userService,
  documentService,
  applicationService,
  applicationListsService,
  houseService,
  loanService,
} from '../index';

export async function findClients({ limit, skip, stages, search, sortField, sortDirection }) {
  try {
    // await deleteAllClients();
    //await createClients(5);
    // makeList();

    //makeApplications(20);
    let isProfessionalReferrer = false;
    if (stages.some(x => x === 'professional-referrer')) {
      stages = stages.filter(x => x !== 'professional-referrer');
      isProfessionalReferrer = true;
    }

    let isClientReferrer = false;
    if (stages.some(x => x === 'client-referrer')) {
      stages = stages.filter(x => x !== 'client-referrer');
      isClientReferrer = true;
    }

    const { data, total } = await userService.find({
      query: {
        $limit: limit,
        $skip: skip,
        $or: [
          {firstName: {$like:`%${search}%`}},
          {lastName: {$like:`%${search}%`}},
          {email: {$like:`%${search}%`}},
          {phoneNumber: {$like:`%${search}%`}}
        ],
        $sort: {
          [sortField]: sortDirection,
        },
        userType: ['client'],
        clientStage: {
          $in: stages,
          $nin: ['unregisted'],
        }, 
        firstName: {
          $nin: [''],
        },
        ...(isProfessionalReferrer ? { isProfessionalReferrer: 1 } : {}),
        ...(isClientReferrer ? { isClientReferrer: 1 } : {})
      }
    });

    const output = {total, data, toAppend: skip > 0};
    return output;
      
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function getUser(payload) {
  try {
    let result = await userService.get(payload, { query: { isAdmin: true } });
    const applications = store.getState().applications?.list;
    const user = store.getState().user;
    let userApplications = Object.values(applications)
      .filter(x => x.userId === payload);
    
    if (!userApplications.length) {
      userApplications = [ { meta: {}}];
    }
    let hasDuplicateNumber = false;
    if (result.phoneNumber && user.userType !== 'processor') {
      let number = result.phoneNumber.replace(/\D/g,'');
      if (number.startsWith('61')) number = number.replace('61', '');
      const { data, total } = await userService.find({
        query: {
          $limit: 1,
          id: {
            $nin: [payload],
          },
          userType: ['client'],
          phoneNumber: {
            $like:`%${number}%`
          },
          clientStage: {
            $nin: ['unregisted'],
          }, 
        }
      });
      console.log('Duplicates:', data);
      hasDuplicateNumber = !!total;
    }
    return { ...result, applications: userApplications, hasDuplicateNumber };
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function patchUser({ data, params }) {
  try {
    data = {...data, ...{
      lastLogin: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      fullName: data.firstName + ' ' + data.lastName,
    }};
    return await userService.patch(data.id, data, { query: params });
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createUser(user) {
  try {
    user = {...{
      fullName: user.firstName + ' ' + user.lastName,
      phoneNumberType: 'mobile',
      secondaryPhoneNumberType: 'mobile',
      userType: 'client',
    }, ...user};
    return await userService.create(user);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}


export async function createHouse({userId, house}) {
  if (!house.value) house.value = 0;
  try {
    return await houseService.create({ ...house, userId });
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function removeHouse(id) {
  try {
    return await houseService.remove(id);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createLoan({userId, loan}) {
  try {
    return await loanService.create({ ...loan, userId });
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function removeLoan(id) {
  try {
    return await loanService.remove(id);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}


