import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Card from 'components/Styled/Card';
import Txt from 'components/Styled/Text';
import { applicationListsService } from 'modules';
import Img from 'react-image';
import { logoUrl } from 'modules';
import imgReferrerClientActive from 'img/icons/referrer-client-active.svg';
import Tooltips from 'components/Common/Tooltips';

import iconBrokerSquare from 'img/icons/broker-square.svg';
import iconPreapproval from 'img/icons/deal-preapproval.svg';
import iconRefinance from 'img/icons/deal-refinance.svg';
import iconPurchase from 'img/icons/deal-purchase.svg';
import iconConstruction from 'img/icons/deal-construction.svg';
import { ApplicationCardContent } from 'components/ApplicationsEligibility/ApplicationCardContent';

export class ApplicationCardSmall extends Component {

  


  render() {
    let logoSrc = '';
    let altLogoSrc = '';

    if (data.products && data.products[0]) {
      try {
        logoSrc = `${logoUrl}/${data.products[0].bank}.png`
      } catch(e) {}
      try {
        altLogoSrc = require(`img/banks/${data.products[0].bank}.png`)
      } catch(e) {}
    }

    const { data } = this.props;
    let extraBanksText = '';
    if (data.products && data.products.length > 1) {
      extraBanksText = '+ ' + data.products[1].bank_name;
      if (data.products.length > 2)
        extraBanksText += ', ' + data.products[2].bank_name;
    }
    return (
      <div className='task'>
        <div className='task-description'>
          <div className='client'>{data['client.fullName']}</div>
          <div className='broker'> {data['broker.fullName']}</div>
        </div>
        <div className='task-logo'>
          {data.products && data.products[0] &&
            <Fragment>
              <Img className="img-logo" src={[logoSrc, altLogoSrc]} alt="logo" unloader={<span>{data.products[0].bank_name}</span>}/>
              <Txt noOverflow width='135px' size='12px' marginBottom='5px'> {extraBanksText}</Txt>
            </Fragment>
          }
        </div>
        <div className='task-due'>
          {
            data.daysDue > 0 ? `${data.daysDue} ${data.daysDue === 1 ? 'day' : 'days'}`
              : data.daysDue === 0 ? <div style={{ color: 'red' }}>Today</div>
                : <div style={{ color: 'red' }}>
                  <div style={{ display: 'inline-block' }}>
                    <div className='icon-warning'>!</div>
                  </div>
                  <div style={{ display: 'inline-block' }}>
                    <span>
                      {`${-data.daysDue} ${data.daysDue === -1 ? 'day' : 'days'}`}
                    </span>
                    <br />overdue
                  </div>
                  <div className='txt-urgent'>URGENT</div>
                </div>
          }
        </div>
        <div className='task-money'>
          {toMoneyString(data.loanAmount)}
        </div>
        {data.referrer &&
          <ReferrerIcon referrer={data.referrer}/>
        }
      </div>
    );
  }
}

const ApplicationBigCard= withRouter(class ApplicationCardBig extends Component {

  async componentDidMount() {
    let lists = await applicationListsService.find();
    lists = lists.data;
    if (!lists) return;
    const currentStage = lists.find(list => list.id === this.props.data.list);
    if (currentStage) {
      this.setState({ stageName: currentStage.name });
    }
  }

  render() {
    const { data, stages } = this.props;
    let extraBanksText = '';
    if (data.products && data.products.length > 1) {
      extraBanksText = '+ ' + data.products[1].bank_name;
      if (data.products.length > 2)
        extraBanksText += ', ' + data.products[2].bank_name;
    }

    const currentStage = stages?.[data.list];

    let logoSrc = '';
    let altLogoSrc = '';

    const mainLenderId = data.lenderId || data.products?.[0]?.bank;
    try {
      logoSrc = `${logoUrl}/${mainLenderId}.png`
    } catch(e) {}
    try {
      altLogoSrc = require(`img/banks/${mainLenderId}.png`)
    } catch(e) {}
    
    return (
      <TaskContainer>
        <div className='disabled-task task-card-big' onClick={() => this.props.history.push('/' + currentStage.page + '/view?id=' + data.id)}>
          <ApplicationCardContent {...this.props} page={currentStage?.page}/>
        </div>
        <Txt center size='14px' weight={600} marginBottom='4px' color='#2291FF'>{pageName(currentStage.page)}</Txt>
        <Txt center size='11px' weight={600} marginBottom='6px'>{currentStage?.name ?? 'Stage Deleted'}</Txt>
      </TaskContainer>
      
    );
  }
});

const pageName = page => {
  if (page === 'deals') return 'Current Deals';
  if (page === 'referrals') return 'Referrals';
  if (page === 'qualifiers') return 'Qualifiers';
  if (page === 'new-leads') return 'New Leads';
  if (page === 'in-progress') return 'In Progress';
  return page;
};

const stateToProps = state => {
  return {
    stages: state.applications?.stages,
  };
};

export const ApplicationCardBig = connect(stateToProps)(ApplicationBigCard);

const DealOffset = styled.div`
  position: absolute;
  right: 10px;
  bottom: 27px;
  color: #FF807F;
  font-size: 12px;
  text-align: right;
`;

const DealImg = styled.img`
  position: relative;
  top: 1px;
  margin-right: 4px;
`;

const DealIcon = ({type}) => {
  let src = iconBrokerSquare;
  
  if (type === 'Pre-approval') src = iconPreapproval;
  if (type === 'Refinance') src = iconRefinance;
  if (type === 'Purchase') src = iconPurchase;
  if (type === 'Construction') src = iconConstruction;

  return <DealImg src={src} />;
};

const ReferrerImg = styled.img.attrs({
  src: imgReferrerClientActive,
})`
  width: 24px;
  height: 22px;
`;

const TaskContainer = styled(Card)`
  width: 240px;
  background: #f6f6fa;
  padding: 0;
`;


const TaskCard = styled(Card)`
  position: relative;
  padding: 10px;
  width: 100%;
  height: 125px;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 8px;
  cursor: pointer;

  .task-description {
    position: absolute;
    top: 10px;
    left: 10px;

    .client {
      font-size: 16px;
      font-weight: 600;
      color: #101922;
      margin-bottom: 5px;
    }

    .broker {
      font-size: 12px;
      color: #223241;
      max-width: 170px;
      overflow: hidden;
      white-space: normal;
    }
  }

  .task-logo {
    position: absolute;
    bottom: 5px;
    left: 10px;

    img {
      max-height: 30px;
      margin-bottom: 5px;
    }
  }

  .task-due {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    font-weight: 600;

    div {
      display: inline-block;
      padding-top: auto;
      padding-bottom: auto;
      align-content: center; 
      height: 100%; 
    }

    .icon-warning {
      display: inline-block;
      min-width: 17px;
      height: 17px;
      color: white;
      margin-right: 10px;
      margin-bottom: 14px;
      background-color: red;
      border-radius: 17px;
      vertical-align: middle;
      text-align: center;
      line-height: 17px; 
      font-size: 13px;
      font-weight: 600;
    }

    .txt-urgent {
      box-sizing: border-box;
      display: block;
      min-width: 20px;
      height: 20px;
      color: white;
      background-color: red;
      border-radius: 20px;
      text-align: center;
      line-height: 20px; 
      font-size: 11px;
      font-weight: 600;
    }
  }

  .task-money {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    font-weight: 600;
  }
`;

const TooltipContent = styled.span`
  white-space: nowrap;
  visibility: hidden;
  background-color: #28384A;
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 140%;
  left: 50%;
  transform: translate(-50%, 0);
  /* margin-left: -60px; Use half of the width (120/2 = 60), to center the tooltip */
  z-index: 1;
  line-height: 150%;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;  

const Tooltip = styled.div`
  
  display: inline-block;
  overflow: visible;

  position: absolute;
  bottom: 45px;
  right: 10px;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
    }
  }
`;

const BrokerCircle = styled.div`

  display: inline-block;
  overflow: visible;

  position: absolute;
  right: 10px;
  top: 10px;

  text-align: center;
  line-height: 26px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #2D91F9;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
    }
  }
`;

const ReferrerIcon = ({ referrer }) => {
  return <>
    <Tooltip>
      <TooltipContent>
        <div><i>Client Referrer</i></div>
        <div><b>{referrer.firstName} {referrer.lastName}</b></div>
      </TooltipContent>
      <ReferrerImg/>
    </Tooltip>
  </>;
};

const toMoneyString = (amount) => {
  if (!amount) {
    return '$-';
  }

  if (amount < 1000) {
    const rounded = Math.round(amount * 10) / 10;
    return `$${rounded}`;
  }

  if (amount < 1000000) {
    const rounded = Math.round(amount / 100) / 10;
    return `$${rounded}k`;
  }

  const rounded = Math.round(amount / 10000) / 100;
  return `$${rounded}m`;
};

