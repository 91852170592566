import { createAction } from 'redux-actions';

export const REQUEST_FIND_PRODUCTS = 'REQUEST_FIND_PRODUCTS';
export const RECEIVE_FIND_PRODUCTS = 'RECEIVE_FIND_PRODUCTS';
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const REQUEST_PATCH_PRODUCT = 'REQUEST_PATCH_PRODUCT';
export const RECIEVE_PATCH_PRODUCT = 'RECIEVE_PATCH_PRODUCT';

export const requestFindProducts = createAction(REQUEST_FIND_PRODUCTS);
export const receiveFindProducts = createAction(RECEIVE_FIND_PRODUCTS);

export const requestProduct = createAction(REQUEST_PRODUCT);
export const receiveProduct = createAction(RECEIVE_PRODUCT);

export const requestPatchProduct = createAction(REQUEST_PATCH_PRODUCT);
export const receivePatchProduct = createAction(RECIEVE_PATCH_PRODUCT);
