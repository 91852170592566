// src/modules/index.js

import { combineReducers } from 'redux';
import feathers from '@feathersjs/client';
import { fork, all } from 'redux-saga/effects';
import { reducer as formReducer } from 'redux-form';

import user from './user/reducers';
import lenders from './lenders/reducers';
import applications from './applications/reducers';
import clients from './clients/reducers';
import products from './products/reducers';
import brokers from './brokers/reducers';
import documents from './documents/reducers';

import * as userSagas from './user/sagas';
import * as lenderSagas from './lenders/sagas';
import * as applicationSagas from './applications/sagas';
import * as clientSagas from './clients/sagas';
import * as productSagas from './products/sagas';
import * as brokerSagas from './brokers/sagas';
import * as documentSagas from './documents/sagas';

export function* rootSaga() {
  yield all([
    ...Object.values(userSagas),
    ...Object.values(lenderSagas),
    ...Object.values(applicationSagas),
    ...Object.values(clientSagas),
    ...Object.values(productSagas),
    ...Object.values(brokerSagas),
    ...Object.values(documentSagas),
  ].map(fork));
}

export const rootReducer = combineReducers({
  user,
  lenders,
  applications,
  clients,
  products,
  brokers,
  documents,
  form: formReducer,
});




let host = '';
let client = '';
let image_url = '';
let upload_url = '';
let base_url = '';

if (process.env.NODE_ENV === 'development') {
  //host = 'http://localhost:3030';
  host = 'http://api-staging.loanbase.com.au';
  //host = 'https://api.loanbase.com.au';
  client = 'http://localhost:3001';
  image_url = 'http://admin-staging.loanbase.com.au';
  upload_url = 'http://staging.loanbase.com.au';
  base_url = 'http://localhost:3000';
 
} else {
  if (window.location.hostname.indexOf('stag') !== -1) {
    host = 'http://api-staging.loanbase.com.au';
    client = 'http://staging.loanbase.com.au';
    image_url = 'http://admin-staging.loanbase.com.au';
    upload_url = client;
    base_url = image_url;
  } else {
    host = 'https://api.loanbase.com.au';
    client = 'https://app.loanbase.com.au';
    image_url = 'https://admin.loanbase.com.au';
    base_url = image_url;
    upload_url = client;
  }
}

export const uploadUrl = upload_url;
export const clientAppUrl = client;
export const hostUrl = host;
export const imageUrl = image_url;
export const logoUrl = image_url + '/logos';
export const baseUrl = base_url;

if (false) { // IP for my Mac VM to use
  host = 'http://192.168.148.1:3030';
}

  
export const showUnfinished = false;

export const app = feathers()
  .configure(feathers.rest(host).fetch(window.fetch))
  .configure(feathers.authentication({
    storage: window.localStorage
  }));

export const userService = app.service('users');
export const lenderService = app.service('lenders');
export const verificationService = app.service('verification');
export const applicationService = app.service('applications');
export const applicationListsService = app.service('application-lists');
export const productRawService = app.service('products-raw');
export const productOverrideService = app.service('product-overrides');
export const productService = app.service('products');
export const filesService = app.service('files');
export const brokerService = app.service('brokers');
export const emailService = app.service('email');
export const houseService = app.service('houses');
export const loanService = app.service('loans');
export const uploadService = app.service('file-upload');
export const settingsService = app.service('global-settings');
export const documentService = app.service('documents');
export const documentTemplateService = app.service('document-templates');
export const templateDocService = app.service('template-docs');
export const smsService = app.service('sms');
