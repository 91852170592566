import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { Row } from 'components/Styled/Layout';
import { TabBar, Tab, TabPill } from 'components/Styled/TabBar';
import { PathNavLink } from 'components/Common/PathNavLink';
import Card from 'components/Styled/Card';
import BackButton from 'components/Common/BackButton';

import { DocumentsView } from './DocumentsTab';
import { ReviewView } from './ReviewTab';
import { RequestView } from './RequestsTab';

import { getQueryVariables } from 'util/index';

export const ApplicationsFiles = props => {
  const { id } = getQueryVariables(props.location.search)
  const { url } = props;
  return (
    <div>
      <Row margin='0 0 28px 0'>
        <BackButton margin='15px 0 0 0'/>
      </Row>

      <Card width='800px' padding='0' minHeight='600px'>
        <TabBar height={60} tabWidth='auto' tabMargin={40} marginBottom={20}>
          <Tab replace={true} as={PathNavLink} activeClassName='current' to={`${url}/view/files?id=${id}`}>
            Client Documents
            <TabPill>6</TabPill>
          </Tab>
          <Tab replace={true} as={PathNavLink} activeClassName='current' to={`${url}/view/files/review?id=${id}`}>
            Review
            <TabPill>4</TabPill>
          </Tab>
          <Tab replace={true} as={PathNavLink} activeClassName='current' to={`${url}/view/files/request?id=${id}`}>
            Request
            <TabPill>4</TabPill>
          </Tab>
          <Tab replace={true} as={PathNavLink} activeClassName='current' to={`${url}/view/files/upload?id=${id}`}>
            Upload Files
          </Tab>
        </TabBar>
        <Router url={url} />
      </Card>
    </div>
  );
};

const Router = ({url}) => (
  <Switch>
    <Route exact path={`${url}/view/files`} component={DocumentsView}/>\
    <Route exact path={`${url}/view/files/review`} component={ReviewView}/>
    <Route exact path={`${url}/view/files/request`} component={RequestView}/>
    <Route exact path={`${url}/view/files/upload`} component={UploadView}/>
  </Switch>
);

const UploadView = () => (
  <div style={{margin: '40px'}}>
    This is Upload View
  </div>
);