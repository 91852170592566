import React, { Fragment, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import TaskProgressBar from 'components/Common/TaskProgressBar';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import Select from 'components/Common/Select';
import Label from 'components/Styled/Label';

import { Row, Column, PullLeft, PullRight } from 'components/Styled/Layout';

import imgEditPen from 'img/button/edit-pen.png';
import imgClientDetails from 'img/button/reply.svg';
import imgTag from 'img/button/tag-blue.png';
import imgBookmark from 'img/button/bookmark-orange.png';
import imgSettings from 'img/button/settings-gear.png';

import { requestMoveApplication, requestUpdateApplications } from 'modules/applications/actions';

import { showUnfinished, emailService } from 'modules';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Form, reduxForm } from 'redux-form';
import { Button } from 'views/Settings/Components/Shared';
import { ArchiveButton } from './ArchiveButton';
import html from 'util/html';
import { relativeTimeRounding } from 'moment';
import { Input, MoneyInput, ToggleButton } from 'components/Form';
import Card from 'components/Styled/Card';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { ModelessButton } from 'components/Modal/ModelessButton';
import TextInput from 'components/Styled/TextInput';
import brokers from 'modules/brokers/reducers';
import SendSmsModal from './Modals/SendSmsModal';
import SmsSentModal from './Modals/SmsSentModal';

import imgSms from 'img/button/sms.svg';
import imgEmail from 'img/button/email.svg';
import SendEmailModal from './Modals/SendEmailModal';


const pipelineName = stage => {
  return {
    'qualifiers': 'Qualifiers',
    'referrals': 'Referrals',
    'deals': 'Current Deals',
    'new-leads': 'New Leads',
  }[stage?.page] ?? 'Nowhere';
};

const TopRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const TopBarComponent = ({ application, applications, requestUpdateApplications, lenders, brokers }) => {

  const { openModal, closeModal } = useContext(modalContext);
  const user = useSelector(({ user }) => user);
  const client = useSelector(state => state.clients.current);

  if (!application)
    return null;
    
  const isProcessor = user.userType === 'processor';
  const userProfile = user.profile;
  const brokerName = userProfile && `${userProfile.firstName} ${userProfile.lastName}`;
  const isArchived = application.isArchived;
  const toggleArchive = () => {
    requestUpdateApplications({
      id: application.id,
      isArchived: !isArchived,
      note: { 
        body: (isArchived ? 'The deal was restored from archive ' : 'The deal was archived ') + (brokerName ? 'by ' + brokerName : ''),
        brokerId: 'system'
      }
    });
  };

  const stages = Object.values(applications.stages)
    .sort((a, b) => a.stageIndex > b.stageIndex ? 1 : -1)
    .map((stage, index) => ({ ...stage, index, value: stage.id }));
  
  const currentStage = stages.find(s => s.value === application.list);
  const currentPage = currentStage?.page;
  const pageStages = stages.filter(x => x.page === currentPage && !x.hidden);
  let selectStages = stages.filter(x => x.page === currentPage || x.page === 'archive' && currentPage === 'qualifiers');
  selectStages = [ { name: 'Change Pipeline', value: 'change_pipeline' }, ...selectStages ];

  const initialValues = { ...application, showLVR: application.showLVR !== false }; 

  const openModalForm = component =>  
    () => openModal({
      component,
      props: {
        initialValues,
        onSubmit: values => {
          requestUpdateApplications({
            id: application.id,
            ...values,
          });
        }
      }
    });

  const changeStage = async value => {

    if (value === 'change_pipeline') {
      openModal({
        component: PipelineModal,
        props: {
          stages,
          onSubmit: val => changeStage(val),
        }
      });
      return;
    }
    //debugger;
    const dstStage = stages.find(s => s.value === value);
    const firstItem = dstStage.applications[0];
    let listOrder = 0;
    if (firstItem) listOrder = Math.floor(firstItem.listOrder) - 1; 
    // const srcIndex = applications.stages[currentStage?.id]?.applications
    //   .findIndex(a => a.id === application.id);

    requestUpdateApplications({
      id: application.id, 
      list: dstStage.id,
      listOrder,
    });


    let body;

    // set due date if stage has one and the deal's is unset

    if (dstStage.daysDue && !application.dueDate) {
      const dueDate = moment().add(dstStage.daysDue, 'days');
      requestUpdateApplications({
        id: application.id, 
        meta: { 
          dueDate,
        }
      });
    }

    // add deal move to notes
    if (currentStage.id !== dstStage.id) {
      if (currentStage?.page === dstStage.page) {
        body = html`The deal was moved from <b>${currentStage?.name}</b> to <b>${dstStage.name}</b> ${(brokerName ? 'by ' + brokerName : '')}`;
      } else {
        body = html`The deal was moved from <b>${pipelineName(currentStage)}: ${currentStage?.name ?? 'Deleted Stage'}</b> to <b>${pipelineName(dstStage)}: ${dstStage.name}</b> ${(brokerName ? 'by ' + brokerName : '')}`;
      }

      requestUpdateApplications({
        id: application.id, 
        note: { 
          body, 
          brokerId: 'system'
        }
      });
    }

    if (dstStage.emailEnabled) {
      await openModal({
        component: SendEmailModal,
        props: {
          initialValues: { ...dstStage, includeClient: true },
          deal: application,
        }
      });
    }
    
    if (dstStage.smsEnabled) {
      const openSmsModal = props => {
        closeModal();
        openModal({
          component: SendSmsModal,
          props: {
            ...props,
            sendSms: props => {
              closeModal();
              openModal({
                component: SmsSentModal,
                props,
              });
            },
            goBack: openSmsModal
          } 
        });
      };
      openSmsModal({
        page: dstStage.page,
        initialValues: { ...dstStage, includeClient: true },
        deal: application,
      });
    }

  };

  const sendSms = () => {
    let stage = currentStage;
    if (!stage.smsEnabled) stage = { id: -1, smsSender: 'loanbase' };

    const openSmsModal = props => {
      closeModal();
      openModal({
        component: SendSmsModal,
        props: {
          ...props,
          sendSms: props => {
            closeModal();
            openModal({
              component: SmsSentModal,
              props,
            });
          },
          goBack: openSmsModal
        } 
      });
    };
    openSmsModal({
      page: currentStage.page,
      initialValues: { ...stage, includeClient: true },
      deal: application,
    });
  };

  const sendEmail = () => { 
    let stage = currentStage;
    if (!stage.emailEnabled) stage = { id: -1, emailSender: 'loanbase' };

    const openEmailModal = props => {
      closeModal('continue');
      openModal({
        component: SendEmailModal,
        props,
      }); 
    };
    openEmailModal({
      page: currentStage.page,
      initialValues: { ...stage, includeClient: true, smsEnabled: false },
      deal: application,
    });
  };

  const daysDue = application.dueDate && moment(application.dueDate).diff(moment(), 'days');

  let dueDateColor;
  if (application.dueDate) {
    if (daysDue <= 30) dueDateColor = '#F5A623';
    if (daysDue <= 14) dueDateColor = '#FF4E4C';
  } else {
    dueDateColor = '#A2A2A2';
  }

  return (<div className="bar-top">
    <Row>
      <TopRow>
        <PullLeft style={{ display: 'block'}}>
          <div style={{ display: 'inline-block'}}>
            <h2 className='client-name'>
              <Link style={{color: '#101922'}} to={`/${currentStage?.page}/view?id=${application.id}`}>
                {application.user_name}
              </Link>
              <ClientEditButton style={{ margin: '20px' }} to={`/clients/view?id=${application.userId}`}/>
            </h2>
          </div>
          <DealDescription onClick={openModalForm(DealDescriptionModal)} color={!application.dealDescription && '#A2A2A2'}>
            {application.dealDescription || 'Set deal description'}
            <PenButton />
          </DealDescription>
          <DealDescription onClick={openModalForm(DealDueDateModal)} color={dueDateColor}>
            {(application.dueDate && (application.dueDateDescription || 'Due Date') + ': ' + moment(application.dueDate).format('Do MMM YYYY')) || 'Set due date'}
            <PenButton />
          </DealDescription>
          <div className='top-bar-info'>
            {!!application.loanAmount && 
              <span style={{ cursor: 'pointer'}} onClick={openModalForm(DealValueModal)} >
                <span>${new Number(application.loanAmount).toLocaleString()}</span>
                {!!application.offsetAmount &&
                  <span style={{ marginLeft: '5px', color: '#FF4E4C' }}>
                    - ${new Number(application.offsetAmount).toLocaleString()}
                  </span>
                }
                
                <PenButton  />
                <div className='horizontal-divider'></div>
              </span>
            }
            {!application.loanAmount &&
              <span style={{ cursor: 'pointer'}} onClick={openModalForm(DealValueModal)} >
                <span style={{ color: '#A2A2A2', fontWeight: 'bold', fontSize: '16px'}}>Set Loan Amount</span>
                <PenButton  />
                <div className='horizontal-divider'></div>
              </span>
            }
            {lenders && lenders.map((lender, index) => (
              <Fragment key={lender.id}>
                <span className='grey-text'>{lender.name}</span>
                <span className='horizontal-divider' />
              </Fragment>
            ))} 
           
            {application.broker &&
            <Fragment>
              <span className='grey-text'>Broker: {application.broker.firstName + ' ' + application.broker.lastName}</span>
              <span className='horizontal-divider' />
            </Fragment>
            }
            {!isProcessor && <>
              <ModelessButton 
                buttonDefault={props => 
                  <span { ...props} style={{ cursor: 'pointer' }}>
                    <span className='grey-text'>
                      Processor: {application.processor ? application.processor.firstName + ' ' + application.processor.lastName : 'Not Set'}
                    </span>
                    <PenButton />
                  </span>}
                modeless={ProcessorOptions}
                data={{ 
                  processors: brokers.filter(x => x.isProcessor),
                  onSubmit: data => {
                    requestUpdateApplications({
                      id: application.id,
                      ...data
                    });
                  }}}
              ></ModelessButton>
              <span className='horizontal-divider' />
            </>}
            
            <ModelessButton 
              buttonDefault={props => 
                <span { ...props} style={{ cursor: 'pointer' }}>
                  <span className='grey-text'>
                    Deal Type: {application.dealType ?? 'None Selected'}
                  </span>
                  <PenButton />
                </span>}
              modeless={DealTypes}
              data={{ onSubmit: dealType => {
                requestUpdateApplications({
                  id: application.id,
                  dealType,
                });
              }}}
            ></ModelessButton>
          </div>
        </PullLeft>
        <PullRight style={{marginBottom: '20px'}}>
          {showUnfinished &&
            <ButtonPlain width='40px' className='btn-tag'>
              <img width={16} height={16} src={imgTag} />
            </ButtonPlain>
          }
          {showUnfinished &&
            <ButtonPlain width='40px' className='btn-bookmark'>
              <img width={11} height={14} src={imgBookmark} />
            </ButtonPlain>
          }
          <ButtonPlain onClick={sendEmail} style={{ marginRight: '20px'}}>
            <img src={imgEmail} width='19' style={{ marginRight: '10px', position: 'relative', top: '3px'}}/>
            Send Email
          </ButtonPlain>
          <ButtonPlain onClick={sendSms} style={{ marginRight: '20px'}}>
            <img src={imgSms} width='19' style={{ marginRight: '10px', position: 'relative', top: '3px'}}/>
            Send SMS
          </ButtonPlain>
          <ArchiveButton onConfirm={toggleArchive} title={isArchived ? 'Restore Deal' : 'Archive Deal'} confirmMessage={`Click here to ${isArchived ? 'restore' : 'archive'}`}>Archive Deal</ArchiveButton>
          <Select width='220px' maxHeight='500px' options={selectStages} value={currentStage?.id} onChange={changeStage} />
          
          {showUnfinished &&
            <Fragment>
              <div style={{ width: '20px', height: '40px' }} />
              <div className='stage-container green'>
                <div className='pill'>FINANCE</div>
                <div className='date'>10/08/2018</div>
              </div>

              <div className='stage-container red'>
                <div className='pill'>SETTLEMENT</div>
                <div className='date'>12/09/2018</div>
              </div>

              <div className='stage-container red'>
                <div className='pill'>STAGE DUE</div>
                <div className='date'>12/11/2018</div>
              </div>

              <ButtonPlain width='40px' className='btn-settings'>
                <img width={16} height={16} src={imgSettings} />
              </ButtonPlain>
            </Fragment>
          }
          
        </PullRight>
      </TopRow>
    </Row>
    <Row>
      <TaskProgressBar stages={pageStages} currentStageId={currentStage?.id} />
    </Row>

  </div>
  );
};

const ProcessorOptions = ({ processors, onSubmit, modal }) => {
  
  const onClick = async processorId => {
    modal.hide();
    const processor = processors.find(b => b.id === processorId);
    onSubmit({ processorId, processor, processor_name: processor.name });
  };

  return (
    <TaskBar width={200} offsetLeft={190}>
      {processors && processors.map(({ id, name }) => (
        <TaskBarOption key={id} onClick={() => onClick(id)} >{name}</TaskBarOption>
      ))}
    </TaskBar>
  );
};

const DealTypes = ({ onSubmit, modal }) => {
  
  const onClick = async value => {
    modal.hide();
    onSubmit && onSubmit(value);
  };

  return (
    <TaskBar width={200} offsetLeft={70}>
      <TaskBarOption key={1} onClick={() => onClick('Refinance')}>Refinance</TaskBarOption>
      <TaskBarOption key={2} onClick={() => onClick('Purchase')}>Purchase</TaskBarOption>
      <TaskBarOption key={3} onClick={() => onClick('Pre-approval')}>Pre-approval</TaskBarOption>
      <TaskBarOption key={4} onClick={() => onClick('Construction')}>Construction</TaskBarOption>
    </TaskBar>
  );
};

const DealDescription = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: ${p => p.color || '#101922'};
  cursor: pointer;
  margin-right: 10px;
`;

const ClientEditButton = ({ to, ...props }) => (
  <Link to={to || ''} {...props}>
    <img
      width={15}
      height={15}
      src={imgClientDetails} 
      className='edit-pen'
      style={{ top: '0' }}
    />
  </Link>
);

const PenButton = props => {
  return <img
    width={12}
    height={12}
    src={imgEditPen} 
    {...props}
    className='edit-pen'
    style={{ top: '0', marginLeft: '10px'}}
  />;
};

export const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

const TaskBar = styled(Card)`
  padding: 10px 0;
  position: absolute;
  top: 25px;
  left: ${p => p.width ? ((20 - p.width / 2) + (p.offsetLeft ?? 0) + 'px') : '-55px'};
  width: ${p => p.width ? (p.width + 'px') : '150px'};
  z-index: 120;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 15px 1px rgba(216,220,231,0.65);

  &:after {
    content: "";
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    position: absolute;
    top: -5px;
    left: ${p => p.width ? ((p.width / 2 - 6) + 'px') : '69px'};
    filter: drop-shadow(0px -1.5px 0px #D8DCE7);
    
    z-index: 5;
  }
`;

const TaskBarOption = styled.div`
  width: 100%;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background-color: #f3f3f3
  }
`;

const lessThanLoanAmount = (value, values) => 
  (value > values.loanAmount) && 'Must be less than deal size';

const DealDescriptionModalBase = ({ modal, onSubmit, handleSubmit }) => {
  const submit = values => {
    modal.close();
    onSubmit(values);
  };
  return (
    <ModalBox style={{ width: '500px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Deal Description</SmallHeading>
        </Row>
        <Row margin='0 0 30px 0'>
          <Label>Description</Label>
          <Input name='dealDescription'/>
        </Row>
        <Row>
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPrimary background='#FF4E4C' type='button' onClick={modal.close} width='100%'>Cancel</ButtonPrimary>
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Update</ButtonPrimary>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};



const DealDescriptionModal = reduxForm({ form: 'deal-description'})(DealDescriptionModalBase);

const DealDueDateModalBase = ({ modal, onSubmit, handleSubmit, initialValues }) => {
  
  let initialDueDate = initialValues.dueDate;
  if (initialDueDate && moment(initialDueDate).isValid()) {
    initialDueDate = new Date(initialDueDate);
  } else {
    initialDueDate = Date.now();
  }
  
  const [dueDate, setDueDate] = useState(initialDueDate);
  const [description, setDescription] = useState(initialValues.dueDateDescription);

  const submit = () => {
    modal.close();
    onSubmit({ dueDate, dueDateDescription: description });  
  };

  const remove = () => {
    modal.close();  
    onSubmit({ dueDate: null });
  };

  const addDays = n => {
    const result = new Date();
    result.setDate(result.getDate() + n);
    setDueDate(result);
  };
  
  return (
    <ModalBox style={{ width: '500px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Due Date</SmallHeading>
        </Row>
        <Row margin='0 0 10px 0'>
          <Label>Description</Label>
          <TextInput onChange={e => setDescription(e.target.value)} value={description}/>
        </Row>
        <Label>Due Date</Label>
        <Row margin='0 0 20px 0'>
          <Column width='45%' margin='0 10% 0 0'>
            
            <DatepickerContainer>
              <DatePicker width='100%' dateFormat='dd/MM/yyyy' selected={dueDate} onChange={date => setDueDate(date)}/>
            </DatepickerContainer>
          </Column>
          <Column width='45%'>
            <ButtonPlain type='button' width='100%'onClick={() => addDays(90)}>90 Days From Now</ButtonPlain>
          </Column>
        </Row>  
        <Row> 
          <Column width='45%' margin='0 10% 0 0'>
            {initialDueDate ? 
              <ButtonPrimary background='#FF4E4C' type='button' onClick={remove} width='100%'>Remove</ButtonPrimary>
              :
              <ButtonPrimary background='#FF4E4C' type='button' onClick={modal.close} width='100%'>Cancel</ButtonPrimary>
            }
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Update</ButtonPrimary>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};

const DealDueDateModal = reduxForm({ form: 'deal-due-date'})(DealDueDateModalBase);

const DatepickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
      input {
        width: 100%;
        vertical-align: top;
        color: #101922;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0; 
        height: ${p => p.height || '40px'};
        border: 1px solid #d8dce7;
        border-radius: 4px;
        /* box-shadow: 0px 1px 0px 0px #e9ebf1; */
        padding: 0 12px 0 12px;
        transition: all 300ms;

        &:focus {
          border: 1px solid #2291FF !important;
          box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
        }

        &.error {
          border: 1px solid rgba(255,0,0,0.5) !important;
          box-shadow: inset 0 0 0 2px rgba(255,0,0,0.2) !important;
        }

        &[readonly] {
          cursor: default;
        }

        &:disabled {
          background-color: #d8dce75e !important;
          color: #545454;
          // background-image: none;
          cursor: no-drop;
        }
      }
  }
`;

const DealValueModalBase = ({ modal, onSubmit, handleSubmit }) => {
  
  const formValues = useSelector(state => state.form['deal-value']?.values ?? {});

  const submit = values => {
    modal.close();
    onSubmit(values);
  };

  let lvr;

  if (formValues.loanAmount && formValues.propertyValue)
    lvr = Math.floor((formValues.loanAmount / formValues.propertyValue) * 100);
  
  return (
    <ModalBox style={{ width: '350px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Deal Value</SmallHeading>
        </Row>
        <Row margin='0 0 10px 0'>
          <Label>Approximate Deal Size</Label>
          <MoneyInput name='loanAmount'/>
        </Row>
        <Row margin='0 0 10px 0'>
          <Label>Redraw/Offset Amount</Label>
          <MoneyInput name='offsetAmount' validate={lessThanLoanAmount}/>
        </Row>
        <Row margin='0 0 20px 0'>
          <Label>Property Value</Label>
          <MoneyInput name='propertyValue'/>
        </Row>
        <Row margin='0 0 40px 0'>
          <Label>
            <ToggleButton name='showLVR' valueChecked={true} valueUnchecked={false}/> Show LVR
            <span style={{ marginLeft: '10px'}}>{lvr ? `( ${lvr}% )` : '( invalid )'}</span>
          </Label>
          
        </Row>
        <Row>
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPrimary background='#FF4E4C' type='button' onClick={modal.close} width='100%'>Cancel</ButtonPrimary>
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Update</ButtonPrimary>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};

const DealValueModal = reduxForm({ form: 'deal-value'})(DealValueModalBase);

const PipelineModal = ({ modal, stages, onSubmit }) => {

  const [ pipeline, setPipeline ] = useState('qualifiers');
  const [ stage, setStage ] = useState();

  const changePipeline = value => {
    setPipeline(value);
    setStage(null);
  };

  const changeStage = setStage;

  const visibleStages = Object.values(stages)
    .filter(x => x.page === pipeline)
    .map(({ name, id }) => ({ name, value: id }));

  const handleSubmit = () => {
    onSubmit(stage ?? visibleStages[0].value);
    modal.close();
  };

  return (
    <ModalBox style={{ width: '350px' }}>
      <Row>
        <SmallHeading>Change Pipeline</SmallHeading>
      </Row>
      <Row margin='0 0 10px 0'>
        <Label>Pipeline</Label>
        <Select value={pipeline} onChange={changePipeline} options={[ 
          { name: 'Current Deals', value: 'deals'},
          { name: 'In Progress', value: 'in-progress'},
          { name: 'New Leads', value: 'new-leads'},
          { name: 'Referrals', value: 'referrals'}, 
          { name: 'Qualifiers', value: 'qualifiers'}, 
        ]}
        />
      </Row>
      <Row margin='0 0 40px 0'>
        <Label>Stage</Label>
        <Select value={stage} onChange={changeStage} options={visibleStages}/>
      </Row>
      <Row>
        <ButtonPrimary type='button' width='100%' onClick={handleSubmit}>Submit</ButtonPrimary>
      </Row>
    </ModalBox>
  );
};

const stateToProps = ({brokers}) => ({brokers});

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestMoveApplication,
    requestUpdateApplications,
  }, dispatch);

export const TopBar = connect(stateToProps, mapDispatchToProps) (TopBarComponent);
