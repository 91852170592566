import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';

import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { Row, Column } from 'components/Styled/Layout';
import Label from 'components/Styled/Label';

import { Input as BaseInput, TextArea as BaseTextArea, LabelledInput as BaseLabelledInput, Select, MoneyInput, PercentInput, ToggleButton } from 'components/Form';
import { showUnfinished } from 'modules';
import Img from 'react-image';
import uploadFile from 'util/upload-file';
import uriToBlob from 'util/uri-to-blob';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { logoUrl } from 'modules';
import InputGroup from 'components/Common/InputGroup';
import { required } from 'util/validation';

const $ = window.$;

const InputBase = styled(BaseInput)`
  margin: 0 !important;
  width: 100%;
`;

const Input = props => (
  <span style={{margin: '0 40px 20px 0', display: 'inline-block', width: '100%'}}>
    <InputBase {...props}/>
  </span>
)

const TextArea = styled(BaseTextArea)`
  margin: 0 40px 20px 0;
  width: 90%;
`;

const LabelledInput = styled(BaseLabelledInput)`
  margin: 0 40px 20px 0;
  width: 90%;
`; 

const filePicker = () => {
  $('#file-upload').click();
} 

class EditLenderForm extends Component {
  constructor(props) {
    super(props);
    const { id } = getQueryVariables(props.location.search);
    this.state = {
      imageUploading: false,
      logoUrl: `${logoUrl}/${id}.png`,
    }
  }
  

  componentDidMount() {
    $.ajaxSetup({
      xhr: function () {
          var xhr = new window.XMLHttpRequest();
          // upload progress
          xhr.addEventListener('progress', function (evt) {
              if (evt.lengthComputable) {
                  var percentComplete = evt.loaded / evt.total;
              }
          }, false);
          return xhr;
      }
    });


    const reader  = new FileReader();
    $('#file-upload').change(function(){
      var file = this.files[0];
      // encode dataURI
      reader.readAsDataURL(file);
    });
    const { id } = getQueryVariables(this.props.location.search);
    reader.addEventListener('load', () => {
      
      const blob = uriToBlob(reader.result);
      this.setState({ fileUploading: true, logoUrl: URL.createObjectURL(blob) });
      uploadFile({
        file: blob,
        type: 'logo',
        name: id + '.png',
        callback: (err, res) => {
          if (err) {
            this.setState({ uploadError: "UNKNOWN ERROR! Image failed to upload" });
          }
          this.setState({ fileUploading: false });
        }
      });
  }, false);
  }

  render() {
    const { handleSubmit, lenderId, lenderName } = this.props;
    let altLogoSrc = ''
    const hash = this.state.logoHash;
    const { uploadError } = this.state;
    try {
      altLogoSrc = require(`../../img/banks/${lenderId}.png`);
    } catch (e) {
    }
    
    return (
      <form onSubmit={handleSubmit}>
        <Row style={{paddingBottom: '30px'}}>
          <Column width='66%'>
            <Row>
              <span className='logo-column'>
                <Img className="img-logo" src={[this.state.logoUrl, altLogoSrc]} alt="logo" unloader={() => 'not working'}/>
              </span>
              
              <div className='logo-title'>Lender Logo</div>
              <ButtonPlain type='button' width='150px' onClick={filePicker}><ButtonSpinner color='#2291FF' show={this.state.fileUploading}/>Change Logo</ButtonPlain>
              <input id='file-upload' type='file' name='upload' accept='.png' style={{display: 'none'}}/>

            </Row>
          </Column>
          <Column width='33%'>
            <ToggleButton title='Accredited' name='accredited'/>
          </Column>
        </Row>
        {uploadError &&
          <ErrorText>{uploadError}</ErrorText>
        }
        <div className='seperator' style={{ marginBottom: '20px'}}></div>
        <Row>
          <Column width='66%'>
            
            <Row>
              <Column className='top-padding'>
                <Column width='50%'>
                  <Label>Lender ID</Label>
                  <Input type='text' name='id' disabled className='disabled'/>
                </Column>
                <Column width='50%'>
                  <Label>Lender Name</Label>
                  <Input type='text' name='name' validate={required}/>
                </Column>
                <Column width='50%'>
                  <Label>Broker ID</Label>
                  <Input type='text' name='brokerId' />
                </Column>
                <Column width='50%'>
                  <Label>Broker Assist / Scenarios</Label>
                  <Input type='text' name='brokerAssist' />
                </Column>
                <Column width='50%'>
                  <Label>Broker Assist Phone Number</Label>
                  <Input type='text' name='brokerPhone' />
                </Column>
                <Column width='50%'>
                  <Label>Broker Website</Label>
                  <Input type='text' name='brokerWebsite' />
                </Column>

              </Column>
            </Row>
            <Row>
              <h2>Assessment Rates</h2>
            </Row>
            <Row>
              <Column width='50%'>
                <Label>Owner Occupied Assessment Rate</Label>
                <InputGroup style={{ marginBottom: '20px'}}>
                  <PercentInput width='50%' name='assessmentRateOO' validate={required}/>
                  <Select width='40%' name='assessmentRateOOType'
                    options={[
                      { name: 'Set Rate', value: 'set' },
                      { name: 'Actual + Buffer', value: 'actual' },
                    ]} />
                </InputGroup>
              </Column>
              <Column width='50%'>
                <Label>Investment Assessment Rate</Label>
                <InputGroup style={{ marginBottom: '20px'}}>
                  <PercentInput width='50%' name='assessmentRateInv' validate={required}/>
                  <Select width='40%' name='assessmentRateInvType'
                    options={[
                      { name: 'Set Rate', value: 'set' },
                      { name: 'Actual + Buffer', value: 'actual' },
                    ]} />
                </InputGroup>
              </Column>
              <Column width='50%'>
                <Column width='47%'>
                  <Label>OFI Assessment</Label>
                  <Select
                    name='otherDebtTreatement'
                    width="90%"
                    options={[
                      { name: 'Assessed', value: 'assessed' },
                      { name: 'Actual', value: 'actual' },
                      { name: 'Actual + %', value: 'actual+%' }
                    ]} />
                </Column>
                <Column width='48%'
                  style={{ overflow: 'hidden', transition: 'all 300ms', opacity: this.props.otherDebtTreatement === 'actual+%' ? 1 : 0 }}
                >
                  <Label>OFI Buffer OO</Label>
                  <PercentInput name='otherDebtAdditionOO' width='100%'/>
                </Column>
                
              </Column>
              <Column width='50%' style={{ overflow: 'hidden', transition: 'all 300ms', opacity: this.props.otherDebtTreatement === 'actual+%' ? 1 : 0 }}>
                <Label>OFI Buffer Inv</Label>
                <PercentInput name='otherDebtAdditionInv'/>
              </Column>
            </Row>
            <Row>
              <h2>Rebate</h2>
            </Row>
            <Row>
              <Column width='50%'>
                <Label>Rebate Type</Label>
                <Select
                  style={{marginBottom: '20px'}}
                  name='rebateType'
                  width="90%"
                  options={[
                    { name: 'No Rebate', value: '' },
                    { name: 'Text', value: 'text' },
                    { name: 'Fixed Sum', value: 'amount' },
                    { name: 'Percentage', value: 'percent' }
                  ]} />
              </Column>
              <Column width='50%' style={{ overflow: 'hidden', transition: 'all 300ms', opacity: this.props.rebateType ? 1 : 0 }}>
                <Label>Rebate Value</Label>
                {this.props.rebateType === 'text' &&
                  <Input type='text' name='rebateValue' />
                } {this.props.rebateType === 'amount' &&
                  <MoneyInput name='rebateValue'/>
                } {this.props.rebateType === 'percent' &&
                  <PercentInput name='rebateValue'/>
                }
              </Column>
              <Column width='50%' style={{ overflow: 'hidden', transition: 'all 300ms', opacity: this.props.rebateType ? 1 : 0 }}>
                <Label>Hover Text</Label>
                <TextArea name='rebateTooltip'/>
              </Column>
            </Row>
            <Row>
              <h2>BDM</h2>
            </Row>
            <Row>
              <Column width='50%'>
                <Label>BDM Name</Label>
                <Input name='bdmName' type='text' />
              </Column>

              <Column width='50%'>
                <Label>BDM Phone Number</Label>
                <Input name='bdmPhone' type='phone' />
              </Column>

              <Column width='50%'>
                <Label>BDM Email</Label>
                <Input name='bdmEmail' type='phone' />
              </Column>
            </Row>
            <Row>
              <h2>Commercial BDM</h2>
            </Row>
            <Row>
              <Column width='50%'>
                <Label>Commercial BDM Name</Label>
                <Input name='commercialBdmName' type='text' />
              </Column>
              <Column width='50%'>
                <Label>Commercial BDM Phone Number</Label>
                <Input name='commercialBdmPhone' type='phone' />
              </Column>
              <Column width='50%'>
                <Label>Commercial BDM Email</Label>
                <Input name='commercialBdmEmail' type='phone' />
              </Column>
            </Row>
          </Column>
          <Column width='33%' className='top-padding'>
            <Label>Commission Detail</Label>
            <TextArea
              name='commissionDetail'
              height='129px'
            />
            <Label>Clawback Detail</Label>
            <TextArea
              name='clawbackDetails'
              height='207px'
            />
            <Label>Notes</Label>
            <TextArea
              name='additionalNotes'
              height='207px'
            />
            <Label>Lender Overview</Label>
            <TextArea
              name='description'
              height='207px'
            />
          </Column>
        </Row>
        <Row>
          <ButtonPrimary type='submit' width='240px' style={{ float: 'right', marginRight: '40px'}}>
            Update Lender Information
          </ButtonPrimary>
        </Row>
      </form>
    );
  }
}

const ErrorText = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: red;
`;

EditLenderForm = reduxForm({
  form: 'editLender',
  enableReinitialize: true,
})(EditLenderForm);

const selector = formValueSelector('editLender');

export default withRouter(
  connect(state => ({
    otherDebtTreatement: selector(state, 'otherDebtTreatement'),
    rebateType: selector(state, 'rebateType'),
    lenderId: selector(state, 'id'),
    lenderName: selector(state, 'name'),
  }))(EditLenderForm)
)


function getQueryVariables(query) {
  let values = {};
  if (query) {
    var vars = query.split('?')[1].split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      values[pair[0]] = pair[1];
    }
  }
  return values;
}