import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { receiveUser, requestCreateUser, requestPatchUser } from 'modules/clients/actions';

import Card from 'components/Styled/Card';
import BackButton from 'components/Common/BackButton';
import Form from './Forms/AddClientForm';
import { SubmissionError } from 'redux-form';

import { emailService } from 'modules';

class AddClient extends Component {
  constructor() {
    super();
    this.state = {
      redirectTo: '',
      form: {},
      password: '',
    };
  }

  onSubmit = values => {
    const { requestCreateUser, requestPatchUser, receiveUser, history } = this.props;
    return new Promise((resolve, reject) => {
      const sendEmail = values.send_client_email;
      //delete values.send_client_email;
      requestCreateUser({
        data: {
          userType: 'client',
          ...values,
          send_client_email: undefined,
        },
        callback: data => {
          if (data.error) {
            if (data.error.message === 'Email already exists')
              reject(new SubmissionError({
                'email': 'Email address already taken',
                _error: 'Email address already taken'
              }));
            else 
              reject(new SubmissionError({
                _error: data.error.message,
              }));
            return;
          }
          if (sendEmail) {
          
            emailService.create({
              type: 'admin-created-welcome',
              user: values,
            });
          } 
          requestPatchUser({
            data: {
              id: data.id,
              meta: {
                entryType: 'admin-add',
                confirmed_details: true,
              }
            },
            callback: () => {
              resolve();
              receiveUser({
                ...data,
                ...values,
                force: true,
              });
              history.push('/clients/view?id=' + data.id);
            }
          });

        }
      });
    });
  }

  render() {
    return (
      <main className="main client-add" style={{ paddingTop:'60px' }}>
        <BackButton onClick={() => this.props.history.push('/clients')}/>
        <Card width='800px' padding='40px'>

          <Form onSubmit={this.onSubmit} initialValues={{password: this.state.password, clientStage: 'registered', send_client_email: 'Y' }}/>
        </Card>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestCreateUser,
    requestPatchUser,
    receiveUser,
  }, dispatch);

export default connect(null, mapDispatchToProps) (AddClient);
