// src/views/Products/index.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError, initialize as initializeForm } from 'redux-form'; 
import { Prompt } from 'react-router-dom';

import BackButton from 'components/Common/BackButton';

import RebateForm from './RebateForm';
import DetailsForm from './DetailsForm';
import AdditionalDetailsForm from './AdditionalDetailsForm';
import TheRestForm from './TheRestForm';

import { requestProduct, requestPatchProduct } from 'modules/products/actions';
import { getQueryVariables } from 'util/index';
import { PageLoader } from 'components/Loader';

class ProductView extends Component {
  
  state = {};

  componentDidMount() {
    const { id } = getQueryVariables(this.props.location.search);
    this.setState({ productId: id });
    this.props.requestProduct({ id });
  }

  onSubmit(product) {
    this.props.initializeForm(product.formName, product);
    let overrides = { id: this.state.productId };
    let hasOverrides = false;

    for (const key in product) {
      if (!product[key] || product[key].raw == null) continue;
      const { isOverriden, override, raw } = product[key];
      overrides[key] = isOverriden ? (override || '') : raw;
      if (isOverriden) hasOverrides = true;
    }
    
    overrides.product_active_overriden = product['product_active']?.isOverriden;

    overrides.isOverriden = hasOverrides;

    if (overrides.showLenderIdAs) {
      if (overrides.showLenderIdAs === 'MSL') {
        overrides.showLenderNameAs = 'Mystery Lender';
      }
      if (overrides.showLenderIdAs === 'BLS') {
        overrides.showLenderNameAs = 'Bank Lender';
      }
    } else {
      overrides.showLenderNameAs = '';
    }

    if (overrides.initial_ir_type) {
      overrides.ir_type = overrides.initial_ir_type;
      overrides.interest_rate_type = overrides.initial_ir_type;
      overrides.initial_interest_rate_type = overrides.initial_ir_type;
    }
    
    return new Promise((resolve, reject) => {
      try {
        this.props.requestPatchProduct({ 
          product: overrides, 
          callback: async ({ error }) => {
            if (error) return reject(new SubmissionError({
              _error: error,
            }));
            await resolve();
          }
        });

      } catch(error) {
        console.log(error);
        return reject(new SubmissionError({
          _error: error,
        }));
      }
      
    });
    
  }

  reviewsFinished() {
    const { forms } = this.props;
    const formNames = ['product-details', 'product-additional-details', 'product-the-rest'];
    for (let name of formNames) {
      if (!forms[name]) continue;
      for (let key in forms[name].values) {
        //console.log(key);
        if (forms[name].values[key] && forms[name].values[key].needsReview) {
          return true;
        }
      }
    } 
    return false
  }

  render() {

    const { product } = this.props;

    if (!product || ! product.id || product.id.raw !== this.state.productId) return (
      <main className="main dashboard">
        <PageLoader />
      </main>
    );
    const initialValues = Object.keys(product)
      .reduce((obj, key) => ({ 
        ...obj,
        [key]: { 
          ...product[key], 
          isOverriden: (!product[key].needsReview && product[key].override != null) ? 'Y' : ''},
      }), {});
    
    
    
    let { 
      //details
      isCustomProduct,
      product_active,
      is_priority_product,
      bank,
      bank_name,
      hiddenLenderName,
      hiddenLenderId,
      hiddenProductName,
      product_name,
      iir,
      initial_rate_period,
      rr,
      initial_ir_type,
      fixed_term,
      io_repayments,
      io_allowed_for_own_occ,
      showLenderIdAs,
      showLenderNameAs,

      // additional details
      maximum_loan_amount,
      minimum_loan_amount,
      maximum_term,
      maximum_lvr_refinance,
      maximum_lvr_investment,
      maximum_lvr_own_occ,
      maximum_lvr_owner_builder,
      maximum_lodoc_loan_amount,
      maximum_lvr_spec_builder,
      capitalise_lmi,
      minimum_genuine_savings,
      
      // rebate 
      rebate_type,
      rebate_value,
      rebate_tooltip,
      
      // the rest
      key_details,
      key_notes,

      // part I
      extra_repayments_allowed,
      interest_in_advance,
      full_offset,
      partial_offset,
      portable,
      redraw_allowed,
      pkg_seq,
      offsetTooltip,

      
      //part II
      bridging_loan,
      is_smsf,
      company,
      first_home_buyer,
      first_property_buyer,
      
      //part III
      other_establishment_fees,
      rate_lock_fee,
      lender_legals,
      settlement_fees,
      additional_valuation_fee,
      monthly_fees,
      annual_fees,
      application_fees,
      discharge_fee,
      includes_1_standard_val,
      
      //part IV
      atm_access,
      bpay_access,
      net_access,
      aust_post_access,
      branch_access,
      phone_access,

      //part V
      weekly_repayment,
      fortnightly_repayment,
      monthly_repayment,

    } = initialValues;

    if (includes_1_standard_val === 'N') includes_1_standard_val = '0';
    const detailsInit = {
      formName: 'product-details',
      isCustomProduct,
      product_active,
      is_priority_product,
      bank,
      bank_name,
      product_name,
      hiddenLenderName,
      hiddenLenderId,
      hiddenProductName,
      iir,
      initial_rate_period,
      rr,
      initial_ir_type,
      fixed_term,
      io_repayments,
      io_allowed_for_own_occ,
      showLenderIdAs,
      showLenderNameAs,
    };

    const additionalDetailsInit = {
      formName: 'product-additional-details',
      maximum_loan_amount,
      minimum_loan_amount,
      maximum_term,
      maximum_lvr_refinance,
      maximum_lvr_investment,
      maximum_lvr_own_occ,
      maximum_lvr_owner_builder,
      maximum_lodoc_loan_amount,
      maximum_lvr_spec_builder,
      capitalise_lmi,
      minimum_genuine_savings,
    };

    const rebateInit = {
      formName: 'product-rebates',
      rebate_type,
      rebate_value,
      rebate_tooltip,
    };

    const theRestInit = {
      formName: 'product-the-rest',

      key_details,
      key_notes,
      // part I
      extra_repayments_allowed,
      interest_in_advance,
      full_offset,
      partial_offset,
      portable,
      redraw_allowed,
      pkg_seq,
      offsetTooltip,

      //part II
      bridging_loan,
      is_smsf,
      company,
      first_home_buyer,
      first_property_buyer,

      //part III
      other_establishment_fees,
      rate_lock_fee,
      settlement_fees,
      lender_legals,
      additional_valuation_fee,
      monthly_fees,
      annual_fees,
      application_fees,
      discharge_fee,
      includes_1_standard_val,

      //part IV
      atm_access,
      bpay_access,
      net_access,
      aust_post_access,
      branch_access,
      phone_access,

      //part V
      weekly_repayment,
      fortnightly_repayment,
      monthly_repayment,
    };

    return (

      <main className="main dashboard">
        <div className="main-container">
          <BackButton margin='-15px 0 0 0'/>
          <DetailsForm onSubmit={this.onSubmit.bind(this)} initialValues={detailsInit}/>
          <AdditionalDetailsForm onSubmit={this.onSubmit.bind(this)} initialValues={additionalDetailsInit}/>
          <RebateForm onSubmit={this.onSubmit.bind(this)} initialValues={rebateInit}/>
          <TheRestForm onSubmit={this.onSubmit.bind(this)} initialValues={theRestInit}/>
        </div>
        <Prompt 
          when={this.reviewsFinished()}
          message='Not all changed values have been confirmed, leave anyway?'
        />
      </main>
      
    );
  }
}

const stateToProps = state => ({ 
  product: state.products.current,
  forms: state.form,
});

const dispatchToProps = dispatch => 
  bindActionCreators({
    requestProduct,
    requestPatchProduct,
    initializeForm,
  }, dispatch);

export default connect(stateToProps, dispatchToProps) (ProductView);