
import { takeEvery, call, put } from 'redux-saga/effects';

import {
  findProducts,
  getProductWithOverrides,
  patchProduct,
} from './api';

import {
  receiveFindProducts,
  receiveProduct,
  receivePatchProduct,

  REQUEST_FIND_PRODUCTS,
  REQUEST_PRODUCT,
  REQUEST_PATCH_PRODUCT,

} from './actions';



function* callFindProducts({ payload: { query, callback } }) {
  let result = yield call(findProducts, query);
  yield put(receiveFindProducts(result));
  if (callback)
    callback(result);
}

export function* findProductsSaga() {
  yield takeEvery(REQUEST_FIND_PRODUCTS, callFindProducts);
}


function* callGetProduct({ payload: { id, callback } }) {
  let result = yield call(getProductWithOverrides, id);
  yield put(receiveProduct(result));
  if (callback)
    callback(result);
}

export function* getProductSaga() {
  yield takeEvery(REQUEST_PRODUCT, callGetProduct);
}

function* callPatchProduct({ payload: { product, callback } }) {
  let result = yield call(patchProduct, product);
  yield put(receivePatchProduct(result));
  if (callback)
    callback(result);
}

export function* patchProductSaga() {
  yield takeEvery(REQUEST_PATCH_PRODUCT, callPatchProduct);
}