import { 
  REQUEST_UPDATE_APPLICATIONS,
  RECEIVE_UPDATE_APPLICATIONS,
  RECEIVE_GET_APPLICATIONS,
  REQUEST_MOVE_APPLICATION,
  REMOVE_STAGE,
  CREATE_STAGE,
  UPDATE_STAGE,
  RECEIVE_CREATE_APPLICATION,
  SET_CURRENT_DEAL,
} from './actions';

import { merge } from 'lodash';
import moment from 'moment';
import _ from 'lodash';
import { EXTERNAL_PATCH_USER, REQUEST_PATCH_USER } from 'modules/clients/actions';


const applications = (state = {}, { type, payload })  => {
  
  switch(type) {
  case RECEIVE_CREATE_APPLICATION: {
    let newState = _.cloneDeep(state); 
    let app = _.cloneDeep(payload);
    if (app.page) {
      const pageStages = Object.values(newState.stages).filter(x => x.page === app.page);
      const minIndex = pageStages.reduce((min, x) => {
        return Math.min(min, x.stageIndex);
      }, Infinity);
      const firstStage = pageStages.find(x => x.stageIndex === minIndex);
      app = { ...app, list: firstStage.id, products: [] };
      newState.stages[firstStage.id].applications = [ ...newState.stages[firstStage.id].applications, app ]; 
    }
    newState.list[app.id] = app;
    
    return newState;
  }
  case RECEIVE_UPDATE_APPLICATIONS: {  
    //debugger;
    let newState = _.cloneDeep(state); 
    if (!newState.list) newState.list = {};
    if (!newState.stages) newState.stages = {};
    const app = payload;

    if (app.ignoreNull) {
      for (const key in app) {
        if (app[key] === null) delete app[key];
      }
      delete app.ignoreNull;
    }

    const oldApp = newState.list[app.id] || {};
    const newApp = merge(_.cloneDeep(oldApp), app);
    if (newApp.note) {
      if (app.note.date) {
        const oldNote = newApp.notes.find(x => x.date === app.note.date);
        if (oldNote) {
          const notes = newApp.notes.filter(x => x.date !== app.note.date);
          newApp.notes = [ ...notes, { ...oldNote, visibleToReferrer: app.note.visibleToReferrer }]
        }
      } else {
        newApp.notes = [ ...(newApp.notes ?? []), { ...app.note, date: moment().format() } ];
      }
      delete newApp.note;
    }

    newState.list[app.id] = newApp;
    
    if (oldApp.list && newApp.list !== oldApp.list) {
      let oldStage = newState.stages[oldApp.list];
      oldStage.applications = oldStage.applications.filter(x => x.id !== newApp.id);
      newState.stages[oldStage.id] = oldStage;
    }
    
    let newStage = newState.stages[newApp.list] || { applications: [] };
    let appIndex = newStage.applications.findIndex(a => a.id === app.id);
    let newApplications = [ ...newStage.applications ];
    if (appIndex !== -1) {
      newApplications[appIndex] = newApp;
    } else {
      newApplications.push(newApp);
    }
      
    newStage.applications = newApplications
      .sort((a, b) => a.listOrder - b.listOrder)
      .sort(sortByUrgency);
    if (newStage.name === 'Unassigned') {
      newStage.applications = newStage.applications.sort(sortToTopIfVerified);
    }
    newState.stages[newStage.id] = newStage;
      
    
    return newState; 
  }
  case RECEIVE_GET_APPLICATIONS: {
    // if payload list / stages already exists in state, merge, undefined values in list items shouldn't overwrite existing values
    let newState = _.cloneDeep(state); 
    if (payload.list) {
      if (!newState.list) newState.list = {};
      Object.keys(payload.list).forEach(id => {
        if (newState.list[id]) {
          newState.list[id] = merge(_.cloneDeep(newState.list[id]), payload.list[id]);
        } else {
          newState.list[id] = payload.list[id];
        }
      });
      delete payload.list;
    }
    if (payload.stages) {
      if (!newState.stages) newState.stages = {};
      Object.keys(payload.stages).forEach(id => {
        if (newState.stages[id]) {
          newState.stages[id] = merge(_.cloneDeep(newState.stages[id]), payload.stages[id]);
        } else {
          newState.stages[id] = payload.stages[id];
        }
      });
      delete payload.stages;
    }
    return { ...newState, ...payload };
  }
  
  case CREATE_STAGE: {
    const stages = { ...state.stages };
    const newStage = payload;
    stages[payload.id] = newStage;
    return { ...state, stages };
  }
  case REMOVE_STAGE: {
    const stages = { ...state.stages };
    delete stages[payload];
    return { ...state, stages };
  }
  case UPDATE_STAGE: {
    const stages = { ...state.stages };
    const oldStage = stages[payload.id];
    const newStage = merge(oldStage, payload);
    stages[payload.id] = newStage;
    return { ...state, stages };
  }
  case REQUEST_PATCH_USER: {
    let client = payload.data;

    const applications = Object.values(state.list)
      .filter(x => x.userId === client.id)
      .map(({ id }) => state.list[id]);
    
    if (!applications.length) return state;
    
    let newState = _.cloneDeep(state); 

    client = { ...applications[0].client, ...client };

    for (const application of applications) {
      const newApp = { ...application, client };
      newState.list[application.id] = newApp;
      let newStage = newState.stages[newApp.list] ?? [];
      let appIndex = newStage.applications.findIndex(a => a.id === newApp.id);
      if (appIndex !== -1) {
        let newApplications = [ ...newStage.applications ];
        newApplications[appIndex] = newApp;
        newStage.applications = newApplications.sort(sortByUrgency);
        if (newStage.name === 'Unassigned') {
          newStage.applications = newStage.applications.sort(sortToTopIfVerified);
        }
        newState.stages[newStage.id] = newStage;
      } 
    }

    return newState;
  }
  case EXTERNAL_PATCH_USER: {
    let client = payload.data;
    let newState = _.cloneDeep(state); 
    if (!newState || !newState.list || !newState.stages) return state;
    let appList = Object.values(newState.list);
    let stages = newState.stages;
    let clientApps = appList.filter(x => x.userId === client.id);
    if (!clientApps.length) return newState;
    for (const app of clientApps) {
      app.client = { ...app.client, ...client };
      if (app.list) {
        const appStage = stages[app.list];
        if (!appStage) continue;
        const stageApp = appStage.applications.find(x => x.id === app.id);
        stageApp.client =  { ...stageApp.client, ...client };
      }
    }
    //debugger;
    return newState;
  }
  case SET_CURRENT_DEAL: {
    return { ...state, currentDeal: payload };
  }
  default:
    return state;
  }
};

export default applications;

const sortToTopIfVerified = (a, b) => {
  return (b.user_isMobileVerified ? 1 : 0) - (a.user_isMobileVerified ? 1 : 0);
};

const sortByUrgency = (a, b) => {
  if (!a.dueDate && !b.dueDate) return 0;
  if (!a.dueDate) return 1;
  if (!b.dueDate) return -1;
  const dateA = moment(a.dueDate);
  const dateB = moment(b.dueDate);
  const dateAValid = dateA.isValid();
  const dateBValid = dateB.isValid();
  if (dateAValid && dateBValid) return moment(a.dueDate).valueOf() - moment(b.dueDate).valueOf();
  if (!dateAValid && !dateBValid) return 0;
  if (!dateAValid) return 1;
  if (!dateBValid) return -1;
};