import React from 'react';
import styled, { css } from 'styled-components';

const Spinner = styled.span`
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 50%;
  animation: button-spinner .75s linear infinite;

  height: 35px;
  width: 35px;
  border: 4px solid ${props => props.color || '#2291FF'};
  border-right-color: transparent;
  opacity: 1;

  @keyframes button-spinner {
    to { transform: rotate(360deg); }
  }
`;

const SpinnerContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
`;

const Loading = props => (
  <SpinnerContainer {...props}>
    <Spinner />
  </SpinnerContainer>
);

export default Loading;