import styled from 'styled-components';

export default styled.p`
  text-overflow: ellipsis;
  overflow: ${p => p.noOverflow ? 'hidden' : 'visible'};
  white-space: ${p => p.noWrap ? 'nowrap' : 'inherit'};
  font-family: Lato;
  line-height: ${p => p.lineHeight || ''};
  font-size: ${p => p.size || '12px'};
  font-weight: ${p => p.weight || '400'};
  color: ${p => p.color || '#223241'};
  margin-bottom: ${p => p.marginBottom || '10px'};
  margin-top: ${p => p.marginTop || '0'};
  text-decoration: ${p => p.decoration || 'none'};
  ${p => p.center ? `
    width: 100%;
    text-align: center;
  ` : ''}
  ${p => p.width ? `
    width:${p.width};` 
    : ''}
`;