import React, { Fragment } from 'react';
import styled from 'styled-components';
import checkBold from 'img/button/check-white.png';


const CheckBox = ({ input, label, ...props }) => {
  const id = `checkbox-${input.name}${props.formName ? ('-' + props.formName) : ''}`;
  return (
    <Fragment>
      <Label htmlFor={id} disabled={props.disabled} style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }} {...props}>
        <Input id={id} {...props} {...input} checked={input.value} type="checkbox" />
        {label}
      </Label>
    </Fragment>
  )
}


export default CheckBox;

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  border: 1px solid #d8dce7;
  box-shadow: 0 1px 0 0 #e9ebf1;
  border-radius: 4px;
  background: #fff;
  background-size: 0;
  margin-right: 10px;

  &:hover {
    border: 1px solid #2689EB;
  }

  &:checked {
    background-color: #2689EB;
    border: 1px solid #2689EB;
    background-image: url(${checkBold});
    background-size: 10px 8px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &:disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
    
    &:hover {
      border: 1px solid #d8dce7;
    }

    &:checked {
    background-color: #767676;
    border: 1px solid #767676;
    background-image: url(${checkBold});
    background-size: 10px 8px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  }
`;

const Label = styled.label`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  color: ${p => p.disabled ? '#888' : '#000'}
`;