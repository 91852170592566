import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'components/Styled/Card';
import Select from 'components/Styled/Select';
import TextInput from 'components/Styled/TextInput';
import SearchBar from 'components/Common/SearchBar';
import { ButtonPlain, ButtonPrimary, ButtonImageSquare } from 'components/Styled/Button';
import { ToggleButton } from 'components/Styled/CheckBox';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import crypto from 'crypto';
import { EditDocModal, ApplyTemplateModal } from '../Settings/Components/DocModals';
import { Heading, Label, ToggleContainer, BigBlueButton, Row, Column, PlainButton, Templates, Library, TemplateItems, 
  RequestColumn, ListItem, TemplateItem, LibraryRequest, BigTrashButton, ActionButtons, ButtonsLeft, Saved, Unsaved, 
  ButtonImage, Important, ApplicantCircle } 
  from '../Settings/Components/DocShared';

import Debouncer from 'util/debouncer';
import _ from 'lodash';

import imgFourSquares from 'img/elements/four-squares.svg';
import imgUpload from 'img/button/upload.svg';
import imgTemplate from 'img/button/template.svg';
import imgTrash from 'img/button/trash-can.svg';
import imgMagnify from 'img/button/magnify.svg';
import imgMagnifyGrey from 'img/button/magnify-grey.svg';
import imgCrossRed from 'img/button/cross-red.svg';
import imgCrossGrey from 'img/button/cross-grey.svg';
import imgTick from 'img/button/green-tick.svg';
import imgComplete from 'img/elements/tick-complete.svg';
import imgWarning from 'img/elements/warning-grey.svg';

import { requestAddUserDocument, requestFindDocuments, requestFindDocumentTemplates, requestPatchUserDocument, requestRemoveUserDocument  } from 'modules/documents/actions';

import { requestPatchUser } from 'modules/clients/actions';
import DocsPDF from 'shared/DocsPDFStandAlone';
import download from 'downloadjs';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { DocumentsView } from '../Shared/Files/DocumentsTab';
import moment from 'moment';


const DOCUMENTS = 1;
const REQUEST_DOCS = 2;
const UPLOADS = 3;

const debouncer = new Debouncer;

const SupportingDocs = ({ client }) => {
  
  const dispatch = useDispatch();
  useEffect(() => {
    //dispatch(requestFindDocuments());
    dispatch(requestFindDocumentTemplates());
  }, []);

  const settings = useSelector(state => state.user.globalSettings);
  let libraryState = useSelector(state => state.documents?.library) ?? [];
  const library = _.cloneDeep(libraryState).sort((a, b) => a.name?.localeCompare(b.name, 'en'));
  let templatesState = useSelector(state => state.documents?.templates) ?? [];
  let templates = _.cloneDeep(templatesState).sort((a, b) => a.name?.localeCompare(b.name, 'en'));
  templates = templates.map(template => {
    const documents = (template.documents ?? []).map(doc => library.find(x => x.id === doc.id) ?? doc);
    return { ...template, documents };
  });
  const userDocs = useSelector(state => state.documents?.users) ?? [];
  
  let user = client;
  user.documents = userDocs[user.id];


  const { openModal, closeModal } = useContext(modalContext);
  const [ currentTab, setCurrentTab ] = useState(DOCUMENTS);
  const [ templateId, setTemplateId ] = useState();
  const [ selectedDocId, setSelectedDocId ] = useState();
  const [ selectedPropertyDocId, setSelectedPropertyDocId ] = useState();
  const [ searchText, setSearchText ] = useState('');
  const [ templateSearchText, setTemplateSearchText ] = useState('');

  const [ savingPDF, setSavingPDF ] = useState(false);
  
  const [ noOfApplicants, setNumberOfApplicants ] = useState(user.numberOfApplicants ?? 1);
  const [ applicantNames, setApplicantNames ] = useState(user.applicantNames ?? ['', '', '', '']);
  const [ oldApplicantNames, setOldApplicantNames ] = useState(user.applicantNames ?? ['', '', '', '']);
  const [ isFinal, setIsFinal ] = useState(!!user.isFinalDocs);

  const newApplicantNames = user.applicantNames ?? ['', '', '', ''];
  const newNoOfApplicants = (user.numberOfApplicants ?? 1).toString();
  const newIsFinal = !!user.isFinalDocs;
  useEffect(() => {

    if (!_.isEqual(!!newIsFinal, !!isFinal)) {
      setIsFinal(!!newIsFinal);
    }

    if (!_.isEqual(newNoOfApplicants, noOfApplicants)) {
      setNumberOfApplicants(newNoOfApplicants);
    }

    if (!_.isEqual(oldApplicantNames, newApplicantNames)) {

      if (!_.isEqual(newApplicantNames, applicantNames)) {
        setApplicantNames(newApplicantNames);
        setOldApplicantNames(newApplicantNames);
      }
    }

  });

  const [ isSaving, setIsSaving ] = useState(0);
  const saveRef = useRef();
  saveRef.current = isSaving;

  const selectTemplate = id => {
    setTemplateId(id);
    setSelectedDocId(null);
    setSelectedPropertyDocId(null);
  };

  const selectDoc = id => {
    setSelectedDocId(id);
    setTemplateId(null);
    setSelectedPropertyDocId(null);
  };

  const selectPropertyDoc = id => {
    setSelectedPropertyDocId(id);
    setSelectedDocId(null);
    setTemplateId(null);
  };

  const startIsSaving = () => {
    setIsSaving(saveRef.current + 1);
  };
  const endIsSaving = () => {
    setIsSaving(Math.max(0, saveRef.current - 1));
  };

  const endIsSavingDebouncer = () => {
    if ((saveRef.current - 1000 < 0) && (saveRef.current > 0)) return;
    setIsSaving(Math.max(0, saveRef.current - 1000));
  };
 
  console.log('Is Saving:', isSaving);
  
  const userDocuments = user.documents ?? [];
  // const templateId = templates.some(x => x.id === templateIdState) ? templateIdState : templates[0]?.id;
  
  let [docs, propertyDocs] = split(userDocuments, x => !x.isPropertyDoc);
  docs = docs.sort((a, b) => a.name?.localeCompare(b.name, 'en'));
  propertyDocs = propertyDocs.sort((a, b) => a.name?.localeCompare(b.name, 'en'));
  // const selectedDocId = docs.some(x => x.id === selectedDocIdState) ? selectedDocIdState : docs[0]?.id;
  // const selectedPropertyDocId = propertyDocs.some(x => x.id === selectedPropertyDocIdState) ? selectedPropertyDocIdState : propertyDocs[0]?.id;

  let requestedDocuments = [];
  for (const doc of propertyDocs) {
    requestedDocuments.push({ 
      ...doc, 
      applicantIndex: 0,
      applicant: applicantNames[0], 
      approved: doc.meta.approved?.[0], 
      meta: null,
    });
  }
  for (const doc of docs) {
    for (var i = 0; i < parseInt(noOfApplicants); i++) {
      if (doc.meta.applicants[i]) requestedDocuments.push({ 
        ...doc, 
        applicant: applicantNames[i], 
        applicantIndex: i,
        approved: doc.meta.approved?.[i], 
        meta: null,
      });
    }
  }
  requestedDocuments = requestedDocuments.sort((a, b) => a.applicant ?.localeCompare(b.applicant, 'en'));
  requestedDocuments = requestedDocuments.sort((a, b) => a.name ?.localeCompare(b.name, 'en'));

  const approvedCount = requestedDocuments.reduce((total, doc) => doc.approved ? total + 1 : total, 0);
  console.log('Requested Docs', requestedDocuments);
  
  const uploads = (user.uploads ?? []).filter(x => x.type === 'payslip');
  
  const createDocument = values => {
    closeModal();
    const document = { ...values };
    document.id = crypto.randomBytes(20).toString('hex');
    document.type = 'user';
    document.userId = user.id;
    delete document.documentTemplateId;
    if (!document.meta) {
      document.meta = { 
        applicants: new Array(4).fill(true, 0, noOfApplicants).fill(false, noOfApplicants),
        approved: [false, false, false, false]
      };
    }

    console.log('Creating Document:', document); 
    startIsSaving(); 
    dispatch(requestAddUserDocument({ 
      data: document,
      callback: endIsSaving,
    }));
  };

  const editDocument = values => {
    closeModal(); 
    startIsSaving(); 
    dispatch(requestPatchUserDocument({ 
      data: values,
      callback: endIsSaving,
    }));
  };

  const setApproved = doc => 
    approved => {
      const document = user.documents.find(x => x.id === doc.id);
      const meta = { ...document.meta }; 
      const index = doc.applicantIndex;
      meta.approved[index] = approved;
      editDocument({ id: doc.id, meta });
    };

  const deleteDocument = id => {
    startIsSaving(); 
    dispatch(requestRemoveUserDocument({ 
      id,
      userId: user.id,
      callback: endIsSaving,
    }));
  };

  const openCustomDocModal = () => {
    openModal({ 
      component: EditDocModal, 
      props: { 
        onSubmit: createDocument, 
        document: {}, 
        heading: 'Add Custom Request' 
      } 
    }); 
  };

  const openEditDocModal = id => {
    const doc = userDocuments.find(x => x.id === id);
    if (!doc) return;
    openModal({ 
      component: EditDocModal, 
      props: { 
        onSubmit: editDocument, 
        document: { ...doc, meta: undefined }, 
        heading: 'Document Detail' 
      } 
    });
  };

  const addTemplateDocs = ({ templateId, meta }) => {
    let template = templates.find(x => x.id === templateId);
    if (!template) template = templates[0];
    const docs = template.documents ?? [];
    for (let doc of docs) {
      if (userDocuments.some(x => x.name === doc.name)) continue;
      doc.meta = _.cloneDeep(meta);
      createDocument(doc);
    }
    closeModal();
  };

  const openApplyTemplateModal = () => {
    openModal({ 
      component: ApplyTemplateModal, 
      props: { 
        onSubmit: addTemplateDocs, 
        templateId,
      } 
    });
  };

  const changeApplicantName = (index, value) => {
    let names = [ ...applicantNames ];
    names[index] = value;
    setApplicantNames(names);
    if (isSaving < 1000) setIsSaving(isSaving + 1000); 
    debouncer.run(() => {
      console.log('Saving Names', names);
      dispatch(requestPatchUser({
        data: {
          id: user.id,
          applicantNames: names
        },
        callback: endIsSavingDebouncer,
      }));
    }, 2000);
  };

  const changeApplicantNumber = n => {
    setNumberOfApplicants(n);
    startIsSaving();

    dispatch(requestPatchUser({
      data: {
        id: user.id,
        numberOfApplicants: n
      },
      callback: endIsSaving,
    }));

  };

  const toggleApplicant = (doc, index) => { 
    const document = { ...doc };
    const { applicants } = document.meta;
    applicants[index] = !applicants[index];
    console.log('toggle from', doc.meta.applicants, 'to', applicants);
    startIsSaving();
    dispatch(requestPatchUserDocument({
      data: document,
      callback: endIsSaving,
    }));
  };

  const finishedSavingPDF = blob => {
    setSavingPDF(false);
    const now = moment();
    download(blob, `Supporting Documents - ${user.lastName || user.firstName} - ${now.format('DDMMYY')}.pdf`, 'application/pdf');
  };

  const onKeyPress = e => {
    if (currentTab === DOCUMENTS) return;
    switch(e.code) {
    case 'ArrowUp': {
      if (templateId) {
        const list = templates
          .filter(x => x.name?.toLowerCase()?.includes(templateSearchText.toLowerCase()))
          .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
        setUp(e, templateId, list, setTemplateId, 'template-list');
      }
      if (selectedDocId) {
        const list = docs
          .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
        setUp(e, selectedDocId, list, setSelectedDocId, 'individual-items');
      }
      if (selectedPropertyDocId) {
        const list = propertyDocs
          .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
        setUp(e, selectedPropertyDocId, list, setSelectedPropertyDocId, 'property-items');
      }
      return;

    }

    case 'ArrowDown': {
      if (templateId) {
        const list = templates
          .filter(x => x.name?.toLowerCase()?.includes(templateSearchText.toLowerCase()))
          .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
        setDown(e, templateId, list, setTemplateId, 'template-list');
      }
      if (selectedDocId) {
        const list = docs
          .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
        setDown(e, selectedDocId, list, setSelectedDocId, 'individual-items');
      }
      if (selectedPropertyDocId) {
        const list = propertyDocs
          .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
        setDown(e, selectedPropertyDocId, list, setSelectedPropertyDocId, 'property-items');
      }
      return;
    }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [templateId, selectedDocId, selectedPropertyDocId, currentTab]);

  const toggleFinal = () => {

    startIsSaving();
    dispatch(requestPatchUser({
      data: {
        id: user.id,
        isFinalDocs: !isFinal,
      },
      callback: endIsSaving,
    }));

    setIsFinal(!isFinal);
  };

  // useEffect(() => {
  //   if (user.meta?.isFinalDocs) setIsFinal(true);
  // }, [user.meta?.isFinalDocs]);

  console.log('is final', isFinal);
  return (<>
    <TabBar>
      <Tab active={currentTab === DOCUMENTS} onClick={() => setCurrentTab(DOCUMENTS)}>
        Documents
        <BluePill><div>{requestedDocuments.length}</div></BluePill>
      </Tab>
      <Tab active={currentTab === REQUEST_DOCS} onClick={() => setCurrentTab(REQUEST_DOCS)}>
        Request Docs <FourSquares/>
      </Tab>
      {!!(uploads ?? []).length &&
        <Tab active={currentTab === UPLOADS} onClick={() => setCurrentTab(UPLOADS)}>
          Qualifier Uploads <BluePill><div>{uploads.length}</div></BluePill>
        </Tab>
      }
      <span style={{float: 'right', paddingTop: '25px'}}>
        {isSaving ? <Unsaved/> : <Saved/>}
      </span>
    </TabBar>
    <Card minHeight='300px' minWidth='800px' maxWidth='1100px' style={{ borderTopLeftRadius: '0'}}>
      <Page active={currentTab === UPLOADS}>
        <DocumentsView />
      </Page>
      <Page active={currentTab === DOCUMENTS}>
        <Heading>Requested Documents <FilesReceived approvedFiles={approvedCount} totalFiles={requestedDocuments.length}/></Heading>
        <TableBorder>
          <DocumentTable>
            <tr>
              <th style={{width: '180px'}}>Applicant</th>
              <th>Document Name</th>
              <th style={{width: '240px'}}>Status</th>
            </tr>
            {requestedDocuments.map(doc => {
              return (
                <tr>
                  <td>{doc.applicant}</td>
                  <td>{doc.name}</td>
                  <td>
                    <Status urgent={doc.isUrgent} approved={doc.approved}/>
                    <StatusButtons approved={doc.approved} setApproved={setApproved(doc)} onMagnify={() => openEditDocModal(doc.id)} />
                  </td>
                </tr>
              );
            })}
          </DocumentTable>
        </TableBorder>
       
        <PullRight>
          <Column>
            <BlueButton width='125px' type='button' onClick={() => setSavingPDF(true)}><ButtonSpinner show={savingPDF}/>{savingPDF ? 'Generating' : 'Generate PDF'}</BlueButton>
          </Column>
          <Column style={{ padding: '5px 40px 0 0', fontSize: '15px'}}>
            <ToggleButton checked={isFinal} onChange={toggleFinal}/>
            Final Request
          </Column>
        </PullRight>
        
      </Page>
      <Page active={currentTab === REQUEST_DOCS}>
        <Heading>Applicant Details</Heading>
        
        <Row>
          <Column width='200px' paddingRight='40px'>
            <Label style={{paddingTop: '3px', paddingBottom: '16px'}}>How many applicants?</Label>
            <Select width='65px' value={noOfApplicants} onChange={e => changeApplicantNumber(e.target.value)}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </Select>
          </Column>
          <Row width='600px' style={{flexWrap: 'wrap'}}>  
            {Array.from({ length: noOfApplicants }, (_, index) => (
              <Column paddingRight='40px' paddingBottom='10px' key={index}>
                <Label>
                  <ApplicantCircle number={index + 1} active={true} style={{ marginRight: '10px'}}/>
                  Applicant {index + 1} Name
                </Label>
                <TextInput width='200px' value={applicantNames[index]} onChange={e => changeApplicantName(index, e.target.value)}/>
              </Column>
            ))}
          </Row>
        </Row>
        <Row paddingTop='30px'>
          <Column width='50%'>
            <Heading>Request Library</Heading>
            <Library>
              <ul>
                {library
                  .filter(doc => doc.type === 'library')
                  .filter(doc => doc.name?.toLowerCase()?.includes(searchText.toLowerCase()))
                  .map(doc => {
                    const active = userDocuments.some(x => x.name === doc.name);
                    return (
                      <LibraryRequest
                        tabIndex={-1}
                        onClick={() => !active && createDocument(doc)}
                        key={doc.id}
                        active={!active} 
                      >{doc.name}
                      </LibraryRequest>
                    );
                  })}
              </ul>
            </Library>
            <SearchBar
              placeholder='Enter your search query...'
              msDebounce={300} 
              onChange={setSearchText}
              width='calc(100% - 70px)'
            />
            <PlainButton width='205px' onClick={openCustomDocModal}>
              Add Custom Request <ButtonImage src={imgUpload}/>
            </PlainButton>
          </Column>
          <Column width='50%'>
            <Heading>
              Requested Individual Items 
              <ButtonsLeft active={!!selectedDocId && docs.some(x => x.id === selectedDocId)} onDelete={() => deleteDocument(selectedDocId)} onMagnify={() => openEditDocModal(selectedDocId)}/>
            </Heading>
            <IndividualItems id='individual-items'>
              <ApplicantColumn hasBorder={docs.length}>
                {docs
                  .map(doc => (
                    <ApplicantRow key={doc.id} style={{ width: (noOfApplicants * 27 + 2) + 'px'}}>
                      <ApplicantCircle number={1} active={doc.meta.applicants?.[0]} onClick={() => toggleApplicant(doc, 0)}/>
                      {noOfApplicants > 1 && <ApplicantCircle number={2} active={doc.meta.applicants?.[1]} onClick={() => toggleApplicant(doc, 1)}/>}
                      {noOfApplicants > 2 && <ApplicantCircle number={3} active={doc.meta.applicants?.[2]} onClick={() => toggleApplicant(doc, 2)}/>}
                      {noOfApplicants > 3 && <ApplicantCircle number={4} active={doc.meta.applicants?.[3]} onClick={() => toggleApplicant(doc, 3)}/>}
                    </ApplicantRow>
                  ))
                }
                
              </ApplicantColumn>
              <RequestColumn>
                {docs
                  .map(doc => (
                    <RequestItemStyle tabIndex={-1} key={doc.id} selected={doc.id === selectedDocId} onClick={() => selectDoc(doc.id)}>
                      {!!doc.isUrgent && <Important/>}
                      {doc.name}
                    </RequestItemStyle>
                  ))
                }

              </RequestColumn>
            </IndividualItems>  
          </Column>
        </Row>
        <Row paddingTop='40px'>
          <Column width='50%'>
            <Heading>Templates</Heading>
            <Tempates id='template-list'>
              {(templates || [])
                .filter(doc => doc.name?.toLowerCase()?.includes(templateSearchText.toLowerCase()))
                .sort((a, b) => a.name?.localeCompare(b.name, 'en'))
                .map(doc => 
                  <ListItem 
                    tabIndex={-1}
                    onClick={() => selectTemplate(doc.id)}
                    key={doc.id}
                    selected={doc.id === templateId} 
                    isBankStatement={doc.isBankStatement} 
                    isPropertyDocument={doc.isPropertyDoc}
                    important={doc.isUrgent}
                  >
                    {doc.name}
                  </ListItem>
                )}
            </Tempates>
            <SearchBar
              style={{ marginTop: '10px'}}
              placeholder='Enter your search query...'
              msDebounce={300} 
              onChange={setTemplateSearchText}
              width='calc(100% - 70px)'
            />
            <PlainButton width='179px' onClick={openApplyTemplateModal}>
              Apply Template <ButtonImage src={imgTemplate}/>
            </PlainButton>
          </Column>
          <Column width='50%'>
            <Heading>
              Requested Property Items
              <ButtonsLeft active={!!selectedPropertyDocId && propertyDocs.some(x => x.id === selectedPropertyDocId)} onDelete={() => deleteDocument(selectedPropertyDocId)} onMagnify={() => openEditDocModal(selectedPropertyDocId)}/>
            </Heading>
            <PropertyItems id='property-items'>
              {propertyDocs
                .map(doc => (
                  <ListItem tabIndex={-1} key={doc.id} selected={doc.id === selectedPropertyDocId} onClick={() => selectPropertyDoc(doc.id)}>
                    {!!doc.isUrgent && <Important/>}
                    {doc.name}
                  </ListItem>
                ))
              }
            </PropertyItems>
          </Column>
        </Row>
        <Seperator/>
        <PullRight>
          <Column>
            <BlueButton width='125px' type='button' onClick={() => setSavingPDF(true)}><ButtonSpinner show={savingPDF}/>{savingPDF ? 'Generating' : 'Generate PDF'}</BlueButton>
          </Column>
          <Column style={{ padding: '5px 40px 0 0', fontSize: '15px'}}>
            <ToggleButton checked={isFinal} onChange={toggleFinal}/>
            Final Request
          </Column>
        </PullRight>
      </Page>
    </Card>
    <div style={{ minWidth: '800px', maxWidth: '1100px' }}>
      <span style={{float: 'right', paddingTop: '10px'}}>
        {isSaving ? <Unsaved/> : <Saved/>}
      </span>
    </div>
    
    {savingPDF &&
      <PDF>
        <DocsPDF user={user} settings={settings} isFinal={isFinal} finished={finishedSavingPDF}/>
      </PDF>
    }
    
  </>);
};

export default SupportingDocs;

const setUp = (e, id, list, set, elementId) => {
  const el = document.getElementById(elementId);
  if (!el.contains(document.activeElement)) return;
  const index = list.findIndex(x => x.id === id);
  if (index === -1) return;
  e.preventDefault();
  if (index - 1 >= 0) {
    const newId = list[index - 1]?.id;
    if (el) el.scrollTop = el.scrollTop - 38;
    set(newId);
  }
};

const setDown = (e, id, list, set, elementId) => {
  const el = document.getElementById(elementId);
  if (!el.contains(document.activeElement)) return;
  const index = list.findIndex(x => x.id === id);
  if (index === -1) return;
  e.preventDefault();
  if (index + 1 < list.length) {
    const newId = list[index + 1]?.id;
    if (el) el.scrollTop = el.scrollTop + 38;
    set(newId);
  }
};

function split(arr = [], fn) {
  const a = [], b = [];
  for (const x of arr)
    fn(x) ? a.push(x) : b.push(x);
  return [a, b];
}

const TableBorder = styled.div`
  border: solid 1px #D8DCE7;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 30px;
`;

const DocumentTable = styled.table`
  table-layout: fixed;
  width: 100%;

  tr {
    td {
      padding-left: 20px;
      font-size: 13px;
      height: 50px;
      line-height: 50px; 
      position: relative;
    }

    th {
      padding-left: 20px;
      color: #585e6d;
      font-size: 12px;
      font-weight: 700;
      border-bottom: solid 1px #D8DCE7;
      background: #F6F7FB;
      height: 40px;
      line-height: 40px;
      text-align: left;
    }
    
    &:nth-child(odd) {
      background: #FAFAFD;
    }

    &:hover {
      background: #DFEFFF;
    }
  }

`;

const PDF = styled.div`
  position: absolute;
  left: 10000px;
  top: -100000px;

  * {
    font-family: LatoPDF, sans-serif !important;
  }

  li {
    font-family: inherit;
  }
`;

const Status = ({approved, urgent}) => {
  if (approved) return <Approved>Approved</Approved>;
  else return <Waiting>Waiting {!!urgent && '(!)'}</Waiting>;
};

const FilesReceived = ({ approvedFiles, totalFiles }) => {
  return <FilesReceivedContainer>
    <FilesReceivedIcon src={approvedFiles === totalFiles ? imgComplete : imgWarning}/>
    {approvedFiles} out of {totalFiles} {totalFiles > 1 ? 'files' : 'file'} received
  </FilesReceivedContainer>;
};

const FilesReceivedContainer = styled.span`
  position: absolute;
  right: 10px;
  top: 0;
  color: #151825;
  font-size: 14px;
  font-weight: 400;
`;

const FilesReceivedIcon = styled.img`
  margin-right: 9px;
  position: relative;
  top: 2px;
`;

const Approved = styled.span`
  color: #26bd27;
  font-weight: 700;
`;

const Waiting = styled.span`
  color: #7C8CAE;
  font-weight: 700;
`;

const PropertyItems = styled(Card)`
  height: 202px;
  overflow: auto;
  padding: 0;
`;

const Tempates = styled(Card)`
  width: calc(100% - 70px);
  height: 202px;
  overflow: auto;
  padding: 0;
`;

const IndividualItems = styled(Card)`
  display: flex;
  padding: 0;
  width: 100%;
  height: 393px;
  overflow: auto;
  /* align-content: stretch; */
`;

const ApplicantColumn = styled.ul`
  display: flex;
  flex-direction: column;
  ${p => p.hasBorder && css`
    border-right: solid 1px #D8DCE7;
  `}
  
  /* border-right: solid 1px #D8DCE7; */
  padding: 7px 0 0 7px;
  min-height: 391px;
  max-height: 100%;
`;

const RequestItemStyle = styled(ListItem)`
  flex-shrink: 0;
`;


const ApplicantRow = styled.li`
  height: 38px;
  flex-shrink: 0;
  /* border-right: solid 1px #D8DCE7; */
  padding-right: 7px;

  span:not(:last-child) {
    margin-right: 5px;
  }  
`;


const IconButton = styled(ButtonImageSquare)`
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
`;

const MagnifyButton = styled(IconButton)`
  background-image: url(${imgMagnify});
`;

const TickButton = styled(IconButton)`
  background-image: url(${imgTick});
`;

const MagnifyButtonGrey = styled(IconButton)`
  background-image: url(${imgMagnifyGrey});
  &:hover {
    background-image: url(${imgMagnify});
  }
`;

const CrossButton = styled(IconButton)`
  background-image: url(${imgCrossGrey});
  &:hover {
    background-image: url(${imgCrossRed});
  }
`;

const TableActionButtons = ({ approved, setApproved, onMagnify, ...props }) => approved ?
  <div {...props}>
    <CrossButton onClick={() => setApproved(false)}/>
    <MagnifyButtonGrey onClick={onMagnify}/>
  </div>
  :
  <div {...props}>
    <TickButton onClick={() => setApproved(true)}/>
    <MagnifyButton onClick={onMagnify}/>
  </div>;

const StatusButtons = styled(TableActionButtons)`
  display: inline;
  position: absolute;
  right: 30px;
  top: 10px;
`;

 
const FourSquares = styled.img.attrs({
  src: imgFourSquares,
})`
  margin-left: 15px;
  position: relative;
  top: 3px;

`;

const Seperator = styled.div`
  width: 100%;
  height: 0;
  border-top: solid 1px #d8dce7;
  margin: 40px 0 30px 0;
`;

const BlueButton = styled(ButtonPrimary)`
  font-weight: 700;
  margin-top: 10px;
  margin-top: 0;
`;


const BluePill = styled.span`
  padding-top: 1px;
  margin-left: 15px;
  display: inline-flex;
  width: 30px;
  height: 16px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  background-color: #2291FF;
  justify-content: center;
  align-items: center;
`;

const PullRight = styled(Row)`
  flex-direction: row-reverse;
`;

const TabBar = styled.div`
  min-width: 800px;
  max-width: 1100px;
  margin-bottom: -1px;
  margin-top: 40px;
  overflow: visible;
`;

const Page = styled.div`
  display: none;
  ${p => p.active && css`
    display: block;
  `}  
`;

const Tab = styled.div`
  height: 50px;
  line-height: 50px;
  min-width: 188px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #ECECEC;
  border: 1px solid #D8DCE7;
  color: #4F4F4F;
  position: relative;
  cursor: pointer;

  ${p => p.active && css`
    background-color: #fff;
    border-bottom: none;
    color: #101922;
    cursor: default;

    &:after {
      content: '';
      background-color: #fff;
      /*   */
      height: 5px;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      z-index: 1000;
      
    }

    &:hover {
      border-bottom: none;
    }
  `}

  &:hover {
    background-color: #fff;
  }
`;
