import React, { useContext } from 'react';

import imgClose from 'img/button/close-modal.png';
import FocusTrap from 'focus-trap-react';
import styled from 'styled-components';
import Card from 'components/Styled/Card';
import { PullRight } from 'components/Styled/Layout';
import { modalContext } from '.';

export const ModalBox = ({ isFocused, children, ...props }) => {
  const { closeModal } = useContext(modalContext);
  
  return (
    <FocusTrap>
      <VerticalCenter onMouseDown={e => (e.target === e.currentTarget) && closeModal()}>
        <ModalCard {...props}>
          <PullRight className="button-close">
            <BtnClose onClick={closeModal}/>
          </PullRight>
          {children}
        </ModalCard>
      </VerticalCenter>
    </FocusTrap>
  )
};

const BtnClose = styled.img.attrs({
  src: imgClose,
  width: 15,
  height: 15,
})`
  margin: -20px -20px 0 0;
  cursor: pointer;
`;

const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100% !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
`;

const ModalCard = styled(Card)`
  position: relative;
  margin: auto;
  z-index: 1100;
`;
