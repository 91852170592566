import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { requestClientLogin } from 'modules/user/actions';

import { requestUser } from 'modules/clients/actions';

import { PathNavLink } from 'components/Common/PathNavLink.jsx';
import { Row as BaseRow, Column as BaseColumn } from 'components/Styled/Layout';
import Card from 'components/Styled/Card';
import { ButtonPlain } from 'components/Styled/Button';
import BackButton from 'components/Common/BackButton';
import { getQueryVariables } from 'util/index';
import { clientAppUrl } from 'modules';
import { isIOS } from 'util/getDevice';


import { PageLoader } from 'components/Loader';
import imgWand from 'img/button/wand-dark.png';
import imgClient from 'img/button/man-blue.png';
import DetailsView from './DetailsView';
import VideoProposalView from './VideoProposalView';
import MiniProposalView from './MiniProposalView';
import ClientProfile from './ClientProfile';
import { LoansView } from './LoansView';
import SupportingDocs from './SupportingDocs';
import ReferralProfileView from './ReferralProfileView';
import ReferralView from './ReferralView';


export const Column = styled(BaseColumn)`
  padding: 0 40px 24px 0;
`;

export const SubColumns = styled(BaseColumn)`
  padding: 0 0 24px 0;
`;

export const SecondaryColumn = styled(Column)`
  padding: 0 40px 0 0;
`;

export const Heading = styled.h2`
  font-family: Lato;
  font-size: 18px;
  color: #101922;
  word-spacing: 4px;
  margin-bottom: 20px;
`;

export const Row = styled(BaseRow)`
  margin-right: -40px;
`;
export const ImgWand = styled.img.attrs({
  src: imgWand,
})`
  position: relative;
  width: 16px;
  bottom: -2px;
  margin-right: 10px;
`;

const ImgClient = styled.img.attrs({
  src: imgClient,
})`
  position: relative;
  width: 16px;
  bottom: -2px;
  margin-right: 6px;
`;


class ViewClient extends Component {
  constructor() {
    super();
    this.state = {
      userId: undefined,
      loaded: false,
    };
  }

  getClient = (id) => {
    const { applications } = this.props;
    if (applications) {
      this.props.requestUser({ query: id });
      this.setState({ userId: id });
    } else {
      setTimeout(() => {
        this.getClient(id);
      }, 500);
    }
  }

  componentDidMount() {
    const { id } = getQueryVariables(this.props.location.search);
    const { client, clientLoading, applications } = this.props;
    //debugger;
    if (!client || client.id !== parseInt(id) || ( clientLoading && clientLoading !== parseInt(id))) {
      this.getClient(id);
    } else {
      this.setState({ userId: id });
    }
    // if (client && client.id === parseInt(id)) {
    //   // used to change state
    // } else {

    //   this.props.requestUser({
    //     query: id, callback: () => {
    //       this.setState({ loaded: true });
    //     }
    //   });
    // }
  }

  logInAsClient() {
    const { client } = this.props;
    if (!client) return;
    let siteDomain = clientAppUrl;

    let windowReference;
    
    if (isIOS) {
      windowReference = window.open('', '_blank');
    }
    
    this.props.requestClientLogin({
      data: {
        email: client.email,
        phoneNumber: client.phoneNumber,
        userId: client.id,
      },
      callback: (result) => {
        const url = `${siteDomain}?email=${client.email}&loginToken=${result.token}&godmode=${result.godmode}&id=${client.id}`;
        if (isIOS) {
          windowReference.location = url;
        } else {
          window.open(url, '_blank');
        }
      }
    });
  }

  render() {
    const { userId } = this.state;
    const { clientLoading, applications } = this.props;

    const userIdInt = parseInt(userId);
    const referrerApplications = Object.values(this.props.applications ?? {})
      .filter(x => x.referrerId === userIdInt);


    if (clientLoading || !applications || !userId) return (
      <main  className="main client-view">
        <PageLoader />
      </main>
    );

    return (
      <main className='main client-view' style={{ minWidth: '950px'}}>
        <BaseRow>
          <BaseColumn width='100%'>
            <BaseColumn width='200px'>
              <BackButton margin='15px 0 0 0' onClick={() => this.props.history.goBack()} />
            </BaseColumn>
            <BaseColumn width='200px' style={{ float: 'right' }}>
              <ButtonPlain onClick={() => this.logInAsClient()} style={{ marginBottom: '20px', float: 'right' }}>
                <ImgClient />
                Login as Client
              </ButtonPlain>
            </BaseColumn>
          </BaseColumn>


        </BaseRow>

        <Card minWidth='800px' padding='0' minHeight='0px'>
          <TabBar>
            <Tab as={PathNavLink} activeClassName='current' to={`/clients/view?id=${userId}`}>
              Client Details
            </Tab>
            {!!this.props.client.isProfessionalReferrer && !!referrerApplications.length &&
              <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/referrals?id=${userId}`}>
                Referrals
              </Tab>
            }
            {!!this.props.client.isProfessionalReferrer &&
              <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/referralprofile?id=${userId}`}>
                Referral Profile
              </Tab>
            }
            <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/videoproposal?id=${userId}`}>
              Video Proposal
            </Tab>
            <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/clientprofile/propertydetails?id=${userId}`}>
              Client Profile
            </Tab>
            <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/proposal?id=${userId}`}>
              Proposal
            </Tab>
            <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/docs?id=${userId}`}>
              Supporting Docs
            </Tab>
          </TabBar>
        </Card>

        <Routes />

      </main >

    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestUser,
      requestClientLogin,
    },
    dispatch
  );

const mapStateToProps = state => ({
  client: state.clients.current,
  clientLoading: state.clients.userLoading,
  applications: state.applications.list,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewClient);

const ClientDetails = props => {
  const client = useSelector(state => state.clients?.current);
  return <DetailsView {...props} client={client}/>
};

const FilesView = () => {
  const client = useSelector(state => state.clients.current);
  return <>
    <SupportingDocs client={client}/>
  </>;
};


const Routes = () => (
  <Switch>
    <Route exact path="/clients/view" component={ClientDetails} />
    <Route exact path="/clients/view/loans" component={LoansView} />
    <Route exact path="/clients/view/factfind" component={FactFindView} />
    <Route exact path="/clients/view/videoproposal" component={VideoProposalView} />
    <Route path="/clients/view/clientprofile" component={ClientProfile} />
    <Route path="/clients/view/proposal" component={MiniProposalView} />
    <Route path="/clients/view/docs" component={FilesView} />
    <Route path="/clients/view/referralprofile" component={ReferralProfileView} />
    <Route path="/clients/view/referrals" component={ReferralView} />
  </Switch>
);


const FactFindView = () => (
  <Card margin="40px 0 0 0" >
    <div style={{ width: '720px', margin: 'auto' }}>
      <h1>Fact Find Placeholder</h1>
    </div>
  </Card>
);

const TabBar = styled.div`
  height: 60px;
  width: 100%;
  margin-bottom: 0px;
`;

const Tab = styled.div`
  width: 125px;
  height: 37px;
  display: inline-block;
  margin: 23px 0 0 40px;
  text-align: center;
  font-size: 14px;
  color: #727C8F;

  &.current {
    color: #101922;
    border-bottom: solid 2px #2291FF;
  }

  &:hover {
    border-bottom: solid 2px #2291FF;
  }
`;