import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Debouncer } from 'util/index';
import { requestPatchSelf } from 'modules/user/actions';
import { connect } from 'react-redux';

class DragToScroll extends Component {
  constructor() {
    super();
    this.state = {
      xPos: 0,
      yPos: 0,
      isDragging: false,
    };
    this.debouncer = new Debouncer();
  }

  componentDidMount() {
    document.body.addEventListener('mousedown', this.mouseDown);
    document.body.addEventListener('mouseup', this.mouseUp);
    this.div.addEventListener('mousemove', this.mouseMove);
    const scrollX = this.props.user.meta[this.props.page + 'ScrollX'] ?? 0;
    const scrollY = this.props.user.meta[this.props.page + 'ScrollY'] ?? 0;
    if (scrollX || scrollY) {
      this.div.scrollTo(scrollX, scrollY);
      this.xPos = scrollX;
      this.yPos = scrollY;
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.mouseDown);
    document.body.removeEventListener('mouseup', this.mouseUp);
    this.div.removeEventListener('mousemove', this.mouseMove);
  }

  onScroll = e => {
    const scrollLeft = e.target.scrollLeft;
    const scrollTop = e.target.scrollTop;
    this.debouncer.run(() => {
      console.log('Scrolling:', scrollLeft, scrollTop );
      this.props.requestPatchSelf({
        data: {
          meta: {
            [this.props.page + 'ScrollX']: scrollLeft,
            [this.props.page + 'ScrollY']: scrollTop,
          }
        }
      });
    }, 500);
  }

  mouseDown = e => {
    if (!e.target) return;
    
    if (this.props.exclude && e.target.matches(this.props.exclude)) return;

    if (e.target.matches(['.drag-scroll', '.drag-scroll *'])) {
      e.preventDefault();
      this.xPos = e.pageX;
      this.yPos = e.pageY;
      this.isDragging = true;
      document.body.style.cursor = 'move';
    }
    
  }

  mouseUp = e => {
    this.isDragging = false;
    document.body.style.cursor = 'default';
  }

  mouseMove = e => {
    if (!this.isDragging) return;
    const { horizontal, vertical } = this.props;
    const oldX = this.div.scrollLeft;
    const newX = oldX + (this.xPos - e.pageX);
    const oldY = this.div.scrollTop;
    const newY = oldY + (this.yPos - e.pageY);

    this.div.scrollTo(horizontal ? newX : oldX, vertical ? newY : oldY);
    this.xPos = e.pageX;
    this.yPos = e.pageY;
  }



  render() {
    const {children, exclude, ...props} = this.props;
    return (
      <div 
        ref={element => this.div = element} 
        className='drag-scroll'
        onScroll={this.onScroll}
        {...props}
      >
        {children}
      </div>
    );
  }
}

const stateToProps = state => ({ user: state.user });

const dispatchToProps = dispatch => 
  bindActionCreators(
    { requestPatchSelf },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(DragToScroll);