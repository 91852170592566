import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { reduxForm } from 'redux-form';

import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';

import { Input, Select, AddressInput } from 'components/Form';
import { Column as BaseColumn } from 'components/Styled/Layout';
import InputGroup from 'components/Common/InputGroup';
import { StandardCheckBoxField as CheckBoxField, CheckBox } from 'components/Styled/CheckBox';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { Row, Column } from '../ViewClient';


import { required } from 'util/validation';

import { showUnfinished } from 'modules';

const phoneOptions = [
  { name: 'Mobile', value: 'Mobile' },
  { name: 'Landline', value: 'Landline' }
];

const stageOptions = [
  {name: 'Registered', value: 'registered'},
  {name: 'Applied', value: 'applied'},
  {name: 'Settled', value: 'settled'},
  {name: 'Eligibility', value: 'eligibility'}
];

const CancelButton = styled(ButtonPlain)`
 display: inline-block;
`;

const SubmitButton = styled(ButtonPrimary)`
 float: right;
 margin-right: 40px;
`;

class AddClientForm extends Component {
  state = {
    generatePassword: true,
  }

  generatePassword = length => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    const charsLength = chars.length;
    let password = '';
    for (let i = 0; i < length; i++) {
      const rnd = Math.floor(Math.random() * charsLength);
      password += chars[rnd];
    }
    return password;
  }

  componentWillMount() {
    this.props.change('password', this.generatePassword(20));
  }

  generatePasswordChanged = e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      this.props.change('password', this.generatePassword(20));
    } else {
      this.props.change('password', '');
    }
    this.setState({generatePassword: isChecked});

  }


  render() {
    const { handleSubmit, pristine, submitting, reset, invalid } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <input hidden autoComplete="off" />
        <Row>
          <Column width='50%'>
            <Label>First Name</Label>
            <Input 
              name='firstName'
              validate={required}
            />
          </Column>

          <Column width='50%'>
            <Label>Last Name</Label>
            <Input name='lastName' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Primary Phone</Label>
            <InputGroup>
              <Input type='phone' width='65%' name='phoneNumber'/>
              <Select width='35%' options={phoneOptions} name='phoneNumberType'/>
            </InputGroup>
          </Column>

          <Column width='50%'>
            <Label>Secondary Phone</Label>
            <InputGroup>
              <Input type='phone' width='65%' name='secondaryPhoneNumber'/>
              <Select width='35%' options={phoneOptions} name='secondaryPhoneNumberType'/>
            </InputGroup>
          </Column>

          <Column width='50%'>
            <Label>Email</Label>
            <Input name='email' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Address</Label>
            <AddressInput name='address' width='340px'/>
          </Column>

          <Column width='100%'>
            <Divider/>
          </Column>

          <Column width='50%'>
            <Label style={{marginBottom: '11px'}}>Password 
              <div style={{float: 'right'}}>
                <CheckBox 
                  title='Generate Password' 
                  onChange={this.generatePasswordChanged} 
                  checked
                  style={{marginBottom: '0px !important'}}/>
              </div>
            </Label>
            <Input disabled={this.state.generatePassword} name='password' validate={required}/>
          </Column>

          {showUnfinished &&
            <Column width='50%'>
              <Label>Client Stage</Label>
              <Select name='clientStage' width='340px' options={stageOptions}/>
            </Column>
          }

          <Column width='100%'>
            <Divider/>
          </Column>

          <BaseColumn width='100%'>
            <CancelButton width='300px' onClick={() => this.props.history.push('/clients')} width='95px'>Cancel</CancelButton>


            <SubmitButton type='submit' width='300px' className={(invalid || pristine) && 'disabled'} onSubmit={handleSubmit} width='95px'><ButtonSpinner show={submitting}/>Create</SubmitButton>
            <div style={{float: 'right', margin:'10px 20px 0 0'}}>
              <CheckBoxField name='send_client_email' title='Email Client' checked/>
            </div>
            {showUnfinished &&
              <div style={{float: 'right', margin:'10px 20px 0 0'}}>
                <CheckBox title='Skip Onboarding'/>
              </div>
            }
          </BaseColumn>
        </Row>
      </form>
    );
  }
}

AddClientForm = reduxForm({
  form: 'editClient',
  //enableReinitialize: true,
})(AddClientForm);

export default withRouter(AddClientForm);

function rnd(max) {
  return Math.floor(Math.random() * Math.floor(max));
}