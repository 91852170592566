import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading as HeadingBase, Body, Container as ContainerBase, Column } from './Shared';

export default ({ loans = [], settings }) => {

  const hasTrailRebate = loans
    .some(x => !!x.newLoan.trailRebate)

  return (
    <Container>
      <Column width='100%'>
        <Heading>General Terms & Conditions</Heading>
        <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(settings.overview_pdf_conditions)}}/>
      </Column>
      {hasTrailRebate &&
        <Column width='100%' style={{ marginTop: '55px'}}>
          <Heading>Loan Base Trail Rebates</Heading>
          <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(settings.overview_pdf_rebates)}}/>
        </Column>
      }
    </Container>
  );
};


const Heading = styled(HeadingBase)`
  margin-bottom: 33px;
`;

const Container = styled(ContainerBase)`
  padding-right: 0;
  padding-left: 5px;
  padding-top: 10px;

  img {
    width: 330px;
    margin-right: 33px;
  }
`;