import { createAction } from 'redux-actions';

export const REQUEST_FIND_DOCUMENTS = 'REQUEST_FIND_DOCUMENTS';
export const RECEIVE_FIND_DOCUMENTS = 'RECEIVE_FIND_DOCUMENTS';
export const REQUEST_CREATE_DOCUMENT = 'REQUEST_CREATE_DOCUMENT';
export const RECEIVE_CREATE_DOCUMENT = 'RECEIVE_CREATE_DOCUMENT';
export const REQUEST_PATCH_DOCUMENT = 'REQUEST_PATCH_DOCUMENT';
export const RECEIVE_PATCH_DOCUMENT = 'RECEIVE_PATCH_DOCUMENT';
export const REQUEST_DELETE_DOCUMENT = 'REQUEST_DELETE_DOCUMENT';
export const RECEIVE_DELETE_DOCUMENT = 'RECEIVE_DELETE_DOCUMENT';

export const requestFindDocuments = createAction(REQUEST_FIND_DOCUMENTS);
export const receiveFindDocuments = createAction(RECEIVE_FIND_DOCUMENTS);
export const requestCreateDocument= createAction(REQUEST_CREATE_DOCUMENT);
export const receiveCreateDocument = createAction(RECEIVE_CREATE_DOCUMENT);
export const requestPatchDocument = createAction(REQUEST_PATCH_DOCUMENT);
export const receivePatchDocument = createAction(RECEIVE_PATCH_DOCUMENT);
export const requestDeleteDocument = createAction(REQUEST_DELETE_DOCUMENT);
export const receiveDeleteDocument = createAction(RECEIVE_DELETE_DOCUMENT);

export const REQUEST_FIND_DOCUMENT_TEMPLATES = 'REQUEST_FIND_DOCUMENT_TEMPLATES';
export const RECEIVE_FIND_DOCUMENT_TEMPLATES = 'RECEIVE_FIND_DOCUMENT_TEMPLATES';
export const REQUEST_CREATE_DOCUMENT_TEMPLATE = 'REQUEST_CREATE_DOCUMENT_TEMPLATE';
export const RECEIVE_CREATE_DOCUMENT_TEMPLATE = 'RECEIVE_CREATE_DOCUMENT_TEMPLATE';
export const REQUEST_PATCH_DOCUMENT_TEMPLATE = 'REQUEST_PATCH_DOCUMENT_TEMPLATE';
export const RECEIVE_PATCH_DOCUMENT_TEMPLATE = 'RECEIVE_PATCH_DOCUMENT_TEMPLATE';
export const REQUEST_DELETE_DOCUMENT_TEMPLATE = 'REQUEST_DELETE_DOCUMENT_TEMPLATE';
export const RECEIVE_DELETE_DOCUMENT_TEMPLATE = 'RECEIVE_DELETE_DOCUMENT_TEMPLATE';

export const requestFindDocumentTemplates = createAction(REQUEST_FIND_DOCUMENT_TEMPLATES);
export const receiveFindDocumentTemplates = createAction(RECEIVE_FIND_DOCUMENT_TEMPLATES);
export const requestCreateDocumentTemplate = createAction(REQUEST_CREATE_DOCUMENT_TEMPLATE);
export const receiveCreateDocumentTemplate = createAction(RECEIVE_CREATE_DOCUMENT_TEMPLATE);
export const requestPatchDocumentTemplate = createAction(REQUEST_PATCH_DOCUMENT_TEMPLATE);
export const receivePatchDocumentTemplate = createAction(RECEIVE_PATCH_DOCUMENT_TEMPLATE);
export const requestDeleteDocumentTemplate = createAction(REQUEST_DELETE_DOCUMENT_TEMPLATE);
export const receiveDeleteDocumentTemplate = createAction(RECEIVE_DELETE_DOCUMENT_TEMPLATE);

export const ADD_DOC_TO_TEMPLATE = 'ADD_DOC_TO_TEMPLATE';
export const REMOVE_DOC_FROM_TEMPLATE = 'REMOVE_DOC_FROM_TEMPLATE';

export const addDocToTemplate = createAction(ADD_DOC_TO_TEMPLATE);
export const removeDocFromTemplate = createAction(REMOVE_DOC_FROM_TEMPLATE);

export const REQUEST_ADD_USER_DOCUMENT = 'REQUEST_ADD_USER_DOCUMENT';
export const REQUEST_REMOVE_USER_DOCUMENT = 'REQUEST_REMOVE_USER_DOCUMENT';
export const REQUEST_PATCH_USER_DOCUMENT = 'REQUEST_PATCH_USER_DOCUMENT';
export const RECEIVE_ADD_USER_DOCUMENT = 'RECEIVE_ADD_USER_DOCUMENT';
export const RECEIVE_REMOVE_USER_DOCUMENT = 'RECEIVE_REMOVE_USER_DOCUMENT';
export const RECEIVE_PATCH_USER_DOCUMENT = 'RECEIVE_PATCH_USER_DOCUMENT';

export const requestAddUserDocument = createAction(REQUEST_ADD_USER_DOCUMENT);
export const requestRemoveUserDocument = createAction(REQUEST_REMOVE_USER_DOCUMENT);
export const requestPatchUserDocument = createAction(REQUEST_PATCH_USER_DOCUMENT);
export const receiveAddUserDocument = createAction(RECEIVE_ADD_USER_DOCUMENT);
export const receiveRemoveUserDocument = createAction(RECEIVE_REMOVE_USER_DOCUMENT);
export const receivePatchUserDocument = createAction(RECEIVE_PATCH_USER_DOCUMENT);