import React from 'react';
import styled, { css } from 'styled-components';

export const Loader = props => (
  <LoaderContainer {...props}>
    <div className='duo duo1'>
      <div className='dot dot-a'></div>
      <div className='dot dot-b'></div>
    </div>
    <div className='duo duo2'>
      <div className='dot dot-a'></div>
      <div className='dot dot-b'></div>
    </div>
  </LoaderContainer>
);

export const PageLoader = ({show}) => (
  <PageLoaderContainer show={show}>
    <Loader/>
  </PageLoaderContainer>
);

export const LoaderContainer = styled.div`
  position: relative;
  width: 80px;
  margin: 0 auto;

  .duo,
  .dot {
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .duo {
    height: 20px;
    width: 50px;
    background: hsla(0, 0%, 0%, 0.0);
    position: absolute;

    &.duo1 {
      left: 0;
      animation-name: spin;

      .dot-a {
        opacity: 0;
        animation-name: onOff;
        animation-direction: reverse;
      }
    }

    &.duo2 {
      left: 30px;
      animation-name: spin;
      animation-direction: reverse;

      .dot-b {
        animation-name: onOff;
      }
    }
  }

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #2291ff;
    position: absolute;

    &.dot-a {
      left: 0px;
    }

    &.dot-b {
      right: 0px;
    }
  }


  @keyframes spin {
    0% {
      transform: rotate(0deg)
    }

    50% {
      transform: rotate(180deg)
    }

    100% {
      transform: rotate(180deg)
    }
  }

  @keyframes onOff {
    0% {
      opacity: 0;
    }

    49% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
`;

const PageLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
    
  ${LoaderContainer} {
    position: relative;
    top: calc(50% - 30px);
  }
`;