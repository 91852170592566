import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading, Body as BodyBase, Container as ContainerBase} from './Shared';
import html from '../utils/html';
import { imageUrl } from 'modules';

import imgEmail from '../img/elements/email.png';
import imgPhone from '../img/elements/phone.png';
import imgHotline from '../img/elements/hotline.png';

export default ({ user, isFinal, settings }) => {

  const introLetter = user.meta.miniproposal.overview?.introLetter;
  const broker = user.proposalBroker ?? {};

  const heading = introLetter?.heading ??  `Dear ${user.firstName},`;
  
  let body = isFinal ? settings.atp_intro_final : settings.atp_intro;
  body = body.replace(/\{brokermobile\}/g, broker.mobile);
  // const body = html`
  //   Congratulations on deciding to proceed with a mortgage application through Loan Base!
  //   <br/><br/>
  //   I’ve included your indicative lending structure on the next page of this document - please check this and let me know if you have any questions or concerns. Otherwise, you will find a list of required supporting documents we require on page 3.
  //   <br/><br/>
  //   If you have any questions at all, please call me on 0417 001 130. We look forward to getting your application lodged and the process under way.
  //   <br/><br/>
  //   Kind Regards,
  // `;
  const random = Math.random();
  return (
    <Container>
      <Heading dangerouslySetInnerHTML={{ __html: sanitizeHtml(heading)}}/>
      <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body)}} />
      <Signature crossOrigin='anonymous' src={`${imageUrl}/brokers/signature${broker.id}.png?n=` + random} alt={'signature'} />
      <PortraitContainer>
        <Portrait crossOrigin='anonymous' src={`${imageUrl}/brokers/small${broker.id}.png`} alt={broker.name} />
      </PortraitContainer>
      <BrokerInfo>
        <BrokerName>{broker.name}</BrokerName>
        <BrokerTitle>{broker.title}</BrokerTitle>
        <Info><img src={imgEmail}/>{broker.email}</Info>
        <Info><img src={imgPhone}/>{broker.mobile}</Info>
        <Info><img src={imgHotline}/>{broker.landline}</Info>
      </BrokerInfo>
    </Container>
  );
  }

const Body = styled(BodyBase)`

  ul, ol {
    margin-top: 0;
    li {
      margin-bottom: 0;
    }
  }
`;

const Info = styled.div`
  margin-bottom: 7px;

  img {
    width: 13px;
    margin-right: 10px;
  }
`;

const PortraitContainer = styled.div`
  display: inline-block;
  padding: 5px 20px 5px 0;
  border-right: solid 3px #2291FF;
`;

const Container = styled(ContainerBase)`
  padding-right: 70px;
  background-color: #F7F7F7;
`;

const Signature = styled.img`
  display: block;
  height: 70px;
  margin: 20px 10px 20px 10px;
`;

const Portrait = styled.img`
  display: block; 
  object-fit: cover;
  height: 100px;
  min-width: 100px;
  border-radius: 100%;
  background-color: #D2DCEB;
  /* margin-right: 33px; */
`;

const BrokerInfo = styled.div`
  display: inline-block;
  padding-left: 20px;
`;

const BrokerName = styled.div`
  font-size: 22px;
  color: #2689EB;
  font-weight: 700;
  margin-bottom: 7px;
`;

const BrokerTitle = styled.div`
  font-size: 16.65px;
  font-weight: 700;
  margin-bottom: 11px;
`;