import React, { useContext, useState, useEffect } from 'react';
import { Form, reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { RichTextArea } from 'components/Form/RichTextInput';
import formatNumber from 'shared/utils/formatNumber';
import moment from 'moment';
import html from 'shared/utils/html';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { ButtonPrimary as Button } from 'components/Styled/Button';
import DatePicker from 'react-datepicker';
import { requestPatchUser } from 'modules/clients/actions';

import "react-datepicker/dist/react-datepicker.css";

let AdditionalDetails = ({ active, banks = [], client }) => {
  
  let content = useSelector(state => state.form['atp-details'].values?.text);
  let loanbaseCashback = useSelector(state => state.clients.current.meta.miniproposal?.overview?.loanbaseCashback ?? {});
  let lenderCashback = useSelector(state => state.clients.current.meta.miniproposal?.overview?.lenderCashback ?? {});

  const dispatch = useDispatch();

  const { openModal, closeModal } = useContext(modalContext);

  const loanbaseCashbackValue = '$' + formatNumber(loanbaseCashback.rebateAmount);

  let loanbaseCashbackExpiry = '';
  if (loanbaseCashback.hasEndDate && loanbaseCashback.endDate) {
    const expiryDate = moment(loanbaseCashback.endDate);
    loanbaseCashbackExpiry = changeExpiryDate(expiryDate.format('Do MMMM YYYY'), '__openExpiryModal("loanbase");');
  } else {
    loanbaseCashbackExpiry = noExpiryDate('window.__openExpiryModal("loanbase");');
  }

  const lenderCashbackValue = '$' + formatNumber(lenderCashback.rebateAmount);

  let lenderCashbackExpiry = '';
  if (lenderCashback.hasEndDate && lenderCashback.endDate) {
    const expiryDate = moment(lenderCashback.endDate);
    lenderCashbackExpiry = changeExpiryDate(expiryDate.format('Do MMMM YYYY'), 'window.__openExpiryModal("lender");');
  } else {
    lenderCashbackExpiry = noExpiryDate('window.__openExpiryModal("lender");');
  }

  let lenderName = '';
  if (lenderCashback.lender === 'other') {
    lenderName = lenderCashback.lenderName;
  } else {
    const bank = banks.find(x => x.id === lenderCashback.lender);
    if (bank) {
      lenderName = bank.name;
    }
  }

  content = content.replace(/\{loanbasecashbackvalue\}/g, loanbaseCashbackValue);
  content = content.replace(/\{loanbasecashbackexpiry\}/g, loanbaseCashbackExpiry);
  content = content.replace(/\{lendercashbackvalue\}/g, lenderCashbackValue);
  content = content.replace(/\{lendercashbackexpiry\}/g, lenderCashbackExpiry);
  content = content.replace(/\{lendername\}/g, lenderName);

  const saveDate =  (date, location) => {
    const patch = {
      meta: { miniproposal: { overview: {
        [location]: {
          endDate: date,
          hasEndDate: true,
        }
      }}}};

    dispatch(requestPatchUser({
      data: {
        id: client.id,
        ...patch
      },
      params: {
        mergeMeta: true,
      }
    }));
  };

  const submitExpiryDate = (type, date) => {
    console.log(type, date);
    saveDate(date, type === 'lender' ? 'lenderCashback' : 'loanbaseCashback');
    closeModal();
  };

  const modalOptions = type => ({
    component: ExpiryModal,
    props: {
      onSubmit: date => submitExpiryDate(type, date),
      expiryDate: (type === 'lender' ? lenderCashback?.endDate : loanbaseCashback?.endDate) ?? undefined,
    }
  });

  useEffect(() => {
    // need to set openModal as a global variable so it's accessable from plain html onclick
    window.__openExpiryModal = type => openModal(modalOptions(type));
    return () => {
      window.__openExpiryModal = undefined;
    };
  }, []);
  

  return (
    <Form>
      <Heading>Additional Details</Heading>
      {active ?
        <Paragraph as={RichTextArea} disabled={false} name='text'/>
        :
        <Paragraph dangerouslySetInnerHTML={{ __html: content }}/>
      }
      
    </Form>
  );
};

AdditionalDetails = reduxForm({ form: 'atp-details' })(AdditionalDetails);

export default AdditionalDetails;

const noExpiryDate = onclick => html`<span style='text-decoration: underline; color: red; cursor: pointer' onclick=${onclick}>the expiry date</span>`;

const changeExpiryDate = (date, onclick) => html`<span style='text-decoration: underline; cursor: pointer' onclick=${onclick}>${date}</span>`;

const ModalRow = styled.div``;

export const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

let ExpiryModal = ({ onSubmit, expiryDate }) => {
  const [endDate, setEndDate] = useState(expiryDate ? new Date(expiryDate) : Date.now());
  return (
    <ModalBox width='540px' padding='60px 80px'>

      <ModalRow>
        <SmallHeading>Expiry Date</SmallHeading>
      </ModalRow>
      <ModalRow style={{margin:'0 0 20px 0'}}>
        <DatepickerContainer>
          <DatePicker dateFormat='dd/MM/yyyy' selected={endDate} onChange={date => setEndDate(date)}/>
        </DatepickerContainer>
      </ModalRow>
      <ModalRow>
        <Button type='submit' width='100%' onClick={() => onSubmit(endDate)}>Submit</Button>
      </ModalRow>

    </ModalBox>
  );
}; 

const DatepickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
      input {
        vertical-align: top;
        color: #101922;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0; 
        height: ${p => p.height || '40px'};
        border: 1px solid #d8dce7;
        border-radius: 4px;
        /* box-shadow: 0px 1px 0px 0px #e9ebf1; */
        padding: 0 12px 0 12px;
        transition: all 300ms;

        &:focus {
          border: 1px solid #2291FF !important;
          box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
        }

        &.error {
          border: 1px solid rgba(255,0,0,0.5) !important;
          box-shadow: inset 0 0 0 2px rgba(255,0,0,0.2) !important;
        }

        &[readonly] {
          cursor: default;
        }

        &:disabled {
          background-color: #d8dce75e !important;
          color: #545454;
          // background-image: none;
          cursor: no-drop;
        }
      }
  }
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  color: #575e6c;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
`;

const Paragraph = styled.div`
  color: #575e6c;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    margin-top: 20px;

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
      margin-bottom: 15px;
    }
  }

  ol {
    margin-top: 20px;

    li {
      list-style: decimal inside !important;
      margin-left: 10px;
      margin-bottom: 15px;
    }
  }

  &.active {
    border: solid 1px #A3A8B2;
    border-radius: 3px;
  }

  ${p => p.disabled && css`
    border: none !important;
    padding: 0 !important;
  `}
`;