// src/views/routes.js

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Applications from './Applications';
import ApplicationView from './Applications/ApplicationView';

import Clients from './Clients';
import AddClient from './Clients/AddClient';
import ViewClient from './Clients/ViewClient.jsx';
import ATP from './Clients/ATP';

import Eligibility from './Eligibility';
import EligibilityView from './Eligibility/EligibilityView';
import Archive from './Eligibility/Archive';

import Lenders from './Lenders/LenderSearch';
import AddLender from './Lenders/AddLender.jsx';
import EditLender from './Lenders/EditLender';

import NewsFeed, {
  ShowAllApplications,
  ShowAllEligibility,
  ShowAllProducts,
  ShowAllReviews
} from './NewsFeed';

import Products from './Products';
import ProductsView from './Products/ProductEdit';

import Testing from '../components/Testing/index';
import Settings from './Settings/Settings';

import Team from './Team/Team';
import TeamAdd from './Team/Add';
import TeamEdit from './Team/Edit';

const QualifierPage = props => <Eligibility {...props} page='qualifiers'/>;
const ReferralPage = props => <Eligibility {...props} page='referrals'/>;
const DealPage = props => <Eligibility {...props} page='deals'/>;
const NewLeadsPage = props => <Eligibility {...props} page='new-leads'/>;
const InProgressPage = props => <Eligibility {...props} page='in-progress'/>;

const EligibilityV = props => <EligibilityView {...props} page='eligibility'/>;
const QualifierView = props => <EligibilityView {...props} page='qualifiers'/>;
const ReferralView = props => <EligibilityView {...props} page='referrals'/>;
const DealView = props => <EligibilityView {...props} page='deals'/>;
const NewLeadsView = props => <EligibilityView {...props} page='new-leads'/>;
const InProgressView = props => <EligibilityView {...props} page='in-progress'/>;

const QualifierArchive = props => <Archive {...props} page='qualifiers'/>;
const ReferralArchive = props => <Archive {...props} page='referrals'/>;
const DealArchive = props => <Archive {...props} page='deals'/>;
const NewLeadsArchive = props => <Archive {...props} page='new-leads'/>;
const InProgessArchive = props => <Archive {...props} page='in-progress'/>;

const Routes = () => (
  <Switch>
    <Redirect exact from='/' to='/deals' />
    <Redirect exact from='/login' to='/deals' />
    
    <Route exact path='/newsfeed' component={NewsFeed}/>
    <Route exact path='/newsfeed/products' component={ShowAllProducts}/>
    <Route exact path='/newsfeed/eligibility' component={ShowAllEligibility}/>
    <Route exact path='/newsfeed/reviews' component={ShowAllReviews}/>
    <Route exact path='/newsfeed/applications' component={ShowAllApplications}/>

    <Route exact path='/applications' component={Applications}/>
    <Route path='/applications/view' component={ApplicationView}/>

    <Route exact path='/clients' component={Clients}/>
    <Route exact path='/clients/add' component={AddClient}/>
    <Route path='/clients/view/atp' component={ATP}/>
    <Route path='/clients/view' component={ViewClient}/>
    
  
    <Route exact path='/qualifiers' component={QualifierPage}/>
    <Route exact path='/referrals' component={ReferralPage}/>
    <Route exact path='/deals' component={DealPage}/>
    <Route exact path='/new-leads' component={NewLeadsPage}/>
    <Route exact path='/in-progress' component={InProgressPage}/>
    <Route path='/eligibility/view' component={EligibilityV}/>
    <Route path='/qualifiers/view' component={QualifierView}/>
    <Route path='/referrals/view' component={ReferralView}/>
    <Route path='/deals/view' component={DealView}/>
    <Route path='/new-leads/view' component={NewLeadsView}/>
    <Route path='/in-progress/view' component={InProgressView}/>
    <Route exact path='/qualifiers/archive' component={QualifierArchive}/>
    <Route exact path='/referrals/archive' component={ReferralArchive}/>
    <Route exact path='/deals/archive' component={DealArchive}/>
    <Route exact path='/new-leads/archive' component={NewLeadsArchive}/>
    <Route exact path='/in-progress/archive' component={InProgessArchive}/>

    <Route exact path='/lenders' component={Lenders}/>
    <Route exact path='/lenders/add' component={AddLender}/>
    <Route exact path='/lenders/edit' component={EditLender}/>

    <Route exact path='/products' component={Products}/>
    <Route exact path='/products/view' component={ProductsView}/>

    <Route exact path='/settings' component={Settings}/>
    <Route exact path='/team' component={Team}/>
    <Route exact path='/team/add' component={TeamAdd}/>
    <Route exact path='/team/view' component={TeamEdit}/>
    
    <Route exact path='/testing' component={Testing}/> {/* TODO remove this before production */}

    <Route component={()=>(<main><h1>404</h1></main>)} />
  </Switch>
);

export default Routes;