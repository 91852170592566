import React from "react";
import styled, { css } from 'styled-components'
import BankLogoBase from './BankLogo';
import formatNumber from '../utils/formatNumber';

const CurrentLoan = ({ data, isView }) => {
  if (!isView) return null;
  return (
    <CurrentLoanContainer >
      <Header style={{ marginTop: '0'}}>{data.title}</Header>
      <LoanBox hasLender={!!data.lender}>
        {!!data.lender &&
          <Column className="logo">
            <BankLogo 
              width={135}
              height={60}
              maxFill={0.6}
              product={{ bank: data.lender !== 'other' ? data.lender : null, bank_name: data.otherLenderName }}
              alt="bank logo" 
            /> 
          </Column>
        }
        <Column>
          <LoanDetailsValue style={{ fontSize: '22px' }}>${formatNumber(data.monthlyRepayment)}</LoanDetailsValue>
          <LoanDetailsSubtitle>Monthly Repayment</LoanDetailsSubtitle>
        </Column>
        <Column>  
          <LoanDetailsValue style={{ fontSize: '22px' }}>
            {data.interestRate || '???'}
            <span style={{ fontWeight: '400', fontSize: '11px', color: '#727C8F' }}> % p.a.</span>
          </LoanDetailsValue>
          <LoanDetailsSubtitle>{data.rateType}</LoanDetailsSubtitle>
        </Column>
        <Column>
          <LoanDetailsValue style={{fontSize: '12px', fontWeight: '400' }}>{data.repaymentType === 'PandI' ? 'Principal & Interest' : 'Interest Only'}</LoanDetailsValue>
          <LoanDetailsSubtitle>Repayment Type</LoanDetailsSubtitle>
        </Column>
      </LoanBox>
    </CurrentLoanContainer>
  )
};

export default CurrentLoan;

export const Header = styled.h2`
  width: 100%;
  outline: none;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-bottom: ${p => p.marginBottom ?? '10px'} !important;
  ${p => !p.isPDF && css`
    margin-top: 25px;
  `}
  border-color: #FFF;
  transition: border-color 0.4s ease-in-out;

    &.empty {
      border: solid 1px;
      border-radius: 6px;
      border-color: #EB5846;
    }
  }

`;

const BankLogo = styled(BankLogoBase)`
  align-self: center;
  /* margin-bottom: -18px; */
  /* max-height: 50px;
  max-width: 90px;
  object-fit: contain; */
`;

const CurrentLoanContainer = styled.div`
  font-family: LatoPDF, sans-serif;
  position: relative;
  max-width: 100%;
  transition: max-height 0.2s;
  max-height: 1000px;
`;

const LoanBox = styled.div`

  display: block;
  min-width: 185px;
  max-width: 220px;
  margin: 0 auto;

  border: 1px solid #D8DCE7;
  border-radius: 4px;
  position: relative;

  div:first-of-type {
    border-top: none;
  }

  margin-bottom: 20px;
`;

const Column = styled.div`
  border-top: 1px solid #D8DCE7;
  display: block;
  padding: 15px 20px 20px 20px !important;

  &:last-of-type {
    margin-right: 0;
  }

  &.logo {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 0;
    border-top: none;
    height: 70px;

    img {
      display: block;
      margin-bottom: 0 !important;
    }
  }
  
  @media (max-width: 900px) {
    &.logo {
      flex: 0 0 100%;
    }
  }
`;

const LoanDetailsValue = styled.div`
  font-size: 17px;
  font-weight: 700;
  color: #101922;
  text-align: center;
  margin-bottom: 8px;
`;

const LoanDetailsSubtitle = styled.div`
  font-size: 11px;
  color: #727C8F;
  text-align: center;
`;
