// src/views/Applications/index.js
import React, { Component, Fragment } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';



import { MultiSelect } from 'components/Common/MultiSelect';
import Select from 'components/Common/Select';

import Label from 'components/Styled/Label';
import { StageLists } from 'components/ApplicationsEligibility/';

import {
  requestMoveApplication,
  requestGetApplications
} from 'modules/applications/actions';

const mapStateToProps = ({applications}) => ({applications});

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestGetApplications,
    requestMoveApplication
  }, dispatch);

class Applications extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      brokerId: null,
      tags: [],
      searchFilter: this.createSearchFilter([]),
      redirectTo: null,
    };
  }
  
  tagOptions = [
    {
      name: 'Today',
      value: 'today',
    },
    {
      name: 'Overdue',
      value: 'overdue',
    },
    {
      name: 'Tag 1',
      value: 'tag1',
    },
    {
      name: 'Tag 2',
      value: 'tag2',
    },
  ]

  redirect = (to) => this.setState({ redirectTo: to });

  componentWillMount() {
    // this.props.requestGetApplications(() =>
    //   this.setState({isLoaded: true})
    // );
  }

  render() {
    const { searchFilter, brokerId, redirectTo } = this.state;
    const { applications } = this.props;

    if (redirectTo) {
      this.props.history.push('/applications');
      return <Redirect to={redirectTo}/>;
    }

    return (
      <main className="main applications" >
        <div className="main-container">
          <div style={{paddingLeft: '40px'}}>
            <div style={{ marginBottom: '40px' }}>
              <div style={{display: 'inline-block'}}>
                <label className='label'>Filter by Tags</label>
                <MultiSelect 
                  width='350px' 
                  options={this.tagOptions} 
                  placeholder='No tags selected'
                  onChange={tags => this.setState({ 
                    searchFilter: this.createSearchFilter(tags, brokerId), 
                    tags })}/>
              </div>
              <div style={{display: 'inline-block', marginLeft: '20px'}}>
                <Label>Assigned Broker</Label>
                <Select
                  width='187px' 
                  options={[{name: 'All', value: null}, ...this.getBrokers()]} 
                  onChange={id => this.setState({ 
                    searchFilter: this.createSearchFilter(this.state.tags, id), 
                    brokerId: id 
                  })}/>
              </div>
            </div>
          </div>   
          
          <StageLists searchFilter={searchFilter} url='/applications'/>
          
        </div>
      </main>
    );
  }

  createSearchFilter = (tags, brokerId) => {
    const predicates = tags.map(tag => {
      switch(tag) {
      case 'today':
        return it => it.daysDue === 0;
      case 'overdue':
        return it => it.daysDue < 0;
      default:
        return it => it.tags.some(t => t === tag);
      }
    });

    const brokerFilter = brokerId ? (id) => id === brokerId : () => true; 
    //orEach will string the predicates together with ||
    return apps => apps
      .map((app, index) => ({...app, index}))
      .filter(app => orEach(app, predicates) && brokerFilter((app.broker)?app.broker.id:-1));
  }
  
  getBrokers() {
    let brokerMap = new Map();
    (this.props.applications.list || []).forEach(app => 
      app.broker && brokerMap.set( app.brokerId, app.broker.fullName )
    );
    let brokers = Array.from(brokerMap, ([value, name]) => ({ value, name }));
    if (brokers.length > 0) brokers.sort((a, b) => sortAlphabeticly(a.name, b.name));
    return brokers;
  }
}

const sortAlphabeticly = (_a, _b) => {
  const a = _a && _a.toLowerCase();
  const b = _b && _b.toLowerCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const orEach = (value, predicates) => {
  if (predicates.length === 0) return true;
  for (let i in predicates) {
    if (predicates[i](value)) return true;
  }
  return false;
};

export default connect(mapStateToProps, mapDispatchToProps) (Applications);

