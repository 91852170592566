import React, { Component } from 'react';

import { formValueSelector, reduxForm, FieldArray } from 'redux-form';
import styled from 'styled-components';
import { ButtonPlain } from 'components/Styled/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonImageSquare } from 'components/Styled/Button';
import Label from 'components/Styled/Label';

import { Input, Select, TextArea, RadioBox, MoneyInput, PercentInput, NumberInput } from 'components/Form';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column, SecondaryColumn, SubColumns } from 'views/Clients/ViewClient';
import pen from 'img/button/edit-pen-2.png';
import deleteBin from 'img/button/trash-red.png';

import { requestDeleteLoan, requestPatchUser } from 'modules/clients/actions';
import { requestGetLenders } from 'modules/lenders/actions'

import statusError from 'img/elements/excl-circle.png';
import statusComplete from 'img/elements/tick-circle.png';
import { Column as BaseColumn } from 'components/Styled/Layout';

import Debouncer from 'util/debouncer';
import ErrorDot from '../Tabs/components/ErrorDot';
import BankLogo from 'components/Common/BankLogo';
import HiddenPenEditField from 'views/Clients/component/HiddenPenEditField';


function mapStateToProps(state, props) {
  const selector = formValueSelector(props.form)
  const id = selector(state, 'id');
  return {
    id,
    displayOpen: (state.clients.current.loans.find(l => l.id === id) || {}).displayOpen,
    userId: selector(state, 'userId'),
    propertyPurpose: selector(state, 'propertyPurpose'),
    lenderId: selector(state, 'lenderId'),
    interestRate: selector(state, 'interestRate'),
    name: selector(state, 'name'),
    loanType: selector(state, 'loanType'),
    repaymentType: selector(state, 'repaymentType'),
    lenders: (state.lenders || {}).list,
    client: state.clients.current,
    lenderName: selector(state, 'lenderName'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestDeleteLoan,
    requestPatchUser,
    requestGetLenders,
  }, dispatch);
}

const remainingLoanTermOptions = [
  { name: 'Years', value: 'Years' },
  { name: 'Months', value: 'Months' },
  { name: 'Weeks', value: 'Weeks' },
  { name: 'Days', value: 'Days' },
];

const repaymentOptions = [
  { name: 'Monthly', value: 'Monthly' },
  { name: 'Fortnightly', value: 'Fortnightly' },
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Annually', value: 'Annually' },
];

const debtTypeOptions = [
  { name: 'Please Select', value: ''},
  { name: 'Personal Loan', value: 'Personal Loan'},
  { name: 'Vehicle Loan', value: 'Vehicle Loan'},
  { name: 'Credit Card', value: 'Credit Card'},
  { name: 'HECS-HELP', value: 'HECS-HELP'},
  { name: 'Margin Loan', value: 'Margin Loan'},
  { name: 'Other', value: 'Other'},
]


class AddDebt extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      displayOpen: true, 
      displayDelete: true,
      lendersLoaded: false,
      lastSubmit: {
        ...props.initialValues,
        remainingTermUnit: undefined,
        repaymentFrequency: undefined,
      },
    };
  }

  debouncer = new Debouncer();

  toggleDisplayOpen(e) {
    this.props.requestPatchUser({
      data: { loan: { id: this.props.id, displayOpen: !this.props.displayOpen }, id: this.props.userId },
    });
  }

  toggleDelete(e) {
    this.setState(state => ({ displayDelete: !state.displayDelete }));
  }

  deleteRestoreDebt(willDelete = true) {

    this.props.requestPatchUser({
      data: { 
        loan: {
          id: this.props.loan.id,
          deleting: true,
        }, 
        id: this.props.client.id 
      },
      callback: result => {}
    });
    setTimeout(() => {
      this.props.requestPatchUser({
        data: { 
          loan: {
            id: this.props.loan.id,
            deleting: false,
            isDeleted: willDelete,
            displayOpen: false,
          }, 
          id: this.props.client.id 
        },
        callback: result => {}
      });
    }, 1000);
    
  }

  onFormChange() {
    setTimeout(
      this.props.handleSubmit(values => this.onSubmit(values))
    );
  }

  filterUnchangedValues = values => {
    const oldValues = this.state.lastSubmit;
    let result = Object.keys(values).reduce((obj, key) => {
      if (values[key] !== oldValues[key]) return {...obj, [key]: values[key]}
      return obj;
    }, {});
    result.id = values.id;
    return result;
  }

  onSubmit = async _data => {
    delete _data.displayOpen;
    
    const loan = this.filterUnchangedValues(_data);

    let data = {
      loan,
    }
    
    this.debouncer.run(() => {
        this.setState({ lastSubmit: _data });
        this.props.requestPatchUser({
          data: { ...data, id: this.props.client.id },
          callback: result => {}
        });

    }, 2000);
  }

  render() {
    if (!this.props.lenders) return null;

    const { warning, lenders, lenderId } = this.props;

    let lenderOptions = (lenders || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name }) => ({ name, value: id }));

      lenderOptions = [ 
        {name: 'Please Select', value: ''}, 
        {name: 'Other', value: 'OTHER'}, 
        ...lenderOptions 
      ];

      const lender = (lenders || []).find(l => l.id === lenderId);
    return (
      <form onChange={() => this.onFormChange()}
        style={{
          height: this.props.displayOpen ? "auto" : "40px"
        }}
      >

        <Row>
          <ButtonImageSquare 
          type='button'
          style={{
            float: 'left',
            margin: '0 20px 24px 0',
            backgroundImage: this.props.displayOpen ? `url(${require('../../../img/button/up.png')})` : `url(${require('../../../img/button/down.png')})`
          }} onClick={(e) => this.toggleDisplayOpen(e)}></ButtonImageSquare>
          <Column width={(this.props.displayOpen ? 465 : 415) + (this.props.loan.isDeleted ? 200 : 0) + 'px'} style={{ paddingRight: "20px" }}>
            <RenameContainer>
              <HiddenPenEditField name={this.props.name} keyID={this.props.loan.id} />
            </RenameContainer>
          </Column>
          <LenderContainer width='150px'>
            {!!lender ?
              <BankLogo product={{ bank: lender.id, bank_name: lender.name}}/>
            :
              <LenderName>{this.props.lenderName}</LenderName>
            }
            
          </LenderContainer>
          <StatusIndicator className={warning ? 'error' : 'complete'} style={{ paddingRight: "10px", display: this.props.displayOpen ? "none" : "inline-block" }} />
          {!this.props.loan.isDeleted ?
            <DeleteButton type='button' onClick={(e) => this.toggleDelete(e)}>
              <div className="confirm-delete" onClick={() => this.deleteRestoreDebt()} style={{ display: this.state.displayDelete ? "none" : "block" }}>
                Click here to delete
              </div>
            </DeleteButton>
          :
            <UndeleteButton type='button' onClick={(e) => this.toggleDelete(e)}>
              ♻
              <div className="confirm-restore" onClick={() => this.deleteRestoreDebt(false)} style={{ display: this.state.displayDelete ? "none" : "block" }}>
                Click here to restore
              </div>
            </UndeleteButton>
          }
        </Row>

        <div style={{
          display: this.props.displayOpen ? "block" : "none"
        }}>
          <Row>
            <SubColumns width='50%'>
              <SecondaryColumn width='50%' >
                <Label>Balance<ErrorDot name='balance'/></Label>
                <MoneyInput name='balance'  />
              </SecondaryColumn>
              <SecondaryColumn width='50%'>
                <Label>Interest Rate</Label>
                <PercentInput name='interestRate'  />
              </SecondaryColumn>
            </SubColumns>
            <Column width='50%'>
              <Label>Repayment</Label>
              <InputGroup>
                <MoneyInput width='60%' name='repayments' />
                <Select width='40%' options={repaymentOptions} name='repaymentFrequency' onChange={() => this.onFormChange()}/>
              </InputGroup>
            </Column>
          </Row>

          <Row>
            <SubColumns width='50%'>
              <SecondaryColumn width='50%'>
                <Label>Limit</Label>
                <MoneyInput name='loanLimit'  />
              </SecondaryColumn>
              <SecondaryColumn width='50%'>
                <Label>Term</Label>
                <InputGroup>
                  <NumberInput width='40%' name='remainingTerm' />
                  <Select width='60%' options={remainingLoanTermOptions} name='remainingTermUnit' onChange={() => this.onFormChange()}/>
                </InputGroup>
              </SecondaryColumn>
            </SubColumns>
            <Column width='50%'>
              <Label>Current Lender</Label>
              <Select name='lenderId' options={lenderOptions} onChange={() => this.onFormChange()} />
            </Column>
          </Row>

          <Row>
            <Column width='50%'>
              <Label>Notes</Label>
              <TextArea height='150px' name='notes' />
            </Column>
            <Column width='50%'> 

              <SecondaryColumn style={{ marginBottom: '20px', paddingRight: '0' }}>
                <Label>Debt Type</Label>
                <Select name='productType'  options={debtTypeOptions} onChange={() => this.onFormChange()}/>
              </SecondaryColumn>

              
              {lenderId === 'OTHER' && 
              <SecondaryColumn style={{ paddingRight: '0' }}>
                <Label>Lender Name</Label>
                <Input name='lenderName'  />
              </SecondaryColumn>
            }
            </Column>
            
            
          </Row>
          <Row>
            {validationMessage(!warning)}
          </Row>
        </div>
      </form>
    );
  }
}

const LoanSplit = styled.div`
      display: inline-block;
      vertical-align:top;
      margin-top: 8px;
    
  .value {
          display: inline-block;
        vertical-align:top;
        font-size: 14px;
        text-align: center;
        margin-right: 10px;
    
    &:last-of-type {
          margin-right: 0;
      }
  
    span {
          display: block;
        font-size: 12px;
        color: #727C8F;
      }
    }
  `;

const HiddenInput = styled(Input)`
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0 50px 0 0;
  text-overflow: ellipsis;
  background-image: url(${pen});
  background-position: Calc(100% - 15px) 50%;
  background-size: 17px 17px;
  background-repeat: no-repeat;
  
  &:focus {
        background-image: none;
        font-size: 14px;
        font-weight: 400;
        padding: 0 12px 0 12px;
      }
`;

const AutoRename = styled(ButtonPlain)`
    display: none;
    font-size: 11px;
    height: 18px;
    line-height: 16px;
`;

const LenderContainer = styled(BaseColumn)`
  padding: 0 40px 24px 0;
  text-align: right;
  padding-right: 20px;

  img {
    object-fit: contain;
    height: 40px;
    max-width: 100px;
  }
`;
    
const LenderName = styled.div`
  display: inline-block;
  line-height: 40px;
  font-size: 15px;
  max-width: 140px;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`;

const RenameContainer = styled.div`
      position: relative;
    
  &:hover {
          button {
        display: block;
      }
    }
  `;

const PropertyType = styled.div`
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: top;
    background-size: auto 25px;
    background-position: 0% 50%;
    margin-left: 10px;
    position: relative;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
  
  span {
          font - weight: 600;
        margin-left: 15px;
      }
    `;

const DeleteButton = styled(ButtonImageSquare)`
      float: left;
      margin: 0 20px 24px 0;
      background-size: 14px 14px;
  background-image: url(${deleteBin});
        position: relative;
      
  &:focus {
          border: 1px solid #D8DCE7;
      }
    
  .confirm-delete {
    display: block;
    font-size: 12px;
    color: #ff4e4c;
    position: absolute;
    top: 37px;
    border: 1px solid #d8dce7;
    box-shadow: 0 1px 6px 0 rgba(216,220,231,.65);
    border-radius: 4px 0 4px 4px;
    right: -1px;
    width: 130px;
    padding: 10px 5px;
    background-color: #fff;
    z-index: 100;
    
    &:after {
          position: absolute;
        display: block;
        top: -4px;
        right: 0;
        content: "";
        background-color: #fff;
        width: 38px;
        height: 5px;
      }
    }
  `;

const StatusIndicator = styled.div`
    height: 40px;
    width: 40px;
    display: inline-block;
    vertical-align: top;
    float:left;
    margin-right: 10px;
    background-size: 18px 18px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  
  &.error {
          color: #BF7700;
    background-image: url(${statusError});
      }
    
  &.complete {
          color: #26BD27;
    background-image: url(${statusComplete});
      }
    `;

const UndeleteButton = styled(ButtonImageSquare)`
float: left;
margin: 0 20px 24px 0;
background-size: 14px 14px;
position: relative;
color: #2291FF;
font-size: 20px;

&:focus {
  border: 1px solid #D8DCE7;
}

.confirm-restore {
  display: block;
  font-size: 12px;
  color: #2291FF;
  position: absolute;
  top: 37px;
  border: 1px solid #d8dce7;
  box-shadow: 0 1px 6px 0 rgba(216,220,231,.65);
  border-radius: 4px 0 4px 4px;
  right: -1px;
  width: 130px;
  padding: 10px 5px;
  background-color: #fff;
  z-index: 100;

  &:after {
    position: absolute;
    display: block;
    top: -4px;
    right: 0;
    content: "";
    background-color: #fff;
    width: 38px;
    height: 5px;
  }
}
`;

function validationMessage(valid) {
  if (valid) {
    return <div className="validation-message complete">All mandatory items are complete.</div>;
  } else {
    return <div className="validation-message error">Mandatory items incomplete</div>;
  }
}

function propertyPurposeIcon(type) {
  switch (type) {
    case "Owner Occupied": return "owner-occ";
    case "Investment": return "investment";
    case "Holiday": return "holiday";
    case "Vacant": return "vacant";
    default: return "";
  }
}

const formValidation = values => {
  let errors = { _warning: false, balance: false };

    let { balance } = values;

    if (!balance) {
      errors = {...errors, balance: true, _warning: true };
    }


    return errors;
} 

AddDebt = reduxForm({
  warn: formValidation,
})(AddDebt);

export default connect(mapStateToProps, mapDispatchToProps)(AddDebt);