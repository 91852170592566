// src/modules/users/actions.js
import { createAction } from 'redux-actions';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const RECEIVE_AUTH = 'RECEIVE_AUTH';
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_CLIENT_LOGIN = 'REQUEST_CLIENT_LOGIN';

export const REQUEST_PATCH_SELF = 'REQUEST_PATCH_SELF';
export const RECIEVE_PATCH_SELF = 'RECIEVE_PATCH_SELF';



export const requestLogin = createAction(REQUEST_LOGIN);
export const requestAuth = createAction(REQUEST_AUTH);
export const requestLogout = createAction(REQUEST_LOGOUT);
export const receiveLogout = createAction(RECEIVE_LOGOUT);
export const receiveAuth = createAction(RECEIVE_AUTH);
export const requestForgotPassword = createAction(REQUEST_FORGOT_PASSWORD);
export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET);
export const requestClientLogin = createAction(REQUEST_CLIENT_LOGIN);

export const requestPatchSelf = createAction(REQUEST_PATCH_SELF);
export const receivePatchSelf = createAction(RECIEVE_PATCH_SELF);