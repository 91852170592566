import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'components/Form';
import pen from 'img/button/edit-pen-2.png';
import { requestPatchUser, requestDeleteHouse } from 'modules/clients/actions';

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    requestDeleteHouse,
  }, dispatch);
}

class HiddenPenEditField extends Component {

  constructor(props) {
    super(props);
    this.state = { fieldWidth: 0 };
  }

  componentDidMount() {
    this.fieldWidth();
  }

  componentDidUpdate() {
    this.fieldWidth();
  }

  fieldWidth(e) {
    let width = 0;
    let that = this;
    let selector = this.props.keyID;
    // console.log(e);

    setTimeout(function () {
      let maxWidth = window.$('.hidden-field.' + selector).innerWidth() - 40;
      width = window.$('.hidden-input-value.' + selector).innerWidth() + 10;

      if (width > maxWidth) {
        width = maxWidth;
      }

      // if (width > )
      that.setState(() => ({ fieldWidth: width }));
    }, 10);
    // console.log("The width is" + width);

  }

  render() {
    return (
      <div>
        <HiddenInput style={{ backgroundPosition: `${this.state.fieldWidth}px 50%` }} className={"hidden-field moving-right-background " + this.props.keyID} name='name' onChange={(e) => this.fieldWidth(e)} />
        <div style={{ height: 0, opacity: 0, display: 'inline-block', fontWeight: '600', fontSize: '18px', whiteSpace: 'nowrap' }} className={"hidden-input-value " + this.props.keyID}>{this.props.name}</div>
      </div>
    );
  }
}

// 

export default connect(mapStateToProps, mapDispatchToProps)(HiddenPenEditField);

const HiddenInput = styled(Input)`
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0 50px 0 0;
  text-overflow: ellipsis;
  background-image: url(${pen});
  background-position: Calc(100% - 15px) 50%;
  background-size: 17px 17px;
  background-repeat: no-repeat;
  transition: background-position 0s;

  &:focus {
    background-image: none;
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px 0 12px;
  }
`;
