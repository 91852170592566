import React from 'react';
import { reduxForm, Form, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';
import store from 'store';
import { Select, ToggleButton } from 'components/Form';
import { Row, Column } from '../ViewClient';
import styled, { css } from 'styled-components';
import moment from 'moment';  

import { requestPatchUser, requestUser } from 'modules/clients/actions';

import { requestClientLogin } from 'modules/user/actions';

import { makeRequired } from 'util/validation';
import Debouncer from 'util/debouncer';
import _ from 'lodash';

const requireBroker = makeRequired('Please select a broker');

const debouncer = new Debouncer();

const submit = (_values, props) => {
  return new Promise((resolve, reject) => {
    let patch;
    let values = {..._values.client.meta.miniproposal};
    if (props.client.meta.miniproposal) {
      if (values.agreedToProceed && values.agreedToProceed === true) {
        values.agreedToProceed = moment().format();
      }
      if (values.agreedToProceed && props.agreeDate) {
        values.agreedToProceed = props.agreeDate;
      }
      if (!values.draftMode) {
        values.enabled = false;
      }
      patch = {
        meta: {
          miniproposal: {
            //brokerId: null,
            ...values,
          },
          proposal: {
            brokerId: null, // override legacy system
          }
        },
        proposalBrokerId: values.brokerId,
        
      };
          
      props.requestPatchUser({
        data: {
          id: props.client.id,
          ...patch
        },
        callback: result => {
          if (!result.error) resolve();
          else reject();
        },
        params: {
          mergeMeta: true,
        }
      });
      return; 
    } else {
      patch = {
        meta: {
          miniproposal: {
            ...values,
            agreedToProceed: values.agreedToProceed,
            enabled: false,
            tabs: [1],
            summary: {
              overview: {
                upfrontFees: true,
                ongoingFees: true,
              }
            },
            properties: {
              1: {
                id: 1,
                name: 'Property 1',
                propertyDetail: {
                  enabled: false,
                  addressEnabled: true,
                  loanAmountEnabled: 1,
                  image: true,
                  title: 'Property 1',
                  address: 'Add Address Here',
                  value: '',
                  loanAmount: '',
                },
                currentLoan: {
                  enabled: false,
                  title: 'Current Loan',
                  interestRate: props.client.meta.refinanceInterestRate,
                  loanAmount: props.client.meta.refinanceLoanAmount,
                  monthlyRepayment: props.client.meta.refinanceMonthlyRepayment,
                  repaymentType: props.client.meta.refinanceRepaymentType,
                  loanPeriod: props.client.meta.refinanceYears,
                  Lender: props.client.meta.refinanceLender,
                  otherLenderName: props.client.meta.refinanceOtherLenderName,
                },
                execSummary: {
                  enabled: false,
                  title: 'Executive Summary',
                  text: 'Add Text Here',
                },
                additionalDetails: {
                  enabled: false,
                  title: 'Additional Details',
                  text: 'Add Text Here',
                },
                loanComparison: {
                  loanOrder: [],
                  loans: {},
                }
              }
            }
          }
        },
        proposalBrokerId: values.brokerId,
      };
    }
    
    props.requestPatchUser({
      data: {
        id: props.client.id,
        ...patch,
      },
      callback: result => {
        if (!result.error) resolve();
        else reject();
      },
      params: {
        mergeMeta: true,
      }
    });
  });
};

let MiniProposalForm = ({
  //connect
  requestPatchUser,
  client,
  brokers,
  formValues,
  //props
  handleSubmit,
  agreeDate,
}) => {
  
  const brokerOptions = [
    {name: 'None', value: null},
    ...brokers
      .filter(x => x.isBroker)
      .map(({ id, name }) => ({ name, value: id })),
  ];

  const statusOptions = [
    {name: 'Client not yet agreed', value: false},
    {name: 'Client has agreed', value: true},
  ];
  
  return (
    <Form>
      <Row>

        <Column width='50%'>
          <ToggleButton title='Enable Proposal' name='client.meta.miniproposal.draftMode' valueChecked={true} valueUnchecked={false}/>
        </Column>
      
        <Column width='50%'>
          <Label>Assigned Broker</Label>
          <Select name='client.meta.miniproposal.brokerId' options={brokerOptions} validate={requireBroker}/>
        </Column>

        <Column width='50%'>
          <ToggleButton title='Enable Summary Tab' name='client.meta.miniproposal.showSummary' valueChecked={true} valueUnchecked={false}/>
        </Column>

        <Column width='50%'>
          <Label>Proposal Status</Label>
          <Select name='client.meta.miniproposal.agreedToProceed' options={statusOptions} />
          {!!agreeDate && moment(agreeDate).format() !== 'Invalid date' &&
            <BlueInfoText>
              Client agreed at {moment(agreeDate).format('HH:mm')} on {moment(agreeDate).format('DD/MM/YYYY')}
            </BlueInfoText>
          }
        </Column>

      </Row>
      <Row>
        <Column width='100%'>
          <Divider width='100%' />
        </Column>
      </Row>
    </Form>
  );
};

MiniProposalForm = reduxForm({
  form: 'miniProposal',
  onChange: (values, dispatch, props) => {
    const miniproposal = props.client.meta.miniproposal;
    const newMiniproposal = values.client.meta.miniproposal;
    const changes = getChanges(miniproposal, newMiniproposal);
    if (_.isEmpty(changes)) return;
    console.log('form changed', changes);
    debouncer.run(() => submit({ client: { meta: { miniproposal: changes }}}, props), 500);
  }
})(MiniProposalForm);

const dispatchToProps = dispatch => 
  bindActionCreators({
    requestPatchUser,
    requestClientLogin,
    requestUser,
}, dispatch);

const stateToProps = state => {
  return {
    brokers: state.brokers,
    client: state.clients.current,
  };
};

export default connect(stateToProps, dispatchToProps) (MiniProposalForm);


const getChanges = (a, b) => {
  //debugger;
  let changes = {};
  for (const key in b) {
    if (!a) changes = { ...changes, [key]: b[key] };
    else if (!a[key] && !b[key]) continue;
    else if (_.isEqual(a[key], b[key])) {
      continue;
    } else if (Object.is(b[key])) {
      changes = { ...changes, [key]: getChanges(a[key], b[key]) };
    } else {
      changes = { ...changes, [key]: b[key] };
    }
  }
  return changes;
};

const BlueInfoText = styled.div`
  position: absolute;
  color: #4887f0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  top: 75px;
  animation: fadein 1s ease-in-out;
  animation-fill-mode: forwards;

  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`; 