import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import store from 'store';
import { PathNavLink } from 'components/Common/PathNavLink.jsx';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import { requestPatchUser } from 'modules/clients/actions';
import { getQueryVariables } from 'util/index';

import { requestUser } from 'modules/clients/actions';
import AddProperty from '../../Forms/AddProperty';

class Details extends Component {

  state = {
    selectedGroups: [],
  }

  componentDidMount() {
    
    window.$(".icons:hover").addClass("hover");

    document.addEventListener('mousemove', (function (e) {
      var tooltip = document.querySelectorAll('.tooltip');
      for (var i = tooltip.length; i--;) {
        tooltip[i].style.left = e.pageX + 'px';
        tooltip[i].style.top = e.pageY + 'px';
      }

    }), false);
  }
  

  render() {
    const { form } = store.getState();

    let groups = [];
    this.totalPropertyValue = 0;
    let purposeCount = {
      'Owner Occupied': 0,
      'Investment': 0,
      'Holiday': 0,
      'Vacant': 0
    }

    let totalLoanValue = 0;
    let purposeValue = {
      'Owner Occupied': 0,
      'Investment': 0,
      'Holiday': 0,
      'Vacant': 0
    }
    let storedWarningStates = { ...this.state.storedWarningStates };
    let formStateChanged = false;
    (this.props.loans || []).forEach(loan => {
      const loanForm = form['addSplit' + loan.id]
      
      if (loanForm) {
        if (storedWarningStates[loan.id] !== loanForm.warning) {
          storedWarningStates[loan.id] = loanForm.warning;
          formStateChanged = true;
        }
      }
    });
    if (formStateChanged) this.setState({ storedWarningStates });

    this.props.houses.forEach(house=>{
      this.totalPropertyValue += house.value;
      purposeCount[house.purpose] += 1;
      if (house.group) {
        let g = groups.filter(group=>group.propertyGroup === 'Group ' + house.group);
        if (!g.length) {
          groups.push({
            id: house.group,
            propertyGroup: 'Group ' + house.group,
            properties: [house]
          });
        } else {
          g[0].properties.push(house);
        }
      } else {
        groups.push({
          id: house.id,
          propertyGroup: house.locale || house.name,
          properties: [house]
        });
      }
    });
    
    
    (this.props.loans || []).filter(l => l.type === 'mortgage').forEach(loan => {
      totalLoanValue += loan.balance;
      purposeValue[loan.productPurpose] += loan.balance;
      if (loan.security) {
        let group = groups.find(g => g.id.toString() === loan.security.toString());
        if (group) {
          if (group.loans) group.loans.push(loan);
          else group.loans = [loan];
        }
      }
      
    });
 



    groups = groups.map(group => {
      return {
        ...group,
        propertiesValid: !group.properties.some(p => (form['addProperty' + p.id] || {}).warning),
        loansValid: !group.loans || !group.loans.length || !group.loans.some(l => {
          const loanForm = form['addSplit' + l.id];
          if (loanForm) return loanForm.warning;
          else return storedWarningStates[l.id];
        })
          
      }
    });

    const justGroups = groups.filter(g => g.id.length === 1).sort((a, b) => a.propertyGroup.localeCompare(b.propertyGroup));
    const justProperties = groups.filter(g => g.id.length > 1).sort((a, b) => a.propertyGroup.localeCompare(b.propertyGroup));

    groups = [...justGroups, ...justProperties];

    return (
      <div>
        <div className="general-overview">
          {(this.props.type == "loan-details") ?
            <img className="image" src={require('../../../../img/illustrations/loan-details.png')} />
            :
            <img className="image" src={require('../../../../img/illustrations/property-details.png')} />
          }
          <div className="property-value">
            <div className="main-value">${formatNumber(this.props.type == "loan-details" ? totalLoanValue : this.totalPropertyValue)}</div>
            <div className="subtitle">
              {(this.props.type == "loan-details") ? "Aggregate Lending" : "Aggregate Property Value"}
            </div>
            <table className="count">
              <tbody>
                <tr>
                  <td className="owner-occ">
                    {(this.props.type == "loan-details") ?
                      <span class="monetary value">${formatNumber(purposeValue['Owner Occupied'])}</span>
                      :
                      <span className="value" style={{color: purposeCount['Owner Occupied'] > 1 ? '#FF4E4C' : ''}}><span className="large" style={{color: purposeCount['Owner Occupied'] > 1 ? '#FF4E4C' : ''}}>{purposeCount['Owner Occupied']}</span>Owner Occ</span>
                    }
                  </td>
                  <td className="investment">
                    {(this.props.type == "loan-details") ?
                      <span class="monetary value">${formatNumber(purposeValue['Investment'])}</span>
                      :
                      <span className="value"><span className="large">{purposeCount['Investment']}</span>Investment</span>
                    }
                  </td>
                </tr>
                <tr>
                  <td className="holiday">
                    {(this.props.type == "loan-details") ?
                      <span class="monetary value">${formatNumber(purposeValue['Holiday'])}</span>
                      :
                      <span className="value"><span className="large">{purposeCount['Holiday']}</span>Holiday</span>
                    }
                  </td>
                  <td className="vacant">
                    {(this.props.type == "loan-details") ?
                      <span class="monetary value">${formatNumber(purposeValue['Vacant'])}</span>
                      :
                      <span className="value"><span className="large">{purposeCount['Vacant']}</span>Vacant</span>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="property">
          {groups.map((item, index) => {
            return <Box 
              key={index} 
              data={item} 
              type={this.props.type} 
              toggle={this.props.toggleGroup(item.id)} 
              selectedGroups={this.props.selectedGroups}
            />
          })}
        </div>
      </div>
    );
  }
}

class Box extends Component {

  constructor(props) {
    super(props);
  }


  toggleSelectBox(e) {
    if (this.props.type !== "loan-details") return;
    e.preventDefault();
    this.props.toggle();
    return false;
  }

  render() {

    const isSelected = this.props.selectedGroups[0] === this.props.data.id;
    const noneSelected = !this.props.selectedGroups.length;

    const propertyValue = sumArray(this.props.data.properties, 'value');
    const loanValue = sumArray(this.props.data.loans || [], 'balance');
    const lvr = Math.round((loanValue / propertyValue) * 100) || "-";
    const isValid = this.props.type === "loan-details" ? this.props.data.loansValid : this.props.data.propertiesValid;

    return (
      <div onClick={(e) => this.toggleSelectBox(e)} className={"box " + (isValid ? "valid " : "warning ") + ((this.props.type === "loan-details") ? "selectable " : "") + ((isSelected) ? "selected ": "") + ((noneSelected) ? "" : 'grey')}>
        <div className="property-name">{this.props.data.propertyGroup}</div>
        <div className="icons-group">

          {this.props.data.properties.map((item, index) => {
            return <Icons key={index} icon={item} loans={this.props.data.loans} type={this.props.type} />
          })}

        </div>
        {this.props.type == "loan-details" ?
          <div className="breakdown-value">
            <div className="value"><span>Lending</span>${formatNumber(loanValue)}</div>
            <div className="value"><span>Value</span>${formatNumber(propertyValue)}</div>
            <div className="value"><span>LVR</span>{lvr}%</div>
          </div>
          :
          <div>
            <div className="total-value">${formatNumber(propertyValue)}</div>
            <div className="total-value-desc">Total Value</div>
          </div>
        }

      </div>
    );
  }
}



class IconsBase extends Component {
  render() {
    const totalRepayments = (this.props.loans || []).reduce((acc, loan) => {
      const annualRepayment = (loan.repayments / timeUnitToRatio(loan.repaymentFrequency)) || 0;
      return acc + annualRepayment / 12;
    }, 0)

    const lenderLoans = (this.props.loans || []).reduce((acc, loan) => {
      if (!loan.lenderId || loan.lenderId === 'OTHER') loan.lenderId = loan.lenderName || 'Unknown';
      if (acc[loan.lenderId]) {
        acc[loan.lenderId].push(loan);
        return acc;
      } else return {
        ...acc,
        [loan.lenderId]: [loan]
      }
    }, {});
    const lenderSplits = Object.keys(lenderLoans).map(key => {
      const loans = lenderLoans[key] || [];
      const lender = (this.props.lenders || []).find(l => l.id === key) || {name: key};
      const splits = loans
        .map(l => l.balance)
        .sort((a,b) => b - a)
        .map(n => '$' + formatNumber(n));
      return { name: lender.name, splits }
    })

    return (
      <div className={"icons " + propertyIcon(this.props.icon.purpose)} >
        <div className="tooltip">
          <div className="description">
          {this.props.icon.purpose === "X"?"-":this.props.icon.purpose}
          </div>
          <div className="address">{this.props.icon.address}</div>
          {this.props.type == "loan-details"?<div className="value">
            {lenderSplits.map(lender => [
              <span>{lender.name || 'Unknown'} · </span>,
              <span>{lender.splits.join(' · ')}</span>,
              <br/>
            ])}
            ${formatNumber(totalRepayments)} per month total</div>:<div className="value">${formatNumber(this.props.icon.value)}</div>}
        </div>
      </div >
    );
  }
}

const timeUnitToRatio = unit => {
  switch(unit) {
    case "Annually": return 1;
    case "Monthly": return 1/12;
    case "Fortnightly": return 1/26;
    case "Weekly": return 1/52;
    default: return 1/12;
  }
}

const iconsStateToProps = state => ({
  lenders: state.lenders.list || [],
})

const Icons = connect(iconsStateToProps)(IconsBase);

function propertyIcon(type) {
  switch (type) {
    case "Owner Occupied": return "owner-occ";
    case "Investment": return "investment";
    case "Holiday": return "holiday";
    case "Vacant": return "vacant";
    default: return "NULL";
  }
}

function formatNumber(n = 0, d = 0) {
  if (!parseInt(n)) return '-'
  return parseInt(n)
    .toFixed(d)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function sumArray(items, prop) {
  return items.reduce(function (a, b) {
    return a + b[prop] || 0;
  }, 0);
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestUser,
  }, dispatch);

const mapStateToProps = ({ clients }) => ({ client: clients.current });

export default connect(mapStateToProps, mapDispatchToProps)(Details);
