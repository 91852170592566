import React, { useEffect, useState } from 'react';
import Img from 'react-image';

import { uploadUrl } from 'modules';
import uploadFile from 'util/upload-file';
import uriToBlob from 'util/uri-to-blob';
import { ButtonPlain } from 'components/Styled/Button';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

const $ = window.$;

const ImageUploader = (props) => {

  const random = Math.random();
  const [fileUploading, setFileUploading] = useState(false);
  const [logoImageUrl, setLogoImageUrl] = useState(`${uploadUrl}/logos/${props.fileName}?n=${random}`);

  const id = 'file-upload-' + props.fileName.split('.')[0];

  const filePicker = () => {
    $(`#${id}`).click();
  }

  useEffect(() => {
    $.ajaxSetup({
      xhr: function () {
          var xhr = new window.XMLHttpRequest();
          // upload progress
          xhr.addEventListener('progress', function (evt) {
              if (evt.lengthComputable) {
                  var percentComplete = evt.loaded / evt.total;
              }
          }, false);
          return xhr;
      }
    });

    const reader  = new FileReader();

    $(`#${id}`).change(function(){
      var file = this.files[0];
      // encode dataURI
      reader.readAsDataURL(file);
    });
    reader.addEventListener('load', () => {
      
      const blob = uriToBlob(reader.result);
      setFileUploading(true);
      setLogoImageUrl(URL.createObjectURL(blob));
      uploadFile({
        file: blob,
        type: 'logo',
        name: props.fileName,
        callback: (err, res) => {
          setFileUploading(false);
        }
      });
    }, false);

  }, []);

  return (
    <div>
      <label>{props.title}<br/><br/></label>
      <span className='logo-column'>
        <Img style={{width: props.width, height: props.height}} className="img-logo" src={logoImageUrl} alt="logo" unloader={() => 'not working'}/>
      </span>

      <ButtonPlain type='button' width='150px' onClick={filePicker} style={{ display: 'block', marginTop: '10px'}}><ButtonSpinner color='#2291FF' show={fileUploading}/>Change Image</ButtonPlain>
      <input id={id} type='file' name='upload' accept='.png' style={{display: 'none'}}/>
    </div>
  )
}


export default ImageUploader;