import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { requestLogout } from '../../../modules/user/actions';

class Account extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedOut: false,
    }
  }


  logout() {
    this.props.requestLogout(()=>{
      this.setState(() => ({
        isLoggedOut: true
      }));
    });
  }

  render() {
    

    return (
      <div className="account">

        <div className="account-details">
          {/* <div className="profile-picture">
            <img src={require('../../../img/temp/temp-profile-picture.jpeg')} alt="profile"/>
          </div> */}
          <span>{(this.props.user.firstName ?? '') + ' ' + (this.props.user.lastName) ?? ''}</span>
        </div>

        <ul className="navigation">
          {/* <li><NavLink exact to="/accounts/">Account Settings</NavLink></li> */}
          <li onClick={()=>{this.logout()}}>Log out</li>
        </ul>
      </div>
    );
  }
}


const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestLogout
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Account);
