import React from 'react';
import styled, { css } from 'styled-components';

import { FlexColumn, FlexRow, SmallHeading, Text, Footnote, Container as SectionContainerBase, Heading } from './Shared';
import formatNumber from '../utils/formatNumber';


const Aggregates = ({ loans = [], user }) => {

  const overview = user.meta.miniproposal?.overview?.lendingOverview ?? {};

  const aggregateInterestFrequency = overview.aggregateInterestFrequency || 'year';
  const aggregateRepaymentsFrequency = overview.aggregateRepaymentsFrequency || 'month';
  
  console.log('loans:', loans.map(loan => ({
    purpose: loan.currentLoan.newLoanPurpose,
    term: loan.newLoan.releventInterestTerm || Infinity,
  })));

  const releventInterestTerm = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? Infinity : l.newLoan.releventInterestTerm || 30)
    .reduce((a, b) => Math.min(a, b), 30);

  const totalXYearInterestSavings = loans
    .map(l => l.newLoan.yearXInterestSaving(releventInterestTerm) || 0)
    .reduce((a, b) => a + b, 0);

  const repaymentReduction = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : 
      (l.currentLoan.monthlyRepayment || 0) - (l.newLoan.monthlyRepayments || 0))
    .reduce((a, b) => a + b, 0);

  const totalYearOneInterestSavings = loans
    .map(l => l.newLoan.yearOneInterestSaving || 0)
    .reduce((a, b) => a + b, 0);

  const oldYearOneInterest = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.newLoan.oldYearOneInterest || 0)
    .reduce((a, b) => a + b, 0);

  const yearOneInterest = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.newLoan.yearOneInterest || 0)
    .reduce((a, b) => a + b, 0);
  
  const totalMonthlyRepayments = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.newLoan.monthlyRepayments || 0)
    .reduce((a, b) => a + b, 0);

  const oldTotalMonthlyRepayments = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.currentLoan.monthlyRepayment || 0)
    .reduce((a, b) => a + b, 0);

  const trailRebates = loans
    .map(x => x.newLoan.trailRebate)
    .filter(x => !!x);

  let interestPeriodMultiplier;
  switch (aggregateInterestFrequency) {
    case 'year': interestPeriodMultiplier = 1;
    break;
    case 'month': interestPeriodMultiplier = 1/12;
    break;
    case 'fortnight': interestPeriodMultiplier = 1/26.0714;
    break;
    case 'week': interestPeriodMultiplier = 1/52.1428;
    break;
  }

  let repaymentPeriodMultiplier;
  switch (aggregateRepaymentsFrequency) {
    case 'year': repaymentPeriodMultiplier = 12;
    break;
    case 'month': repaymentPeriodMultiplier = 1;
    break;
    case 'fortnight': repaymentPeriodMultiplier = 12/26.0714;
    break;
    case 'week': repaymentPeriodMultiplier = 12/52.1428;
    break;
  }

  const oldAggregateInterest = oldYearOneInterest * interestPeriodMultiplier;
  const newAggregateInterest = yearOneInterest * interestPeriodMultiplier;

  const oldAggregateRepayments = oldTotalMonthlyRepayments * repaymentPeriodMultiplier;
  const newAggregateRepayments = totalMonthlyRepayments * repaymentPeriodMultiplier;
  const showInterestSavings = (oldAggregateInterest > newAggregateInterest && totalXYearInterestSavings > 0) || overview.forceRefinanceComparison;
  const showRepaymentSavings = (oldAggregateRepayments > newAggregateRepayments) || overview.forceRefinanceComparison;

  if (!showInterestSavings && !showRepaymentSavings) return null;

  return (
    <SectionContainer>
      <Heading style={{ marginBottom: '27px'}}>Refinance Comparison</Heading>
      <Text>
        The table below outlines savings when refinancing your current loans to the recommended solution.
        These figures are based only on refinanced loans and exclude any new lending such as cashouts or purchases.
      </Text>
      <MainFlexRow style={{ marginTop: '33px', marginBottom: '0'}}>
        {showInterestSavings &&
          <FlexColumnAggregatesContainer style={{ order: 10}}>
            <ChartHeading>Aggregate Interest Payable</ChartHeading>
            <FlexChart>
              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Current
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(oldAggregateInterest / newAggregateInterest) * 100}>
                        <ColumnForground  color='#727C8F'/> 
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#727C8F'>
                    <Text size='18.3pxpx' weight='bold' marginBottom='5px'>
                      {formatMoney(oldAggregateInterest)}
                    </Text>
                    <Text size='13.3px' weight='600'>
                      per {aggregateInterestFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>
              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Proposed*
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(newAggregateInterest / oldAggregateInterest) * 100}>
                        <ColumnForground  color='#2291FF'/> 
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#2291FF'>
                    <Text size='18.3px' weight='bold' marginBottom='5px'>
                      {formatMoney(newAggregateInterest)}
                    </Text>
                    <Text size='13.3px' weight='600'>
                      per {aggregateInterestFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>
            </FlexChart>

            <FlexRow margin='15px 0 0 0'>
              <Footnote style={{fontSize: '10px', width: '220px'}}>
                {!!trailRebates.length ? 
                  '*Based on the first year you refinance and factors in the annual trail rebate.' 
                  :
                  '*Based on the first year you refinance.'
                }
              </Footnote>
            </FlexRow>
            
            
          </FlexColumnAggregatesContainer>
        }
        
        <FlexColumnAggregatesContainer type="info" style={{ order: 30}}>
          {showInterestSavings &&
          <>
            <FigureHeading>Interest Saved Year 1</FigureHeading>
              <BigBlueText>{formatMoney(totalYearOneInterestSavings)}</BigBlueText>
              
              {(releventInterestTerm > 1) &&
                <>
                  <div style={{height: '20px'}}/>
                  <FigureHeading>Interest Saved Over {releventInterestTerm} Years</FigureHeading>
                  <BigBlueText>{formatMoney(totalXYearInterestSavings)}</BigBlueText>
                </>
              }
              <div style={{height: '20px'}}/>
            </>
          }
          {showRepaymentSavings &&
            <>
              <FigureHeading>Repayment Reduction</FigureHeading>
              <BigBlueText>{formatMoney(repaymentReduction)} per month</BigBlueText>
              <div style={{height: '20px'}}/>
              <FigureHeading>Repayment Saved Year 1</FigureHeading>
              <BigBlueText>{formatMoney(repaymentReduction * 12)}</BigBlueText>
            </>
          }
        </FlexColumnAggregatesContainer>

        
        {showRepaymentSavings &&

          <FlexColumnAggregatesContainer style={{ order: 20}}>
            <ChartHeading>Aggregate Repayments</ChartHeading>
            <FlexChart>
              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Current
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(oldAggregateRepayments / newAggregateRepayments) * 100}>
                        <ColumnForground  color='#727C8F'/> 
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#727C8F'>
                    <Text size='18.3px' weight='bold' marginBottom='5px'>
                      {formatMoney(oldAggregateRepayments)}
                    </Text>
                    <Text size='13.3px' weight='600'>
                      per {aggregateRepaymentsFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>

              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Proposed
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(newAggregateRepayments / oldAggregateRepayments) * 100}>
                        <ColumnForground  color='#2291FF'/> 
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#2291FF'>
                    <Text size='18.3px' weight='bold' marginBottom='5px'>
                      {formatMoney(newAggregateRepayments)}
                    </Text>
                    <Text size='13.3px' weight='600'>
                      per {aggregateRepaymentsFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>
            </FlexChart>
          </FlexColumnAggregatesContainer>
        }

        {showInterestSavings ? "" :
          <FlexColumnAggregatesContainer style={{ margin: '0', order: 90}}></FlexColumnAggregatesContainer>
        }

      </MainFlexRow>
    </SectionContainer>
  );
} 

export default Aggregates;

const formatMoney = n => {
  return (n < 0) ? `-$${formatNumber(-n)}` : `$${formatNumber(n)}`
}

const FigureHeading = styled(SmallHeading)`
  font-size: 15px !important;
`;

const ChartHeading = styled(SmallHeading)`
  font-size: 17px !important;
  font-weight: 700;
  margin-left: auto !important;
  margin-right: auto !important;
  width: fit-content;
`;

const SectionContainer = styled(SectionContainerBase)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
`;

const FlexChart = styled(FlexRow)`
  justify-content: space-between;
  margin: 15px auto 15px auto;
  width: 220px;
`;

const FlexChartColumns = styled(FlexColumn)`
  width: calc(50% - 20px);
`;


const ChartColumn = styled.div``;

const MainFlexRow = styled(FlexRow)`
  justify-content: space-between;
`;

const RepaymentsContainer = styled(FlexRow)`
  justify-content: center;
  margin: 20px 0 0 0;
`;

const FlexColumnAggregatesContainer = styled(FlexColumn)`
  width: 28%;
  padding: 0 
  &::empty {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  text-align: center;
  ${p => p.fontColor && css`color: ${p.fontColor};`}
  padding: ${p => p.padding || '0'};
  margin: ${p => p.margin || '0'};
`;

const BigBlueText = styled.div`
  font-size: 18.3px;
  font-weight: 700;
  color: #308AE9;
  margin-bottom: 15px;
`;

const RepaymentColumnTitle = styled.div`
  color: #575E6C;
  font-weight: 400;
  font-size: 13.3px;
  text-align: center;
`;

const ColumnContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
`;

const ColumnBackground = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 33px;
  height: 156px;
  background-color: #E9EBF1;
  border-radius: 13px;
  overflow: hidden;
`;

const ForgroundContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: ${p => p.heightPercent}%;
`;

const ColumnForground = styled.div`
  background-color: ${p => p.color};
  width: 100%;
  height: 100%;
  border-radius: 13px;
`;


