import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Row, PullLeft, PullRight } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';
import { tableStyle, TableRow, TableCell, TableHeader, TableBody, Table, SortableHeaderCell as HeaderCell } from 'components/Table';
import { ModelessButton } from 'components/Modal/ModelessButton';
import Card from 'components/Styled/Card';
import { Modal, ModalSubmit, ModalHide, ModalShow, ModalContext } from 'components/Modal/old';


import imgTrash from 'img/button/trash-dark.png';
import imgEllipsisDark from 'img/button/ellipsis-dark.png';
import imgEllipsisWhite from 'img/button/ellipsis-white.png';
import imgDowload from 'img/button/download-dark.png';
import imgChat from 'img/button/chat-dark.png';

import { uploadUrl } from 'modules';

const DocumentsViewPure = ({ files }) => (
  <div style={{ margin: '40px 0 0 0' }}>
    <Row style={{ padding: '0 40px' }}>
      <PullLeft><Txt size='20px' marginTop='8px' marginBottom='30px' weight='500'>Client Uploads</Txt></PullLeft>
      {/* <PullRight><ButtonPlain height='30px' fontSize='11px'>Download All Attachments</ButtonPlain></PullRight> */}
    </Row>
    <Table styling={tableStyle.plain} width='100%' sortField='docName'>
      <TableHeader>
        <TableRow>
          <HeaderCell style={{ paddingLeft: '40px' }} sortField='docName'>Name of Document</HeaderCell>
          <HeaderCell sortField='applicantName'>Name of Applicant</HeaderCell>
          <TableCell>Uploaded By</TableCell>
          <TableCell width='277px'>Options</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {
          files && !!files.length && files
            .filter(file => file.type === 'payslip')
            .map( (file, index) => <File file={file} key={index}/>)
        }
      </TableBody>
    </Table>
  </div>
);

const stateToProps = state => ({
  files: state.clients.current.uploads,
});

export const DocumentsView = connect(stateToProps) (DocumentsViewPure);


const File = ({ file }) => {
  let filename = file.storageId;
  if (filename.indexOf('logo') === -1 && filename.indexOf('uploads') === -1) filename = 'uploads/' + filename

  return (
    <TableRow style={{ verticalAlign: 'center' }}>
      <CellFirst>{file.name}</CellFirst>
      <Cell>{file.applicant}</Cell>
      <Cell>Client</Cell>
      <Cell>
        <ButtonIcon as='a' href={`${uploadUrl}/${filename}`} target='_blank' margin='10px 0'><IconDownload /></ButtonIcon>
        {/* <ButtonIcon><IconTrash /></ButtonIcon> */}
        {file.userComment && 
          <ModalContext modal={CommentModal} animationTimeout={500} >
            <ModalShow as={ButtonIcon} modalProps={{ comment: file.userComment }} width='auto'>
              <IconChat />
            </ModalShow>
          </ModalContext>
        }
        {/* <ButtonIcon width='auto'>
          
          <span className='pill-blue' style={{ marginLeft: '10px' }}>6</span>
        </ButtonIcon> */}
        {/* <ModelessButton
          modeless={TaskOptions} 
          buttonDefault={ModelessButtonClosed} 
          buttonActive={ModelessButtonOpen}/> */}
      </Cell>
    </TableRow>
  );
};

const CommentModal = ({ modal, comment, ...props }) => (
  <Modal modal={modal}>
    <Txt size='16px'>{comment}</Txt>
    <Row>
      <PullRight>
        <ModalHide as={ButtonPrimary}>Done</ModalHide>
      </PullRight>
    </Row>
    
  </Modal>
);

const TaskOptions = () => (
  <TaskBar>
    <TaskBarOption>Move Back to Review</TaskBarOption>
    <TaskBarOption>Change Assigned Applicant</TaskBarOption>
    <TaskBarOption>Rename Document</TaskBarOption>
  </TaskBar>
);

const TaskBar = styled(Card)`
  position: absolute;
  padding: 10px 0;
  top: 60px;
  left: -67px;
  width: 200px;
  z-index: 120;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 15px 1px rgba(216,220,231,0.65);

  &:after {
    content: "";
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    position: absolute;
    top: -5px;
    left: 90px;
    filter: drop-shadow(0px -1.5px 0px #D8DCE7);
    
    z-index: 5;
  }
`;

const TaskBarOption = styled.div`
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background-color: #f3f3f3
  }
`;


const ModelessButtonClosed = props => (
  <ButtonIcon {...props}><IconEllipsisDark/></ButtonIcon>
);

const ModelessButtonOpen = props => (
  <ButtonIconActive {...props}><IconEllipsisWhite/></ButtonIconActive>
);

const Cell = props => (
  <TableCell style={{verticalAlign: 'middle'}} {...props}/>
);

const CellFirst = props => (
  <TableCell style={{verticalAlign: 'middle', paddingLeft: '40px'}} {...props}/>
);

const ButtonIcon = props => (
  <ButtonPlain width='32px' height='32px' margin='10px 0 10px 10px' style={{verticalAlign: 'top'}} {...props}/>
);

const ButtonIconActive = props => (
  <ButtonPrimary width='32px' height='32px' margin='10px 0 10px 10px' style={{verticalAlign: 'top'}} {...props}/>
);

const IconTrash = styled.img.attrs({
  src: imgTrash,
  width: 16,
})`
  position: relative;
  top: 3px;
  left: -3px;
`;

const IconEllipsisDark = styled.img.attrs({
  src: imgEllipsisDark,
  width: 16,
})`
  position: relative;
  top: -2px;
  left: -3px;
`;

const IconEllipsisWhite = styled.img.attrs({
  src: imgEllipsisWhite,
  width: 16,
})`
  position: relative;
  top: 5px;
  left: -2px;
`;

const IconDownload = styled.img.attrs({
  src: imgDowload,
  width: 15,
})`
  position: relative;
  top: 6px;
  left: -2px;
`;

const IconChat = styled.img.attrs({
  src: imgChat,
  width: 16,
})`
  position: relative;
  top: 4px;
  left: -2px;
`;