import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import calculateLoanValues from "../utils/calculateLoanValues"

import BankLogoBase from './BankLogo';
import styled, { css } from 'styled-components';

import calculateMonthlyRepayments from "../utils/calculateMonthlyRepayments";
import formatNumber from '../utils/formatNumber';


function mapStateToProps(state, props) {
  return {
    loanPurpose: props.user.meta.loanPurpose,
    miniProposal: props.user.meta.miniproposal,
    trueRate: props.data.trueRate,
    iir: props.data.iir,
    rr: props.data.rr,
    originalRR: props.data.originalRR,
    approxUpfrontFee: props.data.approxUpfrontFee,
    ongoing_fees: props.data.ongoing_fees,
    extra_repayments_allowed: props.data.extra_repayments_allowed,
    redraw_allowed: props.data.redraw_allowed,
    full_offset: props.data.full_offset,
    offsetTooltip: props.data.offsetTooltip,
    paymentPeriod: props.data.paymentPeriod,
  };
}

class ProposalProductBox extends Component {

  render() {
    const { currentLoan, data, user } = this.props;
    const active = false;
    let product = calculateLoanValues({ ...this.props.calculateBox, iir: this.props.iir }, user);
    
    {
      const rate = parseFloat(product.iir);
      const { loanAmount, loanPeriod } = currentLoan;

      if (product.repaymentType === 'Interest Only') {
        product.monthlyRepayments = (rate / 100 / 12 * loanAmount).toFixed(2);
        product.fortnightlyRepayments = (rate / 100 / 26.0714 * loanAmount).toFixed(2); 
        product.weeklyRepayments = (rate / 100 / 52.1428 * loanAmount).toFixed(2);
      } else {
        product.monthlyRepayments = calculateMonthlyRepayments(rate / 100 / 12, (loanPeriod || 30) * 12, loanAmount).toFixed(2);
        product.fortnightlyRepayments = calculateMonthlyRepayments(rate / 100 / 26.0714, (loanPeriod || 30) * 26.0714, loanAmount).toFixed(2);
        product.weeklyRepayments = calculateMonthlyRepayments(rate / 100 / 52.1428, (loanPeriod || 30) * 52.1428, loanAmount).toFixed(2);
      }
    }

    return (
      <ProposalProduct isPDF={true} numberOfLoans={this.props.numberOfLoans} active={active} purchase={currentLoan.newLoanPurpose !== 'refinance'} recommended={this.props.recommendedIndex === this.props.index} refinance={this.props.index === this.props.refinance && currentLoan.newLoanPurpose === 'refinance'} index={this.props.index} className={toggleTypeClass(this.props.type) + " proposal-product-box"}>
          <div className="lender">
            
            <div className='logo-wrapper'>
              <BankLogo height={45} width={135} maxFill={0.60} product={product} />
            </div>  
            <h6 style={{ fontSize: '10px', paddingTop: '5px'}}>{product.product_name}</h6>
          </div>

          <div className="payments" style={{ paddingTop: '15px', paddingBottom: '15px'}}>
            <div style={{ display: 'flex', justifyContent: 'space-around'}}>
              <span>
                <Rate>${Math.round(repaymentValue(product, this.props.paymentPeriod)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Rate>
                <span style={{fontSize:'9px', marginTop: '8px'}} className="field-description">Payment Monthly
                </span>
              </span>
              <span style={{ position: 'relative', top: '-4px'}}>
                <span style={{fontSize:'12px'}}>{product.repaymentType}</span>
                <span style={{fontSize:'9px', marginTop: '8px'}} className="field-description">Repayment Type</span>
              </span>
            </div>
            
          </div>

          

          <div className="interest-rate" style={{ padding: '15px 0 8px 10px'}}>

              <OptionalTooltip className="variable-rate" active={!!product.trailRebate && !(this.props.godmode && !this.props.miniProposal.previewMode)} definition={`Rate factors in a ${product.trailRebate}% annual trail rebate from us, full details outlined above`}>
                <Rate hidden={active}>{formatNumber(Number.parseFloat(product.iir) - (product.trailRebate || 0), 2)}<AfterRate> % p.a.</AfterRate></Rate>
                <Rate hidden={!active}>{data.iir}<AfterRate> % p.a.</AfterRate></Rate>
              
              <>
                {product.initial_ir_type === 'Fixed' ? 
                  <>
                    <FixedTerm 
                      overriden={this.props.originalRR && this.props.rr !== this.props.originalRR} 
                      editMode={active}
                    >
                      <RateBox style={{ marginTop: product.trailRebate ? '0px' : '5px'}} className="field-description" blueBox={!!product.trailRebate}>
                        {(product.trailRebate ? "Effective " : "") + product.rateDescriptionYears}
                      </RateBox>
                    </FixedTerm>
                  </>
                  :
                  <RateBox style={{ marginTop: product.trailRebate ? '0px' : '5px'}} className="field-description" blueBox={!!product.trailRebate}>
                    {(product.trailRebate ? "Effective " : "") + product.rateDescriptionYears}
                  </RateBox>
                }
              </>
            </OptionalTooltip>
   
            <div className="comparison-rate" >
              
              {product.initial_ir_type === "Fixed" ?
                <>
                  <Rate hidden={active}>
                    {formatNumber(Number.parseFloat(product.rr), 2)}
                    <AfterRate> % p.a.</AfterRate>
                  </Rate>
                  <Rate hidden={!active}>
                    {data.rr}
                    <AfterRate> % p.a.</AfterRate>
                  </Rate>
                </>
                :
                <Rate>{this.props.currentLoan.loanAmount ? this.props.trueRate : '???'}<AfterRate> % p.a.</AfterRate></Rate>
              }

              
              <div className="field-description true-rate" style={{ height: '20px'}}>
                <span style={{verticalAlign: "top", fontSize: '9.2px'}}>{product.initial_ir_type === "Fixed" ? "Revert Rate" : "True Rate"}</span>

              </div>
              
            </div>
          </div>

          <div className="fees" style={{ padding: '10px 0 12px 0'}}>
            <div style={{ display: 'flex', justifyContent: 'space-around'}}>
              <div className="upfront-fee" style={{ width: "37%"}}>
                <span style={{fontSize:'20px', color: 'black'}}>${product.approxUpfrontFee}</span>
                <span className="field-description" style={{ fontSize: '9.2px', marginBottom: '6px'}}>Approx Upfront</span>
              </div>
              
              
              <div className="ongoing-fee" style={{ width: "37%"}}>
                <span style={{fontSize:'20px', color: 'black'}}>${product.ongoing_fees}</span>
                <span className="field-description" style={{ fontSize: '9.2px', marginBottom: '6px'}}>Annual Fees</span>
              </div>
            </div>

          </div>

          <CostReducers className="cost-reducers" isPDF={true} isEditable={active} style={{ padding: '15px 0 0 0'}}>
            <ul>
              <li className={(this.props.redraw_allowed === 'Y').toString()}>
                Redraw
              </li>
              <li className={(this.props.full_offset === 'Y' ? 'true' : this.props.full_offset === 'N' ? 'false' : 'additional')}>
                <span>Offset</span>
              </li>
              <li className={(this.props.extra_repayments_allowed === 'Y').toString()}>
                Extra Repayment
              </li>
            </ul>
          </CostReducers>
          {/* <div className='arrow'/> */}
      </ProposalProduct>
      
      
    );
  }
}

const BankLogo = styled(BankLogoBase)`
  align-self: center;
  margin-bottom: -18px;
  img { 
    max-height: 200px !important;
    max-width: 200px !important;
  }
`;

const OptionalTooltip = ({active, children, ...props}) => {
  return (
    <span {...props}>
      {children}
    </span>  
  );
}

const roleRateDefinition = (rr, orr) => {
  if (orr) {
    if (orr === rr) {
      return 'Original revert: ' + orr + '%'
    } else {
      return 'Overidden revert: ' + rr + '%\n' + 'Original revert: ' + orr + '%'; 
    }
  }
  return 'Original revert: ' + rr + '%'
}

const FixedTerm = styled.span`
  ${p => p.editMode && css`
    border-bottom: dotted 1px #727C8F;
  `}
  
  ${p => p.overriden && css`
    color: #2291FF;
    border-color: #2291FF;
  `}
`;

const Span = styled.span`
  display: ${p => p.hidden ? 'none' : 'inline-block'};
  outline: none;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;

    &:focus {
      border-color: #3F70F2;
    }
  `}
`;

const InitialFeeSpan = styled.div`
  display: ${p => p.hidden ? 'none' : 'inline-block'};
`;

const RateBox = styled.span`
  font-size: 9.2px !important;
  
  ${p => p.blueBox && css`
    display: inline-block !important;
    color: white !important;
    font-weight: bold !important;
    
    background-color: #2291FF;
    padding: 1px 5px 3px 5px;
    border-radius: 6px;
    margin-top: 4px !important;
    white-space: nowrap;
    overflow: hidden;
  `}
  /* height: 20px !important; */
`;

const Rate = styled.div`
    ${p => p.hidden && css`
      display: hidden;
    `}
    font-weight: 700;
    font-family: 'LatoPDF', sans-serif;
    font-size: 22px;
    text-align: center;
`;

const AfterRate = styled.span`
    font-weight: 400;
    font-family: 'LatoPDF', sans-serif;
    font-size: 10.2px;
    color: #727C8F;
`;

const ProposalProduct = styled.div`
  width: calc(33% - 20px) !important;
  margin-right: 30px !important;
  padding: 15px 15px 10px 15px !important;
  width: 250px;
  margin-bottom: 0;
  margin-top: 0;
  box-sizing: border-box;
  white-space: normal;
  
  ${props => props.purchase && css`
    padding-bottom: 10px !important;
    min-height: 565px !important;
  `}

  ${props => props.active && css`
    z-index:  500;
  `}

  @media (max-width: 645px) {
    margin-left: 20px !important;
    margin-right: 0 !important;
  }

  .logo-wrapper {
    height: 34px;
  }

  ${props => props.recommended && css`
    border: 2px solid #2689EB !important;

      /* &:after {
        position: absolute;
        content: 'Recommended Solution';
        color: #2689EB;
        font-weight: 700;
        bottom: -28px;
        left: 0;
        right: 0;
        width: 100%; 
        text-align: center;
      } */

      &:before {
      position: absolute;
      content: 'Recommended Solution';
      color: #2689EB;
      font-weight: 700;
      top: -28px;
      left: 0;
      right: 0;
      width: 100%; 
      text-align: center;
      font-size: 13.6px;
    }

    .arrow {
      bottom: -50px;
    }

    .logo-wrapper {
      height: 32px;
    }

  `};

  ${p => p.isPDF && css`
    min-height: 0 !important;
  `}
`;

const CostReducers = styled.div`
  
  text-align: center;
  ul {
    li {
      font-size: 10.2px !important;
      ${p => p.isEditable && css`
        cursor: pointer;
      `}
      position: relative;
      margin-bottom: 5px;
    }
  }

  ${p => p.isPDF && css`
    border-bottom: none !important;
    padding-bottom: 2px !important;
  `}
`;


const AnalysisContainer = styled.div`
  vertical-align: top;
  display: inline-block;
  width: Calc(50% - 12px);

  @media(max-width: 645px) {
    width: 100%;
  }

  button {
    vertical-align: top;
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: inherit;
    font-size: 16px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 13px 50px 13px 50px;
    color: #fff;
    background-color: #2291FF;
    transition: background 500ms;
    padding: 9px 0;
    font-weight: 700;
    font-size: 13px;
    margin-right: 5px;
    width: 110px;
    transition: background color 0.3s;
    box-sizing: border-box;
    border: 1px solid #1C83EA;
    padding: 10px 2px 10px 2px;
    margin-right: 0;
    width: 100%;

    &:hover {
      background-color: #46A3FF;
    }

    span {
      background-image: url(${require('../img/button/stats-white-large.png')});
      background-size: 14px 14px;
      background-position: 0% 50%;
      padding-left: 22px;
      background-repeat: no-repeat;
    }
  }

  .sub-text {
    font-weight: 400;
    font-size: 11px;
    color: #2291FF;
    text-align: center;
    margin-top: 7px;
    margin-right: 5px;
    display: block;
    cursor: pointer;
  }

`;



function repaymentValue(product, freq) {
  let value;
  if (freq === 'weekly') value = product.weeklyRepayments;
  else if (freq === 'fortnightly') value = product.fortnightlyRepayments;
  else value = product.monthlyRepayments;

  if (value === 'NaN') return 0;
  return value;
}

function toggleTypeClass(type) {
  if (type === "dashboard") {
    return "col-c3 shortlist-result";
  }
  if (type === "check-eligibility") {
    return "check-eligibility-selection shortlist-result";
  }
  else {
    return "shortlist-result";
  }
}

function Award(value) {
  if (value !== '') {
    return <div className="best">{value}</div>
  }
  else {
    return ''
  }
}

export default connect(mapStateToProps)(ProposalProductBox);