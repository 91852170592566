import { 
  RECEIVE_GET_LENDERS, 
  REQUEST_CREATE_LENDER, 
  REQUEST_UPDATE_LENDER,
  REQUEST_DELETE_LENDER
} from './actions';

const lenders = (state = {}, { type, payload }) => {
  
  switch (type) {
    case REQUEST_CREATE_LENDER: {
      return { 
        ...state, 
        list: [...state.list, payload.data].sort((a, b) => sortAlphabeticly(a.name, b.name)) 
      };
    }

    case REQUEST_UPDATE_LENDER: {
      let newList = [...state.list];
      const oldLender = newList.find(l => l.id === payload.data.id);
      const index = newList.indexOf(oldLender);
      newList.splice(index, 1);
      const newLender = payload.data;
      return { 
        ...state, 
        list: [...newList, newLender].sort((a, b) => sortAlphabeticly(a.name, b.name)) 
      };
    }

    case REQUEST_DELETE_LENDER: {
      let newList = [...state.list];
      const lenderToDelete = newList.find(l => l.id === payload.id);
      const index = newList.indexOf(lenderToDelete);
      newList.splice(index, 1);
      return { ...state, list: newList };
    }
      
    case RECEIVE_GET_LENDERS: {
      return { ...state, list: payload };
    }
    
    default:
      return state;
  }
};

export default lenders;

const sortAlphabeticly = (_a, _b) => {
  const a = _a.toLowerCase();
  const b = _b.toLowerCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};