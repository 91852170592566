import React, { Component } from 'react';
//import './App.css';
import './Apps.css';
import { BrowserRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Routes from './views/routes';
import RoutesLogin from './views/routesLogin';

import SideBar from './components/Sidebar/Sidebar';
import Navigation from './components/Navigation/Navigation';

import { requestAuth } from './modules/user/actions';
import { requestFindBrokers } from 'modules/brokers/actions';
import { requestGetLenders } from 'modules/lenders/actions';
import { requestGetApplications } from 'modules/applications/actions';
import { requestFindDocuments, requestFindDocumentTemplates  } from 'modules/documents/actions';
import { hot } from 'react-hot-loader/root';

import liveUpdate from 'util/liveUpdate';

sessionStorage.tabID = Math.random();

class App extends Component {

  constructor() {
    super();
    this.state = {
      isLoaded: false
    };
  }

  componentDidMount = () => {
    this.props.requestAuth(() => {
      this.props.requestGetLenders(() => {});
      this.props.requestGetApplications(() => {});
      this.props.requestFindDocuments();
      this.props.requestFindDocumentTemplates();
      this.props.requestFindBrokers(() => {
        this.setState({ isLoaded: true });
        liveUpdate();
      });
    });    
  }

  render() {
    if (!this.state.isLoaded) return '';

  
    if (!this.props.user || !this.props.user.id) return (
      <div>
        <RoutesLogin />
      </div>
    );


    return (
      <div>
        <Navigation state='active' />
        <SideBar />
        <Routes />
      </div>
    );
  }

  loadComplete = () => this.setState({ isLoaded: true });
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestAuth,
    requestFindBrokers,
    requestGetLenders,
    requestGetApplications,
    requestFindDocuments,
    requestFindDocumentTemplates,
  }, dispatch);


export default process.env.NODE_ENV === 'development' ? hot(connect(mapStateToProps, mapDispatchToProps)(App)) : connect(mapStateToProps, mapDispatchToProps)(App);

