import React, { Component, useState, useEffect } from 'react';

import { formValueSelector, reduxForm, SubmissionError, Field } from 'redux-form';
import styled from 'styled-components';
import { ButtonPlain } from 'components/Styled/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonImageSquare } from 'components/Styled/Button';
import Label from 'components/Styled/Label';

import { Input, Select, TextArea, RadioBox, Checkbox, MoneyInput, AddressInput } from 'components/Form';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column } from 'views/Clients/ViewClient';
import pen from 'img/button/edit-pen-2.png';
import deleteBin from 'img/button/trash-red.png';

import statusError from 'img/elements/excl-circle.png';
import statusComplete from 'img/elements/tick-circle.png';

import { requestPatchUser, requestDeleteHouse } from 'modules/clients/actions';
import ErrorDot from '../Tabs/components/ErrorDot';

import Debouncer from 'util/debouncer';
import HiddenPenEditField from '../component/HiddenPenEditField';


function mapStateToProps(state, props) {
  const selector = formValueSelector('addProperty' + props.property.id);
  const id = selector(state, 'id');
  return {
    id,
    displayOpen: (state.clients.current.houses.find(l => l.id === id) || {}).displayOpen,
    userId: selector(state, 'userId'),
    propertyPurpose: selector(state, 'purpose'),
    valueProperty: selector(state, 'value'),
    groupProperties: selector(state, 'groupProperties'),
    address: selector(state, 'address'),
    name: selector(state, 'name'),
    houseId: selector(state, 'id'),
    xCollat: selector(state, 'cross-collateralise'),
    futurePurchase: selector(state, 'future-purchase'),
    beingSold: selector(state, 'being-sold'),
    client: state.clients.current,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    requestDeleteHouse,
  }, dispatch);
}

const valuePropertyOptions = [
  { name: 'Select', value: 'X'},
  { name: 'Estimate', value: 'Estimate' },
  { name: 'RP Data', value: 'RP Data' },
  { name: 'Actual Val', value: 'Actual Val' },
  { name: 'Contract Val', value: 'Contract Val' },
];

const propertyPurposeOptions = [
  { name: 'Please Select', value: 'X'},
  { name: 'Owner Occupied', value: 'Owner Occupied' },
  { name: 'Investment', value: 'Investment' },
  { name: 'Vacant', value: 'Vacant' },
  { name: 'Holiday', value: 'Holiday' },
];

const groupOptions = [
  { name: 'Please select', value: '' },
  { name: 'Group A', value: 'A' },
  { name: 'Group B', value: 'B' },
  { name: 'Group C', value: 'C' },
  { name: 'Group D', value: 'D' },
  { name: 'Group E', value: 'E' },
];

const authorisationHardCopies = [
  { value: 'Future Purchases', label: 'Future Purchases' },
  { value: 'Being Sold', label: 'Being Sold' },
  { value: 'Cross-collateralised', label: 'Cross-collateralised' },
];

class AddProperty extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      displayOpen: true, 
      displayDelete: true,
      lastSubmit: {...props.initialValues, ...props.initialValues.address},
    };
  }

  debouncer = new Debouncer();


  componentDidMount() {
    //this.onFormChange();
  }

  toggleDisplayOpen(e) {
    this.props.requestPatchUser({
      data: { house: { id: this.props.id, displayOpen: !this.props.displayOpen }, id: this.props.userId },
    });
  }

  toggleDelete(e) {
    e.preventDefault();
    this.setState(state => ({ displayDelete: !state.displayDelete }));
    return false;
  }

  filterUnchangedValues = values => {
    const oldValues = this.state.lastSubmit;
    let result = Object.keys(values).reduce((obj, key) => {
      if (values[key] !== oldValues[key]) return {...obj, [key]: values[key]}
      return obj;
    }, {});
    result.id = values.id;
    return result;
  }

  onSubmit = async _data => {
    if (_data.address.placeId && (!_data.name || _data.name.includes('Security'))) {
      const rename = this.rename();
      if (rename) _data.name = rename;
    }
    
    delete _data.displayOpen;
    
    let flags = [];
    if (_data['cross-collateralise']) flags.push('Cross-collateralise');
    if (_data['future-purchase']) flags.push('Future Purchase');
    if (_data['being-sold']) flags.push('Being Sold');
    flags = JSON.stringify(flags);

    let address = {}
    if (_data.address) {
      address = _data.address;
    }

    _data = {
      ..._data,
      ...address,
      flags
    }

    const house = this.filterUnchangedValues(_data);
    if (Object.keys(house).length === 1) return; 
    let data = {
      house,
    }
    
    this.debouncer.run(() => {
      
      this.setState({ lastSubmit: _data });
      this.props.requestPatchUser({
        data: { ...data, id: this.props.client.id },
      });

    }, 2000)
  
  }

  deleteProperty(willDelete = true) {
    this.props.requestPatchUser({
      data: { 
        house: {
          id: this.props.houseId,
          deleting: true,
        }, 
        id: this.props.client.id 
      },
      callback: result => {}
    });
    setTimeout(() => {
      this.props.requestPatchUser({
        data: { 
          house: {
            id: this.props.houseId,
            deleting: false,
            isDeleted: willDelete,
            displayOpen: false,
          }, 
          id: this.props.client.id 
        },
        callback: result => {}
      });
    }, 1000);
    
  }

  onFormChange() {
    setTimeout(
      this.props.handleSubmit(values => this.onSubmit(values))
    );
  }

  async autoRename() {
    if (this.rename()) {
      this.onFormChange();

    }
  }

  rename() {
    
    const { address, propertyPurpose, change } = this.props;
    if (!propertyPurpose || propertyPurpose === 'X') return false;
    
    const localityName = address.locale;

    if (propertyPurpose && localityName) {
      const newName = `${localityName} ${propertyPurpose}`
      change('name', newName);
      return newName;
    }
  }

  xCollatChanged() {
    this.props.change('group', '');
  }

  render() {

    const { handleSubmit, pristine, submitting, invalid, warning, beingSold, xCollat, futurePurchase, property} = this.props;
    return (
      <form onChange={() => this.onFormChange()}
        style={{
          height: this.props.displayOpen ? "auto" : "40px",
        }}
      >

        <Row>
          <ButtonImageSquare 
          type='button'
          style={{
            float: 'left',
            margin: '0 20px 24px 0',
            backgroundImage: this.props.displayOpen ? `url(${require('../../../img/button/up.png')})` : `url(${require('../../../img/button/down.png')})`
          }} onClick={(e) => this.toggleDisplayOpen(e)}></ButtonImageSquare>
          <Column width='340px'>
            <RenameContainer>
              <HiddenPenEditField name={this.props.name} keyID={this.props.property.id} />
              <span style={{
                position: "absolute",
                left: "0",
                bottom: "0px",
                paddingTop: "30px",
              }}>
                <AutoRename className="auto-rename" type='button' onClick={() => this.autoRename()}>Auto-Rename</AutoRename>
              </span>
            </RenameContainer>
          </Column>
          <Column width={(property.isDeleted ? 478 : 278) + 'px'} style={{ paddingRight: "20px", display: this.props.displayOpen ? "block" : "none" }}>
            {this.props.xCollat && [
            <Label width='50px' style={{ display: "inline-block ", verticalAlign: "middle", margin: "0 15px 0 0" }} >X-Collat<ErrorDot name='group'/></Label>,
            <Select width='Calc(100% - 80px)' style={{ display: "inline-block ", verticalAlign: "middle" }} options={groupOptions} name={'group'} onChange={() => this.onFormChange()} />
            ]}
          </Column>
          <Column width={(property.isDeleted ? 428 : 228) + 'px'} style={{ paddingRight: "10px", display: this.props.displayOpen ? "none" : "block" }}>
            <PropertyType className={"property-type " + propertyPurposeIcon(this.props.propertyPurpose)}>
              {(this.props.valueProperty)? ("$" + this.props.valueProperty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")):""}
              <span>
                {this.props.groupProperties}
              </span>
            </PropertyType>
          </Column>
          <StatusIndicator type='button' className={warning ? 'error' : 'complete'} style={{ paddingRight: "10px", display: this.props.displayOpen ? "none" : "block" }} />
          {!property.isDeleted ?
            <DeleteButton type='button' onClick={(e) => this.toggleDelete(e)}>
              <div className="confirm-delete" onClick={() => this.deleteProperty()} style={{ display: this.state.displayDelete ? "none" : "block" }}>
                Click here to delete
              </div>
            </DeleteButton>
          :
            <UndeleteButton type='button' onClick={(e) => this.toggleDelete(e)}>
              ♻
              <div className="confirm-restore" onClick={() => this.deleteProperty(false)} style={{ display: this.state.displayDelete ? "none" : "block" }}>
                Click here to restore
              </div>
            </UndeleteButton>
          }
          
        </Row>
        <div style={{
          display: this.props.displayOpen ? "block" : "none"
        }}>
          <Row>
            <Column width='100%'>
              <Label>Address</Label>
              <AddressInput name='address' onChange={() => this.onFormChange()}/>
            </Column>
          </Row>
          <Row>
            <Column width='50%'>
              <Label>Value <ErrorDot name='value'/></Label>
              <InputGroup>

                <MoneyInput width='55%' name='value' noError/>
                <Select width='45%' options={valuePropertyOptions} name='valueType' onChange={() => this.onFormChange()} />
              </InputGroup>
            </Column>

            <Column width='50%'>
              <Label>Property Purpose<ErrorDot name='purpose'/></Label>
              <Select width='100%' options={propertyPurposeOptions} name='purpose' onChange={() => this.onFormChange()}/>
            </Column>
          </Row>
          <Row>
            <Column width='50%'>
              <Label>Additional Flags</Label>
              {/* <RadioBox options={authorisationHardCopies} name='propertyFlags' /> */}
              <Column>
                <Checkbox formName={'addProperty' + property.id} name='cross-collateralise' label='Cross-collateralise' onChange={() => this.xCollatChanged()} disabled={futurePurchase}/>
              </Column>
              <Column>
                <Checkbox formName={'addProperty' + property.id} name='future-purchase' label='Future Purchase' disabled={beingSold || xCollat}/>
              </Column>
              <Column>
                <Checkbox formName={'addProperty' + property.id} name='being-sold' label='Being Sold' disabled={futurePurchase}/>
              </Column>  
            </Column>
            <Column width='50%'>
              <Label>Notes</Label>
              <TextArea height='150px' name='notes' />
            </Column>
          </Row>
          <Row>
            {validationMessage(!warning)}
          </Row>
        </div>
      </form>
    );
  }
}

const formValidation = values => {
  let errors = { _warning: false, value: false, value: false, group: false };

    let { valueType, value, purpose, group } = values;

    if (!valueType || valueType === 'X' || !value || value < 5000 || value > 99000000 ) {
     errors = {...errors, value: true, _warning: true };
    }
    if (!purpose || purpose === 'X') {
     errors = {...errors, purpose: true, _warning: true };
    }
    if (values['cross-collateralise'] && group === '') {
     errors = {...errors, group: true, _warning: true };
    }
    return errors;
} 


AddProperty = reduxForm({
  warn: formValidation,
  //form: 'addProperty',
  //enableReinitialize: true,
})(AddProperty);

export default connect(mapStateToProps, mapDispatchToProps)(AddProperty);

const HiddenInput = styled(Input)`
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0 50px 0 0;
  text-overflow: ellipsis;
  background-image: url(${pen});
  background-position: Calc(100% - 15px) 50%;
  background-size: 17px 17px;
  background-repeat: no-repeat;

  &:focus {
    background-image: none;
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px 0 12px;
  }
`;

const AutoRename = styled(ButtonPlain)`
  display: none;
  font-size: 11px;
  height: 18px;
  line-height: 16px;
`;

const RenameContainer = styled.div`
  position: relative;

  &:hover {
    button {
      display: block;
    }
  }
`;

const PropertyType = styled.div`
  display: inline-block;
  background-repeat: no-repeat;
  background-size: auto 25px;
  background-position: 0% 50%;
  padding-left: 39px;
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 12px;

  span {
    font-weight: 600;
    margin-left: 15px;
  }
`;

const DeleteButton = styled(ButtonImageSquare)`
  float: left;
  margin: 0 20px 24px 0;
  background-size: 14px 14px;
  background-image: url(${deleteBin});
  position: relative;

  &:focus {
    border: 1px solid #D8DCE7;
  }

  .confirm-delete {
    display: block;
    font-size: 12px;
    color: #ff4e4c;
    position: absolute;
    top: 37px;
    border: 1px solid #d8dce7;
    box-shadow: 0 1px 6px 0 rgba(216,220,231,.65);
    border-radius: 4px 0 4px 4px;
    right: -1px;
    width: 130px;
    padding: 10px 5px;
    background-color: #fff;
    z-index: 100;

    &:after {
      position: absolute;
      display: block;
      top: -4px;
      right: 0;
      content: "";
      background-color: #fff;
      width: 38px;
      height: 5px;
    }
  }
`;

const UndeleteButton = styled(ButtonImageSquare)`
  float: left;
  margin: 0 20px 24px 0;
  background-size: 14px 14px;
  position: relative;
  color: #2291FF;
  font-size: 20px;

  &:focus {
    border: 1px solid #D8DCE7;
  }

  .confirm-restore {
    display: block;
    font-size: 12px;
    color: #2291FF;
    position: absolute;
    top: 37px;
    border: 1px solid #d8dce7;
    box-shadow: 0 1px 6px 0 rgba(216,220,231,.65);
    border-radius: 4px 0 4px 4px;
    right: -1px;
    width: 130px;
    padding: 10px 5px;
    background-color: #fff;
    z-index: 100;

    &:after {
      position: absolute;
      display: block;
      top: -4px;
      right: 0;
      content: "";
      background-color: #fff;
      width: 38px;
      height: 5px;
    }
  }
`;

const StatusIndicator = styled.div`
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: top;
  float:left;
  margin-right: 10px;
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &.error {
    color: #BF7700;
    background-image: url(${statusError});
  }

  &.complete {
    color: #26BD27;
    background-image: url(${statusComplete});
  }
`;

function validationMessage(valid) {
  if (valid) {
    return <div className="validation-message complete">All mandatory items are complete.</div>;
  } else {
    return <div className="validation-message error">Mandatory items incomplete</div>;
  }
}

function propertyPurposeIcon(type) {
    switch (type) {
    case "Owner Occupied": return "owner-occ";
    case "Investment": return "investment";
    case "Holiday": return "holiday";
    case "Vacant": return "vacant";
    default: return "";
  }
}


