// src/views/routesUnverified.js

import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import SignIn from './Accounts/SignIn';
import ForgotPassword from './Accounts/ForgotPassword';
import ResetPassword from './Accounts/ResetPassword'

const RoutesLogin = () => (
    <Switch>
        <Route exact path='/login' component={SignIn}/>
        <Route exact path='/forgot-password' component={ForgotPassword}/>
        <Route exact path='/reset-password' component={ResetPassword}/>
        <Redirect from='*' to='/login' />
        <Route component={()=>(<main><h1>404</h1></main>)} />
    </Switch>
);

export default RoutesLogin;