import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'redux-form';

import imgCheck from 'img/button/check-small-white.png';
import Label from 'components/Styled/Label';

const CheckBoxInput = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  ${p => p.readOnly && css`
    cursor: no-drop;
  `}
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  border-radius: 4px;
  background: white;
  background-size: 0px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const CheckBoxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  

  font-family: 'lato';
  line-height: 20px;
  font-size: 14px;
  color: #223241;
  font-weight: 400;
  user-select: none;

  ${CheckMark}:after {
    left: 5px;
    top: 6px;
    height: 20px;
    width: 20px;
    background-image: url(${imgCheck});
    background-repeat: no-repeat;
    background-size: 10px;
  }

  ${CheckBoxInput}:disabled {
    cursor: default;
  }

  &:hover {
    ${CheckMark} {
      border: 1px solid #C8CCD7;
    }
  }

  & ${CheckBoxInput}:checked {
      ~ ${CheckMark} {
      background: #2689EB;
      
      border: white;
      border-radius: 4px;

      &:after {
        display: block;
        animation: expand 300ms ease-out forwards;
      }
    }
  }

  @keyframes expand {

  0% {
    transform: scale(3, 3) translate(3px,3px);
    
  }
  100% {
    transform: scale(1, 1);
  }
}
`;



const ToggleButtonSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F6F7FB;
  transition: .4s;
  border: 1px solid #C8CCD7;
  border-radius: 100px;
  
  ${p => (p.readOnly || p.disabled) && css`
    cursor: no-drop;
  `}

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 2.5px;
    bottom: 1.5px;
    background-color: white;
    transition: .4s;
    border-radius: 100px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.26);
  }
`;

const ToggleButtonContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
  top: ${p => p.top || '4px'};

  & ${CheckBoxInput}:checked {
    ~ ${ToggleButtonSlider} {
      background: #26BD27;
      border-color: #26BD27;

      &:before {
        left: 21.5px;
      }
    }
  }

  ${p => p.disabled && css`
    & ${CheckBoxInput} {
      ~ ${ToggleButtonSlider} {
        background: #F6F7FB;
        border-color: #C8CCD7;
        cursor: no-drop;
      }
    }
  `}
`;

export const ToggleButton = ({title, checked, onChange, top, readOnly, disabled,  ...props}) => (
  <Fragment>
    {title && <Label>{title}</Label>}
    <ToggleButtonContainer top={top} {...props} disabled={disabled}>
      <CheckBoxInput defaultChecked={checked} disabled={disabled} checked={checked} onChange={onChange} readOnly={readOnly}/>
      <ToggleButtonSlider readOnly={readOnly}/>
    </ToggleButtonContainer>
  </Fragment>
);

export const CheckBox = ( {title, checked, onChange} ) => (
  <CheckBoxContainer>
    {title}
    <CheckBoxInput defaultChecked={checked} onChange={onChange}/>
    <CheckMark />
  </CheckBoxContainer>
);

const FormCheckbox = ({input, ...props}) => (
  <CheckBoxInput
    checked={!!input.value}
    onChange={e => input.onChange(e.target.checked)}
    {...props}
  />
);



export const ToggleField = ( {title, checked, onChange, name, disabled} ) => (
  <CheckBoxContainer>
    {title}
    <CheckBoxField name={name} disabled={disabled} />
    <CheckMark />
  </CheckBoxContainer>
);

export const StandardCheckBoxField = ({ title, name, ...props }) => 
  <CheckBoxContainer>
    {title}
    <CheckBoxField name={name} />
    <CheckMark />
  </CheckBoxContainer>;

const CheckBoxField = ({ valueChecked, valueUnchecked, ...props }) => 
  <Field 
    component={FormCheckbox} 
    normalize={normalizeToggle(valueChecked, valueUnchecked)} 
    format={formatToggle(valueChecked)}
    {...props}
  />;

const formatToggle = valueChecked => 
  value => {
    if (!valueChecked) return value;
    return (value === valueChecked) ? 'Y' : '';
  };

const normalizeToggle = (valueChecked, valueUnchecked) =>
  value => value ? (valueChecked ?? 'Y') : (valueUnchecked ?? '');

export const FormToggleButton = ({title, name, top, disabled, valueChecked, valueUnchecked, ...props}) => (
  <Fragment>
    {title && <Label>{title}</Label>}
    <ToggleButtonContainer top={top} {...props}>
      <CheckBoxField 
        name={name} 
        disabled={disabled} 
        valueChecked={valueChecked} 
        valueUnchecked={valueUnchecked}
      />
      <ToggleButtonSlider/>
    </ToggleButtonContainer>
  </Fragment>
);


