import {
  RECEIVE_FIND_CLIENTS,
  RECEIVE_USER,
  RECIEVE_PATCH_USER,
  RECIEVE_CREATE_USER,
  REQUEST_PATCH_USER,
  REQUEST_DELETE_HOUSE,
  RECIEVE_CREATE_HOUSE,
  RECIEVE_CREATE_LOAN,
  REQUEST_DELETE_LOAN,
  REQUEST_CREATE_HOUSE,
  REQUEST_CREATE_LOAN,
  ADD_FILE,
  ADD_DOCUMENT,
  REMOVE_DOCUMENT,
  PATCH_DOCUMENT, 
  REQUEST_USER,
  EXTERNAL_PATCH_USER,
  PATCH_DOCUMENT_IN_STORE,
  REMOVE_DOCUMENT_FROM_STORE,
  ADD_DOCUMENT_TO_STORE
} from './actions';

import _ from 'lodash';


const clientReducer = (state = { current: {}, list: {list: []}, referrers: {list: []}}, action) => {
  switch(action.type) {

  case RECEIVE_FIND_CLIENTS: {
    const { data, total, query } = action.payload;
    const listName = query.type ?? 'list';
    if (action.payload.toAppend) {
      const isComplete = !(data && data.length > 0);
      return { ...state, [listName]: { list: [...state[listName].list, ...data], total, isComplete } };
    }
    return { ...state, [listName]: { isComplete: false, list: data, total } };
  }

  case REQUEST_USER: {
    return { ...state, userLoading: parseInt(action.payload.query) };
  }
  case RECEIVE_USER:
    if (!action.payload.force && state.userLoading !== action.payload.id) return state;
    delete action.payload.force;
    return { ...state, current: action.payload, userLoading: null }; 
  
  case REQUEST_PATCH_USER: {
    let user = { ...action.payload.data };
    if (user.application) {
      user.applications = [];
      user.applications.push({...user.application});
      delete user.application;
    }
    if (user.partner) {
      user.partners = [];
      user.partners.push({...user.partner});
      delete user.partner;
    }
    if (user.house) {
      let houseIndex = state.current.houses.findIndex(house=>house.id === user.house.id);
      state.current.houses[houseIndex] = {...state.current.houses[houseIndex], ...user.house}
      delete user.house;
    }
    if (user.loan) {
      let loanIndex = state.current.loans.findIndex(loan=>loan.id === user.loan.id);
      state.current.loans[loanIndex] = {...state.current.loans[loanIndex], ...user.loan}
      delete user.loan;
    }

    if (state.current?.id !== user.id) return state;
    return { ...state, current: { ..._.merge(state.current, user) } };
  }

  case ADD_FILE: {
    let file = action.payload;
    return { ...state, current: { ...state.current, uploads: [ ...state.current.uploads ?? [], file ] } };
  }
  
  // case RECIEVE_CREATE_HOUSE: {
  //   let house = { ...action.payload };
  //   let nextState = {...state};
  //   nextState.current.houses.push(house);
  //   return nextState;
  // }

  case REQUEST_CREATE_HOUSE: {
    let { house } = {...action.payload };
    let nextState = {...state};
    if (!nextState.current.houses) {
      nextState.current.houses = [];
    }
    nextState.current.houses.push(house);
    return nextState;
  }

  case REQUEST_DELETE_HOUSE: {
    let id = action.payload.id
    let nextState = {...state};
    nextState.current.houses = nextState.current.houses.filter(h => h.id !== id);
    return nextState;
  }

  // case RECIEVE_CREATE_LOAN: {
  //   let loan = { ...action.payload };
  //   let nextState = {...state};
  //   nextState.current.loans.push(loan);
  //   return nextState;
  // }

  case REQUEST_CREATE_LOAN: {
    let { loan } = { ...action.payload };
    let nextState = {...state};
    if (!nextState.current.loans) {
      nextState.current.loans = [];
    }
    nextState.current.loans.push(loan);
    return nextState;
  }

  case REQUEST_DELETE_LOAN: {
    let id = action.payload.id
    let nextState = {...state};
    nextState.current.loans = nextState.current.loans.filter(l => l.id !== id);
    return nextState;
  }

  case RECIEVE_PATCH_USER: {
    const user = action.payload;
    const index = state.list.list.findIndex(u => u.id === user.id);
    if (index > -1) {
      let list = state.list.list;
      
      list.splice(index, 1);
      return { ...state, list: { list: [...list, user] } };
    } else {
      return state;
    }
  }
  case RECIEVE_CREATE_USER: {
    return {...state, list: {list: []}};
  }

  
  case EXTERNAL_PATCH_USER: {
    let user =  _.cloneDeep(action.payload.data);
    if (user.isMobileVerified) user.isMobileVerified = !!user.isMobileVerified;
    if (user.mobileVerificationRequired) user.mobileVerificationRequired = !!user.mobileVerificationRequired;
    if (user.meta?.miniproposal?.agreedToProceed) user.client.meta.miniproposal.agreedToProceed = !!user.client.meta.miniproposal.agreedToProceed;

    let newState = _.cloneDeep(state);

    if (user.id === newState.current.id) {
      if (user.application) {
        user.applications = [];
        user.applications.push({...user.application});
        delete user.application;
      }
      if (user.partner) {
        user.partners = [];
        user.partners.push({...user.partner});
        delete user.partner;
      }
      if (user.house) {
        let houseIndex = newState.current.houses.findIndex(house=>house.id === user.house.id);
        newState.current.houses[houseIndex] = { ...newState.current.houses[houseIndex], ...user.house };
        delete user.house;
      }
      if (user.loan) {
        let loanIndex = newState.current.loans.findIndex(loan=>loan.id === user.loan.id);
        newState.current.loans[loanIndex] = { ...newState.current.loans[loanIndex], ...user.loan };
        delete user.loan;
      }

      newState = { ...newState, current: { ..._.merge(newState.current, user) } };
    }
    
    const index = newState.list.list.findIndex(u => u.id === user.id);
    if (index > -1) {
      let list = newState.list.list;
      
      const oldUser = list.splice(index, 1)[0];

      return { ...newState, list: { ...newState.list, list: [...list, {...oldUser, user }] } };
    } else {
      return newState;
    }
  }
  default:
    return state;
  }
};

export default clientReducer;