import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import store from 'store';
import { Redirect } from 'react-router-dom';

import {
  requestUpdateLender,
  requestGetLenders,
  requestDeleteLender
} from 'modules/lenders/actions';

// Styled Components

import BackButton from 'components/Common/BackButton';
import Form from './EditLenderForm';
import { PageLoader } from 'components/Loader';


class EditLender extends Component {
  constructor() {
    super();
    this.state = {
      redirectTo: '',
      form: {},
    };
  }

  submitForm = data => {
    this.props.requestUpdateLender({
      data
    });
    this.redirect('/lenders');
  }

  redirect = (to) => this.setState({ redirectTo: to });

  componentWillMount() {
    const vars = getQueryVariables(this.props.location.search);
    if (store.getState().lenders.list) {
      const lender = store.getState().lenders.list.find(l => l.id === vars.id);
      if (lender) {
        this.setState({ form: lender });
      }
      else {
        this.redirect('/lenders');
      }
    }
    // if (!store.getState().lenders.list) {
    this.props.requestGetLenders(() => {
      const lender = store.getState().lenders.list.find(l => l.id === vars.id);
      if (lender) {
        this.setState({ form: lender });
      }
      else {
        this.redirect('/lenders');
      }
    });
    // }
    // else {
    //   const lender = store.getState().lenders.list.find(l => l.id === vars.id);
    //   if (lender) {
    //     this.setState({ form: lender });
    //   }
    //   else {
    //     this.redirect('/lenders');
    //   }
    // }

  }

  render() {
    const { form, redirectTo } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    if (!form) return (
      <main className='no-bottom-margin'>
        <PageLoader />
      </main>
    );

    return (

      <main className='no-bottom-margin'>
        <div className='lender-container'>
          <BackButton onClick={() => this.redirect('/lenders')} />
          <div className='lender-modal'>
            <Form onSubmit={this.submitForm} initialValues={form}/>
          </div>
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestUpdateLender,
      requestGetLenders,
      requestDeleteLender,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EditLender);

function getQueryVariables(query) {
  let values = {};
  if (query) {
    var vars = query.split('?')[1].split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      values[pair[0]] = pair[1];
    }
  }
  return values;
}