import { takeEvery, call, put } from 'redux-saga/effects';

import {
  patchApplication,
  getApplications,
  moveApplication,
  patchStage,
  createStage,
  removeStage,
  createApplication,
  getApplication,
 } from './api';

import {
  REQUEST_UPDATE_APPLICATIONS,
  REQUEST_GET_APPLICATIONS,
  REQUEST_MOVE_APPLICATION,
  requestGetApplications,
  receiveGetApplications,
  receiveCreateApplication,
  UPDATE_STAGE,
  CREATE_STAGE,
  REMOVE_STAGE,
  REQUEST_CREATE_APPLICATION,
  receiveUpdateApplications,
  REQUEST_GET_APPLICATION,
} from './actions';

// remove duplicate strings from array
const removeDuplicates = (array) => {
  const unique = {};
  array.forEach(i => unique[i] = true);
  return Object.keys(unique);
};

function* callGetApplications({ payload }) {
  yield put(receiveGetApplications({ loading: true, dealsLoading: true }));
  const dealApplications = yield call(getApplications, { archived: false, dealsOnly: true });
  yield put(receiveGetApplications({ ...dealApplications, dealsLoading: false }));
  const applications = yield call(getApplications, { archived: false });
  yield put(receiveGetApplications({ ...applications, loading: false }));
  const archivedApplications = yield call(getApplications, { archived: true });
  const stageIds = removeDuplicates([ ...Object.keys(applications.stages ?? {}), ...Object.keys(archivedApplications.stages ?? {}) ]);
  // concatonate stages lists from both archived and non-archived applications
  const stages = stageIds.reduce((acc, stageId) => {
    acc[stageId] = {
      ...(applications.stages[stageId] ?? archivedApplications.stages[stageId]),
      applications: [ ...(applications.stages[stageId]?.applications ?? []), ...(archivedApplications.stages[stageId]?.applications ?? []) ],
    };
    return acc;
  }, {});
  const combined = { list: {...applications.list, ...archivedApplications.list}, stages };
  yield put(receiveGetApplications({ ...combined }));
  if (payload) payload(combined);
}
export function* getApplicationsSaga() {
  yield takeEvery(REQUEST_GET_APPLICATIONS, callGetApplications);
}

function* callGetApplication({ payload }) {
  const result = yield call(getApplication, payload);
  yield put(receiveUpdateApplications({ ...result, ignoreNull: true }));
}
export function* getApplicationSaga() {
  yield takeEvery(REQUEST_GET_APPLICATION, callGetApplication);
}

function* callMoveApplication({ payload: { callback, listId, placeId, afterId } }) {
 yield call(moveApplication, { listId, placeId, afterId } );
 if (callback) callback();
}
export function* moveApplicationsSaga() {
  yield takeEvery(REQUEST_MOVE_APPLICATION, callMoveApplication);
}

function* callUpdateApplications({ payload }) { 
  yield put(receiveUpdateApplications(payload));
  yield call(patchApplication, payload );
}
export function* updateApplicationsSaga() {
  yield takeEvery(REQUEST_UPDATE_APPLICATIONS, callUpdateApplications);
}

function* callCreateApplication({ payload }) { 
  const result = yield call(createApplication, payload );
  yield put(receiveCreateApplication({ ...payload, ...result }));
  if (payload.callback) payload.callback(result);
 }
 export function* createApplicationSaga() {
   yield takeEvery(REQUEST_CREATE_APPLICATION, callCreateApplication);
 }

function* callUpdateStage({ payload }) { 
  yield call(patchStage, payload );
}
export function* updateStageSaga() {
  yield takeEvery(UPDATE_STAGE, callUpdateStage);
}

function* callCreateStage({ payload }) { 
  yield call(createStage, payload );
}
export function* createStageSaga() {
  yield takeEvery(CREATE_STAGE, callCreateStage);
}

function* callRemoveStage({ payload }) { 
  yield call(removeStage, payload );
}
export function* removeStageSaga() {
  yield takeEvery(REMOVE_STAGE, callRemoveStage);
}