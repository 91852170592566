import React, { useRef, useState, useEffect } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';

import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import uriToBlob from './utils/uriToBlob';

import DocsPDF from './PDF/DocsPDF';

const DocsPDFStandAlone = ({ user, isFinal, finished, settings }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!user) return;
    setTimeout(async () => {
      if (ref && ref.current) {
        console.log('Creating PDF...');

        const uri = await exportPDFWithMethod(isFinal);
        const blob = await uriToBlob(uri);
        // download(blob, 'testPDF', 'application/pdf');
        if (finished) finished(blob);
      }
    }, 2000);
  }, []); 

  if (!user) return null;

  return (  
    <PDFExport
      pageTemplate={PageTemplate1}
      paperSize='A4'
      fileName="proposal.pdf"
      forcePageBreak=".page-break"
      keepTogether=".keep-together2"
      scale={595/1100}
      margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      title=""
      subject=""
      keywords=""
      ref={ref}
    >
      <div className='proposal-pdf'>
        <MainContent>
          <DocsPDF user={user} isFinal={isFinal} settings={settings} />
        </MainContent>
      </div>  
    </PDFExport>

  );

};

export default DocsPDFStandAlone;


const exportPDFWithMethod = async (isFinal) => {
  let el = document.getElementsByClassName('proposal-pdf')[0];
  
  const pdf = await drawDOM(el, { 
    paperSize: 'A4',
    forcePageBreak:'.page-break',
    keepTogether:'.keep-together2',
    scale: 595 / 1100,
    margin: { top: 0, left: 0, right: 0, bottom: 0 },
    template: props => {
      var pageTemplateContent = ReactDOMServer.renderToStaticMarkup(React.createElement(PageTemplate1));
      return '<span>' + pageTemplateContent + '</span>';
    }
  });
  
  const dataUri = await exportPDF(pdf);

  return dataUri;
};

const PageTemplate1 = ({ pageNum, totalPages }) => {
  return (
    <>
      <PageHeader >
        <span>1300 512 377</span>
        <span>/</span>
        <span>contact@loanbase.com.au</span>
        <span>/</span>
        <span>www.loanbase.com.au</span>
      </PageHeader>
      <PageFooter >
        <span>Supporting Documents / Loan Base</span>
      </PageFooter>
    </>
  ); 
};

const PageHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F7F7F7 ;
  width: 100%;
  height: 92px;
  font-size: 15px;
  padding: 39px 135px;
  font-family: LatoPDF, sans-serif;
  color: #575E6C;

  span {
    margin-left: 10px;
  }
`;

const PageFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #2B8AEA;
  width: 100%;
  height: 92px;
  padding: 35px 135px;
  color: white;
  font-size: 18.5px;
  font-family: LatoPDF, sans-serif;

  display: flex;
  justify-content: space-between;
`;

const MainContent = styled.div`
  width: 1100px;
  padding: 147px 135px 0px 135px;
  * {
    font-family: LatoPDF, Lato, sans-serif !important;
  }
`;
