import React, { Fragment, useEffect } from 'react';

import { reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { connect } from 'react-redux'; 
import { Row } from 'components/Styled/Layout';
import { Hide, FormCard, HeadingLarge, SelectField, TextField, MoneyField, PercentField, FormGroup, NumberField, SaveButton, MoneyAndSelectField } from '../Components';
import initializeFormValues from 'util/initializeFormValues';
import { ButtonPlain } from 'components/Styled/Button';
import styled from 'styled-components';

import imgClose from 'img/button/close-modal.png';

const FORM_NAME = 'submission-liabilities';

const IncomeFormPure = ({ 
  handleSubmit,
  submitting, 
  pristine, 
  haveCredit,
  partnerHaveCredit,
  havePersonalLoan,
  partnerHavePersonalLoan,
  haveCarLoan,
  partnerHaveCarLoan,
  applicantType,
  application,
}) => { 
  
  useEffect(() => {
    initializeFormValues(FORM_NAME, values => ({ ...values, application }));
  }, []);
  
  return (
    <FormCard onSubmit={handleSubmit}>
      <HeadingLarge>Applicant</HeadingLarge>
      <Row>
        <SelectField name='client.meta.haveCredit' label='Has Credit Card' options={yesNoOptions} width='60px'/>
        <Hide when={haveCredit !== 'Yes'}>
          <MoneyField name='client.meta.creditCardLimit' label='Total Limit (Across All Cards)' />
        </Hide>
      </Row>
      <Row>
        <SelectField name='client.meta.haveHECS' label='Has HECS-help Debt' options={yesNoOptions} width='60px'/>
      </Row>
      <Row>
        <SelectField name='client.meta.havePersonalLoan' label='Has a Personal Loan' options={yesNoOptions} width='60px'/>
        <Hide when={havePersonalLoan !== 'Yes'}>
          <MoneyField name='client.meta.personalLoanBalance' label='Personal Loan Balance' />
          <MoneyAndSelectField name='client.meta.personalLoanBalanceRepayment' label='Repayment' options={timeOptions}/>
        </Hide>
      </Row>
      <Row>
        <SelectField name='client.meta.haveCarLoan' label='Has a Car Loan' options={yesNoOptions} width='60px'/>
        <Hide when={haveCarLoan !== 'Yes'}>
          <MoneyField name='client.meta.carLoan' label='Car Loan Balance' />
          <MoneyAndSelectField name='client.meta.carLoanRepayment' label='Repayment' options={timeOptions}/>
        </Hide>
      </Row>
      <FieldArray name='client.meta.otherLiabilities' component={OtherLiabilities} />
      <Hide when={applicantType !== 'youAndYourPartner'}>
        <div style={{ width: '100%', height: '40px'}}/>
        <HeadingLarge>Partner</HeadingLarge>
        <Row>
          <SelectField name='client.partner.meta.haveCredit' label='Has Credit Card' options={yesNoOptions} width='60px'/>
          <Hide when={partnerHaveCredit !== 'Yes'}>
            <MoneyField name='client.partner.meta.creditCardLimit' label='Total Limit (Across All Cards)' />
          </Hide>
        </Row>
        <Row>
          <SelectField name='client.partner.meta.haveHECS' label='Has HECS-help Debt' options={yesNoOptions} width='60px'/>
        </Row>
        <Row>
          <SelectField name='client.partner.meta.havePersonalLoan' label='Has a Personal Loan' options={yesNoOptions} width='60px'/>
          <Hide when={partnerHavePersonalLoan !== 'Yes'}>
            <MoneyField name='client.partner.meta.personalLoanBalance' label='Personal Loan Balance' />
            <MoneyAndSelectField name='client.partner.meta.personalLoanBalanceRepayment' label='Repayment' options={timeOptions}/>
          </Hide>
        </Row>
        <Row>
          <SelectField name='client.partner.meta.haveCarLoan' label='Has a Car Loan' options={yesNoOptions} width='60px'/>
          <Hide when={partnerHaveCarLoan !== 'Yes'}>
            <MoneyField name='client.partner.meta.carLoan' label='Car Loan Balance' />
            <MoneyAndSelectField name='client.partner.meta.carLoanRepayment' label='Repayment' options={timeOptions}/>
          </Hide>
        </Row>
        <FieldArray name='client.partner.meta.otherLiabilities' component={OtherLiabilities} />
      </Hide>
      <SaveButton disabled={(submitting || pristine)} submitting={submitting}/>
    </FormCard>
  );
};

const DeleteButton = styled.img.attrs({
  src: imgClose,
  width: 16,
})`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

const OtherLiabilities = ({ fields }) => (
  <Fragment>
    {!!fields.length && <FormGroup/>}
    {fields.map( (item, index) => (
      <FormGroup key={index}>
        <DeleteButton onClick={() => fields.remove(index)} />
        <Row>
          <HeadingLarge>Other Liability {index + 1}</HeadingLarge>
          <TextField name={`${item}.liability`} label='Liability' />
          <MoneyField name={`${item}.amount`} label='Amount' />
          <MoneyAndSelectField name={`${item}.repayment`} label='Repayment' options={timeOptions}/>
        </Row>
      </FormGroup>
    ))}
    <ButtonPlain type='button' onClick={() => fields.push({ repaymentType: timePeriod.MONTH })} height='32px'>Add Other Liability</ButtonPlain>
  </Fragment>
);

const select = formValueSelector(FORM_NAME);
const stateToProps = (state, props) => ({
  haveCredit: select(state, 'client.meta.haveCredit'),
  havePersonalLoan: select(state, 'client.meta.havePersonalLoan'),
  haveCarLoan: select(state, 'client.meta.haveCarLoan'),

  partnerHaveCredit: select(state, 'client.partner.meta.haveCredit'),
  partnerHavePersonalLoan: select(state, 'client.partner.meta.havePersonalLoan'),
  partnerHaveCarLoan: select(state, 'client.partner.meta.haveCarLoan'),
  
  applicantType: props.application.meta.applicantType,
});

const IncomeForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(IncomeFormPure);

export default connect(stateToProps)(IncomeForm);

const timePeriod = {
  YEAR: 1,
  MONTH: 1/12,
  WEEK: 1/365.25*7,
  FORTNIGHT: 1/365.25*14,
};

const timeOptions = [
  {name: 'Monthly', value: timePeriod.MONTH},
  {name: 'Fortnightly', value: timePeriod.FORTNIGHT},
  {name: 'Weekly', value: timePeriod.WEEK},
  {name: 'Annually', value: timePeriod.YEAR},
];

const yesNoOptions = [
  {name: 'Yes', value: 'Yes'},
  {name: 'No', value: 'No'},
];