import { createAction } from 'redux-actions';
import { create } from 'domain';

export const REQUEST_FIND_CLIENTS = 'REQUEST_FIND_CLIENTS';
export const RECEIVE_FIND_CLIENTS = 'RECEIVE_FIND_CLIENTS';
export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const REQUEST_PATCH_USER = 'REQUEST_PATCH_USER';
export const RECIEVE_PATCH_USER = 'RECIEVE_PATCH_USER';
export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER';
export const RECIEVE_CREATE_USER = 'RECIEVE_CREATE_USER';
export const REQUEST_CREATE_HOUSE = 'REQUEST_CREATE_HOUSE';
export const RECIEVE_CREATE_HOUSE = 'RECIEVE_CREATE_HOUSE';
export const REQUEST_DELETE_HOUSE = 'REQUEST_DELETE_HOUSE';
export const REQUEST_CREATE_LOAN = 'REQUEST_CREATE_LOAN';
export const RECIEVE_CREATE_LOAN = 'RECIEVE_CREATE_LOAN';
export const REQUEST_DELETE_LOAN = 'REQUEST_DELETE_LOAN';
export const ADD_FILE = 'ADD_FILE';
export const EXTERNAL_PATCH_USER = 'EXTERNAL_PATCH_USER';

export const requestFindClients = createAction(REQUEST_FIND_CLIENTS);
export const receiveFindClients = createAction(RECEIVE_FIND_CLIENTS);

export const requestUser = createAction(REQUEST_USER);
export const receiveUser = createAction(RECEIVE_USER);

export const requestPatchUser = createAction(REQUEST_PATCH_USER);
export const receivePatchUser = createAction(RECIEVE_PATCH_USER);

export const requestCreateUser = createAction(REQUEST_CREATE_USER);
export const receiveCreateUser = createAction(RECIEVE_CREATE_USER);

export const requestCreateHouse = createAction(REQUEST_CREATE_HOUSE);
export const receiveCreateHouse = createAction(RECIEVE_CREATE_HOUSE);

export const requestDeleteHouse = createAction(REQUEST_DELETE_HOUSE);

export const requestCreateLoan = createAction(REQUEST_CREATE_LOAN);
export const receiveCreateLoan = createAction(RECIEVE_CREATE_LOAN);

export const requestDeleteLoan = createAction(REQUEST_DELETE_LOAN);

export const addFile = createAction(ADD_FILE);

export const externalPatchUser = createAction(EXTERNAL_PATCH_USER);
