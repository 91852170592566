import React, { Component } from 'react';

import { reduxForm, formValueSelector  } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonPrimary } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';

import { Input, Select, AddressInput, ToggleButton } from 'components/Form';
import { PullRight } from 'components/Styled/Layout';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column } from '../ViewClient';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

import { required } from 'util/validation';

const phoneOptions = [
  { name: 'Mobile', value: 'Mobile' },
  { name: 'Landline', value: 'Landline' }
];

class BankDetailsForm extends Component {

  render() {
    const { handleSubmit, pristine, submitting, invalid, disabled } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <input hidden autoComplete="off" />
        <Row>
          <Column width='50%'>
            <Label>Bank Account Name</Label>
            <Input disabled={disabled} name='client.bankAccount.name'/>
          </Column>
          <Column width='20%'>
            <Label>BSB</Label>
            <Input disabled={disabled} name='client.bankAccount.bsb'/>
          </Column>
          <Column width='30%'>
            <Label>Account Number</Label>
            <Input disabled={disabled} name='client.bankAccount.number'/>
          </Column>

          {!disabled &&
            <Row>
              <PullRight>
                <ButtonPrimary className={(invalid || pristine) && 'disabled'} type='submit' width='100px' margin='0 80px 0 20px'><ButtonSpinner show={submitting}/>Save</ButtonPrimary>
              </PullRight>
            </Row>
          }
          <Column width='100%'>
            <Divider width='100%' />
          </Column>
        </Row>
        
      </form>
    );
  }
}


BankDetailsForm = reduxForm({
  form: 'editBankDetails',
  //enableReinitialize: true,
})(BankDetailsForm);

export default BankDetailsForm;