
import { takeEvery, call, put } from 'redux-saga/effects';

import {
  findClients,
  getUser,
  patchUser,
  createUser,
  createHouse,
  removeHouse,
  removeLoan,
  createLoan,
  createDocumentAPI,
  patchDocumentAPI,
  removeDocumentAPI,
} from './api';

import {
  receiveFindClients,
  receiveUser,
  receivePatchUser,
  receiveCreateUser,
  receiveCreateHouse,
  receiveCreateLoan,
  patchDocumentInStore,
  addDocumentToStore,
  removeDocumentFromStore,

  REQUEST_CREATE_HOUSE,
  REQUEST_DELETE_HOUSE,
  REQUEST_FIND_CLIENTS,
  REQUEST_USER,
  REQUEST_PATCH_USER,
  REQUEST_CREATE_USER,
  REQUEST_CREATE_LOAN,
  REQUEST_DELETE_LOAN,
  ADD_DOCUMENT,
  REMOVE_DOCUMENT,
  PATCH_DOCUMENT,
} from './actions';



function* callFindClients({ payload: { query, callback } }) {
  let result = yield call(findClients, query);
  yield put(receiveFindClients({ ...result, query }));
  if (callback)
    callback();
}

export function* findClientsSaga() {
  yield takeEvery(REQUEST_FIND_CLIENTS, callFindClients);
}

function* callGetUser({ payload: { query, callback } }) {
  let result = yield call(getUser, query);
  //debugger;
  yield put(receiveUser(result));
  callback && callback(result);
}

export function* getUserSaga() {
  yield takeEvery(REQUEST_USER, callGetUser);
}

function* callPatchUser({ payload: { data, callback, params } }) {
  delete data.lenders;
  delete data.nsrValues;
  delete data.globalSettings;
  delete data.team;
  data.sessionId = sessionStorage.tabID;
  let result = yield call(patchUser, { data, params } );
  if (!result.error) {
    yield put(receivePatchUser(result));
  }
  callback && callback(result);
}

export function* patchUserSaga() {
  yield takeEvery(REQUEST_PATCH_USER, callPatchUser);
}

function* callCreateUser({ payload: { data, callback } }) {
  data.sessionId = sessionStorage.tabID;
  let result = yield call(createUser, data);
  if (!result.error) {
    yield put(receiveCreateUser(result));
  }
  callback && callback(result);
}

export function* createUserSaga() {
  yield takeEvery(REQUEST_CREATE_USER, callCreateUser);
}

function* callCreateHouse({ payload: { callback, ...data } }) {
  let result = yield call(createHouse, data);
  if (!result.error) {
    yield put(receiveCreateHouse(result));
  }
  callback && callback(result);
}

export function* createHouseSaga() {
  yield takeEvery(REQUEST_CREATE_HOUSE, callCreateHouse);
}

function* callDeleteHouse({ payload: { id } }) {
  yield call(removeHouse, id);
}

export function* deleteHouseSaga() {
  yield takeEvery(REQUEST_DELETE_HOUSE, callDeleteHouse);
}

function* callCreateLoan({ payload: { callback, ...data } }) {
  let result = yield call(createLoan, data);
  if (!result.error) {
    yield put(receiveCreateLoan(result));
  }
  callback && callback(result);
}

export function* createLoanSaga() {
  yield takeEvery(REQUEST_CREATE_LOAN, callCreateLoan);
}

function* callDeleteLoan({ payload: { id } }) {
  yield call(removeLoan, id);
}

export function* deleteLoanSaga() {
  yield takeEvery(REQUEST_DELETE_LOAN, callDeleteLoan);
}

