
import React from "react";
import { Component } from "react";
import { connect } from "react-redux";

import styled, { css } from 'styled-components'
import Property from "./PropertyPDF";
import CurrentLoan from "./CurrentLoanPDF";

import ProposedOptions from "./ProposedOptionsPDF";

// import ProposedOptions from "../views/sections/ProposedOptions";

import _ from 'lodash';


class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      property: false,
      currentLoan: false,
      execSummary: false,
      additionalDetails: false,
    };
  }

  

  render() {
    const { data, index, overviewActivated, user } = this.props;

    return (
      <SectionContainer isPDF={true}>
        <PropertyLoanContainer>
          <Property
            tabId={data.id}
            loan={data.currentLoan}
            isView={data.propertyDetail.enabled}
            user={user}
          />
          {data.propertyDetail.enabled && data.currentLoan.enabled &&
            <Seperator/>
          }
          <CurrentLoan
            data={data.currentLoan}
            isView={data.currentLoan.enabled}
          />
        </PropertyLoanContainer>

        <ProposedOptions
          isOverview={overviewActivated}
          index={index}
          isPDF={true}
          tabId={data.id}
          data={data.loanComparison}
          currentLoan={data.currentLoan}
          user={user}
        />
      </SectionContainer>
    );
  }
}

const Seperator = styled.div`
  width: 80px;
`;

const PropertyLoanContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 560px) {
    display: block;
  }

  > form {
    width: 36%;

    @media (max-width: 1080px) {
      width: 40%;
    }

    @media (max-width: 900px) {
      width: 45%;
    }

    @media (max-width: 700px) {
      width: 50%;
    }

    @media (max-width: 560px) {
      width: 100%;
    }
  }
`;

const SectionContainer = styled.div`
  max-width: 920px;
  margin-top: -20px;
`;

export default connect()(Properties);
