import React, { useState } from 'react';
import { withSize } from 'react-sizeme';

export const Measurer = ({ children, onChange }) => {
  const [ heights, setHeights ] = useState({});

  const name = name => ({
    onSize: ({ height }) => {
      if (!height || height === heights[name]) return;
      const newHeights = { ...heights, [name]: height };
      onChange && onChange(newHeights, { [name]: true });
      setHeights(newHeights); 
    }
  });

  return children({ heights, name });     
};

export const ToMeasure = withSize
  ({ monitorHeight: true, monitorWidth: false })
  (props => <div {...props} />);