import React, { Component } from 'react';
import Context from './ContextOld';
import { Transition } from 'react-transition-group';

export class ModalContext extends Component {
  
  state = {
    isOpen: false,
    modalProps: {},
  }
  

  modalShow = props => {
    this.setState({isOpen: true, modalProps: props },
      () => setTimeout(() => {
        this.focusRef && this.focusRef.focus(); 
      }, 550)
    );
  }

  modalHide = () => {
    this.setState(state => ({...state, isOpen: false}));
  }

  modalContext = {
    show: this.modalShow,
    hide: this.modalHide,  
    focusOnStart: element => this.focusRef = element,
  }

  componentWillMount() {
    window.addEventListener('click', this.clickOffComponent, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickOffComponent, true);
  }

  clickOffComponent = e => {
    if (
      this.state.isOpen &&
      this.modalRef && 
      !this.modalRef.contains(e.target)
    ) {
      this.modalContext.hide();
    };
  }

  render() {
    const {onSubmit, children, animationTimeout} = this.props;
    const {modalProps, isOpen} = this.state;

    const Modal = this.props.modal;
    const modalContext = {...this.modalContext, submit: onSubmit, modalProps, ref: this.modalRef, isOpen };
    
    return (
      <Transition timeout={animationTimeout || 0} in={isOpen}>
        {trans =>
          <Context.Provider value={{ ...modalContext, transition: trans }}>
            <span ref={div => this.modalRef = div}>
              {trans !== 'exited' && 
                <Modal {...modalProps} modal={{ ...modalContext, transition: trans }} />
              }
            </span>
            {children}
          </Context.Provider>
        }
      </Transition>
      
    )
  }
}



