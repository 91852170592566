import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'components/Styled/Card';
import TextInput from 'components/Styled/TextInput';
import TextArea from 'components/Styled/TextArea';
import SearchBar from 'components/Common/SearchBar';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import _, { template } from 'lodash';
import crypto from 'crypto';

import { ToggleButton } from 'components/Styled/CheckBox';
import { requestFindDocuments, requestPatchDocument, requestCreateDocument, requestDeleteDocument, 
  requestFindDocumentTemplates, requestPatchDocumentTemplate, requestCreateDocumentTemplate, 
  requestDeleteDocumentTemplate, addDocToTemplate as addDoc, removeDocFromTemplate as removeDoc,
} from 'modules/documents/actions';

import { Heading, Label, ToggleContainer, BigBlueButton, Row, Column, PlainButton, Templates, Library, TemplateItems, 
  RequestColumn, ListItem, TemplateItem, LibraryRequest, BigTrashButton, ActionButtons, ButtonsLeft, Saved, Unsaved, 
  ButtonImage, Important } 
  from './Components/DocShared';

import { DocForm } from './Components/DocForms';
import { EditDocModal, EditTemplateModal, ViewDocModal } from './Components/DocModals';

import imgUpload from 'img/button/upload.svg';
import imgTemplate from 'img/button/template.svg';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

const DOC_LIBRARY = 1;
const TEMPLATES = 2;

const SupportingDocs = () => {
  const dispatch = useDispatch();
  
  const [ currentTab, setCurrentTab ] = useState(DOC_LIBRARY);
  const [ libraryDocId, setLibraryDocId ] = useState();
  const [ templateDocIdState , setTemplateDocIdState ] = useState();
  const [ templateIdState , setTemplateIdState ] = useState();
  const [ searchLibrary, setLibrarySearch ] = useState('');
  const [ searchTemplate, setTemplateSearch ] = useState('');
  const [ saveCount, setSaveCount ] = useState(0);
  const [ isSaved, setIsSaved ] = useState(true);
  const [ templateName, setTemplateName ] = useState();
  const [ templateDocuments, setTemplateDocuments ] = useState([]);
  const [ __ , setReload ] = useState();
  const { openModal, closeModal } = useContext(modalContext);
  const [ templateSearchText, setTemplateSearchText ] = useState('');
  const [ savingTemplate, setSavingTemplate ] = useState('');
  
  let library = useSelector(state => state.documents?.library);
  let templates = useSelector(state => state.documents?.templates);
  templates = (templates || [])
    .sort((a, b) => a.name?.localeCompare(b.name, 'en'))
    .map(template => {
      const documents = template.documents?.map(doc => library.find(x => x.id === doc.id) ?? doc) ?? [];
      return { ...template, documents };
    });
  const templateId = templates?.some(x => x.id === templateIdState) ? templateIdState : templates[0]?.id;
  const currentTemplate = templates.find(x => x.id === templateId);
  
  const templateChanged = currentTemplate
    && currentTab === TEMPLATES 
    && (currentTemplate?.name !== templateName
    || !documentsEqual(currentTemplate?.documents, templateDocuments));
  
  library.sort((a, b) => a.name?.localeCompare(b.name, 'en', { numeric: true }));
  const selectedDoc = library.some(doc => doc.id === libraryDocId) ? libraryDocId : library[0]?.id;
  
  let templateDocId;
  if (templateDocuments) {
    templateDocId = templateDocuments.some(x => x.id === templateDocIdState) ? templateDocIdState : null;
  }
  
  const startSave = () => {
    setSaveCount(saveCount + 1);
  };
  
  const reload = () => {
    setReload(Math.random());
  };

  const endSave = () => {
    setSaveCount(Math.max(saveCount - 1, 0));
  };

  const patchDocument = values => {
    startSave();
    dispatch(requestPatchDocument({ 
      data: values,
      callback: endSave,
    }));
    reload();
    // if (!currentTemplate) return;
    // setTemplateName(currentTemplate.name);
    // setTemplateDocuments(currentTemplate.documents ?? []);
  };

  const createDocument = values => {
    closeModal();
    values.id = crypto.randomBytes(20).toString('hex');
    dispatch(requestCreateDocument({ 
      data: values,
    }));
  };

  const addDocToTemplate = docId => {
    if (!templateId) return;
    let doc = library.find(x => x.id === docId);
    if (!doc) return;
    if (templateDocuments.some(x => x.name === doc.name)) return;
    doc.documentTemplateId = templateId;
    doc.new = true;
    // doc.type = 'template';
    // doc.id = crypto.randomBytes(20).toString('hex');
    setTemplateDocuments([...templateDocuments, doc]);
  };

  const removeDocFromTemplate = () => {
    setTemplateDocuments(templateDocuments.filter(doc => doc.id !== templateDocId));
  };

  const createTemplate = values => {
    closeModal();
    values.id = crypto.randomBytes(20).toString('hex');
    dispatch(requestCreateDocumentTemplate({ 
      data: values,
    }));
  };

  const deleteTemplate = () => {
    dispatch(requestDeleteDocumentTemplate({ 
      id: templateId,
    }));
    setTemplateDocuments([]);
    setTemplateName('');
  };

  const deleteDocument = () => {
    startSave();
    dispatch(requestDeleteDocument({ 
      id: selectedDoc,
      callback: endSave,
    }));
  };

  const changeIsSaved = dirty => {
    setIsSaved(!dirty);
  };

  const editTemplateDoc = () => {
    const document = templateDocuments.find(x => x.id === templateDocId);
    openModal({ 
      component: EditDocModal, 
      props: { 
        onSubmit: saveTemplateDoc, 
        document,
        heading: 'Edit Template Item' 
      }
    });
  };

  const viewTemplateDoc = () => {
    const document = templateDocuments.find(x => x.id === templateDocId);
    openModal({ 
      component: ViewDocModal, 
      props: {  
        document,
        heading: 'View Template Item' 
      }
    });
  };

  const openCustomDocModal = () => {
    if (!templateId) return;
    const id = crypto.randomBytes(20).toString('hex');
    openModal({ 
      component: EditDocModal, 
      props: { 
        onSubmit: saveTemplateDoc, 
        document: { id, type: 'template', documentTemplateId: templateId }, 
        heading: 'Custom Request' 
      } 
    });
  };

  const saveTemplateDoc = values => {

    values.isUrgent = values.isUrgent ? 1 : 0;
    values.isPropertyDoc = values.isPropertyDoc ? 1 : 0;
    values.isBankStatement = values.isBankStatement ? 1 : 0;
    
    let docs = templateDocuments;
    if (values.id) {
      docs = docs.filter(x => x.id !== values.id);
    } else {
      values.id = crypto.randomBytes(20).toString('hex');
    }
    setTemplateDocuments([...docs, values]);
    reload();
    closeModal();
  };

  const saveTemplate = async () => {
    const dispatches = [];
    setSavingTemplate(true);
    if (!currentTemplate) return;
    if (templateName !== currentTemplate.name) {
      dispatches.push(new Promise(resolve => {
        dispatch(requestPatchDocumentTemplate({ 
          data: { id: templateId, name: templateName },
          callback: resolve,
        }));
      }));
    }
    for (const doc of templateDocuments) {
      if (!(currentTemplate.documents ?? []).some(x => x.id === doc.id)) {
        if ((library ?? []).some(x => x.id === doc.id)) {
          dispatches.push(new Promise(resolve => {
            dispatch(addDoc({ 
              templateId,
              documentId: doc.id,
              callback: resolve,
            }));
          }));
        } else {
          dispatches.push(new Promise(resolve => {
            dispatch(requestCreateDocument({ 
              data: doc,
              callback: () => {
                dispatch(addDoc({ 
                  templateId,
                  documentId: doc.id,
                  callback: resolve,
                }));
              }
            }));
          }));
        }
      }
    }
    for (const doc of currentTemplate.documents ?? []) {
      if (templateDocuments.some(x => x.id === doc.id)) {
        const document = templateDocuments.find(x => x.id === doc.id);
        if (!documentEqual(doc, document)) {
          dispatches.push(new Promise(resolve => {
            dispatch(requestPatchDocument({
              data: document,
              callback: resolve,
            }));
          }));
        }
      } else {
        dispatches.push(new Promise(resolve => {
          if (doc.type === 'template') {
            dispatch(requestDeleteDocument({
              id: doc.id,
              callback: () => {
                dispatch(removeDoc({
                  documentId: doc.id,
                  templateId,
                  callback: resolve,
                }));
              },
            }));
          } else {
            dispatch(removeDoc({
              documentId: doc.id,
              templateId,
              callback: resolve,
            }));
          }
          
        }));
      }
    }
    await Promise.all(dispatches);
    setSavingTemplate(false);
    reload();

  };

  const selectTemplate = id => {
    setTemplateIdState(id);
    setTemplateDocIdState(null);
  };

  // useEffect(() => {
  //   dispatch(requestFindDocuments());
  //   dispatch(requestFindDocumentTemplates());
  // }, []);

  useEffect(() => {
    if (!currentTemplate) return;
    setTemplateName(currentTemplate.name);
    setTemplateDocuments(currentTemplate.documents ?? []);
    
  }, [templateId, currentTab]);

  const onKeyPress = e => {
    
    switch(e.code) {
    case 'ArrowUp': {
      if (currentTab === TEMPLATES) {
        if (templateDocIdState) setUp(e, templateDocId, templateDocuments, setTemplateDocIdState, 'template-doc-list');
        else {
          const list = templates
            .filter(x => x.name?.toLowerCase()?.includes(templateSearchText.toLowerCase()));
          setUp(e, templateId, list , setTemplateIdState, 'template-list');
        }
      } else {
        const list = library
          .filter(doc => doc.type === 'library')
          .filter(doc => doc.name?.toLowerCase()?.includes(searchLibrary));
        setUp(e, selectedDoc, list, setLibraryDocId, 'doc-library');
      }
      return;
    }

    case 'ArrowDown': {
      if (currentTab === TEMPLATES) {
        if (templateDocIdState) setDown(e, templateDocId, templateDocuments, setTemplateDocIdState, 'template-doc-list');
        else { 
          const list = templates
            .filter(x => x.name?.toLowerCase()?.includes(templateSearchText.toLowerCase()));
          setDown(e, templateId, list, setTemplateIdState, 'template-list');
        }
      } else {
        const list = library
          .filter(doc => doc.type === 'library')
          .filter(doc => doc.name?.toLowerCase()?.includes(searchLibrary));
        setDown(e, selectedDoc, list, setLibraryDocId, 'doc-library');
      }

      return;
    }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [templateId, templateDocIdState, selectedDoc, currentTab]);

  let docInitialValues = {};
  if (selectedDoc) {
    docInitialValues = library.find(doc => doc.id === selectedDoc);
    docInitialValues.isUrgent = docInitialValues.isUrgent ? 1 : '';
    docInitialValues.isPropertyDoc = docInitialValues.isPropertyDoc ? 1 : '';
    docInitialValues.isBankStatement = docInitialValues.isBankStatement ? 1 : '';
  }

  // const templateChanged = currentTemplate
  //   && (currentTemplate?.name !== templateName
  //   || !documentsEqual(currentTemplate?.documents, templateDocuments));

  return (<>
    <TabBar>
      <Tab active={currentTab === DOC_LIBRARY} onClick={() => setCurrentTab(DOC_LIBRARY)}>
        Doc Library
      </Tab>
      <Tab active={currentTab === TEMPLATES} onClick={() => setCurrentTab(TEMPLATES)}>
        Templates
      </Tab>
      <span style={{float: 'right', paddingTop: '25px'}}>
        {isSaved && !templateChanged ? <Saved/> : <Unsaved/>}
      </span>
    </TabBar>
    <Card minHeight='300px' minWidth='800px' maxWidth='1100px' style={{ borderTopLeftRadius: '0'}}>
      <Page active={currentTab === DOC_LIBRARY}>
        <Row>
          <Column width='60%'>
            <Heading>
              Supporting Document Library
              <ButtonsLeft name='library-document' active={selectedDoc != null} noMagnify={true} onDelete={deleteDocument}/>
            </Heading>
            <TemplateItems style={{ marginBottom: '10px' }} id='doc-library'>
              <RequestColumn>
                {library
                  .filter(doc => doc.type === 'library')
                  .filter(doc => doc.name?.toLowerCase()?.includes(searchLibrary))
                  .map(doc => 
                    <TemplateItem 
                      onClick={e => setLibraryDocId(doc.id)}
                      key={doc.id}
                      selected={selectedDoc === doc.id} 
                      isBankStatement={doc.isBankStatement} 
                      isPropertyDocument={doc.isPropertyDoc}
                      important={doc.isUrgent}
                    >{doc.name}
                    </TemplateItem>
                  )}
              </RequestColumn>
            </TemplateItems>
            <SearchBar
              placeholder='Enter your search query...'
              msDebounce={300} 
              onChange={value => setLibrarySearch(value.toLowerCase())}
              width='100%'
            />
            <PlainButton width='205px' type='button' onClick={() => openModal({ component: EditDocModal, props: { onSubmit: createDocument, document: { type: 'library'} } })}>
              Add New Item <ButtonImage src={imgUpload} />
            </PlainButton>
          </Column>
          <Column width='40%' paddingLeft='40px'>
            <Heading>Item Detail</Heading>
            <DocForm disabled={!library.length && !selectedDoc} onChangeState={changeIsSaved} form='settings-docs' initialValues={docInitialValues} onSubmit={patchDocument}/>
          </Column>
        </Row> 
      </Page>
      <Page active={currentTab === TEMPLATES}>
        <Row>
          <Column width='50%'>
            <Heading>Request Library</Heading>
            <Library>
              <ul>
                {library
                  .filter(doc => doc.type === 'library')
                  .filter(doc => doc.name?.toLowerCase()?.includes(searchTemplate))
                  .map(doc => 
                    <LibraryRequest 
                      onClick={() => addDocToTemplate(doc.id)}
                      key={doc.id}
                      active={!templateDocuments.some(x => x.id === doc.id)} 
                    >{doc.name}
                    </LibraryRequest>
                  )}
              </ul>
            </Library>
            <SearchBar
              placeholder='Enter your search query...'
              msDebounce={300} 
              onChange={value => setTemplateSearch(value.toLowerCase())}
              width='calc(100% - 70px)'
            />
            <PlainButton width='205px' type='button' onClick={openCustomDocModal}>
              Add Custom Request <ButtonImage src={imgUpload}/>
            </PlainButton>
          </Column>
          <Column width='50%'>
            <Heading>
              Template Items
              <ButtonsLeft 
                active={templateDocId && templateDocuments.some(x => x.id === templateDocId)} 
                onDelete={removeDocFromTemplate} 
                onMagnify={templateDocuments.find(x => x.id === templateDocId)?.type === 'template' ? editTemplateDoc : viewTemplateDoc}/>
            </Heading>
            <TemplateItems id='template-doc-list'>
              <RequestColumn>
                {templateDocuments
                  .sort((a, b) => a.name?.localeCompare(b.name, 'en'))
                  .map(doc => 
                    <TemplateItem 
                      onClick={() => setTemplateDocIdState(doc.id)}
                      key={doc.id}
                      selected={templateDocId === doc.id} 
                      isBankStatement={doc.isBankStatement} 
                      isPropertyDocument={doc.isPropertyDoc}
                      important={doc.isUrgent}
                    >{doc.name}
                    </TemplateItem>
                  )}
              </RequestColumn>
            </TemplateItems>
          </Column>
        </Row>
        <Row paddingTop='40px'>
          <Column width='50%'>
            <Heading>Templates</Heading>
            <Templates id='template-list'>
              {(templates ?? [])
                .sort((a, b) => a.name?.localeCompare(b.name, 'en'))
                .filter(x => x.name?.toLowerCase()?.includes(templateSearchText.toLowerCase()))
                .map((x, i) => 
                  <ListItem 
                    tabIndex={-1}
                    key={i} 
                    selected={templateId === x.id} 
                    onClick={() => selectTemplate(x.id)}
                    style={{ cursor: 'pointer'}}
                  >
                    {x.name}
                  </ListItem>
                )}
            </Templates>
            <SearchBar
              style={{ marginTop: '10px'}}
              placeholder='Enter your search query...'
              msDebounce={300} 
              onChange={setTemplateSearchText}
              width='calc(100% - 70px)'
            />
            <PlainButton type='button' onClick={() => openModal({ component: EditTemplateModal, props: { onSubmit: createTemplate } })} width='179px'>
              Add Template <ButtonImage src={imgTemplate}/>
            </PlainButton>
          </Column>
          <Column width='50%'>
            <Heading>
              Template Name
            </Heading>
            <TextInput value={templateName} onChange={e => setTemplateName(e.target.value)}/>
            <Row paddingTop='40px'>
              <Column width='65px'>
                <BigTrashButton disabled={!templateId} name='template' onDelete={deleteTemplate}/>
              </Column>
              <Column width='calc(100% - 65px)'>
                <BigBlueButton disabled={!templateId || !templateChanged || savingTemplate} type='button' onClick={saveTemplate}><ButtonSpinner show={savingTemplate} />Save Template</BigBlueButton>
              </Column>
            </Row>
            
          </Column>
        </Row>
      </Page>
    </Card>
    <div style={{ minWidth: '800px', maxWidth: '1100px' }}>
      <span style={{float: 'right', paddingTop: '10px'}}>
        {isSaved && !templateChanged ? <Saved/> : <Unsaved/>}
      </span>
    </div>
    
  </>);
};

export default SupportingDocs;

const documentsEqual = (x, y) => {
  x = (x ?? [])
    .sort((a, b) => a.name?.localeCompare(b.name, 'en', { numeric: true }))
    .map(z => ({
      id: z.id,
      type: z.type,
      name: z.name,
      description: z.description,
      isUrgent: !!z.isUrgent,
      isPropertyDoc: !!z.isPropertyDoc,
      isBankStatement: !!z.isBankStatement,
    }));

  y = (y ?? [])
    .sort((a, b) => a.name?.localeCompare(b.name, 'en', { numeric: true }))
    .map(z => ({
      id: z.id,
      type: z.type,
      name: z.name,
      description: z.description,
      isUrgent: !!z.isUrgent,
      isPropertyDoc: !!z.isPropertyDoc,
      isBankStatement: !!z.isBankStatement,
    }));
  
  return _.isEqual(x, y);
};

const documentEqual = (a, b) => {
  a = {
    id: a.id,
    type: a.type,
    name: a.name,
    description: a.description,
    isUrgent: !!a.isUrgent,
    isPropertyDoc: !!a.isPropertyDoc,
    isBankStatement: !!a.isBankStatement,
  };

  b = {
    id: b.id,
    type: b.type,
    name: b.name,
    description: b.description,
    isUrgent: !!b.isUrgent,
    isPropertyDoc: !!b.isPropertyDoc,
    isBankStatement: !!b.isBankStatement,
  };
  return _.isEqual(a, b);
};

const setUp = (e, id, list, set, elementId) => {
  const el = document.getElementById(elementId);
  if (!el.contains(document.activeElement)) return;
  const index = list.findIndex(x => x.id === id);
  if (index === -1) return;
  e.preventDefault();
  if (index - 1 >= 0) {
    const newId = list[index - 1]?.id;
    if (el) el.scrollTop = el.scrollTop - 38;
    set(newId);
  }
};

const setDown = (e, id, list, set, elementId) => {
  const el = document.getElementById(elementId);
  if (!el.contains(document.activeElement)) return;
  const index = list.findIndex(x => x.id === id);
  if (index === -1) return;
  e.preventDefault();
  if (index + 1 < list.length) {
    const newId = list[index + 1]?.id;
    if (el) el.scrollTop = el.scrollTop + 38;
    set(newId);
  }
};

const TabBar = styled.div`
  min-width: 800px;
  max-width: 1100px;
  margin-bottom: -1px;
  margin-top: 40px;
  overflow: visible;
`;

const Page = styled.div`
  display: none;
  ${p => p.active && css`
    display: block;
  `}  
`;

const Tab = styled.div`
  height: 50px;
  line-height: 50px;
  min-width: 188px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #ECECEC;
  border: 1px solid #D8DCE7;
  color: #4F4F4F;
  position: relative;
  cursor: pointer;

  ${p => p.active && css`
    background-color: #fff;
    border-bottom: none;
    color: #101922;
    cursor: default;

    &:after {
      content: '';
      background-color: #fff;
      /*   */
      height: 5px;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      z-index: 1000;
      
    }

    &:hover {
      border-bottom: none;
    }
  `}

  &:hover {
    background-color: #fff;
  }
`;