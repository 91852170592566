import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import Navigation from "components/Navigation/Navigation";
import Divider from "components/OrDivider";
import { NavLink } from "react-router-dom";
import {requestForgotPassword} from "modules/user/actions";

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestForgotPassword
  }, dispatch);
}

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = { email: '', submitted: false };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.email) {
      this.props.requestForgotPassword({email:this.state.email, callback:()=>{
        this.setState(()=>{
          return {submitted: true}
        });
      }});
    }
    return false;
  }

  render() {
    return (
    <div>
      <Navigation state="unactive" />
      <main className="user-authentication">
        <div className="modal single">
        {
          (this.state.submitted)?(
            <div>
              <h2>Password reset request submitted</h2>
              <h4>An email with a link to reset your password has been sent to: {this.state.email}<br/><br/></h4>
            </div>
          ):(
            <div>
              <h2>Don't remember your password?</h2>
              <h4>Enter your e-mail address below and we’ll send you a link to reset your password.</h4>
              <form onSubmit={this.handleSubmit}>
                <label>Email Address</label>
                <input name="email" placeholder="Enter Email" onChange={e => this.setState({ email: e.target.value })} />

                <button type="submit">Reset My Password</button>
              </form>
            </div>
          )
        }

          <div className="navigation back">
            <NavLink exact to="/login">
            ← Back to Sign In
            </NavLink>
          </div>


        </div>


      </main>
  </div>
  );
}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
