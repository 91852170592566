import React, { Component } from 'react';
import { getQueryVariables } from 'util/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  requestGetApplications,
} from 'modules/applications/actions';

import Form from './ApplicantsForm';

class ApplicationsApplicants extends Component {
  
  onSubmit = values => {
    alert('submited!');
    this.props.history.goBack();
  }

  async componentWillMount() {
    // const { applications, requestGetApplications } = this.props;
    // if (!applications.list) {
    //   await requestGetApplications();
    // } 
  }

  render() {
    const { applications } = this.props;
    
    const { id } = getQueryVariables(this.props.location.search);
    const currentApplication = applications.list.get(parseInt(id));

    return (
      <Form onSubmit={this.onSubmit} initialValues={{applicants: [currentApplication.client]}}/>
    );
  }
}

const mapStateToProps = ({applications}) => ({applications});

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestGetApplications,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (ApplicationsApplicants);