import React, { useEffect, Fragment } from 'react';

import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'; 
import { Row } from 'components/Styled/Layout';
import { Hide, FormCard, SelectField, TextField, SaveButton, MultiLineTextField, AddressField, MoneyField } from '../Components';
import initializeFormValues from 'util/initializeFormValues';

const FORM_NAME = 'submission-security';

const SecuritiesFormPure = ({
  handleSubmit, 
  submitting, 
  pristine, 
  loanPurpose, 
  currentStatus, 
  propertyOwner, 
  borrowExtra,
  application,
}) => {
  
  useEffect(() => {
    initializeFormValues(FORM_NAME, values => ({ ...values, application }));
  }, []);

  const hideMorgage = (loanPurpose === 'purposeConstruction') && (currentStatus === 'statusAlreadyOwnLand');

  return (
    <FormCard onSubmit={handleSubmit}>
      <Row>
        <SelectField label='Loan Purpose' name='application.meta.loanPurpose' width='130px' options={[
          { name: 'Purchase', value: 'purposePurchase' },
          { name: 'Refinance', value: 'purposeRefinance' },
          { name: 'Construction', value: 'purposeConstruction' },
        ]} />
        <SelectField label='Property Purpose' name='application.meta.propertyPurpose'  width='110px' options={[
          { name: 'To Live In', value: 'purposeToLiveIn' },
          { name: 'Investment', value: 'purposeInvestment' },
        ]}/>
        <SelectField label='Current Status' name='application.meta.currentStatus' width='200px' options={[
          { name: 'Still Looking', value: 'statusStillLooking' },
          { name: 'Found a Property', value: 'statusFoundAProperty' },
          { name: 'Already Own the Land', value: 'statusAlreadyOwnLand' },
        ]} />
        

        <AddressField label='Property Address' name='application.meta.propertyAddress'/>
        <MoneyField label='Approximate Purchase Price' name='application.meta.propertyValue' />
        <MoneyField label='Desired Loan Amount' name='application.dealValue' />

        <MoneyField hidden={hideMorgage} label='Current Mortgage (if any)' name='application.meta.propertyCurrentMortgage' />

        <MoneyField hidden={!hideMorgage} label='Approximate Construction Cost' name='application.meta.propertyConstructionCost' />
        
      </Row>

      <Hide when={loanPurpose !== 'purposeRefinance'}>
      <SelectField label='Borrow Additional Funds?' name='application.meta.propertyBorrowFunds' width='120px' options={[
          { name: 'No', value: 'No' },
          { name: 'Yes', value: 'Yes' }
        ]} />
        <Row>
          
          <Hide when={borrowExtra !== 'Yes'}>
            <TextField label='Reason' name='application.meta.propertyAdditionalFunds'/>
            <MoneyField label='Additional Funds Amount' name='application.meta.propertyAdditionalFundsAmount' />
          </Hide>
        </Row>
      </Hide>
      
      <Row>
        <SelectField label='Owned By' name='application.meta.propertyOwner' width='300px' options={[
          { name: 'The applicant only', value: 'user' },
          { name: 'The applicant\'s partner', value: 'partner' },
          { name: 'The applicant and their partner', value: 'user&partner' },
          { name: 'Other situation', value: 'other' },
        ]} />

        <TextField hidden={propertyOwner !== 'other'} label='Other Situation' name='application.meta.propertyOtherSituation'/>
      </Row>
      <SaveButton disabled={(submitting || pristine)} submitting={submitting}/>
    </FormCard>
  );
};

const select = formValueSelector(FORM_NAME);
const stateToProps = state => ({
  loanPurpose: select(state, 'application.meta.loanPurpose'),
  currentStatus: select(state, 'application.meta.currentStatus'),
  propertyOwner: select(state, 'application.meta.propertyOwner'),
  borrowExtra: select(state, 'application.meta.propertyBorrowFunds'),
});

const SecuritiesForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(SecuritiesFormPure);

export default connect(stateToProps) (SecuritiesForm);

