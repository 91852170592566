import React, { Component } from 'react';
import styled from 'styled-components';

import { Scrollbars } from 'react-custom-scrollbars';

import Card from 'components/Styled/Card';


class MultiSelectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: props.list,
      selectedIndexes: [],
      onChange: props.onChange || (()=>{}),
    }
  } 

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({ ...state, list: nextProps.list }));
  }

  toggleItem = async index => {
    const { list, selectedIndexes, onChange } = this.state;
    if (selectedIndexes.some(i => i === index)) {
      const newIndexes = selectedIndexes
        .filter(i => i !== index);

      await this.setState(state => ({ 
        ...state, 
        selectedIndexes: newIndexes 
      }));
    } else {

      await this.setState(state => ({ 
        ...state, 
        selectedIndexes: [...selectedIndexes, index] 
      }));
    }
    const selectedValues = list
      .filter((_, index) => this.state.selectedIndexes.some(i => i === index))
      .map(item => item.value);

    onChange(selectedValues);
  }

  clearSelection = () => {
    this.setState(state => ({ ...state, selectedIndexes: [] }));
  }

  componentWillMount() {
    this.props.clear(this.clearSelection);
  }

  render() {

    const { width, height } = this.props;
    const { list, selectedIndexes } = this.state;
    return (
      <ListContainer width={width} height={height}>
        <Scrollbars>
          {list.map((item, index) => {
            const isSelected = selectedIndexes.some(i => i === index);
            return (
              <ListItem
                key={index}
                onClick={() => this.toggleItem(index)}
                className={isSelected ? 'selected' : ''}>

                {item.name}

              </ListItem>
            )
          })}
        </Scrollbars>
      </ListContainer>
    );
  }
}

const ListContainer = styled(Card)`
  padding: 0;
`;

const ListItem = styled.div`
  color: #101922;
  padding: 10px 20px;
  cursor: pointer;
  transition: 300ms all;
  font-size: 14px;

  &.selected {
    background: #2291FF;
    color: white;
  }
`;

export default MultiSelectList;