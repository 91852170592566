import styled from 'styled-components';

export const TabPill = styled.span`
    font-size: 12px;
    border-radius: 50px;
    padding: 3px 12px;
    color: #ffffff;
    background: #727C8F;
    margin-left: 8px;
    transition: all 500ms;
`;

export const Tab = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 14px;
  color: #727C8F;
  cursor: pointer;

      
  
  &.current {
    color: #101922;
    border-bottom: solid 2px #2291FF;

    ${TabPill} {
        background-color: #2291ff!important;
      }
  }
    

  &:hover {
    border-bottom: solid 2px #2291FF;
  }
`;

export const TabBar = styled.div`
  height: ${({height}) => height || 60}px;
  width: 100%;
  border-bottom: solid 1px #D8DCE7;
  margin-bottom: ${({marginBottom}) => marginBottom || 40}px;

  > ${Tab} {
    width: ${({tabWidth}) => tabWidth || '125px'};
    height: ${({height}) => height ? (height / 2) + 7 : 37}px;
    margin: ${({height}) => height ? (height / 2) - 7 : 37}px 0 0 ${({tabMargin})=> tabMargin || 40}px;
  }
`;


