// src/views/Applications/index.js
import React, { Component, Fragment } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';


import { PageLoader } from 'components/Loader';
import { MultiSelect } from 'components/Common/MultiSelect';
import { Select } from 'components/Form';
import { PullRight } from 'components/Styled/Layout';
import { ButtonPlain } from 'components/Styled/Button';
import BaseSelect from 'components/Common/Select';

import Label from 'components/Styled/Label';
import { StageLists } from 'components/ApplicationsEligibility/';

import {
  requestMoveApplication,
  requestGetApplications
} from 'modules/applications/actions';

import { showUnfinished, app } from 'modules';
import ControlPanel from 'components/Form/ControlPanel';
import SearchBar from 'components/Common/SearchBar';
import SearchSelect from 'components/Common/SearchSelect';


const mapStateToProps = ({applications, user, brokers, lenders}) => ({
  applications,
  user,
  brokers,
  lenders
});



const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestGetApplications,
    requestMoveApplication
  }, dispatch);

class Eligibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      brokerId: null,
      processorId: null,
      tags: [],
      searchFilter: this.createSearchFilter([]),
      redirectTo: null,
      searchTerm: '',
      isCollapsed: [ false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, 
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, 
        false, false, false, false ],
    };
  }
  
  tagOptions = [
    {
      name: 'Today',
      value: 'today',
    },
    {
      name: 'Overdue',
      value: 'overdue',
    },
    {
      name: 'Tag 1',
      value: 'tag1',
    },
    {
      name: 'Tag 2',
      value: 'tag2',
    },
  ]

  redirect = (to) => this.setState({ redirectTo: to });

  componentWillMount() {
    // const { applications, user } = this.props;
    // if (applications && !applications.loading) {
    //   this.setState({isLoaded: true, searchFilter: this.createSearchFilter(this.state.tags, null)});
    //   return;
    // } 
    // this.props.requestGetApplications(() =>{
    //   this.setState({isLoaded: true, searchFilter: this.createSearchFilter(this.state.tags, null)});
    // });
    
  }

  componentDidMount() {
    this.setState({ brokerId: this.props.user.meta.brokerId, processorId: this.props.user.meta.processorId}, () => {
      this.setState({ initialStateLoaded: true });
    });

    if (this.props.applications.list) {
      this.setSearchTerm('');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applications.loading && !this.props.applications.loading) {
      this.setSearchTerm('');
    }
  }

  selectBroker = ({ brokerId, processorId }) => {
    this.setState({ 
      brokerId,
      processorId,
      //searchFilter: this.createSearchFilter(this.state.tags, brokerId, this.state.lenderId, this.state.processorId), 
    }, () => {
      this.setSearchTerm(this.state.searchTerm);
    });
    
  }

  setSearchTerm = searchTerm => {
    console.log({ searchTerm });
    this.setState({ searchTerm }, 
      () => this.setState({
        searchFilter: this.createSearchFilter(this.state.tags, this.state.brokerId, this.state.lenderId, this.state.processorId, this.props.page), 
      }, () => {
        this.setState({
          isCollapsed: Object.values(this.props.applications?.stages ?? {})
            .filter(x => x.page === this.props.page)
            .sort((a, b) => a.stageIndex > b.stageIndex ? 1 : -1)
            .map(stage => {
              const apps = Object.values(stage.applications ?? {});
              return !this.state.searchFilter(apps).length;
            }),
        });
      })
    );
  
  }

  toggleCollapsed = index => {
    this.setState(state => {
      let newIsCollapsed = { ...state.isCollapsed };
      newIsCollapsed[index] = !state.isCollapsed[index];
      const newState = { ...state, isCollapsed: newIsCollapsed };
      return newState;
    });
  }

  setLender = value => {
    this.setState({ 
      lenderId: value,
      searchFilter: this.createSearchFilter(this.state.tags, this.state.brokerId, value, this.state.processorId, this.props.page), 
    }, () => this.setState({
      isCollapsed: Object.values(this.props.applications?.stages ?? {})
        .filter(x => x.page === this.props.page)
        .sort((a, b) => a.stageIndex > b.stageIndex ? 1 : -1)
        .map(stage => {
          const apps = Object.values(stage.applications ?? {});
          return !this.state.searchFilter(apps).length;
        }),
    }));
  }

  setSortBy = value => {
    this.setState({ 
      sortBy: value,
    });
  }
  
  render() {
    const { page, applications, user } = this.props;
    const { searchFilter, brokerId, processorId, redirectTo, initialStateLoaded} = this.state;
    if (redirectTo) {
      this.props.history.push('/' + page);
      return <Redirect to={redirectTo}/>;
    }

    const isLoaded = (applications && (!applications.loading  || page === 'deals' && !applications.dealsLoading));
    if (!isLoaded || !initialStateLoaded) return (
      <main  className="main client-add">
        <PageLoader />
      </main>
    );

    const brokerOptions = (this.props.brokers ?? [])
      .filter(x => x.isBroker)
      .map(({ id, name }) => ({ name, value: id }) );

    const processorOptions = (this.props.brokers ?? [])
      .filter(x => x.isProcessor)
      .map(({ id, name }) => ({ name, value: id }) );

    let lenderOptions = (this.props.lenders.list ?? [])
      .map(({ id, name }) => ({ name, value: id }))
      .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
  
    lenderOptions = [ { name: 'All', value: 'all' }, ...lenderOptions ];

    const sortOptions = [
      { name: 'Deal Task Due Date', value: 'task-due-date'},
      { name: 'Due Date', value: 'due-date'},
      { name: 'Loan Amount', value: 'loan-amount'},
      { name: 'Offset/Redraw Amount', value: 'offset-amount'},
      { name: 'Referrals', value: 'referrals'},
    ];

    return (
      <main className="main applications" >
        <div className="main-container">
          <div style={{paddingLeft: '40px'}}>
            <div style={{ marginBottom: '40px' }}>
              {/* {showUnfinished && 
                <div style={{display: 'inline-block'}}>
                  <label className='label'>Filter by Tags</label>
                  <MultiSelect 
                    width='350px' 
                    options={this.tagOptions} 
                    placeholder='No tags selected'
                    onChange={tags => this.setState({ 
                      searchFilter: this.createSearchFilter(tags, brokerId, this.state.lenderId, processorId, page), 
                      tags })}/>
                </div>
              } */}
              <div style={{display: 'inline-block', marginLeft: showUnfinished ? '20px' : '0'}}>
                <ControlPanel callback={this.selectBroker}>
                  
                  <div style={{ display: 'inline-block'}}>
                    <Label>Assigned Broker</Label>
                    <Select
                      name='brokerId'
                      width='220px' 
                      options={[{name: 'All', value: null}, {name: 'None Set', value: -1}, ...brokerOptions]} 
                    />
                  </div>
                  {user.userType !== 'processor' &&
                    <div style={{ display: 'inline-block', marginLeft: '20px' }}>
                      <Label>Assigned Processor</Label>
                      <Select
                        name='processorId'
                        width='220px' 
                        options={[{name: 'All', value: null}, {name: 'None Set', value: -1}, ...processorOptions]} 
                      />
                    </div>
                  }
                    
                  
                </ControlPanel>
              </div>
              <div style={{display: 'inline-block', marginLeft: '20px'}}>
                <Label>Lender</Label>
                <SearchSelect 
                  options={lenderOptions} 
                  dontFilter={['all']} 
                  isSearchable={true}
                  value={this.state.lenderId ?? 'all'}
                  onChange={this.setLender}
                  width='220px' 
                  height='300px'
                />
              </div>

              <div style={{ display: 'inline-block', marginLeft: '20px' }}>
                <Label>Sort By</Label>
                <BaseSelect
                  width='220px' 
                  options={sortOptions} 
                  value={this.state.sortBy}
                  onChange={this.setSortBy}
                />
              </div>
              
              <div style={{display: 'inline-block', position: 'relative', marginLeft: '20px', top: '-12px'}}>
                <SearchBar
                  placeholder='Search'
                  onChange={this.setSearchTerm}
                  msDebounce={50}
                />
              </div>


              <PullRight>
                <ButtonPlain 
                  margin='26px 40px 0 0'
                  onClick={() => this.redirect( page + '/archive')}
                >
                  View Archive
                </ButtonPlain>
              </PullRight>
              
              
            </div>
          </div>   
          
          <StageLists toggleCollapsed={this.toggleCollapsed} isCollapsed={this.state.isCollapsed} searchFilter={searchFilter} url={'/' + page} page={page} sortBy={this.state.sortBy}/>
          
        </div>
      </main>
    );
  }

  keyWordSearch = app => {

    const search = this.state.searchTerm?.trim()?.toLowerCase();
    if (!search) return true;
    const terms = search.split(' ');
    const matchList = [
      app.user_name?.toLowerCase(),
      app.user_email?.toLowerCase(),
      app.user_phone?.toLowerCase(),
      app.broker_name?.toLowerCase(),
      app.processor_name?.toLowerCase(),
      app.referrer_name?.toLowerCase(),
      app.applicationNo?.toLowerCase(),
      // ...(app.products ?? []).map(x => x.bank_name?.toLowerCase()),
      // ...(app.products ?? []).map(x => x.bank?.toLowerCase())
    ];

    for (const term of terms) {
      let foundMatch = false;
      for (const value of matchList) {
        if (!value) continue;
        if (value.includes(term)) foundMatch = true;
      }
      if (!foundMatch) return false;
    }
    return true;
  }

  createSearchFilter = (tags, brokerId, lenderId, processorId, page) => {
    const predicates = tags.map(tag => {
      switch(tag) {
      case 'today':
        return it => it.daysDue === 0;
      case 'overdue':
        return it => it.daysDue < 0;
      default:
        return it => it.tags.some(t => t === tag);
      }
    });

    let brokerFilter = brokerId ? (id) => id === brokerId : () => true;
    if (page === 'new-leads') 
      brokerFilter = brokerId ? (id) => (id === -1 || id === brokerId) : () => true;
    const processorFilter = processorId ? (id) => id === processorId : () => true; 
    const lenderFilter = (lenderId && lenderId !== 'all') ? app => {
      let id = app.lenderId;
      if (!id) {
        const product = app.products?.[0];
        if (product) id = product.bank;
      }
      return id === lenderId;
    } : () => true;

    //orEach will string the predicates together with ||
    return (apps = []) => apps
      .map((app, index) => ({...app, index}))
      .filter(app => 
        orEach(app, predicates)
        && processorFilter(app.processorId || -1) 
        && brokerFilter(app.brokerId || -1)
        && lenderFilter(app)
        && this.keyWordSearch(app)
        && !app.isArchived);
  }
  
  getBrokers() {
    let brokerMap = new Map();
    (this.props.applications.list || []).forEach(app => 
      app.broker && brokerMap.set( app.brokerId, app.broker.fullName )
    );
    let brokers = Array.from(brokerMap, ([value, name]) => ({ value, name }));
    if (brokers.length > 0) brokers.sort((a, b) => sortAlphabeticly(a.name, b.name));
    return brokers;
  }
}

const sortAlphabeticly = (_a, _b) => {
  const a = _a && _a.toLowerCase();
  const b = _b && _b.toLowerCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const orEach = (value, predicates) => {
  if (predicates.length === 0) return true;
  for (let p of predicates) {
    if (p(value)) return true;
  }
  return false;
};

export default connect(mapStateToProps, mapDispatchToProps) (Eligibility);


