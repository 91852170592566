import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled, { css } from 'styled-components';

import TextInput from 'components/Styled/TextInput';
import Debouncer from 'util/debouncer';
import { requestPatchUser } from 'modules/clients/actions';

const debouncer = new Debouncer();

let IntroNames = () => {
  const dispatch = useDispatch();
  const introLetter = useSelector(state => state.clients.current?.meta.miniproposal?.overview?.introLetter ?? {});
  const user = useSelector(state => state.clients.current ?? {});
  const { heading } = introLetter;
  
  useEffect(() => {
    if (heading && heading.startsWith('Dear ') && heading.endsWith(',')) {
      const name = heading.substring(5, heading.length - 1);
      setNames(name);
    } else {
      setNames(user.firstName);
    }
  }, []);

  const onChange = e => {
    const value = e.target.value;
    console.log('Change:', value);
    setNames(value);
    if (heading === `Dear ${value},`) return;
    debouncer.run(() => {
      console.log('Saving Name:', value);
      dispatch(requestPatchUser({
        data: {
          id: user.id,
          meta: { miniproposal: { overview: { introLetter: {
            heading: `Dear ${value},`
          }}}}
        },
        params: {
          mergeMeta: true,
        }
      }));
    }, 2000);
  };

  const [names, setNames] = useState('');
  return (
    <Container>
      <Heading>Intro Names &quot;Dear {names},&quot;</Heading>
      <TextInput width='230px' value={names} onChange={onChange}/>
    </Container>
  );
};

const Heading = styled.h2`
  margin-bottom: 10px;
  color: #575e6c;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

const Container = styled.div`
  margin-bottom: 40px;
`;

export default IntroNames;