import React, { Component, PureComponent, Fragment, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import { connect } from 'react-redux'; 

import { Link, withRouter } from 'react-router-dom';

import imgBank from 'img/temp/banks/ADE.png';
import { ModalContext, ModalShow } from 'components/Modal/old';

import imgEditPen from 'img/button/edit-pen.png';
import imgQualfier from 'img/illustrations/qualifier.svg';
import imgWarning from 'img/icons/o-warning.svg';

// import { LenderModal } from './';
import { ModelessButton } from 'components/Modal/ModelessButton';
import { Column as BaseColumn, Row, PullLeft, PullRight } from 'components/Styled/Layout';
import Card from 'components/Styled/Card';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';

import { userService, applicationService, showUnfinished } from 'modules';
import Label from 'components/Styled/Label';
import SearchSelect from 'components/Common/SearchSelect';
import TextInput from 'components/Styled/TextInput';
import { ButtonPrimary, ButtonPlain, ButtonImageLeft } from 'components/Styled/Button';
import BankLogo from 'shared/PDF/BankLogo';
import { requestUpdateApplications } from 'modules/applications/actions';
import ReferrerModal from 'components/Common/ReferrerModal';
import { Loader, LoaderContainer } from 'components/Loader';
import html from 'shared/utils/html';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Form, reduxForm } from 'redux-form';
import { ToggleButton } from 'components/Styled/CheckBox';
import { Select } from 'components/Form';
import { requestPatchUser } from 'modules/clients/actions';
import formatNumber from 'util/formatNumber';
import imgArchive from 'img/button/archive.svg';
import CommissonModal from './Modals/CommissionModal';
import Spinner from 'components/Styled/ImageLoading';
// import BankLogo from 'components/Common/BankLogo';

// capitialize first letter
const capFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

class SideBarBase extends Component {

  state = { brokers: [] }

  // async componentDidMount() {
  //   const { data } = await userService.find({
  //     query: {
  //       $limit: 1000,
  //       userType: ['broker'],
  //       $sort: {
  //         'lastName': 1,
  //       },#D3DEE9
  //     }
  //   });
  //   this.setState({ brokers: data });
  // }


  render() {
    const { application, client, rootPath, currentPath, changeApp, lender, documents, page, history, user } = this.props;

    if (!application) {
      return '';
    }

    const isAtRoot = (currentPath === rootPath);

    const isProcessor = user.userType === 'processor';
    const userDocuments = documents[application.userId];

    let documentCount = null;
    if (userDocuments && client){
      let [docs, propertyDocs] = split(userDocuments, x => !x.isPropertyDoc);
    
      documentCount = propertyDocs.length;
      for (const doc of docs) {
        for (var i = 0; i < (client.numberOfApplicants ?? 1); i++) {
          if (doc.meta.applicants[i]) documentCount++;
        }
      }
    }
    
    let searchNumber = '';

    if (client && client.hasDuplicateNumber) {
      searchNumber = client.phoneNumber.replace(/\D/g,'');
      if (searchNumber.startsWith('61')) searchNumber = searchNumber.replace('61', '');
    }
    
    return (
      <div className='bar-left'>
        <div className='card-left'>
          {client && client.hasDuplicateNumber &&
            <DupPhoneWarning onClick={() => history.push('../clients?search=' + searchNumber)}/>
          }
          <div style={{ width: '100%', height: '20px'}}>

            <Link to={`${isAtRoot ? 'view/' : ''}client?id=${application.id}`}>
              <PullLeft>
                <Heading linkDisabled={true} baseUrl={rootPath} id={application.id} to='client' replace={!isAtRoot} title='Client Details' />
              </PullLeft>
              <PullRight>
                <EditButton to={`${isAtRoot ? 'view/' : ''}client?id=${application.id}`}/>
              </PullRight>
            </Link>
            
          </div>
          
          
          <div className='details' style={{ marginTop: '14px'}}>
            <p>{application.user_name}</p>
            <p><a href={`tel:${application.user_phone}`}>{application.user_phone}</a></p>
            {application.user_email && !application.user_email.includes('@fake.com') &&
              <p><a href={`mailto:${application.user_email}`}>{application.user_email}</a></p>
            }
            {/* <p>{client.meta.address}</p> */}
          </div>

        </div>
        {(page === 'new-leads' || page === 'in-progress') &&
          <DealDetailsWrapper initialValues={{
            client,
            application
          }}/>
        }

        <Link replace={!isAtRoot} to={`${rootPath}/files?id=${application.id}`} style={{color:'#101922'}}>
          <div className='card-left' >
            <Row>
              <PullLeft>
                <div className='title'>Client Documents</div>
              </PullLeft>
              <PullRight>
                <div className="pill-blue">{documentCount ?? '    '}</div>
              </PullRight>
            </Row>  
          </div>
        </Link>

        
        <div className='card-left'>
          <Column>
            <LenderModalButton application={application} lender={lender}> 
              <PullLeft>
                <div className='title'>Lender Details</div>
              </PullLeft>
              <PullRight>
                {/* <ModalContext modal={rootPath === '/applications/view' ? ApplicationsLenderModal : LenderModal} animationTimeout={500} onSubmit={(data, hide) => hide()}>
                  {showUnfinished && <ModalShow as={EditButtonNoLink} to='./' />}
                </ModalContext> */}
                <EditButtonNoLink />
              </PullRight>
            </LenderModalButton>
            
          </Column>

          
          <div style={{ minHeight: '50px' }}>
            {lender && <>
              {lender.id === 'other' ?
                <BankTitle>{lender.name}</BankTitle>
                :
                <BankLogo key={lender.name} width={200} height={80} maxFill={0.6} product={{ bank: lender.id, bank_name: lender.name }} />
              }
              {/* <div className="title">{lender.name}</div> */}
              <div className='details'>
                
                {lender.brokerPhone &&
                  <p>P: {lender.brokerPhone}</p>
                }
                {/* {lender.brokerId &&
                  <p>Broker Id: {lender.brokerId}</p>
                } */}
                {lender.brokerEmail &&
                  <p>
                    E: <a href={'mailto:' + lender.brokerEmail }>{lender.brokerEmail}</a>
                  </p>
                }
                

              </div>
              {/* <div className="white-space"></div>
              <div className="details">
                <p>Mark Lai</p>
                <p><a href="tel:+0409845351">0409845351</a></p>
                <p><a href="mailto:mark_lai@fakemail.com">mark_lai@fakemail.com</a></p>
              </div> */}
            </>}
          </div>
          
          {/* <img style={{ marginBottom: '12px' }} height={40} src={imgBank} /> */}
            
        </div>
        
        <div className="card-left">
          <ApplicationNoButton application={application}>
            <Column>
              <PullLeft>
                <span className="title">Application Number</span>
              </PullLeft>
              <PullRight>
                <EditButtonNoLink />
              </PullRight>
            </Column>
          </ApplicationNoButton>
          
          <div className="details">
            <p>{application.applicationNo || 'Not set'}</p>
          </div>
        </div>

        {showUnfinished &&
          <div className="card-left">
            <Heading title='Funding' baseUrl={rootPath} id={application.id} to='funding' replace={!isAtRoot}/>
            <div className="details">
              <p>Purchase</p>
              <p>Total Lending: $616,000</p>
              <p>LVR: 80%</p>
              <p>Sp1: $566,000/P&I/2yr Fix @ 4.19%</p>
              <p>Sp1: $50,000/P&I/Basic Var @ 4.19%</p>
              <p>Package: Liberty (Star)</p>
            </div>
          </div>
        }


        <div className="card-left">
          {isProcessor ?
            <Column width='220px'>
              <PullLeft>
                <span className="title">Broker</span>
              </PullLeft>
            </Column>
            :
            <ModelessButton 
              buttonDefault={props =>  
                <Column width='220px' { ...props }>
                  <PullLeft>
                    <span className="title">Broker</span>
                  </PullLeft>
                  <PullRight>
                    <EditButtonNoLink />
                  </PullRight>
                </Column>} 
              modeless={BrokerOptions}
              data={{brokers: this.props.brokers.filter(x => x.isBroker), changeApp}}
            />
          }
          <div className="details">
            <p>{ application.broker ? application.broker.name : 'None Selected' }</p>
            {application.broker && 
              <Fragment>
                <p><a href={`tel:${application.broker.mobile}`}>{application.broker.mobile}</a></p>
                <p><a href={`mailto:${application.broker.email}`}>{application.broker.email}</a></p>
              </Fragment>
            }
          </div>
        </div>

        <div className="card-left">
          {isProcessor ?
            <Column>
              <PullLeft>
                <span className="title">Referred By</span>
              </PullLeft>
            </Column>
            :
            <ReferrerModalButton application={application}>
              <Column>
                <PullLeft>
                  <span className="title">Referred By</span>
                </PullLeft>
                <PullRight>
                  <EditButtonNoLink />
                </PullRight>
              </Column>
            </ReferrerModalButton>
          }
          
          
          <div className="details">
            <p>{ application.referrer ? (application.referrer.firstName ?? '') + ' ' + (application.referrer.lastName ?? '') : 'None Selected' }</p>
            {application.referrer && 
              <Fragment>
                <p><a href={`tel:${application.referrer.mobile}`}>{application.referrer.mobile}</a></p>
                {!application.referrer.email.includes('@fake.com') &&
                  <p><a href={`mailto:${application.referrer.email}`}>{application.referrer.email}</a></p>
                }
                <Row width='240px'>
                  <Column width='50%' style={{ paddingBottom: '0' }}>
                    <ReferrerHeading>Partner Type</ReferrerHeading>
                    <p>{application.referrer.isProfessionalReferrer ? 'Professional' : 'Client'}</p>
                  </Column>
                  <Column width='50%' style={{ paddingBottom: '0' }}>
                    <ReferrerHeading>Referral Tier</ReferrerHeading>
                    <p>{application.referrer.referralTier ? capFirstLetter(application.referrer.referralTier) : 'Not Set'}</p>
                  </Column>
                  <Column width='50%' style={{ paddingBottom: '0' }}>
                    <ReferrerHeading>Referrer Commission</ReferrerHeading>
                    <p>{application.commissionAmount ? '$' + formatNumber(application.commissionAmount) : 'Not Set'}</p>
                  </Column>
                  <Column width='50%' style={{ paddingBottom: '0' }}>
                    <ReferrerHeading>Referree Commission</ReferrerHeading>
                    <p>{application.referreeCommissonAmount ? '$' + formatNumber(application.referreeCommissonAmount) : 'Not Set'}</p>
                  </Column>
                </Row>
                <AddCommissonButtion application={application} user={user}/>
              </Fragment>
            }
          </div>
        </div>

        <div className="card-left">
          <TaskModalButton application={application}>
            <Column>
              <PullLeft>
                <span className="title">Deal Tasks</span>
              </PullLeft>
              <PullRight>
                <EditButtonNoLink />
              </PullRight>
            </Column>
          </TaskModalButton>
          
          <div className="details">
            <p>{ application.currentTaskDueDate ? 
              `${application.currentTaskDescription || 'Due Date'}: ${moment(application.currentTaskDueDate).format('Do MMMM YYYY')}` 
              : 
              'No tasks' }
            </p>
          </div>
        </div>

        {showUnfinished &&
          <div className="card-left">
            <Column>
              <PullLeft>
                <span className="title">Valuation</span>
              </PullLeft>
              <PullRight>
                <ModelessButton 
                  buttonDefault={EditButtonNoLink} 
                  modeless={ValuationOptions} 
                />
              </PullRight>
            </Column>
            <div className="details">
              <p>4.2 Valuation Ordered</p>
            </div>
          </div>
        }
        {showUnfinished &&
          <div className="card-left">
            <Column>
              <PullLeft>
                <span className="title">Referrer</span>
              </PullLeft>
              <PullRight>
                <EditButtonNoLink />
              </PullRight>
            </Column>
            <div className="details">
              <p>None Selected</p>
            </div>
          </div>
        }

        <div className="card-left" >
          <DischargeModalButton application={application}>
            <Column>
              <PullLeft>
                <span className="title">Discharge Form</span>
              </PullLeft>
              <PullRight>
                <EditButtonNoLink />
              </PullRight>
            </Column>
          </DischargeModalButton>

          <div className="details">
            <p>{ application.isFastRefi ? 
              'Fast Refi' : !application.dischargeSent ?
                'Discharge not yet sent' : 'Discharge sent ' + moment(application.dischargeSentDate).format('Do MMMM YYYY')
            }</p>
          </div>
        </div>
        
        {page !== 'new-leads' && page !== 'in-progress' &&
          <DealDetailsWrapper initialValues={{
            client,
            application
          }}/>
        }

        {client?.meta?.eligibility_last_page >= 7 &&
          <div className="card-left" style={{ height: '105px'}}>
            <Link to={`${isAtRoot ? 'view/' : ''}submission?id=${application.id}`}>
              <Column width='50%' >
                <div className="title">Qualifier</div>
                <DateTitle style={{fontWeight: 'bold'}}>Date of submission</DateTitle>
                <DateText>{client.meta.eligibilitySubmitDate ? moment(client.meta.eligibilitySubmitDate).format('Do MMMM YYYY') : '―'}</DateText>
              </Column>
              <Column width='50%'>
                <PullRight>
                  <QualifierButton to={`${isAtRoot ? 'view/' : ''}submission?id=${application.id}`}/>
                </PullRight>
              </Column>
            </Link>
          </div>          
        }

        
        {/* {!client.meta &&  
          <Loading/>
        } */}
      </div>
    );
  }
}

const stateToProps = ({ brokers, documents, user }) => ({ brokers, documents: documents.users, user });

export const SideBar = withRouter(connect(stateToProps)(SideBarBase));

const DateTitle = styled.div`
  color: #101922; 
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
`;

const DateText = styled.div`
  color: #101922;
  font-size: 12px;
  margin-top: 2px;
`;

const BankTitle = styled.div`
  font-size: 16px;
`;

const  ReferrerModalButton = ({ application, ...props }) => {
  const { openModal } = useContext(modalContext);
  return (
    <div 
      style={{ cursor: 'pointer'}}
      onClick={() => openModal({
        component: ReferrerModal,
        props: {
          application,
        }
      })} 
      {...props}
    />
  );
};

const  TaskModalButton = ({ application, ...props }) => {
  const dispatch = useDispatch();
  const { openModal } = useContext(modalContext);
  return (
    <div 
      style={{ cursor: 'pointer'}}
      onClick={() => openModal({
        component: TaskModal,
        props: {
          initialValues: application,
          onSubmit: values => {
            dispatch(requestUpdateApplications({
              id: application.id,
              ...values,
            }));
          }
        }
      })} 
      {...props}
    />
  );
};

const  DischargeModalButton = ({ application, ...props }) => {
  const dispatch = useDispatch();
  const { openModal } = useContext(modalContext);
  return (
    <div 
      style={{ cursor: 'pointer'}}
      onClick={() => openModal({
        component: DischargeModal,
        props: {
          initialValues: application,
          onSubmit: values => {
            dispatch(requestUpdateApplications({
              id: application.id,
              ...values,
            }));
          }
        }
      })} 
      {...props}
    />
  );
};

const  ApplicationNoButton = ({ application, ...props }) => {
  const dispatch = useDispatch();
  const { openModal } = useContext(modalContext);
  return (
    <div 
      style={{ cursor: 'pointer'}}
      onClick={() => openModal({
        component: ApplicationNoModal,
        props: {
          initialValues: application,
          onSubmit: values => {
            dispatch(requestUpdateApplications({
              id: application.id,
              ...values,
            }));
          }
        }
      })} 
      {...props}
    />
  );
};

const LenderModalButton = ({ application, lender, ...props }) => {
  const { openModal } = useContext(modalContext);
  return (
    <div 
      style={{ cursor: 'pointer' }}
      onClick={() => openModal({
        component: LenderModal,
        props: {
          application,
          defaultLender: lender,
        }
      })}
      {...props} 
    />
  );
};

const ModalRow = styled.div`
  margin-bottom: 20px;
`;

const LenderModal = ({ modal, application, defaultLender }) => {

  const dispatch = useDispatch();
  const lenders = useSelector(state => state.lenders?.list);
  const user = useSelector(({ user }) => user);
  const profile = user.profile;
  let brokerName;
  if (profile)
    brokerName = profile.firstName + ' ' + profile.lastName;

  const [ lender, setLender ] = useState(application.lenderId || defaultLender?.id);
  const [ lenderName, setLenderName ] = useState(application.lenderName);

  let lenderOptions = lenders
    .map(({ id, name }) => ({ name, value: id }))
    .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
  
  lenderOptions = [ { name: 'Other', value: 'other' }, ...lenderOptions ];

  const canContinue = !!(lender && lender !== 'other') || !!(lender === 'other' && lenderName); 

  const onSubmit = () => {
    const noteLenderName = lender !== 'other' ? lenders.find(x => x.id === lender)?.name : lenderName;

    dispatch(requestUpdateApplications({
      id: application.id,
      lenderId: lender,
      lenderName,
      note: {
        brokerId: 'system',
        body: html`Lender changed to <b>${noteLenderName}</b> ${brokerName ? 'by ' + brokerName : ''}`
      }
    }));
    modal.close();
  };

  return (
    <ModalBox width='450px'>
      <ModalRow>
        <Label>Please select a lender</Label>
        <SearchSelect 
          options={lenderOptions} 
          dontFilter={['other']} 
          isSearchable={true}
          value={lender}
          onChange={setLender}
          width='368px' 
          height='300px'
        />
      </ModalRow>
      {lender === 'other' &&
        <ModalRow>
          <Label>What is the lender name?</Label>
          <TextInput value={lenderName} onChange={e => setLenderName(e.target.value)}/>
        </ModalRow>
      }
      <ButtonPrimary onClick={onSubmit} disabled={!canContinue} width='100%' margin='20px 0 0 0'>Continue</ButtonPrimary>
    </ModalBox>
  );
};

const TaskModalBase = ({ modal, onSubmit, handleSubmit, initialValues }) => {
  
  let initialDueDate = initialValues.currentTaskDueDate;
  if (initialDueDate && moment(initialDueDate).isValid()) {
    initialDueDate = new Date(initialDueDate);
  } else {
    initialDueDate = Date.now();
  }
  
  const [dueDate, setDueDate] = useState(initialDueDate);
  const [description, setDescription] = useState(initialValues.currentTaskDescription);

  const submit = () => {
    modal.close();
    onSubmit({ currentTaskDueDate: moment(dueDate), currentTaskDescription: description });  
  };

  const remove = () => {
    modal.close();  
    onSubmit({ currentTaskDueDate: null,  currentTaskDescription: null });
  };

  const addDays = n => {
    const result = new Date();
    result.setDate(result.getDate() + n);
    setDueDate(result);
  };
  
  return (
    <ModalBox style={{ width: '500px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Deal Tasks</SmallHeading>
        </Row>
        <Row margin='0 0 10px 0'>
          <Label>Description</Label>
          <TextInput onChange={e => setDescription(e.target.value)} value={description}/>
        </Row>
        <Label>Due Date</Label>
        <Row margin='0 0 20px 0'>
          <Column width='45%' margin='0 10% 0 0'>
            
            <DatepickerContainer>
              <DatePicker width='100%' dateFormat='dd/MM/yyyy' selected={dueDate} onChange={date => setDueDate(date)}/>
            </DatepickerContainer>
          </Column>
          <Column width='45%'>
            <ButtonPlain type='button' width='100%'onClick={() => addDays(2)}>2 Days From Now</ButtonPlain>
          </Column>
        </Row>  
        <Row style={{ marginTop: '-20px'}}> 
          <Column width='45%' margin='0 10% 0 0'>
            {initialDueDate ? 
              <ButtonPrimary style={{ paddingBottom: '0'}} background='#FF4E4C' type='button' onClick={remove} width='100%'>Remove</ButtonPrimary>
              :
              <ButtonPrimary style={{ paddingBottom: '0'}} background='#FF4E4C' type='button' onClick={modal.close} width='100%'>Cancel</ButtonPrimary>
            }
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Update</ButtonPrimary>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};
 
const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

const TaskModal = reduxForm({ form: 'task-due-date'})(TaskModalBase);

const ApplicationNoBase = ({ modal, onSubmit, handleSubmit, initialValues }) => {
  
  const [applicationNo, setApplicationNo] = useState(initialValues.applicationNo);

  const submit = () => {
    modal.close();
    onSubmit({ applicationNo });  
  };
  
  return (
    <ModalBox style={{ width: '500px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Application Number</SmallHeading>
        </Row>
        <Row margin='0 0 10px 0'>
          <TextInput onChange={e => setApplicationNo(e.target.value)} value={applicationNo}/>
        </Row>
        <Row style={{ marginTop: '30px'}}> 
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPrimary style={{ paddingBottom: '0'}} background='#FF4E4C' type='button' onClick={modal.close} width='100%'>Cancel</ButtonPrimary>
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Update</ButtonPrimary>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};
 
const ApplicationNoModal = reduxForm({ form: 'application-no'})(ApplicationNoBase);

function split(arr = [], fn) {
  const a = [], b = [];
  for (const x of arr)
    fn(x) ? a.push(x) : b.push(x);
  return [a, b];
}

const ApplicationsLenderModal = props => (
  <LenderModal maxSelected={1} {...props}/>
);

const Column = styled(BaseColumn)`
  padding-bottom: 20px;
  //width: 240px;
`;

const ImgEdit = () => (
  <img
    width={12}
    height={12}
    src={imgEditPen} 
    className='edit-pen'
  />
);

const Heading = ({ title, baseUrl, id, replace, to, linkDisabled }) => (
  <Link {...(linkDisabled ? { replace, to: `${baseUrl}/${to}?id=${id}` } : {to: ''}) }>
    <div style={{height: '25px'}}>
      <PullLeft>
        <div className='title'>{title}</div>
      </PullLeft>
      <PullRight>
        {!linkDisabled && <ImgEdit />}
      </PullRight>
    </div> 
  </Link>
);

const NoLinkHeading = ({ title }) => (
  <div style={{height: '25px'}}>
    <PullLeft>
      <div className='title'>{title}</div>
    </PullLeft>
  </div> 
);

const EditButton = ({ to, ...props }) => 
  <Link to={to || ''} {...props}>
    <img
      width={12}
      height={12}
      src={imgEditPen} 
      className='edit-pen'
    />
  </Link>;

const QualifierButton = ({ to, ...props }) => 
  <Link to={to || ''} {...props}>
    <img
      width={65}
      height={65}
      src={imgQualfier} 
      className='edit-pen'
    />
  </Link>;

const TaskBar = styled(Card)`
  padding: 10px 0;
  position: absolute;
  top: 25px;
  left: ${p => p.width ? ((20 - p.width / 2) + (p.offsetLeft ?? 0) + 'px') : '-55px'};
  width: ${p => p.width ? (p.width + 'px') : '150px'};
  z-index: 120;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 15px 1px rgba(216,220,231,0.65);

  &:after {
    content: "";
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    position: absolute;
    top: -5px;
    left: ${p => p.width ? ((p.width / 2 - 6) + 'px') : '69px'};
    filter: drop-shadow(0px -1.5px 0px #D8DCE7);
    
    z-index: 5;
  }
`;

const TaskBarOption = styled.div`
  width: 100%;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background-color: #f3f3f3
  }
`;

const BrokerOptions = ({ brokers, changeApp, modal }) => {
  
  const onClick = async brokerId => {
    modal.hide();
    const broker = brokers.find(b => b.id === brokerId);
    changeApp({ brokerId, broker, broker_name: broker.name });
  };

  return (
    <TaskBar width={200} offsetLeft={190}>
      {brokers && brokers.map(({ id, name }) => (
        <TaskBarOption key={id} onClick={() => onClick(id)} >{name}</TaskBarOption>
      ))}
    </TaskBar>
  );
};
      

const ValuationOptions = () => (
  <TaskBar>
    <TaskBarOption>Estimate</TaskBarOption>
    <TaskBarOption>Ordered</TaskBarOption>
    <TaskBarOption>Evaluated</TaskBarOption>
  </TaskBar>
);

export function getQueryVariables(query) {
  let values = {};
  if (query) {
    var vars = query.split('?')[1].split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      values[pair[0]] = pair[1];
    }
  }
  return values;
}


const EditButtonNoLink = styled.img.attrs({
  width: 12,
  height: 12,
  src: imgEditPen,
})`
  position: relative;
  bottom: 2px;
  margin-left: 20px;
  cursor: pointer;
`;

const LoadingContainer = styled.div`
  position: relative;
  top: 38px;
  width: 100%;
  height: 100px;
    
  ${LoaderContainer} {
    position: relative;
    top: calc(50% - 30px);
  }
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <Loader/>
    </LoadingContainer>
  );
};

const DatepickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
      input {
        width: 100%;
        vertical-align: top;
        color: #101922;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0; 
        height: ${p => p.height || '40px'};
        border: 1px solid #d8dce7;
        border-radius: 4px;
        padding: 0 12px 0 12px;
        transition: all 300ms;

        &:focus {
          border: 1px solid #2291FF !important;
          box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
        }

        &.error {
          border: 1px solid rgba(255,0,0,0.5) !important;
          box-shadow: inset 0 0 0 2px rgba(255,0,0,0.2) !important;
        }

        &[readonly] {
          cursor: default;
        }

        &:disabled {
          background-color: #d8dce75e !important;
          color: #545454;
          cursor: no-drop;
        }
      }
  }
`;

const DischargeModalBase = ({ modal, onSubmit, handleSubmit, initialValues }) => {
  
  const initialDueDate = initialValues.dischargeSentDate;
  const [dischargeSentDate, setDischargeSendDate] = useState(initialDueDate ? new Date(initialDueDate) : Date.now());
  const [dischargeSent, setDischargeSent] = useState(initialValues.dischargeSent);
  const [isFastRefi, setIsFastRefi] = useState(initialValues.isFastRefi);

  const submit = () => {
    modal.close();
    onSubmit({ dischargeSentDate: new Date(dischargeSentDate), dischargeSent, isFastRefi });  
  };

  const changeIsFastRefi = value => {
    if (value) setDischargeSent(false);
    setIsFastRefi(value);
  };
  
  return (
    <ModalBox style={{ width: '500px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Discharge Form</SmallHeading>
        </Row>
        <Row margin='0 0 20px 0'>
          <Label>Fast Refi</Label>
          <ToggleButton checked={isFastRefi} onChange={e => changeIsFastRefi(e.target.checked)}/>
        </Row>
        <Row margin='0 0 20px 0'>
          <Label>Discharge Sent</Label>
          <ToggleButton checked={dischargeSent} disabled={isFastRefi} onChange={e => setDischargeSent(e.target.checked)}/>
        </Row>
        <Row margin='0 0 20px 0'>
          {!isFastRefi && dischargeSent &&
            
            <Column margin='0 10% 0 0'>
              <Label>Date Sent</Label>
              <DatepickerContainer>
                <DatePicker disabled={isFastRefi || !dischargeSent} width='100%' dateFormat='dd/MM/yyyy' selected={dischargeSentDate} onChange={date => setDischargeSendDate(date)}/>
              </DatepickerContainer>
            </Column>
              
          }
        </Row>
        <Row style={{ marginTop: '-20px'}}> 
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPrimary style={{ paddingBottom: '0'}} background='#FF4E4C' type='button' onClick={modal.close} width='100%'>Cancel</ButtonPrimary>
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Update</ButtonPrimary>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};
 

const DischargeModal = reduxForm({ form: 'discharge-form'})(DischargeModalBase);

const DealDetailsBase = (props) => {

  const settings = useSelector(state => state.user.globalSettings);
  
  let combinedIncomeOptions = [];
  if (settings) {
    combinedIncomeOptions = (settings.income_brackets ?? [])
      .map((bracket, index) => ({ name: bracket.name, value: index + 1 }));
    combinedIncomeOptions = [
      { name: 'Not Set', value: 0 },
      ...combinedIncomeOptions
    ];
  }
  
  return (

    <Row width='220px' style={{ marginTop: '30px'}}>
      <Column width='50%' padding='0 20px 0 0'>
        <Label>Deal Priority</Label>
        <Select height='30px' name='application.priority' options={[
          { name: 'Not Set', value: ''},
          { name: 'Low', value: 'low' },
          { name: 'Medium', value: 'medium'},
          { name: 'High', value: 'high'}
        ]}/>
      </Column>
      <Column width='50%' padding='0 20px 0 0'>
        <Label>Income</Label>
        <Select height='30px' name='client.incomeBracket' options={combinedIncomeOptions}/>
      </Column>
      <Column width='50%' padding='0 20px 0 0'>
        <Label>Property Count</Label>  
        <Select height='30px' name='client.additionalProperties' options={[
          { name: '1', value: 'None' },
          { name: '2', value: '1' },
          { name: '3', value: '2' },
          { name: '4', value: '3' },
          { name: '5', value: '4' },
          { name: '6', value: '5' },
          { name: '7+', value: '6+' },
        ]}/>
      </Column>
      <Column width='50%' padding='0 20px 0 0'>
        <Label>SMS Verified</Label>
        <Select height='30px' name='client.isMobileVerified' options={[
          { name: 'No', value: false },
          { name: 'Yes', value: true },
        ]}/>
      </Column>
    </Row>

  );
};

const DealDetailsWrapper = props => {
  const dispatch = useDispatch();
  return (
    <div className='card-left' style={{ paddingRight: '0px'}}>
      <div className='title'>Deal Details</div>
      {props.initialValues.client?.id ? 
        <DealDetails dispatch={dispatch} {...props} />
        :
        <Spinner style={{ marginTop: '30px', marginBottom: '20px', paddingRight: '30px'}}/>
      }
    </div>
  );  
};

const DealDetails = reduxForm({ 
  form: 'deal-details',
  onChange: (values, _, props, prevValues) => {
     
    let userPatch = null;
    if (!prevValues || !prevValues.application || !prevValues.client) return;
    if (prevValues.application.priority !== values.application.priority) {
      props.dispatch(requestUpdateApplications({
        id: values.application.id,
        priority: values.application.priority,
      }));
    } else if (prevValues.client.incomeBracket !== values.client.incomeBracket) {
      userPatch = { incomeBracket: values.client.incomeBracket };
    } else if (prevValues.client.additionalProperties !== values.client.additionalProperties) {
      userPatch = { additionalProperties: values.client.additionalProperties };
    } else if (prevValues.client.isMobileVerified !== values.client.isMobileVerified) {
      userPatch = { isMobileVerified: values.client.isMobileVerified };
    }
    if (userPatch) {
      props.dispatch(requestPatchUser({
        data: {
          id: values.client.id,
          ...userPatch,
        }
      }));
    }
  }
})(DealDetailsBase);

const DupPhoneWarning = styled.img.attrs({
  src: imgWarning,
})`
  position: absolute;
  top: 50px;
  right: 40px;
  width: 30px;

  cursor: pointer;
`;

const ReferrerHeading = styled.div`
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CommissonButton = styled(ButtonImageLeft)`
  padding-left: 40px;
  padding-right: 10px;
  margin-right: 20px;
  background-size: 20px 20px;
  background-position-x: 16px;
  background-position-y: 8px;
  background-image: url(${imgArchive});
  background-repeat: no-repeat;
`;

const AddCommissonButtion = ({ application, user }) => {
  const { openModal } = useContext(modalContext);

  if (application.commissionAmount == null && application.referreeCommissonAmount == null) {
    return (
      <CommissonButton width='100%' onClick={() => openModal({ 
        component: CommissonModal, 
        props: { 
          user,
          type: 'add',
          deal: application,
        }
      })}>Add commission payment</CommissonButton>
    );
  } else {
    return (
      <CommissonButton width='100%' onClick={() => openModal({ 
        component: CommissonModal, 
        props: { 
          user,
          type: 'edit',
          deal: application,
          initialValues: {
            commissionAmount: application.commissionAmount, 
            referreeCommissonAmount: application.referreeCommissonAmount,
          },
        } 
      })}>Edit commission payment</CommissonButton>
    );
  }
};