// src/modules/users/sagas.js

import { takeEvery, call, put } from 'redux-saga/effects';

import {
  logout,
  login,
  auth,
  forgotPassword,
  resetPassword,
  clientLogin,
  patchUser,
 } from './api';

import {
  receiveAuth,
  receiveLogout,
  requestAuth,
  receivePatchSelf,
  

  REQUEST_PATCH_SELF,
  REQUEST_LOGIN,
  REQUEST_LOGOUT,
  REQUEST_AUTH,
  REQUEST_FORGOT_PASSWORD,
  REQUEST_PASSWORD_RESET,
  REQUEST_CLIENT_LOGIN
} from './actions';

import {requestFindBrokers} from '../brokers/actions';

function* callLogin({ payload: { data, redirect } }) {
  let result = yield call(login, data);
  if (!result.accessToken) {
    redirect ({
      success: false,
      message: "Invalid username or password"
    });
  }
  yield put(requestAuth(()=>{}));
  yield put(requestFindBrokers(()=>{}));
  redirect ({
    success: true
  });
}

export function* loginSaga() {
  yield takeEvery(REQUEST_LOGIN, callLogin);
}

function* callLogout() {
  yield call(logout);
  yield put(receiveLogout({}));
}

export function* logoutSaga() {
  yield takeEvery(REQUEST_LOGOUT, callLogout);
}

function* callAuth({ payload }) {
  const user = yield call(auth);
  yield put(receiveAuth(user));
  payload();
}

export function* authSaga() {
  yield takeEvery(REQUEST_AUTH, callAuth);
}

function* callForgotPassword({ payload }) {
  let message = yield call(forgotPassword, { email: payload.email });
  payload.callback();
}

export function* forgotPasswordSaga() {
  yield takeEvery(REQUEST_FORGOT_PASSWORD, callForgotPassword);
}

function* callResetPassword({ payload }) {
  let result = yield call(resetPassword, payload.data);
  payload.callback(result);
}

export function* passwordResetSaga() {
  yield takeEvery(REQUEST_PASSWORD_RESET, callResetPassword);
}

function* callClientLogin({ payload }) {
  let result = yield call(clientLogin, payload.data);
  payload.callback(result);
}

export function* clientLoginSaga() {
  yield takeEvery(REQUEST_CLIENT_LOGIN, callClientLogin);
}

function* callPatchUser({ payload: { data, callback } }) {
  let result = yield call(patchUser, data);
  if (!result.error) {
    yield put(receivePatchSelf(result));
  }
  callback && callback(result);
}

export function* patchUserSaga() {
  yield takeEvery(REQUEST_PATCH_SELF, callPatchUser);
}