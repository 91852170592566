import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading, Body as BodyBase, Container as ContainerBase} from './Shared';

export default ({ user }) => {

  const introLetter = user.meta.miniproposal.overview?.introLetter;
  const heading = introLetter?.heading ??  `Dear ${user.firstName},`;
  const body = introLetter?.body ?? '';

  return (
    <Container>
      <Heading dangerouslySetInnerHTML={{ __html: sanitizeHtml(heading)}} />
      <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body)}} />
    </Container>
  );
};

const Body = styled(BodyBase)`

  ul, ol {
    margin-top: 0;
    li {
      margin-bottom: 0;
    }
  }
`;

const Container = styled(ContainerBase)`
  padding-right: 70px;
  background-color: #F7F7F7;
`;