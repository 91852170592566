import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestClientLogin } from 'modules/user/actions';
import { clientAppUrl } from 'modules';
import { isIOS } from 'util/getDevice';
import styled from 'styled-components';

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestClientLogin
  }, dispatch);

class TableItemComponent extends Component {

  shouldComponentUpdate(nextProps) {
    if (this.props.broker.id === nextProps.broker.id)
      return false;
    return true;
  }
  render() {
    const { broker, onClick, ...props } = this.props;
    
    return (
      <Row onClick={onClick} {...props}>
        <Cell width='10%'>{broker.overviewPosition || ''}</Cell>
        <Cell width='11%'>{broker.firstName}</Cell>
        <Cell width='11%'>{broker.lastName}</Cell>
        <Cell width='11%'>{broker.title}</Cell>
        <Cell width='15%'>{broker.email}</Cell>
        <Cell width='12%'>{broker.mobile}</Cell>
        <Cell width='12%'>{broker.landline}</Cell>
        <Cell width='18%'>{broker.calendlyUrl}</Cell>
      </Row>);
  }
}

export const TableItem = connect(null, mapDispatchToProps) (TableItemComponent);

const Row = styled.div`
  display: flex;
  background-color: #F6F6FA;
  
  &:nth-child(even) {
    background-color: white;
  }

  &:hover {
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.2));
  }
`;

const Cell = styled.div`
  &:first-child {
    padding-left: 20px;
  }
  width: ${p => p.width};
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  vertical-align: middle;
  height: 70px;
  border-bottom: 1px solid #D8DCE7;
  padding-top: 28px;
`;