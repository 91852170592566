import React from 'react';
import styled from 'styled-components';

import { Row, PullLeft, PullRight } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';
import { ButtonPlain } from 'components/Styled/Button';
import { Column } from 'components/Styled/Layout';
import Card from 'components/Styled/Card';

import imgTick from 'img/button/check-small.png';
import imgCross from 'img/button/cross-red.png';
import imgDowload from 'img/button/download-dark.png';
import imgChat from 'img/button/chat-dark.png';


export const ReviewView = () => (<div style={{ margin: '40px 40px 10px 40px' }}>
  <Row>
    <PullLeft><Txt size='20px' marginTop='8px' marginBottom='15px' weight='500'>Client Documents</Txt></PullLeft>
    <PullRight><ButtonPlain height='30px' fontSize='11px'>Download All Attachments</ButtonPlain></PullRight>
  </Row>
  <div style={{ marginRight: '-30px' }}>
    <Row>
      <RequestBox />
      <RequestBox />
      <RequestBox />
      <RequestBox />
      <RequestBox />
      <RequestBox />
    </Row>
  </div>

</div>);

const RequestBox = () => (
  <Column width='33.3333%'>
    <Card width='220px' minHeight='237px' padding='0' style={{margin: '0px 30px 30px 0'}}>
      <div style={{height: '125px', borderBottom: 'solid 1px #D8DCE7', padding: '20px 20px 0px 20px'}}>
        <Txt center size='15px' lineHeight='20px' weight='600'>Drivers Licence Front & Back</Txt>
        <Txt center size='13px' marginBottom='8px'>By Joel Collins</Txt>
        <Txt center noOverflow size='12px' color='#727C8F' noWrap>Certified copy of drivers licence and also a cool way to show off that you can actually drive unlike myself...</Txt>
      </div>
      <div style={{height: '40px', borderBottom: 'solid 1px #D8DCE7'}}>
        <Txt center size='13px' color='#727C8F' marginTop='11px'><WarningGrey>!</WarningGrey>Awaiting Upload</Txt>
      </div>
      <div style={{width: '100%', padding:'14px 20px',display: 'flex', justifyContent: 'space-between'}}>
        <ButtonPlain width='32px' height='32px'><IconCrossSmall/></ButtonPlain>
        <ButtonPlain width='32px' height='32px'><IconChatSmall/></ButtonPlain>
        <ButtonPlain width='32px' height='32px'><IconDownloadSmall/></ButtonPlain>
        <ButtonPlain width='32px' height='32px'><IconTickSmall/></ButtonPlain>

      </div>
    </Card>
  </Column>
);


const IconChatSmall = styled.img.attrs({
  src: imgChat,
  width: 18,
})`
  position: relative;
  top: 2px;
  left: -4px;
`;


const IconDownloadSmall = styled.img.attrs({
  src: imgDowload,
  width: 16,
})`
  position: relative;
  top: 2px;
  left: -3px;
`;

const IconTickSmall = styled.img.attrs({
  src: imgTick,
  width: 20,
})`
  position: relative;
  top: 3px;
  left: -5px;
`;


const IconCrossSmall = styled.img.attrs({
  src: imgCross,
  width: 16,
})`
  position: relative;
  top: 2px;
  left: -3px;
`;

const WarningGrey = styled.span`
  display: inline-block;
  position: relative;
  top: 5px;
  min-width: 17px;
  height: 17px;
  color: white;
  margin-right: 10px;
  margin-bottom: 14px;
  background-color: #727C8F;
  border-radius: 17px;
  vertical-align: middle;
  text-align: center;
  line-height: 17px; 
  font-size: 13px;
`;



