import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import BankLogoBase from './BankLogo';
import parseNumber from '../utils/parseNumber';
import { Container as ContainerBase } from './Shared';

const RecommendedLenders = ({ banks = [], loans = [], user, type }) => {

  const lendingOverview = user.meta.miniproposal?.overview?.lendingOverview ?? {};

  const loanbaseCashback = user.meta.miniproposal?.overview?.loanbaseCashback ?? {};
  
  const lenderCashback = user.meta.miniproposal?.overview?.lenderCashback ?? {};

  const lenders = user.lenders;

  const loansTotal = loans
    .map(l => l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0);

  let weightedIIR = loans
    .map(l => l.newLoan.iir * l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0) / loansTotal;
  weightedIIR = (weightedIIR ?? 0).toFixed(2);

  let weightedTrueRate = loans
    .map(l => parseFloat(l.newLoan.trueRate) * l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0) / loansTotal;
  weightedTrueRate = (weightedTrueRate ?? 0).toFixed(2);
  
  let upfrontFees = loans
    .map(l => Math.max( parseNumber(l.newLoan.approxUpfrontFee) - (l.newLoan.upfrontRebate ?? 0), 0))
    .reduce((a, b) => a + b, 0);
  upfrontFees = (upfrontFees ?? 0).toFixed(0);
  
  let ongoingFees = loans
    .map(l => l.newLoan.ongoing_fees)
    .reduce((a, b) => parseNumber(a) + parseNumber(b), 0);
  ongoingFees = (ongoingFees ?? 0).toFixed(0);

  const trailRebates = loans
    .map(x => x.newLoan.trailRebate)
    .filter(x => !!x);

  const hasTrailRebate = !!trailRebates.length;

  let rebateSectionCount = 0;
  if (hasTrailRebate) rebateSectionCount++;
  if (lenderCashback.isActive) rebateSectionCount++;
  if (loanbaseCashback.isActive) rebateSectionCount++;

  let sectionCount = 1;
  if (lendingOverview.aggregateLending === true) sectionCount++;
  if (lendingOverview.aggregateFees !== false) sectionCount++;

  let showPiggy = sectionCount !== 3 ;
  if (type !== 'atp') {
    showPiggy = showPiggy && (rebateSectionCount === 0 || rebateSectionCount === 3);
  }

  let blankColumns = 3 - sectionCount;
  if (showPiggy) blankColumns -= 1;

  return (
    <Container>
      <FlexRowLending>
        <FlexColumnBank>
        <SmallHeading>{type === 'atp' ? 'Lenders' : 'Recommended Lenders'}</SmallHeading>
          <Banks>
            {Object.keys(banks).map(id => (
              <BankLogo 
                key={id}
                width={135}
                height={60}
                maxFill={0.6}
                product={{ bank: id, bank_name: lenders.find(l => l.id === id)?.name }} 
                alt="bank logo" 
              /> 
            ))}
          </Banks> 

        </FlexColumnBank>
          
        {lendingOverview.aggregateLending === true &&
          <FlexColumnLending>
            <SmallHeading>Aggregate Interest Rates</SmallHeading>

            <AggregateRate>
              <Rate>
                <Span>{lendingOverview.weightedIIR || weightedIIR}</Span>
                <Postfix>% p.a.</Postfix>
              </Rate>
              <Title>Weighted Interest Rate</Title>
            </AggregateRate>

            <AggregateRate>
              <Rate>
                <Span>{lendingOverview.weightedTrueRate || weightedTrueRate}</Span>
                <Postfix>% p.a.</Postfix>
              </Rate>
              <Title>Weighted True Rate</Title>
            </AggregateRate>

          </FlexColumnLending>
        }

        {lendingOverview.aggregateFees !== false &&
          <FlexColumnFees>
            <SmallHeading>Aggregate Lender Fees</SmallHeading>

            <AggregateRate>
              <Rate>
                $<Span>{lendingOverview.upfrontFees || upfrontFees}</Span>
              </Rate>
              <Title>Approximate Upfront Fees{type === 'atp' ? '*' : <ReferenceMark>†</ReferenceMark>}</Title>
            </AggregateRate>

            <AggregateRate>
              <Rate>
                $<Span >{lendingOverview.ongoingFees || ongoingFees}</Span>
              </Rate>
              <Title>Annual Fees</Title>
            </AggregateRate>

          </FlexColumnFees>
        }  

        {Array(blankColumns).fill().map(() =>  <FlexColumnFees />)}

        {showPiggy &&
          <FlexColumnFees>
            <img width={190} src={require('../img/illustrations/piggybank.png')} alt="checkeligibilityhelptimer"/>
          </FlexColumnFees>
        }
      </FlexRowLending> 
    </Container>
  );
};

export default RecommendedLenders;

const Container = styled(ContainerBase)`
  padding: 37px 0 0 0;
`;

const FlexColumn = styled.div`
  display: inline-block;
  width: ${p => p.width || '33%'};
`;

const FlexRow = styled.div`
  ${p => p.width && css`width: ${p.width};`}
  display: flex;
  margin: ${p => p.margin || '40px 0 40px 0'};
  font-family: LatoPDF, sans-serif;
`;

const SmallHeading = styled.h2`
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 16px !important;
`;

const FlexRowLending = styled(FlexRow)`
  margin: 0 0;
  justify-content: space-between;
`;


const FlexColumnBank = styled(FlexColumn)`
  border-right: 1px solid #D8DCE7;
  padding-right: 10px;
  width: calc(30% - 10px);
`;

const FlexColumnLending = styled(FlexColumn)`
  width: calc(33% - 10px);
`;

const FlexColumnFees = styled(FlexColumn)`
  width: calc(33% - 10px);

  img {
    object-fit: contain;
  }
`;

const Banks = styled.div`
`;

const ReferenceMark = styled.span`
  font-size: 66%;
  vertical-align: top;
`;

const BankLogo = styled(BankLogoBase)`
  margin-right: 40px;
  margin-top: 5px;
  margin-bottom: 20px;
  object-fit: contain;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Rate = styled.span``;
const Postfix = styled.span``;
const Title = styled.div``;

const AggregateRate = styled.div`
  margin-bottom: 20px;

  ${Rate} {
    color: #2291FF;
    font-weight: 700;
    font-size: 26px;

    ${Postfix} {
      font-weight: 400;
      font-size: 12px;
      margin-left: 3px; 
    }
  }

  ${Title} {
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
    color: #727C8F;
    display: block;
    font-family: 'Lato', sans-serif;
  }

`;

const Span = styled.span`
  display: inline-block;
`;