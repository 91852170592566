import React from 'react';
import PropTypes from 'prop-types';

// total -- int is the total number of tasks to show
// completed -- int is the number of tasks already completed
const TaskProgressBar = ({ stages = [], currentStageId = 0}) => {
  const currentIndex = stages.findIndex(x => x.id === currentStageId)
  return (
    <div className='task-progress-bar'>
      {
        stages.map((stage, index) => (
          <Task
            key = {index}
            content = {stage.name ?? index + 1}
            complete = {index <= currentIndex} 
            first = {index === 0}
            last = {index === stages.length - 1}
          />
        ))
      }
    </div>
  );
};

TaskProgressBar.propTypes = {
  stages: PropTypes.array.isRequired,
  currentStageId: PropTypes.number.isRequired,
};


const Task = ({ complete, first, last, content }) => (
  <div 
    className = {'task' + 
      (complete ? ' complete' : ' incomplete') +
      (first ? ' first' : '') +
      (last ? ' last' : '')
  }>
    <div className = 'centering'>
      <div>{content}</div>
    </div>
  </div>
);

export default TaskProgressBar;