import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  position: relative;
  box-sizing: border-box; 
  width: ${p => p.width || '100%'};
  min-width: ${p => p.minWidth || p.width || 'auto'};
  max-width: ${p => p.maxWidth || p.width || 'auto'};
  min-height: ${p => p.minHeight || '0px'};
  max-height: ${p => p.maxHeight || 'auto'};
  height: ${p => p.height || 'auto'};
  background: white;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  padding: ${p => p.padding || '40px'};
  margin: ${p => p.margin || '0'};
  border-top-left-radius: ${p => p.borderTopLeftRadius || '4px'};
`;

export default Card;