import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'; 
import { Row, Column, PullRight, PullLeft } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';
import BackButton from 'components/Common/BackButton';
import { getQueryVariables } from 'util/index';
import { ButtonPlain } from 'components/Styled/Button';
import { SmallProductBox } from 'components/Product';

import { requestPatchUser } from 'modules/clients/actions';
import { requestUpdateApplications , requestGetApplication } from 'modules/applications/actions';

import { SubmissionError } from 'redux-form';

import { bindActionCreators } from 'redux';


import { HeadingLarge } from './Components';
import { PageLoader } from 'components/Loader';

import ApplicantForm from './Forms/ApplicantForm';
import SecuritiesForm from './Forms/SecuritiesForm';
import OtherProperties from './Forms/OtherPropertiesForm';
import IncomeForm from './Forms/IncomeForm';
import LiabilitiesForm from './Forms/LiabilitiesForm';
import ExpensesForm from './Forms/ExpensesForm';
import CommentForm from './Forms/CommentForm';

class SubmissionView extends Component {
  constructor(props) {
    super(props);
    this.refTop = React.createRef();
    this.refApplicants = React.createRef();
    this.refSecurity = React.createRef();
    this.refOtherProperties = React.createRef();
    this.refIncome = React.createRef();
    this.refLiabilites = React.createRef();
    this.refExpenses = React.createRef();
  }

  scrollTo = ref => {
    this.props.viewportRef.current.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  }

  scrollToTop = () => {
    this.props.viewportRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onSubmit = data => {
    return new Promise((resolve, reject) => {
      const application = { ...data.application };
      delete data.application;
      this.props.requestUpdateApplications(application);

      const client = { ...data.client };
      this.props.requestPatchUser({
        data: {...client, id: this.props.client.id},
        callback: result => {
          if (result.error) {
            if (result.error.message === 'Email already exists')
              reject(new SubmissionError({
                email: 'Email address already taken',
                _error: 'Email address already taken'
              }));
            else 
              reject(new SubmissionError({
                _error: result.error.message,
              }));
            return;
          }
          resolve();
        }
      });
    });
  }
  
  render() {
    const { id } = getQueryVariables(this.props.location.search);
    const { application, client, history } = this.props;


    console.log('loading:', application, application?.meta, client, application?.userId, client?.id);
    if (!application || !application.meta || !client || application.userId !== client.id) {
      return (
        <div ref={this.refTop}>
          <PageLoader />
        </div>
      );
    }
    return (
      

      <div ref={this.refTop}>

        <Row margin='0 0 28px 0'>
          <BackButton margin='0 0 0 0' onClick={() => history.push('./notes?id=' + id)} />
          {/* <PullLeft>
            <BackButton margin='15px 0 0 0' onClick={() => this.props.history.push(`/eligibility/view?id=${id}`)} />
          </PullLeft>
          <PullRight>
            <ButtonPlain margin='0 10px 0 0'>Export to PDF</ButtonPlain>
            <ButtonPlain>Export to CSV</ButtonPlain>
          </PullRight> */}
        </Row>

        <HeadingLarge>Eligibility Check Submission</HeadingLarge>

        <Txt size='14px' color='#575E6C' marginBottom='40px'>
          Jump to:
          <JumpTo onClick={() => this.scrollTo(this.refApplicants)}>1. Applicants</JumpTo>
          <JumpTo onClick={() => this.scrollTo(this.refSecurity)}>2. Security Property</JumpTo>
          <JumpTo onClick={() => this.scrollTo(this.refOtherProperties)}>3. Other Properties</JumpTo>
          <JumpTo onClick={() => this.scrollTo(this.refIncome)}>4. Income</JumpTo>
          <JumpTo onClick={() => this.scrollTo(this.refLiabilites)}>5. Liabilities</JumpTo>
          <JumpTo onClick={() => this.scrollTo(this.refExpenses)}>6. Expenses</JumpTo>
        </Txt>

        <HeadingSmall>Client Selected Products</HeadingSmall>


        <ProductBoxRow>
          {application.products && application.products.map((product, index) => (
            <ProductBoxContainer key={index}>
              <SmallProductBox product={product}/>
            </ProductBoxContainer>
          ))}
        </ProductBoxRow>

        <CommentForm onSubmit={this.onSubmit} application={application}/>

        <HeadingSmall ref={this.refApplicants}>1. Applicants</HeadingSmall>
        <ApplicantForm onSubmit={this.onSubmit} application={application}/>

        <HeadingSmall ref={this.refSecurity}>2. Security Property</HeadingSmall>
        <SecuritiesForm onSubmit={this.onSubmit} application={application}/>

        <HeadingSmall ref={this.refOtherProperties}>3. Other Properties</HeadingSmall>
        <OtherProperties onSubmit={this.onSubmit} application={application}/>
        
        <HeadingSmall ref={this.refIncome}>4. Income</HeadingSmall>
        <IncomeForm onSubmit={this.onSubmit} application={application}/>

        <HeadingSmall ref={this.refLiabilites}>5. Liabilites</HeadingSmall>
        <LiabilitiesForm onSubmit={this.onSubmit} application={application}/>

        <HeadingSmall ref={this.refExpenses}>6. Expenses</HeadingSmall>
        <ExpensesForm onSubmit={this.onSubmit} application={application}/>
       
        <ButtonPlain width='100%' onClick={() => this.scrollToTop()}>Back to top ↑</ButtonPlain>
      </div>
    );
  }

}

const HeadingSmall = styled(Txt)`
  font-size: 16px;
  margin-bottom: 18px;
  padding-top: 20px;
`;

const ProductBoxContainer = styled(Column)`
  width: 270px;
  margin-bottom: 20px;
`;

const ProductBoxRow = styled(Row)`
`;



const JumpTo = styled.span`
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

const mapStateToProps = (state, props) => {
  //debugger;
  const { location } = props;
  const { applications } = state; 
  if (!(applications && applications.list) || !(state.lenders && state.lenders.list)) return {};
  const { id } = getQueryVariables(location.search);
  const application = applications.list[parseInt(id)];
  let lenders = [];
  
  if (application.products) {
    const lenderIds = [...new Set(application.products.map(p => p.bank))];
    lenders = lenderIds
      .map(id => state.lenders.list.find(l => l.id === id))
  }

  return {
    applications,
    application,
    lenders,
    client: state.clients.current,
  };
};

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestPatchUser,
    requestUpdateApplications,
    requestGetApplication,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionView);