import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading as HeadingBase, Body, Container as ContainerBase } from './Shared';

export default ({ user, type, isFinal }) => {
  
  const lendingOverview = user.meta.miniproposal?.overview?.lendingOverview ?? {};
  const atp = user.meta.miniproposal?.atp ?? {};
  const settings = user.globalSettings;

  const defaultBody = isFinal ? settings.atp_lending_preamble_final : settings.atp_lending_preamble;

  let body;
  if (type === 'atp') {
    body = atp.introText || defaultBody;
  } else {
    body = lendingOverview.body ?? '';
  }

  return (
    <Container>
      <Heading>{type === 'atp' ? 'Indicative Lending Structure' : 'Proposed Lending Overview'}</Heading>
      <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body)}}/>
    </Container>
  );
};

const Heading = styled(HeadingBase)`
  margin-bottom: 33px;
`;

const Container = styled(ContainerBase)`
  padding: 0;
`;