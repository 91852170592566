import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import uploadFile from 'util/upload-file';
import { SubmissionError } from 'redux-form';

import { requestCreateLender } from 'modules/lenders/actions';

// Styled Components
import BackButton from 'components/Common/BackButton';

import Form from './AddLenderForm';

class AddLender extends Component {
  constructor() {
    super();
    this.state = {
      redirectTo: '',
      form: {
        otherDebtTreatement: 'assessed',
      }
    };
  }

  submitForm = data => {
    return new Promise((resolve, reject) => {
      if (data['selectedLogo']) {
        uploadFile({
          file: data['selectedLogo'],
          type: 'logo',
          name: data['id'] + '.png',
          callback: (err, res) => {
            if (err) {
              return reject(new SubmissionError({ 
                _error: 'ERROR: An error occured uploading the selected logo.'
              }));
            }
            this.props.requestCreateLender({
              data
            });
            this.redirect('/lenders');
            resolve();
          }
        });
      } else {
        this.props.requestCreateLender({
          data
        });
        this.redirect('/lenders');
        resolve();
      }
      
    });
    
  }

  redirect = (to) => this.setState({ redirectTo: to });

  render() {
    const { redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <main className='no-bottom-margin'>
        <div className='lender-container'>
          <BackButton onClick={() => this.redirect('/lenders')}/>
          <div className='lender-modal'>
            <Form onSubmit={this.submitForm}/>
          </div>
        </div>
      </main>
    );
  }

};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { requestCreateLender },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddLender);