import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import sanitizeHtml from 'sanitize-html';

const Glossary = ({ settings }) => {

  const termsJSON = settings.overview_pdf_glossary_json;
  if (!termsJSON) return null;
  const terms = JSON.parse(termsJSON);
  if (!terms?.length) return null;
  
  return <>
    <Heading>Glossary</Heading>
    <ul>
      {terms.map(({ name, text }) => <li>
        <ItemHeader>{name}</ItemHeader>
        <ItemContent dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }}/>
      </li>)}
    </ul>
  </>
}

export default Glossary;

const Heading = styled.h1`
  font-size: 24px;
  margin-bottom:40px;
`;

const ItemHeader = styled.div`
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  padding: 4px 24px 5px 24px;
  background-color: #E8EDF5;
`;

const ItemContent = styled.p`
  padding: 10px 24px 30px 24px;
  font-size: 15px;
`;