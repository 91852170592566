import styled from 'styled-components';

const  Circle = styled.div`
  width: ${({ diameter }) => diameter};
  height: ${({ diameter }) => diameter};
  background: ${({ color }) => color};
  border-radius: ${({ diameter }) => diameter};
  border: 1px solid ${({ borderColor, color }) => borderColor || color};
`;

export default Circle;