// src/views/Clients/index.js
import React, { Component } from 'react';
import { MultiSelect, SearchBar } from 'components/Form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { requestFindClients } from 'modules/clients/actions';
import { requestPatchSelf } from 'modules/user/actions';
import { ClientTableItem } from './ClientTableItem';
import { TableHeaderCell } from 'components/Table';
import { ButtonPrimary } from 'components/Styled/Button';
import { Link } from 'react-router-dom';
import ControlPanel from 'components/Form/ControlPanel';
import { PageLoader } from 'components/Loader';
import { getQueryVariables } from 'util/index';
import { hostUrl } from 'modules';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import moment from 'moment';


const stageOptions = [
  {name: 'Unregistered', value: 'unregistered'},
  {name:'Registered', value:'registered'},
  {name:'Applied', value:'applied'},
  //{name:'Settled', value:'settled'},
  {name:'Eligibility', value:'eligibility'},
  {name: 'Professional Referrers', value: 'professional-referrer' },
  {name: 'Client Referrers', value: 'client-referrer' }
];

const initialPageSize = 50;
const pageSize = 20;
const bottomLoadHeight = 1200;



class ClientSearch extends Component {
  constructor() {
    super();
    this.state = {
      redirectTo: '',
      clientsLoaded: false,
      stages: [],
      searchText: '',
      sortField: 'lastName',
      sortDirection: 1,
    };
  }

  componentDidMount() {
    const { search } = getQueryVariables(this.props.location.search);
    let stages;
    try {
      stages = JSON.parse(this.props.user.meta.clientStageFilter);
    } catch (err) {}
    if (!stages) stages = [];
    const searchText = search ?? this.props.user.meta.clientSearch;
    if (search) {
      this.props.requestPatchSelf({
        data: {
          id: this.props.user.id,
          meta: {
            clientSearch: search,
            clientStageFilter: [],
          },
        }
      });
    }
    this.setState({ stages, searchText }, async () => {
      await this.filterResults();
      this.setState({ resultsFiltered: true, clientsLoaded: true });
    });
  }

  onChange = ({ clientStageFilter, clientSearch }) => {
    let stages = [];
    try {
      stages = JSON.parse(clientStageFilter);
    } catch (err) {}
    if (!stages) stages = [];
    let stateChange = {};
    if (stages !== this.state.stages) stateChange.stages = stages;
    if (clientSearch !== this.state.searchText) stateChange.searchText = clientSearch;

    this.setState(stateChange, this.filterResults);
  }

  downloadClientCsv = async () => {
    this.setState({ gettingCsv: true });
    const jwt = window.localStorage.getItem('feathers-jwt');
    const fetchUrl = new URL(hostUrl + '/client-csv');
    fetchUrl.search = new URLSearchParams({ 
      search: this.state.searchText, 
      stages: this.state.stages, 
      sortField: this.state.sortField, 
      sortDirection: this.state.sortDirection === 1 ? 'ASC' : 'DESC',
    }).toString();
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + jwt,
      },
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const date = moment().format('DD-MM-YY');
    a.download = `clients ${date}.csv`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();  //afterwards we remove the element again 
    this.setState({ gettingCsv: false });
  } 

  redirect = to => this.props.history.push(to);
  render() {
    const clients = this.props.clients.list;   
    const LengthToAdd = clients.list ? (clients.total - clients.list.length) * 70 : 0;
    
    
    if (!this.state.resultsFiltered) {
      return (
        <main className="main client-search" onScroll={this.onScroll}>
          <PageLoader show={true}/>
        </main>
      );
    }
    return (
      
      <main className="main client-search" onScroll={this.onScroll}>
        
        <div className="client-search-container">
          <ControlPanel callback={this.onChange}>
            <div>
              <div style={{ margin: '0 0 40px 40px', minWidth: '800px' }}>
                
                <div className='inline-block'>
                  
                  <MultiSelect
                    name='clientStageFilter'
                    label='Filter'
                    tabIndex={100}
                    options={stageOptions}
                    width='350px'
                    placeholder='No filters selected' 
                  />
                </div>
                <div className='inline-block' style={{ margin: '26px 0 0 17px' }}>
                  <SearchBar
                    name='clientSearch'
                    tabIndex={200}
                    width='187px'
                    placeholder='Search'
                  />
                </div>
                
                <ButtonPrimary as={Link} to='/clients/add' style={{margin:'26px 40px 0 0', float:'right'}}>
                    + Add Client
                </ButtonPrimary>
                <ButtonPrimary type='button' onClick={this.downloadClientCsv} style={{margin:'26px 40px 0 0', float:'right'}}>
                  <ButtonSpinner show={this.state.gettingCsv}/>
                  Download CSV
                </ButtonPrimary>
              </div>
            </div>
          </ControlPanel>

          <ClientTable>
            <Header context={this} />
            <div className='table-contents client' style={{ top: '196px' }}>
              <div style={{width: '100%', display:'table'}}>
                {clients.list && clients.list.length > 0 ? clients.list.map((client, index) => (
                  
                  <ClientTableItem
                    onClick={exclude(['a', 'button'], () => this.redirect(`/clients/view?id=${client.id}`))}
                    tabIndex={300 + index}
                    key={index}
                    client={client}
                  />
                )) : (
                  <TablePlaceHolder />
                )}
                <div style={{height: `${LengthToAdd}px`}}></div>
              </div>

            </div>
          </ClientTable>
        </div>
      </main>
    );
  }

  onScroll = async e => {
    const clients = this.props.clients.list;
    const { clientsLoaded } = this.state;
    if (clients.listComplete) return;

    const currentFilledLength = clients.list.length * 70; 
    const scrollPosition = e.target.offsetHeight + e.target.scrollTop;
    
    if (scrollPosition < currentFilledLength - bottomLoadHeight) return;
    
    if (clientsLoaded) { //i.e. no currently pending calls to the DB
      this.setState({ clientsLoaded: false });
      const { searchText, sortField, sortDirection, stages } = this.state;
      
      //if user scrolls past currently loaded point increase page size to load to that point in only one call
      let limit = pageSize;
      if (((scrollPosition / 70) - clients.list.length) > pageSize) 
        limit = ((scrollPosition / 70) - clients.list.length) + 10; // + 10 to account for extra scrolling during load
      
      this.props.requestFindClients({
        query: {
          limit,
          skip: clients.list.length,
          stages,
          search: searchText || '',
          sortField,
          sortDirection,
        },
        callback: () => {
          this.setState({ clientsLoaded: true });
        }});  
    }
    
  }

  filterResults() {
    return new Promise((resolve) => {
      const {searchText = '', stages = [], sortDirection, sortField} = this.state;
      this.props.requestFindClients({
        query: {
          limit: initialPageSize,
          skip: 0,
          stages,
          search: searchText,
          sortField,
          sortDirection,
        },
        callback: resolve,
      });
    });
  }

} 

const exclude = (exclusions, fn) => event => {
  if (!event.target.matches(exclusions)) {
    fn(event);
  }
};

const mapStateToProps = ({clients, user}) => ({clients, user});
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      requestFindClients ,
      requestPatchSelf,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps) (ClientSearch);

const ClientTable = (props) => (
  <div className='lender-table' {...props}>
  </div>
);

export const HeadRow = (props) => (
  <div className='table-head-row' {...props}>
  </div> 
);

export const Row = (props) => (
  <div className='table-row' {...props}>
  </div>
);

export const Cell = ({width, ...props}) => (
  <div className='table-cell' {...props} style={{width}}>
  </div>
);

export const Header = ({ context }) => (
  <div style={{display: 'table', width: '100%'}}>
    <HeadRow>
      <TableHeaderCell width='12%' attributeName='lastName' context={context}>Last Name</TableHeaderCell>
      <TableHeaderCell width='12%' attributeName='firstName' context={context}>First Name</TableHeaderCell>
      <TableHeaderCell width='13%' attributeName='phoneNumber' context={context}>Primary Phone</TableHeaderCell>
      <TableHeaderCell width='24%' attributeName='email' context={context}>Email Address</TableHeaderCell>
      <TableHeaderCell width='12%' attributeName='clientStage' context={context}>Client Stage</TableHeaderCell>
      <TableHeaderCell width='15%' attributeName='lastLogin' context={context}>Last Login</TableHeaderCell>
      <Cell width='15%'>Login as Client</Cell>
    </HeadRow>
  </div>);

const TablePlaceHolder = () => [
  <PlaceHolderRow key={0}/>,
  <PlaceHolderRow key={1}/>,
  <PlaceHolderRow key={2}/>,
  <PlaceHolderRow key={3}/>,
  <PlaceHolderRow key={4}/>,
  <PlaceHolderRow key={5}/>,
  <PlaceHolderRow key={6}/>,
  <PlaceHolderRow key={7}/>,
];


const PlaceHolderRow = index => (
  <Row key={index}>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
  </Row>
);

