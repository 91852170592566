import React, { Fragment, useEffect } from 'react';

import { reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { connect } from 'react-redux'; 
import { Row } from 'components/Styled/Layout';
import { Hide, FormCard, HeadingLarge, SelectField, TextField, PercentField, FormGroup, NumberField, SaveButton, MoneyAndSelectField } from '../Components';
import initializeFormValues from 'util/initializeFormValues';
import { ButtonPlain } from 'components/Styled/Button';
import styled from 'styled-components';

import imgClose from 'img/button/close-modal.png';

const FORM_NAME = 'submission-income';

const IncomeFormPure = ({ handleSubmit, submitting, pristine, employmentStatus, partnerEmploymentStatus, applicantType, application }) => { 
  
  useEffect(() => {
    initializeFormValues(FORM_NAME, values => ({ ...values, application }));
  }, []);
  
  return (
    <FormCard onSubmit={handleSubmit}>
      <HeadingLarge>Applicant</HeadingLarge>
      <Row>
        <SelectField name='client.meta.employmentStatus' label='Employment Status' width='200px' options={[
          { name: 'Salaried Employee', value: 'Salaried Employee' },
          { name: 'Self-Employed', value: 'Self-Employed' },
          { name: 'Retired', value: 'Retired' },
          { name: 'Unemployed', value: 'Unemployed' },
          { name: 'Maternity Leave', value: 'Maternity Leave' },
          { name: 'Homemaker', value: 'Homemaker' },
        ]} />
        <Hide when={employmentStatus !== 'Salaried Employee'}>
          <SelectField name='client.meta.qualifyMeEmploymentType' label='Employment Type' width='200px' options={[
            { name: 'Full-time', value: 'Full-time'},
            { name: 'Part-time', value: 'Part-time'},
            { name: 'Casual', value: 'Casual'},
            { name: 'Contract', value: 'Contract'},
          ]} />
          <TextField name={'client.meta.jobTitle'} label='Job Title' />
        </Hide>
        
      </Row>
      <Hide when={employmentStatus !== 'Salaried Employee'}>
        <Row>
          <MoneyAndSelectField name={'client.meta.salaryBeforeTax'} label='Salary Before Tax (excluding Super)' options={timeOptions} />
          <MoneyAndSelectField name={'client.meta.bonusOvertimeCommission'} label='Bonus, Overtime, Commission' options={timeOptions} />
        </Row>
      </Hide>
      <Hide when={employmentStatus !== 'Self-Employed'}>
        <Row>
          <MoneyAndSelectField name={'client.meta.selfEmployedIncomeLastYear'} label='Self Employed Income Last Year' options={timeOptions} />
          <MoneyAndSelectField name={'client.meta.selfEmployedIncomeYearBefore'} label='Self Employed Income the Year Before' options={timeOptions} />
        </Row>
      </Hide>
      <FieldArray name='client.meta.otherIncomeItems' component={OtherIncomes} /> 
      <Hide when={applicantType !== 'youAndYourPartner'}>
        <div style={{ width: '100%', height: '40px'}}/>
        
        <HeadingLarge>Partner</HeadingLarge>
        <Row>
          <SelectField name='client.partner.meta.employmentStatus' label='Employment Status' width='200px' options={[
            { name: 'Salaried Employee', value: 'Salaried Employee' },
            { name: 'Self-Employed', value: 'Self-Employed' },
            { name: 'Retired', value: 'Retired' },
            { name: 'Unemployed', value: 'Unemployed' },
            { name: 'Maternity Leave', value: 'Maternity Leave' },
            { name: 'Homemaker', value: 'Homemaker' },
          ]} />
          <Hide when={partnerEmploymentStatus !== 'Salaried Employee'}>
            <SelectField name='client.partner.meta.qualifyMeEmploymentType' label='Employment Type' width='200px' options={[
              { name: 'Full-time', value: 'Full-time'},
              { name: 'Part-time', value: 'Part-time'},
              { name: 'Casual', value: 'Casual'},
              { name: 'Contract', value: 'Contract'},
            ]} />
            <TextField name={'client.partner.meta.jobTitle'} label='Job Title' />
          </Hide>
        </Row>
        <Hide when={partnerEmploymentStatus !== 'Salaried Employee'}>
          <Row>
            
            <MoneyAndSelectField name='client.partner.meta.salaryBeforeTax' label='Salary Before Tax (excluding Super)' options={timeOptions} />
            <MoneyAndSelectField name='client.partner.meta.bonusOvertimeCommission' label='Bonus, Overtime, Commission' options={timeOptions} />
            
          </Row>
        </Hide>
        <Hide when={partnerEmploymentStatus !== 'Self-Employed'}>
          <Row>
            <MoneyAndSelectField name='client.partner.meta.selfEmployedIncomeLastYear' label='Self Employed Income Last Year' options={timeOptions}  />
            <MoneyAndSelectField name='client.partner.meta.selfEmployedIncomeYearBefore' label='Self Employed Income the Year Before' options={timeOptions} />
          </Row>
        </Hide>

        <FieldArray name='client.partner.meta.otherIncomeItems' component={OtherIncomes} /> 
      </Hide>
      <SaveButton disabled={(submitting || pristine)} submitting={submitting}/>
    </FormCard>
  );
};

const DeleteButton = styled.img.attrs({
  src: imgClose,
  width: 16,
})`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

const OtherIncomes = ({ fields }) => (
  <Fragment>
    {!!fields.length && <FormGroup/>}
    {fields.map( (item, index) => (
      <FormGroup key={index}>
        <DeleteButton onClick={() => fields.remove(index)} />
        <Row>
          <HeadingLarge>Other Income {index + 1}</HeadingLarge>
          <SelectField name={`${item}.incomeType`} label='Income Type' width='300px' options={[
            {name: 'Annuity Income', value: 'Annuity Income'},
            {name: 'Child Support / Maintenance', value: 'Child Support / Maintenance'},
            {name: 'Foreign Pension Income', value: 'Foreign Pension Income'},
            {name: 'Income Protection Payments', value: 'Income Protection Payments'},
            {name: 'Managed Fund Distributions', value: 'Managed Fund Distributions'},
            {name: 'Share Dividends', value: 'Share Dividends'},
            {name: 'Superannuation / Pension Income', value: 'Superannuation / Pension Income'},
            {name: 'Trust Distributions', value: 'Trust Distributions'},
            {name: 'Welfare Payments (Centerlink DVA etc)', value: 'Welfare Payments (Centerlink DVA etc)'},
            {name: 'Other', value: 'Other'},
          ]} />
          <Hide when={!!fields.get(index) && fields.get(index).incomeType !== 'Other'}>
            <TextField name={`${item}.description`} label='Income Description' />
          </Hide>
          <MoneyAndSelectField name={`${item}.incomeAmount`} label='Other Income Amount' options={timeOptions}/>
        </Row>
      </FormGroup>
    ))}
    <ButtonPlain type='button' onClick={() => fields.push({ incomeType: 'Annuity Income' })} height='32px'>Add Other Income</ButtonPlain>
  </Fragment>
);

const select = formValueSelector(FORM_NAME);
const stateToProps = (state, props) => ({
  employmentStatus: select(state, 'client.meta.employmentStatus'),
  partnerEmploymentStatus: select(state, 'client.partner.meta.employmentStatus'),
  applicantType: props.application.meta.applicantType,
});

const IncomeForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(IncomeFormPure);

export default connect(stateToProps)(IncomeForm);

const timePeriod = {
  YEAR: 1,
  MONTH: 1/12,
  WEEK: 1/365.25*7,
  FORTNIGHT: 1/365.25*14,
};

const timeOptions = [
  {name: 'Monthly', value: timePeriod.MONTH},
  {name: 'Fortnightly', value: timePeriod.FORTNIGHT},
  {name: 'Weekly', value: timePeriod.WEEK},
  {name: 'Annually', value: timePeriod.YEAR},
];

const yesNoOptions = [
  {name: 'Yes', value: 'Yes'},
  {name: 'No', value: 'No'},
];