import React, { useEffect } from 'react';

import { reduxForm, Form } from 'redux-form';
import styled from 'styled-components';
import Txt from 'components/Styled/Text';
import { Row } from 'components/Styled/Layout';
import { AutoSizeTextArea, SaveButton } from '../Components';
import initializeFormValues from 'util/initializeFormValues';
import Card from 'components/Styled/Card';
const FORM_NAME = 'submission-comment';

const CommentForm = ({ handleSubmit, submitting, pristine, application }) => {
  
  useEffect(() => {
    initializeFormValues(FORM_NAME, values => ({ ...values, application }));
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <HeadingSmall>Additional Comments</HeadingSmall>

      <Card width='100%' padding='20px 40px' margin='0 0 20px 0'>
        <Row >
          <AutoSizeTextArea noPlaceholder name='client.meta.additionalComments' padding='0' height='20px' />
        </Row>
      </Card>
      <Row style={{ position: 'relative', height: '50px'}}>
        <SaveButton disabled={(submitting || pristine)} submitting={submitting} style={{ position: 'absolute', bottom: '20px', right: '0' }}/>
      </Row>
    </Form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(CommentForm);

const HeadingSmall = styled(Txt)`
  font-size: 16px;
  margin-bottom: 18px;
  padding-top: 20px;
`;