import React, { Component } from 'react';
import styled from 'styled-components';

import Circle from 'components/Styled/Circle';
import Card from 'components/Styled/Card';
import { Scrollbars } from 'react-custom-scrollbars';
import { Row, Column, PullRight } from 'components/Styled/Layout';
import { Modal, ModalSubmit, ModalHide } from 'components/Modal/old';

import Text from 'components/Styled/Text';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';

import imgCheck from 'img/button/check-small-white.png';
import imgLogo from 'img/temp/lender1.png';

export class LenderModal extends Component {
  state = {
    selectedItems: [4],
  }

  listRef = React.createRef();
  listHeight = 380;

  lenderClicked = id => {
    if (!this.props.maxSelected || (this.props.maxSelected > 1)) {
      const { selectedItems } = this.state;
      const index = selectedItems.findIndex(i => i === id);
      if (index !== -1) {
        let tmpSelectedItems = [ ...selectedItems];
        tmpSelectedItems.splice(index, 1);
        this.setState({ selectedItems: tmpSelectedItems });
      }
      else if (selectedItems.length < 3) {
        this.setState({ selectedItems: [...selectedItems, id] });
      }
    } else {
      this.setState({ selectedItems: [id]});
    }
    
  }

  componentWillMount() {
    const windowHeight = window.innerHeight;
    const room = windowHeight - 400;
    let rows = Math.round(room / 120);
    rows = rows < 3 ? 3 : rows;
    this.listHeight = (rows * 120) + 20;
  }

  render() {
    let { modal, maxSelected } = this.props;
    const { selectedItems } = this.state;

    if (!maxSelected)
      maxSelected = 3;
    return (
      <Modal width='720px' height='100%' modal={modal}>
        <Text style={{ fontSize: '24px' }}>Select Lender{maxSelected > 1 && 's'}</Text>
        <button style={{ opacity: 0 }} ref={el => modal.focusOnStart(el)} />
        <LenderListWindow style={{ height: this.listHeight + 'px' }} ref={this.listRef}>
          <Row style={{ padding: '10px' }}>
            {[...Array(40).keys()].map(index => {
              const isSelected = selectedItems.some(i => i === index);
              return <LenderItem
                key={index}
                selected={isSelected}
                onClick={() => this.lenderClicked(index)} />;
            })}
          </Row>
        </LenderListWindow>
        <Row style={{ marginTop: '20px' }}>
          <Column width='33.333%'>
            <ModalHide as={ButtonPlain} width='160px'>Cancel</ModalHide>
          </Column>
          <Column width='33.333%'>
            <div style={{height: '1px'}}/>
            {(maxSelected > 1) &&
              <Text style={{ fontSize: '13px', marginTop: '12px' }} center>You have selected {selectedItems.length} out of {maxSelected} lenders</Text>
            }
          </Column>
          <Column width='33.333%'>
            <PullRight>
              <ModalSubmit as={ButtonPrimary} width='160px' style={{float: 'right'}}>Save</ModalSubmit>
            </PullRight>
          </Column>
        </Row>
      </Modal>

    );
  }
}

const LenderListWindow = styled(Scrollbars)`
  width: 100%;
  min-height: 380px;
  background: #eeeeee;
  border: solid 1px #dddddd;
  border-radius: 4px;
  /* flex-wrap: wrap; */
  padding: 10px;
`;

const ImgLogo = styled.img.attrs({
  src: imgLogo,
})`
  width: 100px;
`;

const LenderCard = styled(Card)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: relative;
  cursor: pointer;
  ${p => p.selected ? `
    box-shadow: 0 0 0 2px rgba(34,145,255,0.20);
    border-color: rgba(34,145,255);
    animation: bounce 300ms ease;
  ` : ``}
  /* transition: all 300ms ease; */
  

  @keyframes bounce {
    0% {
      transform: scale(1.0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.0);
    }
  }
`;

const SelectedCircle = styled(Circle).attrs({
  diameter: '16px',
  color: '#26BD27'
})`
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 200ms ease;
  ${p => !p.selected ? `
    opacity: 0;
  ` : ''}
`;

const ImgCheck = styled.img.attrs({
  src: imgCheck,
  width: 10,
})`
  position: absolute;
  top: 3px;
  left: 2px;
`

const LenderItem = ({ selected, onClick }) => (
  <Column width='25%' style={{padding:'10px 10px'}}>
    <LenderCard selected={selected} onClick={onClick}>
      <ImgLogo/>
      <SelectedCircle selected={selected}><ImgCheck/></SelectedCircle>
    </LenderCard>
  </Column>
  
);