
import store from './store';
import 'map.prototype.tojson';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ModalContext } from 'components/Modal/index.jsx';
import { BrowserRouter } from "react-router-dom";
//import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'

// import { ModalContext } from './components/Modal/old';


ReactDOM.render(
  (<Provider store={store}>
    <BrowserRouter>
      <ModalContext>
        <App />
      </ModalContext>
    </BrowserRouter>
  </Provider>), 
  document.getElementById('root'));
//registerServiceWorker();
