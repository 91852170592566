import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { reduxForm } from 'redux-form';

import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';

import { Input, NumberInput, TextArea, ToggleButton } from 'components/Form';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { Row as BaseRow, Column as BaseColumn } from 'components/Styled/Layout';
import Loading from 'components/Styled/ImageLoading';

import { Img } from 'components/Common/Image';
import { required } from 'util/validation';
import { showUnfinished } from 'modules';
import uriToBlob from 'util/uri-to-blob';

import { uploadUrl } from 'modules';

import placeholderImg from 'img/illustrations/portrait-placeholder.png';
import signatureImg from 'img/illustrations/add-signature.png';
import { EmailTextArea } from 'components/Form/EmailTextInput';

const $ = window.$;

const requiredIfBroker = (value, props) => {
  return (!value && props.isBroker) ? 'This field is required' : null;
};



const validateProposalOrder = (value, _, { brokers, initialValues }) => {
  if (!value) return null;
  if (brokers.some(x => x.overviewPosition === value)) {
    const broker = brokers.find(x => x.overviewPosition === value);
    if (broker.id === (initialValues || {}).id) return null;
    return 'The chosen proposal position is held by ' + broker.name;
  }
  return null;
};

export const Column = styled(BaseColumn)`
  padding: 0 40px 24px 0;
`;

export const Row = styled(BaseRow)`
  margin-right: -40px;
`;

const CancelButton = styled(ButtonPlain)`
 display: inline-block;
`;

const SubmitButton = styled(ButtonPrimary)`
 float: right;
 margin-right: 40px;
`;




class AddBrokerForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      smallImageUploading: false,
      bigImageUploading: false,
      signatureImageUploading: false,
      bigImgUrl: '',
      smallImgUrl: '',
      signatureImgUrl: '',
      selectedLogo: null,
      uploadError: '',
    };
  }

  filePicker = type => () => {
    this.setState({ uploadType: type }, () => {
      $('#file-upload').click();
    });
  };

  componentDidMount() {

    $.ajaxSetup({
      xhr: function () {
        var xhr = new window.XMLHttpRequest();
        // upload progress
        xhr.addEventListener('progress', function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            console.log('upload progress: ', Math.round(percentComplete * 100) + '%');
          }
        }, false);
        return xhr;
      }
    });


    const reader  = new FileReader();

    $('#file-upload').change(function(){

      var file = this.files[0];
      // encode dataURI
      if (!file) return;
      reader.readAsDataURL(file);
    });
    reader.addEventListener('load', () => {
      try {
        const type = this.state.uploadType;
        const blob = uriToBlob(reader.result);
        this.setState({ [type + 'ImageUploading']: true, [type + 'ImgUrl']: URL.createObjectURL(blob) });
        this.props.change( type + 'Image', reader.result);
        setTimeout(() => {
          this.setState({ 
            [type + 'ImageUploading']: false,
            uploadError: '',
          });
        }, 1000);
      } catch(err) {
        this.setState({ uploadError: 'Error displaying uploaded file! Make sure that image is .png and < 10mb'});
      }
    }, false);
    
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid, type, initialValues, error } = this.props;
    const { uploadError } = this.state; 



    const bigImgUrl = `${uploadUrl}/brokers/big${initialValues?.id ?? 'noimage'}.png?n=${Math.random()}`;
    const smallImgUrl = `${uploadUrl}/brokers/small${initialValues?.id ?? 'noimage'}.png?n=${Math.random()}`;
    const signatureImgUrl = `${uploadUrl}/brokers/signature${initialValues?.id ?? 'noimage'}.png?n=${Math.random()}`;

    return (

      <form onSubmit={handleSubmit} autoComplete="off">
        <input hidden autoComplete="off" />
        <Row>
          <Column width='100%' style={{paddingBottom: '30px'}}>
            <Row>
              <Column width='25%'>
                <Img className='img-logo' loader={<Loading height='80px' width='100px'/>} width='100%' src={[this.state.bigImgUrl, bigImgUrl, placeholderImg]} alt="logo" fallback={() => 'not working'}/>
              </Column>
              <Column width='25%'>
                <div style={{ fontSize: '20px', marginBottom: '10px'}}>Large Portrait</div>
                <ButtonPlain type='button' width='150px' onClick={this.filePicker('big')}><ButtonSpinner color='#2291FF' show={this.state.bigImageUploading}/>Upload Image</ButtonPlain>
                <input id='file-upload' type='file' name='upload' accept='.png,.jpg' style={{display: 'none'}}/>
              </Column>
              <Column width='25%'>
                <Img className='img-logo' loader={<Loading height='80px' width='100px'/>} width='100%' src={[this.state.smallImgUrl, smallImgUrl, placeholderImg]} alt='logo' fallback={() => 'not working'}/>
              </Column>
              <Column width='25%'>
                <div style={{ fontSize: '20px', marginBottom: '10px'}}>Team Portrait</div>
                <ButtonPlain type='button' width='150px' onClick={this.filePicker('small')}><ButtonSpinner color='#2291FF' show={this.state.smallImageUploading}/>Upload Image</ButtonPlain>
                <input id='file-upload' type='file' name='upload' accept='.png,.jpg' style={{display: 'none'}}/>
              </Column>
            </Row>
            <Row>
              <Column width='25%'>
                <Img className='img-logo' loader={<Loading height='80px' width='100px'/>} width='100%' src={[this.state.signatureImgUrl, signatureImgUrl, signatureImg]} alt="logo" fallback={() => 'not working'}/>
              </Column>
              <Column width='25%'>
                <div style={{ fontSize: '20px', marginBottom: '10px'}}>Signature</div>
                <ButtonPlain type='button' width='150px' onClick={this.filePicker('signature')}><ButtonSpinner color='#2291FF' show={this.state.signatureImageUploading}/>Upload Image</ButtonPlain>
                <input id='file-upload' type='file' name='upload' accept='.png,.jpg' style={{display: 'none'}}/>
              </Column>
            </Row>
            {uploadError &&
              <ErrorText>{uploadError}</ErrorText>
            }
          </Column>

          <Column width='50%'>
            <ToggleButton 
              name='isBroker' 
              title='Is Assignable Broker' 
              valueChecked={1} 
              valueUnchecked={0}
            />
          </Column>
          
          <Column width='50%'>
            <ToggleButton 
              name='isProcessor' 
              title='Is Assignabled Processor' 
              valueChecked={1} 
              valueUnchecked={0}
            />
          </Column>

          <Column width='50%'>
            <Label>Proposal Position</Label>
            <NumberInput 
              type='number' 
              name='overviewPosition' 
              validate={validateProposalOrder}
            />
          </Column>

          <Column width='50%'>
            <Label>Title</Label>
            <Input name='title' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>First Name</Label>
            <Input name='firstName' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Last Name</Label>
            <Input name='lastName' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Mobile</Label>
            <Input type='phone' name='mobile' validate={requiredIfBroker}/>
          </Column>

          <Column width='50%'>
            <Label>Landline</Label>
            <Input type='phone' name='landline' validate={requiredIfBroker}/>
          </Column>

          <Column width='50%'>
            <Label>Email</Label>
            <Input name='email' validate={requiredIfBroker}/>
          </Column>

          <Column width='50%'>
            <Label>Calendly URL</Label>
            <Input name='calendlyUrl' validate={requiredIfBroker}/>
          </Column>

          <Column width='100%'>
            <Divider/>
          </Column>

          <Column width='100%'>
            <Label>Blurb</Label>
            <TextArea name='blurb'/>
          </Column>

          <Column width='100%'>
            <Label>E-mail Signature</Label>
            <EmailTextArea name='emailSignature'/>
            <Label>E-mail Signature HTML</Label>
            <TextArea spellcheck='false' height='260px' name='emailSignature'/>
          </Column>

          <Column width='100%'>
            <Divider/>
          </Column>

          <BaseColumn width='100%'>
            <CancelButton width='300px' onClick={() => this.props.history.push('/team')} width='95px'>Cancel</CancelButton>
            <SubmitButton type='submit' width='300px' className={(invalid || pristine) && 'disabled'} onSubmit={handleSubmit} width='95px'><ButtonSpinner show={submitting}/>{(type === 'edit') ? 'Update' : 'Create'}</SubmitButton>
          </BaseColumn>
        </Row>
        {error &&
          <ErrorText>{error}</ErrorText>
        }
      </form>
    );
  }
}



const ErrorText = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: red;
`;

const stateToProps = state => ({
  brokers: state.brokers,
});

AddBrokerForm = reduxForm({
  form: 'editBroker',
  //enableReinitialize: true,
})(AddBrokerForm);

export default connect(stateToProps)(withRouter(AddBrokerForm));
