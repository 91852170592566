import React, { Component } from 'react';
import styled from 'styled-components';
import { Form, Field } from 'redux-form';

import { Column } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import Card from 'components/Styled/Card';
import { NumberInput, Select, BoxlessSelect, AddressInput as BaseAddessInput, MoneyInputInt, PercentInput } from 'components/Form';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

export const HeadingLarge = props => (
  <Txt size='18px' weight='600' marginBottom='30px' {...props} />
);

export const FormCard = props => (
  <Card
    as={Form}
    padding='40px 40px 25px 40px'
    margin='0 0 20px 0'
    style={{ position: 'relative' }}
    {...props} />
);

export const FormGroup = styled.div`
  border-bottom: 1px solid #D8DCE7;
  margin-bottom: 40px;
  position: relative;
`;

export const Hide = ({ when, ...props }) => <div className={when ? 'hidden' : ''} {...props} />;

export const SaveButton = ({ disabled, submitting, ...props }) => (
  <ButtonPrimary
    className={disabled ? 'disabled' : ''}
    height='32px'
    width='70px'
    style={{ position: 'absolute', bottom: '20px', right: '40px' }}
    {...props}>
    <ButtonSpinner show={submitting}/>Save
  </ButtonPrimary>
);

export const TextField = ({ label, innerLabel, hidden, ...props }) => (
  <Column width='33.33333%'>
    <Label hidden={hidden}>{label}</Label>
    <InnerLabelDiv as='div' innerLabel={innerLabel}>
      <Field component={TextInput} hidden={hidden} {...props} />
    </InnerLabelDiv>
  </Column>
);

export const AddressField = ({ label, innerLabel, ...props }) => (
  <Column width='33.33333%'>
    <Label>{label}</Label>
    <InnerLabelDiv as='div' innerLabel={innerLabel}>
      <AddressInput {...props} />
    </InnerLabelDiv>
  </Column>
);

const TextInput = ({ input, ...props }) => <InvisibleTextInput {...input} {...props}/>;
const NumInput = ({ input }) => <InvisibleTextInput {...input} as={NumberInput}/>;
const AddressInput = props => <InvisibleTextInput {...props} as={BaseAddessInput}/>;
const MoneyInput = ({ input }) => <InvisibleTextInput {...input} as={MoneyInputInt}/>;

export const NumberField = ({ label, innerLabel, ...props }) => (
  <Column width='33.33333%'>
    <Label>{label}</Label>
    <InnerLabelDiv as='div' innerLabel={innerLabel}>
      <InvisibleTextInput as={NumberInput} {...props} />
    </InnerLabelDiv>
  </Column>
);

export const MoneyField = ({ label, innerLabel, hidden, ...props }) => (
  <Column width='33.33333%'>
    <Label hidden={hidden}>{label}</Label>
    <InnerLabelDiv as='div' innerLabel={innerLabel}>
      <InvisibleTextInput as={MoneyInputInt} hidden={hidden} {...props} />
    </InnerLabelDiv>
  </Column>
);

export const PercentField = ({ label, innerLabel, hidden, ...props }) => (
  <Column width='33.33333%'>
    <Label hidden={hidden}>{label}</Label>
    <InnerLabelDiv as='div' innerLabel={innerLabel}>
      <InvisibleTextInput as={PercentInput} hidden={hidden} {...props} />
    </InnerLabelDiv>
  </Column>
);

export const MultiLineTextField = ({ label, ...props }) => (
  <Column width='33.33333%'>
    <Label>{label}</Label>
    <Field component={AutoSizeTextArea} {...props} />
  </Column>
);

export const SelectField = ({ label, ...props }) => (
  <Column width='33.33333%'>
    <Label>{label}</Label>
    <BoxlessSelect {...props} />
  </Column>
);

export const TextAndSelectField = ({ label, options, ...props }) => (
  <Column width='33.33333%'>
    <Label>{label}</Label>
    <div>
      <Column width='150px'>
        <Field component={TextInput} {...props} />
      </Column>
      <Column width='calc(100% - 150px)'>
        <BoxlessSelect options={options} width='110px' name={props.name + 'Type'} />
      </Column>
    </div>
  </Column>
);

export const MoneyAndSelectField = ({ label, options, ...props }) => (
  <Column width='33.33333%'>
    <Label>{label}</Label>
    <div>
      <Column width='90px'>
        <InnerLabelDiv as='div'>
          <InvisibleTextInput as={MoneyInputInt} {...props} />
        </InnerLabelDiv>
      </Column>
      <Column width='calc(100% - 90px)'>
        <BoxlessSelect options={options} width='110px' name={props.name + 'Type'} format={val => parseFloat(val)} />
      </Column>
    </div>
  </Column>
);

export const Label = styled.label`
  color: #575E6C;
  font-size: 14px;
  padding-bottom: 10px;

  ${(p => p.hidden ? 'display: none' : '')}
`;

export const InvisibleTextArea = styled.textarea.attrs({
  rows: 5,
})`
  appearance: none;
  font-family: Lato;
  font-size: 16px;
  resize: none;
  width: 100%;
  height: 80px;
  padding: ${p => p.padding || '10px 20px 0 0'};
  border: none;
`;

export const InvisibleTextInput = styled.input.attrs({
  type: 'text',
  placeholder: '—'
})`
  display: inline-block;
  appearance: none;
  font-family: Lato;
  font-size: 16px;
  resize: none;
  width: calc(100% - 15px);
  height: 40px;
  border: none;
  margin-bottom: 20px;
  box-shadow: none !important;
  padding-left: 0;

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }

  ${(p => p.hidden ? 'display: none' : '')}
`;

export const InvisibleSelect = styled.select`
  font-family: Lato;
  font-size: 16px;
  appearance: none;
  margin-bottom: 20px;
  width: 90%;
  height: 40px;
  border: none;
`;

export const InnerLabelDiv = styled.div`
  position: relative;
  width: 100%;

  &:before {
    font-size: 16px;
    color: ${p => p.color || '#101922'};
    display: inline;
    content: ${p => p.innerLabel ? `"${p.innerLabel}"` : '""'};
    margin-right: ${p => p.innerLabel ? '1px' : '0'};
  }
`;

export class AutoSizeTextArea extends Component {
  constructor(props) {
    super(props);
    this.textArea = React.createRef();
  }

  onChange = () => {
    let { height } = this.props;
    height = height || 40;
    let el = this.textArea.current;
    el.style.height = 'auto';
    const size = el.scrollHeight;
    el.style.height = (size < height) ? height + 'px' : (size + 1) + 'px';
  };



  render() {
    
    return (
      <InvisibleTextArea
        as={Field}
        name={this.props.name}
        component='textarea'
        placeholder={this.props.noPlaceholder ? '' : '—'}
        ref={this.textArea}
        // onChange={this.onChange}
        padding={this.props.padding}
        height={this.props.height} />
    );
  }
}
