import React, { Component, useState, useRef , useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import imgCheck from 'img/button/check-small-white.png';

// export class MultiSelect2 extends Component {
//   constructor() {
//     super();
//     this.state = {
//       isOpen: false,
//       options: [] 
//     };
//   }

//   componentWillMount() {
//     debugger;
//     this.setState({ 
//       options: this.props.options.map(
//         o => ({ 
//           ...o, 
//           isChecked: (this.props.value || []).some(v => v === o.value)
//         })
//       )});
//     window.addEventListener('click', this.clickOffComponent);
//     window.addEventListener('focus', this.onFocus);
//   }

//   componentWillUnmount() {
//     window.removeEventListener('click', this.clickOffComponent);
//     window.removeEventListener('keypress', this.onKeyPress);
//     window.removeEventListener('focus', this.onFocus);
//   }

//   render() {
//     const { options, isOpen } = this.state;
//     const { placeholder, onChange, width, tabIndex, label, ...props } = this.props;
//     return (
      
//       <div 
//         className={`multi-select ${isOpen && 'open'}`} 
//         style={{width}} 
//         ref={node => this.node = node}
//         {...props}
//       >
//         {label && <label className='label'>{label}</label>}
//         <div className='select-box' onClick={this.toggleOpen} tabIndex = {tabIndex}>
//           {
//             options.every(o => !o.isChecked) ? 
//               placeholder 
//               : options
//                 .filter(o => o.isChecked)
//                 .map(o => o.name)
//                 .join(', ')
//           }
//         </div>
//         {
//           isOpen && (
//             <div className='options-container' style={{width}}>
//               {
//                 options.map((option, index) => (
//                   <MultiSelectOption
//                     tabIndex={tabIndex + index + 1}
//                     key={index}
//                     {...option}
//                     onClick={this.toggleSelect(option, index)}/>
//                 ))
//               }
//             </div>
//           )
//         }
//       </div>
//     )
//   }

//   toggleOpen = e => {
//     e.preventDefault();
//     this.setState({ isOpen: !this.state.isOpen })
//   }

//   toggleSelect = (option, index) => e => {
//     e.preventDefault;
//     let newOptions = [...this.state.options];
//     newOptions[index] = { ...option, isChecked: !option.isChecked };
//     this.setState({ options: newOptions });
    
//     const output = newOptions
//       .filter(o => o.isChecked)
//       .map(o => o.value);
//     console.log('toggling...', output);
//     if (this.props.onChange)
//       this.props.onChange(output);
//   }

//   clickOffComponent = e => {
//     if (this.state.isOpen && !this.node.contains(e.target))
//       this.setState({ isOpen: false});
//   }

//   onKeyPress = e => {
//     if (e.keyCode === 13 || e.keyCode === 32) {
//       if (document.activeElement === ReactDOM.findDOMNode(this.node))
//         this.setState({ isOpen: !this.state.isOpen});
//     }
//   }
// }

export const MultiSelect = ({ value, options, onChange, width, label, placeholder, tabIndex, ...props }) => {

  const [ selectedOptions, setSelectedOptions ] = useState([]);
  const [ isOpen, setIsOpen ] = useState(false);

  const element = useRef(null);

  useEffect(() => {
    if (!options) return;
    setSelectedOptions(options.map(option => ({
      ...option,
      isChecked: (value || []).some(v => v === option.value),
    })));
  }, [value, options]);

  const toggleOpen = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  const toggleSelect = (option, index) => e => {
    e.preventDefault();
    let newOptions = [...selectedOptions];
    newOptions[index] = { ...option, isChecked: !option.isChecked };
    setSelectedOptions(newOptions);
    
    const output = newOptions
      .filter(o => o.isChecked)
      .map(o => o.value);
    onChange && onChange(output);
  }

  const clickOffComponent = e => {
    if (isOpen && !element.current.contains(e.target))
      setIsOpen(false);
  }

  const onKeyPress = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      if (document.activeElement === ReactDOM.findDOMNode(element.current))
        setIsOpen(!isOpen);
    }
  }

  useEffect(() => {
    window.addEventListener('click', clickOffComponent);
    window.addEventListener('keypress', onKeyPress);

    return () => {
      window.removeEventListener('click', clickOffComponent);
      window.removeEventListener('keypress', onKeyPress);
    }
  }, [isOpen]);

  return (
      
    <div 
      className={`multi-select ${isOpen && 'open'}`} 
      style={{width}} 
      ref={element}
      {...props}
    >
      {label && <label className='label'>{label}</label>}
      <div className='select-box' onClick={toggleOpen} tabIndex = {tabIndex}>
        {
          selectedOptions.every(o => !o.isChecked) ? 
            placeholder 
            : selectedOptions
              .filter(o => o.isChecked)
              .map(o => o.name)
              .join(', ')
        }
      </div>
      {
        isOpen && (
          <div className='options-container' style={{width}}>
            {
              selectedOptions.map((option, index) => (
                <MultiSelectOption
                  tabIndex={tabIndex + index + 1}
                  key={index}
                  {...option}
                  onClick={toggleSelect(option, index)}/>
              ))
            }
          </div>
        )
      }
    </div>
  );
}



const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  border-radius: 4px;
  background: white;
  background-size: 0px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  &.checked {

    background: #2689EB;
    
    border: white;
    border-radius: 4px;

    &:after {
      display: block;
      animation: expand 300ms ease-out forwards;
    }
    
  }

  @keyframes expand {
    0% {
      transform: scale(3, 3) translate(3px,3px);
      
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

const CheckBoxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;

  font-family: 'lato';
  line-height: 20px;
  font-size: 14px;
  color: #223241;
  font-weight: 400;
  user-select: none;

  ${CheckMark}:after {
    left: 5px;
    top: 6px;
    height: 20px;
    width: 20px;
    background-image: url(${imgCheck});
    background-repeat: no-repeat;
    background-size: 10px;
  }
`;

const CheckBox = ( {title, checked } ) => (
  <CheckBoxContainer>
    {title}
    <CheckMark className={checked ? 'checked' : ''}/>
  </CheckBoxContainer>
);

const MultiSelectOption = ({ name, value, isChecked, onClick, ...props }) => (
  <div className='select-option' onClick={onClick} {...props}>
    <CheckBox title={name}
    type='checkbox' 
    checked={isChecked}/>
  </div>
);