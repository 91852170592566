import React, { Component } from 'react';

export class NumberInput extends Component {
  state = {
    currentValue: 0,
    currentString: '',
  }

  onChange = e => {
    if (e.target.value === '') {
      this.setState({currentValue: 0, currentString: ''});
      if (this.props.onChange) this.props.onChange(0);
      return;
    }
    const { currentValue, currentString } = this.state;
    const value = e.target.value.replace(/,/g, '');
    const number = parseInt(value);

    if (!isNaN(number) && number !== currentValue && Number.isSafeInteger(number)) {
      const numberString = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      e.target.value = numberString;
      this.setState({ currentValue: number, currentString: numberString });
    } else {
      e.target.value = currentString;
    }
    if (this.props.onChange) this.props.onChange(number || 0);
  }

  render() {
    const {onChange, value, ...props } = this.props;
    return (
      <input onChange={this.onChange} value={(typeof value === 'string') ? value : ''} {...props} />
    );
  }
}

export class ControlledNumberInput extends Component {
  state = {
    currentString: '',
  }

  onChange = e => {
    if (!e.target.value) {
      this.setState({currentString: ''});
      if (this.props.onChange) this.props.onChange(null);
      return;
    }
    const { currentString } = this.state;
    const value = e.target.value.replace(/,/g, '');
    const number = parseInt(value);

    if (!isNaN(number) && Number.isSafeInteger(number)) {
      const numberString = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.setState({ currentString: numberString });
    } else {
      e.target.value = currentString;
    }

    if (this.props.onChange) this.props.onChange(number);
  }

  render() {
    const {onChange, value, ...props } = this.props;
    return (
      <input onChange={this.onChange} value={value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
    );
  }
}