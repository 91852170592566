import React, { useEffect, useState, useCallback } from 'react';
import { reduxForm, Form, submit, startSubmit, stopSubmit } from 'redux-form';
import { ToggleButton, PercentInput, TextArea, Input, Select, RichTextArea, NumberInput, MoneyInputInt } from 'components/Form';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { css } from 'styled-components';

import Card from 'components/Styled/Card';
import Debouncer from 'util/debouncer';
import { Row as _Row, Column } from 'components/Styled/Layout';
import { requestPatchSelf } from 'modules/user/actions';
import { settingsService } from 'modules';
import ImageUploader from 'components/Common/ImageUploader';

import ObjectiveList from './Components/ObjectiveList';
import TextList from './Components/TextList';
import StageList from './Components/StageList';

import DocLibrary from './DocLibrary';

import { requestGetApplications } from 'modules/applications/actions';

import _ from 'lodash';
import { PageLoader } from 'components/Loader';
import { ButtonPrimary } from 'components/Styled/Button';
import store from 'store';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { createStage, patchStage, removeStage } from 'modules/applications/api';

const debouncer = new Debouncer();

const Row = styled(_Row)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const subtabPage = {
  DEFAULT: 0,

  // Proposals
  INTRO_LETTER: 0,
  MAIN_CONTENT: 1,
  GENERIC_CONTENT: 2,
  ATP: 3,

  // Supporting Docs
  DOC_LIBRARY: 0,
  TEMPLATES: 1,
};

const tabPage = {
  DEFAULT: 0,
  GENERAL: 0,
  INTRO: 1,
  PROPOSAL: 2,
  DOCS: 3,
  EMAIL: 4,
  DEAL_FLOW: 5,
};

let Settings = ({ initialize, user, applications, requestGetApplications, submitting, ...props }) => {
  const [ currentTab, setCurrentTab ] = useState(tabPage.DEFAULT);
  const [ currentSubtab, setCurrentSubtab ] = useState(subtabPage.DEFAULT);


  useEffect(() => {

    let settings = { ...user.globalSettings };
    for (const key in settings) {
      if (key.includes('json')) {
        settings[key] = JSON.parse(settings[key]) || [];
      }
    }

    const deal_stages = Object.values(applications?.stages ?? {})
      .filter(x => x.page === 'deals');
    const new_leads_stages = Object.values(applications?.stages ?? {})
      .filter(x => x.page === 'new-leads');
    const in_progress_stages = Object.values(applications?.stages ?? {})
      .filter(x => x.page === 'in-progress');
    
    let income_brackets = [ ...(settings.income_brackets ?? []) ];
    income_brackets[0] = { ...(income_brackets[0] ?? {}), from: 0 };
    income_brackets[3] = { ...(income_brackets[3] ?? {}), to: Infinity };

    initialize({ ...settings, deal_stages, new_leads_stages, in_progress_stages, income_brackets });

  }, [applications]);

  const setTab = tab => {
    setCurrentTab(tab);
    setCurrentSubtab(subtabPage.DEFAULT);
  };

  const setSubtab = tab => {
    console.log(tab);
    setCurrentSubtab(tab);
  };

  const tabProps = useCallback(page => ({
    page,
    currentTab,
    currentSubtab,
    setSubtab,
    setTab,
  }), [currentTab, currentSubtab]);
  if (applications.loading) return <PageLoader show={true}/>;

  return (
    <main>
      <Card minWidth='800px' padding='0' margin='0 0 30px 0' minHeight='0px'>
        <TabBar>
          <Tab {...tabProps(tabPage.GENERAL)}>
            General
          </Tab>
          <Tab {...tabProps(tabPage.INTRO)}>
            Intro Flow
          </Tab>
          <Tab {...tabProps(tabPage.PROPOSAL)}>
            Proposals
          </Tab>
          <Tab {...tabProps(tabPage.EMAIL)}>
            Emails
          </Tab>
          <Tab {...tabProps(tabPage.DOCS)}>
            Supporting Docs
          </Tab>
          <Tab {...tabProps(tabPage.DEAL_FLOW)}>
            Deal Flow
          </Tab>
        </TabBar>
      </Card>
      <Form {...props}>


        {/*/////////////////// General /////////////////////*/}

        <Page {...tabProps(tabPage.GENERAL)}>
          <PageCard submitting={submitting}>
            <Row>
              <ToggleButton name='client_referrals_active' title='Client Referral System Active' />
            </Row>
            <Row>
              <Label>Search - Interest Type</Label>
              <Select
                width='350px'
                name='new_user_ir_type'
                options={[
                  { name: 'Any', value: 'any'},
                  { name: 'Variable', value: 'V'},
                  { name: 'Fixed', value: 'F'}
                ]}
              />
            </Row>
            <Row>
              <Label>Search - minimum allowed interest rate</Label>
              <PercentInput width='350px' name='min_allowed_interest_rate' />
            </Row>
            <Row>
              <Label>Magic Link Expiry</Label>
              <NumberInput width='50px' name='magic_link_expiry' />
              <AfterText>days</AfterText>
            </Row>
            

            <h2>URL Access Methods</h2>
            <Row>
              <TextArea name='access_methods_text' height='500px' spellcheck='false'/>          
            </Row>
          </PageCard>
        </Page>

        {/*/////////////////// Email /////////////////////*/}

        <Page {...tabProps(tabPage.EMAIL)}>
          <PageCard submitting={submitting}>

            <h2>Magic Link Email Template</h2>
            <Row>
              <RichTextArea name='email_template_magic_link' />
            </Row>

            <h2>Proposal PDF Email Tempalate</h2>
            <Row>
              <RichTextArea name='email_template_proposal_pdf' />
            </Row>

          </PageCard>
        </Page>
        
        {/*/////////////////// Intro /////////////////////*/}
        
        <Page {...tabProps(tabPage.INTRO)}>
          <PageCard submitting={submitting}>
            <h2>Scrolling Intro</h2>
            <Row>
              <ToggleButton name='sms_verification' title='Enable SMS Verification' />
            </Row>
            <Row>
              <Label>Phone Number Heading</Label>
              <Input name='intro_phonenumber_heading' />          
            </Row>
            <Row>
              <Label>Phone Number Sub-text</Label>
              <Input name='intro_phonenumber_subtext' />          
            </Row>
            <Divider />
            
            <h2>Income Brackets</h2>
            <Row width='500px'>
              <Column width='25%' padding='0 20px 0 0'>
                <Label>Name</Label>
                <Input name='income_brackets[0].name'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>From (inclusive)</Label>
                <MoneyInputInt disabled name='income_brackets[0].from'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>To</Label>
                <MoneyInputInt name='income_brackets[0].to'/>
              </Column>
            </Row>
            <Row width='500px'>
              <Column width='25%' padding='0 20px 0 0'>
                <Label>Name</Label>
                <Input name='income_brackets[1].name'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>From (inclusive)</Label>
                <MoneyInputInt name='income_brackets[1].from'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>To</Label>
                <MoneyInputInt name='income_brackets[1].to'/>
              </Column>
            </Row>
            <Row width='500px'>
              <Column width='25%' padding='0 20px 0 0'>
                <Label>Name</Label>
                <Input name='income_brackets[2].name'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>From (inclusive)</Label>
                <MoneyInputInt name='income_brackets[2].from'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>To</Label>
                <MoneyInputInt name='income_brackets[2].to'/>
              </Column>
            </Row>
            <Row width='500px'>
              <Column width='25%' padding='0 20px 0 0'>
                <Label>Name</Label>
                <Input name='income_brackets[3].name'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>From (inclusive)</Label>
                <MoneyInputInt name='income_brackets[3].from'/>
              </Column>
              <Column width='10%' padding='0 20px 0 0'>
                <Label>To</Label>
                <MoneyInputInt disabled name='income_brackets[3].to'/>
              </Column>
            </Row>
            <Divider />
            <h2>Modals</h2>
            <Row>
              <Label>Lowest comparison rate - Home loan</Label>
              <PercentInput name='lowest_home_loan' />
            </Row>
            <Row>
              <Label>Lowest comparison rate - Investment loan</Label>
              <PercentInput name='lowest_investment_loan' />
            </Row>
            <Row>
              <Label>Cashback disclaimer</Label>
              <TextArea name='cashback_text' />          
            </Row>
            <Row>
              <Label>Comparison rate disclaimer</Label>
              <TextArea name='comparison_text' />          
            </Row>
            <Row>
              <ImageUploader fileName="promo_image.png" title="Promo Image - Desktop" width={243} height={278} />
            </Row>
            <Row>
              <ImageUploader fileName="promo_image_mobile.png" title="Promo Image - Mobile" width={375} height={130} />
            </Row>
            <Row>
              <ImageUploader fileName="promo_image_footer.png" title="Footer Image - Mobile" width={384} height={266} />
            </Row>
          </PageCard>
        </Page>
        
        {/*/////////////////// Supporting Docs /////////////////////*/}

        <Page {...tabProps(tabPage.DOCS)}>
          <DocLibrary />
        </Page>

        {/*/////////////////// Proposal /////////////////////*/}
          
        <Page {...tabProps(tabPage.PROPOSAL)}>
          <SubtabBar>
            <Subtab {...tabProps(subtabPage.INTRO_LETTER)}>
              Intro Letter
            </Subtab>
            <Subtab {...tabProps(subtabPage.MAIN_CONTENT)}>
              Main Content
            </Subtab>
            <Subtab {...tabProps(subtabPage.GENERIC_CONTENT)}>
              Generic Content
            </Subtab>
            <Subtab {...tabProps(subtabPage.ATP)}>
              ATP
            </Subtab>
          </SubtabBar>
          
          <SubPage {...tabProps(subtabPage.MAIN_CONTENT)}>
            <PageCard style={{ borderTopLeftRadius: '0'}}>
              <h2>Why Loan Base?</h2>
              <Row>
                <RichTextArea name='overview_why_body' />
              </Row>
              <Divider />

              <h2>Objectives</h2> 
              <Row>
                <RichTextArea name='overview_objectives_body' />
              </Row>
              <Divider />

              <h2>Objectives List Items</h2>
              <Row>
                <ObjectiveList name='overview_objectives_json'/>
              </Row>
              <Divider />

              <h2>Lending</h2>
              <Row>
                <RichTextArea name='overview_lending_body' />
              </Row>
              <Divider />

              <h2>Additional Details</h2>
              <Row>
                <TextList name='additional_details_json' buttonTitle='Add Text Block'/>
              </Row>
              <Divider />

              <h2>Next Steps Not Agreed</h2>
              <Row>
                <RichTextArea name='overview_next_body' />
              </Row>
              <Divider />

              <h2>Next Steps Agreed</h2>
              <Row>
                <RichTextArea name='overview_next_agreed' />
              </Row>
              <Divider />
              
              <h2>Beyond Settlement</h2>
              <Row>
                <RichTextArea name='overview_beyond_body' />
              </Row>
              <Divider />
              
              <h2>Loan Base Trail Rebate</h2>
              <Row>
                <TextArea name='overview_loanbase_rebate' />
              </Row>
              <Divider />

              <h2>Loan Base Cashback</h2>
              <Row>
                <TextArea name='overview_loanbase_cashback' />
              </Row>
              <Divider />

              <h2>Lender Cashback</h2>
              <Row>
                <TextArea name='overview_lender_cashback' />
              </Row>
            </PageCard>
          </SubPage>
          
          <SubPage {...tabProps(subtabPage.INTRO_LETTER)}>
            <PageCard style={{ borderTopLeftRadius: '0'}}>
              <h2>Intro Letter Templates</h2>
              <Row>
                <TextList name='intro_letter_json' buttonTitle='Add Template'/>
              </Row>
            </PageCard>
          </SubPage>

          <SubPage {...tabProps(subtabPage.GENERIC_CONTENT)}>
            <PageCard style={{ borderTopLeftRadius: '0'}}>
              <h2>About Loan Base</h2>
              <Row>
                <RichTextArea name='overview_pdf_about' />
              </Row>
              <Divider />

              <h2>General Terms & Conditions</h2>
              <Row>
                <RichTextArea name='overview_pdf_conditions' />
              </Row>
              <Divider />

              <h2>Loan Base Trail Rebates</h2>
              <Row>
                <RichTextArea name='overview_pdf_rebates' />
              </Row> 
              <Divider />

              <h2>Glossary Terms</h2>
              <Row>
                <TextList name='overview_pdf_glossary_json' buttonTitle='Add Term'/>
              </Row>
              
              <Row>
                <ImageUploader fileName="awards_horizontal.png" title="Awards - Horizontal" height={117} />
              </Row>

              <Row>
                <ImageUploader fileName="awards_vertical.png" title="Awards - Vertical" width={157} />
              </Row>
            </PageCard>
            
          </SubPage>

          <SubPage {...tabProps(subtabPage.ATP)}>
            <PageCard style={{ borderTopLeftRadius: '0'}}>
              <h2>Intro Letter</h2>
              <Row>
                <RichTextArea name='atp_intro' />
              </Row>
              <Divider />

              <h2>Intro Letter Final</h2>
              <Row>
                <RichTextArea name='atp_intro_final' />
              </Row>
              <Divider />

              <h2>Lending Preamble</h2>
              <Row>
                <RichTextArea name='atp_lending_preamble' />
              </Row>
              <Divider />

              <h2>Lending Preamble Final</h2>
              <Row>
                <RichTextArea name='atp_lending_preamble_final' />
              </Row>
              <Divider />

              <h2>Initial Docs Checklist</h2>
              <Row>
                <RichTextArea name='request_docs_preamble' />
              </Row>
              <Divider />

              <h2>Final Docs Checklist</h2>
              <Row>
                <RichTextArea name='request_docs_preamble_final' />
              </Row>
            </PageCard>
          </SubPage>

        </Page>

        {/*/////////////////// Deal Flow /////////////////////*/}
        <Page {...tabProps(tabPage.DEAL_FLOW)}>
          <PageCard submitting={submitting}>
            
            <h2>Client Referrer</h2>
            <Row>
              <Label>New Intro</Label>
              <RichTextArea name='referrer_intro_new' />
            </Row>

            <Row>
              <Label>Existing Intro</Label>
              <RichTextArea name='referrer_intro_existing' />
            </Row>

            <Row>
              <Label>Confirm Details</Label>
              <RichTextArea name='referrer_confirm_details' />
            </Row>

            <Row>
              <Label>Referral Form</Label>
              <RichTextArea name='referrer_referral_form' />
            </Row>

            <Row>
              <Label>More Info Modal (HTML)</Label>
              <TextArea name='referrer_info_modal' />
            </Row>
            <Divider />

            <h2>Professional Referrer</h2>
            <Row>
              <Label>New Intro</Label>
              <RichTextArea name='professional_referrer_intro_new' />
            </Row>

            <Row>
              <Label>Existing Intro</Label>
              <RichTextArea name='professional_referrer_intro_existing' />
            </Row>

            <Row>
              <Label>Referral Form</Label>
              <RichTextArea name='professional_referrer_referral_form' />
            </Row>

            <Row>
              <Label>More Info Modal (HTML)</Label>
              <TextArea name='professional_referrer_info_modal' />
            </Row>

            <Divider />
            
            <h2>Referral Stage Tooltips</h2>
            <Row>
              <Label>Referral submitted</Label>
              <Input name='referrer_tooltip_submitted' />          
            </Row>
            <Row>
              <Label>Contacted</Label>
              <Input name='referrer_tooltip_contacted' />          
            </Row>
            <Row>
              <Label>Deal in progress</Label>
              <Input name='referrer_tooltip_in_progress' />          
            </Row>
            <Row>
              <Label>Payment pending</Label>
              <Input name='referrer_tooltip_payment_pending' />          
            </Row>
            <Row>
              <Label>On hold</Label>
              <Input name='referrer_tooltip_on_hold' />          
            </Row>
            <Row>
              <Label>Not proceeding</Label>
              <Input name='referrer_tooltip_not_proceeding' />          
            </Row>
            <Divider />

            <h2>Current Deal Stages</h2>
            <Row>
              <StageList name='deal_stages' buttonTitle='Add Stage'/>
            </Row>
            <Divider />

            <h2>In Progress Stages</h2>
            <Row>
              <StageList name='in_progress_stages' buttonTitle='Add Stage'/>
            </Row>
            <Divider />
            
            <h2>New Leads Stages</h2>
            <Row>
              <StageList name='new_leads_stages' buttonTitle='Add Stage'/>
            </Row>

          </PageCard> 
        </Page>
      </Form>
      
    </main>
  );
};

const Page = ({ page, children, currentTab }) => {
  return (
    <PageContainer display={currentTab === page}>
      {children}
    </PageContainer>
  );
};

const PageCard = ({ children, submitting }) => {
  const dispatch = useDispatch();
  const onSave = () => {
    dispatch(submit('settings'));
  };
  return (
    <Card>
      <SaveButton width='150px' type='button'  onClick={onSave}>Save Changes <ButtonSpinner style={{marginLeft: '10px'}} show={submitting}/></SaveButton>
      {children}
      <div style={{ marginBottom: '30px'}}>
        <SaveButton width='150px' type='button' onClick={onSave}>Save Changes <ButtonSpinner style={{marginLeft: '10px'}} show={submitting}/></SaveButton>
      </div>
    </Card>
  );
};

const SubPage = ({ page, children, currentSubtab }) => {
  return (
    <PageContainer display={currentSubtab === page}>
      {children}
    </PageContainer>
  );
};


const Tab = ({ page, children, currentTab, setTab }) => (
  <TabBase onClick={() => setTab(page)} current={currentTab === page} >
    {children}
  </TabBase>
);

const Subtab = ({ page, children, currentSubtab, setSubtab }) => {
  return (
    <SubtabBase onClick={() => setSubtab(page)} active={currentSubtab === page} >
      {children}
    </SubtabBase>
  );
};

const PageContainer = styled.div`
  display: ${props => props.display ? 'block' : 'none'};
`;

const SaveButton = styled(ButtonPrimary)`
  float: right;
`;

Settings = styled(Settings)`
  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  h2:not(:first-child) {
    margin-top: 50px;
  }

  /* button  {
    display: block;
    margin-top: 10px;
  } */
`;

const AfterText = styled.span`
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
`;

const Divider = styled.div`
  width: 100%;
  border-top: solid 1px #DCDFE9;
  margin-top: 60px;
`;

const TabBar = styled.div`
  height: 60px;
  width: 100%;
  overflow: hidden;
`;

const TabBase = styled.div`
  width: 125px;
  height: 37px;
  display: inline-block;
  margin: 23px 0 0 40px;
  text-align: center;
  font-size: 14px;
  color: #727C8F;
  margin-bottom: 40px;
  cursor: pointer;
  ${p => p.current && css`
    color: #101922;
    border-bottom: solid 2px #2291FF;
  `}
`;

const SubtabBar = styled.div`
  width: 100%;
  margin-bottom: -1px;
  margin-top: 40px;
  overflow: visible;
`;


const SubtabBase = styled.div`
  min-width: 188px;
  padding: 18px 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #ECECEC;
  border: 1px solid #D8DCE7;
  color: #4F4F4F;
  position: relative;
  cursor: pointer;

  ${p => p.active && css`
    background-color: #fff;
    border-bottom: none;
    color: #101922;
    cursor: default;

    &:after {
      content: '';
      background-color: #fff;
      /*   */
      height: 5px;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      z-index: 1000;
      
    }

    &:hover {
      border-bottom: none;
    }
  `}

  &:hover {
    background-color: #fff;
  }
`;

const stateToProps = state => ({
  user: state.user,
  applications: state.applications,
});

const dispatchToProps = dispatch => 
  bindActionCreators(
    { 
      requestPatchSelf,
      requestGetApplications,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(reduxForm({ 
  form: 'settings',
  onSubmit: () => {
    store.dispatch(startSubmit('settings'));
    const state = store.getState();
    const values = state.form.settings.values;
    console.log('patching');
    let dealsPatch;
    let inProgressPatch;
    let newLeadsPatch;

    values.sms_verification = values.sms_verification ? 1 : 0;
    values.client_referrals_active = values.client_referrals_active ? 1 : 0;

    const patch = {...values};
    for (const key in patch) {
      if (key.includes('json')) {
        patch[key] = JSON.stringify(patch[key]);
      }
    }
    if (values.deal_stages) {
      dealsPatch = values.deal_stages;
    }
    if (values.in_progress_stages) {
      inProgressPatch = values.in_progress_stages;
    }
    if (values.new_leads_stages) {
      newLeadsPatch = values.new_leads_stages;
    }

    const updates = [];

    if (dealsPatch) updates.push(saveStages(dealsPatch, state.applications.stages, store, 'deals'));
    if (inProgressPatch) updates.push(saveStages(inProgressPatch, state.applications.stages, store, 'in-progress'));
    if (newLeadsPatch) updates.push(saveStages(newLeadsPatch, state.applications.stages, store, 'new-leads'));
    updates.push(settingsService.patch(1, patch));
    
    store.dispatch(requestPatchSelf({
      data: {
        globalSettings: patch,
      }
    }));

    const update = async () => {
      await Promise.all(updates);
      store.dispatch(stopSubmit('settings'));
    };
    update();
    
  },   
})(Settings));

const saveStages = async (newStages = [], applicationStages = {}, store, page) => {
  const oldStages = Object.values(applicationStages ?? {}).filter(x => x.page === page);
  const toCreate = newStages.filter(x => !x.id);
  const toPatch = newStages.filter(x => {
    const stage = oldStages.find(y => x.id === y.id);
    if (!stage) return false;
    return !_.isEqual(x, stage);
  });
  const toDelete = oldStages.filter(x => !newStages.some(y => x.id === y.id));
  let nextId = 0;
  for (const id in applicationStages) {
    nextId = Math.max(nextId, id);
  }

  for (const stage of toCreate) {
    nextId += 1;
    await createStage({ name: '', ...stage, id: nextId, page, hidden: false });
  }

  for (const stage of toDelete) {
    await removeStage(stage.id);
  }

  for (const stage of toPatch) {
    await patchStage({ ...stage, applications: undefined });
  }
  
  console.log({ toCreate, toPatch, toDelete, newStages, oldStages, nextId });
};