import { brokerService } from 'modules';

export async function getBrokers() {
  try {
    const { data } = await brokerService.find({query: { $limit: 1000 }});
    return data;
  } catch(err) {
    console.log(err);
    return [];
  } 
}

export async function patchBroker({ data }) {
  try {
    data = {...data, ...{
      lastLogin: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      name: data.firstName + ' ' + data.lastName,
    }};
    return await brokerService.patch(data.id, data);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createBroker(broker) {
  try {
    broker = {...{
      name: broker.firstName + ' ' + broker.lastName,
    }, ...broker};
    return await brokerService.create(broker);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}