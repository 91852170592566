import { 
  RECEIVE_FIND_BROKERS,
  RECEIVE_PATCH_BROKER,
  RECEIVE_CREATE_BROKER,
  RECEIVE_DELETE_BROKER,
} from './actions';

const brokers = (state = [], { type, payload }) => {
  
  switch (type) {
  case RECEIVE_FIND_BROKERS: {
    return payload;
  }
  case RECEIVE_PATCH_BROKER: {
    const broker = payload;
    const index = state.findIndex(u => u.id === broker.id);
    if (index > -1) state[index] = broker; 
    return state;
  }
  case RECEIVE_CREATE_BROKER: {
    const broker = payload;
    return [...state, broker];
  }
  case RECEIVE_DELETE_BROKER: {
    return state.filter(broker => broker.id !== payload);
  }
  default:
    return state;
  }
};

export default brokers;

