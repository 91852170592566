import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import TextBox from 'components/Styled/TextInput';

import imgDownArrow from 'img/button/down-blue.png';
import imgUpArrow from 'img/button/up-blue.png';


import { Debouncer } from 'util/index';

const ErrorMessage = styled.div`
  position: absolute;
  color: red;
  font-size: 11px;
  padding-top: 4px;
`;

const SelectBox = styled(TextBox)`
  display: relative;
  padding: 0px;
  height: ${p => p.height || '40px'} !important;
  line-height: ${p => p.height || '40px'};
  vertical-align: top;
  background-color: white !important;
  background-image: url(${imgDownArrow});
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  transition: none;

  &.open {
    border-radius: 4px 4px 0px 0px !important;
    background-image: url(${imgUpArrow});
  }
`;

const SelectBoxDisabled = styled(SelectBox)`
  background-color: #d8dce75e !important;
  color: #545454;
  // background-image: none;
  cursor: no-drop;
`;



const OptionContainer = styled.div`
    position: absolute;
    background-color: white;
    border: 1px solid #D8DCE7;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 3px 7px 0px #0000001f;
    z-index: 100;
    margin-left: -1px;
    margin-top: -1px;
`;

const Option = styled.div`
  padding-left: 15px;
  cursor: pointer;
  user-select: none;
  &:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &:hover {
    background-color: #f3f3f3
  }
`;

// class SelectXXX extends Component {
//   constructor() {
//     super();
//     this.state = {
//       isOpen: false,
//       selectedIndex: 0,
//       search: '',
//       isKeyboardMode: false,
//       value: null,
//     };
//     this.debouncer = new Debouncer();
//     this.width = 0;
//     this.node = React.createRef();
//   }

//   // componentDidUpdate(prevProps) {
    
//   //   if (this.props.value !== prevProps.value || (this.props.input && this.props.input.value !== prevProps.input.value)) {
//   //     let { options, value } = this.props;
//   //     if (!value && this.props.input) value = this.props.input.value;

//   //     if (!value && this.props.input) value = this.props.input.value;
//   //     if (value) {
//   //       const { index } = options
//   //         .map((option, index) => ({option, index}))
//   //         .find(i => i.option.value === value)
//   //         || {index: 0};
//   //       this.setState({ selectedIndex: index });
//   //     }
    
//   //   }
//   // }

//   static getDerivedStateFromProps(nextProps, prevState) {
//     if (nextProps.input.name === 'houseId') {
//       debugger;
//     }
//     if (nextProps.input.value !== prevState.value) {
//       const { options } = nextProps;

//       const { index } = options
//           .map((option, index) => ({option, index}))
//           .find(i => i.option.value === nextProps.input.value)
//           || {index: 0};
//       return { selectedIndex: index, value: nextProps.input.value }
//     }
    
//   }
  

//   componentDidMount() {
//     var { options, value } = this.props;
//     if (!value && this.props.input) value = this.props.input.value;
//     if (value) {

//       const { index } = options
//         .map((option, index) => ({option, index}))
//         .find(i => i.option.value === value)
//         || {index: 0};
//       this.setState({ selectedIndex: index });
//     }
//     window.addEventListener('click', this.clickOffComponent);

//     this.resizeInterval = setInterval(() => {
//       this.width = this.node.current.offsetWidth;
//     }, 500);
    
//     this.node.current.addEventListener('keydown', this.keyPressed);
//     this.node.current.addEventListener('keypress', this.charEntered);
//     this.node.current.addEventListener('blur', this.blurred);

//     if (this.props.reference) {
//       this.props.reference(this.node.current);
//     }
//   }

//   componentWillUnmount() {
//     clearInterval(this.resizeInterval);
//     window.removeEventListener('click', this.clickOffComponent);
//     this.node.current.removeEventListener('keydown', this.keyPressed);
//     this.node.current.removeEventListener('keypress', this.charEntered);
//     this.node.current.removeEventListener('blur', this.blurred);
    
//   }

//   render() {
//     const { isOpen, search, isKeyboardMode } = this.state;
//     const { meta, options, isSearchable, width, value, height, disabled, ...props } = this.props;
//     if (disabled) return (
//       <SelectBoxDisabled
//         {...props}
//         as='div' 
//         width={width} 
//         tabIndex={0}
//         height={height}
//         ref={this.node} 
//       >
//         <span style={{marginRight: '15px'}}></span>
//         {options[this.state.selectedIndex].name}
//       </SelectBoxDisabled>
//     );
//     return (
//       <Fragment>
//         <SelectBox 
//           {...props}
//           as='div' 
//           width={width} 
//           tabIndex={0} 
//           ref={this.node} 
//           onClick={this.toggleOpen}
//           className={isOpen ? 'open' : ''}
//           height={height}>

//           <span style={{marginRight: '15px'}}></span>
//           {options[this.state.selectedIndex].name}
//           {
//             isOpen && (
//               <OptionContainer style={{ width: this.width + 'px', maxHeight: '300px', overflowY: 'auto'}}>
//                 {
//                   options
//                     .map((option, index) => (
//                       <SelectOption
//                         isHighlighted={isKeyboardMode && this.state.selectedIndex === index}
//                         key={index}
//                         {...option}
//                         onClick={() => this.select(index)} />
//                     ))
//                 }
//               </OptionContainer>
//             )
//           }
//         </SelectBox>
//         {meta && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>} 
//       </Fragment>
      
//     );
//   }

  

//   moveIndex = step => {
//     const { options } = this.props;
//     const { selectedIndex, isOpen } = this.state;
//     if (isOpen) {
//       this.setState({ isKeyboardMode: true });
//     }
//     const newIndex = selectedIndex + step;
//     if (newIndex >= 0 && newIndex < options.length) {
//       this.select(newIndex);
//     }
//   }

//   blurred = () => {
//     this.setState({ isKeyboardMode: false, isOpen: false });
//   }

//   keyPressed = async e => {
//     if (!e.target === this.node.current) return;


//     switch (e.keyCode) {
//       case 13: //Enter
//       case 27: //Esc
//         await this.setState({ isKeyboardMode: true });
//         this.toggleOpen(e);
//         e.preventDefault();
//         break;
//       case 38: //Up key
//         this.moveIndex(-1);
//         e.preventDefault();
//         break;
//       case 40: //Down Key
//         this.moveIndex(1);
//         e.preventDefault();
//     }
//   };

//   charEntered = async e => {
//     const input = String.fromCharCode(e.keyCode);
//     if (/[a-zA-Z0-9-_ ]/.test(input)) {
//       await this.setState(state => ({ ...state, search: this.state.search.concat(input) }));
//       this.search(this.state.search);
//       this.debouncer.run(
//         () => this.setState(state => ({ ...state, search: '' }))
//         , 350);

//     }
//   }

//   search = query => {
//     const results = this.props.options
//       .map((o, i) => ({ names: o.name.split(' '), index: i }))
//       .find(o => o.names.some(name => caseInsensitiveStartsWith(name, query)));
//     if (results) {
//       this.select(results.index);
//     }
//   }

//   toggleOpen = e => {
//     e.preventDefault();
//     if (this.state.isOpen) {
//       this.setState({ isKeyboardMode: false });
//     }
//     this.setState({ isOpen: !this.state.isOpen });
//   }

//   select = index => {
//     if (index === this.state.selectedIndex) {
//       return;
//     }
//     this.setState({ selectedIndex: index });
//     if (!this.state.isKeyboardMode) {
//       this.setState({ isOpen: false });
//     }

//     //console.log(this.props);
//     if (this.props.onChange) {
//       this.props.onChange(this.props.options[index].value);
//     }

//     if (this.props.input && this.props.input.onChange) {
//       this.props.input.onChange(this.props.options[index].value);
//     }
     
//   }

//   clickOffComponent = e => {
//     if (this.state.isOpen && !this.node.current.contains(e.target))
//       this.setState({ isOpen: false, isKeyboardMode: false });
//   }
// }

const SelectOption = ({ name, onClick, isHighlighted }) => (
  <Option 
    onClick={onClick}
    style={isHighlighted ? { background: '#2291FF', color: 'white' } : {}}>
    {name}
  </Option>
);

const caseInsensitiveStartsWith = (string, filter) =>
  string && string.toLowerCase().startsWith(filter.toLowerCase());




class Select extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isKeyboardMode: false,
      search: '',
    };
    this.debouncer = new Debouncer();
    this.width = 0;
    this.node = React.createRef();
  }

  charEntered = async e => {
    const input = String.fromCharCode(e.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(input)) {
      await this.setState(state => ({ ...state, search: this.state.search.concat(input) }));
      this.search(this.state.search);
      this.debouncer.run(
        () => this.setState(state => ({ ...state, search: '' }))
        , 350);

    }
  }

  moveIndex = step => {
    const { options } = this.props;
    const value = this.props.value || this.props.input.value;
    const { isOpen } = this.state;
    const selectedIndex = options.findIndex(o => o.value === value);
    if (selectedIndex === -1) {
      this.select(options[0].value);
    }
    
    if (isOpen) {
      this.setState({ isKeyboardMode: true });
    }

    const newIndex = selectedIndex + step;
    if (newIndex >= 0 && newIndex < options.length) {
      this.select(options[newIndex].value);
    }
  }

  blurred = () => {
    this.setState({ isKeyboardMode: false, isOpen: false });
  }

  keyPressed = async e => {
    if (!e.target === this.node.current) return;


    switch (e.keyCode) {
      case 13: //Enter
      case 27: //Esc
        await this.setState({ isKeyboardMode: true });
        this.toggleOpen(e);
        e.preventDefault();
        break;
      case 38: //Up key
        this.moveIndex(-1);
        e.preventDefault();
        break;
      case 40: //Down Key
        this.moveIndex(1);
        e.preventDefault();
    }
  };

  componentDidMount() {
    
    window.addEventListener('click', this.clickOffComponent);

    this.resizeInterval = setInterval(() => {
      this.width = this.node.current.offsetWidth;
    }, 500);
    
    this.node.current.addEventListener('keydown', this.keyPressed);
    this.node.current.addEventListener('keypress', this.charEntered);
    this.node.current.addEventListener('blur', this.blurred);

    if (this.props.reference) {
      this.props.reference(this.node.current);
    }
  }

  componentWillUnmount() {
    clearInterval(this.resizeInterval);
    window.removeEventListener('click', this.clickOffComponent);
    this.node.current.removeEventListener('keydown', this.keyPressed);
    this.node.current.removeEventListener('keypress', this.charEntered);
    this.node.current.removeEventListener('blur', this.blurred);
    
  }

  search = query => {
    const results = this.props.options
      .map((o) => ({ names: o.name.split(' '), value: o.value }))
      .find(o => o.names.some(name => caseInsensitiveStartsWith(name, query)));
    if (results) {
      this.select(results.value);
    }
  }

  toggleOpen = e => {
    e.preventDefault();
    if (this.state.isOpen) {
      this.setState({ isKeyboardMode: false });
    }
    this.setState({ isOpen: !this.state.isOpen });
  }

  clickOffComponent = e => {
    if (this.state.isOpen && !this.node.current.contains(e.target))
      this.setState({ isOpen: false, isKeyboardMode: false });
  }

  select = value => {
    const oldValue = this.props.value || (this.props.input && this.props.input.value);

    if (value !== oldValue) {
      if (this.props.onChange) this.props.onChange(value);
      if (this.props.input && this.props.input.onChange) this.props.input.onChange(value);
    }
    if (!this.state.isKeyboardMode) {
      this.setState({ isOpen: false });
    }
  }


  render() {
    const { meta, options, isSearchable, width, height, disabled, maxHeight, ...props } = this.props;
    const { isOpen, isKeyboardMode } = this.state;
    
    const value = this.props.value || (this.props.input ? this.props.input.value : '');
    let selected = (options || []).find(o => o.value === value);
    if (!selected) selected = options[0] || {name: '', value: ''};

    if (disabled) return (
      <SelectBoxDisabled
        {...props}
        as='div' 
        width={width} 
        tabIndex={0}
        height={height}
        ref={this.node} 
      >
        <span style={{marginRight: '15px'}}></span>
        {selected.name}
      </SelectBoxDisabled>
    );
    return (
      <Fragment>
        <SelectBox 
          {...props}
          as='div' 
          width={width} 
          tabIndex={0} 
          ref={this.node} 
          onClick={this.toggleOpen}
          className={isOpen ? 'open' : ''}
          height={height}>

          <span style={{marginRight: '15px'}}></span>
          {selected.name}
          {
            isOpen && (
              <OptionContainer style={{ width: this.width + 'px', maxHeight: maxHeight ?? '300px', overflowY: 'auto'}}>
                {
                  options
                    .map((option, index) => (
                      <SelectOption
                        isHighlighted={isKeyboardMode && selected.value === option.value}
                        key={index}
                        {...option}
                        onClick={() => this.select(option.value)} />
                    ))
                }
              </OptionContainer>
            )
          }
        </SelectBox>
        {meta && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>} 
      </Fragment>
      
    );
  }
}


export default Select;