import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useSelector } from 'react-redux';
import { ButtonPlain } from 'components/Styled/Button';
import { SearchBar, MultiSelect } from 'components/Form';
import { isIOS } from 'util/getDevice';

import { requestClientLogin } from 'modules/user/actions';
import {
  requestGetApplications,
} from 'modules/applications/actions';

import imgQuestionmark from 'img/icons/question-mark-white.png';

import { connect } from 'react-redux';

import { 
  Table, 
  TableRow, 
  TableCell, 
  TableHeader, 
  SortableHeaderCell as HeaderCell, 
  ScrollableBody,
  tableStyle } from 'components/Table';
import { relative } from 'path';
import { clientAppUrl } from 'modules';
import ControlPanel from 'components/Form/ControlPanel';

const statusOptions = [
  {name:'Not Eligible', value: 4},
  {name:'Cancelled', value: 5},
  {name:'Not Proceeding', value: 6},
  {name:'Unfinished', value: 7},
];

const StateUnfinished = () => (
  <div style={{color: '#2291FF'}}>
    <Circle>
      <ImgQuestionMark/>
    </Circle>
    Unfinished
  </div>
);

const StateNotEligible = () => (
  <div style={{color: '#F5A623'}}>
    <Circle colour='#F5A623'>
      <ImgQuestionMark/>
    </Circle>
    Not Eligible
  </div>
);

const StateCancelled = () => (
  <div style={{color: '#FF4E4C'}}>
    <Circle colour='#FF4E4C'>
      <ImgQuestionMark/>
    </Circle>
    Cancelled
  </div>
);

const StateNotProceeding = () => (
  <div style={{color: '#727C8F'}}>
    <Circle colour='#727C8F'>
      <ImgQuestionMark/>
    </Circle>
    Not Proceeding
  </div>
);

const Circle = styled.div`
  position: relative;
  top: -2px;
  background: ${p => p.colour || '#2291FF'};
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 20px;
`;


const ImgQuestionMark = styled.img.attrs({
  src: imgQuestionmark,
  width: 7,
})`
  position: absolute;
  top: 4px;
  left: 5.5px;
`;


class Archive extends Component {
  
  state = {
    search: '',
    sortField: 'updatedAt',
    sortDirection: -1,
    statusFilters: [],
  } 

  logInAsClient(email) {

    let siteDomain = clientAppUrl;

    let windowReference;
    
    if (isIOS) {
      windowReference = window.open('', '_blank');
    }

    this.props.requestClientLogin({data: {
      email
    },
    callback: (result)=>{
      const url = `${siteDomain}?email=${email}&loginToken=${result.token}&godmode=${result.godmode}`;
      if (isIOS) {
        windowReference.location = url;
      } else {
        window.open(url, '_blank');
      }
    }
    });
  }

  componentDidMount() {
    const { meta } = this.props.user;
    const { requestGetApplications, applications } = this.props;
    // if (!(applications && applications.list && !applications.loading)) {
    //   requestGetApplications();
    // }
    let status;
    try {
      status = JSON.parse(meta.archiveStatus);
    } catch (err) {}
    if (!status) status = [];
    this.setState({ statusFilters: status, search: meta.archiveSearch || '' })
  }

  filterApplications(app) {
    let { search, statusFilters } = this.state;
    let { applications } = this.props;
    search = (search ?? '').toLowerCase();

    const stage = applications.stages[app.list];
    const firstChecks = 
      stage?.page === this.props.page &&
      app.isArchived &&
      ((app.client.firstName && app.client.firstName.toLowerCase().includes(search)) ||
      (app.client.lastName && app.client.lastName.toLowerCase().includes(search)) ||
      (app.client.phoneNumber && app.client.phoneNumber.includes(search)));

    const secondChecks = !statusFilters.length || statusFilters.some(f => f === app.list);

    return firstChecks && secondChecks;
  }

  
  sort = (a, b) => {
    const { sortDirection = -1, sortField = 'updatedAt' } = this.state;
    const keys = sortField.split('.');
    for (const key of keys) {
      a = a[key];
      b = b[key];
    }
    
    if (a == null && b == null) return 0;
    if (a == null) return sortDirection;
    if (b == null) return -sortDirection;
    if (typeof a === 'string' && typeof b === 'string') {
      return (a.toLowerCase() < b.toLowerCase()) ? -sortDirection : sortDirection;
    } else {
      return (a - b) * sortDirection;
    }
  };

  onControlChange = ({ archiveStatus, archiveSearch }) => {
    const statusFilters = JSON.parse(archiveStatus || '[]');
    if (statusFilters !== this.state.statusFilters) this.setState({ statusFilters });
    if (archiveSearch !== this.state.search) this.setState({ search: archiveSearch });
  }
  
  render() {
    const { applications, history } = this.props;
    const { search, sortField, sortDirection } = this.state;
    
    const filterOptions = Object.values(applications.stages ?? {})
      .filter(x => x.page === this.props.page)
      .sort((a, b) => a.stageIndex - b.stageIndex)
      .map(x => ({ name: x.name, value: x.id }));

    return (
      <main className="main client-search" >
        <div className="client-search-container">
          <ControlPanel callback={this.onControlChange}>
            <div>
              <div style={{ margin: '0 0 40px 40px', minWidth: '800px' }}>
                <div className='inline-block'>
                  <MultiSelect
                    name='archiveStatus'
                    label='Filter by Status'
                    tabIndex={100}
                    options={filterOptions}
                    width='300px'
                    placeholder='No filters selected'
                  />
                </div>
                <div className='inline-block' style={{ margin: '26px 0 0 17px' }}>
                  <SearchBar
                    name='archiveSearch'
                    tabIndex={200}
                    width='187px'
                    placeholder='Search'
                    msDebounce={300}
                  />
                </div>
              </div>
            </div>
          </ControlPanel>
          

          <Table styling={tableStyle.checked} onSort={({ field, direction })=> this.setState({ sortField: field, sortDirection: direction })} sortField='updatedAt' sortDirection={-1} width='calc(100% - 5px)'>
            <TableHeader>
              <TableRow >
                <HeaderCell width='14.285%' fieldName='client.lastName'>Last Name</HeaderCell>
                <HeaderCell width='14.285%' fieldName='client.firstName'>First Name</HeaderCell>
                <HeaderCell width='14.285%' fieldName='client.phoneNumber'>Primary Phone</HeaderCell>
                <TableCell width='14.285%'>Status</TableCell>
                <HeaderCell width='14.285%' fieldName='updatedAt'>Last Action</HeaderCell>
                <TableCell width='14.285%'>View</TableCell>
                <TableCell width='14.285%'>Login as Client</TableCell>
              </TableRow>
            </TableHeader>
          </Table>
          <Table styling={tableStyle.checked} width='100%'>
            <ScrollableBody topPos='187px'>
              {applications.list && Object.values(applications.list)
                .filter(app => this.filterApplications(app))
                .sort(this.sort)
                .map(app => (
                  <TableRow key={app.id}>
                    <TableCell width='1000px'>{app.client.lastName}</TableCell> 
                    <TableCell width='14.285%'>{app.client.firstName}</TableCell>
                    <TableCell width='14.285%'>{app.client.phoneNumber}</TableCell>
                    <TableCell width='14.285%'>
                      {applications.stages[app.list]?.name}
                      {/* {app.list === 4 && <StateNotEligible/>}
                      {app.list === 5 && <StateCancelled/>}
                      {app.list === 6 && <StateNotProceeding/>}
                      {app.list === 7 && <StateUnfinished/>} */}
                    </TableCell>
                    <TableCell width='14.285%'>
                      {app.updatedAt && `${new Date(app.updatedAt).toLocaleTimeString('en-AU', { hour: '2-digit', minute: '2-digit' })}, `
                      + `${new Date(app.updatedAt).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}`}
                    </TableCell>
                    <TableCell width='14.285%'>
                      <ButtonPlain height='32px' width='110px' onClick={() => history.push('view?id=' + app.id)}>View Details</ButtonPlain>
                    </TableCell>
                    <TableCell width='14.285%'>
                      <ButtonPlain height='32px' width='110px' onClick={() => this.logInAsClient(app.client.email)}>Login</ButtonPlain>
                    </TableCell>
                  </TableRow>
                ))



              }
              
            </ScrollableBody>
          </Table>
        </div>
      </main>
    );
  }
}


const stateToProps = state => ({
  applications: state.applications,
  user: state.user,
});

const dispatchToProps = dispatch => 
  bindActionCreators({
    requestClientLogin,
    requestGetApplications,
  }, dispatch);

export default withRouter(connect(stateToProps, dispatchToProps)(Archive));