import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Heading, Label, ToggleContainer, BigBlueButton, Row, Column, PlainButton, Templates, Library, TemplateItems, 
  RequestColumn, ListItem, TemplateItem, LibraryRequest, BigTrashButton, ActionButtons, ButtonsLeft, Saved, Unsaved, 
  ButtonImage, Important, ApplicantCircle, ApplicantRow } 
  from './DocShared';
import Select from 'components/Styled/Select';
import { useSelector } from 'react-redux';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { DocForm, TemplateForm } from './DocForms';
import _ from 'lodash';


import { ToggleButton } from 'components/Styled/CheckBox';
import Input from 'components/Styled/TextInput';

export const EditDocModal = ({ modal, onSubmit, heading, document = {} }) => {

  const [ saving, setSaving ] = useState(false);

  const save = values => {
    console.log('vals!!!!!: ', values)
    onSubmit(values);
    setSaving(true);
  };
  
  document.isUrgent = document.isUrgent ? 1 : '';
  document.isPropertyDoc = document.isPropertyDoc ? 1 : '';
  document.isBankStatement = document.isBankStatement ? 1 : '';

  return (
    <ModalBox width='420px'>
      <Column width='100% '>
        <ModalHeading>{heading ?? 'Add Request'}</ModalHeading>
        <Label>Document Name</Label>
        <DocForm form='docs-modal' initialValues={document} onSubmit={save} saving={saving}/>
      </Column>
    </ModalBox>
  );
};

export const ViewDocModal = ({ modal, heading, document = {} }) => {

  document.isUrgent = document.isUrgent ? 1 : '';
  document.isPropertyDoc = document.isPropertyDoc ? 1 : '';
  document.isBankStatement = document.isBankStatement ? 1 : '';

  return (
    <ModalBox width='420px' tabIndex={-1}>
      <Column width='100% '>
        <ModalHeading>{heading ?? 'View Request'}</ModalHeading>
        <Label>Document Name</Label>
        {/* <DocForm form='docs-modal' initialValues={document} onSubmit={save} saving={saving}/> */}
        <Input disabled value={document.name}/>
        <Label style={{ marginTop: '25px' }}>Description</Label>
        <TextArea disabled dangerouslySetInnerHTML={{ __html: document.description}}/>
        <OptionsContainer>
          <ToggleContainer>
            <ToggleButton readOnly checked={document.isPropertyDoc}/>
              Is a property document
          </ToggleContainer>
          <ToggleContainer>
            <ToggleButton readOnly checked={document.isUrgent}/>
              Flag as urgent
          </ToggleContainer>
          <ToggleContainer>
            <ToggleButton readOnly checked={document.isBankStatement}/>
              Bank statement
          </ToggleContainer>
        </OptionsContainer>
        <BigBlueButton type='button' style={{ marginTop: '29px' }} onClick={modal.close}>Done</BigBlueButton>
      </Column>
    </ModalBox>
  );
};

export const EditTemplateModal = ({ modal, onSubmit }) => {

  const [ saving, setSaving ] = useState(false);

  const save = values => {
    onSubmit(values);
    setSaving(true);
  };

  return (
    <ModalBox width='420px'>
      <Column width='100% '>
        <ModalHeading>Add Template</ModalHeading>
        <TemplateForm form='templates-modal' onSubmit={save} saving={saving}/>
      </Column>
    </ModalBox>
  );
};


export const ApplyTemplateModal = ({ modal, onSubmit, templateId }) => {

  let templates = _.cloneDeep(useSelector(state => state.documents?.templates) ?? []);
  let user = _.cloneDeep(useSelector(state => state.clients.current));
  const [ saving, setSaving ] = useState(false);
  const [ activeApplicants, setActiveApplicants ] = useState([ true, true, true, true ]);
  const [ selectedTemplate, setSelectedTemplate ] = useState(templateId);

  const toggleApplicant = (index) => { 
    const applicants = [ ...activeApplicants ];
    applicants[index] = !applicants[index];
    setActiveApplicants(applicants);
  };
  
  const noOfApplicants = user.numberOfApplicants || 1;
  //debugger;
  const save = () => {
    onSubmit({ templateId: selectedTemplate, meta: { applicants: activeApplicants, approved: [false, false, false, false] } });
    setSaving(true);
  };

  return (
    <ModalBox width='420px'>
      <Column width='100% '>
        <ModalHeading>Apply Template</ModalHeading>
        <Label>Template Name</Label>
        <Select value={selectedTemplate} onChange={e => setSelectedTemplate(e.target.value)} style={{ marginBottom: '15px'}}>
          {templates
            .sort((a, b) => a.name?.localeCompare(b.name, 'en', { numeric: true }))
            .map(template => 
              <option key={template.id} value={template.id}>{template.name}</option>  
            )
          }
        </Select>
        <Label>Select Applicants</Label>
        <ApplicantRow>
          <ApplicantCircle number={1} active={activeApplicants[0]} onClick={() => toggleApplicant(0)}/>
          {noOfApplicants > 1 && <ApplicantCircle number={2} active={activeApplicants[1]} onClick={() => toggleApplicant(1)}/>}
          {noOfApplicants > 2 && <ApplicantCircle number={3} active={activeApplicants[2]} onClick={() => toggleApplicant(2)}/>}
          {noOfApplicants > 3 && <ApplicantCircle number={4} active={activeApplicants[3]} onClick={() => toggleApplicant(3)}/>}
        </ApplicantRow>
      </Column>
      <BigBlueButton type='button' style={{ marginTop: '10px' }} onClick={save}><ButtonSpinner show={saving}/>Apply</BigBlueButton>
    </ModalBox>
  );
};

const ModalHeading = styled.h2`
  display: block;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
`;

const OptionsContainer = styled.div`
  margin-top: 15px;
`;

// export const ToggleContainer = styled.div`
//   margin-bottom: 10px;
//   font-size: 14px;
// `;

const TextArea = styled.div`
  color: #101922;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0; 
  resize: none;
  width: ${p => p.width || '100%'};
  border: 1px solid #d8dce7;
  border-radius: 4px;
  background: #ffffff;
  color: #101922;
  padding: 10px 12px 10px 12px;
  transition: all 300ms;

  ${p => p.disabled && css`
  background-color: #d8dce75e !important;
    color: #545454;
    cursor: no-drop;
  `}

  line-height: 20px;
  min-height: 100px;
  /* color: #575E6C; */
  
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }
`;
