import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PathNavLink } from 'components/Common/PathNavLink.jsx';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import { requestPatchUser } from 'modules/clients/actions';
import { getQueryVariables } from 'util/index';

import { requestUser } from 'modules/clients/actions';

import PropertyDetails from './Tabs/PropertyDetails';
import LoansView from './Tabs/LoansView';
import NonMortgageDebt from './Tabs/NonMortgageDebt';
import DeletedItems from './Tabs/DeletedItems';
import Card from 'components/Styled/Card';

class ClientProfile extends Component {

  constructor() {
    super();
    this.state = {
      userId: undefined,
      loaded: false,
    };
  }

  componentWillMount() {
    const { id } = getQueryVariables(this.props.location.search);
    this.setState({ userId: id });
  }

  render() {
    const { userId, loaded } = this.state;

    return (
      <div className="client-profile" style={{ width: '100%' }}>
        <TabBar>
          <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/clientprofile/propertydetails?id=${userId}`}>
            🏡  Property Details
          </Tab>
          <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/clientprofile/loandetails?id=${userId}`}>
            📈  Loan Details
          </Tab>
          <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/clientprofile/nonmortgagedebt?id=${userId}`}>
            💰  Non-mortgage Debt
          </Tab>
          <Tab as={PathNavLink} activeClassName='current' to={`/clients/view/clientprofile/deleted?id=${userId}`}>
            🗑️  Deleted Items
          </Tab>
        </TabBar>

        <Routes />
        
      </div>
    );
  }
}

const Routes = () => (
  <Switch>
    <Route exact path="/clients/view/clientprofile/propertydetails" component={PropertyDetails} />
    <Route exact path="/clients/view/clientprofile/loandetails" component={LoansView} />
    <Route exact path="/clients/view/clientprofile/nonmortgagedebt" component={NonMortgageDebt} />
    <Route exact path="/clients/view/clientprofile/deleted" component={DeletedItems} />
  </Switch>
);

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestUser,
  }, dispatch);

const mapStateToProps = ({ clients }) => ({ client: clients.current });

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile);

const TabBar = styled.div`
  width: 100%;
  margin-bottom: -1px;
  margin-top: 40px;
  overflow: visible;
`;

const Tab = styled.div`
  min-width: 188px;
  padding: 18px 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #ECECEC;
  border: 1px solid #D8DCE7;
  color: #4F4F4F;
  position: relative;

  &.current {
    background-color: #fff;
    border-bottom: none;
    color: #101922;

    &:after {
      content: '';
      background-color: #fff;
      /*   */
      height: 5px;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      z-index: 1000;
    }

    &:hover {
      border-bottom: none;
    }
  }

  &:hover {
    background-color: #fff;
  }
`;