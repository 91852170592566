import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/Common/BackButton';
import Card from 'components/Styled/Card';
import { getQueryVariables } from 'util/index';
import { calculateProductValues } from 'util/CalculateProductValues';
import imgClient from 'img/button/man-blue.png';
import { clientAppUrl } from 'modules';
import { isIOS } from 'util/getDevice';
import Lenders from 'components/ATP/Lenders';
import { requestClientLogin } from 'modules/user/actions';
import { requestUser, requestPatchUser } from 'modules/clients/actions';
import formatNumber from 'util/formatNumber';
import parseNumber from 'util/parseNumber';
import { LendingStructure } from 'components/ATP/LendingStructure';
import LendingStructureIntro from 'components/ATP/LendingStructureIntro';
import SectionButton, { MenuOption, SubMenuOption } from 'components/ATP/SectionButton';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { initialize } from 'redux-form';
import AdditionalDetails from 'components/ATP/AdditionalDetails';
import html from 'util/html';
import ATPPDF from 'shared/ATPPDF';
import uploadPDF from 'util/upload-pdf';
import { addFile } from 'modules/clients/actions';
import download from 'downloadjs';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import moment from 'moment';
import { PageLoader } from 'components/Loader';
import { ToggleButton } from 'components/Styled/CheckBox';
import generateAdditonalDetails from 'shared/generateAdditionalDetails';
import IntroNames from 'components/ATP/IntroNames';
import store from 'store';

const NONE = 0; 
const LENDING_STRUCTURE = 1;
const ADDITIONAL_DETAILS = 2;

const ATP = props => {
  const history = useHistory();
  const formValues_Lenders = useSelector(state => state.form['atp-lenders']?.values);
  const formValues_Intro = useSelector(state => state.form['lending-intro']?.values);
  const formValues_Details = useSelector(state => state.form['atp-details']?.values?.text);
  const client = useSelector(state => state.clients.current);
  const brokers = useSelector(state => state.brokers);
  const userDocs = useSelector(state => state.documents?.users) ?? [];
  let clientWithExtras = client;
  clientWithExtras.documents = userDocs[client.id];
  const settings = useSelector(state => state.user.globalSettings);
  const proposalBroker = brokers.find(x => x.id === client.proposalBrokerId); 
  clientWithExtras.proposalBroker = proposalBroker;

  const lendingOverview = useSelector(state => 
    state.clients.current?.meta?.miniproposal?.overview?.lendingOverview ?? {});
  const atpValues = useSelector(state => 
    state.clients.current?.meta?.miniproposal?.atp ?? {});
  const loanbaseCashback = useSelector(state =>
    state.clients.current?.meta?.miniproposal?.overview?.loanbaseCashback ?? {});
  const lenderCashback = useSelector(state =>
    state.clients.current?.meta?.miniproposal?.overview?.lenderCashback ?? {});
  const clientLoading = useSelector(state => state.clients.userLoading);
  const { loans, banks } = getLoanData(client?.meta?.miniproposal);
  const lenders = client.lenders;
  
  const [ isSavingPDF, setIsSavingPDF ] = useState(false);
  const [ isUploadingPDF, setIsUploadingPDF] = useState(false);
  const [ isFinal, setIsFinal ] = useState();

  const [ loaded, setLoaded ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(NONE);

  const dispatch = useDispatch();
  const requestLogin = args => dispatch(requestClientLogin(args));

  // useEffect(() => {
  //   const { id } = getQueryVariables(props.location.search);
  //   // if (client?.id === parseInt(id)) setLoaded(true);
  //   dispatch(requestUser({
  //     query: id, callback: () => {
  //       setLoaded(true);
  //     }
  //   }));
  // }, []);

  useEffect(() => {
    if (atpValues.isFinal) setIsFinal(true);
  }, [atpValues]);
  

  const loansTotal = loans
    .map(l => l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0);

  let weightedIIR = loans
    .map(l => l.newLoan.iir * l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0) / loansTotal;
  weightedIIR = formatNumber(weightedIIR, 2);

  let weightedTrueRate = loans
    .map(l => parseFloat(l.newLoan.trueRate) * l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0) / loansTotal;
  weightedTrueRate = formatNumber(weightedTrueRate, 2);
  
  let upfrontFees = loans
    .map(l => Math.max( parseNumber(l.newLoan.approxUpfrontFee) - (l.newLoan.upfrontRebate ?? 0), 0))
    .reduce((a, b) => a + b, 0);
  upfrontFees = formatNumber(upfrontFees);
  
  let ongoingFees = loans
    .map(l => l.newLoan.ongoing_fees)
    .reduce((a, b) => parseNumber(a) + parseNumber(b), 0);
  ongoingFees = formatNumber(ongoingFees);

  const variableIOLoans = loans
    .filter(loan => loan.newLoan.repaymentType !== 'Principal & Interest' && loan.newLoan.initial_ir_type === 'Variable');

  const hasVariableIOLoans = !!variableIOLoans.length;
  const hasTermlessIOLoans = variableIOLoans.some(loan => !loan.newLoan.ioTerm);
  const hasFixedInterestLoans = loans
    .some(loan => loan.newLoan.initial_ir_type !== 'Variable');

  const hasDatelessCashbacks = 
    (lenderCashback.isActive && (!lenderCashback.hasEndDate || !lenderCashback.endDate)) 
    || (loanbaseCashback.isActive && (!loanbaseCashback.hasEndDate || !loanbaseCashback.endDate));

  let warningText = '';

  if (!client.proposalBroker) {
    warningText = '!No Broker Assigned to This Proposal!';
  }

  if (hasTermlessIOLoans) {
    warningText = '!Set the Interest Only Loan Term!';
  }

  if (hasDatelessCashbacks) {
    warningText = '!Set the Cashback Expiry Date!';
  }

  if (hasDatelessCashbacks && hasTermlessIOLoans) {
    warningText = '!Set the IO Term and Cashback Expiry!';
  }


  const lenderDefaults = { weightedIIR, weightedTrueRate, upfrontFees, ongoingFees };

  const introInitialValues = {
    introText: isFinal ? 
      atpValues.introTextFinal || settings.atp_lending_preamble_final 
      : 
      atpValues.introText || settings.atp_lending_preamble,
  };

  const introDefaults = {
    introText: isFinal ? settings.atp_lending_preamble_final  : settings.atp_lending_preamble,
  };

  const patchLendingOverview = patch => {
    patchProposal({ overview: { lendingOverview: patch } }, client, dispatch);
  };

  const cancelEditLending = () => {
    dispatch(initialize('lending-intro', introInitialValues));
    dispatch(initialize('atp-lenders', lenderInitialValues));
    setIsEditing(NONE);
  };

  const saveLendingForm = () => {
    const introTextKey = isFinal ? 'introTextFinal' : 'introText';
    const lendingOverview = {...formValues_Lenders};
    lendingOverview.upfrontFees = formatNumber(lendingOverview.upfrontFees);
    lendingOverview.ongoingFees = formatNumber(lendingOverview.ongoingFees);
    const patch = {
      atp: {
        [introTextKey]: formValues_Intro.introText,
      },
      overview: {
        lendingOverview,
      }
    };
    patchProposal(patch, client, dispatch);
    dispatch(initialize('atp-lenders', lendingOverview));
    setIsEditing(NONE);
  };

  const resetLendingForm = () => {
    const patch = {
      atp: {
        introText: '',
      },
      overview: {
        lendingOverview: {
          ...lenderDefaults,
          aggregateFees: true,
          aggregateLending: false,
        }
      }
    };

    patchProposal(patch, client, dispatch);
    dispatch(initialize('lending-intro', introDefaults));
    dispatch(initialize('atp-lenders', lenderDefaults));
  };

  
  const aggregateFeesEnabled = lendingOverview.aggregateFees !== false;
  const aggregateLendingEnabled = lendingOverview.aggregateLending === true;
  
  const toggleFees = () => {
    patchLendingOverview({ aggregateFees: !aggregateFeesEnabled });
  };

  const toggleLending = () => {
    patchLendingOverview({ aggregateLending: !aggregateLendingEnabled });
  };

  const lenderInitialValues = {
    weightedTrueRate: lendingOverview.weightedTrueRate || weightedTrueRate, 
    weightedIIR: lendingOverview.weightedIIR || weightedIIR, 
    upfrontFees: lendingOverview.upfrontFees || upfrontFees, 
    ongoingFees: lendingOverview.ongoingFees || ongoingFees
  };

  const detailsDefaultText = generateAdditonalDetails({ user: client, loans });

  const toggleFinal = () => {
    const initialValues = {
      introText: !isFinal ? 
        atpValues.introTextFinal || settings.atp_lending_preamble_final 
        : 
        atpValues.introText || settings.atp_lending_preamble,
    };
    dispatch(initialize('lending-intro', initialValues));
    setIsFinal(!isFinal);
    
    const patch = {
      atp: {
        isFinal: !isFinal,
      }
    };
    patchProposal(patch, client, dispatch);
  };

  const saveAdditionalDetails = () => {
    const patch = {
      atp: {
        detailsText: formValues_Details,
        originalDetailsText: detailsDefaultText,
      },
    };
    patchProposal(patch, client, dispatch);
    setIsEditing(NONE);
  };

  const cancelEditDetails = () => {
    dispatch(initialize('atp-details', { text: atpValues.detailsText || detailsDefaultText }));
    setIsEditing(NONE);
  };

  const resetDetails = () => {
    const patch = {
      atp: {
        detailsText: '',
        originalDetailsText: '',
      },
    };
    dispatch(initialize('atp-details', { text: detailsDefaultText }));
    patchProposal(patch, client, dispatch);
  };

  useEffect(() => {
    if (atpValues.originalDetailsText && atpValues.originalDetailsText !== detailsDefaultText) {
      resetDetails();
    }
  }, []);

  const getClient = id => {
    const applications = store.getState().applications?.list;
    if (applications) {
      dispatch(requestUser({ query: id }));
    } else {
      setTimeout(() => {
        getClient(id);
      }, 500);
    }
  };

  useEffect(() => {
    if (!client?.meta) {
      const { id } = getQueryVariables(props.location.search);
      if (!client || client.id !== parseInt(id) || ( clientLoading && clientLoading !== parseInt(id))) {
        getClient(id);
      }
    }
  }, []);

  const finishSendPDF = async blob => {
    const date = moment();
    setIsSavingPDF(false);

    const name = isFinal ? 
      `App Finalisation - ${client.lastName || client.firstName} - ${date.format('DDMMYY')}.pdf`
      :
      `Application Preparation - ${client.lastName || client.firstName} - ${date.format('DDMMYY')}.pdf`;

    download(blob, name, 'application/pdf');
    console.log('Uploading PDF...');
    const result = await uploadPDF({
      blob,
      type: isFinal ? 'atp-pdf-final' : 'atp-pdf',
      name, 
      userId: client.id,
      email: client.email,
      userName: client.lastName || client.firstName,
      brokerId: client.proposalBrokerId,
    });

    console.log('Uploading finished');
    setIsUploadingPDF(false);

    dispatch(addFile({
      storageId: result.storageId,
      userId: client.id,
      name,
      type: isFinal ? 'atp-pdf-final' : 'atp-pdf',
      status: 1,
      createdAt: date.format(),
    }));

    
  };

  if (!client.meta) return (
    <main  className="main client-view">
      <PageLoader />
    </main>
  );

  return (
    <>
      
      <main className='main client-view' style={{ minWidth: '950px', color: '#575E6C  '}}>
        
        <Row style={{ width: '940px'}}>

          <Column width='130px' >
            <BackButton margin='18px 0 0 0' onClick={() => history.push('/clients/view/proposal?id=' + client.id)} />
          </Column>
          <Column width='400px'>
            <MainHeading color={isFinal ? '#2291FF' : ''}>{isFinal ? 'Final ATP' : 'Agree to Proceed'} Review</MainHeading>
          </Column>
          <Column width='300px' style={{ marginLeft: 'auto' }}>
            <ToggleContainer>
              <InlineTitle>Final ATP</InlineTitle>
              <ToggleButton checked={isFinal} onChange={toggleFinal}/>
            </ToggleContainer>
            <ButtonPlain onClick={() => logInAsClient(client, requestLogin)} style={{ marginBottom: '20px', float: 'right' }}>
              <ImgClient />
              Login as Client
            </ButtonPlain>  
          </Column>
        </Row>
        

        <Card minWidth='940px' maxWidth='940px' padding='60px 50px 60px 50px' minHeight='500px'>
          <Overlay disabled={!isEditing}/>
          <IntroNames />
          <Section active={isEditing === LENDING_STRUCTURE}>
            
            <SectionButton 
              editMode={isEditing === LENDING_STRUCTURE}
              saveEdit={() => saveLendingForm()}
              cancelEdit={() => cancelEditLending()}
            >
              {({ andClose, toggleSubmenu }) => <>
                <MenuOption onClick={andClose(() => setIsEditing(LENDING_STRUCTURE))}>Edit</MenuOption>
                <MenuOption onClick={andClose(toggleFees)}>
                  {aggregateFeesEnabled ? 'Disable' : 'Enable'} Aggregate Lender Fees
                </MenuOption>
                <MenuOption onClick={andClose(toggleLending)}>
                  {aggregateLendingEnabled ? 'Disable' : 'Enable'} Aggregate Interest Rates
                </MenuOption>
                <MenuOption confirm color='red' onClick={toggleSubmenu}>
                  Reset
                  <SubMenuOption onClick={andClose(resetLendingForm)}>Are you sure?</SubMenuOption>
                </MenuOption>
              </>}
            </SectionButton>  

            <LendingStructureIntro initialValues={introInitialValues} active={isEditing === LENDING_STRUCTURE}/>
          
            <Lenders loans={loans} lenders={lenders} banks={banks} defaults={lenderDefaults} initialValues={lenderInitialValues} active={isEditing === LENDING_STRUCTURE}/>
          </Section>

          <LendingStructure loans={loans} showIOFootnote={hasVariableIOLoans} showFeeFootnote={lendingOverview.aggregateFees !== false} showFixedInterestFootnote={hasFixedInterestLoans}/>

          <Section active={isEditing === ADDITIONAL_DETAILS}>
            
            <SectionButton 
              editMode={isEditing === ADDITIONAL_DETAILS}
              saveEdit={() => saveAdditionalDetails()}
              cancelEdit={() => cancelEditDetails()}
            >
              {({ andClose, toggleSubmenu }) => <>
                <MenuOption onClick={andClose(() => setIsEditing(ADDITIONAL_DETAILS))}>Edit</MenuOption>
                <MenuOption confirm color='red' onClick={toggleSubmenu}>
                  Reset
                  <SubMenuOption onClick={andClose(resetDetails)}>Are you sure?</SubMenuOption>
                </MenuOption>
              </>}
            </SectionButton>

            <AdditionalDetails client={client} banks={lenders} initialValues={{ text: atpValues.detailsText || detailsDefaultText }} active={isEditing === ADDITIONAL_DETAILS}/>
           
          </Section>

        </Card>

        <CenterContent width='940px' style={{ margin: '10px 0 300px 0'}}>
          <CenterContentColumn width='250px'>
            <WarningText />
            <ButtonPlain 
              width='205px' 
              fontSize='16px' 
              fontWeight='700'  
              height='45px'
              onClick={() => history.push('/clients/view/miniproposal?id=' + client.id)}>
              
              Cancel
            </ButtonPlain>
            <ButtonSubtext />
          </CenterContentColumn >
          <CenterContentColumn width='250px'>
            {warningText ? 
              <WarningText>{warningText}</WarningText>
              :
              <WarningText/>
            }
            <ButtonPrimary 
              onClick={() => setIsSavingPDF(true)} 
              width='205px' 
              fontSize='16px' 
              fontWeight='700' 
              height='45px' 
              disabled={warningText || isEditing}
            >
              <ButtonSpinner show={isSavingPDF}/>
              {isFinal ? 'Generate Final' : 'Approve'}
            </ButtonPrimary>
            <ButtonSubtext>This will generate the ATP PDF</ButtonSubtext>
            <BottomToggleContainer>
              <InlineTitle>Final ATP</InlineTitle>
              <ToggleButton checked={isFinal} onChange={toggleFinal}/>
            </BottomToggleContainer>
          </CenterContentColumn>
          
          
        </CenterContent>

        
      </main>

      {isSavingPDF &&
        <PDF>
          <ATPPDF user={clientWithExtras} settings={settings} finished={blob => finishSendPDF(blob)} isFinal={isFinal}/>
        </PDF>
      }
    </>
  );
};



export default ATP;

const patchProposal = (values, client, dispatch) => {
  const patch = {
    meta: {
      miniproposal: {
        ...values,
      },
    },
  };

  dispatch(requestPatchUser({
    data: {
      id: client.id,
      ...patch
    },
    params: {
      mergeMeta: true,
    }
  }));
  
};

const InlineTitle = styled.span`
  margin-right: 10px;
`;

const ToggleContainer = styled.div`
  display: inline-block;
  padding-top: 6px;
`;

const BottomToggleContainer = styled.div`
  position: absolute;
  width: 300px;
  left: 270px;
  top: 32px;
`;

const PDF = styled.div`
  position: absolute;
  left: 10000px;
  top: -100000px;

  * {
    font-family: LatoPDF, sans-serif !important;
  }

  li {
    font-family: inherit;
  }
`;

const WarningText = styled.div`
  color: #D0021B;
  height: 15px;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
`;

const ButtonSubtext = styled.div`
  margin-top: 10px;
  color: #575E6C;
  height: 15px;
  font-size: 14px;
  line-height: 17px;
`;

const logInAsClient = (user, requestClientLogin) => {
  if (!user) return;
  let siteDomain = clientAppUrl;

  let windowReference;
  
  if (isIOS) {
    windowReference = window.open('', '_blank');
  }

  requestClientLogin({
    data: {
      email: user.email,
    },
    callback: (result) => {
      const url = `${siteDomain}?email=${user.email}&loginToken=${result.token}&godmode=${result.godmode}&id=${user.id}`;
      if (isIOS) {
        windowReference.location = url;
      } else {
        window.open(url, '_blank');
      }
    }
  });
}

const getLoanData = (miniProposal = {}) => {
  let loans = [];
  for (const id of (miniProposal.tabs || [])) {
    const tab = miniProposal.properties[id];
    if (!tab || tab.excludedFromOverview || !tab.loanComparison.recommendationActive) continue;
    const loanComparison = tab.loanComparison;
    const currentLoan = tab.currentLoan;
    const loanId = loanComparison.loanOrder[0];
    if (!loanId) continue;
    const newLoan = calculateProductValues(loanComparison.loans[loanId], currentLoan);

    if (currentLoan) {
      loans.push({
        newLoan,
        currentLoan,
        name: tab.name,
        cashback: tab.overviewCashback,
        tabId: id,
        loanId,
      });
    }
  }
  

  const banks = loans.reduce((obj, loan) => {
    const bank = loan.newLoan.bank;
    return ({
      ...obj,
      [bank]: [...(obj[bank] || []), loan.newLoan],
    });
  }, {});

  return { loans, banks };
};

const Section = styled.div`
  position: relative;
  ${p => p.active && css`
    z-index: 1000;
  `}
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  width: ${props => props.width ?? '100%'};
`;

const ImgClient = styled.img.attrs({
  src: imgClient,
})`
  position: relative;
  width: 16px;
  bottom: -2px;
  margin-right: 6px;
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  color: #575e6c;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
`;

const MainHeading = styled(Heading)`
  color: ${props => props.color ?? 'inherit'};
  margin-bottom: 0;
  margin-top: 15px;
  display: inline-block;
`;

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.width ?? '100%'};
`;

const CenterContentColumn = styled(CenterContent)`
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: visible;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
  z-index: 90;
  opacity: 0;
  pointer-events: none;
  /* transition: opacity 0.5s; */

  ${props => !props.disabled && css`
    opacity: 1;
    display: block;
    pointer-events: auto;
  `}
`;