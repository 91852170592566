import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { requestCreateBroker, requestPatchBroker } from 'modules/brokers/actions';

import Card from 'components/Styled/Card';
import BackButton from 'components/Common/BackButton';
import Form from './AddBrokerForm';
import { SubmissionError } from 'redux-form';
import uploadFile from 'util/upload-file';
import uriToBlob from 'util/uri-to-blob';


class AddMember extends Component {

  onSubmit = values => {
    const { requestCreateBroker, history } = this.props;
    return new Promise((resolve, reject) => {
      
      requestCreateBroker({
        data: {
          ...values,
        },
        callback: data => {
          if (data.error) {
            reject(new SubmissionError({
              dataError: data.error.message,
              _error: 'UNKNOWN ERROR: Failed to create team member',
            }));
            return;
          }

          Promise.all([
            conditionallyUpdateImage(data.id, values, 'big'),
            conditionallyUpdateImage(data.id, values, 'small'),
            conditionallyUpdateImage(data.id, values, 'signature'),
          ]).then(() => {
            history.push('/team');
            resolve();
          }).catch(err => {
            console.log('Upload Error:', err);
            reject(new SubmissionError({ 
              _error: 'ERROR: An error occured uploading the selected image(s).'
            }));
          });
        }
      });
    });
  }

  render() {
    return (
      <main className="main client-add" style={{ paddingTop:'60px' }}>
        <BackButton onClick={() => this.props.history.push('/team')}/>
        <Card width='800px' padding='40px'>
          <Form onSubmit={this.onSubmit} initialValues={{}}/>
        </Card>
      </main>
    );
  }
}

const conditionallyUpdateImage = async (id, values, name ) => {
  return new Promise((resolve, reject) => {
    if (values[name + 'Image']) {
      uploadFile({
        file: uriToBlob(values[name + 'Image']),
        type: 'broker',
        name: name + id + '.png',
        callback: (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
};

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestCreateBroker,
    requestPatchBroker,
  }, dispatch);

export default connect(null, mapDispatchToProps) (AddMember);
