import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import Label from 'components/Styled/Label';
import { ModalBox } from 'components/Modal/index.jsx';
import { ButtonImageLeft, ButtonImageSquare, ButtonPrimary } from 'components/Styled/Button';
import { requestFindClients } from 'modules/clients/actions';

import imgSearch from 'img/button/magnify.svg';
import TextInput from 'components/Styled/TextInput';

import imgReferrerClientActive from 'img/icons/referrer-client-active.svg';
import imgReferrerClientGrey from 'img/icons/referrer-client-grey.svg';
import imgProfessionalClientActive from 'img/icons/referrer-professional-active.svg';
import imgProfessionalClientGrey from 'img/icons/referrer-professional-grey.svg';

import { requestUpdateApplications } from 'modules/applications/actions';


import imgCircleFull from 'img/button/circle-full.svg';
import imgCircleEmpty from 'img/button/circle-empty.svg';
import { Loader, LoaderContainer } from 'components/Loader';
import { PullRight, PullLeft } from 'components/Styled/Layout';
import html from 'util/html';

const ReferrerModal = ({ modal, application = {}, introText, callback }) => {

  const dispatch = useDispatch();
  const [ searchString, setSearchString ] = useState('');
  const [ selectedId, setSelectedId ] = useState(application.referrerId);
  const [ referrersLoaded, setReferrersLoaded ] = useState(false);
  const [ referrersLoading, setReferrersLoading ] = useState(false);
  const [ referrerUnlinked, setReferrerUnlinked ] = useState(false);


  const referrers = useSelector(state => state.clients.referrers.list);
  const profile = useSelector(state => state.user.profile);
  const brokerName = profile && profile.firstName + ' ' + profile.lastName;

  let shownReferrers = [];

  if (referrersLoaded) {
    shownReferrers = referrers;
  }
  if (application.referrerId && !referrerUnlinked) {
    shownReferrers = [ application.referrer, ...shownReferrers.filter(x => x.id !== application.referrerId)];
  }

  // let shownReferrers = !application.referrerId || referrerUnlinked ? referrers :
  //   [ application.referrer, ...referrers.filter(x => x.id !== application.referrerId)];

  const startSearch = () => {
    setReferrersLoading(true);
    // setReferrersLoaded(false);
    
    setSelectedId(!referrerUnlinked ? application.referrerId : null);
    
    dispatch(requestFindClients({
      query: {
        type: 'referrers',
        limit: 100,
        skip: 0,
        stages: [],
        search: (searchString || '').trim(),
        sortField: 'lastName',
        sortDirection: 1,
      },
      callback: () => {
        setReferrersLoaded(true); 
        setReferrersLoading(false);
      }
    }));
  };

  const checkSubmit = event => {
    if (event.key === 'Enter') {
      startSearch();
      document.activeElement.blur();
    }
  };

  const setReferrer = () => {
    const referrer = referrers.find(x => x.id === selectedId);
    if (callback) {
      modal.close();
      callback(referrer);
      return;
    }
    const referrerName = referrer.firstName + (referrer.lastName ? ' ' + referrer.lastName : '');
    const referralDetails = {
      email: application.client.email,
      firstName: application.client.firstName,
      lastName: application.client.lastName,
      phoneNumber: application.client.phoneNumber,
      borrowAmount: application.loanAmount - (application.offsetAmount ?? 0),
      purchasePrice: application.propertyValue,
    };
    dispatch(requestUpdateApplications({
      id: application.id,
      referrerId: selectedId,
      referrer,
      referralDetails,
      note: {
        brokerId: 'system',
        body: html`Referrer changed to <b>${referrerName}</b> ${brokerName ? 'by ' + brokerName : ''}`
      }
    }));
    modal.close();
  };

  const unlinkReferrer = () => {
    dispatch(requestUpdateApplications({
      id: application.id,
      referrerId: 0,
      referrer: null,
      note: {
        brokerId: 'system',
        body: html`Referrer removed ${brokerName ? 'by ' + brokerName : ''}`
      }
    }));
    setReferrerUnlinked(true);
    setSelectedId(null);
  };

  return <>
    <ModalBox width='700px'>
      <Heading>Link Referrer</Heading>
      {!!introText &&
      <Row style={{ marginBottom: '30px', fontSize: '15px', lineHeight: '1.4', color: '#223241' }}>
        {introText}
      </Row>
      }
      
      <Row style={{ marginBottom: '30px' }}>
        <Column style={{ width: '250px', marginRight: '20px' }}>
          <Label>Search String</Label>
          <TextInput value={searchString} onChange={e => setSearchString(e.target.value)} onKeyUp={checkSubmit}/>
        </Column>
        <Column style={{ width: '200px' }}>
          <SearchButton onClick={startSearch}>Search Referrers</SearchButton>
        </Column>
        <Column style={{ width: '120px' }}>
          {referrersLoading && <Loading />}
        </Column>
      </Row>
      
      {!!shownReferrers.length && <>
        <TableBorder>
          <Table>
            <TableRow>
              <TableHeaderCell style={{ width: '20%' }}>Referrer</TableHeaderCell>
              <TableHeaderCell style={{ width: '38%' }}>Email Address</TableHeaderCell>
              <TableHeaderCell style={{ width: '20%' }}>Phone Number</TableHeaderCell>
              <TableHeaderCell style={{ width: '10%' }}>Type</TableHeaderCell>
              <TableHeaderCell style={{ width: '12%' }}>Select</TableHeaderCell>
            </TableRow>
            <Scrollbars style={{ width: '100%', height: Math.min(351, shownReferrers.length * 50) + 'px' }}>
              {shownReferrers.map(x => {
                const isSelected = x.id === selectedId;
                let referrerImg;
                if (x.isProfessionalReferrer) {
                  if (isSelected) referrerImg = <ImgProfessionalActive/>;
                  else referrerImg = <ImgProfessionalGrey/>;
                } else {
                  if (isSelected) referrerImg = <ImgClientActive/>;
                  else referrerImg =  <ImgClientGrey/>;
                }
                const email = x.email.includes('@fake.com') ? '―' : x.email;
                return <TableRow key={x.id} selected={isSelected} >
                  <TableCell style={{ width: '20%' }}>{x.firstName} {x.lastName}</TableCell>
                  <TableCell style={{ width: '38%' }}>{email}</TableCell>
                  <TableCell style={{ width: '20%' }}>{x.phoneNumber}</TableCell>
                  <TableCell style={{ width: '10%' }}>{referrerImg}</TableCell>
                  <TableCell style={{ width: '12%' }}>{isSelected ? <SelectButtonActive onClick={() => setSelectedId(null)}/> : <SelectButtonGrey onClick={() => setSelectedId(x.id)}/>}</TableCell>
                </TableRow>;
              })}
            </Scrollbars>    
          </Table>
        </TableBorder>
        {!!application.referrerId && !referrerUnlinked &&
          <PullLeft>
            <ButtonPrimary background='#FF4E4C' width='250px'  onClick={unlinkReferrer}>Unlink Referrer</ButtonPrimary>
          </PullLeft>
        }
        
        <PullRight>
          <ButtonPrimary width='250px' disabled={!selectedId} onClick={setReferrer}>Link</ButtonPrimary>
        </PullRight>
      </>}
    </ModalBox>
  </>;
};

export default ReferrerModal;



const LoadingContainer = styled.div`
  position: relative;
  top: 38px;
  width: 100%;
  height: 50px;
    
  ${LoaderContainer} {
    position: relative;
    top: calc(50% - 30px);
  }
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <Loader/>
    </LoadingContainer>
  )
}

const SelectButtonActive = styled(ButtonImageSquare)`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  background-image: url(${imgCircleFull});
  position: relative;
  top: 11px;
`;

const SelectButtonGrey = styled(SelectButtonActive)`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  background-image: url(${imgCircleEmpty});
  position: relative;
  top: 11px;
`;

const TypeImg = styled.img`
  position: relative;
  top: 7px;
`;

const ImgClientActive = styled(TypeImg).attrs({
  src: imgReferrerClientActive,
})`
  width: 24px;
  height: 22px;
`;

const ImgClientGrey = styled(TypeImg).attrs({
  src: imgReferrerClientGrey,
})`
  width: 24px;
  height: 22px;
`;

const ImgProfessionalActive = styled(TypeImg).attrs({
  src: imgProfessionalClientActive,
})`
  width: 24px;
  height: 22px;
`;

const ImgProfessionalGrey = styled(TypeImg).attrs({
  src: imgProfessionalClientGrey,
})`
  width: 24px;
  height: 22px;
`;

const Row = styled.div``;
const Column = styled.div`
  display: inline-block;
  vertical-align: top;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const SearchButton = styled(ButtonImageLeft)`

  padding-left: 50px;
  padding-right: 20px;
  margin-top: 26px;
  background-size: 20px 20px;
  background-position-x: 16px;
  background-position-y: 8px;
  background-image: url(${imgSearch});
  background-repeat: no-repeat;
`;

const TableBorder = styled.div`
  border: solid 1px #D8DCE7;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 30px;
`;

const TableCell = styled.div`
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableRow = styled.div`
  ${props => props.selected && css`
    ${TableCell} {
      color: #121923 !important;
    }
  `}
`;

const TableScrollArea = styled(Scrollbars)`
  width: '100%';
  height: 401px;
`;
const TableHeaderCell = styled(TableCell)``;

const Table = styled.div`
  width: 100%;

  ${TableRow} {
    ${TableCell} {
      padding-left: 20px;
      font-size: 13px;
      height: 50px;
      line-height: 50px; 
      position: relative;
      color: #888888;
    }

    ${TableHeaderCell} {
      padding-left: 20px;
      color: #585e6d;
      font-size: 12px;
      font-weight: 700;
      border-bottom: solid 1px #D8DCE7;
      background: #F6F7FB;
      height: 40px;
      line-height: 40px;
      text-align: left;
    }
    
    &:nth-child(odd) {
      background: #FAFAFD;
    }

    &:hover {
      background: #DFEFFF;
    }
  }

`;