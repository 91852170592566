import React from 'react';

export const LenderTable = (props) => (
  <div className='lender-table' {...props}>
  </div>
);

export const HeadRow = (props) => (
  <div className='table-head-row' {...props}>
  </div>
);

export const Row = (props) => (
  <div className='table-row' {...props}>
  </div>
);

export const Cell = ({width, ...props}) => (
  <div className='table-cell' style={{width}} {...props}>
  </div>
);

export const SearchBar = (props) => (
  <input type='text' className='search-bar' placeholder='Search' {...props}/>
);

export const Header = () => (
  <div style={{display: 'table', width: '100%'}}>
    <HeadRow>
      <Cell width='15%'>Lender Name</Cell>
      <Cell width='12.5%'>BDM Name</Cell>
      <Cell width='20%'>BDM Email</Cell>
      <Cell width='12.5%'>BDM Phone</Cell> 
      <Cell width='12.5%'>Broker Phone</Cell>
      <Cell width='12.5%'>Broker ID</Cell>
      <Cell width='15%'>Broker Website</Cell>
    </HeadRow>
  </div>
);

export const LenderTableItem = ({ lender, onClick }) => (
  <Row onClick={onClick}>
    <Cell width='15%'>{lender.name || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.bdmName || <span className='grey-text'>—</span>}</Cell>
    <Cell width='20%'>{lender.bdmEmail || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.bdmPhone || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.brokerPhone || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.brokerId || <span className='grey-text'>—</span>}</Cell>
    <Cell width='15%'>
      {lender.brokerWebsite ? 
        <a href={lender.brokerWebsite} target='_blank'>{lender.brokerWebsite}</a> 
        : <span className='grey-text'>—</span>
      }
    </Cell>
  </Row>
);

export const TablePlaceHolder = () => [
  <PlaceHolderRow key={0}/>,
  <PlaceHolderRow key={1}/>,
  <PlaceHolderRow key={2}/>,
  <PlaceHolderRow key={3}/>,
  <PlaceHolderRow key={4}/>,
  <PlaceHolderRow key={5}/>,
  <PlaceHolderRow key={6}/>,
  <PlaceHolderRow key={7}/>,
];


export const PlaceHolderRow = index => (
  <Row key={index}>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
  </Row>
)


