import styled, { css } from 'styled-components';


export default styled.span`
  display: inline-block;
  margin-right: 0;
  width: 0;
  height: 0;
  vertical-align: text-bottom;
  
  border-radius: 50%;
  animation: button-spinner .75s linear infinite;
  transition: all 0.5s;
  border: none;
  opacity: 0;

  ${p => p.show ? css`
    height: 15px;
    width: 15px;
    margin-right: ${p.marginRight || '10px'};
    border: 3px solid ${p.color || 'white'};
    border-right-color: transparent;
    opacity: 1;
  ` : ''}

  @keyframes button-spinner {
    to { transform: rotate(360deg); }
  }
`;
