import styled from 'styled-components';

export const ToolTip = styled.div`
  visibility: hidden;
  width: 120px;
  background-color: #223241;
  color: #F6F7FB;
  text-align: center;
  padding: 0 10px;
  border-radius: 4px;

  position: absolute;
  bottom: calc(100%);
  left: 50%;
  margin-left: -60px;
  
  z-index: 1;

  opacity: 0;
  transition: opacity 1s;

  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &:hover {
    visibility: hidden;
  }
`;

export const ToolTipContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: visible;

  &:hover {
    ${ToolTip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

