import React, { Fragment, useEffect } from 'react';

import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'; 
import { Row } from 'components/Styled/Layout';
import { FormCard, HeadingLarge, SelectField, TextField, TextAndSelectField, NumberField, SaveButton } from '../Components';
import initializeFormValues from 'util/initializeFormValues';


const FORM_NAME = 'submission-applicants';

const ApplicantFormPure = ({ handleSubmit, submitting, pristine, applicantType, application }) => {
  
  useEffect(() => {
    initializeFormValues(FORM_NAME, values => ({ ...values, application }));
  }, []);

  return (
    <FormCard onSubmit={handleSubmit}>
      <HeadingLarge>Borrower Type</HeadingLarge>
      <Row>
        <SelectField label='Borrower Type' width='250px' name='application.meta.borrowType' options={[
          { name: 'Personal', value: 'Personal' },
          { name: 'Company', value: 'Company' },
          { name: 'Trust', value: 'Trust' },
          { name: 'Self-Managed Super Fund', value: 'Self-Managed Super Fund' },
        ]} />
        <SelectField label='Who will be on the home loan?' name='application.meta.applicantType' width='300px' options={[
          { name: 'The applicant only', value: 'justYou' },
          { name: 'The applicant and their partner', value: 'youAndYourPartner' },
          { name: 'The applicant and one or more others', value: 'youAndOneOrMoreOthers' },
          { name: 'Other situation', value: 'otherSituation' },
        ]} />
      </Row>
      <HeadingLarge>Applicant Personal Details</HeadingLarge>
      <Row>
        <TextField label='First Name' name='client.firstName'/>
        <TextField label='Last Name' name='client.lastName'/>
        <TextAndSelectField label='Contact Number' name='client.phoneNumber' options={phoneOptions} />
        <NumberField label='Age' name='client.meta.age'/>
        <NumberField label='Number of Dependents' name='client.meta.dependents' />
        <TextField label='Post Code' name='client.meta.postcode' />
      </Row>
      <div className={applicantType === 'youAndYourPartner' ? '' : 'hidden'}>
        <HeadingLarge>Partner Personal Details</HeadingLarge>
        <Row>
          <TextField label='First Name' name='client.partner.firstName'/>
          <TextField label='Last Name' name='client.partner.lastName'/>
          <TextAndSelectField label='Contact Number' name='client.partner.phoneNumber' options={phoneOptions} />
          <NumberField label='Age' name='client.partner.meta.age'/>
        </Row>
      </div>

      <SaveButton disabled={(submitting || pristine)} submitting={submitting}/>
    </FormCard>
  );
};

const phoneOptions = [
  {name: 'Mobile', value: 'mobile'},
  {name: 'Landline', value: 'landline'},
  {name: 'Work', value: 'work'}
];

const select = formValueSelector(FORM_NAME);
const stateToProps = state => {
  return ({
    applicantType: select(state, 'application.meta.applicantType'),
  });
};

const ApplicantForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(ApplicantFormPure);

export default connect(stateToProps)(ApplicantForm);