import React, { Component } from 'react';
import styled from 'styled-components';
import { Route, Switch, Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionQueue, getQueryVariables } from 'util/index';

import imgEditPen from 'img/button/edit-pen.png';
import { PageLoader } from 'components/Loader';

import {
  requestGetApplications,
  requestMoveApplication,
} from 'modules/applications/actions';

import { TopBar, SideBar } from 'components/ApplicationsEligibility';
import { ApplicationsDashboard } from '../Shared/Dashboard/Dashboard';
import ApplicationsApplicants from '../Shared/Applicants/ApplicantsView';
import ApplicationsSecurities from '../Shared/Securities/SecuritiesView';
import ClientSearch from '../Shared/Applicants/ClientSearch';
import ApplicationsFunding from '../Shared/Funding/FundingView';
import { ApplicationsFiles } from '../Shared/Files/FilesView';
const Files = props => <ApplicationsFiles url='/applications' {...props}/>;


const ContentContainer = styled.div`
      flex: 1;
      background: #f6f6fa;
			padding: 40px;
			overflow: auto;
      width: calc(100% - 300px);
      min-height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  min-height: 100%;
`;


const Routes = () => (
  <Switch>
    <Route exact path="/applications/view" component={ApplicationsDashboard}/>
    <Route exact path="/applications/view/applicants" component={ApplicationsApplicants}/>
    <Route exact path="/applications/view/applicants/search" component={ClientSearch}/>
    <Route exact path="/applications/view/securities" component={ApplicationsSecurities}/>
    <Route exact path="/applications/view/funding" component={ApplicationsFunding}/>
    <Route path="/applications/view/files" component={Files}/>
  </Switch>
);



class ApplicationView extends Component {

  constructor() {
    super();

    this.state = {
      actionQueue: new ActionQueue(),
    };
  }

  async componentWillMount() {
    // const { applications, requestGetApplications } = this.props;
    // if (!applications.list && !applications.loading) {
    //   await requestGetApplications();
    // } 
  }

  moveApplication = (args) => this.state.actionQueue.add( () =>
    new Promise( resolve => 
      this.props.requestMoveApplication({ ...args, callback: resolve })
    )
  );

  render() {
    const { applications, location } = this.props;
    if (!applications || !applications.list) return (
      <main className="main application-edit">
        <PageLoader />
      </main>
    );
    const { id } = getQueryVariables(location.search);
    const application = applications.list.get(parseInt(id));
    // if (!application.client) return;
    // console.log('Client:', application.client);
    return (

      <main className="main application-edit">
        <TopBar 
          application={application} 
          applications={applications} 
          moveApplication={this.moveApplication}
        />
        <FlexContainer>
          <SideBar application={application} rootPath='/applications/view' currentPath={location.pathname} />
          
          <ContentContainer>
            <Routes/>
          </ContentContainer>
        </FlexContainer>
      </main>
    );
  }
}



const mapStateToProps = ({applications}) => ({applications});

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestGetApplications,
    requestMoveApplication,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (ApplicationView);

export const EditButton = ({ to, ...props }) => (
  <Link to={to || ''} {...props}>
    <img
      width={12}
      height={12}
      src={imgEditPen} 
      className='edit-pen'
    />
  </Link>
  
);


