import { takeEvery, call, put } from 'redux-saga/effects';

import {
  getBrokers,
  patchBroker,
  createBroker,
} from './api';

import {
  receiveFindBrokers,
  receivePatchBroker,
  receiveCreateBroker,

  REQUEST_CREATE_BROKER,
  REQUEST_PATCH_BROKER,
  REQUEST_FIND_BROKERS
} from './actions';

function* callGetBrokers({ payload }) {
  const result = yield call(getBrokers);
  yield put(receiveFindBrokers(result));
  if (payload)
    payload();
}

export function* deleteLenderSaga() {
  yield takeEvery(REQUEST_FIND_BROKERS, callGetBrokers);
}

function* callPatchBroker({ payload: { data, callback } }) {
  let result = yield call(patchBroker, { data } );
  if (!result.error) {
    yield put(receivePatchBroker(result));
  }
  callback && callback(result);
}

export function* patchbrokerSaga() {
  yield takeEvery(REQUEST_PATCH_BROKER, callPatchBroker);
}

function* callCreateBroker({ payload: { data, callback } }) {
  let result = yield call(createBroker, data);
  if (!result.error) {
    yield put(receiveCreateBroker(result));
  }
  callback && callback(result);
}

export function* createUserSaga() {
  yield takeEvery(REQUEST_CREATE_BROKER, callCreateBroker);
}