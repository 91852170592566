export function calculateMonthlyRepaymentsWithFixedRate(initialRate, rollRate, fixedPeriod, totalPeriod, loanAmount) {
  var min = calculateMonthlyRepayments(Math.min(initialRate, rollRate), totalPeriod, loanAmount);
  var max = calculateMonthlyRepayments(Math.max(initialRate, rollRate), totalPeriod, loanAmount);
  
  var n = 100;
  
  for (var i = 0; i < n; i++) {
    if (max - min < 1) {
      break;
    }
    var testRepayment = min + (max - min) / 2;
    
    var rate = initialRate;
    var loanOwing = loanAmount;
    for (var j; j < totalPeriod; j++) {
      if (j === fixedPeriod) {
        rate = rollRate;
      }
      loanOwing = loanOwing * (rate + 1) - testRepayment;
      if (loanOwing < 0) {
        max = testRepayment;
        break;
      }
    }
    
    if (loanOwing > 0) {
      min = testRepayment;
    } else {
      break;
    }
  }

  return min + (max - min) / 2;
}

export default function calculateMonthlyRepayments(rate, period, loanAmount, offset = 0) {
  offset = Math.min(offset, loanAmount);
  const q = (1 + rate)**period;
  return ((loanAmount - offset) * q * rate / (q - 1)) + (offset / period);
}