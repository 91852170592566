import { app, hostUrl } from 'modules';
import request from "superagent";

const uploadPDF = ({ blob, type, name, userId, email, brokerId, userName }) => {
  return new Promise((resolve, reject) => {
    app.passport.getJWT().then(token => {
      request
        .post(hostUrl + "/file-upload")
        .set('Authorization', token)
        .field('type', type)
        .field('name', name)
        .field('userName', userName)
        .field('userId', userId)
        .field('email', email)
        .field('brokerId', brokerId)
        .attach('uri', blob)
        //.on('progress', event => {})
        .end((err, result) => {
          if (err) return reject(err);
          return resolve(result?.body);
        });
    });
  });
};

export default uploadPDF;