// src/modules/users/api.js

import {
  app,
  userService,
  verificationService,
} from '../index';


export async function login(payload) {
  try {
    return await app.authenticate({
      strategy: 'local',
      ...payload,
    });
  } catch (err) {
    console.error("Authentication error: " + err);
    return {};
  }
}

export function logout() {
  return app.logout();
}

export async function auth() {
  try {
    const token = localStorage.getItem('feathers-jwt');
    const payload = await app.passport.verifyJWT(token);
    const user = await userService.get(payload.userId);
    if (user.role !== "admin") {
      return {};
    }
    return user;
  } catch (err) {
      console.log(err);
    return {};
  }
}

export async function patchUser(details) {
  try {
    if (!details.id) return {};
    details = {...details, ...{
      lastLogin: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      fullName: details.firstName + ' ' + details.lastName,
    }};
    return await userService.patch(details.id, details);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function forgotPassword(payload) {
  try {
    return await verificationService.create({
      email: payload.email,
      type: "forgot"
    });
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function resetPassword(payload) {
  try {
    return await verificationService.create({
      type: "password-reset",
      email: payload.email,
      password: payload.password,
      token: payload.token
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function clientLogin(payload) {
  try {
    return await verificationService.create({
      type: 'admin-login',
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      userId: payload.userId,
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

