import React, { Fragment, useEffect } from 'react';

import { reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { connect } from 'react-redux'; 
import { Row } from 'components/Styled/Layout';
import { Hide, FormCard, HeadingLarge, SelectField, TextField, PercentField, FormGroup, NumberField, SaveButton, MoneyAndSelectField } from '../Components';
import initializeFormValues from 'util/initializeFormValues';
import { ButtonPlain } from 'components/Styled/Button';
import styled from 'styled-components';

import imgClose from 'img/button/close-modal.png';

const FORM_NAME = 'submission-other_properties';

const OtherPropertiesFormPure = ({ handleSubmit, submitting, pristine, ownHome, homeIsMorgaged, application }) => { 
  
  useEffect(() => {
    initializeFormValues(FORM_NAME, values => ({ ...values, application }));
  }, []);
  
  return (
    <FormCard onSubmit={handleSubmit}>
      <FormGroup>
        <HeadingLarge>Living Situation</HeadingLarge>
        <Row>
          <SelectField label='Do you own your own home?' name='client.meta.ownHome' width='60px' options={yesNoOptions} />
          <Hide when={ownHome !== 'Yes'}>
            <SelectField label='Is there a mortgage on your home?' name='client.meta.homeIsMorgaged' width='60px' options={yesNoOptions} />
          </Hide>
        </Row>

        <Hide when={homeIsMorgaged !== 'Yes' && ownHome !== 'Yes'}>
          <Row>
            <NumberField label='Loan Amount (if any)' name='client.meta.homeLoanAmount' />
            <PercentField label='Current Interest Rate' name='client.meta.homeCurrentInterestRate' />
            <MoneyAndSelectField label='Approximate Repayment' name='client.meta.homeApproxRepayment' options={timeOptions} />
            <SelectField label='Repayment Type' name='client.meta.homeRepaymentType' width='180px' options={[
              { name: 'Principle & Interest', value: 'Principle & Interest' },
              { name: 'Interest Only', value: 'Interest Only'},
            ]} />
          </Row>
        </Hide>
        
      </FormGroup>
      <FieldArray name='client.meta.investmentProperties' component={InvestmentProperties} />
      <SaveButton disabled={(submitting || pristine)} submitting={submitting}/>
    </FormCard>
  );
};

const DeleteButton = styled.img.attrs({
  src: imgClose,
  width: 16,
})`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

const InvestmentProperties = ({ fields }) => (
  <Fragment>
    {!!fields.length && <HeadingLarge>Investment Properties</HeadingLarge>}

    {fields.map((item, index) => (
      <FormGroup key={index}>
        <DeleteButton onClick={() => fields.remove(index)} />
        <HeadingLarge>Property {index + 1}</HeadingLarge>
        <Row>
          <MoneyAndSelectField label='Rent' name={`${item}.rent`} innerLabel='$' options={timeOptions}/>
          <SelectField label='Ownership' name={`${item}.ownership`} width='200px' options={[
            {name: 'Applicant', value: 'user'},
            {name: 'Applicant\'s partner', value: 'partner'},
            {name: 'Applicant & their partner', value: 'user&partner'},
            {name: 'Other', value: 'other'},
          ]}/>
          <SelectField label='Is there a mortgage on this property?' name={`${item}.isMorgaged`} width='60px' options={yesNoOptions} />
          
          {!!fields.get(index) && (fields.get(index).isMorgaged === 'Yes') && 
            <Row>
              <NumberField label='Loan Amount' name={`${item}.loanAmount`} />
              <PercentField label='Current Interest Rate' name={`${item}.currentInterestRate`} />
              <MoneyAndSelectField label='Approximate Repayment' name={`${item}.approxRepayment`} options={timeOptions}/>
              <SelectField label='Repayment Type' name={`${item}.repaymentType`} width='180px' options={[
                { name: 'Principle & Interest', value: 'Principle & Interest' },
                { name: 'Interest Only', value: 'Interest Only'},
              ]} />
            </Row>
          }
         

        </Row>
      </FormGroup>
    ))}
    <ButtonPlain type='button' onClick={() => fields.push({ isMorgaged: 'No' })} height='32px'>Add Investment Property</ButtonPlain>
  </Fragment>
);



const select = formValueSelector(FORM_NAME);
const stateToProps = state => ({
  ownHome: select(state, 'client.meta.ownHome'),
  homeIsMorgaged: select(state, 'client.meta.homeIsMorgaged'),
});

const OtherPropertiesForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(OtherPropertiesFormPure);

export default connect(stateToProps)(OtherPropertiesForm);

const timePeriod = {
  YEAR: 1,
  MONTH: 1/12,
  WEEK: 1/365.25*7,
  FORTNIGHT: 1/365.25*14,
};

const timeOptions = [
  {name: 'Monthly', value: timePeriod.MONTH},
  {name: 'Fortnightly', value: timePeriod.FORTNIGHT},
  {name: 'Weekly', value: timePeriod.WEEK},
  {name: 'Annually', value: timePeriod.YEAR},
];

const yesNoOptions = [
  {name: 'Yes', value: 'Yes'},
  {name: 'No', value: 'No'},
];