import React from 'react';
import { reduxForm, Form, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';

import { Input, Select, ToggleButton, TextArea, DateInput } from 'components/Form';
import { PullRight } from 'components/Styled/Layout';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column } from '../ViewClient';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

import { requestPatchUser } from 'modules/clients/actions';

import { requestClientLogin } from 'modules/user/actions';

import { makeRequired, required, createValidateDate } from 'util/validation';

import { clientAppUrl } from 'modules';
import { isIOS } from 'util/getDevice';

const requireBroker = makeRequired('Please select a broker');

const validateDate = createValidateDate();

let VideoProposalForm = ({
  //connect
  requestPatchUser,
  requestClientLogin,
  client,
  expiry,
  proposalUrl,
  brokers,
  //props
  handleSubmit, 
  pristine, 
  submitting, 
  invalid 
}) => {


  const submit = values => {
    return new Promise((resolve, reject) => {
      requestPatchUser({
        data: {
          id: client.id,
          proposalBrokerId: values.brokerId,
          meta: {
            proposal: {
              ...values,
              brokerId: null, // override legacy system
            },
            miniproposal: {
              brokerId: null, // override legacy system
            },
          }
        },
        callback: result => {
          if (!result.error) resolve();
          else reject();
        },
        params: {
          mergeMeta: true,
        }
      });
    });
  };

  const viewProposal = () => {
    if (!client) return;
    let siteDomain = clientAppUrl;

    let windowReference;
    
    if (isIOS) {
      windowReference = window.open('', '_blank');
    }

    requestClientLogin({data: {
      email: client.email,
    },
    callback: (result)=>{
      const url = `${siteDomain}/proposal?email=${client.email}&loginToken=${result.token}&godmode=${result.godmode}`;
      if (isIOS) {
        windowReference.location = url;
      } else {
        window.open(url, '_blank');
      }
    }
    });
  }
  
  const brokerOptions = [
    {name: 'None', value: null},
    ...brokers.map(({ id, name }) => ({ name, value: id })),
  ]
  
  let dateColour = 'black';
  if (expiry) {
    const day = parseInt(expiry.slice(0,2));
    const month = parseInt(expiry.slice(2,4));
    const year = parseInt(expiry.slice(4,8));
     
    if (day && month && year) {
      const date = new Date(year, month - 1, day);
      if (!isNaN(date)) {
        const currentDate = new Date();
        const dayDifference = (date - currentDate) / (1000 * 60 * 60 * 24);
        if (dayDifference < 7) dateColour = 'orange';
        if ((dayDifference < 1 && date.getDay() === currentDate.getDay()) || dayDifference < 0) dateColour = 'red';
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Row>

        <Column width='20%'>
          <ToggleButton title='Enable Proposal' name='enabled'/>
        </Column>

        <Column width='30%'>
          <Label>Proposal Expiry Date</Label>
          <DateInput name='expiry' validate={[required, validateDate]} style={{color: dateColour}}/>
        </Column>
      
        <Column width='50%'>
          <Label>Assigned Broker</Label>
          <Select name='brokerId' options={brokerOptions}/>
        </Column>

        <Column width='100%'>
          <Label>Wistia Embeddable Link</Label>
          <Input name='proposalUrl' validate={required}/>
        </Column>

        <Column width='100%'>
          <Label>Overview</Label>
          <TextArea name='overview' validate={required}/>
        </Column>

        <Column width='50%'>
          <ButtonPlain type='button' width='100%' onClick={viewProposal}>View Proposal</ButtonPlain>
        </Column>

        <Column width='50%'>
          <ButtonPrimary type='submit' width='100%' background='#26BD27' disabled={pristine}><ButtonSpinner show={submitting}/>Save Changes</ButtonPrimary>
        </Column>

      </Row>
      <Row>
        <Column width='100%'>
          <Divider width='100%' />
        </Column>
      </Row>
    </Form>
  );
}

VideoProposalForm = reduxForm({
  form: 'videoProposal',
})(VideoProposalForm);

const dispatchToProps = dispatch => 
  bindActionCreators({
  requestPatchUser,
  requestClientLogin,
}, dispatch);

const selector = formValueSelector('videoProposal');
const stateToProps = state => {
  let now = new Date();
  let monthFromNow = new Date(now.setMonth(now.getMonth() + 2));
  return {
    expiry: selector(state, 'expiry'),
    proposalUrl: selector(state, 'proposalUrl'),
    brokers: state.brokers,
    client: state.clients.current,
    initialValues: {
      overview: 'Please find our proposal video below. We have outlined what we feel to be a great solution for your lending needs. If you have any questions or would like to proceed to application, don’t hesitate to get in touch. You can easily ask questions by using the form at the bottom of this page.',
      expiry: ('' + monthFromNow.getDate()).padStart(2, '0') + ('' + monthFromNow.getMonth()).padStart(2, '0') + monthFromNow.getFullYear(), 
      ...state.clients.current.meta.proposal,
      brokerId: state.clients.current.proposalBrokerId || (state.clients.current.meta.proposal || {}).brokerId
    },
  };
};

export default connect(stateToProps, dispatchToProps) (VideoProposalForm);