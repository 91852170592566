import styled from 'styled-components';

const IconImage = styled.img`
  position: relative;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  top: ${({ top }) => top || 0}px;
  left: ${({ left }) => left || 0}px;
`;

export default IconImage;