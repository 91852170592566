import React, { Component, Fragment, useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import moment from 'moment';

import { Form, reduxForm } from 'redux-form';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Column, Row } from 'components/Styled/Layout';
import TextInput from 'components/Styled/TextInput';
import DatePicker from 'react-datepicker';
import Label from 'components/Styled/Label';
import { requestUpdateApplications } from 'modules/applications/actions';
import { useDispatch } from 'react-redux';
import { ApplicationCardContent } from './ApplicationCardContent';
const $ = window.$;

class ApplicationCardBase extends Component {
  constructor() {
    super();
    this.state = {
      menuIsOpen: false,
      menuX: 0,
      menuY: 0,
    };
  }

  componentDidMount() {
    const { data } = this.props;

    $(document).on('click.deal' + data.id, e => {
      this.setState({ menuIsOpen: false });
    });

    $(document).on('contextmenu.deal' + data.id, e => {
      if (e.target.closest('#context-menu-trigger-' + data.id)) return;
      this.setState({ menuIsOpen: false });
    });

    $('#context-menu-trigger-' + data.id).on('contextmenu.deal' + data.id, e => {
      //console.log(e.pageX, e.pageY);
      this.setState({ menuIsOpen: true, menuX: e.pageX, menuY: e.pageY });
      e.preventDefault();
    });
  }

  componentWillUnmount() {
    const { data } = this.props;
    $(document).off('contextmenu.deal' + data.id);
    $(document).off('click.deal' + data.id);
    $('#context-menu-trigger-' + data.id).off();
  }

  render() {
    const { data, isDisabled, index, urlPath, history } = this.props;

    return (
      <Draggable draggableId={data.id + ''} index={index} isDragDisabled={isDisabled}>
        {(provided, snapshot) => <>
          <div 
            className={`task ${snapshot.isDragging && 'dragging'}`}
            id={'context-menu-trigger-' + data.id}
            onClick={() => history.push(urlPath + '?id=' + data.id)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          > 
            <ApplicationCardContent {...this.props} />
          </div>
          {this.state.menuIsOpen &&
            <Menu data={data} style={{ top: this.state.menuY, left: this.state.menuX }} />
          }
        </>}     
      </Draggable>
    );

    
  }
}

export const ApplicationCard = withRouter(ApplicationCardBase);

const ContextMenu = styled.ul`
  position: fixed;
  /* background-color: #838383; */
  background-clip: padding-box;
  /* border: 1px solid rgba(0,0,0,.15);
  border-radius: 8px;
  color: #f5f5f5; */
  font-size: 13px;
  margin: 10px 0 0;
  min-width: 160px;
  outline: none;
  padding: 8px 0; 
  text-align: left;
  z-index: 9999;
  border: 1px solid #E9EBF1;
  border-radius: 5px;
  background-color: white;
  color: black;
`;

const MenuItem = styled.li`
  background: 0 0;
  border: 0;
  //color: #ffffff;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;

  &:hover {
    /* background-color: #9e9e9e;
    border-color:  #9e9e9e; */
    color: #2291FF;
  }
`;

const Menu = ({ data, style }) => {
  const { openModal } = useContext(modalContext);
  const dispatch = useDispatch();

  const openTaskModal = description =>
    () => {
      const initialValues = { 
        currentTaskDescription: description || data.currentTaskDescription, 
        currentTaskDueDate: data.currentTaskDueDate 
      };
      openModal({
        component: TaskModal,
        props: {
          initialValues,
          onSubmit: values => {
            dispatch(requestUpdateApplications({
              id: data.id,
              ...values,
            }));
          }
        }
      });
    };

  const addDays = description => 
    () => {
      const values = { currentTaskDueDate: moment().add(2, 'days') };
      if (description) values.currentTaskDescription = description;
      dispatch(requestUpdateApplications({
        id: data.id,
        ...values,
      }));
    };

  return (
    <ContextMenu style={style}>
      <MenuItem onClick={openTaskModal()}>Edit Task Due Date</MenuItem>
      <MenuItem onClick={addDays()}>Edit Task Due Date + 2 days</MenuItem>
      <MenuItem onClick={openTaskModal('Client Follow Up')}>Set Client Follow Up</MenuItem>
      <MenuItem onClick={addDays('Client Follow Up')}>Set Client Follow Up + 2 days</MenuItem>
      <MenuItem onClick={openTaskModal('Lender Follow Up')}>Set Lender Follow Up</MenuItem>
      <MenuItem onClick={addDays('Lender Follow Up')}>Set Lender Follow Up + 2 days</MenuItem>
    </ContextMenu>
  );
}; 

const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

const DatepickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
      input {
        width: 100%;
        vertical-align: top;
        color: #101922;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0; 
        height: ${p => p.height || '40px'};
        border: 1px solid #d8dce7;
        border-radius: 4px;
        padding: 0 12px 0 12px;
        transition: all 300ms;

        &:focus {
          border: 1px solid #2291FF !important;
          box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
        }

        &.error {
          border: 1px solid rgba(255,0,0,0.5) !important;
          box-shadow: inset 0 0 0 2px rgba(255,0,0,0.2) !important;
        }

        &[readonly] {
          cursor: default;
        }

        &:disabled {
          background-color: #d8dce75e !important;
          color: #545454;
          cursor: no-drop;
        }
      }
  }
`;

const TaskModalBase = ({ modal, onSubmit, handleSubmit, initialValues }) => {
  
  let initialDueDate = initialValues.currentTaskDueDate;
  if (initialDueDate && moment(initialDueDate).isValid()) {
    initialDueDate = new Date(initialDueDate);
  } else {
    initialDueDate = Date.now();
  }

  const [dueDate, setDueDate] = useState(initialDueDate);
  const [description, setDescription] = useState(initialValues.currentTaskDescription);

  const submit = () => {
    modal.close();
    onSubmit({ currentTaskDueDate: moment(dueDate), currentTaskDescription: description });  
  };

  const remove = () => {
    modal.close();  
    onSubmit({ currentTaskDueDate: null,  currentTaskDescription: null });
  };

  const addDays = n => {
    const result = new Date();
    result.setDate(result.getDate() + n);
    setDueDate(result);
  };
  
  return (
    <ModalBox style={{ width: '500px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Deal Tasks</SmallHeading>
        </Row>
        <Row margin='0 0 10px 0'>
          <Label>Description</Label>
          <TextInput onChange={e => setDescription(e.target.value)} value={description}/>
        </Row>
        <Label>Due Date</Label>
        <Row margin='0 0 20px 0'>
          <Column width='45%' margin='0 10% 0 0'>
            
            <DatepickerContainer>
              <DatePicker width='100%' dateFormat='dd/MM/yyyy' selected={dueDate} onChange={date => setDueDate(date)}/>
            </DatepickerContainer>
          </Column>
          <Column width='45%'>
            <ButtonPlain type='button' width='100%'onClick={() => addDays(2)}>2 Days From Now</ButtonPlain>
          </Column>
        </Row>  
        <Row> 
          <Column width='45%' margin='0 10% 0 0'>
            {initialDueDate ? 
              <ButtonPrimary style={{ paddingBottom: '0'}} background='#FF4E4C' type='button' onClick={remove} width='100%'>Remove</ButtonPrimary>
              :
              <ButtonPrimary style={{ paddingBottom: '0'}} background='#FF4E4C' type='button' onClick={modal.close} width='100%'>Cancel</ButtonPrimary>
            }
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Update</ButtonPrimary>
          </Column>
        </Row>
      </Form>
    </ModalBox>
  );
};

const TaskModal = reduxForm({ form: 'task-due-date'})(TaskModalBase);
