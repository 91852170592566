import React from 'react';
import { NavLink } from 'react-router-dom';

// Removes url params from NavLink params.to so it will match regardless of url params

export const PathNavLink = props => (
  <NavLink replace={props.replace} isActive={
    (_, { pathname }) => pathname === props.to.split(/([^\?]+)/)[1]} 
  {...props} 
  />
);