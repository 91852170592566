import React from 'react';
import Img from 'react-image';
import { logoUrl } from 'modules';

export default ({ product, props }) => {
  let altLogoSrc = '';
  try {
    altLogoSrc = require(`img/banks/${product.bank}.png`);
  } catch(e) {}
  return (
    <Img 
      className="company-logo" 
      src={[`${logoUrl}/${product.bank}.png`, altLogoSrc]} 
      alt="logo" 
      unloader={<h6>{product.bank_name}</h6>}
      {...props}
    />
  )
    
}




  