import React, { Component, Fragment } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import styled from 'styled-components';

import Input from 'components/Styled/TextInput';
import imgPin from 'img/brand/google_maps.svg';

const { google } = window;

export default class AddressInput extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isGoogleApproved: false,
    };
  }

  componentDidMount() {
    const { placeId } = this.props.input.value;
    if (placeId) 
      geocodeByPlaceId(placeId)
        .then(() => this.setState({ isGoogleApproved: true }));
  }

  handleChange = address => {
    this.setState({ isGoogleApproved: false });
    this.props.input.onChange && this.props.input.onChange({ address, placeId: null });
  };

  handleSelect = async (address, placeId) => {
    let postcode, locale;
    if (placeId) {
      this.setState({isGoogleApproved: true});
      const geocode = (await geocodeByPlaceId(placeId) || [])[0];
      if (geocode && geocode.address_components) {
        postcode = geocode.address_components.find(c => c.types[0] === 'postal_code')
        locale = (geocode.address_components.find(c => c.types[0] === 'locality') || {}).short_name
      }
    }
    
    if (postcode && !address.includes(postcode.short_name)) {
      address += " " + postcode.short_name;
    }
    this.props.input.onChange && this.props.input.onChange({ address, placeId, locale });

    
  };

  render() {
    const { width, meta: {touched, error} } = this.props;
    return (
      <PlacesAutocomplete

        value={this.props.input.value.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{position: 'relative', width}} className='address-input'>
            {this.state.isGoogleApproved && <Fragment><VerifiedText>Google Verified</VerifiedText><ImgGooglePin/></Fragment>}
            <Input
              className={touched && error && 'error'}
              type='address'
              style={suggestions.length > 0 ? {borderBottomRightRadius: '0', borderBottomLeftRadius: '0'} : {}} 
              value={this.props.input.value.address}
              {...getInputProps({
                ...this.props,
              })}/>
            {suggestions.length > 0 && 
              <OptionContainer style={{width, minWidth: width}}>
                {suggestions.map((suggestion, index) => {
                  return (
                    <SelectOption key={index} {...getSuggestionItemProps(suggestion, {
                      mainText: suggestion.formattedSuggestion.mainText,
                      secondaryText: suggestion.formattedSuggestion.secondaryText,
                      isHighlighted: suggestion.active,
                      width,
                    })}/>
                  )
                }
              )}
              </OptionContainer>
            }
            {/* {touched && error && <ErrorMessage>{error}</ErrorMessage>} */}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

const ImgGooglePin = styled.img.attrs({
  src: imgPin,
  width: 10,
})`
  position: absolute;
  top: -19px;
  right: 2px;
`;

const VerifiedText = styled.div`
  position: absolute;
  top: -18px;
  right: 17px;
  color: #2291FF;
  font-size: 12px;
`;


const OptionContainer = styled.div`
    position: absolute;
    background-color: white;
    border: 1px solid #D8DCE7;
    border-top: none;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 3px 7px 0px #0000001f;
    z-index: 100;
    

`;

const Option = styled.div`
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  user-select: none;
  width: ${p => p.width};
 
  &:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &:hover {
    background-color: #f3f3f3
  }
`;

const SelectOption = ({ mainText, secondaryText, isHighlighted, ...props }) => (
  <Option 
    {...props}
    style={isHighlighted ? { background: '#2291FF', color: 'white' } : {}}>
    <MainText>{mainText}</MainText>
    <SecondaryText>{secondaryText}</SecondaryText>
  </Option>
);

const MainText = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin-right: 5px;
`;


const SecondaryText = styled.span`
  font-size: 13px;
`;