import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { change, formValueSelector, initialize as initializeForm } from 'redux-form';
import { connect } from 'react-redux';


import { Row, Column } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';
import Label from 'components/Styled/Label';

import { ButtonPlain } from 'components/Styled/Button';

import imgTick from 'img/button/check-bold.png';
import imgCross from 'img/button/simple-remove.png';

import { Input as TextInput, MoneyInput, PercentInput, ToggleButton, MonthInput, YearInput, NumberInput, StyledSelect as Select } from 'components/Form';
import { bindActionCreators } from 'redux';
import { requestPatchProduct } from 'modules/products/actions';


const ImgTick = styled.img.attrs({
  src: imgTick,
  width: 16,
})`
  display: 'inline-block';
  margin-right: 10px;
`;

const RightColumn = styled(Column)`
  min-height: 85px;
  width: calc(100% - 230px);
  padding: 0 40px 20px 40px;
  border-right: 1px solid #D8DCE7;
`;

const LeftColumn = styled(Column)`
  min-height: 85px;
  width: 230px;
  padding: 0 40px 20px 40px;
`;

const SpacedColumn = styled(Column)`
  padding-left: 20px;
`;


const FadeInContainer = styled.div`
  transition: all 300ms;

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
`;


const FadeIn = ({ show, ...props }) => (
  <FadeInContainer className={show ? 'show' : 'hide'} {...props} />
);

class TextInputRowBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedOn: props.updatedOn,
    };
  }

  onToggle = e => {
    const { change, name, defaultValue, isOverriden, formName, needsReview} = this.props;
    
    needsReview || isOverriden || change(formName, `${name}.override`, defaultValue);
  }

  onConfirmReview = () => {
    const { isOverriden, overrideValue, product, name, formName, change, form } = this.props;
    //this.props.initializeForm(product.formName, product);
    this.props.initializeForm(formName, { 
      ...form,
      [name]: {
        ...form[name],
        needsReview: false,
      }
    });

    let overrides = { id: product.id.raw, };
    for (const key in form) {
      if (!form[key] || form[key].raw == null) continue;
      const { isOverriden, override, raw } = form[key];
      overrides[key] = isOverriden ? (override || '') : raw;
    }
    

    this.props.requestPatchProduct({ 
      product: overrides, 
    });
  }

  Input = ({ type, ...props }) => {

    switch(type) {
    case 'text': 
      return <TextInput width='280px' {...props} />;
    case 'number':
      return <NumberInput width='280px' {...props} />;
    case 'money': 
      return <Column><MoneyInput width='280px' {...props}/></Column>;
    case 'percent': 
      return <Column><PercentInput width='280px' {...props}/></Column>;
    case 'month': 
      return <Column><MonthInput width='280px' {...props}/></Column>;
    case 'year': 
      return <Column><YearInput width='280px' {...props}/></Column>;
    default: 
      return <TextInput width='280px' {...props} />;
    }
  } 

  render() {
    const { title, bottomRow, type, name, isOverriden, needsReview } = this.props;
    const { updatedOn, defaultValue, overrideValue } = this.state;

    return (
      <Row height={bottomRow ? '105px' : '85px'}>
        <RightColumn>

          <Column width='280px'>
            <Row>
              <Column>
                <Label>{title}</Label>
              </Column>
              {/* <Column width='100px'>
                <FadeIn show={isOverriden && updatedOn}>
                  <Txt size='12px' marginTop='2.5px' color='#FF4E4C'>Updated {updatedOn}</Txt>
                </FadeIn>
              </Column> */}
              <this.Input name={`${name}.raw`} type={type} disabled={isOverriden} readOnly value={defaultValue}/>
            </Row>
          </Column>

          <SpacedColumn width='180px'>
            <div style={{ height: '32px' }} />
            <ToggleButton name={`${name}.isOverriden`} onChange={this.onToggle} checked={isOverriden}/>
            {!needsReview && (
              isOverriden ?
                <Txt as='span' size='14px' color='#26BD27'>Override Active</Txt>
                :
                <Txt as='span' size='14px' color='#A3A8B2'>Override Off</Txt>
              )
            }
            {needsReview && (
                isOverriden ?
                  <Txt as='span' size='14px' color='#26BD27'>Keep Override</Txt>
                  :
                  <Txt as='span' size='14px' color='#e04545'>Remove Override</Txt>
                )
              }
          </SpacedColumn>

          <Column width='280px' style={{ float: 'right' }}>
            <FadeIn show={isOverriden || needsReview}>
              <Label>{title}</Label>
              <this.Input 
                name={`${name}.override`}
                type={type}
                disabled={!isOverriden}
              />
            </FadeIn>
          </Column>

        </RightColumn>
        <LeftColumn>
          <div style={{ height: '30px' }} />
          <FadeIn show={needsReview}>
            <ButtonPlain
              type='button'
              width='100%'
              height='32px'
              onClick={this.onConfirmReview}>
              <ImgTick/>
              Confirm Review
            </ButtonPlain>
          </FadeIn>

        </LeftColumn>
      </Row>

    );
  }
}

const MapStateToProps = (state, props) =>  { 
  const selector = formValueSelector(props.formName);
  return {
    isOverriden: selector(state, `${props.name}.isOverriden`),
    defaultValue: selector(state, `${props.name}.raw`),
    overrideValue: selector(state, `${props.name}.override`),
    needsReview: selector(state, `${props.name}.needsReview`),
    form: state.form[props.formName].values,
    product: state.products.current,
}};

const MapDispatchToProps = dispatch => bindActionCreators({ 
  change, 
  requestPatchProduct, 
  initializeForm 
}, dispatch);

export const TextInputRow = connect(MapStateToProps, MapDispatchToProps) (TextInputRowBase);



class ToggleInputRowBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedOn: props.updatedOn,
    };
  }

  onToggle = e => {
    const { change, name, defaultValue, isOverriden, formName, needsReview} = this.props;

    needsReview || isOverriden || change(formName, `${name}.override`, defaultValue);
  }

  onConfirmReview = () => {
    const { isOverriden, overrideValue, product, name, formName, change, form } = this.props;
    //this.props.initializeForm(product.formName, product);
    this.props.initializeForm(formName, { 
      ...form,
      [name]: {
        ...form[name],
        needsReview: false,
      }
    });

    let overrides = { id: product.id.raw, };
    for (const key in form) {
      if (!form[key] || form[key].raw == null) continue;
      const { isOverriden, override, raw } = form[key];
      overrides[key] = isOverriden ? (override || '') : raw;
    }


    this.props.requestPatchProduct({ 
      product: overrides, 
    });
  }


  render() {
    const { title, name, isOverriden, defaultValue, overrideValue, valueChecked, valueUnchecked, needsReview } = this.props;
    const { updatedOn } = this.state;
    return (
      <Row>
        <RightColumn>

          <Column width='280px'>
            <Row>
              <Column>
                <Label marginBottom='18px'>{title}</Label>
              </Column>
              <Column>
                <ToggleButton  name={`${name}.raw`} valueChecked={valueChecked} valueUnchecked={valueUnchecked} disabled/>
                <Txt as='span' size='14px'>
                  {(defaultValue === valueChecked) ? 'Yes' : 'No'}
                </Txt>
              </Column>

            </Row>
          </Column>

          <SpacedColumn width='180px'>
            <div style={{ height: '31px' }} />
            <ToggleButton name={`${name}.isOverriden`} onChange={this.onToggle} checked={isOverriden}/>
            {!needsReview && (
              isOverriden ?
                <Txt as='span' size='14px' color='#26BD27'>Override Active</Txt>
                :
                <Txt as='span' size='14px' color='#A3A8B2'>Override Off</Txt>
              )
            }
            {needsReview && (
                isOverriden ?
                  <Txt as='span' size='14px' color='#26BD27'>Keep Override</Txt>
                  :
                  <Txt as='span' size='14px' color='#e04545'>Remove Override</Txt>
                )
              }
          </SpacedColumn>

          <Column width='280px' style={{ float: 'right' }}>
             <FadeIn show={isOverriden || needsReview}>
              <Label marginBottom='18px'>{title}</Label>
              <ToggleButton name={`${name}.override`} disabled={!isOverriden} valueChecked={valueChecked} valueUnchecked={valueUnchecked}/>
              <Txt as='span' size='14px'>
                {(overrideValue === valueChecked) ? 'Yes' : 'No'}
              </Txt>
            </FadeIn>
          </Column>

        </RightColumn>
        <LeftColumn>
          <div style={{ height: '30px' }} />
          <FadeIn show={needsReview}>
            <ButtonPlain
              width='100%'
              height='32px'
              onClick={this.onConfirmReview}>
              <ImgTick/>
              Confirm Review
            </ButtonPlain>
          </FadeIn>

        </LeftColumn>
      </Row>

    );
  }
}



export const ToggleInputRow = connect(MapStateToProps, MapDispatchToProps) (ToggleInputRowBase);

class SelectInputRowBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedOn: props.updatedOn,
    };
  }

  onToggle = e => {
    const { change, name, defaultValue, isOverriden, formName, needsReview} = this.props;
    
    needsReview || isOverriden || change(formName, `${name}.override`, defaultValue);
  }

  onConfirmReview = () => {
    const { isOverriden, overrideValue, product, name, formName, change, form } = this.props;
    //this.props.initializeForm(product.formName, product);
    this.props.initializeForm(formName, { 
      ...form,
      [name]: {
        ...form[name],
        needsReview: false,
      }
    });

    let overrides = { id: product.id.raw, };
    for (const key in form) {
      if (!form[key] || form[key].raw == null) continue;
      const { isOverriden, override, raw } = form[key];
      overrides[key] = isOverriden ? (override || '') : raw;
    }
    

    this.props.requestPatchProduct({ 
      product: overrides, 
    });
  }


  render() {
    const { title, name, isOverriden, defaultValue, overrideValue, children, needsReview } = this.props;
    const { updatedOn } = this.state;
    return (
      <Row>
        <RightColumn>

          <Column width='280px'>
            <Row>
              <Column width='180px'>
                <Label marginBottom='18px'>{title}</Label>
              </Column>
              <Column width='100px'>
                <FadeIn show={isOverriden && updatedOn}>
                  <Txt size='12px' marginTop='2.5px' color='#FF4E4C'>Updated {updatedOn}</Txt>
                </FadeIn>
              </Column>
              <Column>
                <Select name={`${name}.raw`} noClick className={isOverriden ? 'disabled' : 'no-arrow'}>{children}</Select> 
              </Column>

            </Row>
          </Column>

          <SpacedColumn width='180px'>
            <div style={{ height: '38px' }} />
            <ToggleButton name={`${name}.isOverriden`} onChange={this.onToggle} checked={isOverriden} />
            {!needsReview && (
              isOverriden ?
                <Txt as='span' size='14px' color='#26BD27'>Override Active</Txt>
                :
                <Txt as='span' size='14px' color='#A3A8B2'>Override Off</Txt>
              )
            }
            {needsReview && (
                isOverriden ?
                  <Txt as='span' size='14px' color='#26BD27'>Keep Override</Txt>
                  :
                  <Txt as='span' size='14px' color='#e04545'>Remove Override</Txt>
                )
              }
          </SpacedColumn>

          <Column width='280px' style={{ float: 'right' }}>
             <FadeIn show={isOverriden || needsReview}>
              <Label marginBottom='18px'>{title}</Label>
              <Select name={`${name}.override`} disabled={!isOverriden} >{children}</Select>
            </FadeIn>
          </Column>

        </RightColumn>
        <LeftColumn>
          <div style={{ height: '30px' }} />
          <FadeIn show={needsReview}>
            <ButtonPlain
              width='100%'
              height='32px'
              onClick={this.onConfirmReview}>
              <ImgTick/>
              Confirm Review
            </ButtonPlain>
          </FadeIn>

        </LeftColumn>
      </Row>

    );
  }
}



export const SelectInputRow = connect(MapStateToProps, MapDispatchToProps) (SelectInputRowBase);

const LeftColumnSmall = styled(LeftColumn)`
  min-height: 0px;
  height: 45px;
`;

const RightColumnSmall = styled(RightColumn)`
  min-height: 0px;
  height: 45px;
`;

const ImgCross = styled.img.attrs({
  src: imgCross,
  width: 12,
})`
  display: 'inline-block';
  margin-right: 14px;
  display: relative;
  top: 8px;
`;

class ToggleRowSmallBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedOn: props.updatedOn,
    };
  }

  onConfirmReview = () => {
    const { isOverriden, overrideValue, product, name, formName, change, form } = this.props;
    //this.props.initializeForm(product.formName, product);
    this.props.initializeForm(formName, { 
      ...form,
      [name]: {
        ...form[name],
        needsReview: false,
      }
    });

    let overrides = { id: product.id.raw, };
    for (const key in form) {
      if (!form[key] || form[key].raw == null) continue;
      const { isOverriden, override, raw } = form[key];
      overrides[key] = isOverriden ? (override || '') : raw;
    }


    this.props.requestPatchProduct({ 
      product: overrides, 
    });
  }

  onToggle = e => {
    const { change, name, defaultValue, isOverriden, formName, needsReview } = this.props;

    needsReview || isOverriden || change(formName, `${name}.override`, (defaultValue === 'Y') ? 'N' : 'Y');
  }

  render() {
    const { title, name, isOverriden, defaultValue, overrideValue, needsReview } = this.props;
    const { updatedOn } = this.state;
    return (
      <Row>
        <RightColumnSmall>
          <Column width='250px'>
            <Column width='180px'>
              {(defaultValue === 'Y') ?
                <Fragment>
                  <ImgTick />
                  <Txt as='span' size='16px'>{title}</Txt>
                </Fragment>
                :
                <Fragment>
                  <ImgCross />
                  <Txt decoration='line-through solid' as='span' size='16px' color='#D8DCE7'>{title}</Txt>
                </Fragment>
              }
              
            </Column>
            
          </Column>
          <Column width='200px'>
            <Column width='50px'>
              <ToggleButton  top='-2px' name={`${name}.isOverriden`} onChange={this.onToggle}/>
            </Column>
            <Column width='150px'>
            {!needsReview && (
                isOverriden ?
                  <Txt as='span' size='14px' color='#26BD27'>Override Active</Txt>
                  :
                  <Txt as='span' size='14px' color='#A3A8B2'>Override Off</Txt>
                )
              }
              {needsReview && (
                isOverriden ?
                  <Txt as='span' size='14px' color='#26BD27'>Keep Override</Txt>
                  :
                  <Txt as='span' size='14px' color='#e04545'>Remove Override</Txt>
                )
              }
            </Column>
          </Column>
           <FadeIn show={isOverriden || needsReview}>
            <Column width='50px'>
              <ToggleButton name={`${name}.override`} disabled={!isOverriden} top='-2px' valueChecked='Y' valueUnchecked='N'/>
            </Column>
            <Column width='180px'>
              {overrideValue === 'Y'?
                <Fragment>
                  <ImgTick />
                  <Txt as='span' size='16px'>{title}</Txt>
                </Fragment>
                :
                <Fragment>
                  <ImgCross />
                  <Txt decoration='line-through solid' as='span' size='16px' color='#D8DCE7'>{title}</Txt>
                </Fragment>
              }

            </Column>
          </FadeIn>
          
          
          <Column width='200px'>
            <FadeIn show={updatedOn && isOverriden}>
              <Txt size='14px' color='red'>Updated on {updatedOn}</Txt>
            </FadeIn>
          </Column>
        </RightColumnSmall>
        <LeftColumnSmall>
          <FadeIn show={needsReview}>
            <ButtonPlain
              type='button'
              style={{marginTop: '-8px'}}
              width='100%'
              height='32px'
              onClick={this.onConfirmReview}>
              <ImgTick/>Confirm Review
            </ButtonPlain>
          </FadeIn>

        </LeftColumnSmall>
      </Row>
    );
  }
}


export const ToggleRowSmall = connect(MapStateToProps, MapDispatchToProps) (ToggleRowSmallBase);

class TextInputRowSmallBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedOn: props.updatedOn,
    };
  }

  onToggle = e => {
    const { change, name, defaultValue, isOverriden, formName, needsReview} = this.props;
    
    needsReview || isOverriden || change(formName, `${name}.override`, defaultValue);
  }

  onConfirmReview = () => {
    const { isOverriden, overrideValue, product, name, formName, change, form } = this.props;
    //this.props.initializeForm(product.formName, product);
    this.props.initializeForm(formName, { 
      ...form,
      [name]: {
        ...form[name],
        needsReview: false,
      }
    });

    let overrides = { id: product.id.raw, };
    for (const key in form) {
      if (!form[key] || form[key].raw == null) continue;
      const { isOverriden, override, raw } = form[key];
      overrides[key] = isOverriden ? (override || '') : raw;
    }
    

    this.props.requestPatchProduct({ 
      product: overrides, 
    });
  }

  render() {
    const { title, name, isOverriden, defaultValue, overrideValue, bottomRow, needsReview } = this.props;
    const { updatedOn } = this.state;
    return (
      <Row height={bottomRow ? '105px' : '85px'}>
        <RightColumnSmall>

          <Column width='170px'>
            <div style={{height: '7px'}}/>
            <Txt size='12px'>{title}</Txt>
          </Column>

          <SpacedColumn width='100px' style={{paddingTop: '-20px'}}>
            <MoneyInput name={`${name}.raw`}  fontSize='12px' height='25px' width='100%' readOnly disabled={isOverriden} style={{fontSize: '12px'}}/>
          </SpacedColumn>

          <SpacedColumn width='200px'>
          
            <ToggleButton name={`${name}.isOverriden`} onChange={this.onToggle}/>
            {!needsReview && (
              isOverriden ?
                <Txt as='span' size='14px' color='#26BD27'>Override Active</Txt>
                :
                <Txt as='span' size='14px' color='#A3A8B2'>Override Off</Txt>
              )
            }
            {needsReview && (
              isOverriden ?
                <Txt as='span' size='14px' color='#26BD27'>Keep Override</Txt>
                :
                <Txt as='span' size='14px' color='#e04545'>Remove Override</Txt>
              )
            }
          </SpacedColumn>
          
          <Column width='100px' margin='0 30px 0 0'>
             <FadeIn show={isOverriden || needsReview}>
              <MoneyInput name={`${name}.override`} disabled={!isOverriden} fontSize='12px' height='25px' width='100%' style={{fontSize: '12px'}}/>
            </FadeIn>
          </Column>

          <Column width='200px'>
            <FadeIn show={updatedOn && isOverriden}>
              <Txt marginTop='5px' size='14px' color='red'>Updated on {updatedOn}</Txt>
            </FadeIn>
          </Column>

        </RightColumnSmall>
        <LeftColumnSmall>
          <FadeIn show={needsReview}>
            <ButtonPlain
              type='button'
              width='100%'
              height='32px'
              onClick={this.onConfirmReview}>
              <ImgTick/>
              Confirm Review
            </ButtonPlain>
          </FadeIn>

        </LeftColumnSmall>
      </Row>

    );
  }
}

export const TextInputRowSmall = connect(MapStateToProps, MapDispatchToProps) (TextInputRowSmallBase);

class OffsetInputRowBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedOn: props.updatedOn,
    };
  }

  onConfirmReview = () => {
    const { isOverriden, overrideValue, product, name, formName, change, form } = this.props;
    //this.props.initializeForm(product.formName, product);
    this.props.initializeForm(formName, { 
      ...form,
      [name]: {
        ...form[name],
        needsReview: false,
      }
    });

    let overrides = { id: product.id.raw, };
    for (const key in form) {
      if (!form[key] || form[key].raw == null) continue;
      const { isOverriden, override, raw } = form[key];
      overrides[key] = isOverriden ? (override || '') : raw;
    }


    this.props.requestPatchProduct({ 
      product: overrides, 
    });
  }

  onToggle = e => {
    const { change, name, defaultValue, isOverriden, formName, needsReview } = this.props;

    needsReview || isOverriden || change(formName, `${name}.override`, (defaultValue === 'Y') ? 'N' : 'Y');
  }

  render() {
    const { title, name, isOverriden, defaultValue, overrideValue, needsReview } = this.props;
    const { updatedOn } = this.state;
    return (
      <Row>
        <RightColumnSmall style={{ padding: '0 0 20px 40px' }}>
          <Column width='250px'>
            <Column width='180px'>
              {(defaultValue === 'Y') ?
                <Fragment>
                  <ImgTick />
                  <Txt as='span' size='16px'>{title}</Txt>
                </Fragment>
                :
                <Fragment>
                  <ImgCross />
                  <Txt decoration='line-through solid' as='span' size='16px' color='#D8DCE7'>{title}</Txt>
                </Fragment>
              }
            </Column>
            
          </Column>
          <Column width='200px'>
            <Column width='50px'>
              <ToggleButton  top='-2px' name={`${name}.isOverriden`} onChange={this.onToggle}/>
            </Column>
            <Column width='150px'>
              {!needsReview && (
                isOverriden ?
                  <Txt as='span' size='14px' color='#26BD27'>Override Active</Txt>
                  :
                  <Txt as='span' size='14px' color='#A3A8B2'>Override Off</Txt>
              )}
              {needsReview && (
                isOverriden ?
                  <Txt as='span' size='14px' color='#26BD27'>Keep Override</Txt>
                  :
                  <Txt as='span' size='14px' color='#e04545'>Remove Override</Txt>
              )}
            </Column>
          </Column>
          <FadeIn show={isOverriden || needsReview}>
            <Column width='100px' style={{marginRight: '20px'}}>
              <Select name={`${name}.override`} disabled={!isOverriden} style={{position: 'relative', top: '-10px'}}>
                <option value='Y'>Yes</option>
                <option value='N'>No</option>
                <option value='additional'>Custom</option>
              </Select>
            </Column> 
            <TooltipColumn>
              <FadeIn show={overrideValue === 'additional'}> 
                <TextInput name='offsetTooltip.raw' style={{position: 'relative', top: '-10px'}}/>
              </FadeIn>
            </TooltipColumn>
          </FadeIn>
        </RightColumnSmall>
        <LeftColumnSmall>
          <FadeIn show={needsReview}>
            <ButtonPlain
              type='button'
              style={{marginTop: '-8px'}}
              width='100%'
              height='32px'
              onClick={this.onConfirmReview}>
              <ImgTick/>Confirm Review
            </ButtonPlain>
          </FadeIn>

        </LeftColumnSmall>
      </Row>
    );
  }
}

export const OffsetInputRow = connect(MapStateToProps, MapDispatchToProps) (OffsetInputRowBase);

const TooltipColumn = styled(Column)`
  width: calc(100% - 610px);
`;