import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
  box-sizing: border-box; 
  width: ${({width}) => width || 'calc(100% - 40px)'};
  height: 1px;
  background: #E9EBF1;
  margin: ${p => p.margin || '20px 40px 20px 0'};
`;

export default Divider;