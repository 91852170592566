import {
  applicationService,
  userService,
  applicationListsService,
} from '../index';

import moment from 'moment';

import { ActionQueue } from 'util/index';
const actionQueue = new ActionQueue();

export async function createApplication(app) {
  try {
    return await applicationService.create({ ...app, sessionId: sessionStorage.tabID } );
    //return { ...app, id: Math.floor(Math.random() * 999999) };
  } catch(err) {
    console.log(err);
    return { error: err };
  }
}

export async function createStage(stage) {
  try {
    return await applicationListsService.create(stage);
  } catch(err) {
    console.log(err);
    return { error: err };
  }
}

export async function removeStage(id) {
  try {
    return await applicationListsService.remove(id);
  } catch(err) {
    console.log(err);
    return { error: err };
  }
}

export async function patchStage(stage) {
  try {
    return await applicationListsService.patch(stage.id, stage);
  } catch(err) {
    console.log(err);
    return { error: err };
  }
}

export async function moveApplication({ listId, placeId, afterId} ) {
  
  actionQueue.add( async () => {
    try {
      await applicationListsService.patch(listId, { 
        "place": placeId, 
        "after": afterId
      });
    } catch(err) {
      console.log(err);
    }
    return;
  });
}


export async function getApplication(id) {
  try {
    return await applicationService.get(id);
  } catch (error) {
    return { error };
  }
}

export async function getApplications({ archived = false, dealsOnly= false }) {
  return await actionQueue.add( async () => {
    try {
      // an array all the deal pipeline stages. It's missing 30 "declined" simply because the query seems to fail if it has any more than 21 stages in it :/
      const dealListIds = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33, 34, 35];

      const query = {$limit: 1000000, list: {$ne: null}, isArchived: archived ? 1 : 0};
      if (dealsOnly) {
        query.list = {$in: dealListIds};
      }
      const [ appListQuery, appQuery] = await Promise.all([
        applicationListsService.find({query: {$limit: 1000000}}),
        applicationService.find({query})
      ]);

      const lists = appListQuery.data;
      let apps = appQuery.data;

      // TODO remove this, it's only adding the tags field until this exists in the DB
      apps = await Promise.all(
        apps.map(async it => ({ ...it, tags: [] }))
      );

      // convert apps array to map to make untangling the linked list easier
      const appMap = apps.reduce( (obj, app) => ({ ...obj, [app.id]: app }), {} );
        
      let listApps = apps.reduce( (obj, app) => ({ 
        ...obj, 
        [app.list]: [ 
          ...(obj[app.list] ?? []), 
          app 
        ]
      }), {} );

      listApps = Object.keys(listApps).reduce( (obj, listId) => ({ 
        ...obj, 
        [listId]: listApps[listId].sort((a,b) => a.listOrder - b.listOrder)
      }), {} );

      for (const key in appMap) {
        const parentId = appMap[key].parentApplicationId;
        if (parentId) {
          appMap[key].meta = appMap[parentId].meta;
        }
      }

      let listMap = {};
      for (let i in lists) {
        const list = lists[i];
        let applications = listApps[list.id] ?? [];
        
        applications = applications.sort(sortByUrgency);
        if (list.name === 'Unassigned') {
          applications = applications.sort(sortToTopIfVerified);
        }
        listMap = { ...listMap, [list.id]: { ...list, applications } };
      }
      //console.log(JSON.stringify(listMap));
      return { stages: listMap, list: appMap };

    } catch(err) {
      console.log(err);
      return err;
    }
  });
  
}

const sortToTopIfVerified = (a, b) => {
  return (b.user_isMobileVerified ? 1 : 0) - (a.user_isMobileVerified ? 1 : 0);
};

const sortByUrgency = (a, b) => {
  if (!a.dueDate && !b.dueDate) return 0;
  if (!a.dueDate) return 1;
  if (!b.dueDate) return -1;
  const dateA = moment(a.dueDate);
  const dateB = moment(b.dueDate);
  const dateAValid = dateA.isValid();
  const dateBValid = dateB.isValid();
  if (dateAValid && dateBValid) return moment(a.dueDate).valueOf() - moment(b.dueDate).valueOf();
  if (!dateAValid && !dateBValid) return 0;
  if (!dateAValid) return 1;
  if (!dateBValid) return -1;
  return 0;  
};

// export async function getApplication(id) {
//   try {
//     const app = await applicationService.get(id);
    
//     const [client, broker] = await Promise.all([
//       async () => userService.get(app.userId),
//       async () => userService.get(app.brokerId)
//     ]);

//     return { ...app, client, broker };

//   } catch(err) {
//     console.log(err);
//     return {};
//   }
// }

export async function patchApplication(app) {
  try {
    if (app.parentApplicationId) {
      await applicationService.patch(app.parentApplicationId, { meta: app.meta, sessionId: sessionStorage.tabID });
      delete app.meta;
    }
  
    const result = await applicationService.patch(app.id, { ...app, sessionId: sessionStorage.tabID });
    return result;
  } catch(err) {
    console.log(err);
    return {};
  }
}