import React from 'react';

import Context from './ContextOld';

export * from './ModalBoxOld';
export * from './ModalContextOld';


export const ModalShow = ({ as, asSelected, modalProps, ...props } ) => {
  const Component = as;
  const ComponentSelected = asSelected || as;

  return (
    <Context.Consumer>
      {modal => 
        modal.isOpen ?
          <ComponentSelected onClick={() => modal.hide()} {...props}/>
          :
          <Component onClick={e => {
            e.preventDefault();  
            modal.show(modalProps);
          }} {...props}/>
      }
    </Context.Consumer>
  );
};

export const ModalHide = ({ as, ...props } ) => {
  const Component = as;

  return (
    <Context.Consumer>
      {modal =>
        <Component onClick={modal.hide} {...props}/>
      }
    </Context.Consumer>
  );
}

export const ModalSubmit = ({ as, data, ...props }) => {
  const Component = as;

  return (
    <Context.Consumer>
      {modal =>
        <Component onClick={() => modal.submit(data, modal.hide)} {...props}/>
      }
    </Context.Consumer>
  );
}
