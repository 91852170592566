import {
  productService,
  productRawService,
  productOverrideService,
} from '../index';

const areDifferent = (a, b) => {
  const aNum = parseFloat(a);
  const bNum = parseFloat(b);
  if (aNum && bNum) return aNum !== bNum;
  else return a.toString() !== b.toString();
}

export async function getProductWithOverrides(id) {
  try {

    const getRawProduct = async () => {
      try {
        const product = await productRawService.get(id);
        return product;
      } catch(err) {
        return {};
      }
    };

    let [ productVisible, productRaw, productOverrides ] = await Promise.all([
      productService.get(id),
      getRawProduct(),
      productOverrideService.find({
        query: {
          $limit: 10000,
          product_id: id
        }
      }),
    ]);

    let product = { ...productVisible, ...productRaw };

    if (!productRaw.id) {
      product.product_active = '';
    } else {
      product.product_active = 'Y';
    }

    productOverrides = productOverrides.data.reduce((obj, { key, value }) => ({
      ...obj,
      [key]: JSON.parse(value),
    }), {});

    const data = Object.keys(product).reduce((obj, key) => ({
      ...obj,
      [key]: {
        raw: product[key],
        override: productOverrides[key],
        needsReview: productOverrides[key] != null && areDifferent(productVisible[key], productOverrides[key]),
      },
    }), {});

    return { data };

  } catch (error) {
    console.log(error);
    return { error };
  }
}

export async function patchProduct(product) { 
  try {
    return await productService.patch(product.id, product);
  } catch(error) {
    console.log(error);
    return { error };
  }
}

export async function findProductsWithOverride({ limit, skip, search, sortField, sortDirection }) {
  try {
    const rawProducts = productRawService.find({
      query: {
        $limit: limit,
        $skip: skip,
        $or: [
          { bank_name: {$like:`%${search}%`} },
          { product_name: { $like:`%${search}%`} }
        ],
        $sort: {
          [sortField]: sortDirection,
        },
      },
    });

    return await Promise.all(
      rawProducts.map(async product => {
        const overrides = await productOverrideService.find({
          query: {
            product_id: product.id
          }
        });
        return Object.keys(product).reduce((obj, key) => ({
          ...obj,
          [key]: {
            raw: product[key],
            override: overrides[key],
          },
        }), {});
      })
    );
  } catch (error) {
    console.log(error);
    return { error };
  }
}

export async function findProducts({ limit, skip, search, sortField, sortDirection, overridenOnly }) {
  try {
    
    const { data, total } = await productService.find({
      query: {
        $limit: limit,
        $skip: skip,
        $or: [
          { bank_name: {$like:`%${search}%`} },
          { bank:  {$like:`%${search}%`} },
          { product_name: { $like:`%${search}%`} }
        ],
        
        $sort: {
          [sortField]: sortDirection,
        },
        ...(overridenOnly ? { isOverriden: 1 } : {}),
      },
    });

    return { data, total, toAppend: skip > 0 };
    
  } catch (error) {
    console.log(error);
    return { error };
  }
}