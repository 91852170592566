import { documentService, documentTemplateService, templateDocService } from 'modules';

export async function findDocuments() {
  try {
    const { data } = await documentService.find({query: { $limit: 100000 }});
    return data;
  } catch(err) {
    console.log(err);
    return [];
  } 
}

export async function patchDocument({ data }) {
  try {
    return await documentService.patch(data.id, data);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createDocument(document) {
  try {
    return await documentService.create(document);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function removeDocument(id) {
  try {
    return await documentService.remove(id);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function findTemplates() {
  try {
    const { data } = await documentTemplateService.find({query: { $limit: 1000 }});
    return data;
  } catch(err) {
    console.log(err);
    return [];
  } 
}

export async function patchTemplate({ data }) {
  try {
    return await documentTemplateService.patch(data.id, data);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createTemplate(template) {
  try {
    return await documentTemplateService.create(template);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function removeTemplate(id) {
  try {
    return await documentTemplateService.remove(id);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createTemplateDoc({documentId, documentTemplateId}) {
  try {
    return await templateDocService.create({documentId, documentTemplateId});
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function removeTemplateDoc({documentId, documentTemplateId}) {
  try {
    return await templateDocService.remove(
      null, 
      { query: 
        { 
          documentId, 
          documentTemplateId 
        }
      });
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function patchUserDocument({ data }) {
  try {
    return await documentService.patch(data.id, data);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createUserDocument(document) {
  debugger;
  try {
    return await documentService.create(document);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function removeUserDocument(id) {
  try {
    const sessionId = sessionStorage.tabID;
    return await documentService.remove(id, { query: { sessionId } } );
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}