import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading as HeadingBase, Body as BodyBase, Container as ContainerBase, Column } from './Shared';
import html from '../utils/html';
import generateAdditonalDetails from 'shared/generateAdditionalDetails';
import populateAdditionalDetails from 'shared/populateAdditionalDetails';

const AdditionalDetails = ({ user, loans = [], type }) => {

  const loanbaseCashback = user.meta.miniproposal?.overview?.loanbaseCashback ?? {};
  const lenderCashback = user.meta.miniproposal?.overview?.lenderCashback ?? {};

  let body;
  if (type === 'atp') {
    if (user.meta.miniproposal.atp?.detailsText) {
      body =  populateAdditionalDetails(user.meta.miniproposal.atp?.detailsText, user);
    } else {
      let detailsDefaultText = generateAdditonalDetails({ user, loans });
      body = populateAdditionalDetails(detailsDefaultText, user);
    }
  } else {
    body = user.meta.miniproposal.overview?.additionalDetails?.body ?? '';
  }

  if (!body.trim()) return null;

  return (
    <Container>

      <Column width='100%'>
        <Heading>Additional Details</Heading>
        <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body)}}/>
      </Column>

    </Container>
  );
};

export default AdditionalDetails;

const Heading = styled(HeadingBase)`
  margin-bottom: 33px;
`;

const Body = styled(BodyBase)`
    font-size: 14.8px !important;
`;

const Container = styled(ContainerBase)`
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
`;
