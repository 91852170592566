import React, { useState, Fragment } from 'react';
import { modalContext as Context } from '.';
import styled from 'styled-components';
import { ModalSubmit } from './old';

export const ModalContext = ({ children }) => {

  const [modals, setModals] = useState([]);

  const openModal = data => {
    return new Promise(resolve => {
      if (!data || !data.component) 
        throw new Error('Error in openModal: Component missing. Argument must be in the form { component, props }');
      setModals([...modals, { ...data, resolve }]);
    });
    
  };

  const closeModal = result => {
    if (!modals.length) {
      setModals([]);
      return;
    }
    modals[modals.length - 1].resolve(result);
    setModals(modals.slice(0, modals.length - 1));
  };

  const closeAllModals = () => {
    for (const modal of modals.reverse()) {
      modal.resolve();
    }
    setModals([]);
  };

  return (
    <Context.Provider value={{ modals, openModal, closeModal, closeAllModals }}>
      {children}
      <Context.Consumer>
        {({ modals }) => 
          <Fragment>
            {!!modals.length  &&
            <Background>
              {modals.map((modal, index) => {
                const Modal = modal.component;
                const isFocused = index === modals.length - 1;
                const modalProps = {
                  key: index,
                  modal: {
                    open: openModal,
                    close: closeModal,
                    closeAll: closeAllModals,
                    isFocused,
                  },
                  ...modal.props,
                };
                if (isFocused) return (
                  <Background><Modal {...modalProps} /></Background>
                );
                else return (
                  <Modal {...modalProps} />
                );
              })}
            </Background>

            }
          </Fragment>
        }
      </Context.Consumer>
    </Context.Provider>
  )
}

const Background = styled.div`
  background: rgba(33,61,88,0.45);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;