import React, { Component } from 'react';
import styled from 'styled-components';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';


import Card from 'components/Styled/Card';
import { Row, Column, PullRight } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';
import Label from 'components/Styled/Label';

import { ButtonPrimary } from 'components/Styled/Button';
import { TextInputRow, SelectInputRow, ToggleInputRow } from './SharedComponents';

import { Input as TextInput, ToggleButton, Select } from 'components/Form';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

const Heading = props => <Txt size='20px' {...props} />;

const RightColumn = styled(Column)`
  min-height: 85px;
  width: calc(100% - 230px);
  padding: 0 40px 20px 40px;
  border-right: 1px solid #D8DCE7;
`;

const FillerRow = styled(Column)`
  height: 20px;
  width: calc(100% - 230px);
  padding: 0 40px 20px 40px;
  border-right: 1px solid #D8DCE7;
`;

const LeftColumn = styled(Column)`
  min-height: 85px;
  width: 230px;
  padding: 0 40px 20px 40px;
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #D8DCE7;
  margin-bottom: ${p => p.noMargin ? '0' : '20px'};
`;



class LoanProductDetails extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Card maxWidth='1200px' minWidth='1080px' padding='0 0 20px 0' margin='20px 0 40px 0'>
          <Row>
            <RightColumn>
              <Heading marginTop='40px' marginBottom='20px'>Loan Product Details</Heading>
            </RightColumn>
            <LeftColumn>
              <Heading marginTop='40px' >Review Updates</Heading>
            </LeftColumn>
          </Row>
          <ToggleInputRow formName='product-details' name='product_active' title='Product Active' valueChecked='Y' valueUnchecked=''/>
          <RightColumn>
            <Label marginBottom='20px'>Priority Product</Label>
            <ToggleButton name='is_priority_product.raw' valueChecked={true} valueUnchecked={false}/>
          </RightColumn>
          <Row>
            <RightColumn>
              <Label marginBottom='20px'>Display Lender As</Label>
              <Select name='showLenderIdAs.raw' width='280px' options={[
                { name: 'Normal', value: ''},
                { name: 'Mystery Lender', value: 'MSL'},
                { name: 'Bank Lender', value: 'BLS'}
              ]} />
            </RightColumn>
          </Row>
          {!(this.props.isCustomProduct || {}).raw ?
            <Row>
              <RightColumn>
                <Label>Lender</Label>
                <TextInput name='bank_name.raw' width='280px' readOnly/>
              </RightColumn>
              <RightColumn>
                <Label>Lender ID</Label>
                <TextInput name='bank.raw' width='280px' readOnly/>
              </RightColumn>
            </Row>
            :
            <Row>
              <TextInputRow formName='product-details' name='bank_name' title='Lender'/>
              <TextInputRow formName='product-details' name='bank' title='Lender ID'/>
              <TextInputRow formName='product-details' name='hiddenLenderName' title='Hidden Lender'/>
              <TextInputRow formName='product-details' name='hiddenLenderId' title='Hidden Lender ID'/>
              <TextInputRow formName='product-details' name='hiddenProductName' title='Hidden Product Name'/>
            </Row>
          }
         

          <TextInputRow formName='product-details' name='product_name' title='Product Name'/>
          <TextInputRow formName='product-details' name='iir' title='Interest Rate' type='percent'/>
          <SelectInputRow formName='product-details' name='initial_ir_type' title='Product Type'>
            <option value=''>Either</option>
            <option value='V'>Variable</option>
            <option value='F'>Fixed</option>
            <option value='IV'>Intro Variable</option>
          </SelectInputRow>
          <TextInputRow formName='product-details' name='fixed_term' title='Fixed Term (months)' type='number'/>
          <TextInputRow formName='product-details' name='rr' title='Revert Rate' type='percent'/>
          {/* <TextInputRow formName='product-details' name='ongoingFees' title='Ongoing Fees' type='money'/> */}
          {/* <TextInputRow formName='product-details' name='upfrontFees' title='Upfront Fees'type='money'/> */}
          <SelectInputRow formName='product-details' name='io_repayments' title='Repayment Type - Inv'>
            <option value=''>Principle & Interest</option>
            <option value='Y'>Interest Only</option>
          </SelectInputRow>
          <SelectInputRow formName='product-details' name='io_allowed_for_own_occ' title='Repayment Type - OO'>
            <option value=''>Principle & Interest</option>
            <option value='Y'>Interest Only</option>
          </SelectInputRow>
          {/* <TextInputRow formName='product-details' name='purpose' title='Purpose'/> */}
          <Row>
            <FillerRow />
          </Row>

          <BottomBorder />
          <Row>
            <PullRight padding='0 40px 0 0'>
              <ButtonPrimary type='submit' disabled={this.props.pristine || this.props.submitting} width='150px' background='#26BD27'>
                <ButtonSpinner marginRight='15px' show={this.props.submitting}/> 
                Save
              </ButtonPrimary>
            </PullRight>
          </Row>

        </Card>
      </form>
      
    );
  }
}

const selector = formValueSelector('product-details');
export default connect(state => ({
  isCustomProduct: selector(state, 'isCustomProduct'),
}))(
  reduxForm({
    form: 'product-details',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,  
  })(LoanProductDetails)
)


