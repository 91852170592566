import React from 'react';
import styled from 'styled-components';

import TaskList from 'components/Common/TaskList';
import { CreateBox } from 'components/ApplicationsEligibility/CreateBox';
import DealHistory from 'components/ApplicationsEligibility/DealHistory';
import { ButtonPrimary } from 'components/Styled/Button';
import { getQueryVariables } from 'util/index';

import imgRefresh from 'img/icons/icon-refresh-blue.png';

const tasks = [
  {type: 'calendar', title: 'Hello this is a Title', assignedTo: 'Anna Trusova', createdBy: 'Joel Collins', dateTime: '23/02/18 3:20am'},
  {type: 'calendar', title: 'Hello this is a Title', assignedTo: 'Anna Trusova', createdBy: 'Joel Collins', dateTime: '23/02/18 3:20am'},
  {type: 'flag', title: 'Hello this is a Title', assignedTo: 'Anna Trusova', createdBy: 'Joel Collins', dateTime: '23/02/18 3:20am'},
];

const IconRefresh = styled.img.attrs({
  src: imgRefresh,
  width: 15,
})`
  position: relative;
  margin-left: 5px;
  bottom: -2px;
`;

const Heading = styled.div`
  font-family: Lato;
  font-size: 16px;
  color: #101922;
  min-width: 650px;
  max-width: 800px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 25px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  max-width: 800px;
  min-width: 650px;
  margin: 0 0 45px 0;
  border-bottom: 1px solid #D8DCE7;
  height: 80px;
`;

export const ApplicationsDashboard = ({ isEligibilty, history, location }) => {
  const { id } = getQueryVariables(location.search);
  return (  
    <div>
      {isEligibilty &&
        <TopSection>
          <ButtonPrimary 
            width='200px'
            onClick={() => history.push(`view/submission?id=${id}`)}>
            View Eligibility Submission
          </ButtonPrimary>
        </TopSection>
      }
      
      <Heading>Upcoming Tasks <IconRefresh /> </Heading>
      <TaskList tasks={tasks} />

      <Heading style={{ marginTop: '20px' }}>Create</Heading>
      <CreateBox />

      <Heading style={{ marginTop: '40px' }}>Deal History</Heading>
      <DealHistory />
    </div>
  );
};