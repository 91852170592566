import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading as HeadingBase, Body as BodyBase, Container as ContainerBase, Column } from './Shared';

export default ({ user}) => {

  const body = user.meta.miniproposal.overview?.beyondSettlement?.body 
    ?? user.globalSettings['overview_beyond_body'];

  return (
    <Container>

      <Column width='100%'>
        <Heading>Beyond Settlement</Heading>
        <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body ?? '')}}/>
      </Column>

    </Container>
  );
};

const Heading = styled(HeadingBase)`
  margin-bottom: 33px;
`;

const Body = styled(BodyBase)``;

const Container = styled(ContainerBase)`
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
`;