import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { FieldArray, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';

import { getQueryVariables } from 'util/index';

import Card from 'components/Styled/Card';
import { Row as BaseRow, Column as BaseColumn, PullRight, PullLeft } from 'components/Styled/Layout';

import { Input, Select } from 'components/Form';
import Label from 'components/Styled/Label';
import InputGroup from 'components/Common/InputGroup'; 
import BackButton from 'components/Common/BackButton';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';

import imgAdd from 'img/button/small-add-blue.png';
import imgSearch from 'img/button/search-blue.png';
import imgTrash from 'img/button/trash-red.png';


const Row = styled(BaseRow)`
  padding: 30px 0 0 40px;
`;

const Column = styled(BaseColumn)`
  padding: 0 40px 0 0;
`;

const ImgSearch = styled.img.attrs({
  src: imgSearch,
  width: 14,
})`
  position: relative;
  top: 2px;
  margin-right: 10px;

`;

const ImgAdd = styled.img.attrs({
  src: imgAdd,
  width: 10,
})`
  position: relative;
  margin-right: 10px;
`;

const ImgTrash = styled.img.attrs({
  src: imgTrash,
  width: 16,
})`
  position: relative;
  top: 2px;
  right: 4px;
`;

class ApplicantsForm extends Component {
  
  render() {
    const { id } = getQueryVariables(this.props.location.search);
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <input hidden autoComplete="off" />
        <FieldArray name='applicants' component={Applicants} id={id}/>
        <BaseRow style={{width:'800px', margin: '-20px 0 0 0'}}>
          <PullLeft>
            <ButtonPlain type='button' width='100px' onClick={() => this.props.history.goBack()}>Cancel</ButtonPlain>
          </PullLeft>

          <PullRight>
            <ButtonPrimary type='submit' width='150px' >Save</ButtonPrimary>
          </PullRight>
        </BaseRow>
      </form>
    );
  }
}

const relationshipOptions = [
  {name: 'Relationship to Applicant 1', value: ''},
  {name: 'Co-Applicant (Spouse)', value: 'spouse'},
  {name: 'Co-Applicant (Other)', value: 'other'},
  {name: 'Guarantor (Security)', value: 'security'},
  {name: 'Guarantor (Serviceability)', value: 'serviceability'},
];

const phoneOptions = [
  {name: 'Mobile', value: 'mobile'},
  {name: 'Landline', value: 'landline'},
];



const ApplicantsBase = ({fields, id, history}) => {

  return (
    <Fragment>
      <BaseRow style={{width:'800px'}}>
        <PullLeft>
          <BackButton margin='15px 0 0 0'/>
        </PullLeft>

        <PullRight>

          <ButtonPlain type='button' style={{margin: '0 0 20px 20px'}} onClick={() => history.push('applicants/search?id=' + id)}>
            <ImgSearch/>
            Search Existing Contacts
          </ButtonPlain>

          <ButtonPlain type='button' onClick={fields.push} style={{margin: '0 0 20px 20px'}}>
            <ImgAdd/>
            Add another Applicant
          </ButtonPlain>
        </PullRight>

      </BaseRow>
      {fields.map((applicant, index) => 
        <ApplicantCard key={index} index={index} applicant={applicant} fields={fields}/>)
      }
    </Fragment>
  );
};

const Applicants = withRouter(ApplicantsBase);

const ApplicantCard = ({ index, applicant, fields }) => (
  <Card padding='10px 0 40px 0' margin='0 0 40px 0' width='800px'>
    <Row>
      <PullLeft>
        <Heading>Applicant {index + 1}</Heading>
      </PullLeft>
      {index > 0 &&
        <PullRight>
          <Select 
            name={`${applicant}.relationship`}
            fontSize={12}
            height='30px'
            width='250px' 
            options={relationshipOptions}
          />

          <ButtonPlain
            onClick={() => fields.remove(index)} 
            width='30px' 
            height='30px' 
            style={{margin: '-1px 40px 0 10px'}}
          ><ImgTrash/></ButtonPlain>
        </PullRight>
      }
    </Row>
    <Row>
      <Column width='50%'>
        <Label>First Name</Label>
        <Input name={`${applicant}.firstName`} />
      </Column>
      <Column width='50%'>
        <Label>Last Name</Label>
        <Input name={`${applicant}.lastName`}/>
      </Column>
    </Row>
    <Row>
      <Column width='50%'>
        <Label>Primary Phone</Label>
        <InputGroup>
          <Input width='65%' name={`${applicant}.phoneNumber`} />
          <Select width='35%' name={`${applicant}.phoneNumberType`} options={phoneOptions}/>
        </InputGroup>
      </Column>
      <Column width='50%'>
        <Label>Secondary Phone</Label>
        <InputGroup>
          <Input width='65%' name={`${applicant}.secondaryPhoneNumber`} />
          <Select width='35%' name={`${applicant}.secondaryPhoneNumberType`} options={phoneOptions}/>
        </InputGroup>
      </Column>
    </Row>
    <Row>
      <Column width='50%'>
        <Label>Email</Label>
        <Input name={`${applicant}.email`} />
      </Column>
      <Column width='50%'>
        <Label>Address</Label>
        <Input name={`${applicant}.address`} />
      </Column>
    </Row>
  </Card>
);

const Heading = styled.h2`
  font-size: 20px;
`;

ApplicantsForm = reduxForm({
  form: 'applicants',
  enableReinitialize: true,
})(ApplicantsForm);


export default withRouter(ApplicantsForm);
