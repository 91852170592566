// src/components/SideBar/SideBar.js

import React from 'react';
import { NavLink } from "react-router-dom";
import { showUnfinished } from 'modules';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const user = useSelector(state => state.user);

  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li className="menu-item-9"><NavLink activeClassName="current" to="/deals">Current Deals</NavLink></li>
          {user.userType !== 'processor' && <>
            <li className="menu-item-11"><NavLink activeClassName="current" to="/in-progress">In Progress</NavLink></li>
            <li className="menu-item-10"><NavLink activeClassName="current" to="/new-leads">New Leads</NavLink></li>
            <li className="menu-item-8"><NavLink activeClassName="current" to="/referrals">Referrals</NavLink></li>
            <li className="menu-item-4"><NavLink activeClassName="current" to="/qualifiers">Qualifiers</NavLink></li>
            {showUnfinished && <li className="menu-item-1"><NavLink activeClassName="current" exact to="/newsfeed">News Feed</NavLink></li>}
            <li className="menu-item-2"><NavLink activeClassName="current" to="/clients">Clients</NavLink></li>
            {showUnfinished && <li className="menu-item-3"><NavLink activeClassName="current" to="/applications">Applications</NavLink></li>}
            <li className="menu-item-5"><NavLink activeClassName="current" to="/lenders">Lenders</NavLink></li>
            <li className="menu-item-6"><NavLink activeClassName="current" to="/products">Loan Products</NavLink></li>
            <li className="menu-item-7"><NavLink activeClassName="current" to="/settings">Settings</NavLink></li>
            <li className="menu-item-2"><NavLink activeClassName="current" to="/team">Team</NavLink></li>
          </>}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
