import React, { Fragment, useEffect } from 'react';

import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'; 
import { Row, PullRight, PullLeft } from 'components/Styled/Layout';
import { Hide, FormCard, HeadingLarge, SelectField, TextField, MoneyField, FormGroup, SaveButton, MoneyAndSelectField } from '../Components';
import initializeFormValues from 'util/initializeFormValues';


const FORM_NAME = 'submission-expenses';

const IncomeFormPure = ({ handleSubmit, submitting, pristine, otherLivingExp, expenses, ownHome, propertyPurpose, housingType, application }) => { 
  
  useEffect(() => {
    initializeFormValues(FORM_NAME, values => ({ ...values, application }));
  }, []);
  return (
    <FormCard onSubmit={handleSubmit}>
      
      <Hide when={ownHome !== 'No' || propertyPurpose !== 'purposeInvestment'}>
        <FormGroup>
          <HeadingLarge>Housing Expenses</HeadingLarge>
          <Row>
            <SelectField name='client.meta.housingType' label='Living Situation' options={[
              {name: 'None Selected', value: ''},
              {name: 'Renting', value: 'Renting'},
              {name: 'Boarding', value: 'Boarding'},
              {name: 'Living With Family (rent-free)', value: 'Living With Family (rent-free)'},
              {name: 'Other', value: 'Other'},
            ]} />
            <Hide when={housingType !== 'Renting' && housingType !== 'Boarding'}>
              <MoneyAndSelectField name='client.meta.housingAmount' label='Expenses' options={timeOptions}/>
            </Hide>
            <Hide when={housingType !== 'Other'}>
              <TextField name='client.meta.housingOther' label='Other?' />
            </Hide>
          </Row>
        </FormGroup>
      </Hide>
      <FormGroup>
        <Row>
          <SelectField name='client.meta.otherLivingExp' label='Fill out a detailed budget?' options={yesNoOptions} width='60px'/>
        </Row>
      </FormGroup>
      <Hide when={otherLivingExp !== 'No'}>
        <Row>
          <MoneyField name='client.meta.basicTotalMonthlyExp' label='Estimated Monthly Expenses' />
        </Row>
      </Hide>
      <Hide when={otherLivingExp !== 'Yes'}>
        <FormGroup> 
          <HeadingLarge>Lifestyle</HeadingLarge>
          <Row>
            <MoneyAndSelectField name='client.meta.foodGroceriesExp' label='Food & Groceries' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.phoneInternetPayExp' label='Phone, Internet, Pay TV' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.entertainmentExp' label='Entertainment' options={timeOptions}/>
          </Row>
        </FormGroup>
        <FormGroup>
          <HeadingLarge>Transport</HeadingLarge>
          <Row>
            <MoneyAndSelectField name='client.meta.vehicleRegistrationExp' label='Vehicle Registration, Insurance etc.' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.motorVehiclePetrolExp' label='Motor Vehicle Petrol, Tolls, Parking etc.' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.publicTransportExp' label='Public Transport, Uber, Taxi etc.' options={timeOptions}/>
          </Row>
        </FormGroup>
        <FormGroup>
          <HeadingLarge>Insurance</HeadingLarge>
          <Row>
            <MoneyAndSelectField name='client.meta.healthOtherExp' label='Health & Other Personal' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.homeContentsLandlordExp' label='Home / Contents / Landlord' options={timeOptions}/>
          </Row>
        </FormGroup>
        <FormGroup>
          <HeadingLarge>Property</HeadingLarge>
          <Row>
            <MoneyAndSelectField name='client.meta.councilRatesExp' label='Council & Strata Rates' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.utilitiesExp' label='Utilities (Electricity, Gas, Water)' options={timeOptions}/>
          </Row>
        </FormGroup>
        <FormGroup>
          <HeadingLarge>Miscellaneous</HeadingLarge>
          <Row>
            <MoneyAndSelectField name='client.meta.childcareMaintenanceExp' label='Childcare, Maintenance' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.educationExp' label='Education' options={timeOptions}/>
            <MoneyAndSelectField name='client.meta.otherExp' label='Other' options={timeOptions}/>
          </Row>
        </FormGroup>
      </Hide>
      
      <Row padding='0 0 20px 0'>
        <PullLeft>
          <HeadingLarge>Total Monthly Expenses</HeadingLarge>
        </PullLeft>
        <PullRight>
          <HeadingLarge>${expenses}</HeadingLarge>
        </PullRight>
      </Row>
      <SaveButton disabled={(submitting || pristine)} submitting={submitting}/>
    </FormCard>
  );
};

const select = formValueSelector(FORM_NAME);
const stateToProps = state => {
  const propertyPurpose = state.clients.current.applications[0].meta?.propertyPurpose;
  const ownHome = state.clients.current.meta.ownHome;
  const housingType = select(state, 'client.meta.housingType');
  const housingAmount = select(state, 'client.meta.housingAmount') || 0;
  const housingAmountType = select(state, 'client.meta.housingAmountType') || 1;
  const housingOther = select(state, 'client.meta.housingOther');
  const basicTotalMonthlyExp = select(state, 'client.meta.basicTotalMonthlyExp');

  // internal values
  const MONTH = 1/12;

  const foodGroceriesExp = select(state, 'client.meta.foodGroceriesExp') || 0;
  const foodGroceriesExpPeriod = select(state, 'client.meta.foodGroceriesExpType') || MONTH;
  const phoneInternetPayExp = select(state, 'client.meta.phoneInternetPayExp') || 0;
  const phoneInternetPayExpPeriod = select(state, 'client.meta.phoneInternetPayExpType') || MONTH;
  const entertainmentExp = select(state, 'client.meta.entertainmentExp') || 0;
  const entertainmentExpPeriod = select(state, 'client.meta.entertainmentExpType') || MONTH;
  const vehicleRegistrationExp = select(state, 'client.meta.vehicleRegistrationExp') || 0;
  const vehicleRegistrationExpPeriod = select(state, 'client.meta.vehicleRegistrationExpType') || MONTH;
  const motorVehiclePetrolExp = select(state, 'client.meta.motorVehiclePetrolExp') || 0;
  const motorVehiclePetrolExpPeriod = select(state, 'client.meta.motorVehiclePetrolExpType') || MONTH;
  const publicTransportExp = select(state, 'client.meta.publicTransportExp') || 0;
  const publicTransportExpPeriod = select(state, 'client.meta.publicTransportExpType') || MONTH;
  const healthOtherExp = select(state, 'client.meta.healthOtherExp') || 0;
  const healthOtherExpPeriod = select(state, 'client.meta.healthOtherExpType') || MONTH;
  const homeContentsLandlordExp = select(state, 'client.meta.homeContentsLandlordExp') || 0;
  const homeContentsLandlordExpPeriod = select(state, 'client.meta.homeContentsLandlordExpType') || MONTH;
  const councilRatesExp = select(state, 'client.meta.councilRatesExp') || 0;
  const councilRatesExpPeriod = select(state, 'client.meta.councilRatesExpType') || MONTH;
  const utilitiesExp  = select(state, 'client.meta.utilitiesExp') || 0;
  const utilitiesExpPeriod  = select(state, 'client.meta.utilitiesExpType') || MONTH;
  const childcareMaintenanceExp = select(state, 'client.meta.childcareMaintenanceExp') || 0;
  const childcareMaintenanceExpPeriod = select(state, 'client.meta.childcareMaintenanceExpType') || MONTH;
  const educationExp = select(state, 'client.meta.educationExp') || 0;
  const educationExpPeriod = select(state, 'client.meta.educationExpType') || MONTH;
  const otherExp = select(state, 'client.meta.otherExp') || 0;
  const otherExpPeriod = select(state, 'client.meta.otherExpType') || MONTH;

  const yearlyExpenses = 
    housingAmount / parseFloat(housingAmountType) +
    foodGroceriesExp / parseFloat(foodGroceriesExpPeriod) +
    phoneInternetPayExp / parseFloat(phoneInternetPayExpPeriod) + 
    entertainmentExp / parseFloat(entertainmentExpPeriod) +
    vehicleRegistrationExp / parseFloat(vehicleRegistrationExpPeriod) + 
    motorVehiclePetrolExp / parseFloat(motorVehiclePetrolExpPeriod) + 
    publicTransportExp / parseFloat(publicTransportExpPeriod) + 
    healthOtherExp / parseFloat(healthOtherExpPeriod) + 
    homeContentsLandlordExp / parseFloat(homeContentsLandlordExpPeriod) + 
    councilRatesExp / parseFloat(councilRatesExpPeriod) + 
    utilitiesExp / parseFloat(utilitiesExpPeriod) +
    childcareMaintenanceExp / parseFloat(childcareMaintenanceExpPeriod) + 
    educationExp / parseFloat(educationExpPeriod) + 
    otherExp / parseFloat(otherExpPeriod);

  const otherLivingExp = select(state, 'client.meta.otherLivingExp');

  const expenses = (otherLivingExp === 'Yes') ? (yearlyExpenses / 12).toFixed(0) : basicTotalMonthlyExp;

  return {
    propertyPurpose,
    ownHome,
    otherLivingExp,
    housingType,
    expenses,
    housingAmount,
    housingOther,
    basicTotalMonthlyExp,
  };
};

const IncomeForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(IncomeFormPure);

export default connect(stateToProps)(IncomeForm);

const timePeriod = {
  YEAR: 1,
  MONTH: 1/12,
  WEEK: 1/365.25*7,
  FORTNIGHT: 1/365.25*14,
};

const timeOptions = [
  {name: 'Monthly', value: timePeriod.MONTH},
  {name: 'Fortnightly', value: timePeriod.FORTNIGHT},
  {name: 'Weekly', value: timePeriod.WEEK},
  {name: 'Annually', value: timePeriod.YEAR},
];

const yesNoOptions = [
  {name: 'Yes', value: 'Yes'},
  {name: 'No', value: 'No'},
];