import React from 'react';
import { FieldArray } from 'redux-form';
import styled, { css } from 'styled-components';
import { Input, NumberInput } from 'components/Form';
import { DeleteButton, Button } from './Shared';

const ListItems = ({ fields }) => {

  const newObjective = () => {
    const max = fields.getAll()
      .map(x => x.order)
      .reduce((a, b) => Math.max(a, b), 0);
    const order = (Math.floor(max / 10) + 1) * 10;
    fields.push({ order });
  };

  const remove = index => {
    fields.remove(index);
  };

  return (
    <Container>
      {!!(fields || []).length && 
        <ul>
          
          <li key='header'>
            <Column width='5%'>
              <Label>Order</Label>
            </Column>
            <Column width='20%'>
              <Label>Name</Label>
            </Column>
            <Column width='70%'>
              <Label>Text</Label>
            </Column>
          </li>

          {(fields || []).map((item, index) => {
            return (
              <li key={index}>
                <Column width='5%'>
                  <NumberInput name={`${item}.order`}/>
                </Column>
                <Column width='20%'>
                  <Input name={`${item}.name`}/>
                </Column>
                <Column width='calc(75% - 40px)'>
                  <Input name={`${item}.text`}/>
                </Column>
                <Column width='40px' style={{paddingRight: '0'}}>
                  <DeleteButton onDelete={() => remove(index)} id={index} type='objectives'/>
                </Column>
              </li>
            );
          })}
        </ul>
      }
      <Column width='200px' style={{ marginTop: '10px'}}>
        <Button color='#2291FF' onClick={newObjective}>
          Add Objective
        </Button>
      </Column>
      
    </Container>
  );
};

const ObjectiveList = props => {
  return (
    <FieldArray {...props} component={ListItems}/>
  );
};

export default ObjectiveList;

const Container = styled.div``;

const Label = styled.label`
  display: block;
`;

const Column = styled.div`
  display: inline-block;
  width: ${p => p.width};
  padding-right: 10px;
  margin-bottom: 5px;
  vertical-align: bottom;
`;
