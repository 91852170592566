import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import Txt from 'components/Styled/Text';
import Img from 'react-image';
import { logoUrl } from 'modules';
import styled from 'styled-components';
// import { ToolTipContainer, ToolTip } from 'components/Styled/Tooltip';
import imgReferrerClientActive from 'img/icons/referrer-client-active.svg';
import imgReferrerProfessionalActive from 'img/icons/referrer-professional-active.svg';
import Tooltips from 'components/Common/Tooltips';

import iconBrokerSquare from 'img/icons/broker-square.svg';
import iconPreapproval from 'img/icons/deal-preapproval.svg';
import iconRefinance from 'img/icons/deal-refinance.svg';
import iconPurchase from 'img/icons/deal-purchase.svg';
import iconConstruction from 'img/icons/deal-construction.svg';
import imgImportant from 'img/elements/arrow-important.svg';
import imgImportantOrange from 'img/elements/arrow-important-orange.svg';
import imgImportantGreen from 'img/elements/arrow-important-green.svg';
import imgStatusBlue from 'img/icons/status-circle-blue.svg';
import imgStatusGreen from 'img/icons/status-circle-green.svg';
import imgStatusGrey from 'img/icons/status-circle-grey.svg';
import imgClockGreen from 'img/icons/clock-green.svg';
import imgClockOrange from 'img/icons/clock-orange.svg';
import imgWarning from 'img/icons/alert-red.svg';
import imgTickWhite from 'img/elements/tick-white.svg';
import imgMobileVerifiedRed from 'img/icons/mobile-verified-red.svg';
import imgIncomeBracket0 from 'img/icons/income-bracket-0.svg';
import imgIncomeBracket1 from 'img/icons/income-bracket-1.svg';
import imgIncomeBracket2 from 'img/icons/income-bracket-2.svg';
import imgIncomeBracket3 from 'img/icons/income-bracket-3.svg';
import imgIncomeBracket4 from 'img/icons/income-bracket-4.svg';

import moment from 'moment';



class ApplicationCardContentBase extends Component {
  constructor() {
    super();
    this.state = {
      menuIsOpen: false,
      menuX: 0,
      menuY: 0,
    };
  }

  render() {
    const { data, page } = this.props;
    let logoSrc;
    const mainLenderId = data.lenderId || data.products?.[0]?.bank;
    try {
      logoSrc = `${logoUrl}/${mainLenderId}.png`
    } catch(e) {}

    let altLogoSrc = '';
    try {
      altLogoSrc = require(`img/banks/${mainLenderId}.png`)
    } catch(e) {}


    //console.log('application data:', data);
    // let extraBanksText = '';
    // if (data.products && data.products.length > 1 && !data.lenderId) {
    //   extraBanksText = '+ ' + data.products[1].bank_name;
    //   if (data.products.length > 2)
    //     extraBanksText += ', ' + data.products[2].bank_name;
    // }

    //if (!data.client) return null;

    let brokerInitials;
    let processorInitials;
    if (data.brokerId) {
      const names = data.broker_name.split(' ');
      brokerInitials = names[0].charAt(0) + names[1].charAt(0);
    }
    if (data.processorId) {
      const names = data.processor_name.split(' ');
      processorInitials = names[0].charAt(0) + names[1].charAt(0);
    }
    const daysDue = data.dueDate && moment(data.dueDate).startOf('day').diff(moment().startOf('day'), 'days');
    const taskDaysDue = data.currentTaskDueDate && moment(data.currentTaskDueDate).startOf('day').diff(moment().startOf('day'), 'days');

    let titleWidth = 220;
    if (data.brokerId) titleWidth -= 30;
    if (data.processorId) titleWidth -= 30;

    let hasDocuments = false;
    let pendingDocuments = []; 
    let pendingDocumentCount = 0;
    if (data.client?.documents?.length) {
      hasDocuments = true;
      for (const document of data.client.documents) {
        let pendingApplicants = 0;
        if (document.isPropertyDoc) {
          pendingApplicants = document.meta.approved[0] ? 0 : 1;
        } else {
          const { numberOfApplicants } = data.client;
          for (let i = 0; i < numberOfApplicants; i++) {
            if (document.meta.applicants[i] && !document.meta.approved[i]) {
              pendingApplicants++;
            }
          }
        }
        if (pendingApplicants > 0) {
          pendingDocumentCount += pendingApplicants;
          pendingDocuments.push((pendingApplicants === 1) ? document.name : `${document.name} x ${pendingApplicants}`);
        }
      }
    }

    let tooManyDocs = false;
    if (pendingDocumentCount > 3) {
      tooManyDocs = true;
      pendingDocuments = pendingDocuments.slice(0, 3);
    }

    if (page !== 'qualifiers' && page !== 'referrals' && page !== 'deals') {
      let LVR;
      if (data.loanAmount && data.propertyValue) {
        LVR = Math.floor((data.loanAmount / data.propertyValue) * 100);
      }

      return (
        <>
          <div className='task-description'>
            <div className='client' style={{ width: titleWidth + 'px'}}>{data.user_name}</div>
            <div className='broker'>
              {!!data.dealType &&
                <DealIcon type={data.dealType} />
              }
              {data.dealDescription || ''}
            </div>
          </div>
          {data.brokerId && data.broker_name &&
            <BrokerCircle>
              {brokerInitials}
              <TooltipContent>
                <div><i>Assigned Broker</i></div>
                <div><b>{data.broker_name}</b></div>
              </TooltipContent>
            </BrokerCircle>
          }

          {data.processorId && data.processor_name &&
            <ProcessorCircle style={data.brokerId ? { right: '40px' } : { right: '10px'}}>
              {processorInitials}
              <TooltipContent>
                <div><i>Assigned Processor</i></div>
                <div><b>{data.processor_name}</b></div>
              </TooltipContent>
            </ProcessorCircle>
          }
            
          <DealLVR>
            {data.showLVR !== false && !!LVR && `LVR: ${LVR}%` }
          </DealLVR>
          <div className='task-money' style={{ fontSize: '14px'}}>
            {toMoneyString(data.loanAmount || 0)}
          </div>

          {data.priority === 'low' &&
            <PriorityLow />
          }
          {data.priority === 'medium' &&
            <PriorityMedium />
          }
          {data.priority === 'high' &&
            <PriorityHigh />
          }
          
          {data.dueDate && daysDue > 30 &&
          <ArrowContainer>
            <WarningArrowGreen/> 
            <WarningDateGreen>{moment(data.dueDate).format('D MMMM')}</WarningDateGreen>
            <TooltipContent>
              <div><i>{data.dueDateDescription || 'Due Date'}</i></div>
              <div><b>{moment(data.dueDate).format('D MMMM YYYY')}</b></div>
            </TooltipContent>
          </ArrowContainer>
          }
          {data.dueDate && daysDue <= 30 && daysDue > 14 &&
          <ArrowContainer>
            <WarningArrowOrange/> 
            <WarningDateOrange>{moment(data.dueDate).format('D MMMM')}</WarningDateOrange>
            <TooltipContent>
              <div><i>{data.dueDateDescription || 'Due Date'}</i></div>
              <div><b>{moment(data.dueDate).format('D MMMM YYYY')}</b></div>
            </TooltipContent>
          </ArrowContainer>
          }
          {data.dueDate && daysDue <= 14 &&
          <ArrowContainer>
            <WarningArrowRed/>
            <WarningDateRed>{moment(data.dueDate).format('D MMMM')}</WarningDateRed>
            <TooltipContent>
              <div><i>{data.dueDateDescription || 'Due Date'}</i></div>
              <div><b>{moment(data.dueDate).format('D MMMM YYYY')}</b></div>
            </TooltipContent>
          </ArrowContainer>
          }
          
          <PropertyCount>{getPropertyCount(data.client?.additionalProperties)}</PropertyCount>
                        
          <MiddleLeftContainer>
            {data.currentTaskDueDate &&
            <TaskDueDateContainer hasReferrer={!!data.referrer}>
              {taskDaysDue > 1 &&
                <TaskDueDate color='#26BD27'><img src={imgClockGreen} />{moment(data.currentTaskDueDate).format('D MMMM')}</TaskDueDate>
              }
              {taskDaysDue === 1 &&
                <TaskDueDate color='#F5A623'><img src={imgClockOrange} />Tomorrow</TaskDueDate>
              }
              {taskDaysDue === 0  &&
                <TaskDueDate color='#FF4E4C'><img src={imgWarning} />Today</TaskDueDate>
              }
              {taskDaysDue === -1 &&
                <TaskDueDate color='#FF4E4C'><img src={imgWarning} />Yesterday</TaskDueDate>
              }
              {taskDaysDue < -1  &&
                <TaskDueDate color='#FF4E4C'><img src={imgWarning} />{moment(data.currentTaskDueDate).format('D MMMM')}</TaskDueDate>
              }
              <TooltipContent>
                <div><i>{data.currentTaskDescription || 'Deal Task'}</i></div>
                <div><b>{moment(data.currentTaskDueDate).format('D MMMM YYYY')}</b></div>
              </TooltipContent>
            </TaskDueDateContainer>
            } 
            
            <IncomeBracket src={incomeBracketImgs[data.client?.incomeBracket ?? 0]} />

            {!data.user_isMobileVerified &&
              <img style={{ marginLeft: '8px'}} src={imgMobileVerifiedRed}/>
            }
            
          </MiddleLeftContainer>
        
        </>
      );
    } else {
      return (
        <>
          <div className='task-description'>
            <div className='client' style={{ width: titleWidth + 'px'}}>{data.user_name}</div>
            <div className='broker'>
              {!!data.dealType &&
                <DealIcon type={data.dealType} />
              }
              {data.dealDescription || ''}
            </div>
            {/* <div className='broker'><BrokerSquare /> {data.broker && data.broker.name ? data.broker.name : <Txt as='span' color='red'>None Set</Txt>}</div> */}
          </div>
          <div className='task-logo'>
            {(!!(data.products && data.products[0]) || !!data.lenderId) &&
              <Fragment>
                <Img className="img-logo" src={[logoSrc, altLogoSrc]} alt="logo" unloader={<span>{data.products?.[0]?.bank_name ?? data.lenderId}</span>}/>
                {/* <Txt noOverflow width='135px' size='12px' marginBottom='5px'> {extraBanksText}</Txt> */}
              </Fragment>
            }
          </div>
          {data.brokerId && data.broker_name &&
            <BrokerCircle>
              {brokerInitials}
              <TooltipContent>
                <div><i>Assigned Broker</i></div>
                <div><b>{data.broker_name}</b></div>
              </TooltipContent>
            </BrokerCircle>
          }

          {data.processorId && data.processor_name &&
            <ProcessorCircle style={data.brokerId ? { right: '40px' } : { right: '10px'}}>
              {processorInitials}
              <TooltipContent>
                <div><i>Assigned Processor</i></div>
                <div><b>{data.processor_name}</b></div>
              </TooltipContent>
            </ProcessorCircle>
          }
            
          <DealOffset>
            {!!data.offsetAmount && '-' + toMoneyString(data.offsetAmount) }
          </DealOffset>
          <div className='task-money' style={{ fontSize: '14px'}}>
            {toMoneyString(data.loanAmount || 0)}
          </div>
          
          {data.dueDate && daysDue > 30 &&
          <ArrowContainer>
            <WarningArrowGreen/> 
            <WarningDateGreen>{moment(data.dueDate).format('D MMMM')}</WarningDateGreen>
            <TooltipContent>
              <div><i>{data.dueDateDescription || 'Due Date'}</i></div>
              <div><b>{moment(data.dueDate).format('D MMMM YYYY')}</b></div>
            </TooltipContent>
          </ArrowContainer>
          }
          {data.dueDate && daysDue <= 30 && daysDue > 14 &&
          <ArrowContainer>
            <WarningArrowOrange/> 
            <WarningDateOrange>{moment(data.dueDate).format('D MMMM')}</WarningDateOrange>
            <TooltipContent>
              <div><i>{data.dueDateDescription || 'Due Date'}</i></div>
              <div><b>{moment(data.dueDate).format('D MMMM YYYY')}</b></div>
            </TooltipContent>
          </ArrowContainer>
          }
          {data.dueDate && daysDue <= 14 &&
          <ArrowContainer>
            <WarningArrowRed/>
            <WarningDateRed>{moment(data.dueDate).format('D MMMM')}</WarningDateRed>
            <TooltipContent>
              <div><i>{data.dueDateDescription || 'Due Date'}</i></div>
              <div><b>{moment(data.dueDate).format('D MMMM YYYY')}</b></div>
            </TooltipContent>
          </ArrowContainer>
          }
          
          
          <MiddleLeftContainer>
            <DischargeStatusContainer hasReferrer={!!data.referrer}>
              {data.isFastRefi && <>
                <img src={imgStatusGreen}/>
                <TooltipContent>
                  <div>Fast refi</div>
                </TooltipContent>
              </>}
              {!data.isFastRefi && 
              (data.dischargeSent ?
                <>  
                  <img src={imgStatusBlue}/>
                  <TooltipContent>
                    <div>Discharge sent on</div>
                    <div><b>{moment(data.dischargeSentDate).format('D MMMM YYYY')}</b></div>
                  </TooltipContent>
                </> 
                :
                <>  
                  <img src={imgStatusGrey}/>
                  <TooltipContent>
                    <div>Discharge not yet sent</div>
                  </TooltipContent>
                </>)
              }
            </DischargeStatusContainer>
            {data.currentTaskDueDate &&
            <TaskDueDateContainer hasReferrer={!!data.referrer}>
              {taskDaysDue > 1 &&
                <TaskDueDate color='#26BD27'><img src={imgClockGreen} />{moment(data.currentTaskDueDate).format('D MMMM')}</TaskDueDate>
              }
              {taskDaysDue === 1 &&
                <TaskDueDate color='#F5A623'><img src={imgClockOrange} />Tomorrow</TaskDueDate>
              }
              {taskDaysDue === 0  &&
                <TaskDueDate color='#FF4E4C'><img src={imgWarning} />Today</TaskDueDate>
              }
              {taskDaysDue === -1  &&
                <TaskDueDate color='#FF4E4C'><img src={imgWarning} />Yesterday</TaskDueDate>
              }
              {taskDaysDue < -1  &&
                <TaskDueDate color='#FF4E4C'><img src={imgWarning} />{moment(data.currentTaskDueDate).format('D MMMM')}</TaskDueDate>
              }
              <TooltipContent>
                <div><i>{data.currentTaskDescription || 'Deal Task'}</i></div>
                <div><b>{moment(data.currentTaskDueDate).format('D MMMM YYYY')}</b></div>
              </TooltipContent>
            </TaskDueDateContainer>
            } 
            {data.referrer_name &&
              <ReferrerIcon 
                name={data.referrer_name}
                isProfessional={!!data.referrer_isProfessional}
              />
            }
            
          </MiddleLeftContainer>
          {hasDocuments && (pendingDocumentCount ?
            <DocumentCount>
              {pendingDocumentCount}
              <TooltipContent>
                {pendingDocuments.map((doc, index) => 
                  <div key={index}>{index + 1}. {doc}</div>
                )}
                {tooManyDocs &&
                  <div>+ {pendingDocumentCount - 3} more</div>
                }
              </TooltipContent>
            </DocumentCount>
            :
            <DocumentsComplete>
              <img src={imgTickWhite}/>
              <TooltipContent>
                All requested documents have been provided.
              </TooltipContent>
            </DocumentsComplete>
          )}
        </>
      );
    }
  }
}



export const ApplicationCardContent = withRouter(ApplicationCardContentBase);

const PropertyCount = styled.div`
  position: absolute;
  bottom: 11px;
  right: 73px;
  
  width: 26px;
  height: 26px;
  background-image: url(${require('img/icons/house-blue.svg')});
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
`;


const IncomeBracket = styled.img`
  position: relative;
  top: -10px;
  margin-left: 8px;
`;

const PriorityTag = styled.div`
  position: absolute;
  top: 0;
  left: 132px;
  width: 36px;
  height: 7px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const PriorityHigh = styled(PriorityTag)`
  background: #26BD27;
`;

const PriorityMedium = styled(PriorityTag)`
  background: #F6B900
`;

const PriorityLow = styled(PriorityTag)`
  background: #FF4E4C
`;



const MiddleLeftContainer = styled.div`
  position: absolute;
  bottom: 45px;
  right: 10px;
`;

const WarningDate = styled.span`
  position: relative;
  top: -4px;
  margin-left: 3px;
  font-size: 12px;
  font-weight: bold;
`;

const WarningDateOrange = styled(WarningDate)`
  color: #F5A623;
`;

const WarningDateRed = styled(WarningDate)`
  color: #FF4E4C;
`;

const WarningDateGreen = styled(WarningDate)`
  color: #26BD27;
`;


const DealOffset = styled.div`
  position: absolute;
  right: 10px;
  bottom: 27px;
  color: #FF807F;
  font-size: 12px;
  text-align: right;
`;

const DealLVR = styled(DealOffset)`
  color: #4A90E2;
  font-weight: bold;
  bottom: 30px;
`;

const DealImg = styled.img`
  position: relative;
  top: 1px;
  margin-right: 4px;
`;

const DealIcon = ({type}) => {
  let src = iconBrokerSquare;
  
  if (type === 'Pre-approval') src = iconPreapproval;
  if (type === 'Refinance') src = iconRefinance;
  if (type === 'Purchase') src = iconPurchase;
  if (type === 'Construction') src = iconConstruction;

  return <DealImg src={src} />;
};
const ClientReferrerImg = styled.img.attrs({
  src: imgReferrerClientActive,
})`
  width: 24px;
  height: 22px;
`;

const ProfessionalReferrerImg = styled.img.attrs({
  src: imgReferrerProfessionalActive,
})`
  width: 24px;
  height: 22px;
`;

const WarningArrowRed = styled.img.attrs({
  src: imgImportant,
})`
  width: 16px;
  height: 16px;
`;

const WarningArrowOrange = styled(WarningArrowRed).attrs({
  src: imgImportantOrange,
})``;

const WarningArrowGreen = styled(WarningArrowRed).attrs({
  src: imgImportantGreen,
})``;

const TooltipContent = styled.span`
  visibility: hidden;
  background-color: #28384A;
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 140%;
  left: 50%;
  transform: translate(-50%, 0);
  /* margin-left: -60px; Use half of the width (120/2 = 60), to center the tooltip */
  z-index: 1;
  line-height: 150%;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #28384A transparent transparent transparent;
  }
`;  

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  overflow: visible;
  margin-left: 10px;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
    }
  }
`;

const ArrowContainer = styled.div`
  display: inline-block;
  overflow: visible;

  padding: 20px 10px 30px 10px;

  position: absolute;
  left: -13px;
  top: 29px;
  min-width: 100px;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
      bottom: 55px;
    }
  }
`;

const BrokerCircle = styled.div`

  display: inline-block;
  overflow: visible;

  position: absolute;
  right: 10px;
  top: 10px;

  text-align: center;
  line-height: 26px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #2D91F9;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
    }
  }
`;

const ProcessorCircle = styled(BrokerCircle)`
  /* right: 40px;
  top: 10px; */
  background-color: #5CB943;
`;

const TaskDueDateContainer = styled.div`
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -5px;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
    }
  }
`;

const TaskDueDate = styled.div`
  color: ${p => p.color};
  font-size: 12px;
  text-align: right;
  font-weight: bold;

  img {
    position: relative;
    top: 3px;
    margin-right: 3px;
    width: 15px;
    height: 15px;
  }
`;

const DocumentCount = styled.div`

  display: inline-block;
  overflow: visible;

  position: absolute;
  right: 65px;
  bottom: 10px;

  text-align: center;
  line-height: 18px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  width: 26px;
  height: 18px;
  border-radius: 20px;
  background-color: #2D91F9;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
      text-align: left;
    }
  }
`;

const DocumentsComplete = styled(DocumentCount)`
  background-color: #26BD27;
  img {
    position: relative;
    top: 2px;
  }
`;

const DischargeStatusContainer = styled(TaskDueDateContainer)`
top: -2px;
`;

const ReferrerIcon = ({ name, isProfessional }) => {
  return <>
    <Tooltip>
      <TooltipContent>
        <div><i>{isProfessional ? 'Professional' : 'Client'} Referrer</i></div>
        <div><b>{name}</b></div>
      </TooltipContent>
      {isProfessional ?
        <ProfessionalReferrerImg/> : <ClientReferrerImg/>
      }
    </Tooltip>
  </>;
};

const toMoneyString = (amount) => {
  if (!amount) {
    return '$-';
  }

  if (amount < 1000) {
    const rounded = Math.round(amount*10)/10;
    return `$${rounded}`;
  }
    
  if (amount < 1000000) {
    const rounded = Math.round(amount/100)/10;
    return `$${rounded}k`;
  }

  const rounded = Math.round(amount/10000)/100;
  return `$${rounded}m`;
};

const incomeBracketImgs = [
  imgIncomeBracket0,
  imgIncomeBracket1,
  imgIncomeBracket2,
  imgIncomeBracket3,
  imgIncomeBracket4,
];

const propertyCountConversion = {
  'None': '1',
  '1': '2',
  '2': '3',
  '3': '4',
  '4': '5',
  '5': '6',
  '6+': '7+',
};

const getPropertyCount = count => {
  if (!count) return '1';
  return propertyCountConversion[count];
};


