import React, { useEffect, useState, useRef } from 'react';
import styled, { css }from 'styled-components';


export const Img = ({ src, loader, fallback, className, objectFit, width = '100%', ...props }) => {
  const [ srcIndex, setSrcIndex ] = useState(0);
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  
  const container = useRef(null);

  useEffect(() => {
    setSrcIndex(0);
  }, [src]);
  
  
  useEffect(() => {
    const img = new Image();
    
    img.onload = () => {
      setLoading(false);
      img.style = css`object-fit: ${objectFit ?? 'contain'}; width: ${width};`;
      img.className = className;
      //img.crossOrigin = 'Anonymous';
      if (!container.current || !container.current.appendChild) {
        setError(true);
        return;
      }
      container.current.innerHTML = '';
      container.current.appendChild(img);
    };

    if (!Array.isArray(src)) {
      img.onerror = () => {
        setError(true);
      };
      img.src = src;
        
    } else { //src is an array
      img.onerror = () => {
        if (!error) {
          if (srcIndex < src.length - 1) {
            setSrcIndex(srcIndex + 1);
          } else {
            setError(true);
          }
        }
      };
      img.src = src[srcIndex];
    }
  }, [srcIndex]);

  let content = null;
  if (loading) content = loader;
  if (error) {
    content = fallback;
  }
  

  return (
    <Container ref={container} {...props} width={width}>
      {content}
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;

  img {
    width: ${props => props.width};
  }
`;