import React from 'react';
import { FieldArray } from 'redux-form';
import styled, { css } from 'styled-components';
import { Input, RichTextArea } from 'components/Form';
import { DeleteButton, Button } from './Shared';

const TextBlocks = ({ fields, buttonTitle }) => {

  const newTextBlock = () => {
    fields.push();
  };

  const remove = index => {
    fields.remove(index);
  };

  return (
    <Container>
      {!!(fields || []).length && 
        <ul>

          {(fields || []).map((item, index) => {
            return (
              <li key={index} class='text-item'>
                <Column width='calc(100% - 50px)'>
                  <Label>Name</Label>
                  <Input name={`${item}.name`} />
                </Column>
                <Column width='40px' style={{paddingRight: '0'}}>
                  <DeleteButton onDelete={() => remove(index)} id={index} type='objectives'/>
                </Column>
                <Column>
                  <Label>Text</Label>
                  <RichTextArea name={`${item}.text`}/>
                </Column>
                {/* <Column style={{float: 'right'}} width='10%'>
                  <Button color='red' onClick={() => remove(index)}>Remove</Button>
                </Column> */}
              </li>
            );
          })}
        </ul>
      }
      <Column width='200px' style={{marginTop: '30px'}}>
        <Button color='#2291FF' onClick={newTextBlock}>
          {buttonTitle || 'Add'}
        </Button>
      </Column>
      
    </Container>
  );
};

const TextList = props => {
  return (
    <FieldArray {...props} component={TextBlocks}/>
  );
};

export default TextList;

const Container = styled.div`
  li.text-item {
    margin-bottom: 40px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Column = styled.div`
  display: inline-block;
  width: ${p => p.width || '100%'};
  vertical-align: bottom;
  padding-right: 10px;
  margin-bottom: 10px;
`;
