import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
  color: #101922;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0; 
  resize: none;
  height: ${p => p.height || '130px'};
  width: ${p => p.width || '100%'};
  border: 1px solid #d8dce7;
  border-radius: 4px;
  background: #ffffff;
  color: #101922;
  padding: 10px 12px 10px 12px;
  /* box-shadow: 0px 1px 0px 0px #e9ebf1; */
  transition: all 300ms;

  &:focus {
    border: 1px solid #2291FF !important;
    box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
  }
`;

export default TextArea;