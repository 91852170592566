// src/modules/users/reducers.js

import {
  RECEIVE_AUTH,
  RECEIVE_LOGOUT,
  REQUEST_PATCH_SELF,
} from './actions';

import _ from 'lodash';

const userReducer = (state = {}, action) => {
  switch(action.type) {
  case RECEIVE_AUTH:
  case RECEIVE_LOGOUT:
    return action.payload;
  case REQUEST_PATCH_SELF: {
    const newState = _.cloneDeep(state);
    return _.merge(newState, action.payload.data);
  }
  default:
    return state;
  }
};

export default userReducer;