import calculateMonthlyRepayments from '../utils/calculateMonthlyRepayments';

export default function calculateLoanValues(_product, _user) {
  let {loanAmount, loanPeriod, loanPurpose} = _user.meta ?? {};
  let lenders = _user.lenders;
  let product = {..._product};


  product.valuationFee = product.includes_1_standard_val !== 'Y' ? product.additional_valuation_fee : 0;
  
  if (!product.approxUpfrontFee) {
    product.approxUpfrontFee = parseInt(product.application_fees) + parseInt(product.other_establishment_fees) + parseInt(product.settlement_fees) + parseInt(product.lender_legals) + parseInt(product.valuationFee);
    if (loanPurpose === 'construct') {
      product.approxUpfrontFee = product.approxUpfrontFee + parseInt(product.construction_fees);
    }
  }
  
  if (loanPurpose === 'construct') {
    product.comparisonRate = product.comparisonConstruction || product.comparisonRate;
  }

  product.repaymentType = ((product.io_allowed_for_own_occ === 'Y' && product.maximum_lvr_own_occ > 0) || (product.io_repayments === 'Y' && product.maximum_lvr_investment > 0))?'Interest Only':'Principal & Interest';

  if (product.repaymentType === 'Interest Only') {
    product.monthlyRepayments = (parseFloat(product.iir) / 100 / 12 * loanAmount).toFixed(2);
    product.fortnightlyRepayments = (parseFloat(product.iir) / 100 / 26.0714 * loanAmount).toFixed(2); 
    product.weeklyRepayments = (parseFloat(product.iir) / 100 / 52.1428 * loanAmount).toFixed(2);
  } else {
    product.monthlyRepayments = calculateMonthlyRepayments(parseFloat(product.iir) / 100 / 12, loanPeriod * 12, loanAmount).toFixed(2);
    product.fortnightlyRepayments = calculateMonthlyRepayments(parseFloat(product.iir) / 100 / 26.0714, loanPeriod * 26.0714, loanAmount).toFixed(2);
    product.weeklyRepayments = calculateMonthlyRepayments(parseFloat(product.iir) / 100 / 52.1428, loanPeriod * 52.1428, loanAmount).toFixed(2);
  }
      
  if (product.interest_rate_type === 'V' || product.interest_rate_type === 'B' || product.interest_rate_type === 'LC' ) {
    product.initial_ir_type = 'Variable';
  } else if (product.interest_rate_type === 'F') {
    product.initial_ir_type = 'Fixed';
  } else if (product.interest_rate_type === 'IV') {
    product.initial_ir_type = 'Intro Variable';
  } else {
    product.initial_ir_type = product.fixed_term ? 'Fixed' : 'Variable';
  } 

  product.iir = parseFloat(product.iir).toFixed(2);

  const fixedTermYears = Math.floor(product.fixed_term / 12);
  if (product.initial_ir_type === 'Fixed' || product.initial_ir_type === 'Intro Variable') {
    product.rateDescription = `${product.initial_ir_type} (${product.fixed_term} months)`;
    product.rateDescriptionYears = `${product.initial_ir_type} - ${fixedTermYears} ${fixedTermYears === 1 ? ' year' : ' years'}`;
    if (product.trailRebate && product.initial_ir_type === 'Intro Variable') 
      product.rateDescriptionYears = `Rate - ${fixedTermYears} ${fixedTermYears === 1 ? ' year' : ' yrs'} Intro`;
  } else {
    product.rateDescription = product.initial_ir_type;
    product.rateDescriptionYears = product.initial_ir_type;
  }
  product.shortRateDescription = product.initial_ir_type;
  product.fixedTermMonths = fixedTermYears * 12;
  product.fixedTermYears = fixedTermYears;

  if (!product.ongoing_fees) {
    product.ongoing_fees = product.monthly_fees * 12 || (product.annual_fees || 0);
  }

  const totalFees = product.approxUpfrontFee + product.discharge_fee + product.ongoing_fees * loanPeriod;
  product.totalCost = product.monthlyRepayments * 12 * loanPeriod + totalFees;
  product.totalCostFortnightly = product.fortnightlyRepayments * loanPeriod * 26.0714 + totalFees;
  product.totalCostWeekly = product.weeklyRepayments * loanPeriod * 52.1428 + totalFees;

  switch (product.rebate_type) {
  case 'text': 
    product.rebateTagContent = product.rebate_value;
    break;
  case 'amount': 
    product.rebateTagContent = '$' + product.rebate_value + ' REBATE';
    break;
  case 'percent':
    let amount = Math.floor(loanAmount * parseFloat(product.rebate_value) / 500) * 5;
    product.rebateTagContent = '$' + amount + ' REBATE';
    break;
  }
  product.rebateTooltip = product.rebate_tooltip;

  let lender = (lenders || []).find(lender => lender.id === product.bank);
  if (lender && lender.description) {
    product.lenderDescription = lender.description;
  }
  if (!product.rebateTagContent) { 
  
    if (lender && lender.rebateType) {
      switch (lender.rebateType) {
      case 'text': 
        product.rebateTagContent = lender.rebateValue;
        break;
      case 'amount': 
        product.rebateTagContent = '$' + lender.rebateValue + ' REBATE';
        break;
      case 'percent':
        let amount = Math.floor(loanAmount * parseFloat(lender.rebateValue) / 500) * 5;
        product.rebateTagContent = '$' + amount + ' REBATE';
        break;
      }

      product.rebateTooltip = lender.rebateTooltip;
    }
  }

  return product;
}