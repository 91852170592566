import React from 'react';
import styled, { css } from 'styled-components';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Column, Row, PullLeft, PullRight } from 'components/Styled/Layout';
import { Form, reduxForm } from 'redux-form';
import Label from 'components/Styled/Label';
import SearchSelect from 'components/Common/SearchSelect';
import TextInput from 'components/Styled/TextInput';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { Checkbox, Input, Select, TextArea, ToggleButton } from 'components/Form';
import { DeleteButton } from 'views/Settings/Components/Shared';
import { ApplicationCardDisabled } from '../ApplicationCardDisabled';
import SendSmsModal from './SendSmsModal';
import { smsService } from 'modules';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { useDispatch } from 'react-redux';
import { requestPatchUser } from 'modules/clients/actions';

const SmsSentModalBase = ({ modal, submitting, sendSms, goBack, smsProps, formValues, handleSubmit, smsHtml, deal, result }) => {
  
  const dispatch = useDispatch();
  let brokerName = deal.broker ? `${deal.broker.firstName} ${deal.broker.lastName}` : null;
  let processorName = deal.processor ? `${deal.processor.firstName} ${deal.processor.lastName}` : null;
  let clientName = deal.client ? `${deal.client.firstName}${deal.client.lastName ? ' ' + deal.client.lastName : ''}` : null;
  let clientPhone = deal.client.phoneNumber;

  const back = async () => {
    goBack({
      initialValues: formValues,
      deal,
    });
  };

  const retry = async () => {
    const result = await smsService.create(smsProps);

    if (!result.error) {
      dispatch(requestPatchUser({
        data: {
          id: deal.client.id,
          lastSmsId: deal.id,
        }
      }));
    }

    sendSms({
      formValues,
      result,
      smsHtml,
      smsProps,
      deal,
      goBack,
    });
  };

  return (
    <ModalBox style={{ width: '640px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(retry)}>
        <Row>
          <SmallHeading>Send SMS</SmallHeading>
        </Row>
        {!result.error &&
          <Row margin='0 0 35px 0'  style={{ fontSize: '15px' }}>
            The following SMS has been sent! 🙂
          </Row>
        }
        {result.error &&
          <Row margin='0 0 35px 0'  style={{ fontSize: '15px' }}>
            There was an error sending the SMS: <span style={{ color: 'red'}}>{result.error}</span>
          </Row>
        }
        
        <Row margin='0 0 20px 0'>
          <SmsPreview dangerouslySetInnerHTML={{ __html: smsHtml }} />
        </Row>
        <Row margin='0 0 20px 0' style={{ fontSize: '14px' }}>
          <Column width='20%' style={{ fontWeight: '700' }}>
            Recipients:
          </Column>
          <Column width='80%'>
            {formValues.includeClient &&
              <div>{clientName} ({clientPhone})</div>
            }
            {(formValues.additionalNumbers ?? '').split(',').map((number, index) => 
              <div key={index}>{number}</div>
            )}
          </Column>
        </Row>  
        <Row margin='0 0 20px 0' style={{ fontSize: '14px' }}>
          <Column width='20%' style={{ fontWeight: '700' }}>
            Notified:
          </Column>
          <Column width='80%'>
            {!!formValues.smsNotifyBroker && !!brokerName && !!deal.broker?.email &&
              <Row margin='0 0 5px 0'>{brokerName} ({deal.broker.email})</Row>
            }
            {!!formValues.smsNotifyProcessor && !!processorName && !!deal.processor?.email &&
              <Row margin='0 0 5px 0'>{processorName} ({deal.processor.email})</Row>
            }
          </Column>
        </Row>  
        <Row style={{ marginTop: '30px', marginBottom: '15px'}}> 
  
          {!result.error &&
            <Column width='70%' margin='0 15% 0 15%'>
              <ButtonPrimary onClick={modal.close} width='100%'>Finish</ButtonPrimary>
            </Column>
          }
          {result.error && <>
            <Column width='35%' margin='0 30% 0 0'>
              <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={back} width='100%'>Back</ButtonPlain>
            </Column>
            <Column width='35%' margin='0 0 0 0'>
              <ButtonPrimary type='submit' width='100%'><ButtonSpinner show={submitting}/>Retry</ButtonPrimary>
            </Column>
          </>}
          
        </Row>
      </Form> 
    </ModalBox>
  );
};

const SmsSentModal = reduxForm({ form: 'sms-sent'})(SmsSentModalBase);

export default SmsSentModal;

const SmallHeading = styled.h2`
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 26px !important;
  margin-top: -20px;
`;

const SubLabel = styled.div`
  color: #223241;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 12px;
`;

const SmsPreview = styled.div`
  border-radius: 8px;
  background: #F2F9FF;
  padding: 20px;
  font-size: 12px;
  line-height: 14px;
  min-height: 150px
`;