import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import { FlexColumn, FlexRow, SmallHeading as SmallHeadingBase, Footnote } from './Shared';
import formatNumber from '../utils/formatNumber';

const Rebates = ({ loans = [], user }) => {

  const overview  = user.meta.miniproposal?.overview ?? {};
  const defaults = user.globalSettings;

  const loanbaseCashback = overview.loanbaseCashback ?? {};
  const lenderCashback = overview.lenderCashback ?? {};
  const loanbaseTrailRebate = overview.loanbaseTrailRebate ?? {};

  const lenderCashbackText = overview.lenderCashback?.text || defaults.overview_lender_cashback;
  const loanbaseCashbackText = overview.loanbaseCashback?.text || defaults.overview_loanbase_cashback;
  const loanbaseRebateText = overview.loanbaseTrailRebate?.text || defaults.overview_loanbase_rebate;

  const trailRebates = loans
    .map(x => x.newLoan.trailRebate)
    .filter(x => !!x);

  const splitsCashback = loans
    .map(x => x.cashback || 0)
    .reduce((a, b) => a + b, 0);

  const lenderCashbackTotal = lenderCashback.type === 'splits' ? 
    splitsCashback 
      : 
    lenderCashback.rebateAmount || 0;
  
  let lenderName = '';
  if (lenderCashback.type === 'splits') {
    let lenderNames = loans
      .filter(x => !!x.cashback)
      .map(x => x.newLoan.bank_name);
  
  // remove repetitions
    lenderNames = Array.from(new Set(lenderNames));

    if (lenderNames.length > 1) {
      const last = lenderNames.pop();
      lenderName = `${lenderNames.join(', ')} & ${last}`;
    } else {
      lenderName = lenderNames[0];
    }
  }

  const lenderCashbackEndDate = formatDate(lenderCashback.endDate);

  const loanbaseCashbackEndDate = formatDate(loanbaseCashback.endDate);

  const loanbaseTrailRebateEndDate = formatDate(loanbaseTrailRebate.endDate);

  const hasTrailRebate = !!trailRebates.length;

  let sectionCount = 0;
  if (hasTrailRebate) sectionCount++;
  if (lenderCashback.isActive) sectionCount++;
  if (loanbaseCashback.isActive) sectionCount++;
  
  let trailRebateText = '';
  if (hasTrailRebate && trailRebates.every(x => x === trailRebates[0])) {
    trailRebateText = trailRebates[0] + '%';
  }
  
  if (sectionCount === 0) return null;

  return (
    <Container>
      <FlexRowRebates>
        
        {hasTrailRebate &&
          <FlexColumnRebates>
            <SmallHeading>Loan Base Rebate</SmallHeading>
            <BigBlueText>{trailRebateText} Trail Rebate*</BigBlueText>
            <SmallBlueText>Provided by Loan Base</SmallBlueText>

            <Footnote disabled={true} dangerouslySetInnerHTML={{ __html: sanitizeHtml(loanbaseRebateText)}}/>

            {!!loanbaseTrailRebate.hasEndDate &&
              <GreenText>Rebate Offer Expires {loanbaseTrailRebateEndDate}</GreenText>
            }

          </FlexColumnRebates>
        }
        {!!loanbaseCashback.isActive &&
          <FlexColumnRebates>
            <SmallHeading>Loan Base Cashback</SmallHeading>

            {!!loanbaseCashback.rebateAmount && <>
              <BigBlueText>${formatNumber(loanbaseCashback.rebateAmount)} Cashback</BigBlueText>
              <SmallBlueText>Provided by Loan Base</SmallBlueText>
              <Footnote disabled={true} dangerouslySetInnerHTML={{ __html: sanitizeHtml(loanbaseCashbackText)}}/>
                {!!loanbaseCashback.hasEndDate &&
                  <GreenText>Cashback Expires {loanbaseCashbackEndDate}</GreenText>
                }
            </>}
          </FlexColumnRebates>
        }

        {!!lenderCashback.isActive &&
          <FlexColumnRebates>
            <SmallHeading>Lender Cashback</SmallHeading>
            {!!lenderCashbackTotal && <>
              <BigBlueText>${formatNumber(lenderCashbackTotal)} Cashback</BigBlueText>
              <SmallBlueText>Provided by {lenderName}</SmallBlueText>
              <Footnote disabled={true} dangerouslySetInnerHTML={{ __html: sanitizeHtml(lenderCashbackText)}}/>
              {!!lenderCashback.hasEndDate && 
                <GreenText>Cashback Expires {lenderCashbackEndDate}</GreenText>
              }
            </>}
          </FlexColumnRebates>
        }
        
        {sectionCount < 3 &&
          <FlexColumnRebatesImg>
            <img src={require('../img/illustrations/piggybank.png')} alt="checkeligibilityhelptimer"/>
          </FlexColumnRebatesImg>
        }
      </FlexRowRebates>
    </Container>
  );
}


export default Rebates;

const formatDate = (date) => {
  if (!date) return '';
  date = new Date(date);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
  let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);

  return `${day} ${month} ${year}`;
}

const SmallHeading = styled(SmallHeadingBase)`
  font-size: 24px !important;
  margin-bottom: 13px !important;
`;

const Container = styled.div`
  margin-top: 37px;
  position: relative;

  * {
    font-family: LatoPDF, sans-serif !important;
    color: #575E6C;
  } 
`;

const FlexRowRebates = styled(FlexRow)`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 0;

`;

const FlexColumnRebates = styled(FlexColumn)`
  width: calc(33.33% - 40px);

  img {
    width: 100%;
    object-fit: contain;
    max-height: 190px;
  }
`;

const FlexColumnRebatesImg = styled(FlexColumnRebates)``;

const BigBlueText = styled.div`
  font-size: 17.5px;
  font-weight: 700;
  color: #308AE9;
  margin-bottom: 9px;
`;

const SmallBlueText = styled.div`
  font-size: 11.5px;
  font-weight: 700;
  color: #308AE9;
  margin-bottom: 10px;
`;

const GreenText = styled.div`
  font-size: 13.3px;
  font-weight: 700;
  color: #2BB839;
  margin-top: 15px;
  margin-bottom: 0;
  line-height: 1.2;
`;

          
