import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { ButtonPrimary } from 'components/Styled/Button';
import Divider from 'components/Styled/Divider';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { reduxForm, Form } from 'redux-form';
import SearchSelect from 'components/Common/SearchSelect';
import { Field } from 'redux-form';

import imgPlusWhite from 'img/button/plus-white.png';
import Label from 'components/Styled/Label';
import { TextArea, Input, MoneyInput } from 'components/Form';
import { requestCreateApplication, requestUpdateApplications } from 'modules/applications/actions';
import ReferrerModal from 'components/Common/ReferrerModal';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

import { baseUrl } from 'modules';

const dealTypeOptions = [
  { name: 'Refinance', value: 'Refinance'},
  { name: 'Purchase', value: 'Purchase'},
  { name: 'Pre-approval', value: 'Pre-approval'},
  { name: 'Construction', value: 'Construction'},
];

const AddDeal = () => {

  const dispatch = useDispatch();
  const { openModal, closeModal } = useContext(modalContext);
  const client = useSelector(state => state.clients.current);
  const brokers = useSelector(state => state.brokers);
  const user = useSelector(state => state.user);

  const addReferredDeal = () => {
    openModal({
      component: ReferrerModal,
      props: {
        introText: 'To create a referred deal we need to first select the referrer. Note that the referrer must exist within the system. Search by e-mail, name or phone number below.',
        callback: referrer => {
          openModal({
            component: AddDealModal,
            props: {
              title: 'Add New Referred Deal',
              callback: (values, closePreviousModal) => {
                if (!client) {
                  console.log('Client details missing!');
                  return;
                }
                console.log(values);
                const { loanAmount, additionalComments, brokerId, dealType, dealDescription, offsetAmount, ...props } = values;
                const broker = brokers.find(x => x.id === brokerId);
                const referralDetails = {
                  email: client.email,
                  firstName: client.firstName,
                  lastName: client.lastName,
                  phoneNumber: client.phoneNumber,
                  borrowAmount: loanAmount - (offsetAmount ?? 0),
                };
                const app = { 
                  ...props, 
                  userId: client.id, 
                  client, 
                  brokerId, 
                  broker, 
                  referrerId: referrer.id,
                  referrer,
                  page: 'referrals',
                  loanAmount, 
                  dealType, 
                  dealDescription, 
                  offsetAmount,
                  referralDetails,
                };
                dispatch(requestCreateApplication({ ...app, callback: ({ id }) => {
                  closePreviousModal();
                  openModal({
                    component: FinishModal,
                    props: {
                      title: 'Add New Referred Deal',
                      dealUrl: '../../referrals/view?id=' + id,
                    }
                  });
                  if (!additionalComments) return;
                  dispatch(requestUpdateApplications({
                    id,
                    note: {
                      brokerId: user.profile?.id,
                      body: additionalComments,
                    }
                  }));
                }}));
              }
            }
          });
        }
      }
    });
  };

  const addDeal = () => {
    openModal({
      component: AddDealModal,
      props: {
        title: 'Add New Deal',
        callback: (values, closePreviousModal) => {
          if (!client) {
            console.log('Client details missing!');
            return;
          }
          console.log(values);
          const { loanAmount, additionalComments, brokerId, dealType, dealDescription, offsetAmount, ...props } = values;
          const broker = brokers.find(x => x.id === brokerId);
          const app = { 
            ...props, 
            userId: client.id, 
            client: { ...client, meta: null, globalSettings: null, team: null, lenders: null }, 
            brokerId, 
            broker, 
            page: 'new-leads',
            loanAmount, 
            dealType, 
            dealDescription, 
            offsetAmount,
          };
          dispatch(requestCreateApplication({ ...app, callback: ({ id }) => {
            closePreviousModal();
            openModal({
              component: FinishModal,
              props: {
                title: 'Add New Deal',
                dealUrl: '../../new-leads/view?id=' + id,
              }
            });
            if (!additionalComments) return;
            dispatch(requestUpdateApplications({
              id,
              note: {
                brokerId: user.profile?.id,
                body: additionalComments,
              }
            }));
          }}));
        }
      }
    });
  };

  return <>
    <Heading>Deal Flow</Heading>
    <PlusButton onClick={addReferredDeal}>Add Referred Deal</PlusButton>
    <PlusButton onClick={addDeal}>Add Deal</PlusButton>
  </>;
};

export default AddDeal;

const Heading = styled.h2`
  font-size: 18px;
  font-weight: 700px;
`;

const PlusButton = styled(ButtonPrimary)`
  padding-left: 35px;
  padding-right: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-size: 12px 12px;
  background-position-x: 13px;
  background-position-y: 13px;
  background-image: url(${imgPlusWhite});
  background-repeat: no-repeat;
  margin-right: 20px;
`;

let AddDealModal = ({ modal, handleSubmit, callback, title }) => {

  const lenders = useSelector(state => state.lenders?.list);
  const brokers = useSelector(state => state.brokers.filter(x => x.isBroker));
  const formValues = useSelector(state => state.form['add-deal']?.values ?? {});

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  let brokerOptions = brokers.map(x => ({ value: x.id, name: x.firstName + ' ' + x.lastName }));
  brokerOptions = [ { name: 'None' }, ...brokerOptions ];

  let lenderOptions = lenders
    .map(({ id, name }) => ({ name, value: id }))
    .sort((a, b) => a.name?.localeCompare(b.name, 'en'));
  lenderOptions = [ { name: 'Other', value: 'other' }, ...lenderOptions ];

  const onSubmit = values => {
    setIsSubmitting(true);
    if (callback) callback(values, modal.close);
  };

  return (
    <ModalBox width='540px'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <ModalHeading>{title}</ModalHeading>
          </Row>
          <Row style={{ marginBottom: '30px', fontSize: '15px', lineHeight: '1.4', color: '#223241' }}>
          Note that generally you should add a deal for each app, even if it’s for the same client.
          </Row>
          
          <Row>
            <Column width='50%' style={{ paddingRight: '10px', width: '50%'}}>
              <Label>Approximate Deal Size</Label>
              <MoneyInput name='loanAmount'/>
            </Column>
           
            <Column style={{ paddingLeft: '10px', width: '50%'}}>
              <Label>Redraw / Offset Amount</Label>
              <MoneyInput name='offsetAmount'/>
            </Column> 
          </Row>

          <Row>
            <Column width='50%' style={{ paddingRight: '10px', width: '50%'}}>
              <Label>Deal Type</Label>
              <SelectField
                name='dealType'
                options={dealTypeOptions} 
                isSearchable={false}
                width='199px' 
              />
            </Column>
           
            <Column style={{ paddingLeft: '10px', width: '50%'}}>
              <Label>Assigned Broker</Label>
              <SelectField 
                name='brokerId'
                options={brokerOptions} 
                dontFilter={[undefined]} 
                isSearchable={true}
                width='199px' 
                height='250px'
              />
            </Column>
          </Row>

          <Row>
            <Label>Lender</Label>
            <SelectField
              name='lenderId'
              options={lenderOptions} 
              dontFilter={['other']} 
              isSearchable={true}
              width='418px' 
              height='300px'
            />
            
          </Row>
          
          {formValues.lenderId === 'other' && <Row>
            <Label>Lender Name</Label>
            <Input name='lenderName'></Input>
          </Row>}
          <Row>
            <Label>Deal Description</Label>
            <Input name='dealDescription'/>
          </Row>
          <Row>
            <Label>Additional Notes</Label>
            <TextArea name='additionalComments'/>
          </Row>

          <ButtonPrimary width='100%' margin='20px 0 0 0'>
            <ButtonSpinner show={isSubmitting}/>
            Add Deal
          </ButtonPrimary>
        </Container>
        

      </Form>
    </ModalBox>
  );  
};

let FinishModal = ({ modal, title, dealUrl, history }) => {

  const goToDeal = () => {
    modal.close();
    history.push(dealUrl);
  };

  return <>
    <ModalBox width='540px' >
      <Container>
        <Row>
          <ModalHeading style={{ textAlign: 'center'  }}>{title}</ModalHeading>
        </Row>
        <Row style={{ textAlign: 'center', fontSize: '15px', lineHeight: '1.4', color: '#223241'}}>
          The deal has now been added 🙂
        </Row>
        <ButtonPrimary onClick={goToDeal} width='45%'>Go to Deal</ButtonPrimary>
        <ButtonPrimary width='45%' margin='20px 0 0 10%' onClick={modal.close}>Finish</ButtonPrimary>
      </Container>
    </ModalBox>
  </>;
};

FinishModal = withRouter(FinishModal);

const Column = styled.div`
  display: inline-block;
`;

const Select = ({ input, ...props }) => {
  return <>
    <SearchSelect
      {...input}
      {...props}
    />
  </>;
};

const SelectField = props => {
  return <Field component={Select} {...props} />
};

const Container = styled.div`
  padding: 0 20px 0 20px;
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const ModalHeading = styled.h1`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
`;

AddDealModal = reduxForm({ form: 'add-deal'})(AddDealModal);