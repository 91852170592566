import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Container as ContainerBase, Heading } from './Shared';

const AboutLender = ({ ids = [], banks = {}, user }) => {
  
  const lenders = user.lenders;
  const selectedLenders = ids
    .filter(id => Object.keys(banks).some(key => key === id))
    .map(id => lenders.find(x => x.id === id))
    .filter(x => !!x && !!x.description);

  const lastIndex = selectedLenders.length - 1; 
  return selectedLenders.map((lender, index) => {
    const marginBottom = (index === lastIndex) ? '60px' : '40px';
    return (
      <Container style={{ marginBottom }} key={index}>
        <Heading>About {lender.name}</Heading>
        <P>{lender.description}</P>
      </Container>
  )});
};

export default  AboutLender;

const Container = styled(ContainerBase)`
    padding: 33px 0 0 0;
    margin: 0 !important;
`;

const P = styled.p`
  white-space: pre-line;
  line-height: 24px;
`;