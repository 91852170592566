import React from "react";
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import ProposalProductBox from "./ProductBoxPDF";
import { NavLink } from "react-router-dom";
import RefinanceSavings from "./RefinanceAnalysisPDF";
import formatNumber from '../utils/formatNumber';
import _ from 'lodash';
import html from '../utils/html';


function mapStateToProps(state, props) {
  let products = [];
  let application = {};
  if (props.user && props.user.applications && props.user.applications.length > 0) {
    application = props.user.applications[0];
    products = application.products;
  }
  return {
    shortlist: props.user.shortlist,
    user: props.user,
    miniProposal: props.user.meta.miniproposal,
    products,
    application,
    userId: props.user.id,
  };
}

class ProposedOptions extends React.Component {

  constructor(props) {
    super(props);

    const loanCount = (props.data.loanOrder || []).length;
    this.state = {
      isRecommended: null,
      refinance: (loanCount === 3) ? 1 : 0,
      repaymentFrequency: 'monthly',
    };
  }


  render() {
    let loans = this.props.data.loanOrder.map(id => {
      return this.props.data.loans[id];
    });

    let recommendedIndex = 0;
    if (loans.length === 3) {
      loans = [loans[1], loans[0], loans[2]];
      recommendedIndex = 1;
    }

    const iir = loans[0]?.iir ?? '0.00';
    const trailRebate = loans[0]?.trailRebate ?? 0;

    const textFixed = html`
      Please note that the <b>Effective Fixed</b> rate takes into account our annual trail rebate to you.
      For the recommended solution, the actual fixed rate charged by the lender is ${iir}% pa, 
      your trail rebate is ${trailRebate}% per annum (for the fixed term), 
      so your effective / net rate is ${formatNumber(parseFloat(iir) - (trailRebate), 2)}% pa.
    `;

    const textVarible = html`
      Please note that the <b>Effective Variable</b> rate takes into account our annual trail rebate to you.
      For the recommended solution, the actual variable rate charged by the lender is ${iir}% pa, 
      your trail rebate is ${trailRebate}% per annum (for the life of the loan), 
      so your effective / net rate is ${formatNumber(Number.parseFloat(iir) - trailRebate, 2)}% pa.
    `;

    return (<>
      <ProposedOptionsContainer>
        <Heading>Proposed Options</Heading>
        
        {((!!loans[0]?.trailRebate || !!loans[0]?.upfrontRebate) || loans.some(loan => loan.initial_ir_type === "Fixed")) ?
          <RebateInfoContainer isOverview={this.props.isOverview}>
            {!!(loans[0] || {}).trailRebate && 
              (loans[0].initial_ir_type === 'Fixed' ? 
                <p style={{ fontSize: '14.8px'}} dangerouslySetInnerHTML={{ __html: textFixed }}/>
                :
                <p style={{ fontSize: '14.8px'}} dangerouslySetInnerHTML={{ __html: textVarible }}/>
              )
            }

            {loans.some(loan => loan.initial_ir_type === "Fixed") &&
              <p style={{ fontSize: '14.8px'}}>
                Please note that the <b>Revert Rate</b> is the variable rate applied after the fixed rate term has expired. 
                Before this occurs, we would generally work with to you to roll you into another competitive fixed rate, 
                negotiate a discount to the variable rate, or help you refinance to a more competitive solution elsewhere. 
                This means that you should not usually need to pay the revert rate.
              </p>
            }
          </RebateInfoContainer> : <RebateInfoContainer />
        }

        <div style={{position: 'relative'}}>
        
          <Loans isPDF={true} id='scroll-container' style={{ marginBottom: '0'}}>
            
            <div className="loan-container">
              {(loans.length > 0) ?
                loans.map((box, index) => {
                  const initialValues = {
                    ...box,
                    repaymentFrequency: this.state.repaymentFrequency,
                    iir: box.iir.toString(),
                    rr: box.rr.toString(),
                    approxUpfrontFee: (box.approxUpfrontFee || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    ongoing_fees: (box.ongoing_fees || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  };
                  return (
                    <ProposalProductBox
                      isPDF={true}
                      recommendedIndex={this.props.data.recommendationActive ? recommendedIndex : -1}
                      active={this.props.editMode === (10 + index)}
                      form={'loan-' + this.props.tabId + '-' + box.productId + '-' + this.props.index}
                      tabId={this.props.tabId}
                      repaymentFrequency={this.state.repaymentFrequency}
                      proposalId={this.props.tabId}
                      currentLoan={this.props.currentLoan}
                      index={index}
                      calculateBox={box}
                      data={initialValues}
                      key={box.id}
                      refinance={0}
                      numberOfLoans={loans.length}
                      user={this.props.user}
                    />
                  )})
                :
                <EmptyLoans>
                  <img src={require('../img/illustrations/search_empty_50.png')} alt="checkeligibilityhelp" />
                  <h4>Nothing added as yet.</h4>
                  <p>Go to <NavLink to="/search-loans/">Search Loans</NavLink> to populate this section.</p>
                </EmptyLoans>
              }
            </div>
          </Loans>
        </div>
      </ProposedOptionsContainer>
      
      {(loans.length > 0 && this.props.currentLoan.newLoanPurpose === 'refinance') ? <RefinanceSavings _user={this.props.user} isPDF={this.props.isPDF} currentLoan={this.props.currentLoan} product={loans[recommendedIndex]}/> : ''}
      
    </>);
  }

}

export const Header = styled.h2`
  width: 100%;
  outline: none;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-bottom: ${p => p.marginBottom ?? '10px'} !important;
  ${p => !p.isPDF && css`
    margin-top: 25px;
  `}
  border-color: #FFF;
  transition: border-color 0.4s ease-in-out;

    &.empty {
      border: solid 1px;
      border-radius: 6px;
      border-color: #EB5846;
    }
  }

`;

const RebateInfoContainer = styled.div`
  margin: 9px 0 52px 0;
  width: 100%;
  font-size: 14.8px !important;

  b {
    position: relative;
    top: 3.1px;
  }
`;

const Heading = styled(Header)`
  margin-bottom: 5px !important;
  margin-top: 0;
  font-weight: 700;
  font-size: 18.5px;
`;

const EmptyLoans = styled.div`
  margin: 10px 0 30px 0;
  text-align: center;
  

  img {
    width: 80%;
    max-width: 450px;
    max-height: 190px;
    object-fit: contain;
  }

  h4 {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
  }

  p {
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;

    a {
      color: #2291FF;
      cursor: pointer;

      &:hover {
        color: #46A3FF;
      }
    }
  }
`;

const Loans = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
        height: 14px;
  }

  ::-webkit-scrollbar-track {
    background: #E9EBF1;
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb {
    border: solid 3px #E9EBF1;
    background: #888;
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #626B7B;
  }

  .loan-container {
    
    white-space: nowrap;
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }

  .proposal-product-box {
    margin-right: 40px;
    &:last-of-type {
      margin-right: 0 !important;
    }
  }
`;

const ProposedOptionsContainer = styled.div`

  p {
    margin-bottom: 22px;

    b {
      font-weight: bold;
    }
  }

`;

export default connect(mapStateToProps)(ProposedOptions);
