// src/views/Clients/index.js
import React, { Component } from 'react';
import { MultiSelect, SearchBar } from 'components/Form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { requestFindBrokers } from 'modules/brokers/actions';
import { TableItem } from './TableItem';
import { TableHeaderCell } from 'components/Table';
import { ButtonPrimary } from 'components/Styled/Button';
import { Link } from 'react-router-dom';
import ControlPanel from 'components/Form/ControlPanel';
import styled from 'styled-components';

class TeamSearch extends Component {

  redirect = to => this.props.history.push(to);

  render() {
    const brokers = this.props.brokers;   

    return (
      
      <main className="main client-search">
        
        <div className="client-search-container">
          <ControlPanel>
            <div>
              <div style={{ margin: '0 0 40px 40px', minWidth: '800px' }}>
                
                <ButtonPrimary as={Link} to='/team/add' style={{margin:'26px 40px 0 0', float:'right'}}>
                    + Add Team Member
                </ButtonPrimary>
              </div>
            </div>
          </ControlPanel>

          <Table>
            <Header context={this} />
            <div className='table-contents client' style={{ top: '196px' }}>
              <div style={{width: '100%', display:'table'}}>
                {brokers && brokers.length > 0 ? brokers
                  .sort((a,b) => a.lastName?.localeCompare(b.lastName))
                  .sort((a,b) => (a.overviewPosition || Infinity) - (b.overviewPosition || Infinity))
                  .map((broker, index) => (
                  
                    <TableItem
                      onClick={exclude(['a', 'button'], () => this.redirect(`/team/view?id=${broker.id}`))}
                      tabIndex={300 + index}
                      key={index}
                      broker={broker}
                    />
                  )) : (
                  <TablePlaceHolder />
                )}
              </div>

            </div>
          </Table>
        </div>
      </main>
    );
  }

  
}

const exclude = (exclusions, fn) => event => {
  if (!event.target.matches(exclusions)) {
    fn(event);
  }
};

const mapStateToProps = ({brokers, user}) => ({brokers, user});

const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { requestFindBrokers },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps) (TeamSearch);

const Table = (props) => (
  <div className='lender-table' {...props}>
  </div>
);

const Row = styled.div`
  display: flex;
`;

const Cell = styled.div`
  width: ${p => p.width};
  height: 50px;
`;

const HeadCell = styled.div`
  &:first-child {
    padding-left: 20px;
  }
  width: ${p => p.width};
  line-height: 40px;
  font-size: 11px;
  /* padding: 0 10px; */
  color: #575E6C;
  border-bottom: solid 1px #D8DCE7;
  height: 50px;
`;

export const Header = ({ context }) => (
  <div style={{display: 'table', width: '98.5%'}}>
    <Row>
      <HeadCell width='10%'>Proposal #</HeadCell>
      <HeadCell width='11%'>First Name</HeadCell>
      <HeadCell width='11%'>Last Name</HeadCell>
      <HeadCell width='11%'>Title</HeadCell>
      <HeadCell width='15%'>Email Address</HeadCell>
      <HeadCell width='12%'>Mobile</HeadCell>
      <HeadCell width='12%'>Landline</HeadCell>
      <HeadCell width='18%'>Calendly Link</HeadCell>
    </Row>
  </div>);

const TablePlaceHolder = () => [
  <PlaceHolderRow key={0}/>,
  <PlaceHolderRow key={1}/>,
  <PlaceHolderRow key={2}/>,
  <PlaceHolderRow key={3}/>,
  <PlaceHolderRow key={4}/>,
  <PlaceHolderRow key={5}/>,
  <PlaceHolderRow key={6}/>,
  <PlaceHolderRow key={7}/>,
];


const PlaceHolderRow = index => (
  <Row key={index}>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
  </Row>
);

