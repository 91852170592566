import React, { Component } from 'react';

import { reduxForm, formValueSelector  } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonPrimary } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';

import { Input, Select, AddressInput, ToggleButton } from 'components/Form';
import { PullRight } from 'components/Styled/Layout';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column } from '../ViewClient';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

import { required } from 'util/validation';

const phoneOptions = [
  { name: 'Mobile', value: 'Mobile' },
  { name: 'Landline', value: 'Landline' }
];

class ReferrerProfileForm extends Component {

  render() {
    const { handleSubmit, pristine, submitting, invalid, user } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <input hidden autoComplete="off" />
        <Row>
          <Column width='50%'>
            <Label>Company Name</Label>
            <Input name='client.referralProfile.companyName' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>ABN</Label>
            <Input name='client.referralProfile.abn' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Company Landline</Label>
            <Input name='client.referralProfile.companyPhone' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Accounts E-mail</Label>
            <Input name='client.referralProfile.email' validate={required}/>
          </Column>

          <Column width='100%'>
            <Label>Company Address</Label>
            <AddressInput width='100%' name='client.referralProfile.address' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Primary Contact Name</Label>
            <Input name='client.referralProfile.primaryContactName' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Primary Contact Mobile</Label>
            <Input name='client.referralProfile.primaryContactPhone' validate={required}/>
          </Column>

          <Column width='50%'>
            <Label>Primary Contact E-mail</Label>
            <Input name='client.referralProfile.primaryContactEmail' validate={required}/>
          </Column>
        </Row>

        <Row>
          <Column width='50%'>
            <Label>Secondary Contact Name</Label>
            <Input name='client.referralProfile.secondaryContactName'/>
          </Column>

          <Column width='50%'>
            <Label>Secondary Contact Mobile</Label>
            <Input name='client.referralProfile.secondaryContactPhone'/>
          </Column>

          <Column width='50%'>
            <Label>Secondary Contact E-mail</Label>
            <Input name='client.referralProfile.secondaryContactEmail'/>
          </Column>
        </Row>

        <Row>
          <PullRight>
            <ButtonPrimary className={(invalid || pristine) && 'disabled'} type='submit' width='100px' margin='0 40px 0 20px'><ButtonSpinner show={submitting}/>Save</ButtonPrimary>
          </PullRight>
        </Row>
        
      </form>
    );
  }
}

const stateToProps = ({ user }) => ({ user });

ReferrerProfileForm = reduxForm({
  form: 'referrer-profile'
  //enableReinitialize: true,
})(ReferrerProfileForm);

ReferrerProfileForm = connect(stateToProps)(ReferrerProfileForm);

export default ReferrerProfileForm;