import React, { Component } from 'react';
import styled from 'styled-components';

import Select from 'components/Common/Select';


const SelectBox = styled.div`
  padding: 0px;
  margin-bottom: 20px;
  padding-left: 0px !important;
  height: ${p => p.height || '40px'} !important;
  line-height: ${p => p.height || '40px'};
  width: ${p => p.width || '100%'};
  vertical-align: top;
  background-color: white !important;
  cursor: pointer;
  outline: none;
  transition: none;

  &:focus {
    span {
      padding: 3px 0;
      border: solid 1px #2291FF;
      border-radius: 4px;
    }
  }
`;

const OptionContainer = styled.div`
    position: absolute;
    background-color: white;
    border: 1px solid #D8DCE7;
    border-radius: 4px;
    box-shadow: 0px 3px 7px 0px #0000001f;
    z-index: 100;
    margin-left: -1px;
    margin-top: -1px;
`;
const Option = styled.div`
  padding-left: 15px;
  cursor: pointer;
  user-select: none;
  &:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &:hover {
    background-color: #f3f3f3
  }
`;

const SelectOption = ({ name, onClick, isHighlighted }) => (
  <Option 
    onClick={onClick}
    style={isHighlighted ? { background: '#2291FF', color: 'white' } : {}}>
    {name}
  </Option>
);

class BoxlessSelect extends Select {
  render() {
    const { meta, options, isSearchable, width, height, disabled, ...props } = this.props;
    const { isOpen, isKeyboardMode } = this.state;
    
    const value = this.props.value || (this.props.input ? this.props.input.value : '');
    let selected = (options || []).find(o => o.value === value);

    if (!selected) selected = options[0] || {name: '', value: ''};
    return (

      <SelectBox 
        as='div' 
        width={width} 
        tabIndex={0} 
        ref={this.node} 
        onClick={this.toggleOpen}
        className={isOpen ? 'open' : ''}
        height={height}>
        
        <span>{selected.name}</span>
        {
          isOpen && (
            <OptionContainer style={{ width: this.width + 'px' }}>
              {
                 options
                 .map((option, index) => (
                   <SelectOption
                     isHighlighted={isKeyboardMode && selected.value === option.value}
                     key={index}
                     {...option}
                     onClick={() => this.select(option.value)} />
                 ))
              }
            </OptionContainer>
          )
        }
      </SelectBox>

    );
  }
}

export default BoxlessSelect;