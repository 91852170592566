import React, { Component } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Card from 'components/Styled/Card';
import Details from './components/Details';
import { ButtonImageLeft, ButtonPrimary } from 'components/Styled/Button';
import { requestPatchUser, requestCreateLoan } from 'modules/clients/actions';
import { requestUser } from 'modules/clients/actions';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import AddSplit from '../Forms/AddSplit';

const $ = window.$;

class LoansView extends Component {
  state = {
    newLoanLoading: false,
    selectedGroups: [],
  }

  addSplit() {
    const { requestCreateLoan, client, loans, } = this.props;
    this.setState({ newLoanLoading: true });
    const loanIds = (loans || []).map(h => h.id.split('-'));
    const nextId = loanIds.reduce((max, id) => Math.max(max, id[1]), 0) + 1;
    const newId = `${client.id}-${nextId}`;
    requestCreateLoan({ 
      userId: client.id, 
      loan: {
        id: newId,
        name: `Split ${(loans || []).filter(l => l.type === 'mortgage').length + 1}`,
        lenderId: '',
        type: 'mortgage',
        displayOpen: true,
        security: this.state.selectedGroups[0],
      },
      callback: () => {
        this.setState({ newLoanLoading: false });
      }
    })
    setTimeout(() => {
      $('main').animate({
        scrollTop: $('.form-card').last().offset().top - $('main').offset().top + $('main').scrollTop() - 10
      }, 400);
    }, 10);
  }

  toggleGroup = (id) => 
    () => {
      const { selectedGroups } = this.state;
      if (selectedGroups.some(g => g === id)) {
        this.setState({ selectedGroups: selectedGroups.filter(g => g !== id)});
      } else {
        //this.setState({ selectedGroups: [...selectedGroups, id]});
        this.setState({ selectedGroups: [id]});
      }
    }

  changeOpenStateTo = newState => {
    for (let loan of this.props.loans) {
      if (loan.displayOpen === newState) continue;
      this.props.requestPatchUser({
        data: { loan: { id: loan.id, displayOpen: newState }, id: this.props.client.id },
      });
    }
  }

  render() {
    let groups = [];
    (this.props.houses || [])
      .filter(h => !h.isDeleted)
      .forEach(house=>{
        if (house.group) {
          let g = groups.find(group=>group.id === house.group);
          if (!g) {
            groups.push({
              id: house.group,
              propertyGroup: 'Group ' + house.group,
              properties: [house],
              totalValue: house.value || 0,
            });
          } else {
            g.properties.push(house);
            g.totalValue += house.value || 0;
          }
        } else {
          groups.push({
            id: house.id,
            propertyGroup: house.name,
            properties: [house],
            totalValue: house.value,
          });
        }
    });

    (this.props.loans || [])
      .filter(l => !l.isDeleted)
      .forEach(loan => {
        if (loan.security) {
          let group = groups.find(g => g.id.toString() === loan.security.toString());
          if (group) {
            if (group.loans) {
              group.loans.push(loan);
              group.totalBalance = loan.balance || 0;
            }
            else {
              group.loans = [loan];
              group.totalBalance = loan.balance || 0;
            }
          }
        }
    });
    
    (this.props.loans || []).forEach(loan => {
      if (loan.security) {
        let group = groups.find(g => g.id.toString() === loan.security.toString());
        if (group) {
          if (group.loans) group.loans.push(loan);
          else group.loans = [loan];
        }
      }
    });

    const filteredLoans = (this.props.loans || []).filter(loan => {
        if (loan.isDeleted) return false;
        if (!loan.security) return true;
        return this.state.selectedGroups.some(g => g.toString() === loan.security.toString()) || !this.state.selectedGroups.length;
      }).filter(l => l.type === 'mortgage');

    return (
      <div>
        <Card borderTopLeftRadius="0px" >
          <Details 
            houses={(this.props.houses || []).filter(h => !h.isDeleted)} 
            loans={(this.props.loans || []).filter(l => !l.isDeleted)} 
            type="loan-details" 
            toggleGroup={this.toggleGroup} 
            selectedGroups={this.state.selectedGroups} 
          />
        </Card>
        {!!filteredLoans.length &&
          <div className="expand-close-buttons">
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(true)}><span style={{ backgroundImage: `url(${require('../../../img/button/down.png')})` }}>Expand All</span></ButtonImageLeft>
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(false)}><span style={{ backgroundImage: `url(${require('../../../img/button/up.png')})` }}>Collapse All</span></ButtonImageLeft>
          </div>
        }
        {filteredLoans.map( 
          loan => <SplitBox loan={loan} houses={this.props.houses} key={loan.id} groups={groups} selectedGroups={this.state.selectedGroups} />
        )
        }

        <div style={{width: '1000px', position: 'relative', marginBottom: '40px'}}>
          <ButtonAdd isBig={!filteredLoans.length} onClick={() => this.addSplit()}>
            <img src={require('img/button/plus-white.png')}/>
            Add Split
          </ButtonAdd>
        </div>

      </div>
    );
  }
}

const ButtonAdd = styled(ButtonPrimary)`
  position: absolute;
  margin-top: 20px;

  ${p => p.isBig ? `
    left: 0;
    margin-left: 40px;
    font-size: 24px;
    font-weight: 600;
    padding: 0 30px;
    height: 60px;
    margin-top: 40px;
  ` : `
    left: 620px;
    width: 180px;
  `}

  img {
    ${p => p.isBig ? `
      position: relative;
      height: 17px;
      width: 17px;
      margin-right: 15px;
    ` : `
      position: relative;
      height: 14px;
      width: 14px;
      top: 1px;
      margin-right: 15px;
    `}
  }
`;

class SplitBox extends Component {
  render() {
    let { loan, houses, groups } = this.props;
    if (loan.fixedRateExp) {
      const date = new Date(loan.fixedRateExp)
      loan.fixedRateExp = `${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`;
    }

    loan = {
      remainingTermUnit: 'Years',
      repaymentFrequency: 'Monthly',
      ...loan,
    }
    return (
      <Card maxWidth="1000px" margin="20px 0 0 0" className={ 'form-card ' + (((loan.deleting ? 'card-fade-out add-loan ' : '') + (loan.changingSecurity ? 'card-swipe-left ' : '')) || 'add-loan-card ') + (loan.displayOpen ? '' : 'collapsed') }>
        <AddSplit 
          split={loan} 
          form={'addSplit' + loan.id} 
          initialValues={loan} 
          houses={houses} 
          groups={groups} 
          selectedGroups={this.props.selectedGroups}
          
        />
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestUser,
    requestCreateLoan,
  }, dispatch);

const mapStateToProps = ({ clients }) => ({ client: clients.current, loans: clients.current.loans, houses: clients.current.houses });

export default connect(mapStateToProps, mapDispatchToProps)(LoansView);

