import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ButtonPlain, ButtonPrimary, ButtonImageSquare } from 'components/Styled/Button';
import Card from 'components/Styled/Card';

import imgGreenDot from 'img/elements/green-dot.svg';
import imgOrangeDot from 'img/elements/orange-dot.svg';
import imgPlusGreen from 'img/button/plus-circle-green.svg';
import imgPlusGrey from 'img/button/plus-circle-grey.svg';
import imgImportant from 'img/elements/arrow-important.svg';
import imgTrash from 'img/button/trash-can.svg';
import imgTrashGrey from 'img/button/trash-can-grey.svg';
import imgMagnify from 'img/button/magnify.svg';
import imgMagnifyGrey from 'img/button/magnify-grey.svg';
import imgHouseBlue from 'img/elements/house-blue.svg';
import imgHouseWhite from 'img/elements/house-white.svg';
import imgBankBlue from 'img/elements/bank-blue.svg';
import imgBankWhite from 'img/elements/bank-white.svg';

const $ = window.$;

export const Heading = styled.h2`
  color: #161826;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  position: relative;
`;

export const Label = styled.div`
  color: #121923;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 10px;
`;

export const ToggleContainer = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`;

export const BigBlueButton = styled(ButtonPrimary)`
  width: 100%;
  font-weight: 700;
  height: 45px;
  line-height: 45px;
`;

export const Row = styled.div`
  display: flex;
  padding-top: ${p => p.paddingTop ?? '0'};
  width: ${p => p.width ?? '100%'};
`;

export const Column = styled.div`
  width: ${p => p.width ?? 'auto'};
  padding-right: ${p => p.paddingRight ?? '0'};
  padding-left: ${p => p.paddingLeft ?? '0'};
  padding-bottom: ${p => p.paddingBottom ?? '0'};
`;

export const PlainButton = styled(ButtonPlain)`
  font-weight: 700;
  margin-top: 10px;
  height: 45px;
`;

export const Templates = styled(Card)`
  width: calc(100% - 70px);
  height: 300px;
  overflow: auto;
  padding: 0;
`;

export const Library = styled(Card)`
  width: calc(100% - 70px);
  height: 288px; 
  margin: 0 0 10px 0; 
  padding: 5px 10px;
  overflow: auto;
`;

export const TemplateItems = styled(Card)`
  /* display: flex; */
  padding: 0;
  width: 100%;
  height: 393px;
  overflow: auto;
  /* align-content: stretch; */
`;

export const RequestColumn = styled.ul`
  /* display: flex; */
  flex: 1;
  flex-direction: column;
  padding: 0;
  min-height: 391px;
  align-content: stretch;
`;

export const ListItem = styled.li`
  position: relative;
  height: 38px;
  font-size: 14px;
  font-weight: 400;
  color: #161825;
  line-height: 38px;
  padding-left: 20px;
  white-space: nowrap;

  ${p => p.selected && css`
    background-color: #2291FF;
    color: white;
  `}

  ${p => p.onClick && css`
    cursor: pointer;
  `}
`;

export const Important = styled.img.attrs({
  src: imgImportant,
})`
  position: absolute;
  left: 0px;
  top: 12px;
`;

const ApplicantColors = [
  '#2291FF',
  '#26BD27',
  '#BD10E0',
  '#F5A623',
];

export const ApplicantCircle = props => {
  return <ApplicantCircleStyled {...props}><div>A{props.number}</div></ApplicantCircleStyled>;
};

const ApplicantCircleStyled = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 100%;

  ${p => p.onClick && css`cursor: pointer;`}

  background: ${p => ApplicantColors[p.number - 1]};
  ${p => !p.active && css`
    background: #C7C9C8;
  `}
`;

const HouseIcon = styled.img`
  position: absolute;
  left: 20px;
  top: 11px;
`;

const BankIcon = styled.img`
  position: absolute;
  left: 43px;
  top: 11px;
`;

export const ApplicantRow = styled.li`
height: 38px;
flex-shrink: 0;
/* border-right: solid 1px #D8DCE7; */
padding-right: 7px;

span:not(:last-child) {
  margin-right: 5px;
}  
`;

export const TemplateItem = ({ important, selected, isBankStatement, isPropertyDocument, children, ...props }) => {
  const imgBank = selected ? imgBankWhite : imgBankBlue;
  const imgHouse = selected ? imgHouseWhite : imgHouseBlue;  
      
  return <TemplateItemStyled selected={selected} tabIndex={-1} {...props}>
    {!!important && <Important/>}
    {!!isPropertyDocument && <HouseIcon src={imgHouse}/>}
    {!!isBankStatement && <BankIcon src={imgBank}/>}
    {children}
  </TemplateItemStyled>; 
};

const TemplateItemStyled = styled(ListItem)`
  flex-shrink: 0;
  padding-left: 75px; 
  cursor: pointer;
`;

const Green = styled.span`
  color: #30B449; 
  font-size: 14px;
  font-weight: 400;
`;

const Orange = styled.span`
  color: #F5A623;
  font-size: 14px;
  font-weight: 400; 
`;

export const ButtonImage = styled.img`
  margin-left: 10px;
  position: relative;
  top: 3px;
`;

export const LibraryRequest = ({ children, active, onClick }) => (
  <LibraryListItem tabIndex={-1} active={active}><img onClick={onClick} src={active ? imgPlusGreen : imgPlusGrey}/>{children}</LibraryListItem>
);

const IconButton = styled(ButtonImageSquare)`
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
`;

const BigIconButton = styled(IconButton)`
  width: 45px;
  height: 45px;
  background-size: 26px 26px;
  margin-left: 0;
`;

const TrashButton = styled(IconButton)`
  position: relative; 
  background-image: url(${imgTrash});
`;

const MagnifyButton = styled(IconButton)`
  background-image: url(${imgMagnify});
`;

const TrashButtonDisabled = styled(IconButton)`
  background-image: url(${imgTrashGrey});
  cursor: default;
`;

const MagnifyButtonDisabled = styled(IconButton)`
  background-image: url(${imgMagnifyGrey});
  cursor: default;
`;

export const ActionButtons = ({ active, keepTrash, noMagnify, onMagnify, onDelete, name, ...props }) => {
  const [ confirmDelete, setConfirmDelete ] = useState(false);

  const checkIfLeaveMenu = e => {
    if(!$(e.target).closest('#confirm-delete-' + name).length) {
      setConfirmDelete(false);
    }};

  useEffect(() => {
    $(window).on('click', checkIfLeaveMenu);
    return () => {
      $(window).off('click', checkIfLeaveMenu);
    };
  });

  return active ?
    <div {...props}>
      <TrashButton id={'confirm-delete-' + name} type='button' onClick={() => setConfirmDelete(!confirmDelete)}>
        <ConfirmDelete show={confirmDelete} onClick={onDelete}>Click here to delete</ConfirmDelete>
      </TrashButton>
      {!noMagnify && <MagnifyButton type='button' onClick={onMagnify}/>}
    </div>
    :
    <div {...props}>
      {keepTrash ?
        <TrashButton id={'confirm-delete-' + name} type='button' onClick={() => setConfirmDelete(!confirmDelete)}>
          <ConfirmDelete show={confirmDelete} onClick={onDelete}>Click here to delete</ConfirmDelete>
        </TrashButton>
        :
        <TrashButtonDisabled type='button'/>
      }
      
      {!noMagnify && <MagnifyButtonDisabled type='button'/>}
    </div>;
};

const ConfirmDelete = styled.div`
  
  font-size: 12px;
  color: #ff4e4c;
  position: absolute;
  top: 26px;
  border: 1px solid #d8dce7;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 12%);
  border-radius: 4px 0 4px 4px;
  right: -1px;
  width: 130px;
  padding: 10px 5px;
  background-color: #fff;
  z-index: 100;

  ${p => p.show ?
    css`display: block;`
    :
    css`display: none;`
  }

  &:after {
    position: absolute;
    display: block;
    top: -4px;
    right: 0;
    content: "";
    background-color: #fff;
    width: 28px;
    height: 5px;
  }
`;

const ConfirmDeleteBig = styled(ConfirmDelete)`
  top: 41px;  
  &:after {
    width: 43px;
  }
`;

const BigTrashButtonStyle = styled(BigIconButton)`
  position: relative;
  background-image: url(${imgTrash});
  ${p => p.disabled && css`
    background-image: url(${imgTrashGrey});
  `}
`;

export const BigTrashButton = ({ onDelete, name, ...props }) => {
  const [ confirmDelete, setConfirmDelete ] = useState(false);

  const checkIfLeaveMenu = e => {
    if(!$(e.target).closest('#confirm-delete-' + name).length) {
      setConfirmDelete(false);
    }};

  useEffect(() => {
    $(window).on('click', checkIfLeaveMenu);
    return () => {
      $(window).off('click', checkIfLeaveMenu);
    };
  });

  return (
    <BigTrashButtonStyle id={'confirm-delete-' + name} type='button' onClick={() => setConfirmDelete(!confirmDelete)} {...props}>
      <ConfirmDeleteBig show={confirmDelete} onClick={onDelete}>Click here to delete</ConfirmDeleteBig>
    </BigTrashButtonStyle>
  );
};

export const ButtonsLeft = styled(ActionButtons)`
  display: inline;
  position: absolute;
  right: 0;
  top: -10px;
`;

const LibraryListItem = styled(ListItem)`
  padding-left: 0; 
  white-space: nowrap; 

  img {
    margin-right: 10px;
    position: relative;
    top: 6px;
    cursor: default;
  }

  img {
    cursor: pointer;
  }

  ${p => !p.active && css`
    color: #BCC3CA;

    img {
      cursor: default;
    }
  `}
`;

export const Saved = () => <>
  <img src={imgGreenDot} style={{ marginRight: '5px'}}/>
  <Green>All changes saved</Green>
</>;

export const Unsaved = () => <>
  <img src={imgOrangeDot} style={{ marginRight: '5px'}}/>
  <Orange>Unsaved changes</Orange>
</>;