import React, { Component } from 'react';
import styled from 'styled-components';
import checkBold from 'img/button/check-white.png';

class Radio extends Component {
  render() {
    return (
      <div>
        {this.props.options.map((anObjectMapped, index) => {
          return <div key={index} className="option" style={{ marginBottom: '10px' }}>
            <Input id={anObjectMapped.value} type="checkbox" />
            <Label htmlFor={anObjectMapped.value}>{anObjectMapped.label}</Label>
          </div>
        })}
      </div>

    );
  }
}


export default Radio;

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  border: 1px solid #d8dce7;
  box-shadow: 0 1px 0 0 #e9ebf1;
  border-radius: 4px;
  background: #fff;
  background-size: 0;

  &:hover {
    border: 1px solid #2689EB;
  }

  &:checked {
    background-color: #2689EB;
    border: 1px solid #2689EB;
    background-image: url(${checkBold});
    background-size: 10px 8px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
`;

const Label = styled.label`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
`;