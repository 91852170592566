import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PathNavLink } from 'components/Common/PathNavLink.jsx';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import { requestPatchUser, requestCreateLoan } from 'modules/clients/actions';
import { getQueryVariables } from 'util/index';
import { ButtonImageLeft, ButtonPrimary } from 'components/Styled/Button';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import Card from 'components/Styled/Card';
import AddDebt from '../Forms/AddDebt';

import { requestUser } from 'modules/clients/actions';
const $ = window.$;


class NonMortgageDebt extends Component {

  state = {
    newLoanLoading: false,
  }

  addDebt() {
    const { requestCreateLoan, client, loans} = this.props;
    this.setState({ newLoanLoading: true });
    const loanIds = (loans || []).map(h => h.id.split('-'));
    const nextId = loanIds.reduce((max, id) => Math.max(max, id[1]), 0) + 1;
    const newId = `${client.id}-${nextId}`;
    requestCreateLoan({ 
      userId: client.id, 
      loan: {
        id: newId,
        name: `Non-mortgage Debt ${(loans || []).filter(l => l.type === 'other').length + 1}`,
        lenderId: '',
        type: 'other',
        displayOpen: true,
      },
      callback: () => {
        this.setState({ newLoanLoading: false });
      }
    })

    setTimeout(() => {
      $('main').animate({
        scrollTop: $('.form-card').last().offset().top - $('main').offset().top + $('main').scrollTop() - 10
      }, 400);
    }, 10);
  }

  changeOpenStateTo = newState => {
    for (let loan of this.props.loans) {
      if (loan.displayOpen === newState) continue;
      this.props.requestPatchUser({
        data: { loan: { id: loan.id, displayOpen: newState }, id: this.props.client.id },
      });
    }
  }

  render() {
    const totalDebt = (this.props.loans || [])
      .filter(l => l.type === 'other' && !l.isDeleted)
      .reduce((total, loan) => total + (loan.balance || 0), 0);

    const debts = (this.props.loans || []).filter(loan => loan.type === 'other' && !loan.isDeleted);

    return (
      <div>
        <Card borderTopLeftRadius="0px" >
          <div className="general-overview">
            <img className="image" src={require('../../../img/illustrations/mortgage-debt.png')} />
            <div className="property-value non-mortgage">
              <span className="aggregate">
                <div className="main-value">${formatNumber(totalDebt)}</div>
                <div className="subtitle">Aggregate Non-Mortgage Debt</div>
              </span>
            </div>
          </div>
        </Card>
        {!!debts.length &&
          <div className="expand-close-buttons">
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(true)}><span style={{ backgroundImage: `url(${require('../../../img/button/down.png')})` }}>Expand All</span></ButtonImageLeft>
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(false)}><span style={{ backgroundImage: `url(${require('../../../img/button/up.png')})` }}>Collapse All</span></ButtonImageLeft>
          </div>
        }
        
          {(debts || [])
            .map(loan => ({ repaymentFrequency: 'Monthly', remainingTermUnit: 'Years', ...loan}))
            .map( 
              loan => (
                <Card key={loan.id} maxWidth="800px" margin="20px 0 0 0" className={'form-card ' + (loan.deleting ? 'card-fade-out add-property ' : 'add-debt-card ') + (loan.displayOpen ? '' : 'collapsed')}>
                  <AddDebt loan={loan} key={loan.id} initialValues={loan} form={'addDebt' + loan.id}/>
                </Card>
              )
            )
          }
          <div style={{width: '800px', position: 'relative', marginBottom: '40px'}}>
            <ButtonAdd isBig={!debts.length} onClick={() => this.addDebt()}>
              <img src={require('img/button/plus-white.png')}/>
              Add Debt
            </ButtonAdd>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestUser,
    requestCreateLoan,
  }, dispatch);

const mapStateToProps = ({ clients }) => ({ client: clients.current, loans: clients.current.loans });

export default connect(mapStateToProps, mapDispatchToProps)(NonMortgageDebt);

function formatNumber(n = 0, d = 0) {
  if (!parseInt(n)) return '-'
  return parseInt(n)
    .toFixed(d)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ButtonAdd = styled(ButtonPrimary)`
  position: absolute;
  margin-top: 20px;

  ${p => p.isBig ? `
    left: 0;
    margin-left: 40px;
    font-size: 24px;
    font-weight: 600;
    padding: 0 30px;
    height: 60px;
    margin-top: 40px;
  ` : `
    left: 620px;
    width: 180px;
  `}

  img {
    ${p => p.isBig ? `
      position: relative;
      height: 17px;
      width: 17px;
      margin-right: 15px;
    ` : `
      position: relative;
      height: 14px;
      width: 14px;
      top: 1px;
      margin-right: 15px;
    `}
  }
`;
