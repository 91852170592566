// works with actions in the format:
// action = async () => {
// return await DoStuff()
// });

class ActionQueue {
  constructor() {
    this.queue = [];
    this.isActive = false;
  }

  add(action) {
    return new Promise(async resolve => {
      if (this.isActive) {
        this.queue.push(async () => resolve(await action()));
      } else {
        this.isActive = true;
        resolve(await action());
        while (this.queue.length > 0) {
          const nextAction = this.queue.shift();
          await nextAction();
        }
        this.isActive = false;
      }
    });
  }
}

export default ActionQueue;