import React from 'react';
import { ApplicationCardContent } from './ApplicationCardContent';

const ApplicationCardDisabled = props => {
  return (
    <div className='disabled-task'>
      <ApplicationCardContent {...props} />
    </div>
  );
};

export { ApplicationCardDisabled };