import React from 'react';
import styled, { css } from 'styled-components';

export const Heading = styled.h1`
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: bold;  
`;

export const Body = styled.div`
  font-size: 16.65px;
  line-height: 20.5px;

  b {
    font-weight: bold;
    /* position: relative;
    top: 3px; */

    i {
      top: 0;
    }
  }

  i {
    font-style: italic;
    /* position: relative;
    top: 3px; */

    b {
      top: 0;
    }
  }

  ul {
    margin-top: 20px;
    
    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
      margin-bottom: 15px;
    }
  }

  ol {
    li {
      list-style: decimal inside !important;
      margin-left: 10px;
      margin-bottom: 15px;
    }
  }
`;

export const Container = styled.div`
  color: #576781;
  padding: 37px 37px 37px 37px;
  font-family: LatoPDF, sans-serif !important;
  * {
    font-family: LatoPDF, sans-serif !important; 
  }
`;

export const BlueSeperator = styled.span`
  display: inline-block;
  width: 3px;
  height: 22px;
  margin: 0 20px 0 20px;
  background-color: #2291FF;
  vertical-align: top; 
`;

export const Column = styled.div`
  width: ${p => p.width || '100%'};
  display: inline-block;
  vertical-align: top;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Footnote = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #575E6C;
  
  a {
    color: #575E6C;
    text-decoration: underline;
  }
`;

export const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

export const FlexColumn = styled.div`
  display: inline-block;
  width: ${p => p.width || '33%'};
`;

export const FlexRow = styled.div`
  ${p => p.width && css`width: ${p.width};`}
  display: flex;
  margin: ${p => p.margin || '40px 0 40px 0'};
`;

export const Text = styled.div`
  ${p => p.color && css`color: ${p.color};`}
  text-align: ${p => p.align || 'none'};
  display: ${p => p.type || 'block'};
  font-size: ${p => p.size || '16px'};
  font-weight:  ${p => p.weight || '400'};
  margin-bottom: ${p => p.marginBottom || '0'};
  line-height: ${p => p.lineHight || '22px'};
`;

export const DocTableBorder = styled.div`
  border: solid 1px #D8DCE7;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 30px;
`;

export const DocTable = styled.table`
  table-layout: fixed;
  width: 100%;

  tr {
    border-bottom: solid 1px #D8DCE7;

    &:last-child {
      border-bottom: none;
    }

    td { 
      padding-left: 20px;
      font-size: 16.65px;
      height: 44.4px;
      line-height: 44.4px;
      position: relative;
      /* padding-bottom: 7px; */

      b {
        font-weight: bold;

        i {
            top: 0;
          }
      }

      i {
        font-style: italic;

        b {
          top: 0;
        }
      }

      img {
        position: relative;
        /* top: 3.5px; */
        width: 13px;
        height: 13px;

        margin-right: 14px;
      }
    }

    th {
      border-bottom: solid 1px #D8DCE7;
      padding-left: 20px;
      color: #585e6d;
      font-size: 20px;
      font-weight: 700;
      /* border-bottom: solid 1px #D8DCE7; */
      background: #F6F7FB;
      height: 55px;
      line-height: 55px;
      text-align: left; 
    }
    
    &:nth-child(odd) {
      background: #FAFAFD;
    }
  }

`;