import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column, Heading } from './ViewClient';


import ReferralProfileForm from './Forms/ReferralProfileForm';

import { requestPatchUser } from 'modules/clients/actions';
import { requestGetApplications } from 'modules/applications/actions';
import { SubmissionError } from 'redux-form';

import Card from 'components/Styled/Card';
import Divider from 'components/Styled/Divider';

class ReferralProfileView extends Component {

  onSubmit = data => {
    delete data.client.password;
    return new Promise((resolve, reject) => {
      //debugger;
      this.props.requestPatchUser({
        data: { ...data.client, id: this.props.client.id },
        callback: result => {
          resolve();
        }
      });
    });
  }
  
  render() {
    //debugger;
    const { client, excludeApplications } = this.props;
    //debugger;
    console.log('Client:', client);
    let initialValues = { ...client };
    if (!initialValues.referralProfile?.email) {
      initialValues.referralProfile = { ...(initialValues.referralProfile ?? {}) };
      initialValues.referralProfile.email = initialValues.email;
    }
    return (
      <Card margin="40px 0 0 0" >
        <div style={{ width: '550px', marginLeft: '40px', paddingBottom: '40px' }}>

          <ReferralProfileForm onSubmit={this.onSubmit} initialValues={{ client: initialValues }} />
          
        </div>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestGetApplications,
  }, dispatch);

const mapStateToProps = ({ clients, applications, user }) => ({ user, client: clients.current, applications: applications, applicationsList: applications?.list });

export default connect(mapStateToProps, mapDispatchToProps)(ReferralProfileView);