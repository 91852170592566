import {
  app,
  lenderService,
} from '../index';

export async function getLenders() {
  try {
    let { data } = await lenderService.find({query: { $limit: 100 }});
    //console.log(JSON.stringify(data));
    data = data.sort((a, b) => sortAlphabeticly(a.name, b.name));
    return data;

  } catch(err) {
    console.log(err);
    return {};
  }
}

export async function createLender(newLender) {
  try {
    const baseLender = {
      name: '',
      brokerWebsite: '',
      bdmName: '',
      bdmPhone: '',
      brokerId: '',
      commercialBdmPhone: '',
      commercialBdmName: '',
      brokerPhone: '',
      commissionDetail: '',
      additionalNotes: '',
      id: null,
      assessmentRateOO: null,
      assessmentRateInv: null,
      otherDebtTreatement: 'assessed',
      otherDebtAdditionOO: null,
      otherDebtAdditionInv: null
    };

    newLender = {...baseLender, ...newLender};
    return await lenderService.create(newLender);

  } catch(err) {
    console.log(err);
    return {};
  }
}

export async function patchLender(modifiedLender) {
  try {
    modifiedLender.accredited = !!modifiedLender.accredited;
    return await lenderService.patch(modifiedLender.id, modifiedLender);

  } catch(err) {
    console.log(err);
    return {};
  }
}

export async function deleteLender(id) {
  try {
    return await lenderService.remove(id);

  } catch (err) {
    console.log(err);
    return {};
  }
}

const sortAlphabeticly = (_a, _b) => {
  const a = _a.toLowerCase();
  const b = _b.toLowerCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}
  
