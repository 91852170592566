// src/components/Accounts/LoginForm/index.js

import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import { requestLogin } from '../../../modules/user/actions';
import ButtonSpinner from "components/Styled/ButtonSpinner";
import { requestGetLenders } from "modules/lenders/actions";
import { requestGetApplications } from "modules/applications/actions";
import { requestFindBrokers } from "modules/brokers/actions";


class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      username: '',
      password: '',
      isAuthenticated: false ,
      submitting: false
    };
      
  }

  handleSubmit = (e) => {
    this.setState({ submitting: true });
    this.props.requestLogin({
      redirect: (result) => {
        this.setState({ submitting: false });
        if (!result.success) {
          alert(result.message);
        }

        this.props.requestGetLenders(() => {});
        this.props.requestGetApplications(() => {});
        this.props.requestFindBrokers(() => {});
      },
      data: {
        email: this.state.username,
        password: this.state.password,
      },
    });
    e.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} >
        <label>Email Address</label>
        <input name="username" onChange={e => this.setState({ username: e.target.value })} placeholder="Enter Email" />
        <br />
        
        
        <label className="password">Password</label>
        <input name="password" onChange={e => this.setState({ password: e.target.value })} placeholder="Enter Password" type="password" />
        <NavLink 
          className="forgot-password" 
          exact to="/forgot-password"
          style={{color: '#9b9b9b', fontSize: '12px', float: 'right', marginTop: '5px'}}
        >
          Forgot Password?
        </NavLink>
        <button type="submit" onSubmit={this.handleSubmit}><ButtonSpinner show={this.state.submitting}/>Sign In</button>
      </form>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  requestLogin: data => dispatch(requestLogin(data)),
  requestGetLenders: data => dispatch(requestGetLenders(data)),
  requestGetApplications: data => dispatch(requestGetApplications(data)),
  requestFindBrokers: data => dispatch(requestFindBrokers(data)),
});

export default connect(null, mapDispatchToProps) (LoginForm);
