// src/views/Lenders/index.js
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { requestGetLenders } from 'modules/lenders/actions';

import { SearchBar } from 'components/Form';
import { ButtonPrimary } from 'components/Styled/Button';
import { Link } from 'react-router-dom';

import { 
  LenderTable,
  Header,
  LenderTableItem,
  TablePlaceHolder
} from './LenderSearchComponents';
import ControlPanel from 'components/Form/ControlPanel';


class LendersList extends Component {
  
  constructor() {
    super();
    this.state = {
      lendersLoaded: false,
      searchResults: []
    }
  }

  componentWillMount = () => {
    if (this.props.lenders.list) {
      this.setState({ lendersLoaded: true }, () => {
        this.filterResults(this.props.user.meta.lenderSearch || '');
      });
      return;
    }
      
    this.props.requestGetLenders(() => 
      this.setState({ lendersLoaded: true }, () => {
        this.filterResults(this.props.user.meta.lenderSearch);
      })
    );

    
  }

  filterResults = (filter = '') => {
    const searchResults = this.props.lenders.list
      .filter(item =>
        nullCheckedIncludes(item.id, filter) ||
        nullCheckedIncludes(item.name, filter) ||
        nullCheckedIncludes(item.bdmName, filter) ||
        nullCheckedIncludes(item.bdmEmail, filter) ||
        nullCheckedIncludes(item.bdmPhone, filter) ||
        nullCheckedIncludes(item.brokerPhone, filter) ||
        nullCheckedIncludes(item.brokerId, filter) ||
        nullCheckedIncludes(item.brokerWebsite, filter)
      );
    this.setState({ searchResults });
  }

  redirect = (to) => this.props.history.push(to);


  render() {
    const { lendersLoaded, searchResults, redirectTo } = this.state;

    return (
      <main className='lender-search'>
        <div className='lender-search-container'>
          <div style={{ display: 'inline-block', margin: '0 0 32px 40px' }}>
            <ControlPanel callback={values => this.filterResults(values.lenderSearch)}>
              <SearchBar
                width='250px'
                name='lenderSearch'
                placeholder='Search'
              />
            </ControlPanel>
          </div>

          <ButtonPrimary as={Link} to='/lenders/add' style={{marginRight:'40px', float:'right'}}>
                  + Add Lender
              </ButtonPrimary>
          <LenderTable>
            <Header />
            <div className='table-contents lender'  style={{ top: '162px' }}>
              <div style={{ width: '100%', display: 'table' }}>
                {lendersLoaded && searchResults.length > 0 ? searchResults.map((lender, index) => (
                  <LenderTableItem
                    key={index}
                    lender={lender}
                    onClick={e => excludeLinks(e, () => this.redirect(`/lenders/edit?id=${lender.id}`))}
                  />
                )) : (
                    <TablePlaceHolder />
                  )}
              </div>
            </div>
          </LenderTable>
        </div>
      </main>
    );
  }
}

const nullCheckedIncludes = (string, filter) => 
  string && string.toLowerCase().includes(filter.toLowerCase()); 

const excludeLinks = (event, fn) => {
  if (!event.target.matches('a')) {
    fn(event);
  }
}

const mapStateToProps = ({ lenders, user }) => ({ lenders, user });

const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { requestGetLenders },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps) (LendersList);