import React from 'react';
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components';

import BankLogoBase from './BankLogo';
import { SmallHeading, BlueSeperator, Bold, Footnote, Container as ContainerBase } from './Shared';
import formatNumber from '../utils/formatNumber';

import imgTick from '../img/elements/tick.png';


export default ({ loans = [], user, type }) => {

  const lenderCashback = user.meta.miniproposal?.overview?.lenderCashback || {};
  const lendingOverview = user.meta.miniproposal?.overview?.lendingOverview || {};

  const loansTotal = loans
    .map(l => l.currentLoan.loanAmount || 0)
    .reduce((a, b) => a + b, 0);

  const totalRepayments = loans
    .map(l => l.newLoan['monthlyRepayments'] ?? 0)
    .reduce((a, b) => a + b, 0);

  let trailRebateFootnote = createRebateFootnote(loans);

  if (type === 'atp') trailRebateFootnote = null;

  const showCashbacks = !!lenderCashback.isActive && lenderCashback.type === 'splits';

  const variableIOLoans = loans
    .filter(loan => loan.newLoan.repaymentType !== 'Principal & Interest' && loan.newLoan.initial_ir_type === 'Variable');

  const introVariableLoans = loans
    .filter(loan => loan.newLoan.initial_ir_type === 'Intro Variable');

  const showIOFootnote = type === 'atp' && !!variableIOLoans.length;
  const showIVFootnote = type === 'atp' && !!introVariableLoans.length;

  const hasFixedInterestLoans = loans
    .some(loan => loan.newLoan.initial_ir_type !== 'Variable');

  const logoProps = {
    width: 135,
    height: 60,
    maxFill: 0.6
  };

  

  const repaymentPeriod = 'month';

  return <>
    <Container>
      <InlineHeading>{type === 'atp' ? 'Lending Structure' : 'Proposed Lending Structure'}</InlineHeading>
      <Adjustments>
        <BlueSeperator />

        <AdjustmentText>
          <span>
            <Bold>Total Lending</Bold>
            <BlueValue>${formatNumber(loansTotal)}</BlueValue>
          </span>
          <span>
            <Bold>Total Repayments</Bold>
            <BlueValue>
              ${formatNumber(totalRepayments)}
              <PeriodDropdown>per {repaymentPeriod}</PeriodDropdown>

            </BlueValue>
          </span>
        </AdjustmentText>
      </Adjustments>

      <LoanTable>
        <HeaderRow>
          <Cell width='20%'>Description</Cell>
          <Cell width='16%'>Lender</Cell>
          <Cell width='15%'>Interest Rate</Cell>
          <Cell width='21%'>Rate Type</Cell>
          <Cell width='11%'>Loan Amount</Cell>
          <Cell width='11%' style={{ textAlign: 'right' }}>Repayment</Cell>
        </HeaderRow>
        <Lenders>
          {loans.map(loan => {
            const { currentLoan, newLoan, name, cashback } = loan;
            let typeText = '';
            const isFixedInterest = newLoan.initial_ir_type === 'Fixed';
            const isIntroVariable = newLoan.initial_ir_type === 'Intro Variable';
            if (newLoan.ioTerm || (newLoan.fixedTermMonths > 0 && (isFixedInterest || isIntroVariable))) {
              const years = newLoan.ioTerm || newLoan.fixedTermMonths / 12;
              typeText += `${years} Years `;
            }
            typeText += newLoan.initial_ir_type;
            typeText += (newLoan.repaymentType === 'Principal & Interest') ? ' P&I' : ' IO';
            
            const isVaribleIO = typeText.includes('Variable IO');

            const trailRebateAmount = type === 'atp' ? 0 : (newLoan.trailRebate || 0);
            
            return (
              <Row key={name}>
                <Cell width='20%'>
                  {name}
                  {!!cashback && showCashbacks && 
                    <CashbackText>${formatNumber(cashback)} Lender Cashback</CashbackText>
                  }
                </Cell>
                <LogoCell width='16%' padding='0' >
                  <BankLogoSmall
                    {...logoProps}
                    product={newLoan}
                    alt={newLoan.bank_name} />
                </LogoCell>
                <Cell width='15%' padding='24px'>
                  <LenderRate>
                    <Rate>{formatNumber(newLoan.iir - trailRebateAmount, 2)}<Postfix>% p.a.</Postfix></Rate>
                    {!!newLoan.trailRebate &&
                      <EffectiveRateTooltips type='wrapper' definition={`Rate factors in a ${newLoan.trailRebate}% annual trail rebate from us`}>
                        <EffectiveRateTag>{type === 'atp' ? 'Trail Rebate' : 'Effective Rate*'}</EffectiveRateTag>
                      </EffectiveRateTooltips>
                    }
                  </LenderRate>
                </Cell>
                <Cell width='21%'>
                  {typeText}{type === 'atp' && isVaribleIO && <ReferenceMark size='9px'>†</ReferenceMark>}{type === 'atp' && isIntroVariable && <ReferenceMark size='9px'>§</ReferenceMark>}{isFixedInterest && <ReferenceMark size='9px'>‡</ReferenceMark>}
                  <FeatureContainer>
                    {newLoan.full_offset === 'Y' &&
                      <Feature><img src={imgTick}/>Offset</Feature>
                    }
                    {newLoan.redraw_allowed === 'Y' &&
                      <Feature><img src={imgTick}/>Redraw</Feature>
                    }
                  </FeatureContainer>
                </Cell>
                
                <Cell width='11%'>${formatNumber(currentLoan.loanAmount)}<MobileSubtitle>Loan Size</MobileSubtitle></Cell>
                <Cell width='11%' style={{ textAlign: 'right' }}>
                  ${formatNumber(newLoan[repaymentPeriod + 'lyRepayments'])}
                  <SmallText> / {repaymentPeriod}{type === 'atp' && isVaribleIO && <ReferenceMark size='9px'>†</ReferenceMark>}{isFixedInterest && <ReferenceMark size='9px'>‡</ReferenceMark>}</SmallText>
                  <MobileSubtitle>Repayment</MobileSubtitle>
                </Cell>
              </Row>
            )
          })}
        </Lenders>
      </LoanTable>
    </Container>
    
    {lendingOverview.aggregateFees !== false && type === 'atp' &&
      <Footnote>
        * Upfront lender fees are indicative only, do not include government or variable legal charges, and may vary depending on your particular circumstances.
      </Footnote>
    }

    {loans.some(l => l.newLoan.trailRebate) && trailRebateFootnote &&
      <Footnote>
        * {trailRebateFootnote}
      </Footnote>
    }
    {showIOFootnote &&
      <Footnote>
        † The repayment amount will increase when your interest only period expires. 
      </Footnote>
    }
    
    {lendingOverview.aggregateFees !== false && type !== 'atp' &&
      <Footnote>
        † Upfront lender fees are indicative only, do not include government or variable legal charges, and may vary depending on your particular circumstances.
      </Footnote>
    }
    {hasFixedInterestLoans &&
      <Footnote>
        ‡ At the expiration of your fixed term, the applicable interest rate and/or your regular repayments may increase.
      </Footnote>
    }
    {showIVFootnote &&
      <Footnote>
        § At the expiration of your introductory variable term, the applicable interest rate and/or your regular repayments may increase.
      </Footnote>
    }
    
  </>;

};

const createRebateFootnote = loans => {
  let trailRebatesBySize = loans
    .reduce((acc, loan) => {

      const rebate = loan.newLoan.trailRebate;
      if (!rebate) return acc;
      return {
        ...acc,
        [rebate]: [...(acc[rebate] || []), loan.name]
      }
    }, {});

  trailRebatesBySize = Object.keys(trailRebatesBySize)
    .sort((a, b) => parseFloat(b) - parseFloat(a)) // largest to smallest
    .map(x => ({ size: parseFloat(x), names: trailRebatesBySize[x] }));

  if (!trailRebatesBySize.length) return '';

  let trailRebateFootnote = trailRebatesBySize
    .map(({size, names}) => {
      const s = names.length === 1; //is singular
      return `The effective rate${s?'':'s'} for ${listToString(names)} factor${s?'s':''} in an annual Loan Base rebate of ${size}%.`
    }).join(' ');

  trailRebateFootnote = trailRebateFootnote + ' Please see further details below.';

  return trailRebateFootnote;
}

const listToString = _list => {
  const list = [..._list];
  if (!(list || {}).length) return '';
  if (list.length === 1) return list[0];
  const last = list.pop();
  return `${list.join(', ')} and ${last}`;
};

const ReferenceMark = styled.span`
  font-weight: normal;
  vertical-align: top;
  text-decoration: none !important;
  font-size: ${props => props.size ?? '66%'};
`;

const FeatureContainer = styled.div`
  margin-top: 5px;
`;

const Feature = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 10px;
  color: #26BD27;
  font-size: 12px;
  /* padding-left: 18px; */
  font-weight: 400;

  img {
    position: relative;
    margin-right: 4px;
    top: 2px;
    width: 14px;
  }
`;

const Container = styled(ContainerBase)`
  margin-top: 20px;
  position: relative;
  background-color: #F7F7F7;
  /* background-color: pink; // for testing  */
  padding-bottom: 5px;
  padding-top: 30px;
  margin: 20px -37px 10px -37px;
`;

const EffectiveRateTooltips = styled.div``;

const CashbackText = styled.div`
  margin-top: 6px;
  font-size: 12px;
  font-weight: 700;
  color: #26BD27;
`;

const AdjustmentText = styled.span``;

const Adjustments = styled.span`
display: inline;
white-space: nowrap;

span {
  white-space: nowrap;
}
`;

const MobileSubtitle = styled.div`
display: none;
font-size: 12px;
color: #727C8F;
margin-top: 5px;
`;

const InlineHeading = styled(SmallHeading)`
  display: inline;
  font-family: LatoPDF;
`;


const BlueValue = styled(Bold)`
  position: relative;
  color: #2291FF;
  margin: 0 20px 0 5px;
`;

const PeriodDropdown = styled.span`
  margin-left: 5px;
  padding-bottom: 3px;
  position: relative;
`;

const SmallText = styled.div`
  display: inline;
  font-size: 10px;
  font-weight: 400;
`;

const LoanTable = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  width: 100%;
  display: block;
`;

const Cell = styled.div`
  position: relative;
  font-size: 14px;
  display: inline-block;
  padding-top: ${p => p.padding || '31px'};
  width: ${p => p.width};
  height: 75px;
`;

const LogoCell = styled(Cell)`
  display: flex;
  align-items: center;
`;

const Lenders = styled.div``;

const Row = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #D8DCE7;

  &:last-child {
    border-bottom: none;
  }
`;

const HeaderRow = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #D8DCE7;

  ${Cell} {
    color: #707E97;
    height: 20px;
    padding-top: 0px !important;
  }
`;

const BankLogoSmall = styled(BankLogoBase)`
  vertical-align: middle;
  display: inline-block;
`;

const Rate = styled.span``;
const Postfix = styled.span``;
const Title = styled.div``;

const LenderRate = styled.div`
  margin-bottom: 20px;

  ${Rate} {
    font-size: 22px;
    font-weight: 400;

    ${Postfix} {
      font-size: 12px;
      margin-left: 5px;
      font-weight: 400;
    }
  }

  ${Title} {
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
    color: #727C8F;
    display: block;
    font-family: 'LatoPDF', sans-serif;
  }

`;

const EffectiveRateTag = styled.div`
  display: inline-block;
  font-size: 11px;
  line-height: 100%;
  color: white !important;
  font-weight: bold !important;
  background-color: #2291FF;
  padding: 3px 5px;
  border-radius: 6px;
  margin-top: 2px !important;
  white-space: nowrap;
  overflow: hidden;
  width: 81px;
  text-align: center;
`;


