const getQueryVariables = query => {
  let values = {};
  if (query) {
    var vars = query.split('?')[1].split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      values[pair[0]] = pair[1];
    }
  }
  return values;
};

export default getQueryVariables;