import React from 'react';
import Form from './Forms/VideoProposalForm';
import EmailForm from './Forms/ProposalEmailForm';

import Card from 'components/Styled/Card';

const VideoProposalView = () => {
  return (
    <Card margin="40px 0 0 0" >
      <div style={{ width: '720px', marginLeft: '40px', paddingBottom: '40px' }}>
        <Form onSubmit={() => { }} />
        <EmailForm onSubmit={() => { }} />
      </div>
    </Card>
  );
};

export default VideoProposalView;
