
import React, { Component } from 'react';
import styled from 'styled-components';
import { TableContext } from './TableContext';

export { SortableHeaderCellBlock } from './SortableHeaderCell';


// export const Table = (props) => (
//   <div className='lender-table' {...props}>
//   </div>
// );



export const TableRow = styled.div`
  display: block;
  &:after { 
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
`;

export const TableCell = styled.div`
  width: ${p => p.width || 'auto'};
  display: block;
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TableHeader = styled.div`
  display: block;
`;

export const TableBody = styled.div`
  display: block;
`;

const PlainStyledTable = styled.div`
  width: ${p => p.width || 'auto'};
  display: block;
  background: white;

  ${TableCell} {
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    border-bottom: solid 1px #D8DCE7;
    white-space: nowrap;
    vertical-align: center;
  }

  ${TableRow} {
    width: 100%; 
    
    ${TableCell} {
      font-size: 13px;
      color: #101922;
    }
  }

  ${TableHeader} {

    ${TableCell} {
      font-size: 11px;
      padding: 0 10px;
      color: #575E6C;
    }
  }
`;

const CheckedTable = styled.div`
  width: ${p => p.width || 'auto'};
  display: block;

  ${TableCell} {
    line-height: 70px;
    padding: 0 10px;
    border-bottom: solid 1px #D8DCE7;
    white-space: nowrap;
    vertical-align: center;
    
  }

  ${TableRow} {
    width: 100%; 
    background-color: #f6f7fB;

    &:hover {
      filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.2));
    }
    
    &:nth-child(even) {
       background-color: white;
    }

    ${TableCell} {
      font-size: 14px;
      color: #101922;
    }

    > * {
      &:first-child {
        padding-left: 40px !important;
      }

      &:last-child {
        padding-right: 40px !important;
      }
    }

  }

  ${TableHeader} {
    /* filter: drop-shadow(-5px 5px 3px rgba(0, 0, 0, 0.05)); */
    width: ${p => p.headerWidth || '100%'};

    ${TableRow} {
      
      &:hover {
        filter: drop-shadow(-5px 5px 3px rgba(0, 0, 0, 0));
      }
    }

    ${TableCell} {
      line-height: 40px;
      font-size: 11px;
      padding: 0 10px;
      color: #575E6C;
      user-select: none;
      height: 50px;
    }
  }
`;

export const tableStyle = {
  plain: PlainStyledTable,
  checked: CheckedTable,
};

export class Table extends Component {
  constructor() {
    super();

    this.state = {
      sorting: {
        field: '',
        direction: 1,
        sortTable: null,
        update: fieldNew => {
          const { sorting } = this.state;
          const { field, direction, sortTable } = sorting;
          if (fieldNew === field) {
            this.setState({ sorting: {...sorting, direction: -direction}});
            sortTable && sortTable({ field, direction: -direction});
          }
          else {
            this.setState({ sorting: {...sorting, field: fieldNew, direction: 1}});
            sortTable && sortTable({field: fieldNew, direction: 1});
          }
        },
      }
    };
  }

  async componentWillMount() {
    await this.setState( 
      state => (
        {
          ...state,
          sorting: {
            ...state.sorting, 
            sortTable: this.props.onSort || ( () => {} ), 
            field: this.props.sortField,
          }
        }
      )
    )
    
  }

  render() {
    const {styling, ...props} = this.props;
    const StyledTable = styling;

    if (!this.state.sorting.update) return '';

    return (
      <TableContext.Provider value={this.state}>
        <StyledTable {...props}/>
      </TableContext.Provider>
    );
  }
}

export const ScrollableBody = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: ${p => p.topPos || '0'};
`;

