import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading, Body, Container as ContainerBase, Column } from './Shared';

const $ = window.$;

export default ({ user }) => {

  const body = user.meta.miniproposal.overview?.objectives?.body ?? '';

  const [ showImage, setShowImage ] = useState(true);

  useEffect(() => {
    const listLength = $('li', '#objectives-body').length;
    if (listLength > 4) setShowImage(false);
  });

  return (
    <Container>

      <Column width={showImage ? '462px' : '825px'}>
        <Heading>Your Objectives</Heading>
        <Body id='objectives-body' dangerouslySetInnerHTML={{ __html: sanitizeHtml(body)}}/>
      </Column>

      {showImage && <img src={require('../img/illustrations/objectives.png')} alt="objectives"/>}

    </Container>
  );
};

const Container = styled(ContainerBase)`
  display: flex;
  padding-right: 0;
  padding-left: 5px;
  padding-bottom: 15px;

  img {
    align-self: flex-end;
    width: 330px;
    margin-left: 33px;
    margin-bottom: 20px;
  }
`;