import { createAction } from 'redux-actions';

export const REQUEST_FIND_BROKERS = 'REQUEST_FIND_BROKERS';
export const RECEIVE_FIND_BROKERS = 'RECEIVE_FIND_BROKERS';
export const REQUEST_CREATE_BROKER = 'REQUEST_CREATE_BROKER';
export const RECEIVE_CREATE_BROKER = 'RECEIVE_CREATE_BROKER';
export const REQUEST_PATCH_BROKER = 'REQUEST_PATCH_BROKER';
export const RECEIVE_PATCH_BROKER = 'RECEIVE_PATCH_BROKER';
export const REQUEST_DELETE_BROKER = 'REQUEST_DELETE_BROKER';
export const RECEIVE_DELETE_BROKER = 'RECEIVE_DELETE_BROKER';

export const requestFindBrokers = createAction(REQUEST_FIND_BROKERS);
export const receiveFindBrokers = createAction(RECEIVE_FIND_BROKERS);
export const requestCreateBroker= createAction(REQUEST_CREATE_BROKER);
export const receiveCreateBroker = createAction(RECEIVE_CREATE_BROKER);
export const requestPatchBroker = createAction(REQUEST_PATCH_BROKER);
export const receivePatchBroker = createAction(RECEIVE_PATCH_BROKER);
export const requestDeleteBroker = createAction(REQUEST_DELETE_BROKER);
export const receiveDeleteBroker = createAction(RECEIVE_DELETE_BROKER);