import React, { Component, Fragment } from 'react';

import { ModalContext, ModalShow  } from './old';

export class ModelessButton extends Component {
  render() {
    const { modeless, buttonDefault, buttonActive, data, animationTimeout, ...props } = this.props;
    return (
      <div style={{ position: 'relative', display: 'inline-block', cursor: 'pointer'}}>
        <ModalContext
          modal={modeless}
          animationTimeout={animationTimeout}>

          <Button
            {...props}
            buttonDefault={buttonDefault}
            buttonActive={buttonActive}
            data={data} />

        </ModalContext>
      </div>

    );
  }
} 


class Button extends Component {
  
  render() {
    const {buttonDefault, buttonActive, data, ...props } = this.props;
    return (
      <ModalShow 
        as={buttonDefault} 
        asSelected={buttonActive} 
        modalProps={data} 
        {...props}/> 
    );
  }
}







