import { app, hostUrl } from 'modules';
import request from 'superagent';

export default async function uploadFile({file, type, name, callback}) {
  return new Promise(resolve => {
    app.passport.getJWT().then(token => {
      request
        .post(hostUrl + '/file-upload')
        .set('Authorization', token)
        .field('type', type)
        .field('name', name)
        .attach('uri', file)
        .end((err, res) => {
          resolve(res?.body);
          if (callback) {
            callback(err, res);
          }
        });
    });
  });
}