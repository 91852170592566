import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import Tooltips from 'components/Common/Tooltips';
import BankLogoBase from 'shared/PDF/BankLogo';
import formatNumber from 'util/formatNumber';
import { ButtonPrimary as Button } from 'components/Styled/Button';
import { NumberInput } from 'components/Form';

import imgTick from 'img/elements/tick.svg';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Form, reduxForm } from 'redux-form';
import { requestPatchUser } from 'modules/clients/actions';

export const LendingStructure = ({ loans = [], showIOFootnote, showFeeFootnote, showFixedInterestFootnote }) => {
  const dispatch = useDispatch();
  const dropdownButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [repaymentPeriod, setRepaymentPeriod] = useState('month');

  const handleClickOffDropdown = event => {
    if ((dropdownRef && !dropdownRef.current.contains(event.target)) &&
      (dropdownButtonRef && !dropdownButtonRef.current.contains(event.target))) {
      setDropdownIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOffDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOffDropdown);
    };
  }, []);

  const { openModal, closeModal } = useContext(modalContext);

  const { client, lenderCashback } = useSelector(state => ({
    overview: ((state.user.meta.miniproposal || {}).overview || {}).lendingOverview || {},
    client: state.clients?.current,
    lenderCashback: state.clients?.current?.meta.miniproposal?.overview?.lenderCashback || {},
  }));

  const setNewRepaymentPeriod = period => {
    setRepaymentPeriod(period)
    setDropdownIsOpen(false);
  };

  const loansTotal = loans
    .map(l => l.currentLoan.loanAmount || 0)
    .reduce((a, b) => a + b, 0);

  const totalRepayments = loans
    .map(l => l.newLoan[(repaymentPeriod || 'month') + 'lyRepayments'] || 0)
    .reduce((a, b) => a + b, 0);

  const introVariableLoans = loans
    .filter(loan => loan.newLoan.initial_ir_type === 'Intro Variable');

  const showIVFootnote = !!introVariableLoans.length;


  const showCashbacks = !!lenderCashback.isActive && lenderCashback.type === 'splits';

  const logoProps = {
    width: 135,
    height: 60,
    maxFill: 0.6
  };

  const saveIOTerm = loan => 
    values => {
      console.log(values, loan.tabId, loan.loanId);
      const patch = {
        meta: { miniproposal: { properties: {
          [loan.tabId]: {
            loanComparison: {
              loans: {
                [loan.loanId]: values
              }
            }
          }
        }}}
      };

      dispatch(requestPatchUser({
        data: {
          id: client.id,
          ...patch
        },
        params: {
          mergeMeta: true,
        }
      }));

      closeModal();
    };

  return (
    <Container>
      <InlineHeading>Proposed Lending Structure</InlineHeading>

      <Adjustments>
        <BlueSeperator />

        <AdjustmentText>
          <span>
            <Bold>Total Lending</Bold>
            <BlueValue>${formatNumber(loansTotal)}</BlueValue>
          </span>
          <span>
            <Bold>Total Repayments</Bold>
            <BlueValue>
              ${formatNumber(totalRepayments)}
              <PeriodDropdown ref={dropdownButtonRef} onClick={() => setDropdownIsOpen(!dropdownIsOpen)}>per {repaymentPeriod}</PeriodDropdown>

              <Menu ref={dropdownRef} display={dropdownIsOpen}>
                <MenuList>
                  <MenuItem onClick={() => setNewRepaymentPeriod("week")}>per week</MenuItem>
                  <MenuItem onClick={() => setNewRepaymentPeriod("fortnight")}>per fortnight</MenuItem>
                  <MenuItem onClick={() => setNewRepaymentPeriod("month")}>per month</MenuItem>
                </MenuList>
              </Menu>

            </BlueValue>
          </span>
        </AdjustmentText>
      </Adjustments>

      <LoanTable>
        <HeaderRow>
          <Cell width='20%'>Description</Cell>
          <Cell width='16%'>Lender</Cell>
          <Cell width='15%'>Interest Rate</Cell>
          <Cell width='21%'>Rate Type</Cell>
          <Cell width='11%'>Loan Amount</Cell>
          <Cell width='11%' style={{ textAlign: 'right' }}>Repayment</Cell>
        </HeaderRow>
        <Lenders>
          {loans.map(loan => {
            const { currentLoan, newLoan, name, cashback } = loan;
            const repaymentType = (newLoan.repaymentType === 'Principal & Interest') ? 'P&I' : 'IO';
            const isIntroVariable = newLoan.initial_ir_type === 'Intro Variable';
            const isFixedInterest = newLoan.initial_ir_type === 'Fixed';
            const isVariableIO = newLoan.initial_ir_type === 'Variable' && repaymentType == 'IO';
            let typeText = '';
            if (newLoan.fixedTermMonths > 0 && (isIntroVariable || isFixedInterest)) {
              const years = newLoan.fixedTermMonths / 12;
              typeText += `${years} Years `;
            }
            typeText += newLoan.initial_ir_type;
            typeText += ' ' + repaymentType;

            const modalOptions = {
              component: IOModal,
              props: {
                initialValues: { ioTerm: newLoan.ioTerm },
                onSubmit: saveIOTerm(loan)
              }
            };

            if (isVariableIO) {
              if (newLoan.ioTerm) {
                typeText = <NormalIO onClick={() => openModal(modalOptions)}>
                  <Underline>{newLoan.ioTerm} Years Variable IO</Underline><ReferenceMark size='9px'>†</ReferenceMark>
                </NormalIO>;
              } else {
                typeText = <WarningIO onClick={() => openModal(modalOptions)}><Underline>Variable IO</Underline><ReferenceMark size='9px'>†</ReferenceMark></WarningIO>;
              }
            }

            return (
              <Row key={loan.tabId}>
                <Cell width='20%'>
                  {name}
                  {!!cashback && showCashbacks && 
                    <CashbackText>${formatNumber(cashback)} Lender Cashback</CashbackText>
                  }
                </Cell>
                <LogoCell width='16%' padding='0' >
                  <BankLogoSmall
                    {...logoProps}
                    product={newLoan}
                    alt={newLoan.bank_name} />
                </LogoCell>
                <Cell width='15%' padding='24px'>
                  <LenderRate>
                    <Rate>{formatNumber(newLoan.iir, 2)}<Postfix>% p.a.</Postfix></Rate>
                    {!!newLoan.trailRebate &&
                      <EffectiveRateTag>Trail Rebate</EffectiveRateTag>
                    }
                  </LenderRate>
                </Cell>
                <Cell width='21%'>
                  {typeText}{isFixedInterest && <ReferenceMark size='9px'>‡</ReferenceMark>}{isIntroVariable && <ReferenceMark size='9px'>§</ReferenceMark>}
                  <FeatureContainer>
                    {newLoan.full_offset === 'Y' &&
                      <Feature><img src={imgTick}/>Offset</Feature>
                    }
                    {newLoan.redraw_allowed === 'Y' &&
                      <Feature><img src={imgTick}/>Redraw</Feature>
                    }
                  </FeatureContainer>
                </Cell>
                
                <Cell width='11%'>${formatNumber(currentLoan.loanAmount)}<MobileSubtitle>Loan Size</MobileSubtitle></Cell>
                <Cell width='11%' style={{ textAlign: 'right' }}>
                  ${formatNumber(newLoan[repaymentPeriod + 'lyRepayments'])}
                  <SmallText> 
                    / {repaymentPeriod}{isVariableIO && <ReferenceMark size='9px'>†</ReferenceMark>}{isFixedInterest && <ReferenceMark size='9px'>‡</ReferenceMark>}
                  </SmallText>
                  <MobileSubtitle>Repayment</MobileSubtitle>
                </Cell>
              </Row>
            )
          })}
        </Lenders>
      </LoanTable>
      {showFeeFootnote &&
        <Footnote>
          * Upfront lender fees are indicative only, do not include government or variable legal charges, and may vary depending on your particular circumstances.
        </Footnote>
      }
      
      {showIOFootnote &&
        <Footnote>
          † The repayment amount will increase when your interest only period expires. 
        </Footnote>
      }

      {showFixedInterestFootnote &&
        <Footnote>
          ‡ At the expiration of your fixed term, the applicable interest rate and/or your regular repayments may increase.
        </Footnote>
      }

      {showIVFootnote &&
        <Footnote>
          § At the expiration of your introductory variable term, the applicable interest rate and/or your regular repayments may increase.
        </Footnote>
      }
      
    </Container>


  );

};


const ModalRow = styled.div``;

const YearText = styled.span`
  margin: 10px 0 0 10px;
  display: inline-block;
`;

let IOModal = ({ handleSubmit }) => {
  return (
    <ModalBox width='540px' padding='60px 80px'>
      <Form onSubmit={handleSubmit}>
        <ModalRow>
          <SmallHeading>IO Term</SmallHeading>
        </ModalRow>
        <ModalRow style={{margin:'0 0 20px 0'}}>
          <NumberInput width='50px' name='ioTerm'/>
          <YearText>years</YearText>
        </ModalRow>
        <ModalRow>
          <Button type='submit' width='100%'>Submit</Button>
        </ModalRow>
      </Form>
    </ModalBox>
  );
};  

IOModal = reduxForm({ form: 'io-term'})(IOModal);

const ReferenceMark = styled.span`
  font-weight: normal;
  vertical-align: top;
  text-decoration: none !important;
  font-size: ${props => props.size ?? '66%'};
`;

const WarningIO = styled.div`
  color: #FF4E4C;
  font-weight: 700;
  cursor: pointer;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const NormalIO = styled.div`

  cursor: pointer;
`;

export const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

export const BlueSeperator = styled.span`
  display: inline-block;
  width: 3px;
  height: 22px;
  margin: 0 20px 0 20px;
  background-color: #2291FF;
  vertical-align: top; 
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Footnote = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  a {
    color: #575E6C;
    text-decoration: underline;
  }
`;

const FeatureContainer = styled.div`
  margin-top: 5px;

`;

const Feature = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 10px;
  color: #26BD27;
  font-size: 12px;
  /* padding-left: 18px; */
  font-weight: 400;

  img {
    position: relative;
    margin-right: 4px;
    top: 2px;
  }
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

const EffectiveRateTooltips = styled(Tooltips)`
`;

const CashbackText = styled.div`
  margin-top: 6px;
  font-size: 12px;
  font-weight: 700;
  color: #26BD27;
`;

const AdjustmentText = styled.span`
`;

const Adjustments = styled.span`
display: inline;
white-space: nowrap;

span {
  white-space: nowrap;
}
`;

const MobileSubtitle = styled.div`
display: none;
font-size: 12px;
color: #727C8F;
margin-top: 5px;
`;

const InlineHeading = styled(SmallHeading)`
  display: inline;
`;

const BlueValue = styled(Bold)`
  position: relative;
  color: #2291FF;
  margin: 0 20px 0 5px;
`;

const PeriodDropdown = styled.span`
  margin-left: 5px;
  padding-bottom: 3px;
  position: relative;
  cursor: pointer;
  border-bottom: solid 2px #308AE9;
`;

const Menu = styled.div`
  ${p => p.display ? `` : css`display: none;`}
  top: 35px;
  left: 35px;
  z-index: 100;
  padding: 5px 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216,220,231,0.65);
  border-radius: 4px;
  cursor: default !important;

  &::before {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent #D8DCE7 transparent;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

const MenuList = styled.ul`
`;

const MenuItem = styled.li`
  width: 110px;
  vertical-align: baseline;
  font-size: 14px;
  padding: 12px 15px;
  font-weight: 400;
  color: #101922;
  cursor: pointer;
  &:hover {
    background-color: #F6F7FB;
  }
`;

const SmallText = styled.div`
  display: inline;
  font-size: 10px;
  font-weight: 400;
`;

const LoanTable = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  width: 100%;
  display: block;
`;

const Cell = styled.div`
  position: relative;
  font-size: 14px;
  display: inline-block;
  padding-top: ${p => p.padding || '31px'};
  width: ${p => p.width};
`;

const LogoCell = styled(Cell)`
  display: flex;
  align-items: center;
`;

const Lenders = styled.div`
`;

const Row = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #D8DCE7;

  ${Cell} {
    height: 75px;
    font-weight: 700;

    &:nth-of-type(1), &:nth-of-type(4) {
      font-family: 'Lato', sans-serif;
      font-weight: 600;
    }
  }
`;
const HeaderRow = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #D8DCE7;

  ${Cell} {
    color: #707E97;
    height: 20px;
    padding-top: 0px !important;
  }
`;

const BankLogoSmall = styled(BankLogoBase)`
  vertical-align: middle;
  /* max-width: 130px;
  max-height: 30px;
  object-fit: contain; */
  display: inline-block;
  object-fit: contain;
`;

const Rate = styled.span``;
const Postfix = styled.span``;
const Title = styled.div``;

const LenderRate = styled.div`
  margin-bottom: 20px;

  ${Rate} {
    font-size: 22px;
    font-weight: 400;

    ${Postfix} {
      font-size: 12px;
      margin-left: 5px;
      font-weight: 400;
    }
  }

  ${Title} {
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
    color: #727C8F;
    display: block;
    font-family: 'Lato', sans-serif;
  }

`;

const EffectiveRateTag = styled.div`
  display: inline-block;
  font-size: 11px;
  line-height: 100%;
  color: white !important;
  font-weight: bold !important;
  background-color: #2291FF;
  padding: 3px 5px;
  border-radius: 6px;
  margin-top: 2px !important;
  white-space: nowrap;
  overflow: hidden;
  width: 81px;
  text-align: center;
`;
