import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Container as ContainerBase, Heading } from './Shared';
import { imageUrl } from 'modules';

const TheTeam = ({ team, max }) => {

  return (
    <Container>
      <Heading>Meet Our Specialist Team</Heading>
      <TeamContainer>

        {(team || {}).map(({ id, title, name, blurb }, index) => {
          if (index >= max) return null;
          return(
            <MainContainer key={id}>
              <DesktopTeam>
                <Portrait crossOrigin='anonymous' src={`${imageUrl}/brokers/small${id}.png`} alt={name} />
                <TextContainer>
                  <Title>{title}</Title>
                  <BlueSeperator />
                  <Name>{name}</Name>
                  <BlurbContainer>
                    <Blurb>{blurb}</Blurb>
                  </BlurbContainer>
                </TextContainer>
              </DesktopTeam>
            </MainContainer>
          );
        })}

      </TeamContainer>
    </Container>
  );
};

const stateToProps = (state, props) => {
  let { team, proposalBroker } = props.user;
  if (!proposalBroker) return { team };
  const broker = {
    id: proposalBroker.id,
    name: proposalBroker.name,
    title: 'Your Broker',
    blurb: proposalBroker.blurb,
  };
  
  team = team.filter(x => x.id !== broker.id && x.overviewPosition);
  team = [broker, ...team];

  return {
    team
  };
};

export default connect(stateToProps)(TheTeam);

const Container = styled(ContainerBase)`
    padding: 10px 0 0 0;
`;

const DesktopTeam = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 645px) {
    display: none;
  }
`;

const TeamContainer = styled.div`
  margin-top: 30px;
`;

const Portrait = styled.img`
  display: inline-block; 
  object-fit: cover;
  height: 100px;
  min-width: 100px;
  border-radius: 100%;
  background-color: #D2DCEB;
  margin-right: 33px;

  @media (max-width: 645px) {
    height: 80px;
    width: 80px;
    min-width: 80px;
    margin-right: 0;
  }
`;

const Blurb = styled.span`
  color: #576377;
  font-size: 16px;
  line-height: 150%;
`;

const Name = styled(Blurb)`
  font-weight: 700;

  @media (max-width: 645px) {
    display: block;
  }
`;

const Title = styled(Blurb)`
  font-weight: 800;
  font-style: italic;

  @media (max-width: 645px) {
    display: block;
  }
`;

const MainContainer = styled.div`
  display: block;
`;

const TextContainer = styled.div`
  display: inline-block;
  /* margin-top: 10px; */
  //width: 50%;
`;

const BlurbContainer = styled.div`
  margin-top: 4px;
`;

const BlueSeperator = styled.span`
  display: inline-block;
  width: 3px;
  height: 22px;
  margin: 0 20px 0 20px;
  background-color: #2291FF;
  vertical-align: top; 

  @media (max-width: 645px) {
    height: 43px;
  }
`;