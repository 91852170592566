import { MoneyInput, RichTextArea } from 'components/Form';
import { ModalBox } from 'components/Modal';
import { ButtonPrimary } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import { verificationService } from 'modules';
import { requestUpdateApplications } from 'modules/applications/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, reduxForm, reset } from 'redux-form';
import styled from 'styled-components';
import html from 'util/html';

const Row = styled.div``;

const ModalHeading = styled.h1`
  font-weight: 700;
  font-size: 24px;
`;

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
`;

let CommissonModal = ({ modal, type, dirty, deal, handleSubmit, initialValues, user }) => {
  const dispatch = useDispatch();

  const commissionAmount = useSelector(state => state.form['commission-modal']?.values?.commissionAmount);
  const referreeCommissonAmount = useSelector(state => state.form['commission-modal']?.values?.referreeCommissonAmount);
  const formNote = useSelector(state => state.form['commission-modal']?.values?.note);
  const initialCommissionAmount = initialValues?.commissionAmount;
  const initialReferreeCommissonAmount = initialValues?.referreeCommissonAmount;
  debugger;
  const changeCommission = ({ commissionAmount, referreeCommissonAmount, note }) => {
    addNote({ body: note });
    dispatch(requestUpdateApplications({ 
      id: deal.id, 
      commissionAmount,
      referreeCommissonAmount,
    }));
  };

  const addNote = ({ body, visibleToReferrer }) => {
    dispatch(requestUpdateApplications({ 
      id: deal.id, 
      note: { 
        visibleToReferrer,
        body, 
        brokerId: user.profile?.id 
      } }));
    dispatch(reset('deal-notes'));

    if (visibleToReferrer && deal.referrer?.email) {
      verificationService.create({
        type: 'referral-comment',
        applicationId: deal.id,
        email: deal.referrer?.email,
        note: body,
      });
    }
  }; 

  const onSubmit = ({ commissionAmount, referreeCommissonAmount, note }) => {
    const body = html`
      <div><b>${type === 'add' ? 'Commission Paid' : 'Commission Amount Changed'}</b></div>
      ${commissionAmount && `<div><b>Referrer Amount: </b> $${commissionAmount}</div>`}
      ${referreeCommissonAmount && `<div><b>Referree Amount: </b> $${referreeCommissonAmount}</div>`}
      <div>${note ?? ''}</div>
    `;
    
    changeCommission({ commissionAmount, referreeCommissonAmount, note: body });
    modal.close();
  };

  const cancelCommission = () => {
    const body = html`
      <div><b>Commission Cancelled</b></div>
      <div>${formNote ?? ''}</div>
    `;
    changeCommission({ commissionAmount: null, referreeCommissonAmount: null, note: body});
    modal.close();
  };
  
  return <>
    <ModalBox width='550px'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row style={{ marginBottom: '20px' }}>
          <ModalHeading>{(type === 'add') ? 'Add' : 'Edit'} Commission Payment</ModalHeading>
        </Row>
        <Row style={{ marginBottom: '20px' }}>
          <Column style={{ width: '45%', marginRight: '10%'}} >
            <Label>Referrer amount paid</Label>
            <MoneyInput name='commissionAmount' />
          </Column>
          <Column style={{ width: '45%' }}>
            <Label>Referree amount paid</Label>
            <MoneyInput name='referreeCommissonAmount' />
          </Column>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Label>Additional Notes</Label>
          <RichTextArea name='note' />
        </Row>
        <Row>
          <ButtonPrimary type='button' background='#FF4E4C' width='45%' margin='0 10% 0 0' onClick={cancelCommission}>Cancel Commission</ButtonPrimary>
          <ButtonPrimary type='submit' disabled={!dirty || (initialCommissionAmount === commissionAmount && initialReferreeCommissonAmount === referreeCommissonAmount)} width='45%'>
            {(type === 'add') ? 'Add Commission Payment' : 'Apply Change to Commission'}
          </ButtonPrimary>
        </Row>
      </Form>
    </ModalBox>
  </>;
};

CommissonModal = reduxForm({ form: 'commission-modal' })(CommissonModal);

export default CommissonModal;