import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';

import Card from 'components/Styled/Card';
import { Row, Column, PullRight } from 'components/Styled/Layout';
import Txt from 'components/Styled/Text';

import { ButtonPrimary } from 'components/Styled/Button';
import { ToggleRowSmall, TextInputRowSmall, ToggleInputRow, OffsetInputRow } from './SharedComponents';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

import { TextArea } from 'components/Form';

import { showUnfinished } from 'modules';

const RightColumn = styled(Column)`
  min-height: 85px;
  width: calc(100% - 230px);
  padding: 0 40px 20px 40px;
  border-right: 1px solid #D8DCE7;
`;

const FillerRow = styled(Column)`
  height: 20px;
  width: calc(100% - 230px);
  padding: 0 40px 20px 40px;
  border-right: 1px solid #D8DCE7;
`;

const RightColumnSmall = styled(RightColumn)`
  min-height: 0px;
  height: 45px;
`;

const LeftColumn = styled(Column)`
  min-height: 85px;
  width: 230px;
  padding: 0 40px 20px 40px;
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #D8DCE7;
  margin-bottom: ${p => p.noMargin ? '0' : '20px'};
`;

const Heading = props => <Txt size='20px' {...props} />;


class TheRest extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Card maxWidth='1200px' minWidth='1080px' padding='0 0 20px 0' margin='0 0 40px 0'>
          <Row>
            <RightColumn>
              <Heading marginTop='40px' marginBottom='20px'>Additional Details</Heading>
            </RightColumn>
            <LeftColumn>
              <Heading marginTop='40px' >Review Updates</Heading>
            </LeftColumn>
          </Row>
          <Row>
            <RightColumn>
              <TextArea name='key_details.raw' width='100%' height='192px' />
            </RightColumn>
          </Row>
          <Row>
            <FillerRow />
          </Row>
          <BottomBorder noMargin />
          <Row>
            <FillerRow />
          </Row>
          <Row>
            <RightColumnSmall>
              <Column width='250px'>
                <Heading>Features</Heading>
              </Column>
            </RightColumnSmall>
          </Row>
          <ToggleRowSmall formName='product-the-rest' name='extra_repayments_allowed' title='Extra Repayment' />
          
          <ToggleRowSmall formName='product-the-rest' name='partial_offset' title='Partial Offset'/>
          <OffsetInputRow formName='product-the-rest' name='full_offset' title=' Full Offset'/>
          {/* <ToggleRowSmall formName='product-the-rest' name='splitting' title='Splitting'/> */}
          <ToggleRowSmall formName='product-the-rest' name='interest_in_advance' title='Interest in Advance'/>
          <ToggleRowSmall formName='product-the-rest' name='portable' title='Portability'/>
          {/* <ToggleRowSmall formName='product-the-rest' name='loanIncrease' title='Loan Increase'/> */}
          <ToggleRowSmall formName='product-the-rest' name='redraw_allowed' title='Redraw'/>
          {/* <ToggleRowSmall formName='product-the-rest' name='pkg_seq' title='Package'/>
          <ToggleRowSmall formName='product-the-rest' name='rate_lock_fee' title='Locked Rate'/> */}

          <Row>
            <FillerRow />
          </Row>
          <BottomBorder noMargin />
          <Row>
            <FillerRow />
          </Row>
          <Row>
            <RightColumnSmall>
              <Column width='250px'>
                <Heading>Loan Types</Heading>
              </Column>
            </RightColumnSmall>
          </Row>
          <ToggleRowSmall formName='product-the-rest' name='bridging_loan' title='Bridging'/>
          <ToggleRowSmall formName='product-the-rest' name='company' title='Company / Trust'/>
          {/* <ToggleRowSmall formName='product-the-rest' name='redraw_allowed' title='Construction'/> */}
          <ToggleRowSmall formName='product-the-rest' name='first_home_buyer' title='First Home Buyer'/>
          <ToggleRowSmall formName='product-the-rest' name='first_property_buyer' title='First Property Buyer'/>
          <ToggleRowSmall formName='product-the-rest' name='is_smsf' title='SMSF'/>
          
          
          {/* <ToggleRowSmall formName='product-the-rest' name='ownerBuilder' title='Owner Builder'/> */}
          {/* <ToggleRowSmall formName='product-the-rest' name='vacantLand' title='Vacant Land'/> */}
          {/* <ToggleRowSmall formName='product-the-rest' name='construction' title='Construction'/> */}
          {/* <ToggleRowSmall formName='product-the-rest' name='loDoc' title='Lo Doc / Alt Doc'/> */}
          {/* <ToggleRowSmall formName='product-the-rest' name='refinance' title='Refinance'/> */}
          <Row>
            <FillerRow />
          </Row>
          <BottomBorder noMargin />
          <Row>
            <FillerRow />
          </Row>
          <Row>
            <RightColumnSmall>
              <Heading>Fee Detail</Heading>
            </RightColumnSmall>
          </Row>
          <TextInputRowSmall formName='product-the-rest' name='annual_fees' title='Annual Fees' bottomRow/>
          <TextInputRowSmall formName='product-the-rest' name='monthly_fees' title='Monthly Fees' bottomRow/>
          <TextInputRowSmall formName='product-the-rest' name='application_fees' title='Application Fees' bottomRow/>
          <TextInputRowSmall formName='product-the-rest' name='rate_lock_fee' title='Lock Rate Fee'/>
          <TextInputRowSmall formName='product-the-rest' name='lender_legals' title='Lender Legals'/>
          {/* <TextInputRowSmall formName='product-the-rest' name='settmentChequeFee' title='Settlement Cheque Fee'/> */}
          <TextInputRowSmall formName='product-the-rest' name='settlement_fees' title='Settlement Fee'/>
          <TextInputRowSmall formName='product-the-rest' name='additional_valuation_fee' title='Additional Val Fee'/>
          <TextInputRowSmall formName='product-the-rest' name='other_establishment_fees' title='Other Establishment Fees'/>
          {/* <TextInputRowSmall formName='product-the-rest' name='settlmentAdminFee' title='Settlement Administration Fee'/> */}
          {/* <TextInputRowSmall formName='product-the-rest' name='titleSearchFee' title='Title Search Fee'/> */}
          <TextInputRowSmall formName='product-the-rest' name='discharge_fee' title='Discharge Fee' bottomRow/>
          <ToggleInputRow formName='product-the-rest' name='includes_1_standard_val' title='Includes one standard valuation' valueChecked='Y' valueUnchecked='0'/>
          {showUnfinished &&
            <Fragment>
              <Row>
                <RightColumn>
                  <Heading>Upfront Fees</Heading>
                  <TextArea name='upfontFeesTxt' width='100%' height='192px' />
                </RightColumn>
              </Row>
              <Row>
                <FillerRow />
              </Row>
              <Row>
                <RightColumn>
                  <Heading>Ongoing Fees</Heading>
                  <TextArea name='ongoingFeesTxt' width='100%' height='192px' />
                </RightColumn>
              </Row>
              <Row>
                <FillerRow />
              </Row>
              <Row>
                <RightColumn>
                  <Heading>Exit Fees</Heading>
                  <TextArea name='exitFees' width='100%' height='192px' />
                </RightColumn>
              </Row>
              
            </Fragment>
          }
          <Row>
            <FillerRow />
          </Row>
          <BottomBorder noMargin />
          <Row>
            <FillerRow />
          </Row> 
          <Row>
            <RightColumnSmall>
              <Column width='250px'>
                <Heading>Account Access Methods</Heading>
              </Column>
            </RightColumnSmall>
          </Row>
          <ToggleRowSmall formName='product-the-rest' name='atm_access' title='ATM Access'/>
          <ToggleRowSmall formName='product-the-rest' name='bpay_access' title='BPAY Access'/>
          <ToggleRowSmall formName='product-the-rest' name='net_access' title='Net Access'/>
          <ToggleRowSmall formName='product-the-rest' name='aust_post_access' title='Australia Post Access'/>
          <ToggleRowSmall formName='product-the-rest' name='branch_access' title='Branch Access'/>
          <ToggleRowSmall formName='product-the-rest' name='phone_access' title='Phone Access'/>

          <Row>
            <FillerRow />
          </Row>
          <BottomBorder noMargin />
          <Row>
            <FillerRow />
          </Row> 
          <Row>
            <RightColumnSmall>
              <Column width='250px'>
                <Heading>Repayment Frequency</Heading>
              </Column>
            </RightColumnSmall>
          </Row>
          <ToggleRowSmall formName='product-the-rest' name='weekly_repayment' title='Weekly'/>
          <ToggleRowSmall formName='product-the-rest' name='fortnightly_repayment' title='Fortnightly'/>
          <ToggleRowSmall formName='product-the-rest' name='monthly_repayment' title='Monthly'/>

          <Row>
            <FillerRow />
          </Row>
          <BottomBorder noMargin />
          <Row>
            <FillerRow />
          </Row> 
          <Row>
            <FillerRow />
          </Row> 
          <Row>
            <RightColumn>
              <Heading>Key Notes</Heading>
              <TextArea name='key_notes.raw' width='100%' height='192px' />
            </RightColumn>
          </Row>

          <Row>
            <FillerRow />
          </Row>
          <BottomBorder />
          <Row>
            <PullRight padding='0 40px 0 0'>
              <ButtonPrimary type='submit' disabled={this.props.pristine || this.props.submitting} width='150px' background='#26BD27'>
                <ButtonSpinner marginRight='15px' show={this.props.submitting}/> 
                Save
              </ButtonPrimary>
            </PullRight>
          </Row>

        </Card>
      </form>
      
    );
  }
}


TheRest = reduxForm({
  form: 'product-the-rest',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,  
})(TheRest);

export default TheRest;