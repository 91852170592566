import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationCardBig } from 'components/TaskCard';
import { Row, Column, Heading } from './ViewClient';


import { requestPatchUser } from 'modules/clients/actions';
import { requestGetApplications } from 'modules/applications/actions';
import { SubmissionError } from 'redux-form';

import Card from 'components/Styled/Card';
import AddDeal from './Forms/AddDeal';
import Divider from 'components/Styled/Divider';

class ReferralView extends Component {

  onSubmit = data => {
    delete data.client.password;
    return new Promise((resolve, reject) => {
      //debugger;
      this.props.requestPatchUser({
        data: { ...data.client, id: this.props.client.id },
        callback: result => {
          if (result.error) {
            if (result.error.message === 'Email already exists')
              reject(new SubmissionError({
                email: 'Email address already taken',
                _error: 'Email address already taken'
              }));
            else
              reject(new SubmissionError({
                _error: result.error.message,
              }));
            return;
          }
          resolve();
        }
      });
    });

  }

  componentDidMount() {
    // const { applications, excludeApplications } = this.props;
    // debugger;
    // if (!excludeApplications && !applications?.list && !applications?.loading)  this.props.requestGetApplications();
  }
  
  render() {
    //debugger;
    const { client, excludeApplications, applicationsList, user } = this.props;

    const clientApplications = Object.values(applicationsList ?? {})
      .filter(x => x.referrerId === client.id);

    console.log('Client:', client);
    return (
      <Card margin="40px 0 0 0" >
        <div style={{ width: '720px', marginLeft: '40px', paddingBottom: '40px' }}>

          {/* <AddDeal/> */}
          
          {!!clientApplications && !!clientApplications.length && <>
            <Heading>Referrals</Heading>
            <Row>
              {clientApplications.map(app => {
                const application = applicationsList?.[app.id];
                if (!application) return null;
                return <>
                  <Column key={app.id} width='33.3%'>
                    <ApplicationCardBig data={{
                      ...application,
                      clientName: application.client.firstName + ' ' + application.client.lastName,
                      brokerName: application.broker?.firstName + ' ' + application.broker?.lastName,
                    }} />
                  </Column>
                </>;
              })}
            </Row>
          </>}
          
          
        </div>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestGetApplications,
  }, dispatch);

const mapStateToProps = ({ clients, applications, user }) => ({ user, client: clients.current, applications: applications, applicationsList: applications?.list });

export default connect(mapStateToProps, mapDispatchToProps)(ReferralView);
