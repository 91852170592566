import { createAction } from 'redux-actions';

export const REQUEST_GET_LENDERS = 'REQUEST_GET_LENDERS';
export const RECEIVE_GET_LENDERS = 'RECEIVE_GET_LENDERS';
export const REQUEST_CREATE_LENDER = 'REQUEST_CREATE_LENDER';
export const RECEIVE_CREATE_LENDER = 'RECEIVE_CREATE_LENDER';
export const REQUEST_UPDATE_LENDER = 'REQUEST_UPDATE_LENDER';
export const RECEIVE_UPDATE_LENDER = 'RECEIVE_UPDATE_LENDER';
export const REQUEST_DELETE_LENDER = 'REQUEST_DELETE_LENDER';
export const RECEIVE_DELETE_LENDER = 'RECEIVE_DELETE_LENDER';

export const requestGetLenders = createAction(REQUEST_GET_LENDERS);
export const receiveGetLenders = createAction(RECEIVE_GET_LENDERS);
export const requestCreateLender = createAction(REQUEST_CREATE_LENDER);
export const receiveCreateLender = createAction(RECEIVE_CREATE_LENDER);
export const requestUpdateLender = createAction(REQUEST_UPDATE_LENDER);
export const receiveUpdateLender = createAction(RECEIVE_UPDATE_LENDER);
export const requestDeleteLender = createAction(REQUEST_DELETE_LENDER);
export const receiveDeleteLender = createAction(RECEIVE_DELETE_LENDER);