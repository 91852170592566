import React, { Component } from 'react';
import { Row, Cell } from './index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestClientLogin } from 'modules/user/actions';
import { clientAppUrl } from 'modules';
import { isIOS } from 'util/getDevice';

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestClientLogin
  }, dispatch);

class ClientTableItemComponent extends Component {

  logInAsClient() {

    let siteDomain = clientAppUrl;

    let windowReference;
    
    if (isIOS) {
      windowReference = window.open('', '_blank');
    }

    this.props.requestClientLogin({
      data: {
        email: this.props.client.email
      },
      callback: (result)=>{
          const url = `${siteDomain}?email=${this.props.client.email}&loginToken=${result.token}&godmode=${result.godmode}&id=${this.props.client.id}`;
        if (isIOS) {
          windowReference.location = url;
        } else {
          window.open(url, '_blank');
        }
      }
    });
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.client.id === nextProps.client.id)
      return false;
    return true;
  }
  render() {
    const { client, onClick, ...props } = this.props;
    let clientStage = () => {
      switch (client.clientStage) {
      case 'applied':
        return <span className='client-stage applied'>Applied</span>;
      case 'registered':
        return <span className='client-stage registered'>Registered</span>;
      case 'settled':
        return <span className='client-stage settled'>Settled</span>;
      case 'eligibility':
        return <span className='client-stage eligibility'>Eligibility</span>;
      case 'unregistered':
        return <span className='client-stage unregistered'>Unregistered</span>;
      case 'abused':
        return <span className='client-stage abused'>Abused</span>;
      default:
        return false;
      }
    };
    return (<Row onClick={onClick} {...props}>
      <Cell width='12%'>{client.lastName || <span className='grey-text'>—</span>}</Cell>
      <Cell width='12%'>{client.firstName || <span className='grey-text'>—</span>}</Cell>
      <Cell width='13%'>{client.phoneNumber && <a href={`tel:${client.phoneNumber}`}>{client.phoneNumber}</a> || <span className='grey-text'>—</span>}</Cell>
      <Cell width='24%'>{client.email && <a href={`mailto:${client.email}`}>{client.email}</a> || <span className='grey-text'>—</span>}</Cell>
      <Cell width='12%'>{clientStage() || <span className='grey-text'>—</span>}</Cell>
      <Cell width='15%'>{client.lastLogin && `${new Date(client.lastLogin).toLocaleTimeString('en-AU', { hour: '2-digit', minute: '2-digit' })}, `
        + `${new Date(client.lastLogin).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}`
        || <span className='grey-text'>—</span>}
      </Cell>
      <Cell width='15%'>
        <button onClick={()=>this.logInAsClient()} className='btn btn-secondary'>Login</button>
      </Cell>
    </Row>);
  }
}

export const ClientTableItem = connect(null, mapDispatchToProps) (ClientTableItemComponent);

