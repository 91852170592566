import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { RichTextArea } from 'components/Form/RichTextInput';
import { Form, reduxForm, reset } from 'redux-form';
import { ButtonPrimary, ButtonImageLeft } from 'components/Styled/Button';
import { requestUpdateApplications } from 'modules/applications/actions';
import { getQueryVariables } from 'util/index';
import { emailService, uploadUrl, verificationService } from 'modules';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import Label from 'components/Styled/Label';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import html from 'util/html';

import imgArchive from 'img/button/archive.svg';
import { NumberInput, MoneyInput, Checkbox }  from 'components/Form';
import { Img } from 'components/Common/Image';
import Loading from 'components/Styled/ImageLoading';

const $ = window.$;

const DEAL_SETTLED_STAGE_ID = 13;
 
const DealNotes = ({ location, handleSubmit, initialValues }) => {
  const { openModal } = useContext(modalContext);
  const messageRef = useRef(null);
  let { id } = getQueryVariables(location.search);
  id = parseInt(id);
  const dispatch = useDispatch();
  const user = useSelector( state => state.user );
  const deal = useSelector( state => state.applications.list[parseInt(id)]);
  const note = useSelector( state => state.form['deal-notes']?.values?.body);
  const notepad = useSelector( state => state.form['deal-notes']?.values?.notepad);
  if (!deal) {
    return '';
  }
  const notes = deal.notes;
  const isProfessionalReferrer = deal.referrer?.isProfessionalReferrer;

  const saveNotepad = ({ notepad }) => {
    dispatch(requestUpdateApplications({ 
      id, 
      notepad,
      notepadEditDate: (new Date()).toISOString(),
    }));
  };

  const addNote = ({ body, visibleToReferrer }) => {
    dispatch(requestUpdateApplications({ 
      id, 
      note: { 
        visibleToReferrer,
        body, 
        brokerId: user.profile?.id 
      } }));
    dispatch(reset('deal-notes'));

    if (visibleToReferrer && deal.referrer?.email) {
      verificationService.create({
        type: 'referral-comment',
        applicationId: id,
        email: deal.referrer?.email,
        note: body,
      });
    }
  }; 

  const editNote = ({ date, visibleToReferrer }) => {
    dispatch(requestUpdateApplications({ 
      id, 
      note: { 
        date,
        visibleToReferrer,
      }}));
  };

  // // scroll to the most recent notes
  // useEffect(() => {
  //   const messages = messageRef.current;
  //   messages.scrollTop = messages.scrollHeight;
  // }, []);
  return (
    <MainContainer> 
      <Form onSubmit={handleSubmit(saveNotepad)}>
        <Heading>Deal Overview</Heading>
        <Row style={{ margin: '10px 0 40px 0'}}>
          <Column style={{ width: '80%'}}>
            <RichTextArea name='notepad' allowAnyPaste/>
          </Column>
          <Column style={{ width: '16%', marginLeft: '4%'}}>
            <ButtonPrimary type='submit' width='100%' disabled={notepad === initialValues.notepad}>Save</ButtonPrimary>
            {deal.notepadEditDate && <NotepadDate>{moment(deal.notepadEditDate).format('h:mma, Do MMM, YYYY')}</NotepadDate>}
          </Column>
        </Row>
      </Form>
      
      <Form onSubmit={handleSubmit(addNote)}>
        <Heading style={{ marginBottom: '10px' }}>Deal Notes</Heading>
        {isProfessionalReferrer &&
          <div style={{ marginBottom: '10px', marginTop: '20px'}}>
            <label style={{ fontWeight: '400'}}>
              <Checkbox name='visibleToReferrer'/> 
              <span style={{ position: 'relative', top: '1px'}}>Note visible to referral partner</span>
            </label>
            
          </div>
        }
        <Row>
          <Column style={{ width: '80%'}}>
            <RichTextArea name='body'/>
          </Column>
          <Column style={{ width: '16%', marginLeft: '4%'}}>
            <ButtonPrimary type='submit' disabled={!note} width='100%'>Add Note</ButtonPrimary>
          </Column>
        </Row>
      </Form>
      <Messages ref={messageRef} overflow={!!notes}>
        {notes ? 
          notes
            .sort((a, b) => moment(b.date) - moment(a.date))
            .map((note, index) => <MessageComponent key={index} index={index} note={note} editNote={editNote} application={deal}/>)
          : 
          <Loading/>
        }
      </Messages>
      
      {/* {deal.list === DEAL_SETTLED_STAGE_ID && deal.commissionAmount == null && // TODO deal must also have a referrer?
        <Row style={{ marginTop: '40px', marginBottom: '70px'}}>
          <Column style={{ width: '35%'}}>
            <CommissonButton width='100%' onClick={() => openModal({ 
              component: CommissonModal, 
              props: { 
                addNote,
                type: 'add',
                deal,
              } 
            })}>Add commission payment</CommissonButton>
          </Column>
          <Column style={{ width: '65%'}}>
            <CommissionText>
              As this deal has now settled and has a referrer, we can note that a commission payment has been made.
            </CommissionText>
          </Column>
        </Row>
      }
      
      {deal.commissionAmount != null &&
      <Row style={{ marginTop: '40px', marginBottom: '70px'}}>
        <Column style={{ width: '35%'}}>
          <CommissonButton width='100%' onClick={() => openModal({ 
            component: CommissonModal, 
            props: { 
              addNote,
              type: 'edit',
              deal,
              initialValues: {
                commissionAmount: deal.commissionAmount, 
              },
            } 
          })}>Edit commission payment</CommissonButton>
        </Column>
      </Row> 
      }  */}

    </MainContainer>
  );
}; 

export default reduxForm({ form: 'deal-notes' })(DealNotes);


const MessageComponent = ({ note, index, editNote, application }) => {
  const brokers = useSelector( state => state.brokers );
  const user = useSelector( state => state.user );
  const [ menuOpen, setMenuOpen ] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const toggleVisibility = () => {
    editNote({
      date: note.date,
      visibleToReferrer: !note.visibleToReferrer
    });
    setMenuOpen(false);
  };

  let broker, avatarUrl, isSelf, isReferrer;
            
  let isWarning = false;
  if (note.brokerId === 'system') {
    broker = { firstName: 'System', lastName: '' }; 
    avatarUrl = require('img/avatars/system.png');
  } else if (note.brokerId === 'system warning') {
    broker = { firstName: 'System Warning', lastName: '' }; 
    avatarUrl = require('img/avatars/system.png');
    isWarning = true;
  } else if (note.brokerId === 'referrer') {
    broker = { firstName: application.referrer.firstName, lastName: application.referrer.lastName, prefix: '(Client Referer)' }; 
    isReferrer = true;
  } else {
    broker = brokers.find(x => x.id === note.brokerId);
    avatarUrl = `${uploadUrl}/brokers/small${broker?.id ?? 'noimage'}.png`;
    isSelf = broker?.id === user.profile?.id;
  } 

  if (!broker) {
    broker = { firstName: 'Unknown Broker', lastName: '' };
  }

  const checkIfLeaveMenu = e => {
    if(!$(e.target).parents('.menu-button-' + index).length) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    $(window).on('click', checkIfLeaveMenu);
    return () => {
      $(window).off('click', checkIfLeaveMenu);
    };
  });

  const processHtml = note.brokerId === 'system' ? x => x : sanitizeHtml;

  return <>
    <Message isSelf={isSelf} visibleToReferrer={note.visibleToReferrer} isWarning={isWarning} isReferrer={isReferrer}>
      <HeaderRow>
        <Header>
          <Avatar src={avatarUrl} objectFit={'cover'} loader={<Fallback broker={broker} />} fallback={<Fallback broker={broker} />}/>
          <Sender>{broker.prefix} {broker.firstName} {broker.lastName}</Sender>
          <Time>{moment(note.date).format('h:mma, Do MMM, YYYY')}</Time>
        </Header>
      </HeaderRow>
      {!isWarning && note.brokerId !== 'system' && note.brokerId !== 'referrer' &&
        <div className={'menu-button-' + index} style={{ float: 'right' }}>
          <ButtonDots active={menuOpen} onClick={toggleMenu} isWhite={isSelf || note.visibleToReferrer || isWarning}/>
          {menuOpen && (!note.visibleToReferrer ?
            <Menu width='100'>
              <MenuOption onClick={toggleVisibility}>Show referrer</MenuOption>
            </Menu>
            :
            <Menu width='125'>
              <MenuOption onClick={toggleVisibility}>Hide from referrer</MenuOption>
            </Menu>
          )}
          
        </div>
      }
      
      
      <Body dangerouslySetInnerHTML= {{ __html: processHtml(note.body) }} />
    </Message>
  </>;
};

let CommissonModal = ({ modal, type, dirty, deal, handleSubmit, addNote, initialValues }) => {
  const dispatch = useDispatch();

  const commissionAmount = useSelector(state => state.form['commission-modal']?.values?.commissionAmount);
  const formNote = useSelector(state => state.form['commission-modal']?.values?.note);
  const initialCommissionAmount = initialValues?.commissionAmount;

  const changeCommission = ({ commissionAmount, note }) => {
    addNote({ body: note });
    dispatch(requestUpdateApplications({ 
      id: deal.id, 
      commissionAmount,
    }));
  };


  const onSubmit = ({ commissionAmount, note }) => {
    const body = html`
      <div><b>${type === 'add' ? 'Commission Paid' : 'Commission Amount Changed'}: $${commissionAmount}</b></div>
      <div>${note ?? ''}</div>
    `;
    
    changeCommission({ commissionAmount, note: body });
    modal.close();
  };

  const cancelCommission = () => {
    const body = html`
      <div><b>Commission Cancelled</b></div>
      <div>${formNote ?? ''}</div>
    `;
    changeCommission({ commissionAmount: null, note: body});
    modal.close();
  };
  
  return <>
    <ModalBox width='550px'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row style={{ marginBottom: '20px' }}>
          <ModalHeading>{(type === 'add') ? 'Add' : 'Edit'} Commission Payment</ModalHeading>
        </Row>
        <Row style={{ marginBottom: '20px' }}>
          <Column style={{ width: '180px' }}>
            <Label>Commission amount paid</Label>
            <MoneyInput name='commissionAmount' />
          </Column>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Label>Additional Notes</Label>
          <RichTextArea name='note' />
        </Row>
        <Row>
          <ButtonPrimary type='button' background='#FF4E4C' width='45%' margin='0 10% 0 0' onClick={cancelCommission}>Cancel Commission</ButtonPrimary>
          <ButtonPrimary type='submit' disabled={!dirty || initialCommissionAmount === commissionAmount } width='45 %'>
            {(type === 'add') ? 'Add Commission Payment' : 'Apply Change to Commission'}
          </ButtonPrimary>
        </Row>
      </Form>
    </ModalBox>
  </>;
};

CommissonModal = reduxForm({ form: 'commission-modal' })(CommissonModal);

const NotepadDate = styled.div`
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  color: #858997;
  text-align: center;
`;

const ModalHeading = styled.h1`
  font-weight: 700;
  font-size: 24px;
`;
const CommissonButton = styled(ButtonImageLeft)`
  padding-left: 40px;
  padding-right: 10px;
  margin-right: 20px;
  background-size: 20px 20px;
  background-position-x: 16px;
  background-position-y: 8px;
  background-image: url(${imgArchive});
  background-repeat: no-repeat;
`;

const CommissionText = styled.div`
  padding-left: 20px;
  padding-top: 2px;
  line-height: 150%;
  font-size: 13px;
`;

const MainContainer = styled.div`
  margin-top: -15px;
  min-width: 700px;
  max-width: 900px;
`;

const Messages = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  max-height: 650px;
  padding-right: 20px;
  padding-top: 10px;
  ${p => p.overflow && css`
    overflow-y: auto;
  `}
`;


const Heading = styled.h1`
  font-size: 17px;
  font-weight: bold;
`;

const Row = styled.div`
  /* margin-top: 10px;
  display: flex;
  flex-direction: row-reverse; */
`;

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
`;

const Body = styled.div`
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    /* margin-top: 20px; */

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
      /* margin-bottom: 15px; */
    }
  }

  ol {
    /* margin-top: 20px; */

    li {
      list-style: decimal inside !important;
      margin-left: 10px;
      /* margin-bottom: 15px; */
    }
  }
`;

const Header = styled.div``;
const HeaderRow = styled.div``;
const Avatar = styled(Img)``;
const Sender = styled.span``;
const Time = styled.span``;

const Fallback = ({broker}) => {
  if (!broker) return <FallbackAvatar />;
  return <FallbackAvatar>
    <div>{broker.firstName.charAt(0)}{broker.lastName.charAt(0)}</div>
  </FallbackAvatar>;
};

const FallbackAvatar = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #2D91F9;
  
  text-align: center;
  line-height: 26px;
  color: white;
  font-size: 12px;
  font-weight: 700;
`;

const Message = styled.div`
  margin-bottom: 20px;

  ${HeaderRow} {
    display: flex;
    margin-bottom: 10px;
    ${p => p.isSelf && css`
      flex-direction: row-reverse;
    `}
  }

  ${Avatar} {
    display: inline-block;
    object-fit: cover;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #919191;
    position: relative;
    top: 7px;
  }

  ${Sender} {
    position: relative;
    //top: -7px;
    font-size: 12px;
    font-weight: bold;
    margin: 0 10px 0 6px;
  }

  ${Time} {
    position: relative;
    //top: -7px;
    font-size: 12px;
    color: #858997;
  }

  ${Body} {
    background-color: #F1F0F0;
    border-radius: 12px;
    width: 100%;
    font-size: 15px;
    padding: 12px;
    line-height: 150%;

    ${p => p.isSelf && css`
      background-color: #2291FF;
      color: white;
    `}

    ${p => p.isWarning && css`
      background-color: #FF4E4C;
      color: white;
    `}

    ${p => p.visibleToReferrer && css`
      background-color: #9013FE;
      color: white;
    `}

    ${p => p.isReferrer && css`
      background-color: #00B601;
      color: white;
    `}
  }
`;

const ButtonDots = styled.div`
  float: right;
  ${p => p.isWhite ? css`
    background-image: url(${require('img/button/tripledot_white.png')});
  ` : css`
    background-image: url(${require('img/button/tripledot.png')});
  `}
  
  background-size: 16px 5px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: rgba(0,0,0,0);
  height: 25px;
  width: 40px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  ${props => props.active && css`
    border: 1px solid #D8DCE7;
    background-image: url(${require('img/button/tripledot.png')});
    background-color: white;
  `};

  &:hover {
    border: 1px solid #D8DCE7;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
    border-radius: 4px 4px 4px 4px;
  }
`;

const Menu = styled.ul`
  position: relative;
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
  border-radius: 4px 0 4px 4px;
  right: 0px;
  top: 20px;
  padding: 0px 10px;
  width: ${p => p.width || 150}px;
  text-align: left;
  z-index: 51;
  height: 35px;

  ${props => props.location && css`
    right: none;
    left: 0px;
    border-radius: 0 4px 4px 4px;

    &:after {
      right: none;
      left: -1px;
    }
  `}
  
  ${props => props.status && css`
    display: block;
  `};

  &:after {
    content: '';
    position: absolute;
    height: 3px;
    background-color: #fff;
    width: 40px;
    right: -1px;
    top: -3px;
    border-right: 1px solid #D8DCE7;
    border-left: 1px solid #D8DCE7;
  }
`;

const MenuOption = styled.li`
  position: absolute;
  font-size: 12px;
  border-bottom: 1px solid #D8DCE7;
  padding: 10px 0;
  cursor: pointer;
  text-align: left;

  /* color: #FF4E4C;

  &:hover {
    color: #EC2422;
  } */


  &:last-of-type {
    border-bottom: 0;
  }
`;
