import React from 'react';
import styled from 'styled-components';
import imgSortUnselected from 'img/button/sort-unselected.png';
import imgSortUp from 'img/button/sort-up.png';
import imgSortDown from 'img/button/sort-down.png';


const Icon = styled.img`
  position: relative;
  width: 6px;
  margin-left: 5px;
`;

const IconUnselected = styled(Icon)
  .attrs({src: imgSortUnselected})
  `bottom: -2px;`;

const IconDown = styled(Icon)
  .attrs({src: imgSortDown})
  `bottom: 1px;`;

const IconUp = styled(Icon)
  .attrs({src: imgSortUp})
  `bottom: 1px;`;

export const HeaderCell = ({ attributeName, context, width, children, ...props }) => (
  <div className='table-cell header' onClick={async () => {
    if (context.state.sortField === attributeName) {
      await context.setState({ sortDirection: -context.state.sortDirection });
    }
    else {
      await context.setState({ sortField: attributeName, sortDirection: 1 });
    }
    context.filterResults();
    }} 
    style={{ width }} 
    {...props}>
    <span>
      {children}
      {context.state.sortField !== attributeName ?
        null
        : context.state.sortDirection === 1 ?
          <IconDown/>
          : <IconUp/>
      }
    </span>
  </div>);