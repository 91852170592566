import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Column } from 'components/Styled/Layout';
import Card from 'components/Styled/Card';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import TextArea from 'components/Styled/TextArea';
import { CheckBox } from 'components/Styled/CheckBox';
import TextInput from 'components/Styled/TextInput';
import Label from 'components/Styled/Label';
import { TabBar, Tab } from 'components/Styled/TabBar';
import IconImage from 'components/Styled/IconImage';


import imgEditNote from 'img/icons/edit-square-dark.png';
import imgEmail from 'img/icons/email-dark.png';
import imgToDo from 'img/icons/todo-dark.png';
import imgEditNoteBlue from 'img/icons/edit-square-blue.png';
import imgEmailBlue from 'img/icons/email-blue.png';
import imgToDoBlue from 'img/icons/todo-blue.png';
import imgDownArrow from 'img/button/down-blue.png';

export class CreateBox extends Component {
  constructor() {
    super();
    this.state = {
      currentTab: 'note',
    };
  }
  render() {
    const { currentTab } = this.state;
    return (
      <Card minWidth='650px' maxWidth='800px' padding='0'>
        <TabBar height={60} tabWidth='100px' tabMargin={15} marginBottom={20}>
          <Tab className={currentTab === 'note' ? 'current' : ''} onClick={() => this.setState({ currentTab: 'note' })}>
            <IconImage src={currentTab === 'note' ? imgEditNoteBlue : imgEditNote} width={16} marginRight={8} top={2} />
            Add Note
          </Tab>
          <Tab className={currentTab === 'task' ? 'current' : ''} onClick={() => this.setState({ currentTab: 'task' })}>
            <IconImage src={currentTab === 'task' ? imgToDoBlue : imgToDo} width={16} marginRight={8} top={2} />
            Add Task
          </Tab>
          <Tab className={currentTab === 'email' ? 'current' : ''} onClick={() => this.setState({ currentTab: 'email' })}>
            <IconImage src={currentTab === 'email' ? imgEmailBlue : imgEmail} width={16} marginRight={8} top={2} />
            Send Email
          </Tab>
        </TabBar>
        {(currentTab === 'note') && <AddNote />}
        {(currentTab === 'task') && <AddTask />}
        {(currentTab === 'email') && <SendEmail />}
      </Card>);
  }
}

const TabHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const AddNote = () => (
  <div style={{ padding: '20px 40px 20px 40px' }}>
    <TabHeading>New Note</TabHeading>
    <TextArea />
    <Row>
      <div style={{ float: 'left', margin: '30px 20px 0 0' }}>
        <CheckBox title='Notify Broker' checked />
      </div>
      <div style={{ float: 'left', margin: '30px 20px 0 0' }}>
        <CheckBox title='Notify Client' />
      </div>
      <div style={{ float: 'right', margin: '20px 0 0 0' }}>
        <ButtonPrimary>Add Note</ButtonPrimary>
      </div>
    </Row>
  </div>
);

const AddTask = () => (
  <div style={{ padding: '20px 40px 20px 40px' }}>
    <TabHeading>Add Task</TabHeading>
    <Label>Task Name</Label>
    <TextInput style={{margin:'0 0 30px 0'}}/>
    <Label>Task Description</Label>
    <TextArea/>
    <Row>
      <div style={{ float: 'left', margin: '10px 20px 0 0' }}>
        <Label>Due Date (optional)</Label>
        <TextInput width='125px'/>
      </div>
      <div style={{ float: 'left', margin: '10px 40px 0 0' }}>
        <Label>Due Hour (optional)</Label>
        <TextInput width='125px'/>
      </div>
      <div style={{ float: 'left', margin: '46px 20px 0 0' }}>
        <CheckBox title='Notify Broker' checked />
      </div>
      <div style={{ float: 'right', margin: '36px 0 0 0' }}>
        <ButtonPrimary>Add Task</ButtonPrimary>
      </div>
    </Row>
  </div>
);


const LabelledInputDiv = styled(TextInput)`
  padding-right: 0 !important;
  margin-bottom: 20px;

  &:focus-within {
    border: 1px solid #2291FF;
    box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20);
  }

  &:before {
    font-size: 14px;
    color: #727C8F;
    content: ${({ label }) => `'${label}'`};
  }

  input {
    background: none !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 10px;
    box-shadow: none !important;
    height: 38px !important;
  }
`;

const LabelledInput = ({ label, ...props}) => (
  <LabelledInputDiv as='div' label={label}>
    <input type='text' {...props}/>
  </LabelledInputDiv>
);


const SendEmail = () => (
  <div style={{ padding: '20px 40px 20px 40px' }}>
    <TabHeading>New Email</TabHeading>
    <Row>
      <Column width='calc(100% - 60px)' style={{marginRight: '20px'}}>
        <LabelledInput label='To: '/>
      </Column>
      <Column width='40px'>
        <ButtonPlain width='40px'><IconImage src={imgDownArrow} width={12}/></ButtonPlain>
      </Column>
      <Column>
        <LabelledInput label='Subject: '/>
      </Column>
      <Column>
        <TextArea />
      </Column>
    </Row>
  
  
    <Row>
      <div style={{ float: 'left', margin: '30px 20px 0 0' }}>
        <CheckBox title='Notify Broker' checked />
      </div>
      <div style={{ float: 'left', margin: '30px 20px 0 0' }}>
        <CheckBox title='Notify Client' />
      </div>
      <div style={{ float: 'right', margin: '20px 0 0 0' }}>
        <ButtonPrimary>Send Email</ButtonPrimary>
      </div>
    </Row>
  </div>
);