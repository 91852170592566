import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import calculateLoanValues from 'util/calculateLoanValues';
import { withRouter } from 'react-router-dom';
import BankLogo from 'components/Common/BankLogo';

class SmallProductBoxPure extends Component {
  render() {
    let product = calculateLoanValues(this.props.product);
    

    return (
      <div>
        <div className="product-select" onClick={() => this.props.history.push('/products/view?id=' + product.id)}>
          <BankLogo product={product}/>
          <h6>{product.product_name}</h6>

          <div className="interest-rate">
            <div className="variable-rate">
              <div className="rate">{product.iir}<span>% p.a.</span></div>
              <div className="rate-description">{product.rateDescriptionYears}</div>
            </div>
            <div className="comparison-rate">
              <div className="rate">{product.comparisonRate}<span>% p.a.</span></div>
              <div className="rate-description">Comparison Rate</div>
            </div>
          </div>

          <div className="principal-interest">
            <h5>{product.repaymentType}</h5>
            <div className="description">Repayment Type</div>
          </div>

          <ul className="statistics">
            <li className={(product.full_offset === "Y").toString()}><span>Offset</span></li>
            <li className={(product.redraw_allowed === "Y").toString()}><span>Redraw</span></li>
            <li className={(product.extra_repayments_allowed === "Y").toString()}><span>Early Repayments</span></li>
          </ul>


          <div className="fees">
            <div className="upfront-fee">${product.approxUpfrontFee}<span>Approx Upfront</span></div>
            <div className="annual-fee">${product.ongoing_fees}<span>Annual Fee</span></div>
          </div>
        </div>
      </div>
    )
  }
}

export const SmallProductBox = withRouter(SmallProductBoxPure);

export class MediumProductBox extends Component {
  constructor(props) {
    super(props);

    this.state = { removeToggle: false };
  }

  removeLoan() {
    this.setState((prevState) => { return { ...prevState, removeToggle: !prevState.removeToggle } });
  }

  render() {
    return (
      <div className='product'>
        <div className='product-item'>
          {(!this.state.removeToggle ?
            <div className='active'>
              <button onClick={() => this.removeLoan()} className='close'></button>

              <div className='favourites'></div>

              <img className='company-logo' src={require(`img/temp/lender1.png`)} alt='company-logo' />
              <h6>Offset Home Loan Package</h6>

              <div className='interest-rate'>
                <div className='variable-rate'>
                  <div className='rate'>3.89<span> % p.a.</span></div>
                  <div className='description'>Variable</div>
                </div>
                <div className='comparison-rate'>
                  <div className='rate'>3.89<span> % p.a.</span></div>
                  <div className='description'>Comparison</div>
                </div>
              </div>

              <div className='principal-interest'>
                <h5>Principal & Interest</h5>
                <div className='description'>Repayment Type</div>
              </div>

              <ul className='statistics'>
                <li className={'false'}>Offset</li>
                <li className={'true'}>Redraw</li>
                <li className={'false'}>Early Repayments</li>
              </ul>

              <div className='fees'>
                <div className='upfront-fee'>$210<span>Upfront fee</span></div>
                <div className='annual-fee'>$210<span>Annual Fee</span></div>
              </div>

              <div className='more-info'>

                <button className='eligibility'>Check Eligiblity</button>
              </div>

            </div>
            :
            <div className='remove'>
              <h2>Are you sure<br />you want to remove this loan?</h2>
              <div className='remove-navigation'>
                <button className='cancel-button' onClick={() => this.removeLoan()} >Cancel</button>
                <button className='remove-button' >Remove</button>
              </div>
            </div>
          )}



        </div>
      </div>
    );
  }
}

export class ExtraSmallProductBox extends Component {
  render() {
    return (
      <div className='product-select'>
        <img className='company-logo' src={require(`img/temp/lender1.png`)} alt='company-logo' />
        <h6>Offset Home Loan Package</h6>

        <div className='interest-rate'>
          <div className='variable-rate'>
            <div className='rate'>3.79<span>% p.a.</span></div>
            <div className='rate-description'>Variable Rate</div>
          </div>
          <div className='comparison-rate'>
            <div className='rate'>3.79<span>% p.a.</span></div>
            <div className='rate-description'>Comparison Rate</div>
          </div>
        </div>
      </div>
    );
  }
}
