import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getQueryVariables } from 'util/index';

import { Row, PullRight, PullLeft } from 'components/Styled/Layout';

import BackButton from 'components/Common/BackButton';
import { SearchBar } from 'components/Form';
import Text from 'components/Styled/Text';
import { ButtonPlain } from 'components/Styled/Button';
import { Table, SortableHeaderCell as HeaderCell, tableStyle, TableHeader, TableRow, TableCell } from 'components/Table';

import { requestFindClients } from 'modules/clients/actions';

const initialPageSize = 50;
const pageSize = 20;
const bottomLoadHeight = 1200;

class ClientSearch extends Component {
  constructor() {
    super();
    this.state = {
      clientsLoaded: false,
      searchText: '',
      sortDirection: 1,
      sortField: 'lastName'
    };
  }
  componentWillMount = () => {
    
    if (this.props.clients.length > 0) {
      this.setState({ clientsLoaded: true});
      return;
    }

    this.props.requestFindClients({
      query: {
        limit: initialPageSize,
        skip: 0,
        stages: [],
        search: '',
        sortField: 'lastName',
        sortDirection: 1,
      },
      callback: () => this.setState({ clientsLoaded: true }),
    });
  }


  async filterResults() {
    const {searchText, sortDirection, sortField} = this.state;
    this.props.requestFindClients({
      query: {
        limit: initialPageSize,
        skip: 0,
        stages: [],
        search: searchText,
        sortField,
        sortDirection,
      },
    });
  }

  onSort = ({field, direction}) => {
    this.setState({ sortField: field, sortDirection: direction }, this.filterResults);
  }



  render() {
    const { id } = getQueryVariables(this.props.location.search);
    return (
      <div>
        <Row>
          <PullLeft>
            <BackButton margin='15px 0 40px 0'/>
          </PullLeft>
          <PullRight>
            <SearchBar 
              placeholder='Search'
              msDebounce={300} 
              onChange={text => this.setState({searchText: text}, this.filterResults)} />
          </PullRight>
        </Row>
        <Text size='18px'>Select Client</Text>

        <div className='client-search' style={{ margin: '0 -40px' }}>
          <ClientTable onSort={this.onSort}>
            <Header />
            {this.state.clientsLoaded && this.props.clients.list.list.map((client, index) =>
              <ClientTableRow key={index} client={client}/>
            )}
          </ClientTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({clients}) => ({clients});
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { requestFindClients },
    dispatch
  );

export default  connect(mapStateToProps, mapDispatchToProps) (ClientSearch);

const ClientTable = (props) => (
  <Table 
    width='100%' 
    sortField='lastName' 
    className='lender-table' 
    styling={tableStyle.checked}
    {...props}/>
);

const ClientTableRowBase = ({ client, history }) => (
  <TableRow>
    <TableCell>{client.lastName}</TableCell>
    <TableCell>{client.firstName}</TableCell>
    <TableCell>—</TableCell>
    <TableCell>{client.address}</TableCell>
    <TableCell><ButtonPlain width='100%' onClick={history.goBack}>Select</ButtonPlain></TableCell>
  </TableRow>
);

const ClientTableRow = withRouter(ClientTableRowBase);


export const Header = () => (
  <TableHeader>
    <TableRow>
      <HeaderCell width='21%' fieldName='lastName'>Last Name</HeaderCell>
      <HeaderCell width='21%' fieldName='firstName'>First Name</HeaderCell>
      <HeaderCell width='21%' fieldName='birthDate'>Date of Birth</HeaderCell>
      <HeaderCell width='22%' fieldName='address'>Address</HeaderCell>
      <TableCell width='15%'></TableCell>
    </TableRow>
  </TableHeader>
);