import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PathNavLink } from 'components/Common/PathNavLink.jsx';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import { requestPatchUser, requestCreateLoan } from 'modules/clients/actions';
import { getQueryVariables } from 'util/index';
import { ButtonImageLeft, ButtonPrimary } from 'components/Styled/Button';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import Card from 'components/Styled/Card';
import AddDebt from '../Forms/AddDebt';
import AddSplit from '../Forms/AddSplit';
import AddProperty from '../Forms/AddProperty';

import { requestUser } from 'modules/clients/actions';
const $ = window.$;


class DeletedItems extends Component {

  state = {
    newLoanLoading: false,
  }

  changeOpenStateTo = newState => {
    const { loans, houses } = this.props;

    const deletedLoans = (loans || []).filter(l => l.isDeleted);
    const deletedHouses = (houses || []).filter(h => h.isDeleted);

    for (let loan of deletedLoans) {
      if (loan.displayOpen === newState) continue;
      this.props.requestPatchUser({
        data: { loan: { id: loan.id, displayOpen: newState }, id: this.props.client.id },
      });
    }

    for (let house of deletedHouses) {
      if (house.displayOpen === newState) continue;
      this.props.requestPatchUser({
        data: { house: { id: house.id, displayOpen: newState }, id: this.props.client.id },
      });
    }
  }

  render() {
    const { loans, houses } = this.props;
    let groups = [];
    (houses || [])
      .filter(h => !h.isDeleted)
      .forEach(house=>{
        if (house.group) {
          let g = groups.find(group=>group.id === house.group);
          if (!g) {
            groups.push({
              id: house.group,
              propertyGroup: 'Group ' + house.group,
              properties: [house],
              totalValue: house.value || 0,
            });
          } else {
            g.properties.push(house);
            g.totalValue += house.value || 0;
          }
        } else {
          groups.push({
            id: house.id,
            propertyGroup: house.name,
            properties: [house],
            totalValue: house.value,
          });
        }
    });

    

    (loans || [])
      .filter(l => !l.isDeleted)
      .forEach(loan => {
        if (loan.security) {
          let group = groups.find(g => g.id.toString() === loan.security.toString());
          if (group) {
            if (group.loans) {
              group.loans.push(loan);
              group.totalBalance = loan.balance || 0;
            }
            else {
              group.loans = [loan];
              group.totalBalance = loan.balance || 0;
            }
          }
        }
    });
    
    (loans || []).filter(l => !l.isDeleted).forEach(loan => {
      
      if (loan.security) {
        let group = groups.find(g => g.id.toString() === loan.security.toString());
        if (group) {
          if (group.loans) group.loans.push(loan);
          else group.loans = [loan];
        }
      }
    });

    const deletedLoans = (loans || []).filter(l => l.isDeleted);
    const deletedHouses = (houses || [])
      .filter(h => h.isDeleted)
      .map(house => ({...house, type: 'house' }));

    const allDeleted = [...deletedHouses, ...deletedLoans];

    console.log("deleted items:", allDeleted);

    return (
      <div>
        <Card borderTopLeftRadius="0px" >
          <div className="general-overview">
            <img className="image" src={require('../../../img/illustrations/trash.jpg')} />
          </div>
        </Card>
        {!! allDeleted.length &&
          <div className="expand-close-buttons">
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(true)}><span style={{ backgroundImage: `url(${require('../../../img/button/down.png')})` }}>Expand All</span></ButtonImageLeft>
            <ButtonImageLeft onClick={() => this.changeOpenStateTo(false)}><span style={{ backgroundImage: `url(${require('../../../img/button/up.png')})` }}>Collapse All</span></ButtonImageLeft>
          </div>
        }
        
          {allDeleted
            .map( 
              item => {
                if (item.type === 'other') return (
                  <Card key={item.id} maxWidth="1000px" margin="20px 0 0 0" className={'form-card ' + (item.deleting ? 'card-fade-out add-property ' : 'add-property-card ') + (item.displayOpen ? '' : 'collapsed')}>
                    <AddDebt loan={item} key={'debt' + item.id} initialValues={item} form={'addDebt' + item.id}/>
                  </Card>
                ) 
                else if (item.type === 'mortgage') return (
                  <SplitBox loan={item} houses={this.props.houses} key={'loan' + item.id} groups={groups} />
                )
                else if (item.type === 'house') return (
                  <PropertyBox property={item} key={'house' + item.id}/>
                )
              })
          }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestPatchUser,
    requestUser,
    requestCreateLoan,
  }, dispatch);

const mapStateToProps = ({ clients }) => ({ client: clients.current, loans: clients.current.loans, houses: clients.current.houses });

export default connect(mapStateToProps, mapDispatchToProps)(DeletedItems);

function formatNumber(n = 0, d = 0) {
  if (!parseInt(n)) return '-'
  return parseInt(n)
    .toFixed(d)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class SplitBox extends Component {
  render() {
    let { loan, houses, groups } = this.props;
    if (loan.fixedRateExp) {
      const date = new Date(loan.fixedRateExp)
      loan.fixedRateExp = `${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`;
    }

    loan = {
      remainingTermUnit: 'Years',
      repaymentFrequency: 'Monthly',
      ...loan,
    }
    return (
      <Card maxWidth="1000px" margin="20px 0 0 0" className={ 'form-card ' + (((loan.deleting ? 'card-fade-out add-loan ' : '') + (loan.changingSecurity ? 'card-swipe-left ' : '')) || 'add-loan-card ') + (loan.displayOpen ? '' : 'collapsed') }>
        <AddSplit 
          split={loan} 
          form={'addSplit' + loan.id} 
          initialValues={loan} 
          houses={houses} 
          groups={groups} 
          selectedGroups={this.props.selectedGroups}
          
        />
      </Card>
    );
  }
}

class PropertyBox extends Component {
  render() {
    let house = this.props.property
    const flags = JSON.parse(house.flags)

    house = {
      ...house,
      ['future-purchase']: flags.includes('Future Purchase'),
      ['cross-collateralise']: flags.includes('Cross-collateralise'),
      ['being-sold']: flags.includes('Being Sold'),
      address: { address: house.address, placeId: house.placeId, locale: house.locale },

    }
    return (
      <Card maxWidth="1000px" margin="20px 0 0 0" className={'form-card ' + (house.deleting ? 'card-fade-out add-property ' : 'add-property-card ') + (house.displayOpen ? '' : 'collapsed')}>
        <AddProperty property={house} form={'addProperty' + this.props.property.id} initialValues={house} />
      </Card>
    );
  }
}