import React from 'react';
import styled from 'styled-components';

import { TableContext } from './TableContext';
import{ TableCell } from './';
import { TableCell as TableCellBlock } from './TableBlock';

import imgSortUnselected from 'img/button/sort-unselected.png';
import imgSortUp from 'img/button/sort-up.png';
import imgSortDown from 'img/button/sort-down.png';

const Icon = styled.img`
  position: relative;
  width: 6px;
  margin-left: 5px;
`;

const IconUnselected = styled(Icon)
  .attrs({src: imgSortUnselected})
  `bottom: -2px;`;

const IconDown = styled(Icon)
  .attrs({src: imgSortDown})
  `bottom: 1px;`;

const IconUp = styled(Icon)
  .attrs({src: imgSortUp})
  `bottom: 1px;`;

export const SortableHeaderCell = ({ fieldName, children, ...props }) => (
  <TableContext.Consumer>
    {({ sorting })=> (
      <TableCell
        style={{cursor: 'pointer'}}
        onClick={(() => sorting.update(fieldName))}
        {...props}>
        <span>
          {children}
          {sorting.field !== fieldName ?
            null
            : sorting.direction === 1 ?
              <IconDown/>
              : <IconUp/>
          }
        </span>
      </TableCell>
    )}
  </TableContext.Consumer>
);

export const SortableHeaderCellBlock = ({ fieldName, children, ...props }) => (
  <TableContext.Consumer>
    {({ sorting })=> (
      <TableCellBlock
        style={{cursor: 'pointer'}}
        onClick={(() => sorting.update(fieldName))}
        {...props}>
        <span>
          {children}
          {sorting.field !== fieldName ?
            null
            : sorting.direction === 1 ?
              <IconDown/>
              : <IconUp/>
          }
        </span>
      </TableCellBlock>
    )}
  </TableContext.Consumer>
);