import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';

import Card from 'components/Styled/Card';


class SelectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: props.list,
      selectedIndex: -1,
      onChange: props.onChange || (()=>{}),
    };
  } 

  selectItem = async index => {
    await this.setState(state => ({...state, selectedIndex: index}));
    this.state.onChange(index);
  }

  render() {

    const { width, height } = this.props;
    const { list, selectedIndex } = this.state;
    return (
      <ListContainer width={width} height={height}>
        <Scrollbars>
          {list.map((item, index) => {
            const isSelected = selectedIndex === index;
            return (
              <ListItem
                key={index}
                onClick={() => this.selectItem(index)}
                className={isSelected ? 'selected' : ''}>

                {item.name}

              </ListItem>
            );
          })}
        </Scrollbars>
      </ListContainer>
    );
  }
}

const ListContainer = styled(Card)`
  padding: 0;
`;

const ListItem = styled.div`
  color: #101922;
  padding: 10px 20px;
  cursor: pointer;
  transition: 300ms all;
  font-size: 14px;

  &.selected {
    background: #2291FF;
    color: white;
  }
`;

SelectList.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default SelectList;