import React from 'react';
import { Form, reduxForm } from 'redux-form';
import styled, { css } from 'styled-components';
import { RichTextArea } from 'components/Form/RichTextInput';


let LendingStructureIntro = ({ active }) => {
  
  
  return (
    <Form>
      <Heading>Indicative Lending Structure</Heading>
      <Paragraph disabled={!active} name='introText'/>
    </Form>
  );

}

LendingStructureIntro = reduxForm({ form: 'lending-intro' })(LendingStructureIntro);

export default LendingStructureIntro;

const Heading = styled.h2`
  margin-bottom: 20px;
  color: #575e6c;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
`;

const Paragraph = styled(RichTextArea)`
  color: #575e6c;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    margin-top: 20px;

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
      margin-bottom: 15px;
    }
  }

  ol {
    margin-top: 20px;

    li {
      list-style: decimal inside !important;
      margin-left: 10px;
      margin-bottom: 15px;
    }
  }

  &.active {
    border: solid 1px #A3A8B2;
    border-radius: 3px;
  }

  ${p => p.disabled && css`
    border: none !important;
    padding: 0 !important;
    min-height: auto !important;
  `}
`;