import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { reduxForm } from 'redux-form';
import BankLogoBase from 'shared/PDF/BankLogo';
import EditableContent from 'components/Form/EditableContent';
import Tooltips from 'components/Common/Tooltips';

const Lenders = ({ banks = [], lenders = [], loans = [], active, editMode, defaults }) => {
  const lendingOverview = useSelector(state => 
    state.clients.current?.meta?.miniproposal?.overview?.lendingOverview ?? {});

  let sectionCount = 1;
  if (lendingOverview.aggregateLending === true) sectionCount++;
  if (lendingOverview.aggregateFees !== false) sectionCount++;

  const showPiggy = sectionCount !== 3;

  let blankColumns = 3 - sectionCount;
  if (showPiggy) blankColumns -= 1;

  const blurOnEnter = () => {
    document.activeElement.blur();
  };


  return (
    <FlexRowLending>
      <FlexColumnBank>
        <SmallHeading>Lenders</SmallHeading>
        <Banks>
          {Object.keys(banks).map(id => (
            <BankLogo 
              key={id}
              width={135}
              height={60}
              maxFill={0.6}
              product={{ bank: id, bank_name: lenders.find(l => l.id === id)?.name }} 
            /> 
          ))}
        </Banks> 

      </FlexColumnBank>
      
      {lendingOverview.aggregateLending === true &&
        <FlexColumnLending>
          <SmallHeading>Aggregate Interest Rates</SmallHeading>

          <AggregateRate>
            <Rate>
              <Span 
                as={EditableContent} 
                name='weightedIIR' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              <Postfix>% p.a.</Postfix>
              {!!lendingOverview.weightedIIR && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from ${defaults.weightedIIR}%`}/>
              }
            </Rate>
            <Title>Weighted Interest Rate</Title>
          </AggregateRate>

          <AggregateRate>
            <Rate>
              <Span 
                as={EditableContent} 
                name='weightedTrueRate' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              <Postfix>% p.a.</Postfix>
              {!!lendingOverview.weightedTrueRate && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from ${defaults.weightedTrueRate}%`}/>
              }
            </Rate>
            <Title>Weighted True Rate</Title>
          </AggregateRate>

        </FlexColumnLending>
      }

      {lendingOverview.aggregateFees !== false &&
        <FlexColumnFees>
          <SmallHeading>Aggregate Lender Fees</SmallHeading>

          <AggregateRate>
            <Rate>
              $<Span 
                as={EditableContent} 
                name='upfrontFees' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              {!!lendingOverview.upfrontFees && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from $${defaults.upfrontFees}`}/>
              }
            </Rate>
            <Title>Approximate Upfront Fees*</Title>
          </AggregateRate>

          <AggregateRate>
            <Rate>
              $<Span 
                as={EditableContent} 
                name='ongoingFees' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              {!!lendingOverview.ongoingFees && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from $${defaults.ongoingFees}`}/>
              }
            </Rate>
            <Title>Annual Fees</Title>
          </AggregateRate>

        </FlexColumnFees>
      }  

      {Array(blankColumns).fill().map(() =>  <FlexColumnFees />)}

      {showPiggy &&
        <FlexColumnFees>
          <img src={require('img/illustrations/piggybank.png')} alt="checkeligibilityhelptimer"/>
        </FlexColumnFees>
      }

      
    </FlexRowLending>
        
  );
};

export default reduxForm({
  form: 'atp-lenders',
})(Lenders);

const FlexColumn = styled.div`
  display: inline-block;
  width: ${p => p.width || '33%'};
`;

const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

const FlexRow = styled.div`
  ${p => p.width && css`width: ${p.width};`}
  display: flex;
  margin: ${p => p.margin || '40px 0 40px 0'};
`;

const InlineBlueDot = styled(Tooltips)`
  display: inline-block !important;
  height: 7px !important;
  width: 7px !important;
  background-color: #2291FF !important;
  border-radius: 50% !important;
  margin-left: 5px !important;
  margin-top: 0 !important;
`;

const FlexRowLending = styled(FlexRow)`
  margin: 50px 0 10px 0;
  justify-content: space-between;
`;


const FlexColumnBank = styled(FlexColumn)`
  width: calc(33% - 10px);
`;

const FlexColumnLending = styled(FlexColumn)`
  width: calc(33% - 10px);
`;

const FlexColumnFees = styled(FlexColumn)`
  width: calc(33% - 10px);

  img {
    width: 100%;
    object-fit: contain;
    max-height: 150px;
  }
`;

const Banks = styled.div`
`;

const ReferenceMark = styled.span`
  font-size: 66%;
  vertical-align: top;
`;

const BankLogo = styled(BankLogoBase)`
  margin-right: 40px;
  margin-top: 5px;
  margin-bottom: 35px;
  /* max-width: 180px;
  max-height: 30px; */
  object-fit: contain;
  display: block;
  
  &:last-child {
    margin-bottom: 10px;
  }
`;

const Rate = styled.span``;
const Postfix = styled.span``;
const Title = styled.div``;

const AggregateRate = styled.div`
  margin-bottom: 20px;

  ${Rate} {
    color: #2291FF;
    font-weight: 700;
    font-size: 26px;

    ${Postfix} {
      font-weight: 400;
      font-size: 12px;
      margin-left: 3px; 
    }
  }

  ${Title} {
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
    color: #727C8F;
    display: block;
    font-family: 'Lato', sans-serif;
  }

`;

const Span = styled.span`
  display: inline-block;
  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;
  `}
`;

