import React, { useState } from 'react';

import styled from 'styled-components';

import { Heading as HeadingBase, Body as BodyBase, DocTableBorder, DocTable, Column } from './Shared';

import imgCheckBox from '../img/elements/checkbox.png';
import imgIllionLogo from '../img/brand/illion-logo.png';
import listToString from '../utils/listToString';
import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { Measurer, ToMeasure } from './Measurer';

const MAX_CONTENT_HEIGHT = 1300;

const DocsPDF = ({ user, isFinal, settings }) => {
  const [ pageBreakIndex, setPageBreakIndex ] = useState(0);
  if (!user) return null;

  const broker = user.proposalBroker ?? {};

  const preamble = settings.request_docs_preamble.replace(/\{brokermobile\}/g, broker.mobile);
  const preambleFinal = settings.request_docs_preamble_final.replace(/\{brokermobile\}/g, broker.mobile);

  let docs = _.cloneDeep(user.documents);

  docs = docs.filter(doc => {
    const n = user.numberOfApplicants || 1;
    // property docs don't have applicants, so the first applicant is used to mark approval
    if (doc.isPropertyDoc) return !doc.meta.approved[0];
    // else check that all the applicant docs are approved
    for (let i = 0; i < n; i++) {
      if (doc.meta.applicants[i] && !doc.meta.approved[i]) return true;
    }
    return false;
  });

  for (const doc of docs) {
    if (!doc.description.includes('{applicants}')) continue;
    const n = user.numberOfApplicants || 1;
    const applicants = [];
    for (let i = 0; i < n; i++) {
      if (doc.meta.applicants?.[i] && !doc.meta.approved?.[i]) applicants.push(user.applicantNames?.[i]);
    }
    const applicantString = namesToString(applicants);
    doc.description = doc.description.replace(/\{applicants\}/g, applicantString);
  }

  const [ urgentDocs, normalDocs ] = split(docs, doc => doc.isUrgent);
  let documents = isFinal ? docs : normalDocs;
  const docCount = documents.length;
  const firstPageDocs = documents.slice(0, docCount - pageBreakIndex);
  const secondPageDocs = documents.slice(docCount - pageBreakIndex, docCount);

  const onSizeChange = (heights, changed) => {
    if (changed.docsPage) {
      if (heights.docsPage > MAX_CONTENT_HEIGHT) {
        setPageBreakIndex(pageBreakIndex + 1);
      }
    }
  };

  if (!isFinal) return (<>  
    <Measurer onChange={onSizeChange}>
      {({name}) => <Page {...name('docsPage')}> 
        <Heading>Initial Documents Checklist</Heading>
        <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(preamble)}}/>
        {!!urgentDocs.length && 
          <DocTableBorder>
            <DocTable>
              <tr>
                <th>Urgent Items - For us to get started with preparing your application</th>
              </tr>
              {urgentDocs
                .sort((a, b) => a.description?.localeCompare(b.description, 'en'))
                .map(doc => (
                  <tr key={doc.id}>
                    <td>
                      <img src={imgCheckBox} />
                      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(doc.description)}}/>{doc.isBankStatement ? '*' : ''}
                    </td>
                  </tr>
                ))
              }
            </DocTable>
          </DocTableBorder>
        }
        {!!firstPageDocs.length && 
          <DocTableBorder>
            <DocTable>
              <tr>
                <th>Required Items - For us to lodge your application</th>
              </tr>
              {firstPageDocs
                .sort((a, b) => a.description?.localeCompare(b.description, 'en'))
                .map(doc => (
                  <tr key={doc.id}>
                    <td>
                      <img src={imgCheckBox} />
                      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(doc.description)}}/>{doc.isBankStatement ? '*' : ''}
                    </td>
                  </tr>
                ))
              }
            </DocTable>
          </DocTableBorder>
        }
        {!!secondPageDocs.length &&
          <div style={{ marginTop: '20px', marginLeft: '5px', height: '20px'}}>
            (Continued on following page…)
          </div>
        }
        {docs.some(doc => doc.isBankStatement) && !secondPageDocs.length &&
          <Row>
            <Column width='265px'>
              <img src={imgIllionLogo} height={92}/>
            </Column>
            <Column width='560px' >
              <IllionText>
                *A good option to obtain the necessary transaction listings and statements is to use our integration with Bank Statements by visiting the following URL:
                <div>
                  <a href='https://scv.bankstatements.com.au/ANLO'>https://scv.bankstatements.com.au/ANLO</a>
                </div>
              </IllionText>
            </Column>
          </Row>
        }
      </Page>}
      
    </Measurer>
    {!!secondPageDocs.length && <>
      <PageBreak/>
      <DocTableBorder style={{ marginTop: '0'}}>
        <DocTable>
          <tr>
            <th>Required Items - Continued</th>
          </tr>
          {secondPageDocs
            .sort((a, b) => a.description?.localeCompare(b.description, 'en'))
            .map(doc => (
              <tr key={doc.id}>
                <td>
                  <img src={imgCheckBox} />
                  <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(doc.description)}}/>{doc.isBankStatement ? '*' : ''}
                </td>
              </tr>
            ))
          }
        </DocTable>
      </DocTableBorder>
      {docs.some(doc => doc.isBankStatement) &&
          <Row>
            <Column width='265px'>
              <img src={imgIllionLogo} height={92}/>
            </Column>
            <Column width='560px' >
              <IllionText>
                *A good option to obtain the necessary transaction listings and statements is to use our integration with Bank Statements by visiting the following URL:
                <div>
                  <a href='https://scv.bankstatements.com.au/ANLO'>https://scv.bankstatements.com.au/ANLO</a>
                </div>
              </IllionText>
            </Column>
          </Row>
      }
    </>}
  </>);

  return (<>  
    <Measurer onChange={onSizeChange}>
      {({name}) => <Page {...name('docsPage')}> 
        <Heading>Final Documents Checklist</Heading>
        <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(preambleFinal)}}/>
        {!!firstPageDocs.length && 
          <DocTableBorder>
            <DocTable>
              <tr>
                <th>Outstanding Items - Required for us to lodge your application</th>
              </tr>
              {firstPageDocs
                .sort((a, b) => a.description?.localeCompare(b.description, 'en'))
                .map(doc => (
                  <tr key={doc.id}>
                    <td><img src={imgCheckBox} /><span dangerouslySetInnerHTML={{ __html: sanitizeHtml(doc.description)}}/></td>
                  </tr>
                ))
              }
            </DocTable>
          </DocTableBorder>
        }
        {!!secondPageDocs.length &&
          <div style={{ marginTop: '20px', marginLeft: '5px', height: '20px'}}>
            (Continued on following page…)
          </div>
        }
        {docs.some(doc => doc.isBankStatement) && !secondPageDocs.length &&
          <Row>
            <Column width='265px'>
              <img src={imgIllionLogo} height={92}/>
            </Column>
            <Column width='560px' >
              <IllionText>
                *A good option to obtain the necessary transaction listings and statements is to use our integration with Bank Statements by visiting the following URL:
                <div>
                  <a href='https://scv.bankstatements.com.au/ANLO'>https://scv.bankstatements.com.au/ANLO</a>
                </div>
              </IllionText>
            </Column>
          </Row>
        }
      </Page>}
    </Measurer>
    {!!secondPageDocs.length && <>
      <PageBreak/>
      <DocTableBorder style={{ marginTop: '0'}}>
        <DocTable>
          <tr>
            <th>Outstanding Items - Continued</th>
          </tr>
          {secondPageDocs
            .sort((a, b) => a.description?.localeCompare(b.description, 'en'))
            .map(doc => (
              <tr key={doc.id}>
                <td>
                  <img src={imgCheckBox} />
                  <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(doc.description)}}/>{doc.isBankStatement ? '*' : ''}
                </td>
              </tr>
            ))
          }
        </DocTable>
      </DocTableBorder>
      {docs.some(doc => doc.isBankStatement) &&
        <Row>
          <Column width='265px'>
            <img src={imgIllionLogo} height={92}/>
          </Column>
          <Column width='560px' >
            <IllionText>
              *A good option to obtain the necessary transaction listings and statements is to use our integration with Bank Statements by visiting the following URL:
              <div>
                <a href='https://scv.bankstatements.com.au/ANLO'>https://scv.bankstatements.com.au/ANLO</a>
              </div>
            </IllionText>
          </Column>
        </Row>
      }
    </>}
  </>
  );
};

export default DocsPDF;

const Row = styled.div`
  padding-top: 30px;
`;

const Heading = styled(HeadingBase)`
  color: #575E6C;
  margin-bottom: 30px;
`;

const Body = styled(BodyBase)`
  color: #575E6C;
  line-height: 22px;
`;

const IllionText = styled(Body)`
  padding-top: 7px;
  line-height: 27px;

  a {
    /* text-decoration: underline; */
    color: #2689EB;
  }
`;

function split(arr, fn) {
  const a = [], b = [];
  for (const x of arr)
    fn(x) ? a.push(x) : b.push(x);
  return [a, b];
}

function namesToString(names) {
  return listToString(names.map((name, index) => {
    return (name ?? '').trim() || `Applicant ${index + 1}`;
  }));
}

const Page = styled(ToMeasure)`
  /* background-color: ${p => p.color ?? 'white'}; */
`;

const PageBreak = props => <div className='page-break' {...props}/>;
