import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ButtonImageLeft } from 'components/Styled/Button';
import imgArchive from 'img/button/archive.svg';
import { lighten } from 'polished';

const $ = window.$;

export const ArchiveButton = ({ onConfirm, id, type, title, confirmMessage, ...props }) => {
  const [ deleteActive, setDeleteActive ] = useState(false);
  const toggleDelete = () => setDeleteActive(!deleteActive);

  const checkIfLeaveMenu = e => {
    if(!$(e.target).parents(`.${type}-${id}`).length) {
      setDeleteActive(false);
    }
  };

  useEffect(() => {
    $(window).on('click', checkIfLeaveMenu);
    return () => {
      $(window).off('click', checkIfLeaveMenu);
    };
  }, []);

  return (
    <Container className={`${type}-${id}`}>
      <DeleteButtonStyle type='button' onClick={toggleDelete} {...props}>
        {title}
        <div className="confirm-delete" onClick={() => onConfirm()} style={{ display: deleteActive ? 'block' : 'none' }}>
          {confirmMessage}
        </div>
      </DeleteButtonStyle>
    </Container>
    
  );
};

const Container = styled.div`
  display: inline-block;
`;

const DeleteButtonStyle = styled(ButtonImageLeft)`
  /* float: left;
  margin: 0 !important; */
  padding-left: 50px;
  padding-right: 20px;
  margin-right: 20px;
  background-size: 20px 20px;
  background-position-x: 16px;
  background-position-y: 8px;
  background-image: url(${imgArchive});
  background-repeat: no-repeat;
  position: relative; 

  &:focus {
    border: 1px solid #D8DCE7;
  }

  .confirm-delete {
    display: block;
    font-size: 12px;
    color: #ff4e4c;
    position: absolute;
    top: 37px;
    border: 1px solid #d8dce7;
    box-shadow: 0 1px 6px 0 rgba(216,220,231,.65);
    border-radius: 4px 0 4px 4px;
    right: -1px;
    width: 130px;
    padding: 10px 5px;
    background-color: #fff;
    z-index: 100;

    /* &:after {
      position: absolute;
      display: block;
      top: -4px;
      right: 0;
      content: "";
      background-color: #fff;
      width: 100%;
      height: 5px;
    } */
  }
`;

export const Button = styled.button.attrs({ type: 'button' })`
position: relative;

top: ${p => p.top || '0'};
margin: 0 !important;
width: 100%;
font-family: 'Lato', sans-serif;
font-size: 14px;
font-weight: 700;
border-radius: 4px;
padding: 12px 0 12px 0;
color: white;
border: none; 
background-color: ${p => p.color};
cursor: pointer;
transition: 0.5s background-color;

&:focus, &:hover {
  background-color: ${p => lighten(0.05, p.color)};
}

&:active {
  background-color: ${p => lighten(0.05, p.color)};
}
`;