import React from 'react';
import styled, { css } from 'styled-components';
import { lighten, grayscale } from 'polished'

export const BasicButton = styled.button`
  display: inline-block;
  height: ${p => p.height || '40px'};
  line-height: ${p => p.height || '40px'};
  width: ${p => p.width || 'auto'};
  border-radius: 4px;
  transition: all 300ms;
  padding: 0 10px 0 10px;
  margin: ${p => p.margin || '0'};
  font-size: ${p => p.fontSize || '14px'};
  font-weight: ${p => p.fontWeight || '400'};
  cursor: pointer;
`;

export const ButtonPrimary = styled(BasicButton)`
  color: white !important;
  background-color: ${p => p.background || '#2291FF'};
  border: none;
  text-align: center;

  font-family: 'Lato';
  letter-spacing: 0;

  &:hover, &:focus {
    background-color: ${p => lighten(0.1, p.background || '#2291FF')};
  }

  &:disabled, &.disabled {
    background-color: ${p => grayscale(p.background || '#2291FF')};
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${p => p.disabled && css`
    cursor: not-allowed;
    background-color: ${p => grayscale(p.background || '#2291FF')};
  `}
`;

export const ButtonImageLeft = styled(BasicButton)`

  background: white;
  border: 1px solid #D8DCE7;
  /* box-shadow: 0 1px 0 0 #E9EBF1; */

  font-family: 'Lato';
  color: #101922;
  letter-spacing: 0;
  line-height: 20px;

  span {
    padding-left: 20px;
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: auto 6px;
  }

  &:focus {
    border: 1px solid #2291FF;
  }
`;


export const ButtonPlain = styled(BasicButton)`

  background: white;
  border: 1px solid #D8DCE7;
  /* box-shadow: 0 1px 0 0 #E9EBF1; */

  font-family: 'Lato';
  color: #101922;
  letter-spacing: 0;
  line-height: 20px;

  &:focus {
    border: 1px solid #2291FF;
  }
`;

export const ButtonImage = styled(BasicButton)`

  background: white;
  border: 1px solid #D8DCE7;
  /* box-shadow: 0 1px 0 0 #E9EBF1; */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 6px;

  font-family: 'Lato';
  color: #101922;
  letter-spacing: 0;
  line-height: 20px;

  /* &:focus {
    border: 1px solid #2291FF;
  } */
`;

export const ButtonImageSquare = styled(ButtonImage)`
  height: 40px;
  width: 40px;
`;