// src/components/Navigation/Navigation.js

import React, { Component } from "react";
import { NavLink, Switch, Route } from "react-router-dom";
import Account from './components/Account'
import PageTitle from './components/PageTitle';
import { PullRight } from 'components/Styled/Layout';

const Navigation = ({ state }) => (
  <header className='active' >
    <div className="header-wrapper">
      <div className='left-navigation'>
        <NavLink className="logo" exact to="/">
          <img src={require('../../img/brand/logo.png')} alt='LoanBase logo'/>
        </NavLink>
      </div>
        {state === 'active' ? <NavigationActive/> : <NavigationInactive/>}
      </div>
  </header>
);


class NavigationActive extends Component {
  render() {
    return (
      <div className='right-navigation'>
        <div className='page-title'>
          <PageTitle/>
        </div>
        <PullRight>
          <div className='navigation-elements'>
            <Account />
          </div>
        </PullRight>
        
      </div>
    );
  }
}

class NavigationInactive extends Component {
  render() {
    return (
      <div className='right-navigation'>
        <div className='page-title'>
          <span>Welcome to Loanbase</span>
        </div>
        <div className='navigation-elements'>
        
        </div>
      </div>
    );
  }
}

export default Navigation;