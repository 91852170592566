import React, { Component } from 'react';
import styled from 'styled-components';

import { Row, Column } from 'components/Styled/Layout';
import Card from 'components/Styled/Card';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';

import imgCalendar from 'img/icons/calendar-white.png';
import imgFlag from 'img/icons/flag-diagonal-white.png';
import imgEllipsisDark from 'img/button/ellipsis-dark.png';
import imgEllipsisWhite from 'img/button/ellipsis-white.png';
import imgEditPen from 'img/button/edit-pen.png';
import imgCross from 'img/button/cross-red.png';
import imgCheck from 'img/button/check-small.png';

import { Modal, ModalContext, ModalShow } from 'components/Modal/old';
import Label from 'components/Styled/Label';
import TextArea from 'components/Styled/TextArea';

import { ModelessButton } from 'components/Modal/ModelessButton';



class Task extends Component {
  render() {
    const { data } = this.props;
    return (
      <Row>
        <Column width='55px'>
          {data.type === 'flag' ? <CircleFlag/> : <CircleCalendar/>}
          <DashedLine height='60px' margin='0 0 0 19px'/>
        </Column>
        <Column width='calc(100% - 55px)'>
          <TaskCard>
            <ModalContext modal={DetailsModal} animationTimeout={500} onSubmit={(data, hide) => hide()}>
              <ModalShow as={ClickableDiv}>
                <div style={{float: 'left'}}>
                  <TaskTitle>{data.title}</TaskTitle>
                  <TaskDetails>Assigned to {data.assignedTo} | Created By {data.createdBy} on {data.dateTime}</TaskDetails>
                </div>
              </ModalShow>
            </ModalContext>
            <div style={{float: 'right', position:'relative'}}>
              <ModelessButton 
                modeless={TaskOptions} 
                buttonDefault={ModelessButtonClosed} 
                buttonActive={ModelessButtonOpen}/>
            </div>
          </TaskCard>
        </Column>
      </Row>
    );
  }
}

const TaskBar = styled(Card)`
  position: absolute;
  padding: 10px 0;
  top: 50px;
  left: -55px;
  width: 150px;
  z-index: 120;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 15px 1px rgba(216,220,231,0.65);

  &:after {
    content: "";
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    position: absolute;
    top: -5px;
    left: 69px;
    filter: drop-shadow(0px -1.5px 0px #D8DCE7);
    
    z-index: 5;
  }
`;

const TaskBarOption = styled.div`
  width: 100%;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background-color: #f3f3f3
  }
`;

const TaskOptions = () => (
  <TaskBar>
    <TaskBarOption><IconEdit/>Edit</TaskBarOption>
    <TaskBarOption style={{color: '#26BD27'}}><IconTick/>Mark as Done</TaskBarOption>
    <TaskBarOption style={{color: '#FF4E4C'}}><IconCross/>Delete</TaskBarOption>
  </TaskBar>
);

const ModelessButtonClosed = props => (
  <ButtonPlain width='40px' height='40px' {...props}><IconEllipsisDark/></ButtonPlain>
);

const ModelessButtonOpen = props => (
  <ButtonPrimary width='40px' height='40px' {...props}><IconEllipsisWhite/></ButtonPrimary>
);


const TaskList = ({ tasks }) => (
  <TaskListContainer>
    {tasks.map((task, index) => (
      <Task key={index} data={task}/>
    ))}
  </TaskListContainer>
);

const Circle = styled.div`
  width: ${({ diameter }) => diameter};
  height: ${({ diameter }) => diameter};
  background: ${({ color }) => color};
  border-radius: ${({ diameter }) => diameter};
  border: 1px solid ${({ borderColor, color }) => borderColor || color};
`;

const DashedLine = styled.div`
  width: 0;
  height: ${({ height }) => height || 0};
  margin: ${({ margin }) => margin || 0};
  border-left: 1px dashed #C8CCD7;
`;

const TaskCard = styled(Card)`
  position: relative;
  height: 80px;
  padding: 20px;
  &:after {
    content: "";
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
    position: absolute;
    top: 13px;
    left: -5px;
    filter: drop-shadow(-1.5px 0px 0px #D8DCE7);
    
    z-index: 5;
    
  }
`;

const TaskListContainer = styled.div`
  min-width: 650px;
  max-width: 800px;
  > * {
    &:last-child {
      ${DashedLine} {
        opacity: 0;
      }
    }
  }
`;

const TaskTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #101922;
  margin-bottom: 9px;
`;

const TaskDetails = styled.p`
  font-size: 13px;
  color: #575E6C;
`;

const IconCalendar = styled.img.attrs({
  src: imgCalendar,
  width: 16,
})`
  position: relative;
  top: 11px;
  left: 11px;
`;

const IconEllipsisDark = styled.img.attrs({
  src: imgEllipsisDark,
  width: 20,
})`
  position: relative;
  top: -2px;
  left: -1px;
`;

const IconEdit = styled.img.attrs({
  src: imgEditPen,
  width: 12,
})`
  margin-right: 10px;
`;

const IconTick = styled.img.attrs({
  src: imgCheck,
  width: 15,
})`
  margin-right: 10px;
`;

const IconCross = styled.img.attrs({
  src: imgCross,
  width: 12,
})`
  margin-right: 10px;
`;

const IconEllipsisWhite = styled.img.attrs({
  src: imgEllipsisWhite,
  width: 20,
})`
  position: relative;
  top: -2px;
  left: -1px;
`;

const IconFlag = styled.img.attrs({
  src: imgFlag,
  width: 16,
})`
  position: relative;
  top: 12px;
  left: 11px;
`;

const CircleCalendar = () => 
  <Circle diameter='40px' color='#2291FF' borderColor='#1C83EA'>
    <IconCalendar/>
  </Circle>;

const CircleFlag = () => 
  <Circle diameter='40px' color='#F5A623' borderColor='#C58500'>
    <IconFlag/>
  </Circle>;

const DetailsModal = ({ modal }) => (
  <Modal width='600px' modal={modal}>
    <Row>
      <Label>Placeholder Modal</Label>
      <TextArea/>
    </Row>
  </Modal>
);

const ClickableDiv = (props) => <div {...props} style={{cursor: 'pointer'}}/>; 

export default TaskList;