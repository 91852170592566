import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Column, Row, PullLeft, PullRight } from 'components/Styled/Layout';
import { Form, reduxForm, submit } from 'redux-form';
import Label from 'components/Styled/Label';
import SearchSelect from 'components/Common/SearchSelect';
import TextInput from 'components/Styled/TextInput';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { Checkbox, Input, Select, TextArea, ToggleButton } from 'components/Form';
import { DeleteButton } from 'views/Settings/Components/Shared';
import { ApplicationCardDisabled } from '../ApplicationCardDisabled';
import { connect, useDispatch, useSelector } from 'react-redux';
import html from 'util/html';
import { baseUrl, emailService, smsService } from 'modules';
import SmsSentModal from './SmsSentModal';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { requestUpdateApplications } from 'modules/applications/actions';
import { EmailTextArea } from 'components/Form/EmailTextInput';
import { required } from 'util/validation';

import imgX from 'img/button/close-small.svg';
const $ = window.$;

const senderValidation = (value, _, props) => {
  if (!value) return 'No sender selected';
  if (value === 'loanbase') return;
  const isSenderBroker = value === 'broker';
  const sender = (isSenderBroker) ? props.deal.broker : props.deal.processor;
  if (!sender) return isSenderBroker ? 'No broker is selected for this deal' : 'No processor is selected for this deal';
};

const includeClientValidation = (isIncluded, values, props) => {
  if (!isIncluded) {
    if (values.additionalEmails) return;
    else return 'No recipients selected';
  }
  
  else return (props.deal.client.email && !props.deal.client.email.includes('@fake')) ? null : 'Client is missing valid email';
};

const SendEmailModalBase = ({ 
  modal, 
  handleSubmit, 
  deal, 
  invalid, 
  initialize, 
  reset, 
  submitting, 
  initialValues,
  page,
  ...props }) => {

  const dispatch = useDispatch();

  const stages = useSelector(state => state.applications.stages);
  const templateText = useSelector(state => state.form[props.form]?.values.emailTemplate);
  const templateName = useSelector(state => state.form[props.form]?.values.name);
  const emailSubject = useSelector(state => state.form[props.form]?.values.emailSubject);
  const lenders = useSelector(state => state.lenders.list);
  const syncErrors = useSelector(state => state.form[props.form].syncErrors);
  const [ result, setResult ] = useState(null);
  const [ attachments, setAttachments ] = useState(initialValues.emailAttachments ?? []);
  const [ uploadingAttachment, setUploadingAttachment ] = useState(false);

  const pages = Object.values(stages)
    .filter(x => x.emailTemplate && x.emailTemplate.trim())
    .sort((a,b) => a.stageIndex - b.stageIndex)
    .reduce((obj, stage) => ({
      ...obj,
      [stage.page]: [ ...(obj[stage.page] ?? []), stage ]
    }), {});

  let templateOptions = [ 
    ...(pages['deals'] ?? []), 
    ...(pages['in-progress'] ?? []), 
    ...(pages['new-leads'] ?? []), 
    ...(pages['referrals'] ?? []), 
    ...(pages['qualifiers'] ?? []), 
    ...(pages['email-template'] ?? [])
  ].map(stage => {
    let name;
    let stageName = capitalizeFirstLetter(stage.page);
    if (stage.page === 'new-leads') stageName = 'New Leads';
    if (stage.page === 'in-progress') stageName = 'In Progress';
    if (stage.page !== 'email-template') name = `${stageName} - ${stage.name}`;
    else name = stage.name;
    return { name, value: stage.id };
  });

  templateOptions = [ { name: 'None', id: -1 }, ...templateOptions ];

  let brokerName = deal.broker ? `${deal.broker.firstName} ${deal.broker.lastName}` : null;
  let brokerFirstName = deal.broker ? deal.broker.firstName : null;
  let processorName = deal.processor ? `${deal.processor.firstName} ${deal.processor.lastName}` : null;
  let processorFirstName = deal.processor ? deal.processor.firstName : null;
  let clientName = deal.client ? `${deal.client.firstName}${deal.client.lastName ? ' ' + deal.client.lastName : ''}` : null;
  let clientFirstName = deal.client ? deal.client.firstName : null;
  let lenderName = lenders.find(x => x.id === deal.lenderId)?.name;
  let brokerTitle = deal.broker ? deal.broker.title : null;
  let clientLastName = deal.client && deal.client.lastName ? deal.client.lastName.toUpperCase() : null;
  let clientEmail = deal.client ? deal.client.email : null;

  const replaceListHtml = [
    ['\n', '<br/>'],
    ['{broker}', brokerName ?? warningHtml('(deal is missing a broker)')],
    ['{brokerfirst}', brokerFirstName ?? warningHtml('(deal is missing a broker)')],
    ['{processor}', processorName ?? warningHtml('(deal is missing a processor)') ],
    ['{processorfirst}', processorFirstName ?? warningHtml('(deal is missing a processor)')],
    ['{client}', clientName ],
    ['{clientfirst}', clientFirstName ],
    ['{lender}', lenderName ?? warningHtml('(deal is missing a lender)') ],
    ['{brokeremail}', deal.broker?.email ?? warningHtml('(deal is missing a broker)')],
    ['{processoremail}', deal.processor?.email ?? warningHtml('(deal is missing a processor)')],
    ['{clientemail}', deal.client?.email ],
    ['{calendly}', deal.broker ? deal.broker.calendlyUrl ? `<a href="${deal.broker.calendlyUrl}">${deal.broker.calendlyUrl}</a>` : warningHtml('(broker is missing calendly url)') : warningHtml('(deal is missing a broker)')],
    ['{brokertitle}', brokerTitle ?? warningHtml('(deal is missing a broker)')],
    ['{clientlast}', clientLastName ],
    ['{referafriend}', linkButtonHtml('Refer-a-Friend', 'referrals/dashboard')],
    ['{proposal}', linkButtonHtml('View Proposal', 'proposal')],
    ['’', '\''],
  ];

  const replaceListSubject = [
    ['{broker}', brokerName ?? '<ERROR>'],
    ['{brokerfirst}', brokerFirstName ?? '<ERROR>'],
    ['{processor}', processorName ?? '<ERROR>'],
    ['{processorfirst}', processorFirstName ?? '<ERROR>'],
    ['{client}', clientName ?? '<ERROR>'],
    ['{clientfirst}', clientFirstName ?? '<ERROR>'],
    ['{lender}', lenderName ?? '<ERROR>'],
    ['{brokertitle}', brokerTitle ?? '<ERROR>'],
    ['{clientlast}', clientLastName ?? '<ERROR>'],
    ['’', '\''],
  ];

  let emailTextHtml = templateText ?? '';
  for (const [from, to] of replaceListHtml) {
    emailTextHtml = emailTextHtml.replaceAll(from, to);
  }

  let subjectText = emailSubject ?? '';
  for (const [from, to] of replaceListSubject) {
    subjectText = subjectText.replaceAll(from, to);
  }

  const senderOptions = [
    { name: `Assigned Broker (${brokerName ?? 'No broker'})`, value: 'broker' },
    { name: `Assigned Processor (${processorName ?? 'No processor'})`, value: 'processor' },
    { name: 'Loan Base', value: 'loanbase' }, 
  ];

  const changeTemplate = id => {
    let template = stages[id];
    if (!template) template = { id: -1, emailSender: 'loanbase', name: 'Custom E-mail' };
    let emailText = template.emailTemplate ?? '';
    for (const [from, to] of replaceListHtml) {
      emailText = emailText.replaceAll(from, to);
    }
    let subjectText = template.emailSubject ?? '';
    for (const [from, to] of replaceListSubject) {
      subjectText = subjectText.replaceAll(from, to);
    }
    setAttachments(template.emailAttachments ?? []);
    initialize({ ...template, emailText, subjectText, includeClient: true });
    reset();
  };
  
  const submit = async values => { 
    let to = [];
    if (values.includeClient && clientEmail) {
      to = [ clientEmail ];
    }
    if (values.additionalEmails) {
      const additionalEmails = values.additionalEmails
        .replaceAll(' ', '')
        .split(',')
        .filter(x => !!x);
      to = [ ...to, ...additionalEmails ];
    }

    let from;
    if (values.emailSender !== 'loanbase') {
      from = (values.emailSender === 'broker') ? `${brokerName} <${deal.broker?.email}>` : `${processorName} <${deal.processor?.email}>`;
    }

    const dealUrl = `${baseUrl}/deals/view?id=${deal.id}`;
    const notifyBroker = values.emailNotifyBroker && brokerName && !!deal.broker?.email;
    const notifyProcessor =  values.emailNotifyProcessor && processorName && !!deal.processor?.email;
    const notifyAdmin = values.emailNotifyAdmin;

    let cc = [];
    if (notifyBroker) cc.push(deal.broker.email);
    if (notifyProcessor) cc.push(deal.processor.email);
    if (notifyAdmin) cc.push('admin@loanbase.com.au');


    let signature = '';
    if (values.emailSender !== 'loanbase') {
      signature = ((values.emailSender === 'broker') ? deal.broker?.emailSignature : deal.processor?.emailSignature) ?? '';
    }

    const emailProps = { 
      type: 'auto-email',
      from, 
      to, 
      cc,
      body: values.emailText + signature, 
      subject: values.subjectText,
      attachments,
      userEmail: clientEmail,
    };  
    
    const result = await emailService.create(emailProps);
    // TODO add email send code
    if (!result.error) {

      let sender = 'Loan Base';
      if (values.emailSender === 'broker') {
        sender = `${brokerName}(${deal.broker.email})`;
      }
      if (values.emailSender === 'processor') {
        sender = `${processorName}(${deal.processor.email})`;
      }
      

      const noteBody = html`
        <div><b>An E-mail was sent to ${clientName} (${clientEmail}) from ${sender}:</b></div>
        <div>${values.emailText}</div>
        <br/>
        ${(notifyBroker || notifyProcessor) ? html`
          <div><b>Notifications were sent to:</b></div>
          ${notifyBroker ? html`<div>${brokerName} (${deal.broker.email})</div>` : ''}
          ${notifyProcessor ? html`<div>${processorName} (${deal.processor.email})</div>` : ''}
        ` : ''}
      `;

      dispatch(requestUpdateApplications({
        id: deal.id,
        note: { 
          body: noteBody,
          brokerId: 'system'
        }
      }));

      if (values.emailDebug) {
        const emailBody = html`
          <div>An automated e-mail has been sent.</div>
          <br/>
          <div>Client Name: ${clientName}</div>
          <div>Deal Description: ${deal.dealDescription}</div>
          <div>Template Name: ${templateName}</div>
          <div>E-mail Sender: ${sender}</div>
          <div>E-mail Recipient(s): ${[...to, ...cc].join(', ')}</div>
          <div>Message Content:</div>
          <div>${values.emailText}</div>
          <div><a href='${dealUrl}'>${dealUrl}</a></div>
        `; 

        emailService.create({
          type: 'system-email',
          subject: `Email sent (debug) - ${clientName} - ${templateName}`,
          body: emailBody,
        });
      }
    }
    setResult({ ...result, formValues: values })
  };

  const selectFile = () => {
    $('#file-upload').click();
  };

  useEffect(() => {
    $('#file-upload').off();
    $('#file-upload').change(function(){
      const reader  = new FileReader();
      var file = this.files[0];
      // encode dataURI
      if (!file) return;
      setUploadingAttachment(true);
      reader.addEventListener('load', () => {
        setAttachments([ ...attachments, { filename: file.name, path: reader.result }]);
        setUploadingAttachment(false);
      }, false);
  
      reader.readAsDataURL(file);
    });
  }, [attachments]);

  const deleteAttachment = useCallback(index => {
    console.log('Deleting attachment at index:', index);
    let attachmentsCopy = [ ...attachments ];
    attachmentsCopy.splice(index, 1);
    setAttachments(attachmentsCopy);
  }, [attachments]);

  if (result) return (
    <ModalBox style={{ width: '640px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(() => submit(result.formValues))}>
        <Row>
          <SmallHeading>Send E-mail</SmallHeading>
        </Row>
        {!result.error &&
          <Row margin='0 0 35px 0'  style={{ fontSize: '15px' }}>
            The following E-mail has been sent! 🙂
          </Row>
        }
        {result.error &&
          <Row margin='0 0 35px 0'  style={{ fontSize: '15px' }}>
            There was an error sending the email: <span style={{ color: 'red'}}>{result.error.message}</span>
          </Row>
        }
        
        <Row margin='0 0 20px 0'>
          <EmailPreview dangerouslySetInnerHTML={{ __html: result.formValues.emailText }} />
        </Row>
        <Row margin='0 0 20px 0' style={{ fontSize: '14px' }}>
          <Column width='20%' style={{ fontWeight: '700' }}>
            Recipients:
          </Column>
          <Column width='80%'>
            {result.formValues.includeClient &&
              <div>{clientName} ({clientEmail})</div>
            }
            {(result.formValues.additionalEmails ?? '').split(',').map((number, index) => 
              <div key={index}>{number}</div>
            )}
          </Column>
        </Row>  
        <Row margin='0 0 20px 0' style={{ fontSize: '14px' }}>
          <Column width='20%' style={{ fontWeight: '700' }}>
            Cc’d: 
          </Column>
          <Column width='80%'>
            {!!result.formValues.emailNotifyBroker && !!brokerName && !!deal.broker?.email &&
              <Row margin='0 0 5px 0'>{brokerName} ({deal.broker.email})</Row>
            }
            {!!result.formValues.emailNotifyProcessor && !!processorName && !!deal.processor?.email &&
              <Row margin='0 0 5px 0'>{processorName} ({deal.processor.email})</Row>
            }
            {!!result.formValues.emailNotifyAdmin && 
              <Row margin='0 0 5px 0'>admin@loanbase.com.au</Row>
            }
          </Column>
        </Row>  
        <Row style={{ marginTop: '30px', marginBottom: '15px'}}> 
  
          {!result.error &&
            <Column width='70%' margin='0 15% 0 15%'>
              <ButtonPrimary onClick={modal.close} width='100%'>Finish</ButtonPrimary>
            </Column>
          }
          {result.error && <>
            <Column width='35%' margin='0 30% 0 0'>
              <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={() => setResult(null)} width='100%'>Back</ButtonPlain>
            </Column>
            <Column width='35%' margin='0 0 0 0'>
              <ButtonPrimary type='submit' width='100%'><ButtonSpinner show={submitting}/>Retry</ButtonPrimary>
            </Column>
          </>}
          
        </Row>
      </Form> 
    </ModalBox>
  ); 

  return (
    <ModalBox style={{ width: '640px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>
            Send E-mail
            {initialValues.smsEnabled &&
              <span>+ SMS Automation</span>
            }
          </SmallHeading>
          
          
        </Row>
        <Row margin='0 0 20px 0'>
          <Column width='265px'>
            <Label>Deal Confirmation</Label>
            <ApplicationCardDisabled data={deal} index={999999} isDisabled={true} page={page}/>
          </Column>
          <Column width='300px' margin='0 -20px 0 0'>
            <Row margin='0 0 9px 0'>
              <Label>E-mail Template</Label>
              <Select width='300px' name='id' options={templateOptions} onChange={changeTemplate} />
            </Row>
            <Row margin='0 0 0 0'>
              <Label>E-mail Sender</Label>
              <Select width='300px'name='emailSender' options={senderOptions} validate={senderValidation}/>
            </Row>
          </Column>
        </Row>
        <Row margin='0 0 20px 0'>
          <Label>E-mail Subject</Label>
          <Input width='100%' name='subjectText' validate={required} />
        </Row>
        <Row margin='0 0 -30px 0'>
          <Label>E-mail Message</Label>
          <EmailTextArea name='emailText'/>
        </Row>
        <Row margin='0 0 20px 0'>
          <ButtonPrimary margin='0 10px 0 0' height='30px' type='button' onClick={uploadingAttachment ? ()=>{} : selectFile}>Attach a file...</ButtonPrimary>
          {attachments.map((file, index) => 
            <Attachment name={file.filename} onDelete={() => deleteAttachment(index)} key={index}/>
          )}
        </Row>
        <Row margin='0 0 5px 0'>
          <Column width='50%'>
            <Label style={{ marginBottom: '15px' }}>Recipient(s)</Label>
            <Checkbox style={{ marginLeft: '0px'}} name='includeClient' label={`${clientName} (${clientEmail ?? 'NO PHONE'})`} validate={includeClientValidation}/>
            <ErrorMessage>{props.submitFailed  ? syncErrors?.includeClient : ''}</ErrorMessage>
          </Column>
          <Column width='50%'>
            <Label>Additional receipient(s)</Label>
            <Input height='30px' name='additionalEmails'/>
          </Column>
        </Row>
        <Row margin='0 0 10px 0'>
          <Column width='50%'>
            <Label style={{ marginBottom: '15px' }}>E-mail to also cc</Label>
            <Checkbox style={{ marginLeft: '0px'}} label='Broker' name='emailNotifyBroker'/>
            <Checkbox style={{ marginLeft: '15px'}} label='Processor' name='emailNotifyProcessor'/>
          </Column>
          <PullRight style={{ position: 'relative', top: '20px'}}>
            <Label>
              <ToggleButton name='emailDebug' />
              Debug Mode
            </Label>
          </PullRight>
        </Row>
        
        <Row style={{ marginTop: '30px'}}> 
          <Column width='35%' margin='0 30% 0 0'>
            <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={modal.close} width='100%'>Cancel</ButtonPlain>
          </Column>
          <Column width='35%'>
            <ButtonPrimary type='submit' width='100%' className={invalid ? 'disabled' : ''}><ButtonSpinner show={submitting}/>Send E-mail</ButtonPrimary>
          </Column>
        </Row>
      </Form> 
      <input id='file-upload' type='file' name='upload' style={{display: 'none'}}/>
    </ModalBox>
  );
};

const AttachmentPill = styled.span`
  position: relative;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  border-radius: 8.5px;
  background: #d8dce7;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 30px 0 10px;
`;

const AttachmentDelete = styled.span`
  position: absolute;
  right: 0px;
  border-left: 1px solid #c0c3cb;
  height: 25px;
  width: 20px;
  cursor: pointer;

  img {
    margin-left: 4px;
  }
`;

const Attachment = ({ name, onDelete }) => {
  return <AttachmentPill>
    {name}
    <AttachmentDelete onClick={onDelete}><img src={imgX}/></AttachmentDelete>
  </AttachmentPill>;
};

const stateToProps = (state, props) => {
  const { initialValues, deal } = props;
  const lenders = state.lenders.list;

  let brokerName = deal.broker ? `${deal.broker.firstName} ${deal.broker.lastName}` : null;
  let brokerFirstName = deal.broker ? deal.broker.firstName : null;
  let processorName = deal.processor ? `${deal.processor.firstName} ${deal.processor.lastName}` : null;
  let processorFirstName = deal.processor ? deal.processor.firstName : null;
  let clientName = deal.client ? `${deal.client.firstName}${deal.client.lastName ? ' ' + deal.client.lastName : ''}` : null;
  let clientFirstName = deal.client ? deal.client.firstName : null;
  let lenderName = lenders.find(x => x.id === deal.lenderId)?.name;
  let brokerTitle = deal.broker ? deal.broker.title : null;
  let clientLastName = deal.client && deal.client.lastNmae ? deal.client.lastName.toUpperCase() : null;

  const replaceListHtml = [
    ['\n', '<br/>'],
    ['{broker}', brokerName ?? warningHtml('(deal is missing a broker)')],
    ['{brokerfirst}', brokerFirstName ?? warningHtml('(deal is missing a broker)')],
    ['{processor}', processorName ?? warningHtml('(deal is missing a processor)') ],
    ['{processorfirst}', processorFirstName ?? warningHtml('(deal is missing a processor)')],
    ['{client}', clientName ],
    ['{clientfirst}', clientFirstName ],
    ['{lender}', lenderName ?? warningHtml('(deal is missing a lender)') ],
    ['{brokeremail}', deal.broker?.email ?? warningHtml('(deal is missing a broker)')],
    ['{processoremail}', deal.processor?.email ?? warningHtml('(deal is missing a processor)')],
    ['{clientemail}', deal.client?.email ],
    ['{calendly}', deal.broker ? deal.broker.calendlyUrl ? `<a href="${deal.broker.calendlyUrl}">${deal.broker.calendlyUrl}</a>` : warningHtml('(broker is missing calendly url)') : warningHtml('(deal is missing a broker)')],
    ['{brokertitle}', brokerTitle ?? warningHtml('(deal is missing a broker)')],
    ['{clientlast}', clientLastName ],
    ['{referafriend}', linkButtonHtml('Refer-a-Friend', 'referrals/dashboard')],
    ['{proposal}', linkButtonHtml('View Proposal', 'proposal')],
    ['’', '\''],
  ];

  const replaceListSubject = [
    ['{broker}', brokerName ?? '<ERROR>'],
    ['{brokerfirst}', brokerFirstName ?? '<ERROR>'],
    ['{processor}', processorName ?? '<ERROR>'],
    ['{processorfirst}', processorFirstName ?? '<ERROR>'],
    ['{client}', clientName ?? '<ERROR>'],
    ['{clientfirst}', clientFirstName ?? '<ERROR>'],
    ['{lender}', lenderName ?? '<ERROR>'],
    ['{brokertitle}', brokerTitle ?? '<ERROR>'],
    ['{clientlast}', clientLastName ?? '<ERROR>'],
    ['’', '\''],
  ];

  let emailText = initialValues.emailTemplate ?? '';
  for (const [from, to] of replaceListHtml) {
    emailText = emailText.replaceAll(from, to);
  }

  let subjectText = initialValues.emailSubject ?? '';
  for (const [from, to] of replaceListSubject) {
    subjectText = subjectText.replaceAll(from, to);
  }

  return {
    initialValues: {
      ...initialValues,
      emailText,
      subjectText,
    }
  };
};

const SendEmailModal = connect(stateToProps)(reduxForm({ form: 'send-email'})(SendEmailModalBase))  ;

export default SendEmailModal;

const ErrorMessage = styled.div`
  color: red;
  padding-top: 3px;
  font-size: 12px;
  height: 15px;
`;

const SmallHeading = styled.h2`
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 26px !important;
  margin-top: -20px;

  span {
    margin-left: 5px;
    font-size: 14px !important;
  }
`;

const SubLabel = styled.div`
  color: #223241;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 12px;
`;

function capitalizeFirstLetter(string) {  
  return string[0].toUpperCase() +  string.slice(1); 
} 

function warningHtml(text) {
  return html`
    <span style='color: red;'>${text}</span>
  `;
}

function linkButtonHtml(text, page) {
  return (
    "<table style='width: 100%;'>" + 
    "<tr>" +
    "<td style='padding-top: 20px; padding-bottom: 40px'>" +
    "<div style='text-align: center'>" +
    "<!--[if mso]><v:roundrect xmlns:v='urn:schemas-microsoft-com:vml' xmlns:w='urn:schemas-microsoft-com:office:word' href='{clientUrl}/" + page + "?email={email}&loginToken={token}' style='height:50px; v-text-anchor:middle; width:225px;' arcsize='5%' strokecolor='#47B4F5' fillcolor='#47B4F5'><w:anchorlock/>" +
    "<center style='color:#ffffff;font-family:Arial, sans-serif; font-size:16px; text-decoration: none; font-weight: bold;'>" +
    "<![endif]-->" +
    `<a` + " href='{clientUrl}/" + page + "?email={email}&loginToken={token}' target='_blank' style='text-decoration: none; color: #fff; background-color: #5592F8; font-weight: bold; line-height: 60px; padding: 0 30px; display: inline-block; border-radius: 4px; font-size: 18px; font-weight: 800;'>" + text + "</a>" +
    "<!--[if mso]></center></v:roundrect><![endif]-->" +
    "</div>" +
    "</td>" +
    "</tr>" +
    "</table>"
  );
}

function toMoneyString(amount) {
  if (!amount) {
    return '$-';
  }

  if (amount < 1000) {
    const rounded = Math.round(amount*10)/10;
    return `$${rounded}`;
  }
    
  if (amount < 1000000) {
    const rounded = Math.round(amount/100)/10;
    return `$${rounded}k`;
  }

  const rounded = Math.round(amount/10000)/100;
  return `$${rounded}m`;
}

const EmailPreview = styled.div`
  color: #101922;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  letter-spacing: 0; 
  resize: none;
  min-width: ${p => p.width || '100%'};
  border: 1px solid #d8dce7;
  border-radius: 4px;
  background: #ffffff;
  color: #101922;
  padding: 10px 12px 10px 12px;
  /* box-shadow: 0px 1px 0px 0px #e9ebf1; */
  transition: all 300ms;
  overflow: auto;
  margin-bottom: 40px;
  max-height: 400px;

  ${p => p.error && css`
    border: 1px solid rgba(255,0,0,0.5) !important;
    box-shadow: inset 0 0 0 2px rgba(255,0,0,0.2) !important;
  `}

  &:focus {
    border: 1px solid #2291FF !important;
    box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
  }

  line-height: 20px;
  min-height: 100px;
  /* color: #575E6C; */
  
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
    }
  }

  ol {
    li {
      list-style: decimal inside !important;
      margin-left: 10px;
    }
  } 
  min-height: 150px
`;