import {
  RECEIVE_FIND_PRODUCTS,
  RECEIVE_PRODUCT,
} from './actions';

const productReducer = (state = { current: {}, list: [] }, action) => {
  switch(action.type) {
    case RECEIVE_FIND_PRODUCTS: {
      const { data, total, toAppend, error } = action.payload;
      
      if (error) return state;
      if (toAppend) {
        const isComplete = !(data && data.length);
        return { ...state, total, isComplete, list: [...state.list, ...data] };
      } else {
        return { ...state, isComplete: false, total, list: data };
      }
    }
    case RECEIVE_PRODUCT: {
      const { data, error } = action.payload;
      if (error) return state;
      return { ...state, current: data};
    }
    default: {
      return state;
    }
  }
};

export default productReducer;