import store from 'store';
import { initialize } from 'redux-form';

export default (formName, fn) => {
  const state = store.getState();


  const fieldPaths = Object.keys( state.form[formName].registeredFields )
    .map(key => key.split('.'));
  
  

  const userState = { 
    client: {
      ...state.clients.current, 
      partner: state.clients.current.partners[0] || {} 
    },
    application: state.clients.current.applications[0] || {},
  };
  let initialValues = {};  

  for (let path of fieldPaths) { 
    const lastIndex = path.length - 1;
    let valLoc = initialValues;
    let stateLoc = userState; 
  
    for (let [index, key] of path.entries()) {
      if (!stateLoc[key]) break;
      if (index === lastIndex) {
        valLoc[key] = stateLoc[key];
        break;
      }
      if (!valLoc[key]) valLoc[key] = {};
      valLoc = valLoc[key];
      stateLoc = stateLoc[key];
    }  
  }

  for (const key in initialValues) {
    if (initialValues[key] == null) delete initialValues[key];
  }


  if (fn) 
    store.dispatch( initialize(formName, fn(initialValues, state)) );
  else 
    store.dispatch( initialize(formName, initialValues) );
};