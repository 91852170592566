import React from "react"; import { Component } from "react";
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import calculateLoanValues from "../utils/calculateLoanValues"
import { bindActionCreators } from 'redux';
import calculateMonthlyRepayments from "../utils/calculateMonthlyRepayments";  
import { SmallHeading } from './Shared';
import formatNumber from '../utils/formatNumber';
import BankLogo from './BankLogo';

function mapStateToProps(state, props) {
  return {
    shortlist: props._user.shortlist,
    nsrValues: props._user.nsrValues,
    products: state.products.list,
    user: props._user.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}


class RefinanceAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refinanceState: false,
      toggleRefinance: false,
      repaymentInterval: 1,
    };
  }

  switchRefinanceState() {
    this.setState((prevState) => { return { ...prevState, refinanceState: !prevState.refinanceState } });
  }

  calculatePeriods = ({ user, product, mode, currentLoan }) => {
    const loanPeriod = user.refinanceYears || 30;
    const newLoanPeriod = (mode === "proposal" && currentLoan) ? (currentLoan.newLoanPeriod || 30) : loanPeriod; 

    let chartPeriod = (product.initial_ir_type === 'Fixed' || product.initial_ir_type === 'Intro Variable') ?
      Math.floor(product.fixed_term / 12) : Math.max(loanPeriod, newLoanPeriod);

    if (user.refinanceRepaymentType === 'Interest' || product.repaymentType === 'Interest Only') {
      chartPeriod = Math.min(chartPeriod, 5);
    }

    return { chartPeriod, loanPeriod, newLoanPeriod };
  }

  calculateLoanSchedulePandI = ({ period, ir, principle, chartPeriod }) => {
    const repayment = calculateMonthlyRepayments(ir, period, principle);
    let totalInterestRepayment, totalPrincipleRepayment;
    totalInterestRepayment = totalPrincipleRepayment = 0;
    let schedule = [{
      time: 0,
      remainingPrinciple: principle,
      totalInterestRepayment,
      totalPrincipleRepayment,
      interestRepayment: 0,
      principleRepayment: 0
    }];
    [...Array(chartPeriod)].forEach((_, i) => {
      
      const remainingPrinciple = principle - totalPrincipleRepayment;
      const interestRepayment = remainingPrinciple * ir;
      const principleRepayment = repayment - interestRepayment;
      if (i < period) {
        totalInterestRepayment += interestRepayment;
        totalPrincipleRepayment += principleRepayment;
      }
     
      schedule.push({
        time: i + 1,
        interestRepayment,
        totalInterestRepayment,
        principleRepayment,
        remainingPrinciple
      });
    });
    return schedule;
  }

  calculateLoanScheduleInterestOnly = ({ period, ir, principle, chartPeriod }) => {
    //console.log('IO schedule stuff:', period, ir, principle)
    let schedule = [{
      time: 0,
      remainingPrinciple: principle,
      totalInterestRepayment: 0,
      totalPrincipleRepayment: 0,
      interestRepayment: 0,
      principleRepayment: 0
    }];
    const interestRepayment = ir * principle;
    let totalInterestRepayment = 0;
    [...Array(chartPeriod)].forEach((_, i) => {
      if (i < period) {
        totalInterestRepayment += interestRepayment;
      }

      schedule.push({
        time: i + 1,
        interestRepayment,
        totalInterestRepayment,
        principleRepayment: 0,
        remainingPrinciple: principle
      });
      
    });
    return schedule;
  }

  render() {
    let { user, currentLoan } = this.props;

    currentLoan.newLoanPeriod = parseInt(currentLoan.newLoanPeriod);
    user = {
      ...user,
      refinanceLoanAmount: currentLoan.loanAmount,
      refinanceInterestRate: currentLoan.interestRate,
      refinanceLender: currentLoan.lender,
      refinanceMonthlyRepayment: currentLoan.monthlyRepayment,
      refinanceOtherLenderName: currentLoan.otherLenderName,
      refinanceRepaymentType: currentLoan.repaymentType,
      refinanceYears: currentLoan.loanPeriod,
    }; 

    const product = calculateLoanValues(this.props.product, user);
    const { chartPeriod, loanPeriod, newLoanPeriod } = this.calculatePeriods({ user, product, mode: "proposal", currentLoan });
    const trailRebate = product.trailRebate || 0;
    
    let newMonthlyRepayment = calculateMonthlyRepayments(parseFloat(product.iir) / 100 / 12, newLoanPeriod * 12, (user.refinanceLoanAmount)).toFixed(2);
    if (product.repaymentType === 'Interest Only') {
      newMonthlyRepayment = product.iir * user.refinanceLoanAmount / 12 / 100;
    }
    const widthNewMonthlyRepayment = ((((parseFloat(newMonthlyRepayment) / parseFloat(user.refinanceMonthlyRepayment)) * 100)));
    const widthCurrentMonthlyRepayment = ((((parseFloat(user.refinanceMonthlyRepayment) / parseFloat(newMonthlyRepayment)) * 100)));

    product.irrBeforeRebate = product.iir;
    product.iir -= trailRebate;

    const loanSchedule = user.refinanceRepaymentType === 'Interest' ?
      this.calculateLoanScheduleInterestOnly({ period: loanPeriod * 12, ir: user.refinanceInterestRate / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 })
      :
      this.calculateLoanSchedulePandI({ period: loanPeriod * 12, ir: user.refinanceInterestRate / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 });

    const newLoanSchedule = product.repaymentType === 'Interest Only' ?
      this.calculateLoanScheduleInterestOnly({ period: newLoanPeriod * 12, ir: product.iir / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 })
      :
      this.calculateLoanSchedulePandI({ period: newLoanPeriod * 12, ir: product.iir / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 });

    const newLoanScheduleWithoutRebate = product.repaymentType === 'Interest Only' ?
      this.calculateLoanScheduleInterestOnly({ period: newLoanPeriod * 12, ir: product.irrBeforeRebate / 1200, principle: user.refinanceLoanAmount, chartPeriod: 13 })
      :
      this.calculateLoanSchedulePandI({ period: newLoanPeriod * 12, ir: product.irrBeforeRebate / 1200, principle: user.refinanceLoanAmount, chartPeriod: 13 });

    const yearOneInterestSaving = loanSchedule[12].totalInterestRepayment - newLoanSchedule[12].totalInterestRepayment;
    const yearOneRebateSaving = newLoanScheduleWithoutRebate[12].totalInterestRepayment - newLoanSchedule[12].totalInterestRepayment;
    //console.log('loan schedules', loanSchedule, newLoanSchedule);
 
    let AltLogo;
    if (user.refinanceOtherLenderName) {
      AltLogo = () => <h6 className="company-text">{user.refinanceOtherLenderName}</h6>;
    } else {
      AltLogo = () => <h6 className="company-text">Your Current Lender</h6>;
    }

    let currentBankLogo;
    try {
      currentBankLogo = <img className="company-logo" src={require(`img/banks/${user.refinanceLender}.png`)} alt="logo" />;
    } catch (e) {
      if (user.refinanceOtherLenderName) {
        currentBankLogo = <h6 className="company-text">{user.refinanceOtherLenderName}</h6>;
      } else {
        currentBankLogo = <h6 className="company-text">Your Current Lender</h6>;
      }
    }

    const rebate = Number.parseInt(product.rebate_value) || 0;

    let repaymentIntervalString = 'monthly';
    if (this.state.repaymentInterval > 2) repaymentIntervalString = 'fortnightly';
    if (this.state.repaymentInterval > 4) repaymentIntervalString = 'weekly';

    //console.log('loan periods', chartPeriod, loanPeriod)

    const totalInterestSavings = (loanSchedule[chartPeriod * 12].totalInterestRepayment - newLoanSchedule[chartPeriod * 12].totalInterestRepayment) + rebate
    const chartLabels = (chartPeriod < 4) ? [...Array(chartPeriod * 12 + 1).keys()] : [...Array(chartPeriod * 12 + 1).keys()].map(n => '' + Math.floor(n / 12));
    const labelPeriod = (chartPeriod < 4) ? 'Month' : 'Year';
    //console.log(chartLabels)

    var options = {
      animation: false,
      lineHeightAnnotation: {
        always: false,
        hover: true,
        color: '#aaa',
        lineWeight: 1,
        noDash: true,
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            minRotation: 0
          },
          scaleLabel: {
            display: true,
            labelString: labelPeriod + 's',
          }
        }],
        yAxes: [{
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            callback: function (label, index, labels) {
              if (label > 999999) {
                return '$' + label / 1000000 + 'M';
              } else {
                return '$' + label / 1000 + 'k';
              }
            },
            scaleLabel: {
              display: true,
              labelString: '1k = 1000'
            },
            autoSkip: true,
            maxTicksLimit: 6
          }
        }]
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return labelPeriod + ' ' + data['labels'][tooltipItem[0]['index']];
          },
          label: function (tooltipItem, data) {
            var label = Math.round(tooltipItem.yLabel)
            var comma = label.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            return '$' + comma + ' savings';
          },
        },
        backgroundColor: '#fff0',
        //borderColor: '#E9EBF1',
        //borderWidth: 1,
        intersect: false,
        titleFontFamily: "'Lato', sans-serif",
        titleFontSize: 11,
        titleFontStyle: 400,
        titleFontColor: '#727C8F',
        bodyFontColor: '#101922',
        bodyFontFamily: "'Lato', sans-serif",
        bodyFontSize: 12,
        bodyFontStyle: 600,
        displayColors: false,
        caretPadding: 30,
        caretSize: 15,
        cornerRadius: 4,
        xPadding: 10,
        yPadding: 10
      },
      elements: { point: { radius: 50 } },
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
    }
    this.data = (canvas) => {
      const ctx = canvas.getContext("2d")
      const gradient = ctx.createLinearGradient(150.000, 0.000, 150.000, 300.000);
      gradient.addColorStop(0.2, 'rgba(234,245,255,1)');
      gradient.addColorStop(1, 'rgba(255,255,255,0)');
      return {
        labels: chartLabels,
        datasets: [
          {
            label: 'My First dataset',
            lineTension: 0.1,
            backgroundColor: gradient,
            borderColor: 'rgba(34,145,255,1)',
            borderWidth: 2,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'bevel',
            pointBorderWidth: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(34,145,255,1)',
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            fill: 'origin',
            data: [...Array(chartPeriod * 12 + 1).keys()]
              .map(index => ({
                x: index,
                y: (loanSchedule[index].totalInterestRepayment - newLoanSchedule[index].totalInterestRepayment) + rebate
              })),
          }
        ]
      }
    };

    return <Container>
      <Row>
        <CenteredColumn>
          <Heading>Your Potential Refinance Savings</Heading>
        </CenteredColumn>
      </Row>
      <Box>
        
        <Row>
          <CenteredColumn width='40%' style={{ margin: '25px 0 25px 25px'}}>
            <LogoContainer>
              <BankLogo 
                width={135}
                height={45}
                maxFill={0.6}
                product={product}
                alt="bank logo" 
              /> 
            </LogoContainer>
            <div style={{fontSize: '10.2px', marginBottom: '25px'}}>
              Offset Home Loan Package
            </div >
            <div style={{fontSize: '22px', marginBottom: '5px', fontWeight: 'bold'}}>
              {dollars(user.refinanceLoanAmount)}
            </div>
            <div style={{fontSize: '10.2px', marginBottom: '30px', color: '#727c8f'}}>
              New Loan Amount
            </div >
            <div style={{width: '100%', height: '44px'}}>
              <IndicatorBackground>
                <IndicatorForground color='#727C8F' width={Value(widthCurrentMonthlyRepayment) + '%'}>Current Replayments</IndicatorForground>
              </IndicatorBackground>
              <span style={{fontSize: '22px', paddingLeft: '20px', fontWeight: 'bold'}}>
                {dollars(user.refinanceMonthlyRepayment / this.state.repaymentInterval)}  
              </span>
            </div>
            <div style={{width: '100%', height: '44px'}}>
              <IndicatorBackground>
                <IndicatorForground color='#2291FF' width={Value(widthNewMonthlyRepayment) + '%'}>New Replayments</IndicatorForground>
              </IndicatorBackground>
              <span style={{fontSize: '22px', color:'#2291FF', paddingLeft: '20px', fontWeight: 'bold'}}>
                {dollars(Math.round(newMonthlyRepayment / this.state.repaymentInterval))}
              </span>
            </div>
          </CenteredColumn>
          <Column width='60%' style={{ position: 'relative', margin: '20px auto'}}>
            <PotentialSavings positive={totalInterestSavings > 0}>
              <h2 style={{ fontWeight: '700', fontSize: '22px'}}>{dollars(totalInterestSavings)}</h2>
              {!!trailRebate ?
                <p style={{ fontSize: '12px', color: '#575E6C'}}>Total Interest Savings Over&nbsp;{chartPeriod} Years <br/> Including Rebate</p>
                  :
                <p style={{ fontSize: '12px', color: '#575E6C'}}>Total Interest Savings<br />Over&nbsp;{chartPeriod} Years</p>
              }
            </PotentialSavings>
            <Line data={this.data} options={options} style={{ height: '307px'}}/>
          </Column>
        </Row>
        <Row borderTop>
          <Column borderRight width='40%' style={{ padding: '15px'}}>
            <p style={{ fontSize: '10px'}}>
              The new loan term is assumed to be equal in length to the current loan's remaining term. 
              For any interest only period, the comparison chart only shows savings over 5 years. 
              All savings and rebates are approximations only, and are based on the stated loan balance.
            </p>
          </Column>
          <Column width='60%' style={{ paddingTop: '20px'}}>
              <HorizontalItems >
                  <CenteredColumn width='33%'>
                    <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px'}}>{dollars(user.refinanceMonthlyRepayment - newMonthlyRepayment)}</div>
                    <div style={{ fontSize: '11px'}}>Monthly Replayment Savings</div>
                  </CenteredColumn>

                <CenteredColumn width='33%'>
                  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px'}}>{dollars(yearOneInterestSaving)}</div>
                  <div style={{ fontSize: '11px'}}>Year 1 Interest Savings</div>
                </CenteredColumn>
                {!!trailRebate &&
                  <CenteredColumn width='33%'>
                    <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px'}}>{dollars(yearOneRebateSaving)}</div>
                    <div style={{ fontSize: '11px'}}>Year 1 Rebate</div>
                  </CenteredColumn>
                }
                

              </HorizontalItems>
          </Column>
        </Row>
      </Box>

    </Container>;
  }
}

const LogoContainer = styled.div`
  height: 35px;
  img {
    max-width: 200px !important;
    max-height: 200px !important;
  }
`;

const HorizontalItems = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-content: center;
`;

const Container = styled.div`
  margin: 30px 0 0 0;
`;

const Box =  styled.div`
    width: 100%;
    background-color: #fff;
    border: 1px solid #D8DCE7;
    border-radius: 4px;
    color: #575E6C;
    * {
      font-family: LatoPDF, sans-serif;
    }
`;

const Row = styled.div`
  width: 100%;
  display: flex;

  ${props => props.borderTop && css`
    border-top: 1px solid #D8DCE7;
  `}
`;

const Column = styled.div`
  width: ${props => props.width ?? '100%'};
  ${props => props.borderRight && css`
    border-right: 1px solid #D8DCE7;
  `}
`;

const Heading = styled(SmallHeading)`
  font-size: 18.5px !important;
  margin-bottom: 10px !important;
`;

const CenteredColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IndicatorBackground = styled.div`
  vertical-align: top;
  display: inline-block;
  height: 24px;
  width: 200px;
  border-radius: 24px;
  background-color: #E9EBF1;
`;

const IndicatorForground = styled(IndicatorBackground)`
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  width: ${props => props.width};
  background-color: ${props => props.color};
`;

const PotentialSavings = styled.div`
  display: block;
  position: absolute;
  right: 30px;
  text-align: right;

  ${props => props.positive ? css`
    bottom: 60px;
  ` : css`
    top: 30px;
  `}
`;

const dollars = n => (n > 0) ? '$' + formatNumber(n) : `-$` + formatNumber(-n);

function LVR(value) {
  if (value > 100) {
    return <div className="value red">{"100"}%</div>;
  }
  else if (value > 90) {
    return <div className="value red">{value}%</div>;
  }
  else if (value > 80) {
    return <div className="value orange">{value}%</div>;
  }
  else {
    return <div className="value">{value}%</div>;
  }
}

function Value(value) {
  if (value < 100) {
    return value;
  } else {
    return 100;
  }
}

function InvOrOcc(inv, occ) {
  if ((inv > 0) && (occ > 0)) {
    return "Investment or Owner Occupied";
  }
  else {
    if (occ > 0) {
      return "Owner Occupied"
    }
    else {
      return "Investment"
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefinanceAnalysis);