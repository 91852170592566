import React, { useRef, useState, useEffect } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';

import TitlePage from './PDF/TitlePagePDF';
import IntroLetter from './PDF/ATPIntroLetter';
import AboutLoanBase from './PDF/AboutLoanBasePDF';
import RecommendedLenders from './PDF/RecommendedLendersPDF';
import LendingOverviewIntro from './PDF/LendingOverviewIntroPDF';
import LendingStructure from './PDF/LendingStructurePDF';
import AdditionalDetails from './PDF/AdditionalDetailsPDF';
import Glossary from './PDF/GlossaryPDF';
import TermsAndConditions from './PDF/TermsAndConditionsPDF';

import imgCity from './img/illustrations/city_watermark.png';
import { Measurer, ToMeasure } from './PDF/Measurer';
import { calculateProductValues } from './utils/CalculateProductValues';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import uriToBlob from './utils/uriToBlob';
import DocsPDF from './PDF/DocsPDF';
import TimeLine from './PDF/TimeLinePDF';

const MAX_CONTENT_HEIGHT = 1320;

export default ({ finished, user, isFinal, settings }) => {

  // const user = useSelector(state => state.clients.current);

  if (!user) return null;

  const miniProposal = user.meta.miniproposal;
  const lenders = user.lenders;

  const { loans, banks } = getLoanData(user);

  let proposalLenders = miniProposal.tabs
    .map(id => {
      const tab = miniProposal.properties[id];
      if (!tab || tab.excludedFromOverview || !tab.loanComparison.recommendationActive) return null;
      const loanId = tab.loanComparison.loanOrder[0];
      if (!loanId) return null;
      let loan = tab.loanComparison.loans[loanId];
      return loan;
    })
    .filter(loan => {
      if (!loan) return false;
      const bank = lenders.find(x => x.id === loan.bank)
      if (!bank || !bank.description) return false;
      return true;
    })
    .reduce((acc, loan) => ({
      ...acc,
      [loan.bank]: loan.bank_name,
    }), {});
  
  proposalLenders = Object.keys(proposalLenders)
    .map(id => ({ id, name: proposalLenders[id] }));
  
  const componentProps = { user, banks, loans, isFinal, type:'atp' };
    

  const overflowComponents = [
    <LendingOverviewIntro {...componentProps}/>,
    <RecommendedLenders {...componentProps}/>, 
    <LendingStructure {...componentProps}/>,
    <AdditionalDetails {...componentProps}/>,
  ];

  const [ page1End, setPage1End ] = useState(overflowComponents.length);
  const [ page2End, setPage2End ] = useState(overflowComponents.length);
  const [ page3End, setPage3End ] = useState(overflowComponents.length);

  const ref = useRef(null);

  const onSizeChange = (heights, changed) => {

    if (changed.overflow1) {
      if (heights.overflow1 > MAX_CONTENT_HEIGHT && page1End > 0) {
        setPage1End(page1End - 1);
      }
    }

    if (changed.overflow2) {
      if (heights.overflow2 > MAX_CONTENT_HEIGHT && page2End - 1 > page1End) {
        setPage2End(page2End - 1);
      }
    }

    if (changed.overflow3) {
      if (heights.overflow3 > MAX_CONTENT_HEIGHT && page3End - 1 > page2End) {
        setPage3End(page3End - 1);
      }
    }
  }

  useEffect(() => {
    setTimeout(async () => {
      if (ref && ref.current) {
        console.log('Creating PDF...');

        const uri = await exportPDFWithMethod(isFinal);
        const blob = await uriToBlob(uri);
        
        if (finished) finished(blob);
      }
    }, 2000);
  }, []);

  const tabs = miniProposal.tabs.map(id => miniProposal.properties[id]);
  return (  
    <PDFExport
      pageTemplate={isFinal ? PageTemplate2 : PageTemplate1}
      paperSize='A4'
      fileName="proposal.pdf"
      forcePageBreak=".page-break"
      keepTogether=".keep-together2"
      scale={595/1100}
      margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      title=""
      subject=""
      keywords=""
      ref={ref}
    >
      <div className='proposal-pdf'>
        <TitlePage user={user} type='atp' isFinal={isFinal}/>

        <PageBreak />

        <MainContent className='proposal'>
          <BackgroundCity>
            <IntroLetter user={user} isFinal={isFinal} settings={settings}/>
            <AboutLoanBase settings={settings}/>
          </BackgroundCity>
          
          <Measurer onChange={onSizeChange}>
            {({ name }) => {
              return <>
                <PageBreak />
                <Page {...name('overflow1')}>
                  {overflowComponents.slice(0, page1End)}
                </Page>
                <PageBreak />
                {page1End !== overflowComponents.length && <>
                  <Page {...name('overflow2')}>
                    {overflowComponents.slice(page1End, page2End)}
                  </Page>
                  <PageBreak />
                </>}
                {page2End !== overflowComponents.length && <>
                  <Page color='green' {...name('overflow3')}>
                    {overflowComponents.slice(page2End, page3End)}
                  </Page>
                  <PageBreak />
                </>}
                {page3End !== overflowComponents.length && <>
                  <Page color='pink' {...name('overflow4')}>
                    {overflowComponents.slice(page3End, overflowComponents.length)}
                  </Page>
                  <PageBreak />
                </>}
              </>;
            }}
          </Measurer>
          
          {!!user.documents?.length &&
            <DocsPDF user={user} isFinal={isFinal} settings={settings}/>
          }
          <PageBreak />

          <TimeLine isFinal={isFinal}/>
          <PageBreak/>

          <Glossary settings={settings}/>
          <PageBreak/>

          <TermsAndConditions loans={loans} settings={settings}/>
          
        </MainContent> 

        
      </div>
      
    </PDFExport>

  );

};

const exportPDFWithMethod = async (isFinal) => {
  let el = document.getElementsByClassName('proposal-pdf')[0];
  
  const pdf = await drawDOM(el, { 
    paperSize: 'A4',
    forcePageBreak:'.page-break',
    keepTogether:'.keep-together2',
    scale: 595 / 1100,
    margin: { top: 0, left: 0, right: 0, bottom: 0 },
    template: props => {
      var pageTemplateContent = ReactDOMServer.renderToStaticMarkup(React.createElement(isFinal ? PageTemplate2 : PageTemplate1, props));
      return '<span>' + pageTemplateContent + '</span>';
    }
  });
  
  const dataUri = await exportPDF(pdf);

  return dataUri;
  //download(dataUri, 'proposal.pdf', 'application/pdf');
};

const Page = styled(ToMeasure)`
  /* background-color: ${p => p.color ?? 'white'}; */
`;

const BackgroundCity = styled.div`
  height: 1300px;
  width: 100%;
  background-image: url(${imgCity});
  background-size: 780px 180px;
  background-position: 0px 1090px;
  background-repeat: no-repeat;
`;

const PageBreak = props => <div className='page-break' {...props}/>;

const KeepTogether = props => <div className='keep-together' {...props}/>;

const MainContent = styled.div`
  width: 1100px;
  padding: 147px 135px 0px 135px;
  * {
    font-family:   !important;
  }
  /* .page-break {
    border-bottom: 3px solid black;
  } */
`;

const PageTemplate1 = ({ pageNum, totalPages }) => {
  if (pageNum === 1) return null;
  return (
    <>
      <PageHeader >
        <span>1300 512 377</span>
        <span>/</span>
        <span>contact@loanbase.com.au</span>
        <span>/</span>
        <span>www.loanbase.com.au</span>
      </PageHeader>
      <PageFooter >
        <span>Application Preparation / Loan Base</span>
        <PageNumber><div>{pageNum - 1}</div></PageNumber>
      </PageFooter>
    </>
  );
}

const PageTemplate2 = ({ pageNum, totalPages }) => {
  if (pageNum === 1) return null;
  return (
    <>
      <PageHeader >
        <span>1300 512 377</span>
        <span>/</span>
        <span>contact@loanbase.com.au</span>
        <span>/</span>
        <span>www.loanbase.com.au</span>
      </PageHeader>
      <PageFooter >
        <span>Application Finalisation / Loan Base</span>
        <PageNumber><div>{pageNum - 1}</div></PageNumber>
      </PageFooter>
    </>
  );
}

const PageNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding-bottom: 3px;
  padding-right: 1px;
`;

const PageHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F7F7F7 ;
  width: 100%;
  height: 92px;
  font-size: 15px;
  padding: 39px 135px;
  font-family: LatoPDF, sans-serif;
  color: #575E6C;

  span {
    margin-left: 10px;
  }
`;

const PageFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #2B8AEA;
  width: 100%;
  height: 92px;
  padding: 35px 135px;
  color: white;
  font-size: 18.5px;
  font-family: LatoPDF, sans-serif;

  display: flex;
  justify-content: space-between;
`;

const getLoanData = user => {
  const miniProposal = user.meta.miniproposal;
  let loans = [];
  for (const id of (miniProposal.tabs || [])) {
    const tab = miniProposal.properties[id];
    if (!tab || tab.excludedFromOverview || !tab.loanComparison.recommendationActive) continue;
    const loanComparison = tab.loanComparison;
    const currentLoan = tab.currentLoan;
    const loanId = loanComparison.loanOrder[0];
    if (!loanId) continue;
    const newLoan = calculateProductValues(loanComparison.loans[loanId], currentLoan, user);

    if (currentLoan) {
      loans.push({
        newLoan,
        currentLoan,
        name: tab.name,
        cashback: tab.overviewCashback,
      });
    }
  }

  const banks = loans.reduce((obj, loan) => {
    const bank = loan.newLoan.bank;
    return ({
      ...obj,
      [bank]: [...(obj[bank] || []), loan.newLoan],
    });
  }, {});

  return { loans, banks };
};