import React, { Component } from 'react';
import styled from 'styled-components';

import { intToOrdinal, getQueryVariables } from 'util/index';

import Card from 'components/Styled/Card';
import { Row as BaseRow, Column as BaseColumn, PullRight, PullLeft } from 'components/Styled/Layout';
import Input from 'components/Styled/TextInput';
import TextArea from 'components/Styled/TextArea';
import Label from 'components/Styled/Label';
import Select from 'components/Common/Select';
import BackButton from 'components/Common/BackButton';
import LabelledInput from 'components/Common/LabelledInput';
import { ButtonPlain, ButtonPrimary } from 'components/Styled/Button';
import Text from 'components/Styled/Text';
import { NumberInput } from 'components/Common/NumberInput';


import imgAdd from 'img/button/small-add-blue.png';
import imgSearch from 'img/button/search-blue.png';
import imgTrash from 'img/button/trash-red.png';

const Row = styled(BaseRow)`
  padding: 0 0 0 40px;
`;

const Column = styled(BaseColumn)`
  padding: 30px 40px 0 0;
`;

const ButtonRight = styled(ButtonPlain)`
  align-self: top;
  float: right;
  margin-right: '20px';
`;

const ImgSearch = styled.img.attrs({
  src: imgSearch,
  width: 14,
})`
  position: relative;
  top: 2px;
  margin-right: 10px;

`;

const ImgAdd = styled.img.attrs({
  src: imgAdd,
  width: 10,
})`
  position: relative;
  margin-right: 10px;
`;

const ImgTrash = styled.img.attrs({
  src: imgTrash,
  width: 16,
})`
  position: relative;
  top: 2px;
  right: 4px;
`;




class ApplicationsFunding extends Component {
  
  render() {
    const { id } = getQueryVariables(this.props.location.search);
    return (
      <div>
        <BaseRow style={{width:'800px'}}>
          <PullLeft>
            <BackButton margin='15px 0 0 0' />
          </PullLeft>
            
          <PullRight>
            <ButtonPlain style={{margin: '0 0 20px 20px'}}>
              <ImgAdd/>
              Add Split
            </ButtonPlain>

          </PullRight>

        </BaseRow>
        
        
        <FundingCard main number='1'/>
        <FundingCard number='2'/>

        <BaseRow style={{width:'800px', margin: '-20px 0 0 0'}}>
          <PullLeft>
            <ButtonPlain width='100px'>Cancel</ButtonPlain>
          </PullLeft>

          <PullRight>
            <ButtonPrimary width='150px'>Save</ButtonPrimary>
          </PullRight>
        </BaseRow>
      </div>
    );
  }

}

const FundingCard = ({ number, main }) => (
  <Card padding='10px 0 40px 0' margin='0 0 40px 0' width='800px'>
    <Row>
      <Column>
        <PullLeft>
          <Heading>{intToOrdinal(number)} Split </Heading>
        </PullLeft>
        {!main &&
          <PullRight>
            <ButtonPlain width='30px' height='30px' style={{ margin: '-1px 0 0 0' }}><ImgTrash /></ButtonPlain>
          </PullRight>
        }
      </Column>
    </Row>
    <Row>

      <Column width='25%'>
        <Label>Loan Balance</Label>
        <LabelledInput as={NumberInput} label='$' />
      </Column>

      <Column width='25%'>
        <Label>Loan Limit</Label>
        <LabelledInput as={NumberInput} label='$' />
      </Column>

      <Column width='25%'>
        <Label>Loan Term</Label>
        <Input />
      </Column>

      <Column width='25%'>
        <Label>Loan Purpose</Label>
        <Input />
      </Column>

      <Column width='25%'>
        <Label>Interest Rate</Label>
        <LabelledInput label='%' />
      </Column>

      <Column width='25%'>
        <Label>Revert Rate</Label>
        <LabelledInput label='%' />
      </Column>

      <Column width='25%'>
        <Label>Interest Type</Label>
        <Select options={[{name: '2 Years Fixed', value: ''}]}/>
      </Column>
      
      <Column width='25%'>
        <Label>Repayment Type</Label>
        <Select options={[{name: 'Interest Only', value: ''}]}/>
      </Column>
      
      <Column width='50%'>
        <Label>Notes</Label>
        <TextArea height='135px'/>
      </Column>

      <Column width='25%'>
        <Label>Fixed Expiry</Label>
        <Select options={[{name: '10/10/2020', value: ''}]}/>
      </Column>

      <Column width='25%'>
        <Label>Interest Only Expiry</Label>
        <Select options={[{name: '10/10/2020', value: ''}]}/>
      </Column>

      <Column width='50%'>
        <Label>Security or X-Collat Group</Label>
        <Select options={[{name: '98 Hassans Walls Rd, Lithgow 2790', value: ''}]}/>
      </Column>


    </Row>

    <Row>
      <BaseColumn width='50%' style={{height: '15px'}}></BaseColumn>
      <BaseColumn width='50%' style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text style={{flexGrow: 1, margin: '15px 0 0 0'}} as='span' size='12px'>Value: $1,400,000</Text>
        <Text style={{flexGrow: 1, margin: '15px 0 0 0'}} as='span' size='12px'>Total Lending: $400,000</Text>
        <Text style={{flexGrow: 1, margin: '15px 0 0 0'}} as='span' size='12px'>LVR: 80%</Text>
      </BaseColumn>
      <Column width='50%'>
        <ButtonPrimary background='#26BD27'>Link Loan Product</ButtonPrimary>
      </Column>
      <Column width='50%'>
        <Text size='12px' >Linked Loan Product</Text>
        <Text size='12px'>Resimac MaxiSaver 80% LVR Investment Loan</Text>
      </Column>
    </Row>
  </Card>
);


const Heading = styled.h2`
  font-size: 20px;
`;

export default ApplicationsFunding;