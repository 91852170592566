import React from 'react';
import { FieldArray } from 'redux-form';
import styled, { css } from 'styled-components';
import { Input, NumberInput, ToggleButton } from 'components/Form';
import { DeleteButton, Button } from './Shared';

const TextBlocks = ({ fields, buttonTitle }) => {

  const newStage = () => {
    const maxIndex = fields.getAll().reduce((max, stage) => Math.max(stage.stageIndex, max), 0);
    const roundedIndex = (Math.floor(maxIndex / 100) + 1) * 100;
    fields.push({ canBeDeleted: true, stageIndex: roundedIndex, applications: [] });
  };

  const remove = index => {
    fields.remove(index);
  };

  return (
    <Container>
      {!!(fields || []).length && 
        <ul>

          {(fields || []).map((item, index) => {
            const stage = fields.get(index);
            return (
              <li key={index} clasName='text-item'>
                <Column width='100px'>
                  <Label>Order</Label>
                  <NumberInput name={`${item}.stageIndex`} />
                </Column>
                <Column width='calc(100% - 360px)'>
                  <Label>Name</Label>
                  <Input name={`${item}.name`} />
                </Column>
                <Column width='100px'>
                  <Label>Due Days</Label>
                  <NumberInput name={`${item}.daysDue`} />
                </Column>
                <Column width='100px' style={{ position: 'relative', top: '-15px', left: '20px'}}>
                  <Label>Hidden</Label>
                  <ToggleButton name={`${item}.hidden`} valueChecked={1} valueUnchecked={0}/>
                </Column>
                {!!stage.canBeDeleted && !(stage.applications && stage.applications.length) &&
                  <Column width='40px' style={{paddingRight: '0'}}>
                    <DeleteButton onDelete={() => remove(index)} id={index} type='objectives'/>
                  </Column>
                }
                
              </li>
            );
          })}
        </ul>
      }
      <Column width='200px' style={{marginTop: '30px'}}>
        <Button color='#2291FF' onClick={newStage}>
          {buttonTitle || 'Add'}
        </Button>
      </Column>
      
    </Container>
  );
};

const TextList = props => {
  return (
    <FieldArray {...props} component={TextBlocks}/>
  );
};

export default TextList;

const Container = styled.div`
  li.text-item {
    margin-bottom: 40px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Column = styled.div`
  display: inline-block;
  width: ${p => p.width || '100%'};
  vertical-align: bottom;
  padding-right: 10px;
  margin-bottom: 10px;
`;
